/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  Logo,
  Button,
  Content,
  ClassHelper,
} from "components/lib";
import Style from "./home.module.scss";

export function HomeNav(props) {
  // context
  const context = useContext(AuthContext);

  const css = ClassHelper(Style, {
    wrapper: true,
    color: !props.transparent,
    transparent: props.transparent,
  });

  return (
    <section className={css}>
      <Content>
        <Logo className={Style.logo} />
        <nav className={Style.nav}>
          {/* <Link
            url='/pricing'
            text='Pricing'
            className={Style.link}
            color='white'
          /> */}

          {context.user?.token ? (
            <Button
              small
              goto='/dashboard'
              text='Inicio'
              className={Style.button}
            />
          ) : (
            <Fragment>
              <Button
                small
                goto='/signin'
                text='Iniciar Sesión'
                className='inline-block'
              />
            </Fragment>
          )}
        </nav>
      </Content>
    </section>
  );
}
