/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   noPadding: remove the padding
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/

import React from "react";
import { Grid, ClassHelper } from "components/lib";
import Style from "./title.module.scss";
import useCollapse from "react-collapsed";

export function Title(props) {
  const titleStyle = ClassHelper(Style, {
    className: props.className,
  });
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <section className={titleStyle}>
      {props.whiteTitle && (
        <h2 className={Style.whiteTitle}>{props.whiteTitle}</h2>
      )}
      {props.blackTitle && (
        <div className='collapsible'>
          <div className='header' {...getToggleProps()}>
            {isExpanded ? (
              <h2 className={Style.blackTitle}>{props.blackTitle}</h2>
            ) : (
              <h2 className={Style.blackTitle}>{props.blackTitle}</h2>
            )}
          </div>
          <div {...getCollapseProps()}>
            <div className='content'>{props.children}</div>
          </div>
        </div>
      )}
    </section>
  );
}
