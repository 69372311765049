import { BackButton } from "components/backButton/button";
import {
    Submit,
    buscaEmisionSdp,
    enviaOtp,
    getCandidateById,
    saveCandidateOtp,
    updateCandidateOtp,
} from "components/bridges/BridgeServices";
import { SwitchProduct } from "components/formproduct/switch/switch";
import {
    Animate,
    ViewContext,
    useAPI,
    useLocation
} from "components/lib";
import { useContext, useEffect, useState } from "react";
import GlobalStyle from "../../../../css/globalStyle.module.scss";
import Style from "../../agriculture/agricultureSeller.module.scss";
import { Title } from "./components/title";

export function FormVerificacionOTP() {
    const context = useContext(ViewContext);
    const plan = JSON.parse(localStorage.getItem('plan_details'));
    let url = useLocation();
    let vecUrl = url.pathname.split("/");
    let candidateId = vecUrl[vecUrl.length - 1];
    const [isLoading, setIsLoading] = useState(false);
    const formsFilled = useAPI(`/api/formByFilter?candidate_id=${candidateId}&product_name=priori&type=priori_vinculacion`);
    const otpFilled = useAPI(`/api/candidate_otp/${candidateId}`);

    const [formData, setFormData] = useState(null);
    const viewContext = useContext(ViewContext);
    const [sendOtpCode, setSendOtpCode] = useState(false);
    const [verifyOtpCode, setVerifyOtpCode] = useState(false);
    const [send, setSend] = useState(false);
    const [codigoOTP, setCodigoOTP] = useState('');


    useEffect(() => {
        async function getEmitStatus() {  
            let curCandidate = await getCandidateById(candidateId);
            let idProveedor = curCandidate.data[0]?.emitesdp_request?.astr_id_proveedor;
            if (idProveedor === null || idProveedor === undefined || idProveedor === '') {
                return;
            }
            try {
                let responseBusca = await buscaEmisionSdp(idProveedor, candidateId);
                let response = JSON.parse(responseBusca?.data?.message);
                if (response.resultado === 'OK') {
                    setSend(true);
                }
            }
            catch (error) {
                setSendOtpCode(false);
                setSend(false);
                setVerifyOtpCode(false);
            }

        }
        getEmitStatus();
    }, []);

    useEffect(() => {
        async function tryGetOtpData() {
            if (!otpFilled.loading && otpFilled.data) {      
                if (otpFilled.data.estado_autorizacion_cliente === 'Reenviado') {
                    setSendOtpCode(true);
                    setVerifyOtpCode(true);
                }
            }
        }
        tryGetOtpData();
    }, [otpFilled]);

    useEffect(() => {
        async function tryGetFormData() {
            if (!formsFilled.loading && formsFilled.data && formsFilled.data.length > 0) {
                formsFilled.data.map(async (item) => {
                    const form = item.detail;
                    setFormData(form);
                });
            }
        }
        tryGetFormData();
    }, [formsFilled]);





    const sendOtp = async () => {
        setIsLoading(true);
        const datosPersonales = formData.datosPersonales;
        const dataOfac = JSON.parse(localStorage.getItem("loginListasOfac"));
        let objSendOtp = {
            data: {
                astr_tipo_id_cliente: "00",
                astr_num_id_cliente: datosPersonales.numeroIdentificacion,
                astr_codigo_agente: dataOfac.idUsuario,
                astr_codigo_ramo: "20",
                astr_codigo_producto: plan.detail.plan.astr_id_producto,
                astr_num_celular: datosPersonales.telefono,
                astr_num_email: datosPersonales.correoElectronico,
            },
        };
        const respOTP = await enviaOtp(objSendOtp);
        if (respOTP && respOTP?.astr_exitoso && respOTP?.astr_exitoso === "S") {
            viewContext.notification.show(
                `OTP enviado al celular: ${objSendOtp?.data?.astr_num_celular}`,
                "success",
                true
            );
            setSendOtpCode(true);
            const candidateOtpV2 = await saveCandidateOtp(
                "candidate_otp/" + candidateId
            );
            if (!candidateOtpV2) {
                const objCandidateOtp = {
                    candidate_id: candidateId,
                    request: objSendOtp.data,
                    response: respOTP,
                    estado_autorizacion_cliente: "Enviado",
                };
                await Submit("candidate_otp", objCandidateOtp, "POST");
            } else {
                const objCandidateOtp = {
                    request: objSendOtp.data,
                    response: respOTP,
                    estado_autorizacion_cliente: "Reenviado",
                };
                await updateCandidateOtp(
                    "candidate_otp/" + candidateId,
                    objCandidateOtp
                );
            }
        } else {
            viewContext.notification.show(`OTP no enviado: ${JSON.stringify(respOTP)}`, "error");
            setSendOtpCode(false);
        }
        setIsLoading(false);
    };

    async function editCandidate(data) {

        try {
            const result = await Submit("candidate/updateStepPrioriEmitir", data, "patch");
            const message = result?.data?.message;
            const messageObj = JSON.parse(message.substring(message.indexOf("{")));
            const status = messageObj.status;
            const resultado = messageObj.resultado;

            if (status === 200) {
                context.notification.show('Emisión resultado: ' + resultado, "success", false);
                setSend(true);
                setVerifyOtpCode(true);
            } else {
                context.notification.show('Emisión no exitosa', "error", false);
                setSend(false);
            }
        } catch (error) {
            context.notification.show('Emisión no exitosa', "error", false);
            setSend(false);
        }



        // setTimeout(async () => {
        //   window.location.reload(true);
        // }, "3000");


        // const response = res?.data?.message;

        //   const responseObject = JSON.parse(response);
        //   const resultado = responseObject.resultado;
        //   const status = responseObject.status;
        //   if (status === 200) {
        //     context.notification.show(response?.resultado ? response.resultado : 'Emisión resultado: ' + resultado, "success", false);
        //   } else {
        //     context.notification.show(response?.resultado ? response.resultado : 'Emisión no exitosa', "error", false);
        //   }
        //   window.location.reload(true);

        // context.modal.show(
        //   {
        //     title: "Emitir",
        //     form: {
        //       id: {
        //         type: "hidden",
        //         value: data.id,
        //       },
        //       secuencial: {
        //         label: "No. de solicitud",
        //         type: "text",
        //         required: true,
        //         readonly: true,
        //         value: data.secuencial,
        //       },
        //       estado: {
        //         label: "Estado",
        //         type: "radio",
        //         options: ["Emitir"],
        //         required: true,
        //         errorMessage: "Por favor selecciona una opción",
        //       },
        //     },
        //     buttonText: "Actualizar",
        //     url: "api/candidate/updateStepPrioriEmitir",
        //     method: "PATCH",
        //   },
        //   (res) => {
        //     setTimeout(async () => {
        //       let candId = res?.id?.value
        //       let curCandidate = await getCandidateById(candidateId);
        //       let idProveedor = curCandidate.data[0]?.emitesdp_request?.astr_id_proveedor;
        //       let responseBusca = await buscaEmisionSdp(idProveedor, candId);
        //       let response = JSON.parse(responseBusca?.response?.data?.message);
        //       if (responseBusca?.response?.status === 200) {
        //         context.notification.show(response?.resultado ? response.resultado : 'Emisión exitosa', "success", false);
        //       } else {
        //         context.notification.show(response?.resultado ? response.resultado : 'Emisión no exitosa', "error", false);
        //       }
        //       window.location.reload(true);
        //     }, "3000")
        //   }
        // );
    }

    const verifyOtp = async () => {

        if (sendOtpCode === false && (codigoOTP === null || codigoOTP === '')) {
            viewContext.notification.show(`Debe ingresar el código OTP`, "error");
            return;
        }
        setIsLoading(true);
        const datosPersonales = formData.datosPersonales;
        const dataOfac = JSON.parse(localStorage.getItem("loginListasOfac"));

        let data = {
            astr_tipo_id_cliente: "00",
            astr_num_id_cliente: datosPersonales.numeroIdentificacion,
            astr_codigo_agente: dataOfac.idUsuario,
            astr_codigo_ramo: "20",
            astr_codigo_producto: plan.detail.plan.astr_id_producto,
            astr_num_celular: datosPersonales.telefono,
            astr_num_email: datosPersonales.correoElectronico,
            astr_OTP: codigoOTP,
        }
        const respOTP = await Submit('bridgesdp', data, 'post');
        if (respOTP && respOTP?.data?.data?.astr_exitoso && respOTP?.data?.data?.astr_exitoso === "S") {
            viewContext.notification.show(
                'Verificación OTP exitosa',
                "success",
                true
            );
            setSendOtpCode(true);
            setVerifyOtpCode(true);
            const candidateOtpV2 = await saveCandidateOtp(
                "candidate_otp/" + candidateId
            );
            if (!candidateOtpV2) {
                const objCandidateOtp = {
                    candidate_id: candidateId,
                    request: data.data,
                    response: respOTP,
                    estado_autorizacion_cliente: "Enviado",
                };
                await Submit("candidate_otp", objCandidateOtp, "POST");
            } else {
                const objCandidateOtp = {
                    request: data.data,
                    response: respOTP,
                    estado_autorizacion_cliente: "Reenviado",
                };
                await updateCandidateOtp(
                    "candidate_otp/" + candidateId,
                    objCandidateOtp
                );
            }
            //await updateCandidate();
        } else {
            viewContext.notification.show(`Verificación OTP fallida`, "error");
            setVerifyOtpCode(false);
        }
        setIsLoading(false);
    };


    const enviar = async () => {
        const data = {
            id: candidateId,
            estado: "Emitir",
            secuencial: plan.secuencial,
        };
        setIsLoading(true);
        await editCandidate(data);
        setIsLoading(false);

    };


    // async function callCandidatePrioriEmitir() {
    //     let obj = {
    //         id: candidateId,
    //         estado: "Emitir",
    //     }
    //     return new Promise((resolve, reject) => {
    //         Submit("candidate/updateStepPrioriEmitir", obj, "patch")
    //             .then(async (value) => {                                  
    //                 resolve(value);
    //             })
    //             .catch((error) => {                    
    //                 reject(null);
    //             });
    //     });
    // }




    return (
        <Animate type="pop">
            <div className={Style.buttonPosition}>
                <BackButton go={'/vendedor/priori/cotizaciones/' + candidateId} />
            </div>
            <div style={{ marginBottom: '40px' }}>
            </div>
            <Title whiteTitle='6. Firma OTP' />
            {!isLoading && (
                <>
                    <div className={GlobalStyle.mainContainerFormWithBgTransparent}>
                        <div className={GlobalStyle.containerButtons}>
                            <input
                                type='submit'
                                value='Enviar OTP'
                                className={!sendOtpCode ? GlobalStyle.buttonFinalizar : GlobalStyle.buttonFinalizarDisabled}
                                onClick={() => sendOtp()}
                                style={{ marginTop: '8px', width: '220px' }}
                                disabled={isLoading}
                            />
                        </div>
                        <div className={GlobalStyle.inputContainerQuarter}>
                            <SwitchProduct
                                label={sendOtpCode ? 'Enviado' : 'Pendiente'}
                                name={'send_otp_code'}
                                id={'send_otp_code'}
                                checked={
                                    sendOtpCode
                                }
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className={GlobalStyle.mainContainerFormWithBgTransparent}>
                        <div className={GlobalStyle.containerButtons}>
                            <input
                                type='submit'
                                value='Verificar OTP'
                                className={((verifyOtpCode && !sendOtpCode) || codigoOTP.length !== 6) ? GlobalStyle.buttonFinalizarDisabled : GlobalStyle.buttonFinalizar}
                                onClick={() => verifyOtp()}
                                style={{ marginTop: '8px', width: '220px' }}
                            //disabled={isLoading && !sendOtpCode && !verifyOtpCode}
                            />
                        </div>
                        <div className={GlobalStyle.inputContainerQuarter}>
                            <input
                                className={GlobalStyle["input_number"]}
                                value={codigoOTP}
                                type='text'
                                onChange={(e) => {
                                    const inputText = e.target.value;
                                    const numericInput = inputText.replace(/[^0-9]/g, '');
                                    setCodigoOTP(numericInput);
                                }}
                                maxLength={6}
                                pattern="[0-9]*" // Optional: HTML pattern attribute for browsers that support it
                                placeholder="Ingresar código OTP"
                                disabled={isLoading || verifyOtpCode}
                            />
                        </div>
                    </div>

                    <div className={GlobalStyle.mainContainerFormWithBgTransparent}>
                        <div className={GlobalStyle.containerButtons}>
                            <input
                                type='submit'
                                value='Enviar'
                                className={(!verifyOtpCode || send) ? GlobalStyle.buttonFinalizarDisabled : GlobalStyle.buttonFinalizar}
                                onClick={() => enviar()}
                                style={{ marginTop: '8px', width: '220px' }}
                            //disabled={isLoading}
                            />
                        </div>
                        <div className={GlobalStyle.inputContainerQuarter}>
                            <SwitchProduct
                                label={send ? 'Enviado' : 'Pendiente'}
                                name={'final_send'}
                                id={'final_send'}
                                // onChange={(e) =>
                                //     setVerifyOtpCode(!verifyOtpCode)
                                // }
                                checked={
                                    send
                                }
                                disabled={true}
                            />
                        </div>
                    </div>



                    <div style={{ marginBottom: '20px' }}>
                    </div>
                </>
            )}
            {isLoading && (
                <div className={GlobalStyle.loadingDualRing}></div>
            )}


        </Animate>
    )
}