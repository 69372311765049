/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { Fragment, useContext } from "react";
import {
  AuthContext,
  Form,
  Card,
  Animate,
  useAPI,
} from "components/lib";
import Style from "./salesForce.module.scss";
import GlobalStyle from "../../css/globalStyle.module.scss";
import ProfileIcon from "../../assets/icons/perfil-de-usuario.svg";
import Img from "../../assets/img/fotoFormularios-1.png";
import { BackButton } from "components/backButton/button";

export function Profile(props) {
  // context
  const authContext = useContext(AuthContext);

  // fetch
  const user = useAPI("/api/user");

  return (
    <Fragment>
      {/* <AccountNav /> */}
      <Animate>
        <div className={GlobalStyle.buttonPosition}>
          <BackButton go={"/account"} />
        </div>
        <div className={Style.section}>
          <p className={Style.textTitle}>Actualizar perfil</p>
          <div className={Style.mainContainer}>
            <div className={Style.centerCard}>
              <div className={Style.centerCardInside}>
                <div className={Style.centerCardInsideLeft}>
                  <img
                    className={Style.imgRounded}
                    alt='logo perfil'
                    src={Img}
                  />
                </div>
                <div className={Style.centerCardInsideRight}>
                  <img
                    className={Style.imgPadding}
                    alt='logo perfil'
                    src={ProfileIcon}
                  />
                  <Card loading={user.loading} restrictWidth>
                    <h2 className={Style.subtitle}>Consulta tu Perfil</h2>
                    <hr className={Style.divider} />
                    {user?.data && (
                      <Form
                        // buttonText='Guardar'
                        url='/api/user'
                        method='PATCH'
                        data={{
                          nombre: {
                            label: "Nombre",
                            type: "text",
                            value: user.data.nombre,
                            readonly: true,
                            errorMessage: "Por favor ingresa tu nombre",
                          },
                          apellido: {
                            label: "Apellido",
                            type: "text",
                            value: user.data.apellido,
                            readonly: true,
                            errorMessage: "Por favor ingresa tu apellido",
                          },
                          email: {
                            label: "Correo electrónico",
                            type: "email",
                            required: true,
                            value: user.data.email,
                            readonly: true,
                            errorMessage:
                              "Por favor ingresa tu dirección de correo",
                          },
                          ...(user.data.permission === "owner" && {
                            account_name: {
                              type: "text",
                              label: "Account Name",
                              value: user.data.account_name,
                            },
                          }),
                          ...(user.data.accounts?.length > 1 && {
                            default_account: {
                              label: "Default Account",
                              type: "select",
                              default: user.data.default_account,
                              options: user.data.accounts.map((x) => {
                                return {
                                  value: x.id,
                                  label: x.name,
                                };
                              }),
                            },
                          }),
                        }}
                        callback={(res) => {
                          // update the account name
                          if (authContext.user?.accounts?.length > 0) {
                            const accounts = [...authContext.user.accounts];
                            accounts[
                              accounts.findIndex(
                                (x) => x.id === authContext.user.account_id
                              )
                            ].name = res.data.data.account_name;
                            authContext.update({ accounts: accounts });
                          }

                          // update the user name
                          authContext.update({ name: res.data.data.name });
                        }}
                      />
                    )}
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Animate>
    </Fragment>
  );
}
