import {
  getBrokers,
  getSalesForces,
  getSponsor,
} from "components/bridges/BridgeServices";
import {
  SwitchProduct,
  useLocation
} from "components/lib";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { colourStyles } from "../../../css/globalStyles";
import Style from "./salesForce.module.scss";


Modal.setAppElement("#root");

export function SalesForce(props) {
  const { salesForce, setSalesForce, style } = props;
  const customStyle = style ? style : Style.salesForce;
  const [dataList, setDataList] = useState();
  const [tableList, setTableList] = useState();
  const [buttonModal, setButtonModal] = useState({
    broker: false,
    sponsor: false,
    sdp: false,
  });
  let url = useLocation();
  let vecUrl = url.pathname.split("/");
  let id = "";
  if (vecUrl.length > 3) {
    id = vecUrl[3];
  }

  const onChangeValue = (name, value) => {
    setButtonModal({ ...buttonModal, [name]: value });
    setSalesForce({ ...salesForce, [name]: [] });
  };

  const fillData = async () => {
    let tempTable = { brokers: [], sponsors: [], salesforce: [] };
    if (dataList) {
      dataList.brokers.map((broker) => {
        tempTable.brokers.push({
          astr_num_id_asesor: broker.astr_num_id_asesor,
          astr_nombre_asesor: broker.astr_nombre_asesor,
          astr_tipo_id_asesor: broker.astr_tipo_id_asesor,
          label: broker.astr_nombre_asesor,
          value: broker.astr_num_id_asesor,
        });
      });

      dataList.sponsors.map((sponsor) => {
        tempTable.sponsors.push({
          astr_num_id_asesor: sponsor.astr_num_id_asesor,
          astr_nombre_asesor: sponsor.astr_nombre_asesor,
          astr_tipo_id_asesor: sponsor.astr_tipo_id_asesor,
          label: sponsor.astr_nombre_asesor,
          value: sponsor.astr_num_id_asesor,
        });
      });

      dataList.salesForce.map((saleforce) => {
        tempTable.salesforce.push({
          astr_num_id_asesor: saleforce.astr_num_id_asesor,
          astr_nombre_asesor: saleforce.astr_nombre_asesor,
          astr_modelo: saleforce.astr_modelo,
          astr_codigo_usuario: saleforce.astr_codigo_usuario,
          astr_origen: saleforce.astr_origen,
          astr_submodelo: saleforce.astr_submodelo,
          astr_tipo_id_asesor: saleforce.astr_tipo_id_asesor,
          label: saleforce.astr_nombre_asesor,
          value: saleforce.astr_num_id_asesor,
        });
      });

      setTableList(tempTable);
    }
  };

  const getDataList = async () => {
    try {
      let tempDataList = {};
      const responseSponsor = await getSponsor();
      tempDataList.sponsors = responseSponsor;
      const responseBrokers = await getBrokers();
      tempDataList.brokers = responseBrokers;
      const responseSDP = await getSalesForces();
      tempDataList.salesForce = responseSDP;
      setDataList(tempDataList);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeBroker = (e) => {
    const salesForceList = { ...salesForce };
    salesForceList.broker = e;
    setSalesForce(salesForceList);
  };

  const handleChangeSponsor = (e) => {
    const salesForceList = { ...salesForce };
    salesForceList.sponsor = e;
    setSalesForce(salesForceList);
  };

  const handleChangeSDP = (e) => {
    const salesForceList = { ...salesForce };
    salesForceList.sdp = e;
    setSalesForce(salesForceList);
  };

  useEffect(() => {
    let tempButton = {
      broker: salesForce?.broker?.length > 0 ? true : false,
      sponsor: salesForce?.sponsor?.length > 0 ? true : false,
      sdp: salesForce?.sdp?.length > 0 ? true : false,
    };
    setButtonModal(tempButton);
  }, []);

  useEffect(() => {
    fillData();
  }, [dataList]);

  useEffect(() => {
    getDataList();
  }, []);

  return (
    <div style={customStyle}>
      <div style={{ display: "flex", padding: 10 }}>
        <div>
          <SwitchProduct
            name={"broker"}
            label={"Broker"}
            onChange={onChangeValue}
            checked={buttonModal.broker}
          />
        </div>
        <div style={{ display: "flex", position: "relative", left: "100px" }}>
          {buttonModal.broker && (
            <div style={{ display: "flex" }}>
              <div>
                <Select
                  value={salesForce.broker}
                  isMulti
                  // defaultValue={defaultProduct}
                  styles={colourStyles}
                  options={tableList && tableList.brokers}
                  isSearchable
                  // isLoading={optionsCodeProducts.length === 0 ? true : false}
                  placeholder={"Seleccionar"}
                  menuPosition='fixed'
                  menuPlacement={"auto"}
                  onChange={handleChangeBroker}
                // isDisabled={id.localeCompare("") === 0 ? false : true}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "flex", padding: 10 }}>
        <div>
          <SwitchProduct
            name={"sponsor"}
            label={"Sponsor"}
            onChange={onChangeValue}
            checked={buttonModal.sponsor}
          />
        </div>
        <div style={{ display: "flex", position: "relative", left: "100px" }}>
          {buttonModal.sponsor && (
            <div style={{ display: "flex" }}>
              <div>
                <Select
                  value={salesForce.sponsor}
                  isMulti
                  // defaultValue={defaultProduct}
                  styles={colourStyles}
                  options={tableList && tableList.sponsors}
                  isSearchable
                  placeholder={"Seleccionar"}
                  menuPosition='fixed'
                  menuPlacement={"auto"}
                  onChange={handleChangeSponsor}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "flex", padding: 10 }}>
        <div>
          <SwitchProduct
            name={"sdp"}
            label={"Fuerza de venta SDP"}
            onChange={onChangeValue}
            checked={buttonModal.sdp}
          />
        </div>
        <div style={{ display: "flex", position: "relative", left: "100px" }}>
          {buttonModal.sdp && (
            <div style={{ display: "flex" }}>
              <div>
                <Select
                  value={salesForce.sdp}
                  isMulti
                  // defaultValue={defaultProduct}
                  styles={colourStyles}
                  options={tableList && tableList.salesforce}
                  isSearchable
                  placeholder={"Seleccionar"}
                  menuPosition='fixed'
                  menuPlacement={"auto"}
                  onChange={handleChangeSDP}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
