import React, { Fragment, useState, useEffect } from "react";
import { Loader, Icon, Table, Button, useAPI } from "components/lib";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import Style from "./byCycle.module.scss";
import { SetViewCandidateOther } from "utils/getPermissionsOther";
import useViewPort from "../../../hooks/useViewPort";
import { Scrollbars } from "react-custom-scrollbars-2";

export function ByCycle(props) {
  const rawUser = JSON.parse(localStorage.getItem('profile'))
  const userPermissions = JSON.parse(localStorage.getItem("user_permissions"));
  const { agriculture, setAgriculture } = props;
  const [data, setData] = useState();
  const [screenSize] = useViewPort();
  const [dateRange, onChange] = useState([new Date(), new Date()]);
  const [newInput, setNewInput] = useState({
    nameCycle: "",
    dateBegin: "",
    dateEnd: "",
    state: true,
    cultivations: "",
    description: "",
  });

  const inputData = {
    header: [
      { name: "nombre_ciclo", title: "Nombre del ciclo", sort: false },
      {
        name: "fecha_duracion",
        title: "Fecha de duración del ciclo",
        sort: false,
      },
      { name: "cultivos", title: "Cultivos", sort: false },
      { name: "observaciones", title: "Observaciones", sort: false },
    ],
    body: [
      {
        nombre_ciclo: (
          <input
            style={{ width: "100%", border: "1px solid black" }}
            type='text'
            name='nameCycle'
            value={newInput.nameCycle}
            onChange={(e) => handleInputDataChange(e)}
          />
        ),
        fecha_duracion: (
          <DateRangePicker onChange={onChange} value={dateRange} required />
        ),
        cultivos: <p>{agriculture.cultivationSelected}</p>,
        observaciones: (
          <input
            style={{ width: "100%", border: "1px solid black" }}
            type='text'
            name='description'
            value={newInput.description}
            onChange={(e) => handleInputDataChange(e)}
          />
        ),
      },
    ],
  };

  const handleChangeCultivations = (e, indexData, nameSection) => {
    const { value } = e.target;
    const agricultureList = { ...agriculture };
    agricultureList[nameSection] = value;
    setAgriculture(agricultureList);
  };

  const handleInputDataChange = (e) => {
    const { name, value } = e.target;
    const newInputTemp = { ...newInput };
    newInputTemp.cultivations = agriculture.cultivationSelected;

    const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;

    if (
      name === "nameCycle" ||
      name === "cultivations" ||
      name === "description"
    ) {
      if (value === '' || alphanumericExtendedRegex.test(value)) {
        newInputTemp[name] = value;
        setNewInput(newInputTemp);
      }
    }

  };

  const handleDeleteCultivation = (indexCultivation) => {
    const agricultureList = { ...agriculture };
    agricultureList.plansCultivation.map((item) => {
      if (item.name === agricultureList.cultivationSelected) {
        item.cultivationCycle.splice(indexCultivation, 1);
      }
    });
    setAgriculture(agricultureList);
  };

  const validateDate = (dateBegin, dateEnd) => {
    let dateBeginTemp = new Date(dateBegin);
    let dateEndTemp = new Date(dateEnd);
    let todayDate = new Date();

    if (
      dateBeginTemp.getTime() <= todayDate.getTime() &&
      dateEndTemp.getTime() >= todayDate.getTime()
    ) {
      return true;
    }

    return false;
  };

  const saveCycle = () => {
    const agricultureTemp = { ...agriculture };
    const indexCultivation = agricultureTemp.plansCultivation.findIndex(
      (object) => object.name === newInput.cultivations
    );
    const tempState = validateDate(newInput.dateBegin, newInput.dateEnd);
    newInput.state = tempState;

    // let tempState = ;
    // newInput.state = tempState;
    if (indexCultivation === -1) {
      agricultureTemp.plansCultivation.push({
        name: newInput.cultivations,
        cultivationPlan: [],
        cultivationCycle: [newInput],
        cultivationValueInsured: [],
      });
    } else {
      agricultureTemp.plansCultivation[indexCultivation].cultivationCycle.push(
        newInput
      );
    }
    setAgriculture(agricultureTemp);
  };

  const getPlanByName = (cultivationPlan) => {
    return cultivationPlan.filter(
      (item) => item.name === agriculture.cultivationSelected
    );
  };

  const makeDataTable = (cultivationCycle) => {
    let tempDataTable = {
      header: [
        { name: "nombre_ciclo", title: "Nombre del ciclo", sort: false },
        {
          name: "fecha_inicio",
          title: "Fecha de inicio del ciclo",
          sort: false,
        },
        {
          name: "fecha_finalización",
          title: "Fecha de finalización del ciclo",
          sort: false,
        },
        { name: "estado", title: "Estado", sort: false },
        { name: "cultivos", title: "Cultivos", sort: false },
        { name: "observaciones", title: "Observaciones", sort: false },
      ],
      body: [],
    };
    let tempObjectCultivation = {};
    let newCultivationArray = getPlanByName(cultivationCycle);
    let nameSection = "cultivationCycle";
    if (newCultivationArray.length > 0) {
      newCultivationArray[0][nameSection].map(
        (cultivation, indexCultivation) => {
          tempObjectCultivation.nombre_ciclo = cultivation.nameCycle;
          tempObjectCultivation.fecha_inicio =
            cultivation.dateBegin.split(" ")[0];
          tempObjectCultivation.fecha_finalización =
            cultivation.dateEnd.split(" ")[0];
          tempObjectCultivation.estado = cultivation.estado;
          tempObjectCultivation.cultivos = cultivation.cultivations;
          tempObjectCultivation.observaciones = (
            <div style={{ display: "flex" }}>
              <p>{cultivation.description}</p>
              <button
                style={{ padding: 5 }}
                onClick={(e) => handleDeleteCultivation(indexCultivation)}
              >
                <Icon image={"minus"} size={18} />
              </button>
            </div>
          );
          tempDataTable.body.push(tempObjectCultivation);
          tempObjectCultivation = {};
        }
      );
    }
    setData(tempDataTable);
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date) => {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  };

  useEffect(() => {
    makeDataTable(agriculture.plansCultivation);
  }, [agriculture]);

  useEffect(() => {
    if (dateRange !== null) {
      let tempDate = formatDate(new Date());
      let tempDateRange2 = formatDate(dateRange[1]);
      let tempDateRange1 = formatDate(dateRange[0]);
      if (tempDateRange2 !== tempDate && tempDateRange1 < tempDateRange2) {
        setNewInput({
          ...newInput,
          dateBegin: formatDate(dateRange[0]),
          dateEnd: formatDate(dateRange[1]),
        });
      } else {
        setNewInput({
          ...newInput,
          dateBegin: "",
          dateEnd: "",
        });
      }
    }
  }, [dateRange]);

  return (
    <div className={Style.byCycle}>
      {screenSize.dynamicWidth > 1024 ? (
        <>
          <div style={{ width: "50%" }}>
            <label>Cultivo</label>
            <select
              style={{ width: "150px" }}
              name={"cultivations"}
              id="cultivations"
              onChange={(e) =>
                handleChangeCultivations(e, "", "cultivationSelected")
              }
              value={
                agriculture.cultivationSelected !== "" &&
                agriculture.cultivationSelected
              }
            >
              {agriculture.cultivations.map((option, i) => {
                return (
                  <option
                    key={`${option.nombre_del_cultivo}${option.tipo_del_cultivo}${option.vigencia}Options${i}`}
                    value={option.nombre_del_cultivo}
                  >
                    {option.nombre_del_cultivo}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ width: "100%" }}>
            <Table data={inputData} />
            <div style={{ paddingTop: 25 }}>
              {SetViewCandidateOther(
                rawUser,
                userPermissions,
                ["c", "u"],
                "confprod"
              ) && (
                  <Button
                    color='blue'
                    action={saveCycle}
                    text='Guardar ciclo'
                    disabled={
                      (newInput.cultivations === "" &&
                        newInput.cultivations !== "Selecciona un cultivo") ||
                        newInput.dateBegin === "" ||
                        newInput.dateEnd === "" ||
                        newInput.description === "" ||
                        newInput.nameCycle === ""
                        ? true
                        : false
                    }
                  />
                )}
            </div>
          </div>
          <div>
            <Table data={data} />
          </div>
        </>
      ) : (
        <Scrollbars
          style={{
            width: "100%",
            height: "600px",
          }}
        >
          <div style={{ width: "50%" }}>
            <label>Cultivo</label>
            <select
              style={{ width: "150px" }}
              name={"cultivations"}
              id="cultivations"
              onChange={(e) =>
                handleChangeCultivations(e, "", "cultivationSelected")
              }
              value={
                agriculture.cultivationSelected !== "" &&
                agriculture.cultivationSelected
              }
            >
              {agriculture.cultivations.map((option, i) => {
                return (
                  <option
                    key={`${option.nombre_del_cultivo}${option.tipo_del_cultivo}${option.vigencia}Options${i}`}
                    value={option.nombre_del_cultivo}
                  >
                    {option.nombre_del_cultivo}
                  </option>
                );
              })}
            </select>
          </div>
          <div style={{ width: "100%" }}>
            <Table data={inputData} />
            <div style={{ paddingTop: 25 }}>
              {SetViewCandidateOther(
                rawUser,
                userPermissions,
                ["c", "u"],
                "confprod"
              ) && (
                  <Button
                    color='blue'
                    action={saveCycle}
                    text='Guardar ciclo'
                    disabled={
                      (newInput.cultivations === "" &&
                        newInput.cultivations !== "Selecciona un cultivo") ||
                        newInput.dateBegin === "" ||
                        newInput.dateEnd === "" ||
                        newInput.description === "" ||
                        newInput.nameCycle === ""
                        ? true
                        : false
                    }
                  />
                )}
            </div>
          </div>
          <div>
            <Table data={data} />
          </div>
        </Scrollbars>
      )}
    </div>
  );
}
