import React, { useContext, useState } from "react";
import { MenuCard, Button, Animate, ViewContext, useAPI } from "components/lib";
import { postCandidates, Submit } from "components/bridges/BridgeServices";
import {
  Box,
  FileInput,
  Grid as GridGroomet,
  grommet,
  Heading,
  Paragraph,
  ResponsiveContext,
  ThemeContext,
} from "grommet";
import people from "components/logo/images/menu/Usuarios.svg";
import document from "components/logo/images/menu/productosCreados.svg";
import Style from "./agricultureSeller.module.scss";
import { deepMerge } from "grommet/utils";

const customFile = deepMerge(grommet, {
  fileInput: {
    background: "white",
  }
});

const columns = {
  small: ["auto"],
  medium: ["auto", "auto"],
  large: ["auto", "auto"],
  xlarge: ["auto", "auto"],
};

const rows = {
  small: ["auto", "auto"],
  medium: ["auto", "auto"],
  large: ["auto", "auto"],
  xlarge: ["auto", "auto"],
};

// set the different areas you need for every size
const areas = {
  small: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [0, 1], end: [0, 1] },
    { name: "side3", start: [0, 1], end: [1, 0] },
  ],
  medium: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
  large: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
  xlarge: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
};

// set the different areas you need for every size
const ResponsiveGrid = ({
  children,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {(size) => {
      // take into consideration if not array is sent but a simple string
      let columnsVal = columns;
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size];
        }
      }

      let rowsVal = rows;
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size];
        }
      }

      // also if areas is a simple array not an object of arrays for different sizes
      let areasVal = areas;
      if (areas && !Array.isArray(areas)) areasVal = areas[size];

      return (
        <GridGroomet
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
          justify='center'
        >
          {children}
        </GridGroomet>
      );
    }}
  </ResponsiveContext.Consumer>
);

export function AgricultureSeller(props) {
  const [file, setFile] = useState();
  const viewContext = useContext(ViewContext);
  const [dataError, setDataError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const rawUser = JSON.parse(localStorage.getItem('profile'))


  const setViewCandidate = () => {
    let isView = false;
    if (rawUser.data && rawUser.data.flag_uno) {
      isView = true;
    } else {
      let tmpUserPermissions = localStorage.getItem('user_permissions')
      if (tmpUserPermissions) {
        let jsonUserPermissions = JSON.parse(tmpUserPermissions);
        Object.keys(jsonUserPermissions.agro.candidato).map((key) => {
          if (key === "c") {
            if (jsonUserPermissions.agro.candidato[key]) {
              isView = true;
            }
          }
        });
      }
    }
    return isView;
  };

  const uploadFileForm = async (fileData) => {
    setIsLoading(true);
    let obj = {
      file: fileData.file,
      product_type: fileData.product_type,
      email: "fernando.murillo@kinanalytics.com",
    };

    try {
      await postCandidates(obj).then((resp) => {
        viewContext.notification.show(resp.data.message, "success", true);
        setDataError("");
        setIsLoading(false);
      });
    } catch (error) {
      let strBackResponse = error?.response?.data?.data?.message;
      let frontMessage = "";
      if (!strBackResponse) frontMessage = "Error desconocido";
      else frontMessage = strBackResponse;
      viewContext.notification.show(frontMessage, "error", false);
      setDataError(frontMessage);
      setIsLoading(false);
    }
  };

  return (
    <Animate type='pop'>
      <div className={Style.mainContainer}>
        <Box
          justify='center'
          align='center'
          style={{
            paddingTop: "75px",
            maxHeight: 'size.indexOf("small") > -1 ? "80vh" : "80vh"',
          }}
        >
          <ResponsiveGrid
            style={{
              zIndex: 1,
            }}
            height='70%'
            rows={rows}
            columns={columns}
            gap='small'
            areas={areas}
            margin='small'
          >
            {setViewCandidate() ? (
              <>
                <Box gridArea='side1' className={Style.boxContainer}>
                  <MenuCard
                    name='revenues'
                    title='Candidatos'
                    goto='/vendedor/agricola/candidato'
                    icon={people}
                  />
                </Box>
                <Box
                  gridArea='side2'
                  align='start'
                  className={Style.boxContainer}
                >
                  <MenuCard
                    center
                    name='revenues'
                    title='Mis cotizaciones'
                    goto='/vendedor/agricola/cotizaciones'
                    icon={document}
                  />
                </Box>
                <Box align='center'>
                  <Box>
                    <br />
                    <Heading
                      textAlign='start'
                      color='white'
                      level={4}
                      margin='none'
                    >
                      Carga Masiva
                    </Heading>
                    <br />
                    {!isLoading ? (
                      <ThemeContext.Extend value={customFile}>
                        <FileInput
                          messages={{
                            browse: "Buscar",
                            dropPrompt: "Arrastra aquí o selecciona el archivo",
                            files: "Archivos",
                            remove: "Eliminar",
                            removeAll: "Eliminar todos",
                            maxFile:
                              "Adjunte un máximo de {max} archivos solamente.",
                          }}
                          accept='.xlsx, .xls, .csv'
                          multiple={false}
                          name='file'
                          onChange={(e) => {
                            setFile({
                              title: e.target.files[0],
                            });
                          }}
                        />
                      </ThemeContext.Extend>
                    ) : (
                      <div className={Style.ldsDualRing}></div>
                    )}
                    <br />
                    <div>
                      {file !== undefined && (
                        <Button
                          applyColor='#EAF7FB'
                          applyTextColor='#0f265c'
                          text='Cargar'
                          action={() =>
                            uploadFileForm({
                              product_type: "agro",
                              file: file.title,
                            })
                          }
                        />
                      )}
                    </div>
                    <br />
                  </Box>
                </Box>
              </>
            ) : (
              <Box gridArea='side1' className={Style.boxContainer}>
                <MenuCard
                  center
                  name='revenues'
                  title='Mis cotizaciones'
                  goto='/vendedor/agricola/cotizaciones'
                  icon={document}
                />
              </Box>
            )}
          </ResponsiveGrid>
          <Box gridArea='msg' align='center'>
            {dataError !== "" && (
              <Paragraph
                style={{
                  color: "#FAFAFA",
                  fontSize: "14px",
                  zIndex: "10",
                  paddingLeft: "10px",
                  textAlign: "justify",
                }}
              >${dataError}</Paragraph>
            )}
          </Box>
        </Box>
        <div className={Style.blockMenu}></div>
      </div>
    </Animate>
  );

}
