import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { BackButton } from "components/backButton/button";
import { getFilterDataPricesV2 } from "components/bridges/BridgeServices";
import {
  Animate,
  Button,
  Content,
  Table,
  ViewContext,
  useNavigate
} from "components/lib";
import { useContext, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { getCandidateAgroCsvList } from "../../components/bridges/BridgeServices";
import "../../css/DateRangePicker.css";
import Style from "../../css/globalStyle.module.scss";

export function PriceAgricultureQualification(props) {
  const context = useContext(ViewContext);
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [dataSeach, setDataSearch] = useState({
    number: "",
    id: "",
    dateBegin: "",
    dateEnd: "",
    date: "",
    secuencial: "",
    channel: "",
  });
  const [allCandidates, setAllCandidates] = useState([]);
  const [canEdit, setCanEdit] = useState(true);
  const [loadingTable, setLoadingTable] = useState(false);


  useEffect(() => {
    const user_permissions = JSON.parse(localStorage.getItem("user_permissions"));
    const agro = user_permissions.agro.calificacion;
    setCanEdit(agro.u);//update ermission
  }, [])

  function validarFechas(obj) {
    if (!obj.fecha_desde || !obj.fecha_hasta) {
      return false;
    }
    let fechaDesde = new Date(obj.fecha_desde);
    let fechaHasta = new Date(obj.fecha_hasta);
    if (isNaN(fechaDesde.getTime()) || isNaN(fechaHasta.getTime())) {
      return false;
    }
    // Verificar si la diferencia entre las fechas es menor o igual a 6 meses
    let seisMesesEnMilisegundos = 2 * 30 * 24 * 60 * 60 * 1000;
    if (Math.abs(fechaHasta - fechaDesde) > seisMesesEnMilisegundos) {
      return false;
    }
    return true;
  }

  const handleSearchData = async () => {

    const user = JSON.parse(localStorage.getItem("profile"));
    const isSuperAdmin = user.data.flag_uno === true;

    let obj = {
      nro_documento: dataSeach.id,
      fecha_desde: dataSeach.dateBegin,
      fecha_hasta: dataSeach.dateEnd,
      product_type: "agro",
      secuencial: dataSeach.secuencial,
      canal: dataSeach.channel,
    };

    if (obj.secuencial === '') {
      if (!validarFechas(obj)) {
        context.notification.show("Las fechas no son válidas.", "error", true);
        return;
      }
    }

    setLoadingTable(true);

    await getFilterDataPricesV2(obj, isSuperAdmin).then((res) => {
      let serverCode = res?.status;
      if (serverCode === 200) {
        let respCandidates = res?.data?.data;
        let candidates = [];
        respCandidates.map(async (item) => {
          if (item.step_two_request !== null) {
            candidates.push(item);
          }
        });
        setAllCandidates(candidates);
        setLoadingTable(false);
      } else {
        setAllCandidates([]);
        setLoadingTable(false)
        let tmpMessage = res?.response?.data?.message ? res?.response?.data?.message : 'Algo salió mal'
        context.notification.show(tmpMessage, "error", true);
      }
    });

  };

  const parseDate = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleDate = (date) => {
    if (date === null) {
      setDataSearch({
        ...dataSeach,
        date: null,
      });
    } else {
      setDataSearch({
        ...dataSeach,
        dateBegin: parseDate(date[0]),
        dateEnd: parseDate(date[1]),
        date,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const alphanumericExtendedRegex = /^[0-9 ]+$/;
    if (value === '' || alphanumericExtendedRegex.test(value)) {
      setDataSearch({ ...dataSeach, [name]: value });
    }
  };

  async function generateCsvList() {
    let secuencial = allCandidates
      .map(function (elem) {
        return elem.secuencial;
      })
      .join("-");
    let respCsv = await getCandidateAgroCsvList(secuencial, "agro");
    window.location.href = respCsv?.data;
  }

  async function anulateCandidate(item) {
    context.modal.show(
      {
        title: "Anular Cotización",
        form: {
          id: {
            type: "hidden",
            value: item.id,
          },
        },
        buttonText: "Anular Cotización",
        text: `Estas seguro de que deseas anular la cotización para asegurar el cultivo de ${item.product.cultivationInfo.cultivo_Asegurar} con una superficie de ${item.product.cultivationInfo.superficie} hectáreas del candidato: ${item.product.candidateInfo.primer_nombre} ${item.product.candidateInfo.apellido_paterno}?`,
        url: "/api/candidate/cancelAgroEmision",
        method: "PATCH",
        destructive: true,
      },
      (res) => {
        context.notification.show(
          `${data.email}', se ha anulado la cotización con éxito`,
          "success",
          true
        );
        handleSearchData();
      }
    );
  }

  const makeDataTable = () => {
    let tempDataTable = {
      header: [
        { name: "detail", title: "Detalles", sort: false },
        { name: "actionsPrice", title: "Acciones", sort: false },
        { name: "numberSolicitud", title: "No. de solicitud", sort: false },
        { name: "ci", title: "Cédula", sort: false },
        {
          name: "fechaIngreso",
          title: "Fecha ingreso de solicitud",
          sort: false,
        },
        { name: "estado", title: "Estado", sort: false },
        { name: "cultivo", title: "Cultivo", sort: false },
        { name: "hectareas", title: "Hectáreas", sort: false },
        { name: "montoAsegurado", title: "Suma asegurada", sort: false },
        { name: "primaTotal", title: "Prima total", sort: false },
        { name: "poliza", title: "Póliza", sort: false },
        { name: "factura", title: "Factura", sort: false },
        { name: "plan", title: "Plan", sort: false },
        { name: "vigencia", title: "Vigencia", sort: false },
        { name: "observaciones", title: "Observaciones", sort: false },
      ],
      body: [],
    };
    let tempObjectCultivation = {};

    allCandidates.map((item, idx) => {
      tempObjectCultivation.detail = item;
      const rawUser = JSON.parse(localStorage.getItem('profile'));
      const isSuperAdmin = rawUser.data.flag_uno === true;
      const userCanal = rawUser?.data?.intermediary?.sponsor.astr_nombre_asesor || rawUser?.data?.intermediary?.broker.astr_nombre_asesor || rawUser?.data?.intermediary?.sdp.astr_nombre_asesor;
      const itemCanal = item.canal;
      if (userCanal === itemCanal || isSuperAdmin) {
        if (
          item?.estado === "Emitido"
        ) {
          tempObjectCultivation.actionsPrice = (
            <>
              <div style={{ display: "flex space-x-4" }}>
                <Button
                  icon='eye'
                  name='view'
                  action={() => {
                    navigate(`/vendedor/agricola/cotizaciones/${item.id}`, {
                      state: item,
                    });
                  }}
                  tooltip='Ver cotización'
                />
                {
                  canEdit && (
                    <Button
                      icon='slash'
                      name='Anulate'
                      action={() => anulateCandidate(item)}
                      tooltip='Anular cotización'
                    />
                  )
                }

              </div>

            </>
          );
        }
        else if (
          (item?.estado === "Aprobado Automático" ||
            item?.estado === "Aprobado Manual" ||
            item?.estado === "Aprobar" ||
            item?.estado === "En Proceso" ||
            item?.estado === "Error") &&
          item?.estado !== "Emitido" && item?.estado !== "Pendinete"
        ) {
          tempObjectCultivation.actionsPrice = (
            <>
              <div style={{ display: "flex space-x-4" }}>
                <Button
                  icon='eye'
                  name='view'
                  action={() => {
                    navigate(`/vendedor/agricola/cotizaciones/${item.id}`, {
                      state: item,
                    });
                  }}
                  tooltip='Ver cotización'
                />
              </div>

            </>
          );
        } else {
          tempObjectCultivation.actionsPrice = <div></div>;
        }
        tempObjectCultivation.numberSolicitud = (
          <div>{item.secuencial.toString().padStart(4, "0")}</div>
        );
        tempObjectCultivation.ci = <div>{item.nro_documento}</div>;
        tempObjectCultivation.fechaIngreso = (
          <div>{item.created_at.split("T")[0]}</div>
        );
        if (item?.estado === "Aprobar") {
          tempObjectCultivation.estado = (
            <div style={{ fontWeight: "bold" }}>Aprobado Manual</div>
          );
        } else if (item?.estado === "Negar") {
          tempObjectCultivation.estado = (
            <div style={{ fontWeight: "bold" }}>Negado</div>
          );
        } else {
          tempObjectCultivation.estado = (
            <div style={{ fontWeight: "bold" }}>{item?.estado}</div>
          );
        }
        tempObjectCultivation.cultivo = (
          <div>
            {item?.step_two_request?.description?.cultivo_Asegurar?.toUpperCase()}
          </div>
        );
        tempObjectCultivation.hectareas = (
          <div style={{ textAlign: "right" }}>
            {item?.step_two_request === null
              ? ""
              : parseFloat(
                item?.step_two_request?.description?.superficie
              )?.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
          </div>
        );
        tempObjectCultivation.montoAsegurado = (
          <div style={{ textAlign: "right" }}>
            {`$${item?.step_two_response?.description?.valorAsegurado?.toLocaleString(
              "en-US",
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}`}
          </div>
        );
        tempObjectCultivation.primaTotal = (
          <div style={{ textAlign: "right" }}>
            {item?.estado === "Negar"
              ? ""
              : `${item?.step_two_response?.description?.prima !== undefined
                ? "$"
                : ""
              }${item?.step_two_response?.description?.prima !== undefined
                ? `${item?.step_two_response?.description?.prima?.toLocaleString(
                  "en-US",
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}`
                : ``
              }`}
          </div>
        );
        tempObjectCultivation.poliza = (
          <div style={{ textAlign: "right" }}>
            {item?.buscaemisionsdp_response?.astr_poliza}
          </div>
        );
        tempObjectCultivation.factura = (
          <div style={{ textAlign: "right" }}> </div>
        );
        tempObjectCultivation.plan = (
          <div>
            {item?.step_two_request?.description?.plan?.namePlan}
          </div>
        );
        tempObjectCultivation.vigencia = (
          <div>
            {item?.step_two_request?.description?.fecha_siembra_vigencia}
          </div>
        );
        tempObjectCultivation.observaciones = (
          <div>
            {item?.estado === "Pendiente"
              ? item?.step_two_response?.description?.observaciones
              : item?.rules?.observaciones}
          </div>
        );

        tempDataTable.body.push(tempObjectCultivation);
        tempObjectCultivation = {};
      }

    });

    setData(tempDataTable);
  };

  useEffect(() => {
    makeDataTable();
  }, [allCandidates]);

  /*useEffect(() => {
    if (rawUser) {
      getInvite();
    }
  }, [rawUser]);*/

  return (
    <Animate type='pop'>
      <div className={Style.buttonPosition}>
        <BackButton go={-1} />
      </div>
      <section className={Style.paddingLeft}>
        <Content>
          <div style={{ padding: "15px 0px" }}>
            <p className={Style.textTitle}>Calificación agrícola</p>
          </div>
          <div style={{ paddingBottom: 20 }}>
            <div className={Style.qualificationInputSection}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Número de solicitud:</label>
                <input
                  className={Style["input"]}
                  value={dataSeach.secuencial}
                  name='secuencial'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Canal:</label>
                <input
                  className={Style["input"]}
                  value={dataSeach.channel}
                  name='channel'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Número de documento:</label>
                <input
                  className={Style["input"]}
                  value={dataSeach.id}
                  name='id'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Fecha ingreso de solicitud:</label>
                <DateRangePicker onChange={handleDate} value={dataSeach.date} />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Button color='blue' text='Buscar' action={handleSearchData} />
              </div>
            </div>
          </div>
          {loadingTable ? (
            <div className={Style.ldsDualRingContainer}>
              <div className={Style.ldsDualRing}></div>
            </div>
          ) : (<>
            <div>
              <Scrollbars
                style={{
                  width: "100%",
                  height: "600px",
                }}
              >
                <Table
                  id='table-Cotizaciones'
                  show={[
                    "actionsPrice",
                    "numberSolicitud",
                    "ci",
                    "fechaIngreso",
                    "estado",
                    "cultivo",
                    "hectareas",
                    "montoAsegurado",
                    "primaTotal",
                    "poliza",
                    "plan",
                    "vigencia",
                    "observaciones",
                  ]}
                  data={data}
                  loading={Object.entries(data).length === 0 ? true : false}
                // actions={{ edit: viewProduct }}
                />
              </Scrollbars>
            </div>
            <div style={{ padding: "20px 0px", textAlign: "right" }}>
              {allCandidates.length > 0 ? (
                <Button
                  color='blue'
                  text='Descargar Tabla'
                  action={generateCsvList}
                />
              ) : (
                <p></p>
              )}
            </div>
          </>)}
        </Content>
      </section>
    </Animate>
  );
}
