import React from "react";
import Style from "../candidate.module.scss";
import { Animate, Button, ViewContext } from "components/lib";
import { formatNumber } from "utils/formatNumber";
import { postFillFormMultiple } from "components/bridges/BridgeServices";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Submit } from "../../../../components/bridges/BridgeServices";
import { Scrollbars } from "react-custom-scrollbars-2";
import Input from "../../../../css/globalStyle.module.scss";

export function StepThree(props) {
  const [sentMail, setSentMail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const viewContext = useContext(ViewContext);
  const userProfile = JSON.parse(localStorage.getItem("profile")).data;
  const {
    plan,
    dataProjection,
    saveCandidate,
    setStep,
    client,
    idForm,
    setIdForm,
  } = props;

  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0");
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  const formatToCurrency = (strNumber) => {
    return Number(strNumber)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const getExecutiveType = async () => {
    return new Promise(async (resolve) => {
      let typeExecutive = "Seguros del Pichincha";
      let user = JSON.parse(localStorage.getItem("loginListasOfac"));
      await Submit(
        "invite/getByEmail?email=" + user.correoUsuario,
        null,
        "get"
      ).then((value) => {
        let objItermediary = value?.data?.data;
        if (objItermediary) {
          let curIntermediary = objItermediary.intermediary;
          let tipoSdp = typeof curIntermediary?.sdp;
          let tipoSponsor = typeof curIntermediary?.sponsor;
          let tipoFv = typeof curIntermediary?.fv;
          let tipoBroker = typeof curIntermediary?.broker;

          if (tipoSdp === "object") typeExecutive = "Seguros del Pichincha";
          if (tipoSponsor === "object")
            typeExecutive = curIntermediary?.sponsor?.astr_nombre_asesor;
          if (tipoFv === "object") typeExecutive = curIntermediary?.fv;
          if (tipoBroker === "object")
            typeExecutive = curIntermediary?.broker?.astr_nombre_asesor;

          resolve(typeExecutive);
        } else {
          resolve(typeExecutive);
        }
      });
    });
  };

  const sendInformationEmail = async () => {
    try {
      //Format dataProjection
      let vecAniosAporte = [],
        vecEdad = [],
        vecCuotaTotalAnual = [],
        vecValorDisponible = [],
        vecBeneficioMuerte = [],
        vecValorRescate = [];
      let dataProj = dataProjection?.data?.proyeccion;
      let dataProjObj = [];
      for (let entry of dataProj) {
        let tmpObj = {
          aniosaporte: entry.aint_anio,
          edad: entry.adec_edad,
          cuotatotalanual: entry.adec_prima_total,
          valordisponible: entry.adec_tasa_garantizada,
          beneficiomuerte: entry.adec_valor_prima_ahorro,
          valorrescate: entry.adec_tasa_proyectada,
        };
        dataProjObj.push(tmpObj);
        vecAniosAporte.push(entry.aint_anio);
        vecEdad.push(entry.adec_edad);
        vecCuotaTotalAnual.push(entry.adec_prima_total);
        vecValorDisponible.push(entry.adec_tasa_garantizada);
        vecBeneficioMuerte.push(entry.adec_valor_prima_ahorro);
        vecValorRescate.push(entry.adec_tasa_proyectada);
      }

      let strCoberturaPrincipal = plan.alternativesSelected
        .map((item, idx) => {
          if (item.astr_principal === "S") return item.label;
          else return "";
        })
        .join(",");

      let strCoberturaSecundaria = plan.alternativesSelected
        .map((item, idx) => {
          if (item.astr_principal === "N") return item.label;
          else return "";
        })
        .join(",");

      let index_65 = null;
      dataProjection?.data?.proyeccion.map((item, index) => {
        if (item.adec_edad === 65) index_65 = index;
      });
      let cuota_total = dataProjection?.data?.proyeccion
        ? dataProjection?.data?.pagos[0].adec_pag_valor_total
        : 0;
      let ahorro_20 = dataProjection?.data?.proyeccion
        ? dataProjection?.data?.proyeccion[19]?.adec_tasa_garantizada
        : 0;
      let ahorro_65 = dataProjection?.data?.proyeccion
        ? dataProjection?.data?.proyeccion[index_65]?.adec_tasa_garantizada
        : 0;
      let fin_vigencia = new Date();
      fin_vigencia.setDate(fin_vigencia.getDate() + 30);

      let dd = String(fin_vigencia.getDate()).padStart(2, "0");
      let mm = String(fin_vigencia.getMonth() + 1).padStart(2, "0");
      let yyyy = fin_vigencia.getFullYear();

      let fin_vigencia_str = yyyy + "-" + mm + "-" + dd;
      let req = {
        type: "cotizacion",
        email: client?.correo?.toLowerCase(),
        toEmail: userProfile.email?.toLowerCase(),
        form_id: idForm,
        context: {
          nombres: `${client.primerNombre} ${client.segundoNombre} ${client.apellidoPaterno} ${client.apellidoMaterno}`,
          fechaNacimiento: client.fechaNacimiento,
          genero: client.genero === "1" ? "Masculino" : "Femenino",
          telefono: client.telefono,
          correo: client.correo,
          ejecutivo: await getExecutiveType(),
          cedula: `${client.numeroDocumento}`,
          asesor: client?.asesor ? client?.asesor : "",
          correoAsesor: userProfile.email,
          fecha: today,
          fin_vigencia: fin_vigencia_str,
          producto: plan.cod_producto.astr_descripcion, //`${plan.cod_producto.astr_descripcion} - ${plan.textPlan} - ${plan.alternativeNumber}`,
          estatus: "CANDIDATO",
          cuota_total: cuota_total,
          ahorro_20: ahorro_20,
          ahorro_65: ahorro_65,
          frecuencia:
            client.typePaid === "A"
              ? "ANUAL"
              : client.typePaid === "M"
                ? "MENSUAL"
                : client.typePaid === "S"
                  ? "SEMESTRAL"
                  : "TRIMESTRAL",
          fumador: client.smoker === 'S' ? 'SI' : 'NO',
          cobertura_principal_monto: plan?.valuesSelected[0],
          cobertura_secundaria_monto: plan?.valuesSelected[1],
          cobertura_principal_nombre: strCoberturaPrincipal,
          cobertura_secundaria_nombre: strCoberturaSecundaria,
          aniosaporte: vecAniosAporte,
          edad: vecEdad,
          cuotatotalanual: vecCuotaTotalAnual,
          valordisponible: vecValorDisponible,
          beneficiomuerte: vecBeneficioMuerte,
          valorrescate: vecValorRescate,
          labels_proyeccion: [
            "aniosaporte",
            "edad",
            "cuotatotalanual",
            "valordisponible",
            "beneficiomuerte",
            "valorrescate",
          ],
          proyeccion: dataProjObj,
        }
      };
      await postFillFormMultiple(req).then((resp) => {
        viewContext.notification.show("Formulario enviado", "success", false);
        setIsLoading(false);
        setIdForm("");
      });
    } catch (error) {
      viewContext.notification.show(error, "error", true);
      setIsLoading(false);
      setIdForm("");
      console.error(error);
    }
  };

  useEffect(() => {
    if (idForm !== "" && sentMail) {
      sendInformationEmail();
      setSentMail(false)
    }
  }, [idForm]);

  return (
    <Animate type='pop'>
      <div>
        <section>
          <div style={{ width: "100%", paddingTop: 30 }}>
            <div className={Input.mainContainerStepThree}>
              <div className={Input.conatinerStepThreeValues}>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className={Input.mainPadding}>
                      <div style={{ padding: 10 }}>
                        <h2 style={{ fontWeight: "bold" }}>
                          Datos del cliente
                        </h2>
                      </div>
                      <div style={{ padding: 10 }}>
                        <p>{`Nombres: ${client.primerNombre} ${client.segundoNombre} ${client.apellidoPaterno} ${client.apellidoMaterno}`}</p>
                      </div>
                      <div style={{ padding: 10 }}>
                        <p
                          className={Input["roboto"]}
                        >{`Cédula: ${client.numeroDocumento}`}</p>
                      </div>
                      <div style={{ padding: 10 }}>
                        <p>{`Asesor: ${client?.asesor ? client?.asesor : ""
                          }`}</p>
                      </div>
                      <div style={{ padding: 10 }}>
                        <p className={Input["roboto"]}>{`Fecha: ${today}`}</p>
                      </div>
                      <div style={{ padding: 10 }}>
                        <p>{`Producto: ${plan.cod_producto.astr_descripcion} - ${plan.textPlan} - ${plan.alternativeNumber}`}</p>
                      </div>
                      <div style={{ padding: 10 }}>
                        <p>{`Estatus: ${client.status}`}</p>
                      </div>
                      <div style={{ padding: 10 }}>
                        <p className={Input["roboto"]}>{`Cuota Total: $${dataProjection?.data?.pagos
                          ? dataProjection?.data?.pagos[0]
                            ?.adec_pag_valor_total
                          : 0
                          }`}</p>
                      </div>
                      <div style={{ padding: 10 }}>
                        <p>{`Frecuencia pago: ${client.typePaid === "A"
                          ? "ANUAL"
                          : client.typePaid === "M"
                            ? "MENSUAL"
                            : client.typePaid === "S"
                              ? "SEMESTRAL"
                              : "TRIMESTRAL"
                          }`}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Input.conatinerStepThreeValuesFlex}>
                <div>
                  {plan !== undefined &&
                    plan.alternativesSelected !== undefined &&
                    plan.alternativesSelected.map((item, idx) => {
                      return (
                        <div
                          key={`alternativeStepThree${idx}`}
                          className={Input.containerNamePlanThree}
                        >
                          <p style={{ color: "white", fontWeight: "500" }}>
                            {item.label}
                          </p>
                        </div>
                      );
                    })}
                </div>
                <div>
                  {plan !== undefined &&
                    plan.textPlan !== undefined &&
                    plan.valuesSelected !== undefined &&
                    plan.valuesSelected.map((item, idx) => {
                      return (
                        <div
                          key={`coveragesValuesStepTwo${idx}`}
                          className={Input.containerValuePlanThree}
                        >
                          <p
                            style={{ color: "white", fontWeight: "500" }}
                            className={Input["roboto"]}
                          >
                            ${formatToCurrency(item)}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "20px 5px" }}>
            <Scrollbars style={{ width: "100%", height: "450px" }}>
              <table className={Style.tg}>
                <thead>
                  <tr>
                    <th className={Style.tg73oqWhite} colSpan='3'></th>
                    <th className={Style.tg73oq} colSpan='2'>
                      Garantizado 3.5%
                    </th>
                    <th className={Style.tg73oq} colSpan='1'>
                      Proyectado 4.0%
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={Style.tg0pkyHead}>Año de aporte</td>
                    <td className={Style.tg0pkyHead}>Edad</td>
                    <td className={Style.tg0pkyHead}>Cuota total anual</td>
                    <td className={Style.tg0pkyHead}>Valor disponible</td>
                    <td className={Style.tg0pkyHead}>Beneficio muerte</td>
                    <td className={Style.tg0pkyHead}>Valor de rescate</td>
                  </tr>
                  {dataProjection &&
                    dataProjection.data.proyeccion?.map((item, idx) => {
                      return (
                        <tr key={`keyProjection${idx}`}>
                          <td className={Style.tg0pky}>{item.aint_anio}</td>
                          <td className={Style.tg0pky}>{item.adec_edad}</td>
                          <td className={Style.tg0pky}>
                            {item.adec_prima_total === 0
                              ? `$0.00`
                              : `$${formatNumber(item.adec_prima_total)}`}
                          </td>
                          <td className={Style.tg0pky}>
                            {item.adec_tasa_garantizada === 0
                              ? `$0.00`
                              : `$${formatNumber(item.adec_tasa_garantizada)}`}
                          </td>
                          <td className={Style.tg0pky}>
                            {item.adec_valor_prima_ahorro === 0
                              ? `$0.00`
                              : `$${formatNumber(
                                item.adec_valor_prima_ahorro
                              )}`}
                          </td>
                          <td className={Style.tg0pky}>
                            {item.adec_tasa_proyectada === 0
                              ? `$0.00`
                              : `$${formatNumber(item.adec_tasa_proyectada)}`}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </Scrollbars>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ paddingLeft: 15 }}>
              {!isLoading ? (
                <Button
                  applyColor='#0f265c'
                  text='Enviar info por mail'
                  action={() => {
                    setIsLoading(true);
                    saveCandidate();
                    setSentMail(true);
                  }}
                  disabled={dataProjection?.data?.proyeccion ? false : true}
                />
              ) : (
                <div className={Style.ldsDualRing}></div>
              )}
            </div>
          </div>
          <div style={{ padding: "10px 0px" }}>
            <button
              style={{
                fontWeight: 600,
                fontSize: "22px",
                textDecoration: "underline",
                textAlign: "left",
              }}
              onClick={() => {
                saveCandidate();
                setStep(1);
              }}
            >
              + Crear cotización B
            </button>
          </div>
          <div style={{ padding: "10px 0px" }}>
            <button
              style={{
                fontWeight: 600,
                fontSize: "22px",
                textDecoration: "underline",
                textAlign: "left",
              }}
              onClick={() => window.location.reload()}
            >
              + Crear cotización Nuevo candidato
            </button>
          </div>
        </section>
      </div>
    </Animate>
  );

}
