import { Table } from "components/lib";
import { Button } from "grommet";
import { Add, Edit, Clear } from "grommet-icons";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { FuerzaVentasPromptModal } from "views/seller/life/forms/FuerzaVentasPromptModal";
import Input from "../../../../css/globalStyle.module.scss";
import Style from "./byPlan.module.scss";

let optionsByPlan = [];

export function ByPlan(props) {
  const { agriculture, setAgriculture, servicesData } = props;
  const handleDeleteCultivation = (indexCultivation) => {
    const agricultureList = { ...agriculture };

    agricultureList.plansCultivation.map((item) => {
      if (item.name === agricultureList.cultivationSelected) {
        item.cultivationPlan.splice(indexCultivation, 1);
      }
    });
    setAgriculture(agricultureList);
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const handleInputDataChange = (e, indexData, nameSection) => {
    const { name, value, selectedIndex, id } = e.target;
    const agricultureList = { ...agriculture };
    const indexCultivation = agriculture.plansCultivation.findIndex(
      (object) => object.name === agricultureList.cultivationSelected
    );
    if (name === "selectBox") {
      agricultureList.plansCultivation[indexCultivation][nameSection][
        indexData
      ] = {
        plan: agricultureList.plansCultivation[indexCultivation][nameSection][
          indexData
        ].plan,
        adbl_mon_max: servicesData.branchProduct[selectedIndex].adbl_mon_max,
        adbl_mon_min: servicesData.branchProduct[selectedIndex].adbl_mon_min,
        adbl_vm_der_emision:
          servicesData.branchProduct[selectedIndex].adbl_vm_der_emision,
        aint_edad_max: servicesData.branchProduct[selectedIndex].aint_edad_max,
        aint_edad_min: servicesData.branchProduct[selectedIndex].aint_edad_min,
        aint_edad_perm:
          servicesData.branchProduct[selectedIndex].aint_edad_perm,
        astr_ahorro: servicesData.branchProduct[selectedIndex].astr_ahorro,
        namePlan: servicesData.branchProduct[selectedIndex].astr_descripcion,
        codePlan: servicesData.branchProduct[selectedIndex].astr_id_producto,
        astr_mon_id: servicesData.branchProduct[selectedIndex].astr_mon_id,
        astr_ramo: servicesData.branchProduct[selectedIndex].astr_ramo,
        coverages: servicesData.branchProduct[selectedIndex].coberturas,
        state:
          agricultureList.plansCultivation[indexCultivation][nameSection][
            indexData
          ].state,
        tax: getTax(servicesData.branchProduct[selectedIndex].coberturas),
        area_minima:
          agricultureList.plansCultivation[indexCultivation][nameSection][
            indexData
          ].area_minima,
          fuerza_venta: agricultureList.plansCultivation[indexCultivation][nameSection][
            indexData
          ].fuerza_venta,
      };
    } else if (nameSection === "cultivationSelected") {
      agricultureList[nameSection] = value;
    } else if (id === "state") {
      agricultureList.plansCultivation[indexCultivation][nameSection][
        [indexData]
      ][id] = value;
    } else {
      agricultureList.plansCultivation[indexCultivation][nameSection][
        [indexData]
      ][name] = value;
    }
    setAgriculture(agricultureList);
  };
  const [data, setData] = useState();

  const handleAddRow = () => {
    setNewModalSalesForce({});
    setModalVisible(true);
  };

  const [editedRowCultivationPlan, setEditedRowCultivationPlan] = useState(undefined);

  const handleEditRow = (cultivation) => {
    const agricultureList = { ...agriculture };
    const planCultivation = agricultureList.plansCultivation.filter((item) => item.name === agricultureList.cultivationSelected);
    const cultivationPlan = planCultivation[0].cultivationPlan.filter((item) => item.plan === cultivation.plan);
    setEditedRowCultivationPlan(cultivationPlan[0]);
    const fuerza_venta = cultivationPlan[0].fuerza_venta ? cultivationPlan[0].fuerza_venta : {
      broker: [],
      sponsor: [],
      sdp: [],
    };
    setEditModalSalesForce(fuerza_venta);
    setEditModalVisible(true);
  };

  const [newModalSalesForce, setNewModalSalesForce] = useState({
    broker: [],
    sponsor: [],
    sdp: [],
  });

  const [editModalSalesForce, setEditModalSalesForce] = useState({
    broker: [],
    sponsor: [],
    sdp: [],
  });


  const onModalSave = (plan) => {
    const nameSection = "cultivationPlan";
    const agricultureList = { ...agriculture };
    const selectedCultivo = getPlanByName(agriculture.plansCultivation);
    const indexPlan =
    selectedCultivo.length === 0
        ? 0
        : selectedCultivo[0].cultivationPlan.length;
    const item = {
      plan: indexPlan + 1,
      adbl_mon_max: plan.service.adbl_mon_max,
      adbl_mon_min: plan.service.adbl_mon_min,
      adbl_vm_der_emision: plan.service.adbl_vm_der_emision,
      aint_edad_max: plan.service.aint_edad_max,
      aint_edad_min: plan.service.aint_edad_min,
      aint_edad_perm: plan.service.aint_edad_perm,
      astr_ahorro: plan.service.astr_ahorro,
      namePlan: plan.service.astr_descripcion,
      codePlan: plan.service.astr_id_producto,
      astr_mon_id: plan.service.astr_mon_id,
      astr_ramo: plan.service.astr_ramo,
      coverages: plan.service.coberturas,
      state: "active",
      tax: getTax(plan.service.coberturas),
      area_minima: 0,
      fuerza_venta: newModalSalesForce,
    };
    if (selectedCultivo.length === 0) {
      agricultureList.plansCultivation.push({
        name: agricultureList.cultivationSelected,
        cultivationPlan: [item],
        cultivationCycle: [],
        cultivationValueInsured: [],
      });
    } else {
      agricultureList.plansCultivation.map((itemCultivation, index) => {
        if (itemCultivation.name === agricultureList.cultivationSelected) {
          agricultureList.plansCultivation[index][nameSection].push(item);
        }
      });
    }
    setAgriculture((prevRequirements) => ({
      ...prevRequirements,
    }));
  }

  const onEditModalSave = (plan) => {
    const oldTmp = editedRowCultivationPlan;
    oldTmp.fuerza_venta = editModalSalesForce;
    setEditedRowCultivationPlan(oldTmp);
    const agricultureList = { ...agriculture };
    const tempCultivation = getPlanByName(agriculture.plansCultivation);
    const indexPlan = tempCultivation[0].cultivationPlan.findIndex((item) => item.plan === editedRowCultivationPlan.plan);
    agricultureList.plansCultivation.map((itemCultivation, index) => {
      if (itemCultivation.name === agricultureList.cultivationSelected) {
        agricultureList.plansCultivation[index].cultivationPlan[indexPlan] = oldTmp;
      }
    });
    setAgriculture(agricultureList);
  }

  const getCoverages = (coverages) => {
    let tempTextCoverages = "";
    coverages.map((coverage, idx) => {
      if (coverages.length > idx && 0 < idx) {
        tempTextCoverages += " - ";
      }
      tempTextCoverages += coverage.astr_cob_descrip_print;
    });
    return tempTextCoverages;
  };

  const getTax = (taxs) => {
    let totalTax = 0;
    taxs.map((tax) => (totalTax += tax.adbl_qt_tasa));
    return totalTax;
  };

  const getPlanByName = (cultivationPlan) => {
    if (cultivationPlan)
      return cultivationPlan.filter(
        (item) => item.name === agriculture.cultivationSelected
      );
  };

  const makeDataTable = (cultivationPlan, handleInputDataChange) => {
    if (servicesData) {
      let tempDataTable = {
        header: [
          { name: "plan", title: "Plan", sort: false },
          { name: "nombre_Plan", title: "Nombre del plan", sort: false },
          { name: "codigo_Plan", title: "Código plan", sort: false },
          { name: "tasa", title: "Tasa(%)", sort: false },
          { name: "coberturas", title: "Coberturas", sort: false },
          { name: "area_minima", title: "Área mínima (HA)", sort: false },
          { name: "estado", title: "Estado", sort: false },
          { name: "fuerza_venta", title: "Canal", sort: false },
          { name: "modify", title: "Acciones", sort: false },
        ],
        body: [],
      };
      let tempObjectCultivation = {};
      let newCultivationArray = getPlanByName(cultivationPlan);
      let nameSection = "cultivationPlan";
      if (newCultivationArray && newCultivationArray.length > 0) {
        newCultivationArray[0][nameSection].map(
          (cultivation, indexCultivation) => {
            if (indexCultivation === 0) {
              optionsByPlan = [];
              servicesData.branchProduct.map((service) =>
                optionsByPlan.push({
                  value: service.astr_id_producto,
                  name: service.astr_descripcion,
                  service: service,
                })
              );
            }
            tempObjectCultivation.plan = cultivation.plan;
            tempObjectCultivation.nombre_Plan = (
              <select
                style={{ display: "inline-block", maxWidth: "100%" }}
                name='selectBox'
                id={nameSection}
                value={cultivation.codePlan !== "" && cultivation.codePlan}
                onChange={(e) =>
                  handleInputDataChange(e, indexCultivation, nameSection)
                }
              >
                {optionsByPlan.map((option) => {
                  return (
                    <option
                      key={`${nameSection}ByPlanOptions${option.value}`}
                      value={`${option.value}`}
                    >
                      {option.name}
                    </option>
                  );
                })}
              </select>
            );
            tempObjectCultivation.codigo_Plan = cultivation.codePlan;
            tempObjectCultivation.tasa = cultivation.tax;
            tempObjectCultivation.coberturas = (
              <div
                style={{ overflow: "auto", width: "350px", height: "200px" }}
              >
                <p>{getCoverages(cultivation.coverages)}</p>
              </div>
            );
            tempObjectCultivation.area_minima = (
              <input
                type='number'
                value={cultivation.area_minima}
                name='area_minima'
                className={Input["roboto"]}
                onChange={(e) =>
                  handleInputDataChange(e, indexCultivation, nameSection)
                }
              />
            );
            tempObjectCultivation.estado = (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label style={{ fontSize: "15px" }}>Inactivo</label>
                    <div style={{ paddingLeft: 5 }}>
                      <input
                        id='state'
                        type='radio'
                        value='inactive'
                        name={`${cultivation.namePlan}${indexCultivation}`}
                        checked={cultivation.state === "inactive"}
                        onChange={(e) =>
                          handleInputDataChange(
                            e,
                            indexCultivation,
                            nameSection
                          )
                        }
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <label style={{ fontSize: "15px" }}>Activo</label>
                    <div style={{ paddingLeft: 5 }}>
                      <input
                        id='state'
                        type='radio'
                        value='active'
                        name={`${cultivation.namePlan}${indexCultivation}`}
                        checked={cultivation.state === "active"}
                        onChange={(e) =>
                          handleInputDataChange(
                            e,
                            indexCultivation,
                            nameSection
                          )
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
            let plan_reference_name = '';
            if (cultivation.fuerza_venta !== undefined && cultivation.fuerza_venta !== null) {
              if (cultivation.fuerza_venta.broker !== null && cultivation.fuerza_venta.broker !== undefined && cultivation.fuerza_venta.broker.length > 0) {
                plan_reference_name += '<strong>Broker:</strong>\n';
                cultivation.fuerza_venta.broker.map((item, index) => {
                  plan_reference_name += item.astr_nombre_asesor;
                  if (index < cultivation.fuerza_venta.broker.length - 1) {
                    plan_reference_name += '<strong>,</strong> ';
                  }
                });
                plan_reference_name += '\n';
              }

              if (cultivation.fuerza_venta.sponsor !== null && cultivation.fuerza_venta.sponsor !== undefined && cultivation.fuerza_venta.sponsor.length > 0) {
                plan_reference_name += '\n<strong>Sponsor:</strong>\n';
                cultivation.fuerza_venta.sponsor.map((item, index) => {
                  plan_reference_name += item.astr_nombre_asesor;
                  if (index < cultivation.fuerza_venta.sponsor.length - 1) {
                    plan_reference_name += '<strong>,</strong> ';
                  }
                });
                plan_reference_name += '\n';
              }

              if (cultivation.fuerza_venta.sdp !== null && cultivation.fuerza_venta.sdp !== undefined && cultivation.fuerza_venta.sdp.length > 0) {
                plan_reference_name += '\n<strong>SDP:</strong>\n';
                cultivation.fuerza_venta.sdp.map((item, index) => {
                  plan_reference_name += item.astr_nombre_asesor;
                  if (index < cultivation.fuerza_venta.sdp.length - 1) {
                    plan_reference_name += '<strong>,</strong> ';
                  }
                });
                plan_reference_name += '\n';
              }

              tempObjectCultivation.fuerza_venta = (
                <div style={{ display: "flex", width: '350px' }}>
                  <div style={{ width: '300px', whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: plan_reference_name }}>
                  </div>
                </div>
              );

            } else {
              tempObjectCultivation.fuerza_venta = (
                <div style={{ display: "flex", width: '350px' }}>
                  <div style={{ width: '300px', whiteSpace: 'pre-line' }}>
                  </div>
                </div>
              );
            }

            tempObjectCultivation.modify = (
              <div style={{ display: "flex", width: '120px' }}>
                <div style={{ width: '60px', whiteSpace: 'pre-line' }}>
                  <Button primary onClick={() => handleEditRow(cultivation)} style={{ display: "block", width: '50px', height: '50px' }}>
                    <Edit size='medium' />
                  </Button>
                </div>
                <div style={{ width: '60px', whiteSpace: 'pre-line' }}>
                  <Button primary onClick={() => handleDeleteCultivation(indexCultivation)} style={{ display: "block", width: '50px', height: '50px' }}>
                    <Clear size='medium' />
                  </Button>
                </div>
              </div>
            )


            tempDataTable.body.push(tempObjectCultivation);
            tempObjectCultivation = {};
          }
        );
      }
      setData(tempDataTable);
    }
  };

  useEffect(() => {
    makeDataTable(agriculture.plansCultivation, handleInputDataChange);
  }, [agriculture]);

  return (
    <div className={Style.byPlan}>
      <Scrollbars
        style={{
          width: "100%",
          // height: "700px",
        }}
        autoHeight
        autoHeightMin={600}
      >
        <div style={{ width: "50%" }}>
          <label>Cultivo:&nbsp;</label>
          <select
            style={{ width: "150px" }}
            name={"cultivations"}
            id="cultivations"
            onChange={(e) =>
              handleInputDataChange(e, "", "cultivationSelected")
            }
            value={
              agriculture.cultivationSelected !== "" &&
              agriculture.cultivationSelected
            }
          >
            {agriculture.cultivations.map((option, i) => {
              return (
                <option
                  key={`${option.nombre_del_cultivo}${option.tipo_del_cultivo}${option.vigencia}Options${i}`}
                  value={option.nombre_del_cultivo}
                >
                  {option.nombre_del_cultivo}
                </option>
              );
            })}
          </select>
        </div>
        <div>
          <Table data={data} />
        </div>
      </Scrollbars>
      <Button primary onClick={() => handleAddRow()}>
        <Add size='medium' />
      </Button>
      <FuerzaVentasPromptModal isOpen={modalVisible} onClose={setModalVisible} onSave={onModalSave} salesForce={newModalSalesForce} setSalesForce={setNewModalSalesForce} optionsByPlan={optionsByPlan} ></FuerzaVentasPromptModal>
      <FuerzaVentasPromptModal isOpen={editModalVisible} onClose={setEditModalVisible} onSave={onEditModalSave} salesForce={editModalSalesForce} setSalesForce={setEditModalSalesForce} optionsByPlan={optionsByPlan} currentPlan={editedRowCultivationPlan}></FuerzaVentasPromptModal>
    </div>
  );
}
