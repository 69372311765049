import { BackButton } from "components/backButton/button";
import useViewPort from "components/hooks/useViewPort";
import {
  Animate,
  Content,
  ProgressSteps, ViewContext, useNavigate
} from "components/lib";
import { add, differenceInDays } from "date-fns";
import { useContext, useState } from "react";
import Style from "../../../css/globalStyle.module.scss";
import { StepOne } from "./steps/stepOne";
import { StepTwo } from "./steps/stepTwo";

export function CandidatePriori(props) {

  const [screenSize] = useViewPort();
  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [candidate, setCandidate] = useState({});
  const [client, setClient] = useState({
    asesor: "",
    nombre: "",
    apellido: "",
    fechaNacimiento: "",
    fechaNacimientoSinFormato: "",
    telefono: "",
    correo: "",
    frecuenciaPago: "",
    value: "",
  });

  const stepsCandidate = [
    {
      name: "Información del candidato",
      completed: true,
    },
    {
      name: "Información de producto",
      completed: step >= 1 ? true : false,
    },
  ];

  const parseDate = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleInputChange = (event) => {
    try {
      const { name, value } = event.target;
      if (
        name === "correo" ||
        name === "apellido" ||
        name === "nombre"
      ) {
        setClient({ ...client, [name]: value.toUpperCase() });
      } else {
        setClient({ ...client, [name]: value });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDate = (date) => {
    try {

      let tempDate = parseDate(date);

      const fechaReferencia = new Date(date);

      const fechaActual = new Date();

      const milisegundosReferencia = fechaReferencia.getTime();
      const milisegundosActual = fechaActual.getTime();

      const diferenciaEnMilisegundos = milisegundosActual - milisegundosReferencia;
      const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

      const anios = 54;
      const meses = 11;
      const dias = 29;

      const fechaCalculada = add(fechaActual, { years: -anios, months: -meses, days: -dias });
      const edadEnDias = differenceInDays(fechaActual, fechaCalculada);

      const fechaCalculada18 = add(fechaActual, { years: -18, months: 0, days: 0 });
      const edadEnDias18 = differenceInDays(fechaActual, fechaCalculada18);

      if (diferenciaEnDias > edadEnDias) {
        viewContext.notification.show("La edad no puede ser mayor a 54 años 11 meses y 29 días", "error", true);
        setClient({
          ...client,
          fechaNacimiento: '',
          fechaNacimientoSinFormato: '',
        });
        return;
      }

      if (diferenciaEnDias < edadEnDias18) {
        viewContext.notification.show("La edad no puede ser menor a 18 años", "error", true);
        setClient({
          ...client,
          fechaNacimiento: '',
          fechaNacimientoSinFormato: '',
        });
        return;
      }

      setClient({
        ...client,
        fechaNacimiento: tempDate,
        fechaNacimientoSinFormato: date,
      });
    } catch (error) {
      console.error(error);
    };
  };

  const isBirthdayValid = () => {
    try {
      const fechaReferencia = new Date(client.fechaNacimientoSinFormato);
      const fechaActual = new Date();
      const milisegundosReferencia = fechaReferencia.getTime();
      const milisegundosActual = fechaActual.getTime();
      const diferenciaEnMilisegundos = milisegundosActual - milisegundosReferencia;
      const diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
      const anios = 54;
      const meses = 11;
      const dias = 29;
      const fechaCalculada = add(fechaActual, { years: -anios, months: -meses, days: -dias });
      const edadEnDias = differenceInDays(fechaActual, fechaCalculada);
      const fechaCalculada18 = add(fechaActual, { years: -18, months: 0, days: 0 });
      const edadEnDias18 = differenceInDays(fechaActual, fechaCalculada18);

      if (diferenciaEnDias > edadEnDias) {
        viewContext.notification.show("La edad no puede ser mayor a 54 años 11 meses y 29 días", "error", true);
        return false;
      }

      if (diferenciaEnDias < edadEnDias18) {
        viewContext.notification.show("La edad no puede ser menor a 18 años", "error", true);
        return false;
      }

      return true;

    } catch (error) {
      console.error(error);
    };

    return false;
  };

  const handleSelectChange = (event, type) => {
    //label
    const { value } = event;
    setClient({ ...client, [type]: value });
  };

  const saveCandidate = async () => {

    const birthdayValid = isBirthdayValid();

    if (!birthdayValid) {
      setClient({
        ...client,
        fechaNacimiento: '',
        fechaNacimientoSinFormato: '',
      });
      return;
    }

    setStep(1);
    return;
  }

  return (
    <Animate type='pop'>
      <div className={Style.buttonPosition}>
        <BackButton
          action={() => {
            if (step === 0) {
              navigate("/vendedor/priori/prioriManagement");
            } else {
              setStep(step - 1);
            }
          }}
        />
      </div>
      <section className={Style.paddingLeft}>
        <Content>
          <section style={{ padding: "20px 0px" }}>
            {screenSize.dynamicWidth > 768 ? (
              <ProgressSteps items={stepsCandidate} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className={Style.circle}>{step + 1}</div>
                <p className={Style.textStep}>{stepsCandidate[step].name}</p>
              </div>
            )}
          </section>
          {step === 0 && (
            <StepOne
              client={client}
              handleInputChange={handleInputChange}
              handleDate={handleDate}
              isLoading={isLoading}
              handleSelectChange={handleSelectChange}
              saveCandidate={saveCandidate}
            />
          )}
          {step === 1 && (
            <StepTwo
              client={client}
              candidate={candidate}
              step={step}
              setStep={setStep}
            />
          )}
        </Content>
      </section>
    </Animate>
  );
}
