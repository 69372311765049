import { useEffect, useState } from 'react';
import Style from "../../css/globalStyle.module.scss";

export function TerminosCondicionesGenericoModal({ isOpen, onClose, file }) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isOpen) return null;

  return (
    <div className={Style['modal']} style={{ width: dimensions.width, height: dimensions.height }}>
      <div className={Style["modal-content"]}>
        {/* <span className={Style["close"]} onClick={onClose}>&times;</span> */}
        <iframe
          src={file}
          title="Términos y Condiciones"
          width={dimensions.width - dimensions.width / 3}
          height={dimensions.height - dimensions.height / 4}
          style={{ border: 'none' }}
        ></iframe>

        <button onClick={onClose}
          style={{
            textAlign: 'center',
            backgroundColor: '#10265C',
            color: 'white',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            height: '65px',
            width: '100%',
          }}>
          Acepto los Términos y Condiciones
        </button>
      </div>
    </div>
  );
}
