import React, { useEffect } from "react";
import { Title } from "./title";
import Style from "../../../../../css/globalStyle.module.scss";

export function InformacionSolicitante(props) {
  const { register, watch, options, errors, setValue, } = props;
  const solicitantePolizaDiferenteAsegurado = watch("solicitantePolizaDiferenteAsegurado");
  const tipoPersonaSolicitanteDiferenteAsegurado = watch("tipoPersonaSolicitanteDiferenteAsegurado");

  useEffect(() => {
    if ((options?.personeria && options?.personeria.length > 0) &&
      (tipoPersonaSolicitanteDiferenteAsegurado === undefined || tipoPersonaSolicitanteDiferenteAsegurado === ""
        || tipoPersonaSolicitanteDiferenteAsegurado === null)) {
      setValue('tipoPersonaSolicitanteDiferenteAsegurado', options.personeria[0].value);
    }
  }, [tipoPersonaSolicitanteDiferenteAsegurado]);

  return (
    <div>
      <Title whiteTitle='1. Sección información del solicitante de la póliza' />
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainer}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;¿El solicitante de la póliza es diferente al asegurado?
          </label>
          <select
            className={Style["select"]}
            {...register("solicitantePolizaDiferenteAsegurado", {
              required: true,
            })}
          >
            <option value='No' key='No11solicitantePolizaDiferenteAsegurado'>
              No
            </option>
            <option value='Si' key='Si11solicitantePolizaDiferenteAsegurado'>
              Si
            </option>
          </select>
          {errors.solicitantePolizaDiferenteAsegurado?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        {solicitantePolizaDiferenteAsegurado === "Si" && (
          <>
            <div className={Style.inputContainer}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;Tipo de persona solicitante diferente al asegurado
              </label>
              <select
                className={Style["select"]}
                {...register("tipoPersonaSolicitanteDiferenteAsegurado", {
                  required: true,
                })}
              >
                {options?.personeria?.map((option) => {
                  return (
                    <option
                      value={option.value}
                      key={`tipoPersonaSolicitanteDiferenteAsegurado${option.value}`}
                    >
                      {option.label}
                    </option>
                  );
                })}
              </select>
              {errors.tipoPersonaSolicitanteDiferenteAsegurado?.type ===
                "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
            </div>
            <div className={Style.inputContainer}>
              <label style={{ marginBottom: "auto" }}>
                <b style={{ color: 'red' }}>*</b>&nbsp;Nombres completos / Razón Social
              </label>
              <input
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                type='text'
                {...register("nombreCompleto", {
                  required: true,
                  maxLength: 255,
                })}
                className={Style["input"]}
              />
              {errors.nombreCompleto?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
