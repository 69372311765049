export const SetViewCandidateOther = (
  rawUser,
  userPermissions,
  action,
  type
) => {
  let isView = false;

  if (rawUser.data && rawUser.data.flag_uno) {
    isView = true;
  } else {
    if (userPermissions) {
      Object.keys(userPermissions[`${type}`]).map((key) => {
        action.map((valueAction) => {
          if (key === valueAction) {
            if (userPermissions[`${type}`][key]) {
              isView = true;
            }
          }
        });
      });
    }
  }
  return isView;
};
