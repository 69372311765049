import React, { Fragment, useState, useEffect } from "react";
import { Loader, Table, SwitchProduct } from "components/lib";
import Style from "./modalContent.module.scss";
import Modal from "react-modal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export function ModalCustomv2(props) {
  const { closeModal, display, height, width, open } = props;

  useEffect(() => { }, []);

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Custom Modal'
    >
      <div className={Style.modalContent}>
        <button onClick={closeModal}>X</button>
        <div
          style={{ height: height ? height : 350, width: width ? width : 350 }}
        >
          {display}
        </div>
      </div>
    </Modal>
  );
}
