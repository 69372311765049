import es from "date-fns/locale/es";
import DatePicker from "react-date-picker";
import { registerLocale } from "react-datepicker";
import Style from "../../../../../css/globalStyle.module.scss";
import "../../../../../css/reactCalendar.css";
import { Title } from "./title";

export function DeclaracionPersonaPolitica(props) {
  const {
    pep,
    setPep
  } = props;

  registerLocale("es", es);

  return (
    <div>
      <Title whiteTitle='2. Sección declaración de persona políticamente expuesta - PEP' />
      <div className={Style.inputContainerDeclaracion}>
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              Usted desempeña o ha desempeñado funciones o cargos públicos en el
              Ecuador o en el extranjero?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label htmlFor="DeclaracionPersonaPolitica-radio-si" className={Style['bigger-radio-label']}>Si</label>
              <input
                id="DeclaracionPersonaPolitica-radio-si"
                required={true}
                type='radio'
                className={Style['bigger-radio']}
                value='Si'
                name='DeclaracionPersonaPolitica-answer'
                checked={pep.answer === "Si"}
                onChange={(e) => {
                  setPep({ ...pep, answer: e.target.value });
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label htmlFor="DeclaracionPersonaPolitica-radio-no" className={Style['bigger-radio-label']}>No</label>
              <input
                id="DeclaracionPersonaPolitica-radio-no"
                required={true}
                type='radio'
                className={Style['bigger-radio']}
                value='No'
                name='DeclaracionPersonaPolitica-answer'
                checked={pep.answer === "No"}
                onChange={(e) => {
                  setPep({ ...pep, answer: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={Style.inputContainerDeclaracion}>
        {pep.answer === "Si" && (
          <>
            <div className={Style.inputContainerDeclaracion}>
              <div className={Style.inputContainerDeclaracionSecond}>
                <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                  Institución
                </h2>
                <input
                  type='text'
                  className={Style["input"]}
                  required={true}
                  value={pep.institution}
                  onChange={(e) => {
                    setPep({ ...pep, institution: e.target.value });
                  }}
                />
              </div>
              <div className={Style.inputContainerDeclaracionSecond}>
                <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Cargo</h2>
                <input
                  type='text'
                  className={Style["input"]}
                  required={true}
                  value={pep.position}
                  onChange={(e) => {
                    setPep({ ...pep, position: e.target.value });
                  }}
                />
              </div>
              <div className={Style.inputContainerDeclaracionSecond}>
                <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                  Fecha de culminación del cargo
                </h2>
                <DatePicker
                  required={true}
                  locale='es'
                  format='dd/MM/yyyy'
                  value={pep.positionCompletionDate instanceof Date ? pep.positionCompletionDate : new Date(pep.positionCompletionDate)}
                  onChange={(e) => {
                    setPep({ ...pep, positionCompletionDate: e });
                  }}
                />

              </div>
            </div>
          </>
        )}
      </div>
      <div style={{ marginBottom: '20px' }}>
      </div>
    </div>
  );
}
