import React from "react";
import { Title } from "./title";
import CurrencyInput from "react-currency-input-field";
import { useState } from "react";
import Style from "../../../../../css/globalStyle.module.scss";

export function SegurosExistentes(props) {
  const { register, watch, errors, monto, setMonto } = props;
  const tieneActualmenteSeguroVida = watch("tieneActualmenteSeguroVida");
  const polizaReemplazaPolizaVigente = watch("polizaReemplazaPolizaVigente");
  const solicitudPendienteCompania = watch("solicitudPendienteCompania");
  const beneficiosSeguroVidaUltimosAnios = watch(
    "beneficiosSeguroVidaUltimosAnios"
  );
  const [values, setValues] = useState();
  const [rawValue, setRawValue] = useState(" ");
  const [value, setValue] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const limit = 1000000000;
  const prefix = "$";

  const handleOnValueChange = (value, _, values, nameState) => {
    setValues(values);
    setRawValue(value === undefined ? "undefined" : value || " ");
    if (!value) {
      setValue("");
      return;
    }
    if (Number.isNaN(Number(value))) {
      setErrorMessage("Please enter a valid number");
      return;
    }
    if (Number(value) > limit) {
      setErrorMessage(`Valor Máximo: ${prefix}${limit}`);
      setValue(value);
      return;
    }
    if (Number(value) >= limit) {
      setErrorMessage(``);
      setValue(value);
      return;
    }
    setValue(value);
    setMonto({ ...monto, [nameState]: value });
  };

  return (
    <div>
      <Title whiteTitle='7. Sección seguros existentes sobre la vida del propuesto asegurado' />
      <div className={Style.inputContainerSeguros}>
        <div className={Style.inputContainerSegurosInput}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;¿Tiene usted actualmente alguna póliza de seguro de vida?
          </label>
          <select
            {...register("tieneActualmenteSeguroVida", { required: true })}
            className={Style["select"]}
          >
            <option value='Si'>Si</option>
            <option value='No'>No</option>
          </select>
          {errors.tieneActualmenteSeguroVida?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
      </div>
      {tieneActualmenteSeguroVida === "Si" && (
        <div className={Style.inputContainerSeguros}>
          <div className={Style.inputContainerSegurosInput}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Compañía
            </label>
            <input
              tabIndex={55}
              type='text'
              {...register("compania", { required: true, maxLength: 255 })}
              className={Style["input"]}
            />
            {errors.compania?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
          <div className={Style.inputContainerSegurosInput}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Monto asegurado
            </label>
            <CurrencyInput
              tabIndex={56}
              id='montoAsegurado'
              name='montoAsegurado'
              decimalsLimit={2}
              prefix={"$"}
              // onValueChange={handleOnValueChange}
              onValueChange={(value, _, values) => {
                handleOnValueChange(value, _, values, "montoAsegurado");
              }}
              value={monto.montoAsegurado}
              className={Style["input_number"]}
              {...register("montoAsegurado", {
                required: true,
                min: 1,
                value: monto.montoAsegurado,
              })}
            />
            <p style={{ color: "red" }}>
              {errorMessage !== "" && errorMessage}
            </p>
            {errors.montoAsegurado?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
          <div className={Style.inputContainerSegurosInput}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Año de emisión
            </label>
            <input
              tabIndex={57}
              min='0'
              type='number'
              {...register("anioEmision", { required: true, min: 1900 })}
              className={Style["input_number"]}
            />
            {errors.anioEmision?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </div>
      )}
      <div className={Style.inputContainerSeguros}>
        <div className={Style.inputContainerSegurosInput}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;¿La póliza solicitada reemplazará a alguna póliza vigente?
          </label>
          <select
            {...register("polizaReemplazaPolizaVigente", { required: true })}
            className={Style["select"]}
          >
            <option value='Si'>Si</option>
            <option value='No'>No</option>
          </select>
          {errors.polizaReemplazaPolizaVigente?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        {polizaReemplazaPolizaVigente === "Si" && (
          <div className={Style.inputContainerSegurosInput60}>
            <label style={{ marginBottom: "auto" }}>
              <b style={{ color: 'red' }}>*</b>&nbsp;Indicar la póliza y el motivo:
            </label>
            <textarea
              tabIndex={59}
              style={{ width: "100%" }}
              {...register("polizaPlazo", { required: true, maxLength: 499 })}
              className={Style["textArea"]}
            />
            {errors.polizaPlazo?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        )}
      </div>
      <div className={Style.inputContainerSeguros}>
        <div className={Style.inputContainerSegurosInput}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;¿Tiene usted alguna solicitud pendiente en alguna compañía?
          </label>
          <select
            {...register("solicitudPendienteCompania", { required: true })}
            className={Style["select"]}
          >
            <option value='Si'>Si</option>
            <option value='No'>No</option>
          </select>
          {errors.solicitudPendienteCompania?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        {solicitudPendienteCompania === "Si" && (
          <div className={Style.inputContainerSegurosInput}>
            <label style={{ marginBottom: "auto" }}>
              <b style={{ color: 'red' }}>*</b>&nbsp;Nombre de la compañía
            </label>
            <input
              tabIndex={59}
              type='text'
              {...register("nombreCompania", {
                required: true,
                maxLength: 255,
              })}
              className={Style["input"]}
            />
            {errors.nombreCompania?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        )}
      </div>
      <div className={Style.inputContainerSeguros}>
        <div className={Style.inputContainerSegurosInput}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;¿Ha recibido beneficios por concepto de seguros de vida en los dos
            últimos años?
          </label>
          <select
            {...register("beneficiosSeguroVidaUltimosAnios", {
              required: true,
            })}
            className={Style["select"]}
          >
            <option value='Si'>Si</option>
            <option value='No'>No</option>
          </select>
          {errors.beneficiosSeguroVidaUltimosAnios?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        {beneficiosSeguroVidaUltimosAnios === "Si" && (
          <>
            <div className={Style.inputContainerSeguros17}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;Describe el beneficio
              </label>
              <textarea
                tabIndex={60}
                {...register("describeBeneficio", {
                  required: true,
                  maxLength: 255,
                })}
                className={Style["textArea"]}
              />
              {errors.describeBeneficio?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerSeguros17}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;Compañía de seguros
              </label>
              <input
                tabIndex={61}
                type='text'
                {...register("companiaSeguros", {
                  required: true,
                  maxLength: 255,
                })}
                className={Style["input"]}
              />
              {errors.companiaSeguros?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerSeguros17}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;Tipo de póliza
              </label>
              <input
                tabIndex={62}
                type='text'
                className={Style["input"]}
                {...register("tipoPoliza", { required: true, maxLength: 255 })}
              />
              {errors.tipoPoliza?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerSeguros17}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;Monto
              </label>
              <CurrencyInput
                tabIndex={63}
                {...register("monto", {
                  required: true,
                  min: 1,
                  value: monto.monto,
                })}
                id='monto'
                name='monto'
                decimalsLimit={2}
                prefix={"$"}
                onValueChange={(value, _, values) => {
                  handleOnValueChange(value, _, values, "monto");
                }}
                value={monto.monto}
                className={Style["input_number"]}
              />
              {errors.monto?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
