import React from "react";
import DatePicker from "react-date-picker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { Title } from "./title";
import { useState } from "react";
import { useEffect } from "react";
import Style from "../../../../../css/globalStyle.module.scss";
import "../../../../../css/reactCalendar.css";

export function DeclaracionPersonaPolitica(props) {
  const { register, watch, errors, control, prevData, setValue } = props;
  const declaracionPoliticaPregunta1 = watch("declaracionPoliticaPregunta1");
  const declaracionPoliticaPregunta2 = watch("declaracionPoliticaPregunta2");
  const declaracionPoliticaPregunta1Tiempo = watch(
    "declaracionPoliticaPregunta1Tiempo"
  );
  const [fechaCargo, setFechaCargo] = useState(
    declaracionPoliticaPregunta1Tiempo === undefined
      ? null
      : new Date(declaracionPoliticaPregunta1Tiempo)
  );
  registerLocale("es", es);

  useEffect(() => {
    setValue("declaracionPoliticaPregunta1Tiempo", new Date());
  }, []);

  return (
    <div>
      <Title whiteTitle='10. Sección declaración de persona políticamente expuesta - PEP' />
      <div className={Style.inputContainerDeclaracion}>
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              Usted desempeña o ha desempeñado funciones o cargos públicos en el
              Ecuador o en el extranjero
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                {...register("declaracionPoliticaPregunta1", {
                  required: true,
                })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                {...register("declaracionPoliticaPregunta1", {
                  required: true,
                })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.declaracionPoliticaPregunta1?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
      </div>
      <div className={Style.inputContainerDeclaracion}>
        {declaracionPoliticaPregunta1 === "Si" && (
          <>
            <div className={Style.inputContainerDeclaracion}>
              <div className={Style.inputContainerDeclaracionSecond}>
                <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                  Institución
                </h2>
                <input
                  type='text'
                  className={Style["input"]}
                  {...register("declaracionPoliticaPregunta1Institucion", {
                    required: true,
                  })}
                />
                {errors.declaracionPoliticaPregunta1Institucion?.type ===
                  "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
              </div>
              <div className={Style.inputContainerDeclaracionSecond}>
                <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Cargo</h2>
                <input
                  type='text'
                  className={Style["input"]}
                  {...register("declaracionPoliticaPregunta1Cargo", {
                    required: true,
                  })}
                />
                {errors.declaracionPoliticaPregunta1Cargo?.type ===
                  "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
              </div>
              <div className={Style.inputContainerDeclaracionSecond}>
                <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                  Fecha de culminación del cargo
                </h2>
                <DatePicker
                  {...register("declaracionPoliticaPregunta1Tiempo", {
                    required: true,
                    value: declaracionPoliticaPregunta1Tiempo,
                  })}
                  name='declaracionPoliticaPregunta1Tiempo'
                  onChange={(e) => {
                    // handleDatePass(e);
                    setFechaCargo(e);
                    setValue("declaracionPoliticaPregunta1Tiempo", e);
                  }}
                  value={declaracionPoliticaPregunta1Tiempo}
                />
                {errors.declaracionPoliticaPregunta1Tiempo?.type ===
                  "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className={Style.inputContainerDeclaracion}>
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              Usted es familiar de un PEP (padres/hijos/abuelos/nietos o
              cónyuge) o usted trabaja con una Persona Expuesta Políticamente en
              forma directa
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                {...register("declaracionPoliticaPregunta2", {
                  required: true,
                })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                {...register("declaracionPoliticaPregunta2", {
                  required: true,
                })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.declaracionPoliticaPregunta2?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
      </div>
      {declaracionPoliticaPregunta2 === "Si" && (
        <>
          <div className={Style.inputContainerDeclaracion}>
            <div className={Style.inputContainerDeclaracionSecond}>
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                Nombres y apellidos
              </h2>
              <input
                type='text'
                className={Style["input"]}
                {...register("declaracionPoliticaPregunta2NombreApellido", {
                  required: true,
                })}
              />
              {errors.declaracionPoliticaPregunta2NombreApellido?.type ===
                "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
            </div>
            <div className={Style.inputContainerDeclaracionSecond}>
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Institución</h2>
              <input
                type='text'
                className={Style["input"]}
                {...register("declaracionPoliticaPregunta2Institucion", {
                  required: true,
                })}
              />
              {errors.declaracionPoliticaPregunta2Institucion?.type ===
                "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
            </div>
            <div className={Style.inputContainerDeclaracionSecond}>
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Cargo</h2>
              <input
                type='text'
                className={Style["input"]}
                {...register("declaracionPoliticaPregunta2Cargo", {
                  required: true,
                })}
              />
              {errors.declaracionPoliticaPregunta2Cargo?.type ===
                "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
            </div>
          </div>
          <div className={Style.inputContainerDeclaracion}>
            <div className={Style.inputContainerDeclaracionSecond}>
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                Parentesco/relación
              </h2>
              <input
                type='text'
                className={Style["input"]}
                {...register("declaracionPoliticaPregunta2Parentesco", {
                  required: true,
                })}
              />
              {errors.declaracionPoliticaPregunta2Parentesco?.type ===
                "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
