/***
 *
 *   USER
 *   shows the current user
 *   if user belongs to more than one account they can switch accounts here
 *
 **********/

import React, { useContext } from "react";
import { AuthContext, ViewContext, HoverNav, Button } from "components/lib";
import LogoColor from "../logo/images/SDP-logo.svg";
import LogoRio from "../logo/images/Logo-RIO.svg";
import LogoColor2 from "../logo/images/Logo-RIO.png";
import Style from "./user.module.scss";

export function User(props) {
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const accountName = authContext.user?.accounts?.find(
    (x) => x.id === authContext.user?.account_id
  )?.name;

  return (
    <div className={Style.user}>
      <div className={Style.logos}>
        <img src={LogoRio} alt='Logo' width={"10%"} />
        <img src={LogoColor2} alt='Logo' width={"50%"} />
      </div>
      {/* <div className={Style.name}>Bienvenido, {authContext.user?.name}</div> */}

      {authContext.user?.accounts?.length > 1 && (
        <HoverNav
          dark
          icon='user'
          label={accountName}
          className={Style.hoverNav}
          align='right'
        >
          {authContext.user.accounts.map((account) => {
            return (
              <Button
                key={account.id}
                text={account.name}
                action={() => {
                  viewContext.setLoading(true);
                  authContext.switchAccount(account.id);
                }}
              />
            );
          })}
        </HoverNav>
      )}
    </div>
  );
}
