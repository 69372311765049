import React, { Fragment, useState, useEffect } from 'react';
import { Form, Message, useNavigate, Animate, Card, useAPI } from 'components/lib';
import CryptrUtils from '../../utils/CryptrUtils';

export function OnboardingView(props) {

  // get the user state
  const user = useAPI("/api/user");
  const encrypted = user?.data;
  let decrypted;
  if (encrypted) {
    decrypted = CryptrUtils.decrypt(encrypted);
  }
  const [qrCode, setQrCode] = useState(undefined);
  const [enabled, setEnabled] = useState(undefined);
  const [backupCode, setBackupCode] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    setEnabled(decrypted?.["2fa_enabled"]);
  }, [user.data]);

  return (
    <Fragment>
      <Animate>
        <Card title='Autenticación de dos factores' restrictWidth className={props.className} loading={user.loading}>

          <Form
            url='/api/user/2fa'
            method='put'
            submitOnChange
            data={{
              '2fa_enabled': {
                type: 'switch',
                label: 'Habilitar la autenticación de dos factores',
                default: decrypted?.['2fa_enabled']
              }
            }}
            callback={res => {

              setQrCode(res.data.data.qr_code);

              if (!res.data.data['2fa_enabled'])
                setEnabled(false);

            }}
          />

          <br></br>
          <br></br>

          {qrCode ?
            <Fragment>
              <Message
                title='Escanear código QR'
                type='info'
                text='Escanee el código QR a continuación con una aplicación de autenticación como Google Authenticator/Microsoft Authenticator e ingrese el código de verificación.' />

              <img src={qrCode} alt='Escanee esto con su aplicación de autenticación' style={{ marginBottom: '1em', marginLeft: '-0.75em' }} />

              <Form
                method='post'
                url='/api/user/2fa/verify'
                buttonText='Verificar'
                data={{
                  code: {
                    label: 'Código de verificación',
                    type: 'text',
                  }
                }}
                callback={res => {
                  setQrCode(null);
                  setEnabled(true);
                  setBackupCode(res.data.data.backup_code)
                }}
              />
            </Fragment> :
            <Fragment>

              {enabled ?
                <Fragment>
                  {backupCode ?
                    <Message
                      title='Tu código de respaldo'
                      type='success'
                      text='Guarde su código de respaldo en un lugar seguro.'>
                      <Form
                        buttonText='Listo'
                        data={{
                          code: {
                            type: 'text',
                            value: backupCode
                          }
                        }}
                        callback={res => {
                          navigate('/dashboard')
                        }}
                      />
                    </Message> :
                    <Message
                      title='2FA habilitado'
                      type='success'
                      text='¡Buen trabajo! La autenticación de dos factores está habilitada en su cuenta.'
                    />}
                </Fragment> :
                <Message
                  title='Habilitar 2FA'
                  type='warning'
                  text={'Recomendamos habilitar 2FA en su cuenta para mayor seguridad.'}
                />
              }
            </Fragment>
          }
        </Card>
      </Animate>
    </Fragment>
  )


  /*const context = useContext(AuthContext);
  const views = [];
  const navigate = useNavigate()

  if (!context.permission) {
    navigate('/dashboard');
  }

  //Check if rol is user to skip invite user (Check this Warning)
  if (context.permission && context.permission.user & !context.permission.admin) {
    navigate(props.onFinish || '/dashboard');
  }

  if (context.permission && context.permission.admin) {
    views.push({
      name: 'Invita a tu equipo',
      description: 'Puedes invitar a los miembros de tu equipo',
      component: <Recomendamos habilitar 2FA en su cuenta para mayor seguridad />,
    });
  }

  if (context.permission && context.user.duplicate_user) {
    views.unshift({
      name: 'Important!',
      description: '',
      component: <DuplicateUser />
    })
  }

  return <Onboarding save onFinish='/dashboard' views={views} />*/

}

function DuplicateUser() {
  return (
    <Message
      type='warning'
      title={`You already have an account`}
      text='We noticed you have already registered an account, so we used your original password to save you any confusion in the future.'
    />
  )
}

function InviteUsers(props) {
  return (

    <Form
      data={{
        /*header: {
          type: 'header',
          title: '',
          label: 'Nota: Para invitar 1 o más usuarios, separa los emails con una coma.'
        },*/
        email: {
          label: 'Email',
          type: 'email',
          required: true,
        }
      }}
      buttonText='Enviar Invitación'
      url='/api/invite'
      method='POST'
    />
  )
}