import { BackButton } from "components/backButton/button";
import {
  Submit,
  getActivity,
  getBranchXProduct,
  getCities,
  getCitiesLocal,
  getGender,
  getMaritalStatus,
  getNacionality,
  getPerson,
  getProvince,
  getTypeId,
  lstOfac,
} from "components/bridges/BridgeServices";
import useViewPort from "components/hooks/useViewPort";
import {
  Animate,
  Content,
  ProgressSteps,
  ViewContext,
  useAPI,
  useNavigate
} from "components/lib";
import { useContext, useEffect, useState } from "react";
import Style from "../../../css/globalStyle.module.scss";
import { StepOne } from "./steps/stepOne";
import { StepTwo } from "./steps/stepTwo";
import { useDataContext } from "../../../context/DataContext";

export function CandidateAgriculture(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  const navigate = useNavigate();
  const dataCatalog = useAPI("/api/catalog/filtered/agro");
  const productsAgro = useAPI("/api/product/agro");
  const parrishCatalog = useAPI("/api/catalogByName?name=parroquia");
  const viewContext = useContext(ViewContext);
  const [userId, setUserId] = useState("");
  const [options, setOptions] = useState({
    personeria: [],
    filteredTipoId: [],
    tipoId: [],
    genero: [],
    estadoCivil: [],
    provincias: [],
    ciudades: [],
    parroquias: [],
    provinciasDomicilio: [],
    ciudadesDomicilio: [],
    parroquiasDomicilio: [],
    nacionalidades: [],
    tipoCultivo: [],
    tipoRiego: [],
    tipoSemilla: [],
    cultivos: [],
    ciudadesCultivation: [],
    parroquiasCultivation: [],
    actividadesEconomicas: [],
    tipoPago: [],
    tipoPlan: [],
    planOptions: [],
    branchProduct: {},
    parametrosRamo: [],
  });
  //abustamante
  const [step, setStep] = useState(0);
  const [plan, setPlan] = useState();
  const [client, setClient] = useState({
    personeria: "",
    tipoId: "",
    numeroDocumento: "",
    primerNombre: "",
    segundoNombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    fechaNacimiento: "",
    fechaNacimientoSinFormato: "",
    genero: "",
    nacionalidad: "",
    estadoCivil: "",
    correo: "",
    telefono: "",
    celular: "",
    lugarNacimiento: "",
    provincia: "",
    canton: "",
    parroquia: "",
    direccionDomicilio: "",
    callePrincipal: "",
    calleTransversal: "",
    numeracion: "",
    provinciaDomicilio: "",
    cantonDomicilio: "",
    parroquiaDomicilio: "",
    actividadEconomica: "",
    ingresosAnuales: "1000",
    patrimonio: "10000",
    personaPolitica: "",
    razonSocial: "",
    direccion: "",
    representanteLegal: "",
    numeroDocumentoRepresentanteLegal: "",
    estadoMigratorio: "",
    expedicionPasaporte: "",
    ingresoPais: "",
    caducidadPasaporte: "",
    expedicionPasaporteSinFormato: "",
    ingresoPaisSinFormato: "",
    caducidadPasaporteSinFormato: "",
    typePaid: "",
  });
  const [cultivation, setCultivation] = useState({
    provincia: "",
    recinto: "",
    coordenadasX: "",
    coordenadasY: "",
    canton: "",
    calle: "",
    parroquia: "",
    altitud: "",
    cultivoAsegurar: "",
    variedad: "",
    tipoSemilla: "",
    superficie: "",
    costoProduccionTotal: "",
    fechaSiembraVigencia: "",
    fechaSiembraVigenciaSinFormato: "",
    riego: "",
    tipoRiego: "",
    costoEstablecimiento: "",
    fechaSiembra: "",
    fechaSiembraSinFormato: "",
    plan: {},
    coberturas: {},
    tempCoverages: {},
    tasa: "",
    optionsCultivation: {},
    prima: "",
    lavadoActivos: false,
    infoPersonal: false,
    usoDatos: false,
  });
  const [candidateInfo, setCandidateInfo] = useState({});
  const [screenSize] = useViewPort();
  const [loadingTable, setLoadingTable] = useState(false);
  const [flagIsValidEmail, setFlagIsValidEmail] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClient({ ...client, [name]: value });
  };

  const handleInputCultivationChange = (event) => {
    const { name, value } = event.target;
    setCultivation({ ...cultivation, [name]: value });
  };

  const handleInputCostChange = (event) => {
    const { name, value } = event.target;
    setCultivation({ ...cultivation, [name]: value });
  };

  const handleChecks = (event) => {
    const { type } = event;
    setCultivation({ ...cultivation, [type]: event.target.checked });
  };

  const handleDate = (date) => {
    let tempDate = parseDate(date);
    setClient({
      ...client,
      fechaNacimiento: tempDate,
      fechaNacimientoSinFormato: date,
    });
  };

  const handleDatePass = (date) => {
    let tempDate = parseDate(date);
    setClient({
      ...client,
      expedicionPasaporte: tempDate,
      expedicionPasaporteSinFormato: date,
    });
  };

  const handleIncomingDate = (date) => {
    let tempDate = parseDate(date);
    setClient({
      ...client,
      ingresoPais: tempDate,
      ingresoPaisSinFormato: date,
    });
  };

  const handleExpireDate = (date) => {
    let tempDate = parseDate(date);
    setClient({
      ...client,
      caducidadPasaporte: tempDate,
      caducidadPasaporteSinFormato: date,
    });
  };

  const parseDate = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleSelectChange = (event) => {
    //label
    const { value, type } = event;
    setClient({ ...client, [type]: value });
    setPersoneriaOptions(event);
  };

  const handleCityChange = () => {
    setOptions({ ...options, parroquias: [] });
  };

  const handleCityCultivationChange = () => {
    setOptions({ ...options, parroquiasCultivation: [] });
  };

  const handleCityHomeChange = () => {
    setOptions({ ...options, parroquiasDomicilio: [] });
  };

  const setPersoneriaOptions = (per) => {
    client.personeria = per?.value;
    let tmpVec = [];
    let curVec = options.tipoId;
    if (per.value.localeCompare("N") === 0) {
      tmpVec = curVec.filter(
        (ele) => ele.value === "00" || ele.value === "01" || ele.value === "02"
      );
      //setFilteredTipoId(tmpVec)
      options.filteredTipoId = tmpVec;
    }
    if (per.value.localeCompare("J") === 0) {
      tmpVec = curVec.filter((ele) => ele.value === "02");

      //setFilteredTipoId(tmpVec)
      options.filteredTipoId = tmpVec;
    }
  };

  const handleSelectCultivationChange = (event) => {
    const { value, type } = event;
    setCultivation({ ...cultivation, [type]: value });
  };

  const handleChangePlanSelected = (e, planId) => {
    let tmpCultivationPlan = cultivation.optionsCultivation.cultivationPlan.find(
      ele => ele.plan === planId
    )
    setCultivation({
      ...cultivation,
      plan: tmpCultivationPlan
    });
  };

  const handleSelectPlan = (event) => {
    let tempOptions = { ...options };
    tempOptions.tipoPlan = [];
    if (event.detail.cod_producto.tipo.includes("nivelada")) {
      tempOptions.tipoPlan.push({
        value: "nivelada",
        label: "nivelada",
      });
    }
    if (event.detail.cod_producto.tipo.includes("creciente")) {
      tempOptions.tipoPlan.push({
        value: "creciente",
        label: "creciente",
      });
    }

    //label
    setPlan(event.detail);
    setOptions(tempOptions);
  };

  const handleChangeSelectOptions = (event) => {
    if (event.value.length > 1) {
      setClient({ ...client, typePlan: event.value });
    } else {
      setClient({ ...client, smoker: event.value });
    }
  };
  const verificarCedula = (ci) => {
    let isNumeric = true;
    let total = 0,
      individual;

    for (let position = 0; position < 10; position++) {
      // Obtiene cada posición del número de cédula
      // Se convierte a string en caso de que 'ci' sea un valor numérico
      individual = ci.toString().substring(position, position + 1)

      if (isNaN(individual)) {
        return { code: false, message: `No puede ingresar caracteres: ${ci}` };
      } else {
        // Si la posición es menor a 9
        if (position < 9) {
          // Si la posición es par, osea 0, 2, 4, 6, 8.
          if (position % 2 == 0) {
            // Si el número individual de la cédula es mayor a 5
            if (parseInt(individual) * 2 > 9) {
              // Se duplica el valor, se obtiene la parte decimal y se aumenta uno 
              // y se lo suma al total
              total += 1 + ((parseInt(individual) * 2) % 10);
            } else {
              // Si el número individual de la cédula es menor que 5 solo se lo duplica
              // y se lo suma al total
              total += parseInt(individual) * 2;
            }
            // Si la posición es impar (1, 3, 5, 7)
          } else {
            // Se suma el número individual de la cédula al total
            total += parseInt(individual);
          }
        }
      }
    }

    if ((total % 10) != 0) {
      total = (total - (total % 10) + 10) - total;
    } else {
      total = 0;
    }


    if (isNumeric) {
      // El total debe ser igual al último número de la cédula
      // La cédula debe contener al menos 10 dígitos
      if (ci.toString().length != 10) {
        return { code: false, message: `La c\u00E9dula debe ser de: 10 d\u00EDgitos: ${ci}` };
      }

      // El número de cédula no debe ser cero
      if (parseInt(ci, 10) == 0) {
        alert("La c\u00E9dula ingresada no puede ser cero.");
        return { code: false, message: `La c\u00E9dula ingresada no puede ser cero: ${ci}` };
      }

      // El total debe ser igual al último número de la cédula
      if (total != parseInt(individual)) {
        return { code: false, message: `La c\u00E9dula ingresada no es v\u00E1lida: ${ci}` };
      }

      return { code: true, message: `La c\u00E9dula ingresada es v\u00E1lida: ${ci}` };
    }

    // Si no es un número  
    return { code: true, message: `El dato solo puede contener numeros: ${ci}` };
  }

  const validateRUC = (ci) => {
    let numero = ci;
    let numeroProvincias = 24;

    /* Verifico que el campo no contenga letras */
    let ok = 1;
    for (let i = 0; i < numero.length && ok == 1; i++) {
      let n = parseInt(numero.charAt(i));
      if (isNaN(n)) ok = 0;
    }
    if (ok == 0) {
      return { code: false, message: `No puede ingresar caracteres: ${ci}` };
    }

    if (numero.length < 13) {
      return {
        code: false,
        message: `El número ingresado no es válido (logintud<13): ${ci}`,
      };
    }

    /* Los primeros dos digitos corresponden al codigo de la provincia */
    let provincia = numero.substr(0, 2);
    let provinciaInt = parseInt(provincia, 10);
    if (provinciaInt < 1 || provinciaInt > numeroProvincias) {
      return {
        code: false,
        message: `El código de la provincia (dos primeros dígitos) es inválido: ${ci}`,
      };
    }
    /* El tercer dígito es un número menor a 6 (0,1,2,3,4,5). ó 6(publico) ó 9(juridico o extranjero)*/
    let tercerDigito = parseInt(numero.charAt(2));
    if (tercerDigito === 6 || tercerDigito === 7 || tercerDigito === 8)
      return {
        code: false,
        message: `Tercer dígito inválido: ${ci} (${tercerDigito})`,
      };

    return { code: true, message: `RUC válido:${tercerDigito}` };
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const validateClient = async () => {
    setLoadingTable(true)
    //Setting Asesor Name
    const user = JSON.parse(localStorage.getItem("user"));
    client.asesor = user.name;
    //Check ci/ruc validation
    let respCiRucValidation = {};
    if (client.tipoId === "00")
      respCiRucValidation = verificarCedula(client.numeroDocumento);
    if (client.tipoId === "02")
      respCiRucValidation = validateRUC(client.numeroDocumento);
    if (!respCiRucValidation.code && client.tipoId !== "01") {
      viewContext.notification.show(respCiRucValidation.message, "error");
      return;
    }
    if (client.tipoId === '00') {
      if (!client.apellidoPaterno || !client.primerNombre || !client.celular) {
        viewContext.notification.show("Debe completar todos los campos obligatorios", "error");
        return;
      }
      if (!client.genero || !client.nacionalidad || !client.estadoCivil || !client.fechaNacimiento ||
        !client.provinciaDomicilio || !client.cantonDomicilio || !client.actividadEconomica) {
        viewContext.notification.show("Debe seleccionar todos los campos obligatorios", "error");
        return;
      }
    } else {
      if (client.personeria === "J") {
        if (!client.razonSocial || !client.direccion || !client.representanteLegal ||
          !client.numeroDocumentoRepresentanteLegal || !client.actividadEconomica || !client.celular) {
          viewContext.notification.show("Debe completar todos los campos obligatorios", "error");
          return;
        }
      } else {
        if (!client.actividadEconomica || !client.celular) {
          viewContext.notification.show("Debe completar todos los campos obligatorios", "error");
          return;
        }
      }
    }
    //Check if candidate is adult
    let curAge = calcularEdad(client.fechaNacimiento);
    if (curAge < 18) {
      viewContext.notification.show(
        `Edad candidato no permitida: ${curAge}`,
        "error"
      );
      return;
    }
    let flagIsValidEmailTmp = isValidEmail(client.correo);
    setFlagIsValidEmail(isValidEmail(client.correo));
    if (!flagIsValidEmailTmp) {
      viewContext.notification.show(
        `Correo no válido: ${client.correo}`,
        "error"
      );
      return;
    }
    //Send to ListasOfac service
    const dataOfac = JSON.parse(localStorage.getItem("loginListasOfac"));
    let objUserOfac = {
      resource: "/rest/jwt/listasofacext",
      data: {
        tipoCliente: client.personeria,
        tipoId: client.tipoId,
        cedula: client.numeroDocumento,
        primerNombre: client.primerNombre.toUpperCase(),
        segundoNombre: client.segundoNombre.toUpperCase(),
        primerApellido: client.apellidoPaterno.toUpperCase(),
        segundoApellido: client.apellidoMaterno.toUpperCase(),
        idUsuario: dataOfac.idUsuario,
        codigoPlataforma: dataOfac.codigoPlataforma,
        correoUsuario: dataOfac.correoUsuario,
      },
      httpVerb: "POST",
    };
    let objSaveCandidate = {
      product_type: "agro",
      detail: {},
      nro_documento: client.numeroDocumento,
    };

    if (client.personeria === "N") {
      objSaveCandidate.detail = {
        personeria: client.personeria,
        tipo_documento: client.tipoId,
        primer_nombre: client.primerNombre.toUpperCase(),
        segundo_nombre: client.segundoNombre.toUpperCase(),
        apellido_paterno: client.apellidoPaterno.toUpperCase(),
        apellido_materno: client.apellidoMaterno.toUpperCase(),

        fecha_nacimiento: client.fechaNacimiento,
        genero: client.genero.toUpperCase(),
        nacionalidad: client.nacionalidad.toUpperCase(),
        estado_civil: client.estadoCivil.toUpperCase(),

        telefono: client.telefono,
        celular: client.celular,
        correo: client.correo.toUpperCase(),

        lugar_nacimiento: [
          client.provincia,
          client.canton,
          client.parroquia,
        ].join("/"),
        provincia: client.provincia,
        canton: client.canton,
        parroquia: client.parroquia,

        direccion_domicilio: client.direccionDomicilio.toUpperCase(),
        calle_principal: client.callePrincipal.toUpperCase(),
        calle_transversal: client.calleTransversal.toUpperCase(),
        numeracion: client.numeracion.toUpperCase(),

        provincia_domicilio: client.provinciaDomicilio,
        canton_domicilio: client.cantonDomicilio,
        parroquia_domicilio: client.parroquiaDomicilio,

        actividad_economica: client.actividadEconomica.toUpperCase(),
        ingresos_anuales: client.ingresosAnuales,
        patrimonio: client.patrimonio,
        persona_politica: client.personaPolitica.toUpperCase(),

        pasaporte: client.tipoId === "01" ? client.numeroDocumento : "",
        estado_migratorio:
          client.tipoId === "01" ? client.estadoMigratorio.toUpperCase() : "",
        fecha_expedicion_pasaporte:
          client.tipoId === "01" ? client.expedicionPasaporte : "",
        fecha_ultimo_ingreso_pais:
          client.tipoId === "01" ? client.ingresoPais : "",
        fecha_caducidad_pasaporte:
          client.tipoId === "01" ? client.caducidadPasaporte : "",
      };
    } else {
      objSaveCandidate.detail = {
        personeria: client.personeria,
        tipo_documento: client.tipoId,
        razon_social: client.razonSocial.toUpperCase(),
        direccion: client.direccion.toUpperCase(),
        direccion_domicilio: client.direccionDomicilio.toUpperCase(),
        correo: client.correo.toUpperCase(),
        telefono: client.telefono,
        celular: client.celular,
        representante_legal: client.representanteLegal.toUpperCase(),
        numero_documento_representanteLegal:
          client.numeroDocumentoRepresentanteLegal.toUpperCase(),
        actividad_economica: client.actividadEconomica.toUpperCase(),
        ingresos_anuales: client.ingresosAnuales,
        patrimonio: client.patrimonio,
      };
    }

    let objStep = {
      id: userId,
      step: "Paso 1",
      step_one_request: {
        description: "",
      },
      step_one_response: {
        description: "",
      },
    };

    try {
      const resp = await lstOfac(objUserOfac);

      if (resp?.message?.data?.Info === "Cliente en revisión.") {
        viewContext.notification.show("Cliente en revisión", "error");
        return;
      }
      if (resp?.message?.data?.Info === "Cliente no aprobado.") {
        viewContext.notification.show("Cliente no aprobado", "error");
        return;
      }

      if (resp?.message?.data?.data?.Info === "OK") {
        viewContext.notification.show("Validación exitosa", "success", true);
        setStep(1);
      }

      await Submit("candidate", objSaveCandidate, "post")
        .then((value) => {
          objStep.id = value.data.data[0].id;
          setUserId(value.data.data[0].id);
          objStep.step_one_request.description = objUserOfac;
          objStep.step_one_response = resp;
          setCandidateInfo(objSaveCandidate.detail);
          setLoadingTable(false);
        })
        .catch((error) => {
          viewContext.notification.show(error, "error");
          setLoadingTable(false);
        })
        .finally(() => { });

      await Submit("candidate/updateStep", objStep, "patch")
        .then((value) => { })
        .catch((error) => {
          viewContext.handleError(error);
        })
        .finally(() => {
          viewContext.handleError(
            "The Promise is settled, meaning it has been resolved or rejected."
          );
        });
    } catch (error) {
      viewContext.handleError(error);
    }
  };

  let isValidating = false;
  const validateCultivation = () => {
    if (isValidating) return;
    isValidating = true;
    let valorAsegurado = (cultivation.superficie * cultivation.costoProduccionTotal);
    let activeCycle = false, ensuredValue = false, validateArea = false;
    let estado = "Pendiente";
    let valueOb = "La suma asegurada no es menor a los valores de la lista de costos de producción.";
    let cycleOb = "La fecha de siembra no se encuentra dentro del ciclo.";
    let areaOb = "El área de siembra es mayor al área de siembra configurada.";
    // Technical improvment: Leo Muñoz Mar-2024
    if (cultivation?.optionsCultivation?.cultivationCycle != null &&
      cultivation?.optionsCultivation?.cultivationValueInsured != null) {
      // Validate Cultivos por ciclo
      for (const [key, value] of Object.entries(cultivation?.optionsCultivation.cultivationCycle)) {
        if (value.estado.toString().toLowerCase() === "activo") {
          const start = new Date(value.dateBegin);
          const end = new Date(value.dateEnd);
          if (cultivation?.fechaSiembraVigenciaSinFormato >= start && cultivation?.fechaSiembraVigenciaSinFormato <= end) {
            activeCycle = true;
            cycleOb = "";
          } else
            activeCycle = false;
        } else
          activeCycle = false;
      }
      // Validate Cultivos por valor asegurado
      let tempProvinces = parrishCatalog.data;
      for (const [key, value] of Object.entries(tempProvinces.detail)) {
        if (cultivation?.provincia === key) {
          let provincia = value.provincia;
          let findedProvValue = cultivation.optionsCultivation.cultivationValueInsured.find(ele => ele.province === provincia);
          if (Number(cultivation.costoProduccionTotal) <= Number(findedProvValue.valueMax)) {
            ensuredValue = true;
            valueOb = "";
          }
        }
      }
      // Validate Cultivos por plan
      let areaMinima = parseInt(cultivation.plan.area_minima);
      let superficie = parseInt(cultivation.superficie);
      if (superficie <= areaMinima) {
        areaOb = "";
        validateArea = true;
      } else {
        validateArea = false;
      }
    }
    let validation;
    // If the 3 rules are met, then the status of the quote is updated to 'Aprobado Automático', 
    // otherwise it remains 'Pendiente' status.
    if (activeCycle && ensuredValue && validateArea) {
      let objPrima = calcularPrima(valorAsegurado);
      estado = "Aprobado Automático";
      validation = {
        estado: estado,
        valorAsegurado: valorAsegurado,
        primaNeta: objPrima.primaNeta,
        seguro_campesino: objPrima.seguro_campesino,
        supercias: objPrima.supercias,
        derechoEmision: objPrima.derechoEmision,
        prima: objPrima.prima,
        tasa: objPrima.tasa,
        vigencia: objPrima.vigencia,
        dias_vigencia_cotizacion: options.parametrosRamo[0].vigenciaCotizacion,
      };
    } else {
      validation = {
        estado: estado,
        valorAsegurado: valorAsegurado,
        observaciones: `${cycleOb} ${valueOb} ${areaOb}`,
        dias_vigencia_cotizacion: options.parametrosRamo[0].vigenciaCotizacion,
      };
    }
    //saveState(estado);
    return validation;
  };

  const calcularPrima = (valorAsegurado) => {
    let vigencia, tasaVigencia;
    for (const [key, value] of Object.entries(options.cultivos)) {
      if (cultivation.cultivoAsegurar === value.value) {
        vigencia = value.vigencia;
        tasaVigencia = (cultivation.plan.tax * vigencia) / 365;
      }
    }
    const primaNeta = (valorAsegurado * tasaVigencia) / 100;
    let tasaSuper = 0,
      tasaSeguro = 0,
      derechoEmision = 0;
    if (options.parametrosRamo[0].seguroCampesino) {
      tasaSeguro = (primaNeta * 0.5) / 100;
    }
    if (options.parametrosRamo[0].superCias) {
      tasaSuper = (primaNeta * 3.5) / 100;
    }
    if (primaNeta <= 250.0) {
      derechoEmision = 0.45;
    } else if (primaNeta >= 251.0 && primaNeta <= 500.0) {
      derechoEmision = 0.45;
    } else if (primaNeta >= 501.0 && primaNeta <= 1000.0) {
      derechoEmision = 0.45;
    } else if (primaNeta >= 1001.0 && primaNeta <= 2000.0) {
      derechoEmision = 0.45;
    } else if (primaNeta >= 2001.0 && primaNeta <= 4000.0) {
      derechoEmision = 0.45;
    } else if (primaNeta >= 4001.0) {
      derechoEmision = 0.45;
    }
    const prima = primaNeta + tasaSuper + tasaSeguro + derechoEmision;
    let tempPrima = {
      primaNeta: primaNeta,
      seguro_campesino: tasaSeguro,
      supercias: tasaSuper,
      derechoEmision: derechoEmision,
      prima: prima,
      tasa: cultivation.plan.tax,
      vigencia: vigencia,
    };
    return tempPrima;
  };

  const calcularEdad = (fecha) => {
    let hoy = new Date();
    let cumpleanos = new Date(fecha);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    let m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    return edad;
  };

  const generatePrice = async () => {
    try {
      if (!cultivation.provincia || !cultivation.canton || !cultivation.parroquia || !cultivation.recinto ||
        !cultivation.calle || !cultivation.coordenadasY || !cultivation.coordenadasX) {
        viewContext.notification.show("Datos del predio vacíos", "error");
        return;
      }
      if (!cultivation.cultivoAsegurar || !cultivation.tipoSemilla || !cultivation.superficie ||
        !cultivation.costoProduccionTotal || !cultivation.fechaSiembraVigencia || !cultivation.riego) {
        viewContext.notification.show("Datos de cultivo vacíos", "error");
        return;
      }
      if (Object.entries(cultivation.plan).length === 0) {
        viewContext.notification.show("Debe seleccionar un plan", "error");
        return;
      }
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;

      let objReq = {
        adt_fecha_cotiz: today,
        provincia_cultivo: cultivation.provincia,
        recinto_cultivo: cultivation.recinto.toUpperCase(),
        coordenadasX_cultivo: cultivation.coordenadasX,
        coordenadasY_cultivo: cultivation.coordenadasY,
        canton_cultivo: cultivation.canton,
        calle_cultivo: cultivation.calle.toUpperCase(),
        parroquia_cultivo: cultivation.parroquia,
        altitud_cultivo: cultivation.altitud,
        cultivo_Asegurar: cultivation.cultivoAsegurar,
        variedad: cultivation.variedad.toUpperCase(),
        tipo_semilla: cultivation.tipoSemilla.toUpperCase(),
        superficie: cultivation.superficie,
        costo_produccion_total: cultivation.costoProduccionTotal,
        fecha_siembra_vigencia: cultivation.fechaSiembraVigencia,
        riego: cultivation.riego.toUpperCase(),
        tipo_riego: cultivation.tipoRiego.toUpperCase(),
        costo_establecimiento: cultivation.costoEstablecimiento,
        fecha_siembra: cultivation.fechaSiembra,
        plan: cultivation.plan,
        coberturas: cultivation.coberturas,
        options_cultivation: cultivation.optionsCultivation
      };

      let objStep = {
        id: userId,
        step: "Paso 2",
        step_two_request: {
          description: objReq
        },
        step_two_response: {
          description: ""
        },
        product: {}
      };

      objStep.step_two_response.description = validateCultivation();

      const product = {
        candidateInfo,
        cultivationInfo: objStep.step_two_request.description,
        cotizacionResponse: objStep.step_two_response.description
      };
      objStep.product = product;

      if (objStep.step_two_response.description !== undefined) {
        objStep.estado = objStep.step_two_response.description?.estado;
        await Submit("candidate/updateStep", objStep, "patch")
          .then((value) => {
            viewContext.notification.show(value.data.message, "success", true);
            navigate("/vendedor/agricola");
          })
          .catch((error) => {
            viewContext.handleError(error);
          })
          .finally(() => {
            viewContext.handleError(
              "The Promise is settled, meaning it has been resolved or rejected."
            );
          });
      }

    } catch (error) {
      viewContext.handleError(error);
    }
  };

  const getOptions = async () => {
    try {
      let tempOptions = {
        ...options,
      };
      const respIdV2 = localStorage.getItem('typeid')
      let respIdJson;
      if (respIdV2) {
        respIdJson = JSON.parse(respIdV2)?.data
      }
      const respId = state?.typeid?.data ? state.typeid.data : respIdJson ? respIdJson : await getTypeId();
      //
      const respPersonV2 = localStorage.getItem('person')
      let respPersonJson;
      if (respPersonV2) {
        respPersonJson = JSON.parse(respPersonV2)?.data
      }
      const respPerson = state?.person?.data ? state.person.data : respPersonJson ? respPersonJson : await getPerson();
      //
      const respGenderV2 = localStorage.getItem('gender')
      let respGenderJson;
      if (respGenderV2) {
        respGenderJson = JSON.parse(respGenderV2)?.data
      }
      const respGender = state?.gender?.data ? state.gender.data : respGenderJson ? respGenderJson : await getGender();
      //
      const respMaritalStatusV2 = localStorage.getItem('maritalStatus')
      let respMaritalStatusJson;
      if (respMaritalStatusV2) {
        respMaritalStatusJson = JSON.parse(respMaritalStatusV2)?.data
      }
      const respMaritalStatus = state?.maritalStatus?.data ? state.maritalStatus.data : respMaritalStatusJson ? respMaritalStatusJson : await getMaritalStatus();
      //
      const respProvincesV2 = localStorage.getItem('provinces')
      let respProvincesJson;
      if (respProvincesV2) {
        respProvincesJson = JSON.parse(respProvincesV2)?.data
      }
      const respProvinces = state?.provinces?.data ? state.provinces.data : respProvincesJson ? respProvincesJson : await getProvince();
      //
      const respNacionalityV2 = localStorage.getItem('nacionalities')
      let respNacionalityJson;
      if (respNacionalityV2) {
        respNacionalityJson = JSON.parse(respNacionalityV2)?.data
      }
      const respNacionality = state?.nacionalities?.data ? state.nacionalities.data : respNacionalityJson ? respNacionalityJson : await getNacionality();
      //
      const respActivitesV2 = localStorage.getItem('activities')
      let respActivitesJson;
      if (respActivitesV2) {
        respActivitesJson = JSON.parse(respActivitesV2)?.data
      }
      const respActivites = state?.activities?.data ? state.activities.data : respActivitesJson ? respActivitesJson : await getActivity();

      respPerson.map((item) => {
        tempOptions.personeria.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "personeria",
        });
      });
      respId.map((item) => {
        tempOptions.tipoId.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "tipoId",
        });
      });
      respGender.map((item) => {
        tempOptions.genero.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "genero",
        });
      });
      respMaritalStatus.map((item) => {
        tempOptions.estadoCivil.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "estadoCivil",
        });
      });
      respProvinces.map((item) => {
        tempOptions.provincias.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "provincia",
        });
        tempOptions.provinciasDomicilio.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "provinciaDomicilio",
        });
      });
      respNacionality.map((item) => {
        tempOptions.nacionalidades.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "nacionalidad",
        });
      });
      respActivites.map((item) => {
        tempOptions.actividadesEconomicas.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "actividadEconomica",
        });
      });
      let obj = {
        ramo: "20",
        ahorro: "S",
      };
      const respBranchXProductV2 = localStorage.getItem('branchXProducts')
      let respBranchXProductJson;
      if (respBranchXProductV2) {
        respBranchXProductJson = JSON.parse(respBranchXProductV2)?.data
      }
      const respBranchXProduct = state?.branchXProducts?.data ? state.branchXProducts.data :
        respBranchXProductJson ? respBranchXProductJson : await getBranchXProduct(obj);
      tempOptions.branchProduct = respBranchXProduct;
      setOptions(tempOptions);
    } catch (error) {
      console.error(error);
      viewContext.handleError(error);
    }
  };

  const setDataOptions = () => {
    let tempCultivation = { ...cultivation };
    let existCultivation = false;
    productsAgro.data[0].detail.cultivo.map((itemCultivo) => {
      if (itemCultivo.name === cultivation.cultivoAsegurar) {
        tempCultivation.optionsCultivation = itemCultivo;
        setCultivation({
          ...cultivation,
          optionsCultivation: itemCultivo,
        });

        existCultivation = true;
      }
    });
    if (!existCultivation) {
      tempCultivation.optionsCultivation = {};
    }
    setCultivation(tempCultivation);
  };

  const getParrishOptions = async () => {
    if (parrishCatalog.data) {
      let tempCatalog = parrishCatalog.data;
      let tempOptions = [];
      for (const [key, value] of Object.entries(tempCatalog.detail)) {
        if (key === client.provincia) {
          let prov = value;
          for (const [key, value] of Object.entries(prov.cantones)) {
            if (key === client.canton) {
              Object.keys(value.parroquias).map((parr) => {
                tempOptions.push({
                  value: parr,
                  label: value.parroquias[parr],
                  codigoProvincia: client.provincia,
                  codigoCanton: client.canton,
                  codigoParroquia: parr,
                  nombreParroquia: value.parroquias[parr],
                  type: "parroquia",
                });
              });
            }
          }
        }
      }
      if (
        tempOptions.length > 0 &&
        tempOptions[0].codigoProvincia === client.provincia
      ) {
        setOptions({ ...options, parroquias: tempOptions });
      } else {
        setOptions({ ...options, parroquias: [] });
        setClient({ ...client, parroquia: "" });
      }
      tempOptions = [];
    }
  };

  const getCitiesHomeOptions = async () => {
    try {
      const respCiudadesV2 = localStorage.getItem('ciudades')
      let respCiudadesJson;
      if (respCiudadesV2) {
        respCiudadesJson = JSON.parse(respCiudadesV2)?.data
      }
      const respCiudades = state?.ciudades ? state.ciudades : respCiudadesJson ? respCiudadesJson : await getCitiesLocal();
      let tmpResp = getValueByKey(respCiudades?.data ? respCiudades.data : respCiudades, client.provinciaDomicilio);

      setOptions({ ...options, ciudadesDomicilio: [] });
      let tempCities = [];
      tmpResp.map((item) => {
        tempCities.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          astr_id: item.astr_id,
          astr_descripcion: item.astr_descripcion,
          astr_padre: item.astr_padre,
          type: "cantonDomicilio",
        });
      });
      setOptions({ ...options, ciudadesDomicilio: tempCities });
    } catch (error) { }
  };

  const getParrishHomeOptions = async () => {
    if (parrishCatalog.data) {
      let tempCatalog = parrishCatalog.data;
      let tempOptions = [];
      for (const [key, value] of Object.entries(tempCatalog.detail)) {
        if (key === client.provinciaDomicilio) {
          let prov = value;
          for (const [key, value] of Object.entries(prov.cantones)) {
            if (key === client.cantonDomicilio) {
              Object.keys(value.parroquias).map((parr) => {
                tempOptions.push({
                  value: parr,
                  label: value.parroquias[parr],
                  codigoProvincia: client.provincia,
                  codigoCanton: client.canton,
                  codigoParroquia: parr,
                  nombreParroquia: value.parroquias[parr],
                  type: "parroquiaDomicilio",
                });
              });
            }
          }
        }
      }
      if (tempOptions.length > 0) {
        setOptions({ ...options, parroquiasDomicilio: tempOptions });
      } else {
        setOptions({ ...options, parroquiasDomicilio: [] });
        setClient({ ...client, parroquiasDomicilio: "" });
      }
      tempOptions = [];
    }
  };

  const getCitiesCultivationOptions = async () => {
    try {
      const respCiudadesV2 = localStorage.getItem('ciudades')
      let respCiudadesJson;
      if (respCiudadesV2) {
        respCiudadesJson = JSON.parse(respCiudadesV2)?.data
      }
      const respCiudades = state?.ciudades ? state.ciudades : respCiudadesJson ? respCiudadesJson : await getCitiesLocal();
      let tmpResp = getValueByKey(respCiudades?.data ? respCiudades.data : respCiudades, cultivation.provincia);

      setOptions({ ...options, ciudadesCultivation: [] });
      setCultivation({ ...cultivation, canton: "" });
      let tempCities = [];
      tmpResp.map((item) => {
        tempCities.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          astr_id: item.astr_id,
          astr_descripcion: item.astr_descripcion,
          astr_padre: item.astr_padre,
          type: "canton",
        });
      });
      setOptions({ ...options, ciudadesCultivation: tempCities });
    } catch (error) { }
  };

  const getParrishCultivationOptions = async () => {
    if (parrishCatalog.data) {
      let tempCatalog = parrishCatalog.data;
      let tempOptions = [];
      for (const [key, value] of Object.entries(tempCatalog.detail)) {
        if (key === cultivation.provincia) {
          let prov = value;
          for (const [key, value] of Object.entries(prov.cantones)) {
            if (key === cultivation.canton) {
              Object.keys(value.parroquias).map((parr) => {
                tempOptions.push({
                  value: parr,
                  label: value.parroquias[parr],
                  codigoProvincia: cultivation.provincia,
                  codigoCanton: cultivation.canton,
                  codigoParroquia: parr,
                  nombreParroquia: value.parroquias[parr],
                  type: "parroquia",
                });
              });
            }
          }
        }
      }
      if (tempOptions.length > 0) {
        setOptions({ ...options, parroquiasCultivation: tempOptions });
      } else {
        setOptions({ ...options, parroquiasCultivation: [] });
        setClient({ ...client, parroquia: "" });
      }
      tempOptions = [];
    }
  };

  const getDataAgro = () => {
    // cultivos catalog section
    let tempCatalog = dataCatalog.data.find(item => item.name === "cultivos");
    if (!tempCatalog) {
      console.log('No "cultivos" found in the catalog.');
      return;
    }
    let tempOptions = { ...options };
    tempCatalog.detail.detail.forEach(cultivo => {
      if (!cultivo) return
      tempOptions.cultivos.push({
        value: cultivo.nombre_del_cultivo,
        label: cultivo.nombre_del_cultivo,
        nombre_del_cultivo: cultivo.nombre_del_cultivo,
        tipo_del_cultivo: cultivo.tipo_del_cultivo,
        vigencia: cultivo.vigencia,
        type: "cultivoAsegurar",
      });
    });
    // tipo_semilla catalog section
    tempCatalog = dataCatalog.data.filter(
      (item) => item.name === "tipo_semilla"
    );
    tempCatalog[0].detail.detail.map((item) => {
      tempOptions.tipoSemilla.push({
        value: item.codigo,
        label: item.descripcion,
        codigo: item.codigo,
        descripcion: item.descripcion,
        type: "tipoSemilla",
      });
    });
    tempCatalog = dataCatalog.data.filter((item) => item.name === "tipo_riego");
    tempCatalog[0].detail.detail.map((item) => {
      tempOptions.tipoRiego.push({
        value: item.codigo,
        label: item.descripcion,
        codigo: item.codigo,
        descripcion: item.descripcion,
        type: "tipoRiego",
      });
    });
    tempCatalog = dataCatalog.data.filter(
      (item) => item.name === "tipo_cultivo"
    );
    tempCatalog[0].detail.detail.map((item) => {
      tempOptions.tipoCultivo.push({
        value: item.codigo,
        label: item.descripcion,
        codigo: item.codigo,
        descripcion: item.descripcion,
      });
    });
    tempCatalog = dataCatalog.data.filter(
      (item) => item.name === "parametros_ramo"
    );
    tempCatalog[0].detail.detail.map((item) => {
      tempOptions.parametrosRamo.push({
        seguroCampesino: item.contribuciones.seguro_campesino,
        superCias: item.contribuciones.supercias,
        vigenciaCotizacion: item.dias_vigencia_cotizacion,
      });
    });

    setOptions(tempOptions);
  };

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    getCitiesHomeOptions();
  }, [client.provinciaDomicilio]);

  useEffect(() => {
    getParrishHomeOptions();
  }, [client.cantonDomicilio]);

  /*useEffect(() => {
    getCitiesOptions();
  }, [client.provincia]);*/

  useEffect(() => {
    getParrishOptions();
  }, [client.canton]);

  useEffect(() => {
    getParrishOptions();
  }, [parrishCatalog]);

  useEffect(() => {
    getCitiesCultivationOptions();
  }, [cultivation.provincia]);

  useEffect(() => {
    getParrishCultivationOptions();
  }, [cultivation.canton]);

  useEffect(() => {
    if (dataCatalog.data) {
      getDataAgro();
    }
  }, [dataCatalog]);

  useEffect(() => {
    if (productsAgro.data) setDataOptions();
  }, [cultivation.cultivoAsegurar]);

  const stepsCandidate = [
    {
      name: "Información del candidato",
      url: "/vendedor/agricola/candidato",
      completed: true,
    },
    {
      name: "Datos del predio y cultivo",
      url: "/vendedor/agricola/candidato#",
      completed: step >= 1 ? true : false,
    },
  ];

  function getValueByKey(obj, key) {
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    } else {
      return undefined;
    }
  }

  return (
    <Animate type='pop'>
      <div className={Style.buttonPosition}>
        <BackButton
          action={() => {
            if (step === 0) {
              navigate(-1);
              // localStorage.removeItem("oldPageData");
            } else {
              setStep(step - 1);
            }
          }} />
      </div>
      <section>
        <Content>
          <section style={{ padding: "20px 0px" }}>
            {screenSize.dynamicWidth > 768 ? (
              <ProgressSteps items={stepsCandidate} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className={Style.circle}>{step + 1}</div>
                <p className={Style.textStep}>{stepsCandidate[step].name}</p>
              </div>
            )}
          </section>
          {/* <div>
            <Button text='Atrás' color="blue" action={() => setStep(step - 1)} />
          </div>
          <div style={{ paddingTop: 5 }}>
            <Button text='Siguiente' color="blue" action={() => setStep(step + 1)} />
          </div> */}
          {step === 0 && (
            <StepOne
              options={options}
              client={client}
              handleSelectChange={handleSelectChange}
              handleCityChange={handleCityChange}
              handleCityHomeChange={handleCityHomeChange}
              handleInputChange={handleInputChange}
              handleDate={handleDate}
              handleDatePass={handleDatePass}
              handleIncomingDate={handleIncomingDate}
              handleExpireDate={handleExpireDate}
              validateClient={validateClient}
              loadingTable={loadingTable}
              flagIsValidEmail={flagIsValidEmail}
            />
          )}
          {step === 1 && (
            <StepTwo
              cultivation={cultivation}
              setCultivation={setCultivation}
              options={options}
              plan={plan}
              generatePrice={generatePrice}
              handleInputCultivationChange={handleInputCultivationChange}
              handleInputCostChange={handleInputCostChange}
              handleCityCultivationChange={handleCityCultivationChange}
              handleChecks={handleChecks}
              // handleChangeAlternativeSelected={handleChangeAlternativeSelected}
              handleChangePlanSelected={handleChangePlanSelected}
              handleSelectPlan={handleSelectPlan}
              handleChangeSelectOptions={handleChangeSelectOptions}
              handleSelectCultivationChange={handleSelectCultivationChange}
              productsAgro={productsAgro}
              dataCatalog={dataCatalog}
            />
          )}
        </Content>
      </section>
    </Animate>
  );
}
