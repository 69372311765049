/***
 *
 *   SIGN UP (user)
 *   Signup form for child users
 *
 **********/

import React, { useContext } from "react";
import {
  Animate,
  Row,
  AuthContext,
  Card,
  Form,
  Link
} from "components/lib";

export function SignupUser(props) {
  const context = useContext(AuthContext);
  const url = window.location.href;
  const tokenStr = url.substring(url.indexOf("?id=") + 4);
  const token = parseJwt(tokenStr);
  const id = token?.id;
  const email = token?.email;
  const nombre = token?.nombre;
  const apellido = token?.apellido;
  const fullName = nombre + ' ' + apellido;
  const expInSeconds = token?.exp;
  const expInMilliseconds = expInSeconds * 1000;
  let dateN = new Date().getTime();

  return (
    expInMilliseconds > dateN ? <>
      <Animate type='pop'>
        <Row title='Crea tu cuenta'>
          <Card restrictWidth center>
            <Form
              data={{
                name: {
                  label: "Nombre",
                  value: fullName,
                  type: "text",
                  readonly: true
                },

                nombre: {
                  type: "hidden",
                  value: nombre,
                },
                apellido: {
                  type: "hidden",
                  value: apellido,
                },

                email: {
                  label: "Usuario (correo electrónico)",
                  value: email,
                  type: "email",
                  required: true,
                  readonly: true
                },
                password: {
                  label: "Contraseña (8-25 caracteres)",
                  type: "password",
                  required: true,
                  placeholder: "Ingresa tu contraseña",
                  complexPassword: true,
                },
                confirm_password: {
                  label: "Repita Contraseña",
                  type: "password",
                  required: true,
                  placeholder: "Repite tu contraseña",
                  complexPassword: true,
                },
                invite_id: {
                  type: "hidden",
                  value: id,
                },
                onboarded: {
                  type: "hidden",
                  value: false,
                }
              }}
              url='/api/user'
              method='POST'
              redirect='/dashboard'
              buttonText='Crear Cuenta'
              callback={context.signin}
            />

            <div className='mt-4'>
              ¿Ya estás registrado? <Link url='/signin' text='Iniciar Sesión' />
            </div>

          </Card>
        </Row>
      </Animate>
    </> : <>
      <Row title='Token expirado'></Row>
      <Row title='¡El administrador tendrá que volverte a invitar!'></Row>
    </>
  );
}

function parseJwt(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
