import React, { useState } from "react";
import { Label, Error, Icon, ClassHelper } from "components/lib";
import Style from "./input.tailwind.js";

export function PasswordInput(props) {
  const [error, setError] = useState(
    props.errorMessage || "Por favor ingresa tu contraseña"
  );

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  function validate(e) {
    let value = e ? e.target.value : "";
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === "") valid = false;

    // input isn't required and value is blank
    if (!props.required && value === "") valid = true;

    if (props.required && value !== "") valid = true;

    if (!props.required) valid = true;

    // complexity rules
    if (props.complexPassword) {

      let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
      let respValue = strongRegex.test(value);
      if (value.length < 8 && value.length > 25) {
        valid = false;
        setError("La contraseña debe tener al menos 8 caracteres");
      } else if (!respValue) {
        valid = false;
        setError("La contraseña debe tener al menos 1 letra mayúscula, 1 minuscula, 1 caracter especial y 1 numero");
      }
    }

    // update the parent form
    props.onChange?.(props.name, value, valid);
  }

  const passwordStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  return (
    <div className={Style.input}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}

      <input
        placeholder={props.placeholder}
        type={passwordShown ? "text" : "password"}
        id={props.for}
        name={props.name}
        className={passwordStyle}
        onChange={(e) =>
          props.onChange?.(props.name, e.target.value, undefined)
        }
        onBlur={(e) => validate(e)}
      />

      {props.valid === false && <Error message={error} />}

      <span id="grid" onClick={togglePasswordVisiblity}>
        <Icon
          image='eye'
          color='#00'
          className={Style.successIcon}
          size={16} />
      </span>

    </div>
  );
}


