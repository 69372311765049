/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   noPadding: remove the padding
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/

import React from "react";
import { Loader, ClassHelper, Grid, Image, MenuButton } from "components/lib";
import Style from "./menuitem.module.scss";

export function MenuItem(props) {
  const cardStyle = ClassHelper(Style, {
    shadow: props.shadow,
    center: props.center,
    padding: props.padding,
    loading: props.loading,
    className: props.className,
    last: props.last,
    restrictWidth: props.restrictWidth,
  });

  return (
    <section className={cardStyle}>
      <Grid cols='2'>
        <div>
          <Image
            source={props.img}
            title='Gravity Logo'
            alt='Awsome logo'
            className='logo'
          />
        </div>
        <div>
          {props.title && (
            <header className={Style.header}>
              <h1 className={Style.title}>{props.title}</h1>
            </header>
          )}
          <MenuButton
            goto={props.goto}
            iconColor={"#0f265c"}
            iconSize={46}
            icon={"arrow-right"}
            slideRight
          />
        </div>
      </Grid>

      {props.loading ? <Loader /> : props.children}
    </section>
  );
}
