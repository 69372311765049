/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React from "react";
import { MenuCard, Animate } from "components/lib";
import { Box, Grid as GridGroomet, ResponsiveContext } from "grommet";
import Roles from "../../components/logo/images/menu/Roles.svg";
import Usuarios from "../../components/logo/images/menu/Usuarios.svg";
import { useContext } from "react";
import Style from "./profile.module.scss";

// columns, rows and areas are for Grid with a known number of contents / boxes.

// if size if small, we only 1 column
// if size if medium, we only 2 column
// if size if large or xlarge, we 3 three columns
const columns = {
  small: ["auto"],
  medium: ["auto", "auto"],
  large: ["auto", "auto"],
  xlarge: ["auto", "auto"],
};

// if size if small, we have 3 rows
// if size if medium, we have 2 rows
// if size if large or xlarge, we have 1 row
const rows = {
  small: ["auto", "auto", "auto"],
  medium: ["auto", "auto"],
  large: ["auto", "auto"],
  xlarge: ["auto", "auto"],
};

// set the different areas you need for every size
const areas = {
  small: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [0, 1], end: [0, 1] },
    { name: "side3", start: [0, 2], end: [0, 2] },
  ],
  medium: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
  large: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
  xlarge: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
};

// set the different areas you need for every size

const ResponsiveGrid = ({
  children,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {(size) => {
      // take into consideration if not array is sent but a simple string
      let columnsVal = columns;
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size];
        }
      }

      let rowsVal = rows;
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size];
        }
      }

      // also if areas is a simple array not an object of arrays for different sizes
      let areasVal = areas;
      if (areas && !Array.isArray(areas)) areasVal = areas[size];

      return (
        <GridGroomet
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
          justify='center'
        >
          {children}
        </GridGroomet>
      );
    }}
  </ResponsiveContext.Consumer>
);

export function Profile(props) {
  const size = useContext(ResponsiveContext);

  return (
    <Animate type='pop'>
      <div className={Style.mainContainer}>
        <Box
          height={size.indexOf("small") > -1 ? "75vh" : "75vh"}
          justify='center'
          align='center'
        >
          <ResponsiveGrid
            rows={rows}
            columns={columns}
            gap='small'
            areas={areas}
            margin='small'
          >
            <Box gridArea='side1' className={Style.boxContainer}>
              <MenuCard
                name='rol'
                title='Roles'
                goto='/profile/rol'
                icon={Roles}
              />
            </Box>
            <Box gridArea='side2' align='start' className={Style.boxContainer}>
              <MenuCard
                center
                name='users'
                title='Usuarios'
                goto='/account/users'
                icon={Usuarios}
              />
            </Box>
          </ResponsiveGrid>
        </Box>
        <div className={Style.blockMenu}></div>
      </div>
    </Animate>
  );
}
