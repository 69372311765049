import React from 'react';

const Container = ({ children, backgroundColor, padding, height }) => {

  const style = {
    backgroundColor: backgroundColor || '#fff',
    borderRadius: '0.75em',
    padding: padding || '1em',
    height: height || '100%',
  }

  return (
    <div style={style}>
      {children}
    </div>
  );
}

export { Container };