import React, { forwardRef }  from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatNumber } from 'utils/formatNumber';

export const BarChart = forwardRef((props, ref) => {

  let options = { ...props.options };
  options.maintainAspectRatio = false;
  options.responsive = true;
  options.layout = {
    padding: 20
  };
  options.plugins.datalabels = {
    color: 'black',
    anchor: 'end',
    align: 'top',
    clamp: true,
    overlap: true,
    formatter: function (value, context) {
      return `$${formatNumber(value)}`;
    },
    labels: {
      title: {
        font: {
          weight: 'bold',
          family: 'Calibri'
        }
      }
    }
  }

  return <Chart ref={ref} type='bar' data={props.data
  } options={options} plugins={[ChartDataLabels]} />

});
