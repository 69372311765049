import React, { useContext, useState } from "react";
import {
  Animate,
  Card,
  useAPI,
  useLocation,
  Button,
  ViewContext,
} from "components/lib";
import styles from "./priceAgroLife.module.scss";
import LogoColor1 from "../../../components/logo/images/SDP-logo-blanco.svg";
import { postFillFormLight } from "components/bridges/BridgeServices";
import Style from "../life/candidate.module.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import useViewPort from "components/hooks/useViewPort";

export function PriceAgroLife(props) {
  const [screenSize] = useViewPort();
  let url = useLocation();
  const viewContext = useContext(ViewContext);

  const parrishCatalog = useAPI("/api/catalogByName?name=parroquia");

  const dataPrice = url.state;

  let canton = "";
  let parroquia = "";
  const [isLoading, setIsLoading] = useState(false);

  const getParrish = (provCode) => {
    if (parrishCatalog.data) {
      let tempCatalog = parrishCatalog.data;
      let provincia = "";
      for (const [key, value] of Object.entries(tempCatalog.detail)) {
        if (key === provCode) {
          provincia = value.provincia;
          let prov = value;
          for (const [key, value] of Object.entries(prov.cantones)) {
            if (
              key === dataPrice?.step_two_request?.description?.canton_cultivo
            ) {
              canton = value.canton;
              let cant = value;
              for (const [key, value] of Object.entries(cant.parroquias)) {
                if (
                  key ===
                  dataPrice?.step_two_request?.description?.parroquia_cultivo
                ) {
                  parroquia = value;
                }
              }
            }
          }
        }
      }
      return provincia;
    }
  };

  const filterCoberturas = (filter) => {
    let coberturas = dataPrice?.step_two_request?.description?.plan?.coverages;

    if (coberturas) {
      let response = coberturas
        .filter(function (cober) {
          if (cober.astr_principal.localeCompare(filter) !== 0) {
            return true;
          }
          return false;
        })
        .map(function (cober) {
          return cober.astr_cob_descrip_print;
        });
      let response2 = response.reduce(
        (acc, str) =>
          acc.charAt(0) +
          acc.slice(1).toLowerCase() +
          " - " +
          (str.charAt(0) + str.slice(1).toLowerCase())
      );
      return response2;
    } else {
      return "";
    }
  };

  const addDays = (days) => {
    let start =
      dataPrice?.step_two_request?.description?.fecha_siembra_vigencia;
    const result = new Date(start);
    let finish = result.setDate(result.getDate() + Number(days) + 1);
    const date = new Date(finish),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const shapeDocAssemblerDoc5 = async () => {
    let solicitante =
      dataPrice?.detail?.personeria === "J"
        ? dataPrice?.detail?.razon_social?.toUpperCase()
        : dataPrice?.detail?.primer_nombre?.toUpperCase() +
        " " +
        dataPrice?.detail?.apellido_paterno?.toUpperCase();
    let valorAsegurado =
      dataPrice?.step_two_response?.description?.valorAsegurado?.toLocaleString(
        "en-US",
        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      );
    let direccion_predio =
      dataPrice?.step_two_request?.description?.recinto_cultivo +
      "-" +
      dataPrice?.step_two_request?.description?.calle_cultivo +
      " - Altitud:" +
      dataPrice?.step_two_request?.description?.altitud_cultivo +
      "m. - Latitud:" +
      dataPrice?.step_two_request?.description?.coordenadasX_cultivo +
      " - Longitud:" +
      dataPrice?.step_two_request?.description?.coordenadasY_cultivo;
    let fecha_siembra_vigencia =
      dataPrice?.step_two_request?.description?.fecha_siembra_vigencia;
    let fecha_fin_siembra = addDays(
      dataPrice?.step_two_response?.description?.vigencia
    );
    let dias_vigencia_cotizacion =
      dataPrice?.step_two_response?.description?.dias_vigencia_cotizacion !==
        null
        ? dataPrice?.step_two_response?.description?.dias_vigencia_cotizacion
        : dataPrice?.step_two_request?.description?.dias_vigencia_cotizacion;
    let callePrincipal = dataPrice?.detail?.calle_principal
      ? "/" + dataPrice?.detail?.calle_principal
      : "";
    let calleTransversal = dataPrice?.detail?.calle_transversal
      ? "/" + dataPrice?.detail?.calle_transversal
      : "";
    let numeracion = dataPrice?.detail?.numeracion
      ? "/" + dataPrice?.detail?.numeracion
      : "";
    let direccion_domicilio = dataPrice?.detail?.direccion_domicilio + callePrincipal + calleTransversal + numeracion;
    let context = {
      secuencial: dataPrice.secuencial.toString().padStart(4, "0"),
      namePlan: dataPrice?.step_two_request?.description?.plan?.namePlan,
      codePlan: dataPrice?.step_two_request?.description?.plan?.codePlan,
      solicitante: solicitante,
      asegurado: solicitante,
      nro_documento: dataPrice?.nro_documento,
      direccion_domicilio: direccion_domicilio,
      telefono: dataPrice?.detail?.telefono,
      valorAsegurado: valorAsegurado,
      created_at: dataPrice?.created_at.split("T")[0],
      cultivo_Asegurar:
        dataPrice?.step_two_request?.description?.cultivo_Asegurar,
      superficie: dataPrice?.step_two_request?.description?.superficie,
      provincia_cultivo: getParrish(
        dataPrice?.step_two_request?.description?.provincia_cultivo
      ),
      canton: canton,
      parroquia: parroquia,
      direccion_predio: direccion_predio,
      fecha_siembra_vigencia: fecha_siembra_vigencia,
      fecha_fin_siembra: fecha_fin_siembra,
      dias_vigencia_cotizacion: dias_vigencia_cotizacion,
      filterCoberturas: filterCoberturas("X"),
      primaNeta:
        dataPrice?.step_two_response?.description?.primaNeta?.toLocaleString(
          "en-US",
          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        ),
      supercias:
        dataPrice?.step_two_response?.description?.supercias?.toLocaleString(
          "en-US",
          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        ),
      seguro_campesino:
        dataPrice?.step_two_response?.description?.seguro_campesino?.toLocaleString(
          "en-US",
          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        ),
      derechoEmision:
        dataPrice?.step_two_response?.description?.derechoEmision?.toLocaleString(
          "en-US",
          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
        ),
      prima: dataPrice?.step_two_response?.description?.prima?.toLocaleString(
        "en-US",
        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      ),
    };

    let req = {
      type: "cotizacion_agro",
      email: dataPrice.email,
      toEmail: dataPrice.email,
      form_id: dataPrice.id,
      context: context,
    };

    await postFillFormLight(req).then((resp) => {
      viewContext.notification.show("Tabla generada", "success", true);
      setIsLoading(false);
      window.open(resp?.message?.pdf_url, "_blank");
    });
  };

  return (
    <Animate type='pop'>
      <Card>
        <section>
          <div className={styles["container01"]}>
            <div>
              <img className={styles["logo"]} src={LogoColor1} alt='logo' />{" "}
            </div>
          </div>
          <div className={styles["container"]}>
            <br />
            <div className={styles["container04"]}>
              <div
                style={{ width: "100%", textAlign: "center", color: "#0F265C" }}
              >
                <span className={styles["text021"]}>Cotización</span>
              </div>
              <span className={styles["text020"]}>
                N° {dataPrice.secuencial.toString().padStart(4, "0")}
              </span>
              <br />
              <span className={styles["text020"]}>
                La información proporcionada en este documento será de estricta
                confidencialidad, misma que será utilizada por la Compañía para
                la emisión de pólizas, en caso de que el solicitante acepte esta cotización.
              </span>
              <br />
              <div className={styles["container02"]}>
                <span>DETALLE DEL CONTRATANTE</span>
              </div>
              <div className={styles["container05"]}>
                <div className={styles["container06"]}>
                  <span># Cotización</span>
                  <span>
                    {dataPrice.secuencial.toString().padStart(4, "0")}
                  </span>
                </div>
                <div className={styles["container06"]}>
                  <span>Ramo</span>
                  <span>AGROPECUARIO</span>
                </div>
                <div className={styles["container06"]}>
                  <span>Código</span>
                  <span>40</span>
                </div>
                <div className={styles["container06"]}>
                  <span>Producto</span>
                  <span>
                    {dataPrice?.step_two_request?.description?.plan?.namePlan}
                  </span>
                </div>
                <div className={styles["container06"]}>
                  <span>Código</span>
                  <span>
                    {dataPrice?.step_two_request?.description?.plan?.codePlan}
                  </span>
                </div>
              </div>
              <div className={styles["container11"]}>
                <div className={styles["container12"]}>
                  <span>Solicitante</span>
                  <span>
                    {dataPrice?.detail?.personeria === "J"
                      ? `${dataPrice?.detail?.razon_social?.toUpperCase()}`
                      : `${dataPrice?.detail?.primer_nombre?.toUpperCase()} ${dataPrice?.detail?.apellido_paterno?.toUpperCase()}`}
                  </span>
                </div>
                <div className={styles["container12"]}>
                  <span>Asegurado</span>
                  <span>
                    {dataPrice?.detail?.personeria === "J"
                      ? `${dataPrice?.detail?.razon_social?.toUpperCase()}`
                      : `${dataPrice?.detail?.primer_nombre?.toUpperCase()} ${dataPrice?.detail?.apellido_paterno?.toUpperCase()}`}
                  </span>
                </div>
                <div className={styles["container12"]}>
                  <span>Identificación</span>
                  <span>{dataPrice?.nro_documento}</span>
                </div>
                <div className={styles["container12"]}>
                  <span>Grupo</span>
                  <span>28</span>
                </div>
              </div>
              <div className={styles["container16"]}>
                <div className={styles["container17"]}>
                  <span>Dirección</span>
                  <span>
                    {dataPrice?.detail?.direccion_domicilio?.toUpperCase()}
                  </span>
                </div>
                <div className={styles["container18"]}>
                  <span>Teléfono</span>
                  <span>{dataPrice?.detail?.telefono}</span>
                </div>
                <div className={styles["container19"]}>
                  <span>Fax</span>
                  <span>---</span>
                </div>
              </div>
              <div className={styles["container20"]}>
                <div className={styles["container21"]}>
                  <span>Moneda</span>
                  <span>Dólar</span>
                </div>
                <div className={styles["container21"]}>
                  <span>Valor Asegurado</span>
                  <span>
                    $
                    {dataPrice?.step_two_response?.description?.valorAsegurado?.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}
                  </span>
                </div>
                <div className={styles["container23"]}>
                  <span>Fecha de cotización</span>
                  <span>{dataPrice?.created_at.split("T")[0]}</span>
                </div>
              </div>
            </div>
            <br />
            {screenSize.dynamicWidth < 769 ? (
              <Scrollbars
                autoHeight
                autoHeightMin={300}
                autoHeightMax={400}
                style={{
                  width: "100%",
                  // height: "300px",
                }}
              >
                <div className={styles["container24"]}>
                  <div className={styles["container25"]}>
                    <span className={styles["text052"]}>CULTIVO</span>
                    <span className={styles["text054"]}>RIESGO No.</span>
                    <span className={styles["text053"]}>PROVINCIA</span>
                    <span className={styles["text054"]}>CANTÓN</span>
                    <span className={styles["text053"]}>PARROQUIA</span>
                    <span className={styles["text054"]}>
                      DIRECCION DEL PREDIO:
                    </span>
                    <span className={styles["text053"]}>
                      INICIO DE VIGENCIA:
                    </span>
                    <span className={styles["text054"]}>
                      VIGENCIA DE LA COTIZACION:
                    </span>
                  </div>
                  <div className={styles["container26"]}>
                    <span className={styles["text052"]}>ÁREA A ASEGURAR:</span>
                    <div className={styles["container27"]}>
                      <span className={styles["text054"]}>
                        LOTE DE TERRENO{" "}
                        {dataPrice?.step_two_request?.description?.cultivo_Asegurar?.toUpperCase()}
                        , {dataPrice?.step_two_request?.description?.superficie}{" "}
                        HECTÁREAS
                      </span>
                    </div>
                    <span className={styles["text053"]}>
                      {getParrish(
                        dataPrice?.step_two_request?.description
                          ?.provincia_cultivo
                      )}
                    </span>
                    <span className={styles["text054"]}>{canton}</span>
                    <span className={styles["text053"]}>{parroquia}</span>
                    <span className={styles["text054"]}>
                      {
                        dataPrice?.step_two_request?.description
                          ?.recinto_cultivo
                      }{" "}
                      -{" "}
                      {dataPrice?.step_two_request?.description?.calle_cultivo}{" "}
                      - Altitud:{" "}
                      {
                        dataPrice?.step_two_request?.description
                          ?.altitud_cultivo
                      }
                      m. - Latitud:{" "}
                      {
                        dataPrice?.step_two_request?.description
                          ?.coordenadasX_cultivo
                      }{" "}
                      - Longitud:{" "}
                      {
                        dataPrice?.step_two_request?.description
                          ?.coordenadasY_cultivo
                      }
                      <span />
                    </span>
                    <span className={styles["text053"]}>
                      Fecha inicio:{" "}
                      {
                        dataPrice?.step_two_request?.description
                          ?.fecha_siembra_vigencia
                      }{" "}
                      Fecha fin:{" "}
                      {addDays(
                        dataPrice?.step_two_response?.description
                          ?.vigencia
                      )}
                    </span>
                    <span className={styles["text054"]}>
                      {dataPrice?.step_two_response?.description
                        ?.dias_vigencia_cotizacion !== null
                        ? dataPrice?.step_two_response?.description
                          ?.dias_vigencia_cotizacion
                        : dataPrice?.step_two_request?.description
                          ?.dias_vigencia_cotizacion}{" "}
                      días
                    </span>
                  </div>
                </div>
              </Scrollbars>
            ) : (
              <div className={styles["container24"]}>
                <div className={styles["container25"]}>
                  <span className={styles["text052"]}>CULTIVO</span>
                  <span className={styles["text054"]}>RIESGO No.</span>
                  <span className={styles["text053"]}>PROVINCIA</span>
                  <span className={styles["text054"]}>CANTÓN</span>
                  <span className={styles["text053"]}>PARROQUIA</span>
                  <span className={styles["text054"]}>
                    DIRECCION DEL PREDIO:
                  </span>
                  <span className={styles["text053"]}>INICIO DE VIGENCIA:</span>
                  <span className={styles["text054"]}>
                    VIGENCIA DE LA COTIZACION:
                  </span>
                </div>
                <div className={styles["container26"]}>
                  <span className={styles["text052"]}>ÁREA A ASEGURAR:</span>
                  <div className={styles["container27"]}>
                    <span className={styles["text054"]}>
                      LOTE DE TERRENO{" "}
                      {dataPrice?.step_two_request?.description?.cultivo_Asegurar?.toUpperCase()}
                      , {dataPrice?.step_two_request?.description?.superficie}{" "}
                      HECTÁREAS
                    </span>
                  </div>
                  <span className={styles["text053"]}>
                    {getParrish(
                      dataPrice?.step_two_request?.description
                        ?.provincia_cultivo
                    )}
                  </span>
                  <span className={styles["text054"]}>{canton}</span>
                  <span className={styles["text053"]}>{parroquia}</span>
                  <span className={styles["text054"]}>
                    {dataPrice?.step_two_request?.description?.recinto_cultivo}{" "}
                    - {dataPrice?.step_two_request?.description?.calle_cultivo}{" "}
                    - Altitud:{" "}
                    {dataPrice?.step_two_request?.description?.altitud_cultivo}
                    m. - Latitud:{" "}
                    {
                      dataPrice?.step_two_request?.description
                        ?.coordenadasX_cultivo
                    }{" "}
                    - Longitud:{" "}
                    {
                      dataPrice?.step_two_request?.description
                        ?.coordenadasY_cultivo
                    }
                    <span />
                  </span>
                  <span className={styles["text053"]}>
                    Fecha inicio:{" "}
                    {
                      dataPrice?.step_two_request?.description
                        ?.fecha_siembra_vigencia
                    }{" "}
                    Fecha fin:{" "}
                    {addDays(
                      dataPrice?.step_two_response?.description
                        ?.vigencia
                    )}
                  </span>
                  <span className={styles["text054"]}>
                    {dataPrice?.step_two_response?.description
                      ?.dias_vigencia_cotizacion !== null
                      ? dataPrice?.step_two_response?.description
                        ?.dias_vigencia_cotizacion
                      : dataPrice?.step_two_request?.description
                        ?.dias_vigencia_cotizacion}{" "}
                    días
                  </span>
                </div>
              </div>
            )}
            <br />
            <div className={styles["container28"]}>
              <Scrollbars
                autoHeight
                autoHeightMin={150}
                autoHeightMax={400}
                style={{
                  width: "100%",
                }}
              >
                <div className={styles["displayFlex"]}>
                  <div className={styles["textCultivo"]}>
                    <p className={styles["text05"]}>CULTIVO ASEGURADO</p>
                    <p className={styles["text054"]}>
                      LOTE DE TERRENO{" "}
                      {
                        dataPrice?.step_two_request?.description
                          ?.cultivo_Asegurar
                      }
                      , {dataPrice?.step_two_request?.description?.superficie}{" "}
                      HECTAREAS
                    </p>
                  </div>
                  <div className={styles["textCultivo"]}>
                    <p className={styles["text05"]}>VALOR ASEGURADO</p>
                    <p className={styles["text054"]}>
                      {dataPrice?.step_two_response?.description?.valorAsegurado.toLocaleString(
                        "en-US",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}{" "}
                      USD
                    </p>
                  </div>
                </div>
              </Scrollbars>
              <br />
              <Scrollbars
                autoHeight
                autoHeightMin={150}
                autoHeightMax={400}
                style={{
                  width: "100%",
                }}
              >
                <div className={styles["displayFlex"]}>
                  <div className={styles["textCultivo"]}>
                    <div className={styles["text05"]}>
                      <span>TABLA DE COBERTURAS</span>
                    </div>
                    <div className={styles["container30"]}>
                      <span>{filterCoberturas("X")}</span>
                    </div>
                  </div>
                  <div className={styles["textCultivo"]}>
                    <div className={styles["text05"]}>
                      <span>DEDUCIBLES</span>
                    </div>
                    <div className={styles["container30"]}>
                      <p>
                        Coberturas climáticas y adicionales: 15% del valor del
                        siniestro
                      </p>
                      <p>Coberturas biológicas: 25% del valor del siniestro (si aplica)</p>
                    </div>
                  </div>
                </div>
              </Scrollbars>
            </div>
            <Scrollbars
              autoHeight
              autoHeightMin={150}
              autoHeightMax={400}
              style={{
                width: "100%",
              }}
            >
              <>
                <div className={styles["containerPrimaTotal"]}>
                  <div className={styles["textPrima"]}>
                    <span className={styles["text086"]}>
                      {`Prima Total (incluido impuestos): `}
                    </span>
                    <span>
                      $
                      {dataPrice?.step_two_response?.description?.prima?.toLocaleString(
                        "en-US",
                        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                      )}
                    </span>
                    <span>USD</span>
                  </div>
                </div>
                <div className={styles["container44"]}>
                  <div className={styles["container45"]}>
                    <div className={styles["container46"]}>
                      <span className={styles["text089"]}>DETALLE</span>
                    </div>
                    <div className={styles["container47"]}>
                      <span className={styles["text090"]}>VALOR (USD)</span>
                    </div>
                  </div>
                  <div className={styles["container4"]}>
                    <div className={styles["container49"]}>
                      <span>SUMA ASEGURADA:</span>
                    </div>
                    <div className={styles["container50"]}>
                      <span className={styles["text101"]}>
                        {dataPrice?.step_two_response?.description?.valorAsegurado.toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </span>
                    </div>
                  </div>
                  <div className={styles["container4"]}>
                    <div className={styles["container52"]}>
                      <span
                        className={styles["priceAgroLife_container49__+PUTf"]}
                      >
                        PRIMA NETA
                      </span>
                      <span>S.B. 3.5%</span>
                      <span>S.C. 0.5%</span>
                      <span>DE</span>
                      <span className={styles["text097"]}>IVA</span>
                      <span>PRIMA TOTAL</span>
                    </div>
                    <div className={styles["container53"]}>
                      <span className={styles["text100"]}>
                        {dataPrice?.step_two_response?.description?.primaNeta?.toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </span>
                      <span className={styles["text101"]}>
                        {dataPrice?.step_two_response?.description?.supercias?.toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </span>
                      <span className={styles["text101"]}>
                        {dataPrice?.step_two_response?.description?.seguro_campesino?.toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </span>
                      <span className={styles["text101"]}>
                        {dataPrice?.step_two_response?.description?.derechoEmision?.toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </span>
                      <span className={styles["text100"]}>0.0</span>
                      <span className={styles["text101"]}>
                        {dataPrice?.step_two_response?.description?.prima?.toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            </Scrollbars>
            <br />
            <div className={styles["containerDetalle"]}>
              <span className={styles["text05"]}>DETALLES DEL SEGURO</span>
            </div>
            <div className={styles["container55"]}>
              <span>
                Se deberá considerar todo lo señalado en las Condiciones Generales de la póliza, este documento detalla información
                relevante de las Condiciones del Seguro y no reemplaza lo definido en el documento señalado.
              </span>
            </div>
            <div className={styles["container55"]}>
              <span className={styles["text107"]}>
                1.     TIPO DE SEGURO: De acuerdo al cultivo el seguro que se
                aplique puede ser:
                <span />
              </span>
            </div>
            <div className={styles["container55"]}>
              <span>
                a)     SEGURO DE COSTOS DIRECTOS DE PRODUCCIÓN - CULTIVO CICLO
                CORTO
              </span>
            </div>
            <div className={styles["container55"]}>
              <span style={{ textAlign: "justify" }}>
                Seguro de costos directos de producción con ajuste a cosecha,
                que cubre la pérdida de rendimiento derivada de eventos
                amparados en la póliza. Se consideran costos directos de
                producción a los valores invertidos en el cultivo que
                comprenden: semilla, preparación de suelo, mano de obra directa
                del cultivo, fertilizantes e insumos agropecuarios. Los planes
                con cobertura de RENTA PLUS deben contar con asesoría técnica de
                Casas Comerciales comprobable.
              </span>
            </div>
            <div className={styles["container55"]}>
              <span>b)   SEGURO POR PLANTA PARA CULTIVOS PERENNES</span>
            </div>
            <div className={styles["container55"]}>
              <span>
                Seguro por planta que se refiere a la protección del árbol como
                tal, independiente de lo que ocurra con su producción. Se ampara
                la pérdida del árbol como ente productivo.
              </span>
            </div>
            <div className={styles["container55"]}>
              <span className={styles["text109"]}>
                2.     COBERTURAS:
                <span />
              </span>
            </div>
            <div className={styles["container55"]}>
              <span>
                Las coberturas se detallan en el frente de esta cotización. En
                el ciclo verano,los cultivos deben mantener fuentes de agua que
                cubran las necesidades del cultivo.
              </span>
            </div>
            <div className={styles["container55"]}>
              <span>
                En la cobertura de Sequía incluye daños por sequía ocurridos a consecuencia de escasez de agua en
                cultivos bajo riego, debido a: roturas de canal de riego, obstrucción de pozos o roturas en el equipo de riego,
                siempre y cuando estos daños sean a causa de un evento climático, eventos provocados por terremoto,
                erupción volcánica o tsunami (maremoto) si estos eventos están cubiertos en la póliza.
              </span>
            </div>
            <div className={styles["container55"]}>
              <span>
                La cobertura de Exceso de humedad y exceso de lluvia no se limitan a eventos que generen la saturación
                del suelo por un periodo mayor a 72 horas.
              </span>
            </div>
            <div className={styles["container55"]}>
              <span>
                La información referente a las condiciones de esta cotización se encuentran disponibles en la página web de
                Seguros del Pichincha:
                <a href="https://segurosdelpichincha.com/agricola-herramientas-para-comercializar">
                  <strong> https://segurosdelpichincha.com/agricola-herramientas-para-comercializar</strong></a>.
              </span>
            </div>

            <div className={styles["container5"]}>
              <span style={{ textAlign: "justify" }}>Atentamente,</span>
            </div>
            <div className={styles["container5"]}>
              <span style={{ textAlign: "justify" }}>
                SEGUROS DEL PICHINCHA
              </span>
            </div>
            <div className={styles["container5"]}>
              <br></br>
              <span style={{ textAlign: "justify" }}>
                <i><u>CONSIDERACIONES:</u></i>
              </span>
            </div>

            <div className={styles["container55"]}>
              <span style={{ textAlign: "justify", fontStyle: "italic" }}>
                1.    La presente cotización no significa aceptación del riesgo, la emisión de la póliza está sujeta a verificación y análisis
                de la información entregada por parte del cliente, así mismo Seguros del Pichincha se reserva el derecho de
                solicitar información adicional a fin de analizar el riesgo propuesto, en cumplimiento de las normas sobre
                prevención de lavado de activos. En caso que a la cotización el cliente haya acompañado un pago, no obliga a
                Seguros del Pichincha a la emisión de la póliza, si luego del análisis del riesgo, la compañía de seguros decide no
                aceptarlo. En esos casos se realizará el proceso de devolución del pago realizado.
              </span>
            </div>

            <div className={styles["container55"]}>
              <span style={{ textAlign: "justify", fontStyle: "italic" }}>
                2.    Las condiciones y prima que constan en la presente “Cotización” durante su período de validez también puede ser
                modificada en función de la variación de los criterios de riesgo detallados anteriormente.
                realizado.
              </span>
            </div>

            <div className={styles["container55"]}>
              <span style={{ textAlign: "justify", fontStyle: "italic" }}>
                3.    La presente “Cotización” ha sido realizada en función de la información de personal y confidencial proporcionada
                por su titular – cliente, utilizada únicamente para dicha finalidad.
              </span>
            </div>

          </div>
          <div>
            <br></br>
            {!isLoading ? (
              <Button
                color='blue'
                text='Descargar'
                action={() => {
                  shapeDocAssemblerDoc5();
                  setIsLoading(true);
                }}
              />
            ) : (
              <div
                style={{ margin: "0px" }}
                className={Style.ldsDualRing}
              ></div>
            )}
          </div>
        </section>
      </Card>
    </Animate>
  );
}
