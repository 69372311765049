/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   noPadding: remove the padding
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/

import React from "react";
import { Loader, useNavigate } from "components/lib";
import Style from "./menucard.module.scss";
import { Box, Button, Card, Heading } from "grommet";
import { LinkNext } from "grommet-icons";

export function MenuCard(props) {
  const navigate = useNavigate();

  return (
    <Card
      height='100%'
      width='medium'
      background='background'
      hoverIndicator='#e6e8f4'
      justify='center'
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.goto) {
          if (typeof props.goto === 'object') {
              navigate(props.goto.pathname, { state: props.goto.state });
          } else {
              navigate(props.goto);
          }
      }

        if (props.onClick) props.onClick();
      }}
    >
      <Box pad='small' direction='row' width={"100%"} style={{justifyContent: 'center', alignItems: 'center'}}>
        {props.label && (
          <div style={{fontSize: '18px'}}>
          {props.label}
          </div>
        )}
      </Box>
      <Box pad='small' direction='row' width={"100%"}>
        {props.icon && (
          <Box align='center' justify='center' pad={"10px"} width={"50%"}>
            <img src={props.icon} alt='Logo' width={"75%"} />
          </Box>
        )}
        <Box direction='column' pad={"10px"} width={"50%"}>
          <Box height={"70%"}>
            {props.title && (
              <Heading
                textAlign='start'
                color='#282828'
                // size='22px'
                responsive
                size='95%'
                margin='none'
                weight={700}
              >
                {props.title}
              </Heading>
            )}

            {props.loading ? <Loader /> : props.children}
          </Box>
          <Box align='start' height={"30%"}>
            <Button
              style={{
                width: "100%",
                padding: "15px",
                borderRadius: "35px",
                background:
                  "transparent linear-gradient(180deg, #E6E8F4 0%, #95D4E9 100%) 0% 0% no-repeat padding-box",
              }}
            >
              <LinkNext className={Style.arrow} color='#001689' size='medium' />
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
    // <Card
    //   height='small'
    //   width='medium'
    //   background='background'
    //   hoverIndicator='red'
    // >
    //   <CardBody
    //     justify='center'
    //     pad='large'
    //     onClick={(e) => {
    //       e.preventDefault();
    //       e.stopPropagation();

    //       if (props.goto) navigate(props.goto);

    //       if (props.onClick) props.onClick();
    //     }}
    //   >
    //     {props.title && (
    //       <Heading textAlign='center' color='active' level={3} margin='none'>
    //         {props.title}
    //       </Heading>
    //     )}

    //     {props.loading ? <Loader /> : props.children}
    //   </CardBody>
    // </Card>
  );
}
