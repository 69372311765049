import React, { Fragment, useState, useEffect, useContext } from "react";
import {
  Card,
  Table,
  useAPI,
  Animate,
  ViewContext,
  Button,
} from "components/lib";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  getFilterDataPricesAgro,
  getCandidateAgroCsvList,
} from "../../components/bridges/BridgeServices";
import "../../css/DateRangePicker.css";
import Style from "../../css/globalStyle.module.scss";
import { BackButton } from "components/backButton/button";
import { Scrollbars } from "react-custom-scrollbars-2";

export function Qualification(props) {
  const parrishCatalog = useAPI("/api/catalogByName?name=parroquia");

  const context = useContext(ViewContext);
  const [rol, setRol] = useState([]);
  const [data, setData] = useState({});
  const [dataSeach, setDataSearch] = useState({
    number: "",
    dateBegin: "",
    dateEnd: "",
    date: "",
    cultivo: "",
    channel: "",
  });

  const handleSearchData = async () => {
    let obj = {
      tramite: dataSeach.number,
      fecha_desde: dataSeach.dateBegin,
      fecha_hasta: dataSeach.dateEnd,
      product_type: "agro",
      cultivo: dataSeach.cultivo,
      canal: dataSeach.channel,
    };

    await getFilterDataPricesAgro(obj).then((res) => {
      if (res?.data) {
        setData(res?.data);
      } else {
        context.notification.show("No existen registros", "error", true);
        setData([]);
      }
    });
  };

  const getCycle = (e) => {
    if (e.length !== 0) {
      for (const [key, value] of Object.entries(e)) {
        if (value.estado === "Activo") {
          return value.nameCycle;
        }
      }
    } else {
      return "";
    }
  };

  const getCanton = (provincia, canton) => {
    if (parrishCatalog.data) {
      let tempCatalog = parrishCatalog.data;
      for (const [key, value] of Object.entries(tempCatalog.detail)) {
        if (key === provincia) {
          let prov = value;
          for (const [key, value] of Object.entries(prov.cantones)) {
            if (key === canton) {
              return value.canton;
            }
          }
        }
      }
    }
  };

  const parseDate = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleDate = (date) => {
    if (date === null) {
      setDataSearch({
        ...dataSeach,
        date: null,
      });
    } else {
      setDataSearch({
        ...dataSeach,
        dateBegin: parseDate(date[0]),
        dateEnd: parseDate(date[1]),
        date,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDataSearch({ ...dataSeach, [name]: value });
  };

  async function generateCsvList() {
    let secuencial = rol
      .map(function (elem) {
        return elem.secuencial;
      })
      .join("-");
    let respCsv = await getCandidateAgroCsvList(secuencial);
    window.location.href = respCsv?.data;
  }

  useEffect(() => {
    let list = [];
    if (data?.length) {
      list = data.map((x) => {
        let tmpSumaAsegurada = x.suma_asegurada ? x.suma_asegurada : 0;
        return {
          id: x.id,
          secuencial: x.secuencial,
          tramite: x.tramite,
          canal: x.canal,
          estado: x.estado_res.charAt(0).toUpperCase() + x.estado_res.slice(1).toLowerCase(),
          cultivo: x.cultivo?.toUpperCase(),
          canton_name: getCanton(x.provincia, x.canton),
          fecha_siembre: x.fecha_siembre,
          ciclo: getCycle(x.ciclo),
          suma_asegurada: `$${tmpSumaAsegurada.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
          observaciones: x?.observaciones_res,
          observacion_cal: x.observacion_cal,
        };
      });
    }
    setRol(list);
  }, [data]);

  let tmpRols = {
    header: [
      { name: "id", title: "ID", sort: true },
      { name: "tramite", title: "Trámite", sort: true },
      { name: "canal", title: "Canal", sort: true },
      { name: "estado", title: "Estado", sort: true },
      { name: "cultivo", title: "Cultivo", sort: true },
      { name: "canton_name", title: "Cantón", sort: true },
      { name: "fecha_siembre", title: "Fecha de siembra", sort: true },
      { name: "ciclo", title: "Ciclo", sort: true },
      { name: "suma_asegurada", title: "Suma Asegurada", sort: true },
      { name: "observaciones", title: "Observaciones", sort: true },
      { name: "actions", title: "Calificar", sort: false },
      //{ name: "observacion_cal", title: "Observación Calificador", sort: true }
    ],
    body: rol,
  };

  function editCandidate(data, callback) {
    context.modal.show(
      {
        title: "Calificar",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          secuencial: {
            label: "Trámite",
            type: "text",
            required: true,
            readonly: true,
            value: data.tramite,
          },
          observaciones: {
            label: "Observaciones",
            type: "text",
            required: true,
            value: "",
            errorMessage: "Por favor ingresa una observación",
          },
          estado: {
            label: "Estado",
            type: "radio",
            options: ["Aprobar", "Negar"],
            required: true,
            errorMessage: "Por favor selecciona una opción",
          },
        },
        buttonText: "Actualizar",
        url: "api/candidate/updateStepAgro",
        method: "PATCH",
      },
      (res) => {
        context.notification.show(
          data.tramite + " ha sido actualizado",
          "success",
          true
        );
        callback(res);
        window.location.reload(true);
      }
    );
  }

  return (
    <Fragment>
      <Animate>
        <div className={Style.buttonPosition}>
          <BackButton go={-1} />
        </div>
        {/* <TitleRow title='Calificación de Riesgo'></TitleRow> */}
        <div className={Style.paddingLeft}>
          <div style={{ padding: "15px 0px 15px 25px" }}>
            <p className={Style.textTitle}>Calificación de riesgo</p>
          </div>
          <Card>
            <div style={{ paddingBottom: 20 }}>
              <div className={Style.qualificationInputSection}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <label>Número de solicitud:</label>
                  <input
                    className={Style["input_number"]}
                    value={dataSeach.number}
                    name='number'
                    type='text'
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <label>Canal:</label>
                  <input
                    className={Style["input"]}
                    value={dataSeach.channel}
                    name='channel'
                    type='text'
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <label>Cultivo:</label>
                  <input
                    className={Style["input"]}
                    value={dataSeach.cultivo}
                    name='cultivo'
                    type='text'
                    onChange={handleInputChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <label>Filtrar por fecha de siembra:</label>
                  <DateRangePicker
                    onChange={handleDate}
                    value={dataSeach.date}
                    required
                  />
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <Button
                    color='blue'
                    text='Buscar'
                    action={handleSearchData}
                  />
                </div>
              </div>
            </div>
            <div>
              <Scrollbars
                style={{
                  width: "100%",
                  height: "600px",
                }}
              >
                <Table
                  className='restrict-width'
                  data={tmpRols}
                  loading={data?.loading}
                  show={[
                    "tramite",
                    "canal",
                    "estado",
                    "cultivo",
                    "canton_name",
                    "fecha_siembre",
                    "ciclo",
                    "suma_asegurada",
                    "observaciones",
                    "actions",
                  ]}
                  hideActionsHeaders={{
                    edit: editCandidate,
                  }}
                  actions={{
                    edit: editCandidate,
                  }}
                />
              </Scrollbars>
            </div>
          </Card>

          <div style={{ padding: "20px 0px", textAlign: "right" }}>
            {rol.length > 0 ? (
              <Button
                color='blue'
                text='Descargar Tabla'
                action={generateCsvList}
              />
            ) : (
              <p></p>
            )}
          </div>
        </div>
      </Animate>
    </Fragment>
  );
}
