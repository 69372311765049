import React, { useState, useEffect, createRef, useContext } from "react";
import { Icon, useLocation, ViewContext } from "components/lib";
import Select from "react-select";
import Style from "./createPlan.module.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Submit } from "components/bridges/BridgeServices";
import { colourStyles } from "../../../css/globalStyles";

export function CreatePlan(props) {
  const viewContext = useContext(ViewContext);
  const { planList, setPlanList, options, planValues, setPlanValues } = props;
  const [selected, setSelected] = useState([[]]);
  const [groupOptions, setGroupOptions] = useState();
  const [height, setHeight] = useState();
  const contentRef = createRef();
  let url = useLocation();
  let vecUrl = url.pathname.split("/");
  let id = "";
  if (vecUrl.length > 3) {
    id = vecUrl[3];
  }

  //Plan Actions
  // handle click event of the Remove button plan
  const handleRemovePlanClick = async (index) => {
    if (id !== "") {
      if (planValues.hasOwnProperty(`plan${index + 1}`)) {
        let url = `product/getByIdProducto/${planValues[`plan${index + 1}`].uuid
          }/plan`;
        await Submit(url, "", "get").then((resp) => {
          if (resp?.data?.data?.hasSons) {
            viewContext.notification.show(
              "No se puede eliminar el plan",
              "error",
              true
            );
            return;
          } else {
            let tempPlanValues = planValues;
            let tempSelectedOption = selected;
            const list = [...planList];
            const tempOptions = [...groupOptions];

            //remover plna de lista de planes
            list.splice(index, 1);
            delete tempPlanValues[`plan${index + 1}`];
            tempOptions.splice(index, 1);

            //acutlaizar lista  de planes
            Object.keys(tempPlanValues).map((item, i) => {
              tempPlanValues[`plan${i + 1}`] = tempPlanValues[item];
              delete tempPlanValues[item];
            });

            // actualizar opciones seleccionadas
            tempSelectedOption.splice(index, 1);

            setPlanValues(tempPlanValues);
            setGroupOptions(tempOptions);
            setPlanList(list);
            setSelected(tempSelectedOption);
          }
          // idForm = resp.data[0].id;
        });
      } else {
        let tempPlanValues = planValues;
        let tempSelectedOption = selected;
        const list = [...planList];
        const tempOptions = [...groupOptions];

        //remover plna de lista de planes
        list.splice(index, 1);
        delete tempPlanValues[`plan${index + 1}`];
        tempOptions.splice(index, 1);

        //acutlaizar lista  de planes
        Object.keys(tempPlanValues).map((item, i) => {
          tempPlanValues[`plan${i + 1}`] = tempPlanValues[item];
          delete tempPlanValues[item];
        });

        // actualizar opciones seleccionadas
        tempSelectedOption.splice(index, 1);

        setPlanValues(tempPlanValues);
        setGroupOptions(tempOptions);
        setPlanList(list);
        setSelected(tempSelectedOption);
      }
    } else {
      let tempPlanValues = planValues;
      const list = [...planList];
      const tempOptions = [...groupOptions];
      let tempSelectedOption = selected;

      //remover plna de lista de planes
      list.splice(index, 1);
      delete tempPlanValues[`plan${index + 1}`];
      tempOptions.splice(index, 1);

      //acutlaizar lista  de planes
      Object.keys(tempPlanValues).map((item, i) => {
        tempPlanValues[`plan${i + 1}`] = tempPlanValues[item];
        delete tempPlanValues[item];
      });

      // actualizar opciones seleccionadas
      tempSelectedOption.splice(index, 1);

      setPlanValues(tempPlanValues);
      setGroupOptions(tempOptions);
      setPlanList(list);
      setSelected(tempSelectedOption);
    }
  };

  // handle click event of the Add button plan
  const handleAddPlanClick = (index) => {
    const plan = [...planList];
    const selectOption = [...selected];
    plan.push({
      features: [plan[0].features[0]],
      alternative: [[0.0]],
    });

    let tempGroupOptions = groupOptions;
    tempGroupOptions.push(options);
    selectOption.push([]);
    setSelected(selectOption);
    setGroupOptions(tempGroupOptions);
    setPlanList(plan);
  };

  //Features Actions
  // handle input change
  const handleInputFeatureChange = async (
    e,
    indexFeature,
    indexPlan,
    planChild
  ) => {
    if (id !== "" && planValues[`plan${indexPlan + 1}`] !== undefined) {
      let url = `product/getByIdProducto/${planValues[`plan${indexPlan + 1}`].uuid
        }/plan`;
      await Submit(url, "", "get").then((resp) => {
        if (resp?.data?.data?.hasSons) {
          viewContext.notification.show(
            "No se puede editar el plan",
            "error",
            true
          );
          return;
        } else {
          const plan = [...planList];
          const selectOption = [...selected];
          plan[indexPlan].features = [plan[indexPlan].features[0]];
          plan[indexPlan].alternative[0] = [0];
          selectOption[indexPlan] = [];
          e.map((value) => {
            plan[indexPlan].features.push(value);
            plan[indexPlan].alternative[0].push(0.0);
            selectOption[indexPlan].push(value);
          });
          for (
            let index = 1;
            index < plan[indexPlan].alternative.length;
            index++
          ) {
            plan[indexPlan].alternative[index] = [0];
            for (
              let indexAlternative = 1;
              indexAlternative < plan[indexPlan].alternative[0].length;
              indexAlternative++
            ) {
              plan[indexPlan].alternative[index].push(0.0);
            }
          }
          setPlanList(plan);
          setSelected(selectOption);
        }
        // idForm = resp.data[0].id;
      });
    } else {
      const plan = [...planList];
      const selectOption = [...selected];
      plan[indexPlan].features = [plan[indexPlan].features[0]];
      plan[indexPlan].alternative[0] = [0];
      selectOption[indexPlan] = [];
      e.map((value) => {
        plan[indexPlan].features.push(value);
        plan[indexPlan].alternative[0].push(0.0);
        selectOption[indexPlan].push(value);
      });
      for (let index = 1; index < plan[indexPlan].alternative.length; index++) {
        plan[indexPlan].alternative[index] = [0];
        for (
          let indexAlternative = 1;
          indexAlternative < plan[indexPlan].alternative[0].length;
          indexAlternative++
        ) {
          plan[indexPlan].alternative[index].push(0.0);
        }
      }
      setPlanList(plan);
      setSelected(selectOption);
    }
  };

  useEffect(() => {
    let selectOption = [...selected];
    selectOption = [];
    let tempGroupOptions = groupOptions ? groupOptions : [];

    planList.map((itemPlan, indexPlan) => {
      selectOption.push([]);
      tempGroupOptions.push(options);
      itemPlan.features.map((itemFeature) => {
        options.map((itemOption) => {
          if (itemFeature.value === itemOption.value) {
            selectOption[indexPlan].push(itemOption);
          }
        });
      });
    });

    setSelected(selectOption);
    setGroupOptions(tempGroupOptions);
    // }
  }, [options]);

  useEffect(() => {
    const content = contentRef.current;
    setHeight((content && content.clientHeight) || 0);
  }, [contentRef]);

  return (
    <div className={Style.createPlan}>
      <Scrollbars
        style={{
          width: "100%",
          height: "250px",
        }}
      >
        <div
          ref={contentRef}
          style={{ display: "flex", flexDirection: "column" }}
        >
          {planList.length === 0 && (
            <div>
              <button
                style={{
                  background: "#0F265C 0% 0% no-repeat padding-box",
                  opacity: 1,
                  borderRadius: "15px",
                  color: "white",
                  padding: "3px",
                  marginLeft: "5px",
                }}
                onClick={() => handleAddPlanClick(0)}
              >
                <Icon image={"plus"} size={18} />
              </button>
            </div>
          )}
          {planList.map((plan, indexPlan) => {
            return (
              <div
                key={`createPlan${plan}${indexPlan}`}
                style={{ padding: 15 }}
              >
                <div style={{ display: "flex", paddingBottom: "15px" }}>
                  <h2
                    style={{
                      background: "#95D4E9 0% 0% no-repeat padding-box",
                      borderRadius: "10px",
                      opacity: 1,
                      padding: "15px 60px",
                      color: "#0F265C",
                    }}
                  >
                    {`Plan ${indexPlan + 1}`}
                  </h2>
                  <div style={{ alignSelf: "center", marginLeft: "10px" }}>
                    {/* {planList.length !== 1 && indexPlan !== 0 && ( */}
                    {indexPlan !== 0 && (
                      <button
                        style={{
                          background: "#0F265C 0% 0% no-repeat padding-box",
                          opacity: 1,
                          borderRadius: "15px",
                          color: "white",
                          padding: "3px",
                        }}
                        onClick={() => handleRemovePlanClick(indexPlan)}
                      >
                        <Icon image={"minus"} size={18} />
                      </button>
                    )}
                    <button
                      style={{
                        background: "#0F265C 0% 0% no-repeat padding-box",
                        opacity: 1,
                        borderRadius: "15px",
                        color: "white",
                        padding: "3px",
                        marginLeft: "5px",
                      }}
                      onClick={() => handleAddPlanClick(indexPlan)}
                    >
                      <Icon image={"plus"} size={18} />
                    </button>
                  </div>
                </div>
                <input
                  style={{
                    padding: "10px 10px 10px 0px",
                    width: "100%",
                    color: "#0F265C",
                  }}
                  type='text'
                  id={`alternative${indexPlan}${indexPlan}`}
                  name='alternative'
                  value={planList[indexPlan]?.features[0]?.label}
                  disabled
                />
                <div
                  style={{
                    display: "flex",
                    padding: "10px 10px 10px 0px",
                    // width: "250px",
                  }}
                >
                  {options.length > 0 && (
                    <Select
                      value={selected[indexPlan]}
                      className='basic-single'
                      classNamePrefix='select'
                      styles={colourStyles}
                      options={groupOptions && groupOptions[indexPlan]}
                      isSearchable
                      isMulti
                      isLoading={options.length === 0 ? true : false}
                      placeholder={"Seleccionar"}
                      menuPosition='fixed'
                      menuPlacement={"auto"}
                      onChange={(e) =>
                        handleInputFeatureChange(e, "", indexPlan, plan)
                      }
                      isDisabled={options.length === 0 ? true : false}
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
}
