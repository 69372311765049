import React, { useState, useEffect } from "react";
import { Title } from "./title";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import Style from "../../../../../css/globalStyle.module.scss";
import "../../../../../css/reactCalendar.css";
import useViewPort from "components/hooks/useViewPort";

export function HistoriaMedica(props) {
  let {
    register,
    dataInfo,
    watch,
    errors,
    peso,
    altura,
    preguntaPreliminar1Section3,
    checkedQuestion,
    genero,
    control,
    detailQuestion,
    setDetailQuestion,
  } = props;
  const [screenSize] = useViewPort();
  registerLocale("es", es);
  const cambioPeso = watch("cambioPeso");
  const [addInfo, setAddInfo] = useState([]);
  if (preguntaPreliminar1Section3) {
    preguntaPreliminar1Section3 = preguntaPreliminar1Section3.toString().trim();
  }
  const preguntaPreliminar5 = watch("preguntaPreliminar5");
  const preguntaPreliminar6 = watch("preguntaPreliminar6");
  const preguntaPreliminar7 = watch("preguntaPreliminar7");
  const preguntaPreliminar8 = watch("preguntaPreliminar8");
  const preguntaPreliminar9 = watch("preguntaPreliminar9");
  const preguntaPreliminar10 = watch("preguntaPreliminar10");
  const preguntaPreliminar11 = watch("preguntaPreliminar11");
  const preguntaPreliminar12 = watch("preguntaPreliminar12");
  const preguntaPreliminar13 = watch("preguntaPreliminar13");
  const preguntaPreliminar14 = watch("preguntaPreliminar14");
  const preguntaPreliminar15 = watch("preguntaPreliminar15");
  const preguntaPreliminar16 = watch("preguntaPreliminar16");
  const preguntaPreliminar17 = watch("preguntaPreliminar17");
  const preguntaPreliminar18 = watch("preguntaPreliminar18");
  const preguntaPreliminar19 = watch("preguntaPreliminar19");
  const preguntaPreliminar20 = watch("preguntaPreliminar20");
  const preguntaPreliminar21 = watch("preguntaPreliminar21");
  const preguntaPreliminar22 = watch("preguntaPreliminar22");
  const preguntaPreliminar23 = watch("preguntaPreliminar23");
  const preguntaPreliminar24 = watch("preguntaPreliminar24");
  const preguntaPreliminar25 = watch("preguntaPreliminar25");
  const preguntaPreliminar26 = watch("preguntaPreliminar26");

  const addQuestion = (element) => {
    let tempDetailQuestion = detailQuestion;
    tempDetailQuestion.push(element);
    tempDetailQuestion.sort(function (a, b) {
      return a - b;
    });
    setDetailQuestion((prevMovies) => [...tempDetailQuestion]);
  };

  const deleteQuestion = (element) => {
    let tempDetailQuestion = detailQuestion;
    const index = tempDetailQuestion.indexOf(element);
    if (index > -1) {
      // only splice array when item is found
      tempDetailQuestion.splice(index, 1); // 2nd parameter means remove one item only
    }
    tempDetailQuestion.sort(function (a, b) {
      return a - b;
    });
    setDetailQuestion((prevMovies) => [...tempDetailQuestion]);
  };

  useEffect(() => {
    if (preguntaPreliminar5 === "Si") {
      addQuestion("6.a");
    } else {
      deleteQuestion("6.a");
    }
  }, [preguntaPreliminar5]);

  useEffect(() => {
    if (preguntaPreliminar6 === "Si") {
      addQuestion("6.b");
    } else {
      deleteQuestion("6.b");
    }
  }, [preguntaPreliminar6]);

  useEffect(() => {
    if (preguntaPreliminar7 === "Si") {
      addQuestion("6.c");
    } else {
      deleteQuestion("6.c");
    }
  }, [preguntaPreliminar7]);

  useEffect(() => {
    if (preguntaPreliminar8 === "Si") {
      addQuestion("6.d");
    } else {
      deleteQuestion("6.d");
    }
  }, [preguntaPreliminar8]);

  useEffect(() => {
    if (preguntaPreliminar9 === "Si") {
      addQuestion("6.e");
    } else {
      deleteQuestion("6.e");
    }
  }, [preguntaPreliminar9]);

  useEffect(() => {
    if (preguntaPreliminar10 === "Si") {
      addQuestion("6.f");
    } else {
      deleteQuestion("6.f");
    }
  }, [preguntaPreliminar10]);

  useEffect(() => {
    if (preguntaPreliminar11 === "Si") {
      addQuestion("6.g");
    } else {
      deleteQuestion("6.g");
    }
  }, [preguntaPreliminar11]);

  useEffect(() => {
    if (preguntaPreliminar12 === "Si") {
      addQuestion("6.h");
    } else {
      deleteQuestion("6.h");
    }
  }, [preguntaPreliminar12]);

  useEffect(() => {
    if (preguntaPreliminar13 === "Si") {
      addQuestion("6.i");
    } else {
      deleteQuestion("6.i");
    }
  }, [preguntaPreliminar13]);

  useEffect(() => {
    if (preguntaPreliminar14 === "Si") {
      addQuestion("6.j");
    } else {
      deleteQuestion("6.j");
    }
  }, [preguntaPreliminar14]);

  useEffect(() => {
    if (preguntaPreliminar15 === "Si") {
      addQuestion("6.k");
    } else {
      deleteQuestion("6.k");
    }
  }, [preguntaPreliminar15]);

  useEffect(() => {
    if (preguntaPreliminar16 === "Si") {
      addQuestion("7.a");
    } else {
      deleteQuestion("7.a");
    }
  }, [preguntaPreliminar16]);

  useEffect(() => {
    if (preguntaPreliminar17 === "Si") {
      addQuestion("7.b");
    } else {
      deleteQuestion("7.b");
    }
  }, [preguntaPreliminar17]);

  useEffect(() => {
    if (preguntaPreliminar18 === "Si") {
      addQuestion("7.c");
    } else {
      deleteQuestion("7.c");
    }
  }, [preguntaPreliminar18]);

  useEffect(() => {
    if (preguntaPreliminar19 === "Si") {
      addQuestion("7.d");
    } else {
      deleteQuestion("7.d");
    }
  }, [preguntaPreliminar19]);

  useEffect(() => {
    if (preguntaPreliminar20 === "Si") {
      addQuestion("7.e");
    } else {
      deleteQuestion("7.e");
    }
  }, [preguntaPreliminar20]);

  useEffect(() => {
    if (preguntaPreliminar21 === "Si") {
      addQuestion("8");
    } else {
      deleteQuestion("8");
    }
  }, [preguntaPreliminar21]);

  useEffect(() => {
    if (preguntaPreliminar22 === "Si") {
      addQuestion("9");
    } else {
      deleteQuestion("9");
    }
  }, [preguntaPreliminar22]);

  useEffect(() => {
    if (preguntaPreliminar23 === "Si") {
      addQuestion("10.a");
    } else {
      deleteQuestion("10.a");
    }
  }, [preguntaPreliminar23]);

  useEffect(() => {
    if (preguntaPreliminar24 === "Si") {
      addQuestion("10.b");
    } else {
      deleteQuestion("10.b");
    }
  }, [preguntaPreliminar24]);

  useEffect(() => {
    if (preguntaPreliminar26 === "Si") {
      addQuestion("11.a");
    } else {
      deleteQuestion("11.a");
    }
  }, [preguntaPreliminar26]);

  useEffect(() => {
    let tempdetailQuestion = detailQuestion;
    tempdetailQuestion.sort(function (a, b) {
      return a - b;
    });
    setAddInfo(() => [...tempdetailQuestion]);
  }, [detailQuestion]);

  return (
    <div>
      <Title whiteTitle='8. Sección historia médica' />
      <div className={Style.inputGeneralContainerMedica}>
        <div className={Style.inputContainerQuarterMedica}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;Peso en kg
          </label>
          <input
            tabIndex={64}
            type='number'
            className={Style["input_number"]}
            {...register("pesoKg", { required: true, min: 0 })}
          />
          {errors.pesoKg?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerQuarterMedica}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;Altura en cm
          </label>
          <input
            tabIndex={65}
            type='number'
            className={Style["input_number"]}
            {...register("alturaCm", { required: true, min: 0 })}
          />
          {errors.alturaCm?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerQuarterMedica}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;IMC
          </label>
          <input
            tabIndex={66}
            value={(peso / ((altura / 100) * (altura / 100))).toFixed(0)}
            disabled
            type='number'
            className={Style["input_number"]}
            {...register("imc", {
              required: true,
              min: 0,
              value: (peso / ((altura / 100) * (altura / 100))).toFixed(0),
            })}
          />
          {errors.imc?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerQuarterMedica}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;¿Usted fuma?
          </label>
          <input
            tabIndex={67}
            disabled
            type='text'
            className={Style["input"]}
            value={dataInfo.client.smoker === "S" ? "Si" : "No"}
            {...register("fuma", {
              required: true,
              min: 0,
              value: dataInfo.client.smoker === "S" ? "Si" : "No",
            })}
          />
          {errors.fuma?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
      </div>
      <div className={Style.inputGeneralContainerMedica}>
        <div className={Style.inputContainerQuarterMedica}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;¿Ha tenido algún cambio de peso en el último año?
          </label>
          <select
            {...register("cambioPeso", { required: true })}
            className={Style["select"]}
          >
            <option value='Si'>Si</option>
            <option value='No'>No</option>
          </select>
          {errors.cambioPeso?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        {cambioPeso === "Si" && (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ display: "flex" }}>
                <label style={{ padding: "0px 10px" }}>
                  <b style={{ color: 'red' }}>*</b>&nbsp;Aumentó peso
                </label>
                <input
                  tabIndex={68}
                  {...register("aumentoPeso", { required: true })}
                  type='radio'
                  value='Si'
                />
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <label style={{ padding: "0px 10px" }}>
                  <b style={{ color: 'red' }}>*</b>&nbsp;Perdió peso
                </label>
                <input
                  tabIndex={69}
                  {...register("aumentoPeso", { required: true })}
                  type='radio'
                  value='No'
                />
                {errors.aumentoPeso?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
            </div>

            <div className={Style.inputContainerQuarterMedica}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;¿Cuál fue la causa?
              </label>
              <textarea
                tabIndex={70}
                {...register("causaCambioPeso", {
                  required: true,
                  maxLength: 255,
                })}
                className={Style["textArea"]}
              />
              {errors.causaCambioPeso?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
          </>
        )}
      </div>
      <div style={{ backgroundColor: "#DEEBF7" }}>
        <h2 style={{ fontWeight: 600 }}>
          Alguna vez ha sido diagnosticado, tratado o se le ha recomendado
          tratamiento para lo siguiente:
        </h2>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;¿Ha sido diagnosticado con alguna enfermedad por la cual se
              encuentre tomando medicación continua o algún tipo de tratamiento?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={71}
                {...register("preguntaPreliminar4", { required: true })}
                type='radio'
                value='Si'
                checked={preguntaPreliminar1Section3 === "Si"}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={72}
                {...register("preguntaPreliminar4", { required: true })}
                type='radio'
                value='No'
                checked={preguntaPreliminar1Section3 === "No"}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar4?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6.a. ¿Dolor en el pecho, hipertensión arterial, arritmia, soplo
              cardíaco, infarto al miocardio, anginia de pecho, aneurisma,
              fiebre reumática, enfermedades del corazón o enfermedades de los
              vasos sanguíneos?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={73}
                type='radio'
                value='Si'
                checked={checkedQuestion.a}
                name='radio-a'
                {...register("preguntaPreliminar5", {
                  required: true,
                  value: checkedQuestion.a ? "Si" : "No",
                })}
              // onChange={(e) => {
              //   addQuestion("6.a");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={74}
                type='radio'
                value='No'
                checked={!checkedQuestion.a}
                name='radio-a'
                {...register("preguntaPreliminar5", {
                  required: true,
                  value: checkedQuestion.a ? "Si" : "No",
                })}
              // onChange={(e) => {
              //   deleteQuestion("6.a");
              // }}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar5?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6.b. ¿Mareos, desmayos, epilepsia o convulsiones, jaquecas,
              alteración del habla, parálisis o apoplejía, derrame, accidente
              cerebrovascular, traumatismo craneal, trastorno nervioso o mental?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={75}
                {...register("preguntaPreliminar6", {
                  required: true,
                  value: checkedQuestion.b ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.b}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={76}
                {...register("preguntaPreliminar6", {
                  required: true,
                  value: checkedQuestion.b ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.b}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar6?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp; 6.c. ¿Cáncer, tumor o pólipos, enfermedades de la piel, alergias,
              ganglios linfáticos o quistes?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={77}
                {...register("preguntaPreliminar7", {
                  required: true,
                  value: checkedQuestion.c ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.c}
              // onChange={(e) => {
              // addQuestion("6.c");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={78}
                {...register("preguntaPreliminar7", {
                  required: true,
                  value: checkedQuestion.c ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.c}
              // onChange={(e) => {
              // deleteQuestion("6.c");
              // }}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar7?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6.d. ¿Ha recibido resultados positivos por la exposición del virus
              de Deficiencia Inmunodeficiencia Humana, VIH 0 ha sido
              diagnosticado de SIDA o cualquier patología derivada de la
              infección con VIH?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={79}
                {...register("preguntaPreliminar8", {
                  required: true,
                  value: checkedQuestion.d ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.d}
              // onChange={(e) => {
              // addQuestion("6.d");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={80}
                {...register("preguntaPreliminar8", {
                  required: true,
                  value: checkedQuestion.d ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.d}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar8?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6.e ¿Anemia, leucemia, flebitis, tromboflebitis, trastornos de la
              sangre, vasculares o del bazo?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={81}
                {...register("preguntaPreliminar9", {
                  required: true,
                  value: checkedQuestion.e ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.e}
              // onChange={(e) => {
              // addQuestion("6.e");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={82}
                {...register("preguntaPreliminar9", {
                  required: true,
                  value: checkedQuestion.e ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.e}
              // onChange={(e) => {
              // deleteQuestion("6.e");
              // }}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar9?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6.f ¿Ahogo, ronquera, tos persistente, bronquitis, pleuresía,
              asma, alergia, enfisema, tuberculosis o cualquier otro desorden
              respiratorio crónico?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={83}
                {...register("preguntaPreliminar10", {
                  required: true,
                  value: checkedQuestion.f ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.f}
              // onChange={(e) => {
              // addQuestion("6.f");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={84}
                {...register("preguntaPreliminar10", {
                  required: true,
                  value: checkedQuestion.f ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.f}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar10?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6.g. ¿Ictericia, cirrosis, hepatitis, hemorragia intestinal,
              apendicitis, úlcera, diverticulitis, hernia, colitis, hemorroides,
              esofagitis, dispepsia, trastornos digestivos del hígado, páncreas
              o vesícula biliar?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={85}
                {...register("preguntaPreliminar11", {
                  required: true,
                  value: checkedQuestion.g ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.g}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={86}
                {...register("preguntaPreliminar11", {
                  required: true,
                  value: checkedQuestion.g ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.g}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar11?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6.h. ¿Azúcar, laminea, pus en la orina. infecciones urinarias,
              enfermedades venéreas, cálculos, quistes u otros desordenes de los
              riñones, vejiga, tracto urinario, próstata u órganos
              reproductivos?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={87}
                {...register("preguntaPreliminar12", {
                  required: true,
                  value: checkedQuestion.h ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.h}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={88}
                {...register("preguntaPreliminar12", {
                  required: true,
                  value: checkedQuestion.h ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.h}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar12?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6.i. ¿Diabetes, hipoglucemia, desórdenes de la tiroides,
              trastornos del páncreas u otros trastornos glandulares?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={89}
                {...register("preguntaPreliminar13", {
                  required: true,
                  value: checkedQuestion.i ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.i}
              // onChange={(e) => {
              // addQuestion("6.i");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={90}
                {...register("preguntaPreliminar13", {
                  required: true,
                  value: checkedQuestion.i ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.i}
              // onChange={(e) => {
              // deleteQuestion("6.i");
              // }}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar13?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6.j. ¿Artritis, reumatismo, ciática, neuritis, gota, desórdenes de
              la columna vertebral, músculos, huesos o articulaciones, colágeno
              deformidad, cojera o amputación?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={91}
                {...register("preguntaPreliminar14", {
                  required: true,
                  value: checkedQuestion.j ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.j}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={92}
                {...register("preguntaPreliminar14", {
                  required: true,
                  value: checkedQuestion.j ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.j}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar14?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label><b style={{ color: 'red' }}>*</b>&nbsp;6.k. ¿Enfermedades de ojos, oído o garganta?</label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={93}
                {...register("preguntaPreliminar15", {
                  required: true,
                  value: checkedQuestion.k ? "Si" : "No",
                })}
                type='radio'
                value='Si'
                checked={checkedQuestion.k}
              // onChange={(e) => {
              // addQuestion("6.k");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={94}
                {...register("preguntaPreliminar15", {
                  required: true,
                  value: checkedQuestion.k ? "Si" : "No",
                })}
                type='radio'
                value='No'
                checked={!checkedQuestion.k}
              // onChange={(e) => {
              // deleteQuestion("6.k");
              // }}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar15?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
      </div>
      <div style={{ backgroundColor: "#DEEBF7" }}>
        <h2 style={{ fontWeight: 600 }}>
          Además de lo antes mencionado, usted?
        </h2>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;7a. ¿Ha tenido o le han recomendado tener cualquier operación,
              biopsia o procedimiento quirúrgico?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={95}
                {...register("preguntaPreliminar16", { required: true })}
                type='radio'
                value='Si'
              // onChange={(e) => {
              // addQuestion("7.a");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={96}
                {...register("preguntaPreliminar16", { required: true })}
                type='radio'
                value='No'
              // onChange={(e) => {
              // deleteQuestion("7.a");
              // }}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar16?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;7.b. ¿Ha estado bajo tratamiento o está actualmente tomando o ha
              tomado cualquier medicamento o quimioterapia?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={97}
                {...register("preguntaPreliminar17", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={98}
                {...register("preguntaPreliminar17", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar17?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;7c. ¿Tiene historia familiar de diabetes, hipertensión, desórdenes
              del corazón o del sistema circulatorio, suicidio o desórdenes
              mentales?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={99}
                {...register("preguntaPreliminar18", { required: true })}
                type='radio'
                value='Si'
              // onChange={(e) => {
              // addQuestion("7.c");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={100}
                {...register("preguntaPreliminar18", { required: true })}
                type='radio'
                value='No'
              // onChange={(e) => {
              // deleteQuestion("7.c");
              // }}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar18?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label><b style={{ color: 'red' }}>*</b>&nbsp;7d. ¿Ha estado o está ahora en una dieta recetada?</label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={101}
                {...register("preguntaPreliminar19", { required: true })}
                type='radio'
                value='Si'
              // onChange={(e) => {
              // addQuestion("7.d");
              // }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={102}
                {...register("preguntaPreliminar19", { required: true })}
                type='radio'
                value='No'
              // onChange={(e) => {
              // deleteQuestion("7.d");
              // }}
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar19?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;7e. ¿Ha sido rechazado, dado de baja o despedido por un empleador
              u otro por razones de incapacidad médica o física?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={103}
                {...register("preguntaPreliminar20", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={104}
                {...register("preguntaPreliminar20", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar20?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;8. ¿Ha sido admitido en un hospital, clínica o sanatorio?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={105}
                {...register("preguntaPreliminar21", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={106}
                {...register("preguntaPreliminar21", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar21?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputGeneralContainerMedica}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;9. ¿Ha tenido un chequeo de rutina, exámenes de sangre,
              tratamiento u otros exámenes médicos en los últimos cinco años?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "0px 10px" }}>Si</label>
              <input
                tabIndex={107}
                {...register("preguntaPreliminar22", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "0px 10px" }}>No</label>
              <input
                tabIndex={108}
                {...register("preguntaPreliminar22", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.preguntaPreliminar22?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
      </div>
      {genero === "2" && (
        <>
          <div style={{ backgroundColor: "#DEEBF7" }}>
            <h2 style={{ fontWeight: 600 }}>Sólo para mujeres</h2>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={Style.inputGeneralContainerMedica}>
              <div className={Style.inputContainerMedicaEnfermedadesLabel}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;10.a. ¿Trastornos de útero, ovarios, senos? ¿Se ha realizado
                  chequeos ginecológicos, pap test, mamografías?
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <label style={{ padding: "0px 10px" }}>Si</label>
                  <input
                    tabIndex={109}
                    {...register("preguntaPreliminar23", { required: true })}
                    type='radio'
                    value='Si'
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ padding: "0px 10px" }}>No</label>
                  <input
                    tabIndex={110}
                    {...register("preguntaPreliminar23", { required: true })}
                    type='radio'
                    value='No'
                  />
                </div>
              </div>
            </div>{" "}
            <div className={Style.inputGeneralContainerMedica}>
              <div className={Style.inputContainerMedicaEnfermedadesLabel}>
                <label>10.b. ¿Toma usted anticonceptivos?</label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <label style={{ padding: "0px 10px" }}>Si</label>
                  <input
                    tabIndex={111}
                    {...register("preguntaPreliminar24", { required: true })}
                    type='radio'
                    value='Si'
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ padding: "0px 10px" }}>No</label>
                  <input
                    tabIndex={112}
                    {...register("preguntaPreliminar24", { required: true })}
                    type='radio'
                    value='No'
                  />
                </div>
              </div>
            </div>{" "}
            <div className={Style.inputGeneralContainerMedica}>
              <div className={Style.inputContainerMedicaEnfermedadesLabel}>
                <label><b style={{ color: 'red' }}>*</b>&nbsp;10.c. ¿Está embarazada?</label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <label style={{ padding: "0px 10px" }}>Si</label>
                  <input
                    tabIndex={113}
                    {...register("preguntaPreliminar25", { required: true })}
                    type='radio'
                    value='Si'
                  // onChange={(e) => {
                  // addQuestion("10.c");
                  // }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ padding: "0px 10px" }}>No</label>
                  <input
                    tabIndex={114}
                    {...register("preguntaPreliminar25", { required: true })}
                    type='radio'
                    value='No'
                  // onChange={(e) => {
                  // deleteQuestion("10.c");
                  // }}
                  />
                </div>
              </div>
            </div>
            {preguntaPreliminar25 === "Si" && (
              <div className={Style.inputGeneralContainerMedica}>
                <div className={Style.inputContainerMedicaEnfermedadesLabel}>
                  <label><b style={{ color: 'red' }}>*</b>&nbsp;Cuantas semanas</label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ display: "flex" }}>
                    <input
                      tabIndex={115}
                      type='number'
                      className={Style["input_number"]}
                      {...register("semanasPreguntaPreliminar25", {
                        required: true,
                      })}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {genero === "1" && (
        <>
          <div style={{ backgroundColor: "#DEEBF7" }}>
            <h2 style={{ fontWeight: 600 }}>Sólo para hombres</h2>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={Style.inputGeneralContainerMedica}>
              <div className={Style.inputContainerMedicaEnfermedadesLabel}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;11.a. ¿Se ha realizado chequeo prostático y/o prueba de PSA?
                </label>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <label style={{ padding: "0px 10px" }}>Si</label>
                  <input
                    tabIndex={116}
                    {...register("preguntaPreliminar26", { required: true })}
                    type='radio'
                    value='Si'
                  // onChange={(e) => {
                  // addQuestion("11.a");
                  // }}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ padding: "0px 10px" }}>No</label>
                  <input
                    tabIndex={117}
                    {...register("preguntaPreliminar26", { required: true })}
                    type='radio'
                    value='No'
                  // onChange={(e) => {
                  // deleteQuestion("11.a");
                  // }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <h2 style={{ fontWeight: 600, color: "#0F265C", paddingBottom: "30px" }}>
        Detalle las preguntas de la sección historia médica en caso de ser
        afirmativas
      </h2>
      {screenSize.dynamicWidth > 768 ? (
        <>
          <div style={{ display: "flex" }}>
            <div style={{ width: "30%" }}>
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Literal</h2>
            </div>
            <div
              style={{
                width: "15%",
                display: "flex",
                flexDirection: "column",
                padding: "10px 15px",
              }}
            >
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                Fecha tratamiento
              </h2>
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                padding: "10px 15px",
              }}
            >
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                Diagnóstico o tratamiento
              </h2>
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                padding: "10px 15px",
              }}
            >
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Duración</h2>
            </div>
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                padding: "10px 15px",
              }}
            >
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                Nombre del médico/clínica u hospital
              </h2>
            </div>
          </div>
          {addInfo?.length > 0 && (
            <>
              {addInfo?.map((item, index) => {
                return (
                  <div
                    key={`opcionesPreguntaPreliminar1${index}${item}`}
                    style={{ display: "flex" }}
                  >
                    <div
                      style={{
                        width: "20%",
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 15px",
                      }}
                    >
                      <input
                        tabIndex={118}
                        className={Style["input_number"]}
                        type='hidden'
                        disabled
                        onChange={() =>
                          setAddInfo((tempdetailQuestion) => [
                            ...tempdetailQuestion,
                          ])
                        }
                        value={addInfo[index]}
                        {...register(`literalPregAseg${index}`, {
                          required: false,
                          value: addInfo[index],
                        })}
                      />
                      <p className={Style["input_number"]}>{item}</p>
                    </div>
                    <div
                      style={{
                        width: "13%",
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 15px",
                        alignSelf: "center",
                        backgroundColor: "#F7F7F8",
                        borderRadius: "5px",
                      }}
                    // className={Style.customDatePickerWidth}
                    >
                      <Controller
                        name={`fechaTratamientoPregAseg${index}`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field }) => {
                          let tmpDate;
                          if (field?.value) {
                            tmpDate = new Date(field.value.toString())
                          }
                          return (
                            <DatePicker
                              placeholderText="Selecciona una fecha"
                              selected={tmpDate}
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                              showFullMonthYearPicker
                              locale="es"
                              onChange={(e) => { field.onChange(e); }}
                            />
                          );
                        }}
                      />
                      {errors[`fechaTratamientoPregAseg${index}`]?.type ===
                        "required" && (
                          <p style={{ color: "red" }}>Este campo es requerido</p>
                        )}
                    </div>
                    <div
                      style={{
                        width: "22%",
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 15px",
                      }}
                    >
                      <input
                        tabIndex={119}
                        className={Style["input"]}
                        type='text'
                        {...register(`diagnoticoTratamientoPregAseg${index}`, {
                          required: true,
                        })}
                      />
                      {errors[`diagnoticoTratamientoPregAseg${index}`]?.type ===
                        "required" && (
                          <p style={{ color: "red" }}>Este campo es requerido</p>
                        )}
                    </div>
                    <div
                      style={{
                        width: "22%",
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 15px",
                      }}
                    >
                      <input
                        tabIndex={120}
                        className={Style["input_number"]}
                        type='text'
                        {...register(`duracionTratamientoPregAseg${index}`, {
                          required: true,
                        })}
                      />
                      {errors[`duracionTratamientoPregAseg${index}`]?.type ===
                        "required" && (
                          <p style={{ color: "red" }}>Este campo es requerido</p>
                        )}
                    </div>
                    <div
                      style={{
                        width: "22%",
                        display: "flex",
                        flexDirection: "column",
                        padding: "10px 15px",
                      }}
                    >
                      <input
                        tabIndex={121}
                        className={Style["input"]}
                        type='text'
                        {...register(`medicoHospitalPregAseg${index}`, {
                          required: true,
                        })}
                      />
                      {errors[`medicoHospitalPregAseg${index}`]?.type ===
                        "required" && (
                          <p style={{ color: "red" }}>Este campo es requerido</p>
                        )}
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </>
      ) : (
        <>
          {addInfo?.length > 0 && (
            <>
              {addInfo.map((item, index) => {
                return (
                  <div
                    key={`opcionesPreguntaPreliminar1${index}${item}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      borderTop: index !== 0 && "#10265C 1px solid",
                    }}
                  >
                    <div className={Style.inputContainerMedicaLiteral}>
                      <div className={Style.inputContainerMedicaLiteralTitulo}>
                        <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                          Literal
                        </h2>
                      </div>
                      <div
                        className={Style.inputContainerMedicaLiteralRespuesta}
                      >
                        <input
                          tabIndex={122}
                          className={Style["input_number"]}
                          type='hidden'
                          disabled
                          onChange={() =>
                            setAddInfo((tempdetailQuestion) => [
                              ...tempdetailQuestion,
                            ])
                          }
                          value={addInfo[index]}
                          {...register(`literalPregAseg${index}`, {
                            required: false,
                            value: addInfo[index],
                          })}
                        />
                        <p
                          style={{ display: "flex", alignItems: "center" }}
                          className={Style["input_number"]}
                        >
                          {item}
                        </p>
                      </div>
                    </div>
                    <div className={Style.inputContainerMedicaLiteral}>
                      <div className={Style.inputContainerMedicaLiteralTitulo}>
                        <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                          Fecha tratamiento
                        </h2>
                      </div>
                      <div
                        className={Style.inputContainerMedicaLiteralRespuesta}
                      >
                        <Controller
                          name={`fechaTratamientoPregAseg${index}`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                            let tmpDate;
                            if (field?.value) {
                              tmpDate = new Date(field.value.toString())
                            }
                            return (
                              <DatePicker
                                placeholderText="Selecciona una fecha"
                                selected={tmpDate}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                locale="es"
                                onChange={(e) => { field.onChange(e); }}
                              />
                            );
                          }}
                        />
                        {errors[`fechaTratamientoPregAseg${index}`]?.type ===
                          "required" && (
                            <p style={{ color: "red" }}>
                              Este campo es requerido
                            </p>
                          )}
                      </div>
                    </div>
                    <div className={Style.inputContainerMedicaLiteral}>
                      <div className={Style.inputContainerMedicaLiteralTitulo}>
                        <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                          Diagnóstico o tratamiento
                        </h2>
                      </div>
                      <div
                        className={Style.inputContainerMedicaLiteralRespuesta}
                      >
                        <input
                          tabIndex={123}
                          className={Style["input"]}
                          type='text'
                          {...register(
                            `diagnoticoTratamientoPregAseg${index}`,
                            {
                              required: true,
                            }
                          )}
                        />
                        {errors[`diagnoticoTratamientoPregAseg${index}`]
                          ?.type === "required" && (
                            <p style={{ color: "red" }}>
                              Este campo es requerido
                            </p>
                          )}
                      </div>
                    </div>
                    <div className={Style.inputContainerMedicaLiteral}>
                      <div className={Style.inputContainerMedicaLiteralTitulo}>
                        <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                          Duración
                        </h2>
                      </div>
                      <div
                        className={Style.inputContainerMedicaLiteralRespuesta}
                      >
                        <input
                          tabIndex={124}
                          className={Style["input_number"]}
                          type='text'
                          {...register(`duracionTratamientoPregAseg${index}`, {
                            required: true,
                          })}
                        />
                        {errors[`duracionTratamientoPregAseg${index}`]?.type ===
                          "required" && (
                            <p style={{ color: "red" }}>
                              Este campo es requerido
                            </p>
                          )}
                      </div>
                    </div>
                    <div className={Style.inputContainerMedicaLiteral}>
                      <div className={Style.inputContainerMedicaLiteralTitulo}>
                        <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                          Nombre del médico/clínica u hospital
                        </h2>
                      </div>
                      <div
                        className={Style.inputContainerMedicaLiteralRespuesta}
                      >
                        <input
                          tabIndex={125}
                          className={Style["input"]}
                          type='text'
                          {...register(`medicoHospitalPregAseg${index}`, {
                            required: true,
                          })}
                        />
                        {errors[`medicoHospitalPregAseg${index}`]?.type ===
                          "required" && (
                            <p style={{ color: "red" }}>
                              Este campo es requerido
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
      <div style={{ paddingBottom: "20px" }}></div>
    </div>
  );
}
