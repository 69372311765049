import React, { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext, AppNav, Header, User, ViewContext, useAPI } from "components/lib";
import Style from "./app.module.scss";
import { Submit, getSalesForces, getSponsor, getBrokers, getUsuariosSDP, getSdpInvites } from "components/bridges/BridgeServices";
import ConfigProfile from "./icons/Configuracion-de-perfiles.svg";
import ConfigProduct from "./icons/Configuracion-de-producto.svg";
import DashboardVidaAgro from "./icons/Dashboard-Vida-Agro.svg";
import SeguroAgricola from "./icons/Seguro-Agricola.svg";
import SeguroVidaAhorro from "./icons/Seguro-Vida-Ahorro.svg";
import useData from "components/hooks/useGeneralData";
import { Box } from "grommet";
import CryptrUtils from '../../../utils/CryptrUtils';

export function AppLayout(props) {
  // context & style
  const context = useContext(AuthContext);
  const [menuOptions, setMenuOptions] = useState([]);
  const { userPermissions, setPermissions } = useData();
  const [objAdminAttribs, setObjAdminAttribs] = useState({});
  const authContext = useContext(ViewContext);

  const { data: rawUser, loading: loadingUser, error: errorUser } = useAPI("/api/user/getById", "get");

  useEffect(() => {
    const getAdminAttribs = async () => {
      try {
        const response = await Submit("demo/attribs", null, "get");
        const encrypted = response.data.newData;
        const decrypted = CryptrUtils.decrypt(encrypted);
        localStorage.setItem("user_permissions", JSON.stringify(JSON.parse(decrypted)));
      } catch (error) {
        authContext.handleError(error);
      }
    };

    const getUserAttribs = async (rol_id) => {
      const cachedPermissions = localStorage.getItem("user_permissions");
      if (cachedPermissions) {
        setPermissions(JSON.parse(cachedPermissions));
        return JSON.parse(cachedPermissions);
      }
      else {
        return new Promise(async (resolve, reject) => {
          await Submit(`rol_attributionByRolId?rolId=${rol_id}`, null, "get")
            .then((value) => {
              const tempValue = value.data.data;
              localStorage.setItem("user_permissions", JSON.stringify(tempValue.detail));
              setPermissions(tempValue.detail);
              resolve(tempValue.detail);
            })
            .catch((error) => {
              authContext.handleError(error);
              reject(error);
            });
        });
      }
    };

    const getInvite = async () => {
      const rawUserObj = parseJwt(rawUser);
      const rawUserData = { data: rawUserObj };
      fillLoginLstOFAC(rawUserData?.data?.intermediary, rawUserData?.data?.email);
      return rawUserData?.data?.intermediary?.rol;
    };

    const getSponsorAndBrokerAndFv = async () => {
      const rawUserObj = parseJwt(rawUser);
      if (rawUserObj?.flag_uno) {
        let lstUserByTypes = {};
        const responseSponsor = await getSponsor();
        lstUserByTypes.sponsors = responseSponsor;
        const responseBrokers = await getBrokers();
        lstUserByTypes.brokers = responseBrokers;
        const responseFV = await getSalesForces();
        lstUserByTypes.salesForce = responseFV;
        const responseSDP = await getUsuariosSDP();
        lstUserByTypes.sdp = responseSDP;
        const responseInvites = await getSdpInvites();
        lstUserByTypes.sdpInvites = responseInvites;
        localStorage.setItem("lstUserByTypes", JSON.stringify(lstUserByTypes));
      }
    };

    const fillLoginLstOFAC = (intermediary, email) => {
      const codigoPlataforma = 'KinAnalytics';
      const idUsuario = intermediary?.broker?.astr_num_id_asesor || intermediary?.sdp?.astr_num_id_asesor || intermediary?.sponsor?.astr_num_id_asesor || intermediary?.fv?.astr_num_id_asesor || "0";
      const loginListasOfac = {
        codigoPlataforma,
        idUsuario,
        correoUsuario: email,
      };
      localStorage.setItem("loginListasOfac", JSON.stringify(loginListasOfac));
    };

    const setViewByAccess = async () => {
      let tempMenuOptions = [
        { label: "Inicio", icon: "activity", link: "/dashboard" },
        { label: "Cuenta", icon: "user", link: "/account" }
      ];

      let rawUserObj = parseJwt(rawUser);
      localStorage.setItem("profile", JSON.stringify({ data: rawUserObj }));
      if (rawUserObj?.flag_uno) {
        if (!objAdminAttribs.filled) {
          setObjAdminAttribs({ filled: true });
          getAdminAttribs();
          //getSponsorAndBrokerAndFv();
          fillLoginLstOFAC(null, rawUserObj?.email, true);
        }
      }

      if (rawUserObj?.flag_uno) {
        tempMenuOptions.push({
          label: "Seguro agrícola",
          img: SeguroAgricola,
          link: "/vendedor/agricola",
        });

        tempMenuOptions.push({
          label: "Seguro vida con ahorro",
          img: SeguroVidaAhorro,
          link: "/vendedor/vida",
        });

        tempMenuOptions.push({
          label: "PRIORI Ahorro",
          img: SeguroVidaAhorro,
          link: "/vendedor/priori/prioriManagement",
        });

        tempMenuOptions.push({
          label: "Configuración de producto",
          img: ConfigProduct,
          link: "/producto",
        });

        tempMenuOptions.push({
          label: "Gestión agricola",
          img: DashboardVidaAgro,
          link: "/vendedor/agricola/agroManagement",
        });

        tempMenuOptions.push({
          label: "Configuración de perfiles",
          img: ConfigProfile,
          link: "/profile",
        });

        tempMenuOptions.push({
          label: "Calificación agrícola",
          img: SeguroAgricola,
          link: "/qualification",
        });

        tempMenuOptions.push({
          label: "Calificación vida",
          img: SeguroVidaAhorro,
          link: "/qualificationLife",
        });

        tempMenuOptions.push(
          { label: "Salir", icon: "log-out", action: context.signout }
        );
      } else {
        const tmpRol1 = await getInvite();
        const userPermissions = await getUserAttribs(tmpRol1);
        if (userPermissions) {
          if (Object.values(userPermissions.agro.calificacion).some(val => val)) {
            tempMenuOptions.push({
              label: "Calificación agrícola",
              img: SeguroAgricola,
              link: "/qualification",
            });
          }

          if (Object.values(userPermissions.vida.calificacion).some(val => val)) {
            tempMenuOptions.push({
              label: "Calificación vida",
              img: SeguroVidaAhorro,
              link: "/qualificationLife",
            });
          }

          if (Object.values(userPermissions.agro.candidato).some(val => val) ||
            Object.values(userPermissions.agro.cotizaciones).some(val => val)) {
            tempMenuOptions.push({
              label: "Seguro agrícola",
              img: SeguroAgricola,
              link: "/vendedor/agricola",
            });
          }

          if (Object.values(userPermissions.vida.candidato).some(val => val) ||
            Object.values(userPermissions.vida.cotizaciones).some(val => val)) {
            tempMenuOptions.push({
              label: "Seguro vida con ahorro",
              img: SeguroVidaAhorro,
              link: "/vendedor/vida",
            });
          }

          if (Object.values(userPermissions.priori).some(val => val)) {
            tempMenuOptions.push({
              label: "PRIORI Ahorro",
              img: SeguroVidaAhorro,
              link: "/vendedor/priori/prioriManagement",
            });
          }

          if (Object.values(userPermissions.confprod).some(val => val)) {
            tempMenuOptions.push({
              label: "Configuración de producto",
              img: ConfigProduct,
              link: "/producto",
            });
          }

          if (Object.values(userPermissions.confperfiles.usuarios).some(val => val) ||
            Object.values(userPermissions.confperfiles.roles).some(val => val)) {
            tempMenuOptions.push({
              label: "Configuración de perfiles",
              img: ConfigProfile,
              link: "/profile"
            });
          }

          if (userPermissions?.agro?.gestion) {
            if (Object.values(userPermissions.agro.gestion).some(val => val)) {
              tempMenuOptions.push({
                label: "Gestión agricola",
                img: DashboardVidaAgro,
                link: "/vendedor/agricola/agroManagement",
              });
            }
          }

          tempMenuOptions.push(
            { label: "Salir", icon: "log-out", action: context.signout }
          );
        }
      }
      setMenuOptions(tempMenuOptions);
    };

    if (rawUser) {
      localStorage.setItem('rawUser', rawUser);
      setViewByAccess();
    }
  }, [userPermissions, rawUser]);

  if (loadingUser) return <div>Loading...</div>;
  if (errorUser) return <div>Error loading user data</div>;

  return (
    <Fragment>
      {menuOptions && (
        <>
          <AppNav items={menuOptions} />

          <main className={Style.app}>
            <Header title={props.title}>
              <Box direction='column'>
                <User />
              </Box>
            </Header>

            {<props.children {...props.data} />}
          </main>
        </>
      )}
    </Fragment>
  );
}

function parseJwt(token) {
  if (token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } else {
    return undefined;
  }
}
