import {
  getConexionParams,
  postConexion,
  postConexionFile,
  submit,
  submitQuery,
  submitV2,
  getConexionParamsV2,
  submitGet
} from "../../conexion/Conexion";

let tempObj = {
  httpVerb: "GET",
};

export const getNacionality = async (obj) => {
  tempObj.resource = "/rest/jwt/nacionalidad";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const nacionality = await postConexion("bridgesdp", tempObj);
  return nacionality.data;
};
export const getNacionalityV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/nacionalidad";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getBanks = async (obj) => {
  tempObj.resource = "/rest/jwt/banco";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const nacionality = await postConexion("bridgesdp", tempObj);
  return nacionality.data;
};
export const getBanksV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/banco";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getCountry = async (obj) => {
  tempObj.resource = "/rest/jwt/pais";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const nacionality = await postConexion("bridgesdp", tempObj);
  return nacionality.data;
};
export const getCountryV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/pais";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getWayPaid = async (obj) => {
  tempObj.resource = "/rest/jwt/formapago";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const nacionality = await postConexion("bridgesdp", tempObj);
  return nacionality.data;
};
export const getWayPaidV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/formapago";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getCard = async (obj) => {
  tempObj.resource = "/rest/jwt/tarjeta";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const nacionality = await postConexion("bridgesdp", tempObj);
  return nacionality.data;
};
export const getCardV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/tarjeta";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getActivity = async (obj) => {
  tempObj.resource = "/rest/jwt/actividad";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const nacionality = await postConexion("bridgesdp", tempObj);
  return nacionality.data;
};
export const getActivityV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/actividad";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getProvince = async (obj) => {
  tempObj.resource = "/rest/jwt/provincia";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const province = await postConexion("bridgesdp", tempObj);
  return province.data;
};
export const getProvinceV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/provincia";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getMaritalStatus = async (obj) => {
  tempObj.resource = "/rest/jwt/estadocivil";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const status = await postConexion("bridgesdp", tempObj);
  return status.data;
};
export const getMaritalStatusV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/estadocivil";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getTypePaid = async (obj) => {
  tempObj.resource = "/rest/jwt/frecuenciapago";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const paid = await postConexion("bridgesdp", tempObj);
  return paid.data;
};
export const getTypePaidV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/frecuenciapago";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getBranchXProduct = async (obj) => {
  tempObj.resource = "/rest/jwt/productosxramo";
  tempObj.data = obj;
  tempObj.httpVerb = "GET";
  const branch = await postConexion("bridgesdp", tempObj);
  return branch.data;
};
export const getBranchXProductV2 = async () => {
  tempObj.resource = "/rest/jwt/productosxramo";
  tempObj.data = {
    ramo: "20",
    ahorro: "S",
  };
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};
export const getBranchXProductV3 = async () => {
  tempObj.resource = "/rest/jwt/productosxramo";
  tempObj.data = {
    ramo: "40",
    ahorro: "N",
  };
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getBranch = async (obj) => {
  tempObj.resource = "/rest/jwt/ramo";
  tempObj.data = obj;
  tempObj.httpVerb = "GET";
  const branch = await postConexion("bridgesdp", tempObj);
  return branch.data;
};
export const getBranchV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/ramo";
  tempObj.data = { ramo: "40" };
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getSponsor = async () => {
  tempObj.resource = "/rest/jwt/listadosponsors";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const branch = await postConexion("bridgesdp", tempObj);
  return branch.data;
};
export const getSponsorV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/listadosponsors";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getBrokers = async () => {
  tempObj.resource = "/rest/jwt/listadobrokers";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const branch = await postConexion("bridgesdp", tempObj);
  return branch.data;
};
export const getBrokersV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/listadobrokers";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getSalesForces = async () => {
  tempObj.resource = "/rest/jwt/listadofventas";
  tempObj.data = {
    origen: "",
    modelo: "",
    submodelo: ""
  };
  tempObj.httpVerb = "GET";
  const branch = await postConexion("bridgesdp", tempObj);
  return branch.data;
};
export const getSalesForcesV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/listadofventas";
  tempObj.data = {
    origen: "",
    modelo: "",
    submodelo: ""
  };
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getUsuariosSDP = async () => {
  tempObj.resource = "/rest/jwt/listadousuariosdep";
  tempObj.data = {
    departamento: "",
  };
  tempObj.httpVerb = "GET";
  const branch = await postConexion("bridgesdp", tempObj);
  return branch.data;
};
export const getUsuariosSDPV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/listadousuariosdep";
  tempObj.data = {
    departamento: ""
  };
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getSdpInvites = async () => {
  tempObj = {};
  tempObj.httpVerb = "GET";
  const branch = await postConexion("invite/getSdpInvites");
  return branch.data;
};

export const getCities = async (obj) => {
  tempObj.resource = "/rest/jwt/canton";
  tempObj.data = {
    provincia: obj,
  };
  tempObj.httpVerb = "GET";
  const cities = await postConexion("bridgesdp", tempObj);
  return cities?.data;
};

export const getCitiesLocal = async () => {
  try {
    let jsonParams = { name: 'ciudades' };
    const respConexion = await submitQuery("catalogByName", jsonParams, "get");
    return respConexion?.data?.data?.detail;
  } catch (error) {
    console.log('Error fetching cities:', error);
  }
}

export const getCitiesV2 = async (obj) => {
  tempObj.resource = "/rest/jwt/canton";
  tempObj.data = {
    provincia: obj,
  };
  tempObj.httpVerb = "GET";
  const id = await postConexion("bridgesdp", tempObj);
  return id.data;
};

export const getTypeId = async () => {
  tempObj.resource = "/rest/jwt/tipoid";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const id = await postConexion("bridgesdp", tempObj);
  return id.data;
};
export const getTypeIdV2 = async () => {
  tempObj.resource = "/rest/jwt/tipoid";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getPerson = async () => {
  tempObj.resource = "/rest/jwt/personeria";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const person = await postConexion("bridgesdp", tempObj);
  return person.data;
};
export const getPersonV2 = async () => {
  tempObj.resource = "/rest/jwt/personeria";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getGender = async () => {
  tempObj.resource = "/rest/jwt/genero";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const gender = await postConexion("bridgesdp", tempObj);
  return gender.data;
};
export const getGenderV2 = async () => {
  tempObj.resource = "/rest/jwt/genero";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getPatrimonio = async () => {
  tempObj.resource = "/rest/jwt/rangopatrimonio";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const gender = await postConexion("bridgesdp", tempObj);
  return gender.data;
};
export const getPatrimonioV2 = async () => {
  tempObj.resource = "/rest/jwt/rangopatrimonio";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getRangoIngresos = async () => {
  tempObj.resource = "/rest/jwt/rangoingreso";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const gender = await postConexion("bridgesdp", tempObj);
  return gender.data;
};
export const getRangoIngresosV2 = async () => {
  tempObj.resource = "/rest/jwt/rangoingreso";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const getParentescoTitular = async () => {
  tempObj.resource = "/rest/jwt/parentescotitular";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const gender = await postConexion("bridgesdp", tempObj);
  return gender.data;
};
export const getParentescoTitularV2 = async () => {
  tempObj.resource = "/rest/jwt/parentescotitular";
  tempObj.data = {};
  tempObj.httpVerb = "GET";
  const rawResponse = await submitV2("bridgesdp", tempObj);
  return rawResponse?.message;
};

export const lstOfac = async (obj) => {
  tempObj.resource = "/rest/jwt/listasofacext";
  tempObj.data = obj?.data;
  tempObj.httpVerb = "GET";
  const resp = await submitV2("bridgesdp", tempObj);
  return resp;
};
export const Submit = async (url, obj, type) => {
  const respConexion = await submit(url, obj, type);
  return respConexion;
};

export const SubmitGet = async (url) => {
  const respConexion = await submitGet(url);
  return respConexion;
};


export const SubmitQuery = async (url, obj, type) => {
  const respConexion = await submitQuery(url, obj, type);
  return respConexion;
};

export const getFilterDataPrices = async (obj, admin = false) => {
  let respData;
  if (admin) {
    respData = await getConexionParams("candidateByFilterAdmin", obj);
  } else {
    respData = await getConexionParams("candidateByFilterAdminChannel", obj);
  }
  return respData;
};

export const getFilterDataPricesV2 = async (obj, admin) => {
  let resource = admin ? "candidateByFilterAdmin" : "candidateByFilterAdminChannel";
  const resp = await getConexionParamsV2(resource, obj);
  return resp;
};

export const getFilterDataPricesLife = async (obj) => {
  const respData = await getConexionParams("candidateByFilter", obj);
  return respData;
};

export const getFilterDataPricesPriori = async (obj) => {
  const respData = await getConexionParams("candidateByFilter/priori", obj);
  return respData;
};

export const getFilterDataPricesAgro = async (obj) => {
  const respData = await getConexionParams("candidateByFilterAgro", obj);
  return respData;
};

export const getFilterDataPricesUser = async (obj) => {
  const respData = await getConexionParams("candidateByFilter", obj);
  return respData;
};

export const postFormSigned = async (obj) => {
  const respData = await postConexionFile("signed_form", obj);
  return respData;
};

export const postFormSave = async (obj) => {
  const respData = await postConexion("form", obj);
  return respData;
};

export const postFillFormPriori = async (obj) => {
  const respData = await postConexion("fill_form/priori", obj);
  return respData;
};

export const postFillForm = async (obj) => {
  const respData = await postConexion("fill_form", obj);
  return respData;
};

export const postFillFormMultiple = async (obj) => {
  const respData = await postConexion("fill_form_multiple", obj);
  return respData;
};

export const postFillForms = async (obj) => {
  const respData = await postConexion("fill_form_v2_multiple", obj);
  return respData;
};

export const postFillFormLight = async (obj) => {
  const respData = await postConexion("fill_form_light", obj);
  return respData;
};

export const postFillForms2v = async (obj) => {
  const respData = await postConexion("fill_form_v3", obj);
  return respData;
};

export const postCandidates = async (obj) => {
  const respData = await postConexionFile("bulk_candidate", obj);
  return respData;
};

export const enviaOtp = async (obj) => {
  tempObj.resource = "/rest/jwt/enviaotp";
  tempObj.data = obj?.data;
  tempObj.httpVerb = "GET";
  const nacionality = await postConexion("bridgesdp", tempObj);
  return nacionality.data;
};

export const saveCandidateOtp = async (candidate_id) => {
  const response = await submit(candidate_id, null, "GET");
  return response?.data;
};

export const updateCandidateOtp = async (candidate_id, data) => {
  const response = await submit(candidate_id, data, "PATCH");
  return response?.data;
};

export const getCandidateAgroCsv = async (secuencial) => {
  const respData = await getConexionParams(
    "candidate/generateCsvBySecuencial/" + secuencial
  );
  return respData;
};

export const getCandidateAgroCsvList = async (secuencial, type) => {
  const respData = await getConexionParams(
    "candidate/generateCsvBySecuencialList/" + secuencial + '/' + type
  );
  return respData;
};

export const getCandidateById = async (candidateId) => {
  const respData = await getConexionParams("candidate/" + candidateId);
  return respData;
};

export const buscaEmisionSdp = async (idProveedor, candidateId) => {
  try {
    const respData = await submit("candidate/buscaEmisionSdp/" + idProveedor, { "id": candidateId }, "PATCH");
    return respData;
  } catch (e) {
    const messageStr = e?.response?.data?.message || { "error": "Internal Server Error" };
    throw JSON.parse(messageStr);
  }
};

export const contratoXParametros = async (obj) => {
  tempObj.resource = "/rest/jwt/contratoxparametros";
  tempObj.data = obj;
  tempObj.httpVerb = "POST";
  const response = await postConexion("bridgesdp", tempObj);
  return response.data;
};

export const getSoloTarifa = async (obj) => {
  tempObj.resource = "/rest/jwt/solotarifa";
  tempObj.data = obj;
  tempObj.httpVerb = "POST";
  const result = await postConexion("bridgesdp", tempObj);
  return result;
};

export const getAgroProducts = async () => {
  const respData = await submit("product/agro", null, 'GET');
  return respData?.data;
};

export const getAgroCatalog = async () => {
  const respData = await submit("catalog/agro", null, 'GET');
  return respData?.data;
};


// export const getAgroProducts = async (obj) => {
//   const respData = await getConexionParams("product/agro", obj);
//   return respData;
// };
// export const getFilterDataPricesLife = async (obj) => {
//   const respData = await getConexionParams("/product/agro", obj);
//   return respData;
// };