export const formCompleted = {
  seccion_info_solicitante: [
    {
      label: "¿El solicitante de la póliza es diferente al asegurado?",
      value: "",
      propertyApp: "solicitantePolizaDiferenteAsegurado",
    },
    {
      label: "¿Tipo de persona solicitante diferente al asegurado?",
      value: "",
      propertyApp: "tipoPersonaSolicitanteDiferenteAsegurado",
    },
    {
      label: "Nombres completos / Razón Social",
      value: "",
      propertyApp: "nombreCompleto",
    },
  ],
  seccion_plan_de_seguro: [
    {
      label: "Producto",
      value: "",
      propertyApp: "producto",
    },
    {
      label: "Cobertura principal",
      value: "",
      propertyApp: "coberturaPrincipal",
    },
    {
      label: "Suma asegurada",
      value: "",
      propertyApp: "sumaAsegurada",
    },
    {
      label: "Cobertura adicional",
      value: "",
      propertyApp: ["coberturasAdicionales[0]coberturaAdicional"],
    },
    {
      label: "Suma asegurada de anexos",
      value: "",
      propertyApp: ["coberturasAdicionales[0]sumaAsegurada"],
    },
    {
      label: "Modalidad de pago",
      value: "",
      propertyApp: "modalidadPago",
    },
  ],
  seccion_datos_personales: [
    {
      label: "Primer apellido",
      value: "",
      propertyApp: "primerApellido",
    },
    {
      label: "Segundo apellido",
      value: "",
      propertyApp: "segundoApellido",
    },
    {
      label: "Primer nombre",
      value: "",
      propertyApp: "primerNombre",
    },
    {
      label: "Segundo nombre",
      value: "",
      propertyApp: "segundoNombre",
    },
    {
      label: "Tipo de identificación",
      value: "",
      propertyApp: "tipoIdentificacion",
    },
    {
      label: "Número de identificación",
      value: "",
      propertyApp: "numeroIdentificacion",
    },
    {
      label: "Fecha de nacimiento",
      value: "",
      propertyApp: "fechaNacimiento",
    },
    {
      label: "Género",
      value: "",
      propertyApp: "genero",
    },
    {
      label: "Nacionalidad",
      value: "",
      propertyApp: "nacionalidad",
    },
    {
      label: "Lugar de nacimiento",
      value: "",
      propertyApp: "lugarNacimiento",
    },
    {
      label: "¿Tipo de persona solicitante diferente al asegurado?",
      value: "",
      propertyApp: "tipoPersonaSolicitanteDiferenteAsegurado",
    },
    {
      label: "Estado civil",
      value: "",
      propertyApp: "estadoCivil",
    },
    {
      label: "Dirección de domicilio",
      value: "",
      propertyApp: "direccionDomicilio",
    },
    {
      label: "País de domicilio",
      value: "",
      propertyApp: "paisDomicilio",
    },
    {
      label: "Provincia",
      value: "",
      propertyApp: "provincia",
    },
    {
      label: "Ciudad",
      value: "",
      propertyApp: "ciudad",
    },
    {
      label: "Cantón",
      value: "",
      propertyApp: "canton",
    },
    {
      label: "Correo electrónico",
      value: "",
      propertyApp: "correoElectronico",
    },
    {
      label: "N° Teléfono celular",
      value: "",
      propertyApp: "telefonoCelular",
    },
    {
      label: "N° Teléfono 2",
      value: "",
      propertyApp: "telefono2",
    },
    {
      label: "País de residencia fiscal",
      value: "",
      propertyApp: "paisResidenciaFiscal",
    },
    {
      label:
        "¿Ha sido diagnosticado con alguna enfermedad por la cual se encuentre tomando medicación continua o algún tipo de tratamiento?",
      value: "",
      propertyApp: "preguntaPreliminar1",
    },
    {
      label: "Enfermedades",
      value: "",
      propertyApp: "opcionesPreguntaPreliminar1",
    },
    {
      label:
        "¿Es piloto o miembro de tripulación y/o realiza actividades de carreras de vehículos, motorizados, paracaídas, buceo, etc.?",
      value: "",
      propertyApp: "preguntaPreliminar2",
    },
    {
      label: "Piloto o miembro de tripulación",
      value: "",
      propertyApp: "opcion1PreguntaPreliminar2",
    },
    {
      label: "Carreras Auto, Motocicleta, Carro de Nieve, Bote de Moto",
      value: "",
      propertyApp: "opcion2PreguntaPreliminar2",
    },
    {
      label: "Deportes Submarino buceo",
      value: "",
      propertyApp: "opcion3PreguntaPreliminar2",
    },
    {
      label: "Deportes Aéreos (paracaidismo)",
      value: "",
      propertyApp: "opcion4PreguntaPreliminar2",
    },
    {
      label:
        "¿Durante los últimos diez años ha consumido marihuana, cocaína, sedante o cualquier sustancia controlada?",
      value: "",
      propertyApp: "preguntaPreliminar3",
    },
    {
      label: "Persona que paga el seguro",
      value: "",
      propertyApp: "personaPagaSeguro",
    },
    {
      label: "Persona que paga el seguro",
      value: "",
      propertyApp: "personaPagaSeguro",
    },
    {
      label: "Primer apellido",
      value: "",
      propertyApp: "primerApellidoPersonaPagaSeguro",
    },
    {
      label: "Segundo apellido",
      value: "",
      propertyApp: "segundoApellidoPersonaPagaSeguro",
    },
    {
      label: "Primer nombre",
      value: "",
      propertyApp: "primerNombrePersonaPagaSeguro",
    },
    {
      label: "Segundo nombre",
      value: "",
      propertyApp: "segundoNombrePersonaPagaSeguro",
    },
    {
      label: "Cédula",
      value: "",
      propertyApp: "cedulaPersonaPagaSeguro",
    },
    {
      label: "Parentesco",
      value: "",
      propertyApp: "parentesco",
    },
  ],
  seccion_actividad_economica_principal: [
    {
      label: "Actividad principal",
      value: "",
      propertyApp: "actividadPrincipal",
    },
    {
      label: "Nombre de la empresa",
      value: "",
      propertyApp: "nombreEmpresa",
    },
    {
      label: "Actividad de la empresa",
      value: "",
      propertyApp: "actividadEmpresa",
    },
    {
      label: "Cargo",
      value: "",
      propertyApp: "cargo",
    },
    {
      label: "Años en el empleo",
      value: "",
      propertyApp: "aniosEmpleo",
    },
    {
      label: "Actividad independiente que realiza y/o giro de su negocio",
      value: "",
      propertyApp: "actividadIndependienteNegocio",
    },
    {
      label: "Dirección actividad económica",
      value: "",
      propertyApp: "direccionActividadEconomica",
    },
    {
      label: "País de domicilio",
      value: "",
      propertyApp: "paisDomicilioActividadEconomica",
    },
    {
      label: "Provincia",
      value: "",
      propertyApp: "provinciaActividadEconomica",
    },
    {
      label: "Ciudad",
      value: "",
      propertyApp: "ciudadActividadEconomica",
    },
    {
      label: "Cantón",
      value: "",
      propertyApp: "cantonActividadEconomica",
    },
    {
      label: "Correo electrónico laboral",
      value: "",
      propertyApp: "correoElectronicoLaboral",
    },
    {
      label: "N° Teléfono",
      value: "",
      propertyApp: "telefonoLaboralActividadEconomica",
    },
  ],
  seccion_informacion_financiera: [
    {
      label: "Ingresos mensuales de su actividad económica principal",
      value: "",
      propertyApp: "ingresoMensualActividadEconomica",
    },
    {
      label:
        "Otros ingresos mensuales diferentes a la actividad económica principal",
      value: "",
      propertyApp: "otroIngresoDiferenteActividadEconomica",
    },
    {
      label: "Fuente de otros ingresos",
      value: "",
      propertyApp: "fuenteOtrosIngresos",
    },
    {
      label: "Total ingresos mensuales",
      value: "",
      propertyApp: "totalIngresoMensuales",
    },
    {
      label: "Total egresos mensuales",
      value: "",
      propertyApp: "totalEgresosMensuales",
    },
    {
      label: "Total activos",
      value: "",
      propertyApp: "totalActivos",
    },
    {
      label: "Total pasivos",
      value: "",
      propertyApp: "totalPasivos",
    },
    {
      label: "Patrimonio",
      value: "",
      propertyApp: "patrimonio",
    },
  ],
  seccion_beneficiarios: [
    {
      label: "Nombres completos",
      value: "",
      propertyApp: ["beneficiariosPrincipales[0].nombreCompletoBeneficiario"],
    },
    {
      label: "Porcentaje",
      value: "",
      propertyApp: ["beneficiariosPrincipales[0].porcentajeBeneficiario"],
    },
    {
      label: "Parentesco",
      value: "",
      propertyApp: ["beneficiariosPrincipales[0].parentescoBeneficiario"],
    },
    {
      label: "N° Identificación",
      value: "",
      propertyApp: [
        "beneficiariosPrincipales[0].numeroIdentificacionBeneficiario",
      ],
    },
    {
      label: "N° Teléfono",
      value: "",
      propertyApp: ["beneficiariosPrincipales[0].numeroTelefonoBeneficiario"],
    },
    {
      label: "Correo electrónico",
      value: "",
      propertyApp: [
        "beneficiariosPrincipales[0].correoElectronicoBeneficiario",
      ],
    },
    {
      label: "Nombres completos",
      value: "",
      propertyApp: ["beneficiariosContigentes[0].nombreCompletoBeneficiario"],
    },
    {
      label: "Porcentaje",
      value: "",
      propertyApp: ["beneficiariosContigentes[0].porcentajeBeneficiario"],
    },
    {
      label: "Parentesco",
      value: "",
      propertyApp: ["beneficiariosContigentes[0].parentescoBeneficiario"],
    },
    {
      label: "N° Identificación",
      value: "",
      propertyApp: [
        "beneficiariosContigentes[0].numeroIdentificacionBeneficiario",
      ],
    },
    {
      label: "N° Teléfono",
      value: "",
      propertyApp: ["beneficiariosContigentes[0].numeroTelefonoBeneficiario"],
    },
    {
      label: "Correo electrónico",
      value: "",
      propertyApp: [
        "beneficiariosContigentes[0].correoElectronicoBeneficiario",
      ],
    },
  ],
  seccion_seguros_existentes: [
    {
      label: "¿Tiene usted actualmente alguna póliza de seguro de vida?",
      value: "",
      propertyApp: "tieneActualmenteSeguroVida",
    },
    {
      label: "Compañía",
      value: "",
      propertyApp: "compania",
    },
    {
      label: "Monto asegurado",
      value: "",
      propertyApp: "montoAsegurado",
    },
    {
      label: "Año de emisión",
      value: "",
      propertyApp: "anioEmision",
    },
    {
      label: "¿La póliza solicitada reemplazará a alguna póliza vigente?",
      value: "",
      propertyApp: "polizaReemplazaPolizaVigente",
    },
    {
      label: "Indicar la póliza y el motivo:",
      value: "",
      propertyApp: "polizaPlazo",
    },
    {
      label: "¿Tiene usted alguna solicitud pendiente en alguna compañía?",
      value: "",
      propertyApp: "solicitudPendienteCompania",
    },
    {
      label: "Nombre de la compañía",
      value: "",
      propertyApp: "nombreCompania",
    },
    {
      label:
        "¿Ha recibido beneficios por concepto de seguros de vida en los dos últimos años?",
      value: "",
      propertyApp: "beneficiosSeguroVidaUltimosAnios",
    },
    {
      label: "Describe el beneficio",
      value: "",
      propertyApp: "describeBeneficio",
    },
    {
      label: "Compañía de seguros",
      value: "",
      propertyApp: "companiaSeguros",
    },
    {
      label: "Tipo de póliza",
      value: "",
      propertyApp: "tipoPoliza",
    },
    {
      label: "Monto",
      value: "",
      propertyApp: "monto",
    },
  ],
  seccion_historia_medica: [
    {
      label: "Peso en kg",
      value: "",
      propertyApp: "pesoKg",
    },
    {
      label: "Altura en cm",
      value: "",
      propertyApp: "alturaCm",
    },
    {
      label: "IMC",
      value: "",
      propertyApp: "imc",
    },
    {
      label: "¿Usted fuma?",
      value: "",
      propertyApp: "fuma",
    },
    {
      label: "¿Ha tenido algún cambio de peso en el último año?",
      value: "",
      propertyApp: "cambioPeso",
    },
    {
      label: "Aumentó peso",
      value: "",
      propertyApp: "aumentoPeso",
    },
    {
      label: "Aumentó peso",
      value: "",
      propertyApp: "aumentoPeso",
    },
    {
      label: "Aumentó peso",
      value: "",
      propertyApp: "aumentoPeso",
    },
    {
      label: "¿Cuál fue la causa?",
      value: "",
      propertyApp: "causaCambioPeso",
    },
    {
      label:
        "¿Ha sido diagnosticado con alguna enfermedad por la cual se encuentre tomando medicación continua o algún tipo de tratamiento?",
      value: "",
      propertyApp: "preguntaPreliminar4",
    },
    {
      label:
        "6.b. ¿Mareos, desmayos, epilepsia o convulsiones, jaquecas, alteración del habla, parálisis o apoplejía, derrame, accidente cerebrovascular, traumatismo craneal, trastorno nervioso o mental?",
      value: "",
      propertyApp: "preguntaPreliminar6",
    },
    {
      label:
        "6.c. ¿Cáncer, tumor o pólipos, enfermedades de la piel. alergias, ganglios linfáticos o quistes?",
      value: "",
      propertyApp: "preguntaPreliminar7",
    },
    {
      label:
        "6.d. ¿Ha recibido resultados positivos por la exposición del virus de Deficiencia Inmunodeficiencia Humana, VIH 0 ha sido diagnosticado de SIDA o cualquier patología derivada de la infección con VIH?",
      value: "",
      propertyApp: "preguntaPreliminar8",
    },
    {
      label:
        "6.e ¿Anemia, leucemia, flebitis, tromboflebitis, trastornos de la sangre, vasculares o del bazo?",
      value: "",
      propertyApp: "preguntaPreliminar9",
    },
    {
      label:
        "6.f ¿Ahogo, ronquera, tos persistente, bronquitis, pleuresía, asma, alergia, enfisema. tuberculosis o cualquier otro desorden respiratorio crónico?",
      value: "",
      propertyApp: "preguntaPreliminar10",
    },
    {
      label:
        "6.g. ¿Ictericia, cirrosis, hepatitis, hemorragia intestinal, apendicitis, úlcera, diverticulitis, hernia, colitis, hemorroides, esofagitis, dispepsia, trastornos digestivos del hígado, páncreas o vesícula biliar?",
      value: "",
      propertyApp: "preguntaPreliminar11",
    },
    {
      label:
        "6.h. ¿Azúcar, laminea, pus en la orina. infecciones urinarias, enfermedades venéreas, cálculos, quistes u otros desordenes de los riñones, vejiga, tracto urinario, próstata u órganos reproductivos?",
      value: "",
      propertyApp: "preguntaPreliminar12",
    },
    {
      label:
        "6.i. ¿Diabetes, hipoglucemia, desórdenes de la tiroides, trastornos del páncreas u otros trastornos glandulares?",
      value: "",
      propertyApp: "preguntaPreliminar13",
    },
    {
      label:
        "6.j. ¿Artritis, reumatismo, ciática, neuritis, gota, desórdenes de la columna vertebral, músculos, huesos o articulaciones, colágeno deformidad, cojera o amputación?",
      value: "",
      propertyApp: "preguntaPreliminar14",
    },
    {
      label: "6.k. ¿Enfermedades de ojos, oído o garganta?",
      value: "",
      propertyApp: "preguntaPreliminar15",
    },
    {
      label:
        "7a. ¿Ha tenido o le han recomendado tener cualquier operación, biopsia o procedimiento quirúrgico?",
      value: "",
      propertyApp: "preguntaPreliminar16",
    },
    {
      label:
        "7.b. ¿Ha estado bajo tratamiento o está actualmente tomando o ha tomado cualquier medicamento o quimioterapia?",
      value: "",
      propertyApp: "preguntaPreliminar17",
    },
    {
      label:
        "7c. ¿Tiene historia familiar de diabetes, hipertensión, desórdenes del corazón o del sistema circulatorio, suicidio o desórdenes mentales?",
      value: "",
      propertyApp: "preguntaPreliminar18",
    },
    {
      label: "7d. ¿Ha estado o está ahora en una dieta recetada?",
      value: "",
      propertyApp: "preguntaPreliminar19",
    },
    {
      label:
        "7e. ¿Ha sido rechazado, dado de baja o despedido por un empleador u otro por razones de incapacidad médica o física?",
      value: "",
      propertyApp: "preguntaPreliminar20",
    },
    {
      label: "8. ¿Ha sido admitido en un hospital, clínica o sanatorio?",
      value: "",
      propertyApp: "preguntaPreliminar21",
    },
    {
      label:
        "9. ¿Ha tenido un chequeo de rutina, exámenes de sangre, tratamiento u otros exámenes médicos en los últimos cinco años?",
      value: "",
      propertyApp: "preguntaPreliminar22",
    },
    {
      label:
        "10.a. ¿Trastornos de útero, ovarios, senos? ¿Se ha realizadochequeos ginecológicos, pap test, mamografías?",
      value: "",
      propertyApp: "preguntaPreliminar23",
    },
    {
      label: "10.b. ¿Toma usted anticonceptivos?",
      value: "",
      propertyApp: "preguntaPreliminar24",
    },
    {
      label: "10.c. ¿Está embarazada?",
      value: "",
      propertyApp: "preguntaPreliminar25",
    },
    {
      label: "10.c. Cuantas semanas",
      value: "",
      propertyApp: "semanasPreguntaPreliminar25",
    },
    {
      label: "11.a. ¿Se ha realizado chequeo prostático y/o prueba de PSA?",
      value: "",
      propertyApp: "preguntaPreliminar26",
    },
  ],
  seccion_estilo_vida: [
    {
      label:
        "1. ¿Ha consumido cualquier derivado del tabaco en los últimos 12 meses?",
      value: "",
      propertyApp: "estiloVidaPregunta4",
    },
    {
      label: "Cantidad",
      value: "",
      propertyApp: "estiloVidaPregunta4Cantidad",
    },
    {
      label:
        "2. ¿Ha sido investigado, arrestado o declarado culpable por undelito?",
      value: "",
      propertyApp: "estiloVidaPregunta5",
    },
    {
      label:
        "3. ¿Ha sido condenado por manejar bajo la influencia de alcohol y/o drogas o se le ha suspendido o revocado su licencia de conducir o ha tenido más de una infracción de tránsito?",
      value: "",
      propertyApp: "estiloVidaPregunta6",
    },
    {
      label:
        "4. ¿Ha viajado más de una vez cada dos meses o vivido fuera de su país de residencia durante el último año, o tiene la intención de hacerlo durante el próximo año?",
      value: "",
      propertyApp: "estiloVidaPregunta7",
    },
    {
      label: "Lugares",
      value: "",
      propertyApp: ["estiloVidaPregunta7Datos[0].lugar"],
    },
    {
      label:
        "5. ¿Viaja o piensa viajar fuera del país en los próximos dos años?",
      value: "",
      propertyApp: "estiloVidaPregunta8",
    },
    {
      label: "Lugar",
      value: "",
      propertyApp: "estiloVidaPregunta8Datos1",
    },
    {
      label:
        "6. ¿Por obligaciones de mi trabajo requiero viajar fuera de mi país de residencia más de una vez cada dos meses?",
      value: "",
      propertyApp: "estiloVidaPregunta9",
    },
    {
      label:
        "7. ¿Alguna vez ha solicitado o recibido beneficios por incapacidad?",
      value: "",
      propertyApp: "estiloVidaPregunta10",
    },
    {
      label: "8. ¿Consume bebidas alcohólicas?",
      value: "",
      propertyApp: "estiloVidaPregunta11",
    },
    {
      label: "Frecuencia",
      value: "",
      propertyApp: "estiloVidaPregunta11Frecuencia",
    },
    {
      label: "Cantidad",
      value: "",
      propertyApp: "estiloVidaPregunta11Cantidad",
    },
    {
      label: "9. ¿Practica algún deporte?",
      value: "",
      propertyApp: "estiloVidaPregunta12",
    },
  ],
  seccion_persona_politica: [
    {
      label:
        "Usted desempeña o ha desempeñado funciones o cargos públicos en el Ecuador o en el extranjero",
      value: "",
      propertyApp: "declaracionPoliticaPregunta1",
    },
    {
      label: "Institución",
      value: "",
      propertyApp: "declaracionPoliticaPregunta1Institucion",
    },
    {
      label: "Cargo",
      value: "",
      propertyApp: "declaracionPoliticaPregunta1Cargo",
    },
    {
      label: "Fecha de culminación del cargo",
      value: "",
      propertyApp: "declaracionPoliticaPregunta1Tiempo",
    },
    {
      label:
        "Usted es familiar de un PEP (padres/hijos/abuelos/nietos o cónyuge) o usted trabaja con una Persona Expuesta Políticamente en forma directa",
      value: "",
      propertyApp: "declaracionPoliticaPregunta2",
    },
    {
      label: "Nombres y apellidos",
      value: "",
      propertyApp: "declaracionPoliticaPregunta2NombreApellido",
    },
    {
      label: "Institución",
      value: "",
      propertyApp: "declaracionPoliticaPregunta2Institucion",
    },
    {
      label: "Cargo",
      value: "",
      propertyApp: "declaracionPoliticaPregunta2Cargo",
    },
    {
      label: "Parentesco/relación",
      value: "",
      propertyApp: "declaracionPoliticaPregunta2Parentesco",
    },
  ],
};
