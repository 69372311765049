import { lstOfac } from "components/bridges/BridgeServices";
import { ViewContext } from "components/lib";
import { useContext, useEffect, useState } from "react";
import { getCitiesLocal, getProvince } from "components/bridges/BridgeServices";
import { Controller } from "react-hook-form";
import Select from "react-select";
import Style from "../../../../../css/globalStyle.module.scss";
import { colourStyles } from "../../../../../css/globalStyles";
import Spin from "../../../agriculture/agricultureSeller.module.scss";
import { Title } from "./title";
import { useDataContext } from "../../../../../context/DataContext";

export function DatosPersonales(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  const { register, options, watch, dataInfo, errors, control, setValue, optionsCiudades, prevData } = props;
  const viewContext = useContext(ViewContext);
  const [defaultEnfermedades, setDefaultEnfermedades] = useState();
  const opcionesPreguntaPreliminar1 = watch("opcionesPreguntaPreliminar1");
  const provincia = watch("provincia");
  const cantonSeleccionado = watch("canton");
  const nacionalidad = watch("nacionalidad");
  const lugarNacimiento = watch("lugarNacimiento");
  const paisDomicilio = watch("paisDomicilio");
  const preguntaPreliminar1 = watch("preguntaPreliminar1");
  const preguntaPreliminar2 = watch("preguntaPreliminar2");
  const personaPagaSeguro = watch("personaPagaSeguro");
  const [isValidateOfac, setIsValidateOfac] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const segundoApellidoPersonaPagaSeguro = watch("segundoApellidoPersonaPagaSeguro");
  const primerApellidoPersonaPagaSeguro = watch("primerApellidoPersonaPagaSeguro");
  const segundoNombrePersonaPagaSeguro = watch("segundoNombrePersonaPagaSeguro");
  const primerNombrePersonaPagaSeguro = watch("primerNombrePersonaPagaSeguro");
  const cedulaPersonaPagaSeguroWatch = watch("cedulaPersonaPagaSeguro");
  const lstEnfermedades = [
    {
      section: "a",
      literal: "6.a",
      label: "Dolor en el pecho",
      value: "Dolor en el pecho",
    },
    {
      section: "a",
      literal: "6.a",
      label: "Hipertensión arterial",
      value: "Hipertensión arterial",
    },
    {
      section: "a",
      literal: "6.a",
      label: "Arritmia",
      value: "Arritmia",
    },
    {
      section: "a",
      literal: "6.a",
      label: "Soplo cardíaco",
      value: "Soplo cardíaco",
    },
    {
      section: "a",
      literal: "6.a",
      label: "Infarto al miocardio",
      value: "Infarto al miocardio",
    },
    {
      section: "a",
      literal: "6.a",
      label: "Anginia de pecho",
      value: "Anginia de pecho",
    },
    {
      section: "a",
      literal: "6.a",
      label: "Aneurisma",
      value: "Aneurisma",
    },
    {
      section: "a",
      literal: "6.a",
      label: "Fiebre reumática",
      value: "Fiebre reumática",
    },
    {
      section: "a",
      literal: "6.a",
      label: "Enfermedades del corazón",
      value: "Enfermedades del corazón",
    },
    {
      section: "a",
      literal: "6.a",
      label: "Enfermedades de los vasos sanguíneos",
      value: "Enfermedades de los vasos sanguíneos",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Mareos",
      value: "Mareos",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Desmayos",
      value: "Desmayos",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Epilepsia o convulsiones",
      value: "Epilepsia o convulsiones",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Jaquecas",
      value: "Jaquecas",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Alteración del habla",
      value: "Alteración del habla",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Parálisis o apoplejía",
      value: "Parálisis o apoplejía",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Derrame",
      value: "Derrame",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Accidente cerebro vascular",
      value: "Accidente cerebro vascular",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Traumatismo craneal",
      value: "Traumatismo craneal",
    },
    {
      section: "b",
      literal: "6.b",
      label: "Trastorno nervioso mental",
      value: "Trastorno nervioso mental",
    },
    {
      section: "c",
      literal: "6.c",
      label: "Cáncer",
      value: "Cáncer",
    },
    {
      section: "c",
      literal: "6.c",
      label: "Tumor o pólipos",
      value: "Tumor o pólipos",
    },
    {
      section: "c",
      literal: "6.c",
      label: "Enfermedades de la piel",
      value: "Enfermedades de la piel",
    },
    {
      section: "c",
      literal: "6.c",
      label: "Alergias",
      value: "Alergias",
    },
    {
      section: "c",
      literal: "6.c",
      label: "Gángleos linfáticos o quistes",
      value: "Gángleos linfáticos o quistes",
    },
    {
      section: "d",
      literal: "6.d",
      label: "VIH o Sida",
      value: "VIH o Sida",
    },
    {
      section: "e",
      literal: "6.e",
      label: "Anemia",
      value: "Anemia",
    },
    {
      section: "e",
      literal: "6.e",
      label: "Leucemia",
      value: "Leucemia",
    },
    {
      section: "e",
      literal: "6.e",
      label: "Flebitis",
      value: "Flebitis",
    },
    {
      section: "e",
      literal: "6.e",
      label: "Tromboflebitis",
      value: "Tromboflebitis",
    },
    {
      section: "e",
      literal: "6.e",
      label: "Trastornos de la sangre, vasculares o bazo",
      value: "Trastornos de la sangre, vasculares o bazo",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Ictericia",
      value: "Ictericia",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Cirrosis",
      value: "Cirrosis",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Hepatitis",
      value: "Hepatitis",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Hemorragia intestinal",
      value: "Hemorragia intestinal",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Apendicitis",
      value: "Apendicitis",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Úlcera",
      value: "Úlcera",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Diverticulitis",
      value: "Diverticulitis",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Hernia",
      value: "Hernia",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Colitis",
      value: "Colitis",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Hemorroides",
      value: "Hemorroides",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Esofagitis",
      value: "Esofagitis",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Dispercia",
      value: "Dispercia",
    },
    {
      section: "g",
      literal: "6.g",
      label: "Trastornos digestivos del hígado, páncreas o vesícula bilear.",
      value: "Trastornos digestivos del hígado, páncreas o vesícula bilear.",
    },
    {
      section: "i",
      literal: "6.i",
      label: "Diabetes",
      value: "Diabetes",
    },
    {
      section: "i",
      literal: "6.i",
      label: "Hipoglusemia",
      value: "Hipoglusemia",
    },
    {
      section: "i",
      literal: "6.i",
      label: "Desórdenes de la tiroides",
      value: "Desórdenes de la tiroides",
    },
    {
      section: "i",
      literal: "6.i",
      label: "Trastornos del páncreas ",
      value: "Trastornos del páncreas ",
    },
    {
      section: "i",
      literal: "6.i",
      label: "Trastornos glandulares",
      value: "Trastornos glandulares",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Artritis",
      value: "Artritis",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Reumatismo",
      value: "Reumatismo",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Ciática",
      value: "Ciática",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Neuritis",
      value: "Neuritis",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Gota",
      value: "Gota",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Desórdenes de la columan vertebral",
      value: "Desórdenes de la columan vertebral",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Desórdenes de músculos",
      value: "Desórdenes de músculos",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Desórdenes de huesos",
      value: "Desórdenes de huesos",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Desórdenes de articulaciones",
      value: "Desórdenes de articulaciones",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Deformidad del colágeno",
      value: "Deformidad del colágeno",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Cojera",
      value: "Cojera",
    },
    {
      section: "j",
      literal: "6.j",
      label: "Amputación",
      value: "Amputación",
    },

    { label: "Ahogo", value: "Ahogo", section: "f", literal: "6.f" },
    { label: "Ronquera", value: "Ronquera", section: "f", literal: "6.f" },
    {
      label: "Tos persistente",
      value: "Tos persistente",
      section: "f",
      literal: "6.f",
    },
    { label: "Bronquitis", value: "Bronquitis", section: "f", literal: "6.f" },
    { label: "Pleuresía", value: "Pleuresía", section: "f", literal: "6.f" },
    { label: "Asma", value: "Asma", section: "f", literal: "6.f" },
    { label: "Alergia", value: "Alergia", section: "f", literal: "6.f" },
    { label: "Efisema", value: "Efisema", section: "f", literal: "6.f" },
    {
      label: "Tuberculosis",
      value: "Tuberculosis",
      section: "f",
      literal: "6.f",
    },
    {
      label: "Desorden respiratorio crónico",
      value: "Desorden respiratorio crónico",
      section: "f",
      literal: "6.f",
    },
    { value: "Azúcar", label: "Azúcar", section: "h", literal: "6.h" },
    { value: "Laminea", label: "Laminea", section: "h", literal: "6.h" },
    {
      value: "Pus en la orina",
      label: "Pus en la orina",
      section: "h",
      literal: "6.h",
    },
    {
      value: "Infecciones urinarias",
      label: "Infecciones urinarias",
      section: "h",
      literal: "6.h",
    },
    {
      value: "Enfermedades venéreas",
      label: "Enfermedades venéreas",
      section: "h",
      literal: "6.h",
    },
    { value: "Cálculos", label: "Cálculos", section: "h", literal: "6.h" },
    {
      value: "Quistes u otros desordenes de los riñones",
      label: "Quistes u otros desordenes de los riñones",
      section: "h",
      literal: "6.h",
    },
    { value: "Vejiga", label: "Vejiga", section: "h", literal: "6.h" },
    {
      value: "Tracto urinario",
      label: "Tracto urinario",
      section: "h",
      literal: "6.h",
    },
    {
      value: "Próstata u órganos reproductivos",
      label: "Próstata u órganos reproductivos",
      section: "h",
      literal: "6.h",
    },
    {
      label: "Enfermedades de ojos",
      value: "Enfermedades de ojos",
      section: "k",
      literal: "6.k",
    },
    {
      label: "Enfermedades de oído",
      value: "Enfermedades de oído",
      section: "k",
      literal: "6.k",
    },
    {
      label: "Enfermedades de garganta",
      value: "Enfermedades de garganta",
      section: "k",
      literal: "6.k",
    },
  ];
  const lstParentescos = [
    { value: "Padres" },
    { value: "Hijos" },
    { value: "Abuelos" },
    { value: "Hermanos" },
    { value: "Nietos" },
    { value: "Tios" },
    { value: "Sobrinos" },
    { value: "Bisabuelos" },
    { value: "Primos hermanos" },
    { value: "Tatarabuelos" },
    { value: "Tataranietos" },
    { value: "Cónyuge" },
    { value: "Conviviente" },
    { value: "Padres del cónyuge" },
    { value: "Hijos del cónyuge" },
    { value: "Yernos" },
    { value: "Nueras" },
    { value: "Abuelos del cónyuge" },
    { value: "Padrastros" },
    { value: "Nieto político" },
  ];
  let [cedulaPersonaPagaSeguro, setCedulaPersonaPagaSeguro] = useState(
    cedulaPersonaPagaSeguroWatch === undefined
      ? ""
      : cedulaPersonaPagaSeguroWatch
  );
  let [primerApellidoTercera, setPrimerApellidoTercera] = useState(
    primerApellidoPersonaPagaSeguro === undefined ? "" : primerApellidoPersonaPagaSeguro
  );
  let [segundoApellidoTercera, setSegundoApellidoTercera] = useState(
    segundoApellidoPersonaPagaSeguro === undefined ? "" : segundoApellidoPersonaPagaSeguro
  );
  let [primerNombreTercera, setPrimerNombreTercera] = useState(
    primerNombrePersonaPagaSeguro === undefined ? "" : primerNombrePersonaPagaSeguro
  );
  let [segundoNombreTercera, setSegundoNombreTercera] = useState(
    segundoNombrePersonaPagaSeguro === undefined ? "" : segundoNombrePersonaPagaSeguro
  );

  const validateCi = () => {
    let ci = cedulaPersonaPagaSeguro;
    let numero = ci;
    let numeroProvincias = 24;

    let ok = 1;
    for (let i = 0; i < numero.length && ok === 1; i++) {
      let n = parseInt(numero.charAt(i));
      if (isNaN(n)) ok = 0;
    }
    if (ok === 0) {
      viewContext.notification.show(
        `No puede ingresar caracteres: ${ci}`,
        "error"
      );
      return { code: false, message: `No puede ingresar caracteres: ${ci}` };
    }

    if (numero.length < 10) {
      viewContext.notification.show(
        `El número ingresado no es válido (logintud<10): ${ci}`,
        "error"
      );
      return {
        code: false,
        message: `El número ingresado no es válido (logintud<10): ${ci}`,
      };
    }

    let provincia = numero.substr(0, 2);
    let provinciaInt = parseInt(provincia, 10);
    if (provinciaInt < 1 || provinciaInt > numeroProvincias) {
      viewContext.notification.show(
        `El código de la provincia (dos primeros dígitos) es inválido: ${ci}`,
        "error"
      );
      return {
        code: false,
        message: `El código de la provincia (dos primeros dígitos) es inválido: ${ci}`,
      };
    }

    let tercerDigito = parseInt(numero.charAt(2));
    if (tercerDigito > 5) {
      viewContext.notification.show(
        `El código de la provincia (dos primeros dígitos) es inválido: ${ci}`,
        "error"
      );
      return {
        code: false,
        message: `Tercer dígito inválido: ${ci} (${tercerDigito})`,
      };
    }

    let vecCoeficientes = [2, 1, 2, 1, 2, 1, 2, 1, 2];
    let acum = 0;
    for (let i = 0; i < numero.length - 1; i++) {
      let n = parseInt(numero.charAt(i));
      let resultado = n * vecCoeficientes[i];
      if (resultado >= 10) resultado -= 9;
      acum += resultado;
    }
    let digitoVerificador = 0;
    let nexTen = roundUpNearest10(acum);
    let digitoVerificadorCand = nexTen - acum;
    digitoVerificador =
      digitoVerificadorCand === 10 ? 0 : digitoVerificadorCand;

    let decimoDigito = parseInt(numero.charAt(9));
    if (decimoDigito !== digitoVerificador) {
      viewContext.notification.show(
        `Décimo dígito inválido: ${ci} (${decimoDigito})`,
        "error"
      );
      return {
        code: false,
        message: `Décimo dígito inválido: ${ci} (${decimoDigito})`,
      };
    }

    // viewContext.notification.show("CI válida", "success", true);
    return { code: true, message: "CI válida" };
  };

  const validateTerceraPersona = async () => {
    setIsValidateOfac(true);
    let respValidateCi = validateCi();
    if (!respValidateCi.code) {
      setIsValidateOfac(false);
      return;
    }
    if (primerApellidoTercera === "" || primerNombreTercera === "") {
      viewContext.notification.show(`Campos vacíos`, "error");
      setIsValidateOfac(false);
      return;
    }
    const dataOfac = JSON.parse(localStorage.getItem("loginListasOfac"));
    let objUserOfac = {
      resource: "/rest/jwt/listasofacext",
      data: {
        tipoCliente: "N",
        tipoId: "00",
        cedula: cedulaPersonaPagaSeguro,
        primerNombre: primerNombreTercera,
        segundoNombre: segundoNombreTercera,
        primerApellido: primerApellidoTercera,
        segundoApellido: segundoApellidoTercera,
        idUsuario: dataOfac.idUsuario,
        codigoPlataforma: dataOfac.codigoPlataforma,
        correoUsuario: dataOfac.correoUsuario,
      },
      httpVerb: "POST",
    };

    const resp = await lstOfac(objUserOfac);
    if (resp?.message?.data?.data?.Info === "Cliente en revisión") {
      // setValue();
      viewContext.notification.show("Cliente en revisión", "error", false);
      setCedulaPersonaPagaSeguro("");
      setPrimerApellidoTercera("");
      setSegundoApellidoTercera("");
      setPrimerNombreTercera("");
      setSegundoNombreTercera("");
      setCedulaPersonaPagaSeguro("");
      setIsValidateOfac(false);
      return {
        code: false,
        message: `Validación listas OFAC no exitosa!`,
      };
    }
    if (resp?.message?.data?.data?.Info === "Cliente no aprobado") {
      viewContext.notification.show("Cliente no aprobado", "error", false);
      setCedulaPersonaPagaSeguro("");
      setPrimerApellidoTercera("");
      setSegundoApellidoTercera("");
      setPrimerNombreTercera("");
      setSegundoNombreTercera("");
      setCedulaPersonaPagaSeguro("");
      setIsValidateOfac(false);
      return {
        code: false,
        message: `Validación listas OFAC no exitosa!`,
      };
    }
    if (resp?.message?.data?.data?.Info.includes("Cliente en revisión")) {
      viewContext.notification.show("Cliente en revisión", "error", false);
      setCedulaPersonaPagaSeguro("");
      setPrimerApellidoTercera("");
      setSegundoApellidoTercera("");
      setPrimerNombreTercera("");
      setSegundoNombreTercera("");
      setCedulaPersonaPagaSeguro("");
      setIsValidateOfac(false);
      return {
        code: false,
        message: `Validación listas OFAC no exitosa!`,
      };
    }
    if (resp?.message?.data?.data?.Info === "OK") {
      viewContext.notification.show("Validación exitosa", "success", true);

      // setStep(1);
    }
    viewContext.notification.show("Validación exitosa", "success", true);
    setIsValidateOfac(false);
  };

  function roundUpNearest10(num) {
    return Math.ceil(num / 10) * 10;
  }

  useEffect(() => {
    if (opcionesPreguntaPreliminar1) {
      setDefaultEnfermedades(opcionesPreguntaPreliminar1);
    }
  }, [opcionesPreguntaPreliminar1]);

  const [showCantones, setShowCantones] = useState(false);
  useEffect(() => {
    if (provincia !== undefined && provincia !== "" && provincia !== null && provincia !== "-Seleccionar") {
      setShowCantones(true);
    } else {
      setShowCantones(false);
    }
  }, [provincia]);

  useEffect(() => {
    if (prevData?.provincia !== undefined && prevData?.provincia !== "" && prevData?.provincia !== null) {
      setSelectedProvincia(prevData?.provincia);
    }
  }, [prevData?.provincia])

  const [selectedCanton, setSelectedCanton] = useState('');
  useEffect(() => {
    // Check if initialCanton matches any option
    const matchingOption = optionsCiudades.find(option => option.label === cantonSeleccionado);
    if (matchingOption) {
      setSelectedCanton(cantonSeleccionado);
      setValue('canton', cantonSeleccionado); // Set initial value in react-hook-form
    }
  }, [cantonSeleccionado, optionsCiudades, setValue]);

  useEffect(() => {
    if (Object.keys(prevData).length === 0) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [prevData]);

  useEffect(() => {
    if (options?.nacionalidades?.length > 0 && options?.provincias?.length > 0) {
      if (prevData?.nacionalidad === '') {
        setValue('nacionalidad', options.nacionalidades[0].label);
      } else {
        setSelectedNacionalidad(prevData.nacionalidad);
      }
      if (nacionalidad === 'ECUADOR' && !lugarNacimiento) {
        setValue('lugarNacimiento', options.provincias[0].label);
      }
      if (prevData?.paisDomicilio === '') {
        setValue('paisDomicilio', options.pais[0].label);
      } else {
        setSelectedPaisDomicilio(prevData.paisDomicilio);
      }
      if (paisDomicilio === 'ECUADOR' && !provincia) {
        setValue('provincia', options.provincias[0].label);
      }
      if (prevData?.paisResidenciaFiscal === '') {
        setValue('paisResidenciaFiscal', options.pais[0].label);
      }
      setPrimerApellidoTercera(primerApellidoPersonaPagaSeguro);
      setPrimerNombreTercera(primerNombrePersonaPagaSeguro);
      setSegundoApellidoTercera(segundoApellidoPersonaPagaSeguro);
      setSegundoNombreTercera(segundoNombrePersonaPagaSeguro);
      setCedulaPersonaPagaSeguro(cedulaPersonaPagaSeguroWatch);
    }
  }, [nacionalidad, lugarNacimiento, options.nacionalidades, options.provincias, optionsCiudades]);

  //Nacionalidad
  const [selectedNacionalidad, setSelectedNacionalidad] = useState('');
  const handleNacionalidadChange = (event) => {
    const newValue = event.target.value;
    setSelectedNacionalidad(newValue);
    setValue('nacionalidad', newValue);
    if (newValue === 'ECUADOR') {
      setValue('lugarNacimiento', options.provincias[0].label);
    } else {
      setValue('lugarNacimiento', '');
    }
  };
  //PaisDomicilio
  const [selectedPaisDomicilio, setSelectedPaisDomicilio] = useState('');
  const handlePaisDomicilioChange = (event) => {
    const newValue = event.target.value;
    setSelectedPaisDomicilio(newValue);
    setValue('paisDomicilio', newValue);
    if (newValue === 'ECUADOR') {
      setValue('provincia', options.provincias[0].label);
    } else {
      setValue('provincia', '');
    }
  };

  const handleCantonChange = (event) => {
    const newValue = event.target.value;
    setSelectedCanton(newValue);
    setValue('canton', newValue); // Update react-hook-form's value
  };

  const [selectedProvincia, setSelectedProvincia] = useState('');
  const handleProvinciaChange = async (event) => {
    const newValue = event.target.value;
    setSelectedProvincia(newValue);
    setValue('provincia', newValue);

    // Get Provinces Data
    const respProvincesV2 = localStorage.getItem('provinces')
    let respProvincesJson;
    if (respProvincesV2) {
      respProvincesJson = JSON.parse(respProvincesV2)?.data
    }
    const respProvinces = state?.provinces?.data ? state.provinces.data : respProvincesJson ? respProvincesJson : await getProvince();
    // Find the selected value of pronvince
    const foundedProv = respProvinces.find(ele => ele.astr_descripcion === newValue);
    // Get Ciudades Data
    const respCiudadesV2 = localStorage.getItem('ciudades')
    let respCiudadesJson;
    if (respCiudadesV2) {
      respCiudadesJson = JSON.parse(respCiudadesV2)?.data
    }
    const respCiudades = state?.ciudades ? state.ciudades : respCiudadesJson ? respCiudadesJson : await getCitiesLocal();
    let tmpResp = getValueByKey(respCiudades?.data ? respCiudades.data : respCiudades, foundedProv?.astr_id);
    //console.log('tmpResp:', tmpResp)
    setValue('canton', tmpResp.length > 0 ? tmpResp[0]?.astr_descripcion : '');
  };

  function getValueByKey(obj, key) {
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    } else {
      return undefined;
    }
  }

  return (
    <div>
      <Title whiteTitle='3. Sección datos personales' />
      {!isLoading ? (
        <>
          <div className={Style.mainContainerForm}>
            <div className={Style.inputContainerQuarter}>
              <label>Primer apellido</label>
              <input
                disabled
                value={dataInfo.client.apellidoPaterno}
                type='text'
                className={Style["input"]}
                {...register("primerApellido", {
                  value: dataInfo.client.apellidoPaterno,
                })}
              />
              {errors.primerApellido?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>Segundo apellido</label>
              <input
                disabled
                value={dataInfo.client.apellidoMaterno}
                type='text'
                className={Style["input"]}
                {...register("segundoApellido", {
                  value: dataInfo.client.apellidoMaterno,
                })}
              />
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>Primer nombre</label>
              <input
                disabled
                value={dataInfo.client.primerNombre}
                type='text'
                className={Style["input"]}
                {...register("primerNombre", {
                  value: dataInfo.client.primerNombre,
                })}
              />
              {errors.primerNombre?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>Segundo nombre</label>
              <input
                disabled
                value={dataInfo.client.segundoNombre}
                type='text'
                className={Style["input"]}
                {...register("segundoNombre", {
                  value: dataInfo.client.segundoNombre,
                })}
              />
            </div>
          </div>
          <div className={Style.mainContainerForm}>
            <div className={Style.inputContainerQuarter}>
              <label>Tipo de identificación</label>
              <select
                className={Style["select"]}
                disabled
                {...register("tipoIdentificacion", {
                  value: dataInfo?.client?.tipoId,
                })}
              >
                <option
                  value={dataInfo?.client?.tipoId}
                  key={`tipoIdentificacion_${dataInfo?.client?.tipoId}`}
                  defaultValue={dataInfo?.client?.tipoId}
                >
                  {dataInfo?.client?.tipoId === "00"
                    ? "CEDULA"
                    : dataInfo?.client?.tipoId === "01"
                      ? "PASAPORTE"
                      : "RUC"}
                </option>
              </select>
              {errors.tipoIdentificacion?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>Número de identificación</label>
              <input
                className={Style["input_number"]}
                disabled
                value={dataInfo.client.numeroDocumento}
                type='text'
                {...register("numeroIdentificacion", {
                  value: dataInfo.client.numeroDocumento,
                })}
              />
              {errors.numeroIdentificacion?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>Fecha de nacimiento</label>
              <input
                className={Style["input_number"]}
                disabled
                value={dataInfo.client.fechaNacimiento}
                type='datetime'
                {...register("fechaNacimiento", {
                  value: dataInfo.client.fechaNacimiento,
                })}
              />
              {errors.fechaNacimiento?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>Género</label>
              <select
                className={Style["select"]}
                disabled
                {...register("genero", { value: dataInfo?.client?.genero })}
              >
                <option
                  value={dataInfo?.client?.genero}
                  key={`genero_${dataInfo?.client?.genero}`}
                  defaultValue={dataInfo?.client?.genero}
                >
                  {dataInfo?.client?.genero === "1" ? "MASCULINO" : "FEMENINO"}
                </option>
              </select>
              {errors.genero?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
          </div>
          <div className={Style.mainContainerForm}>
            <div className={Style.inputContainerQuarter}>
              <label><b style={{ color: 'red' }}>*</b>&nbsp;Nacionalidad</label>
              <select
                className={Style["select"]}
                {...register("nacionalidad", { required: true })}
                value={selectedNacionalidad}
                onChange={handleNacionalidadChange}
              >
                {options?.nacionalidades?.map(item => {
                  return (
                    <option
                      key={`nacionalidad_${item.value}}`}
                      value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
              {errors.nacionalidad?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            {nacionalidad === "ECUADOR" ? (
              <div className={Style.inputContainerQuarter}>
                <label><b style={{ color: 'red' }}>*</b>&nbsp;Lugar de nacimiento</label>
                <select
                  className={Style["select"]}
                  {...register("lugarNacimiento", { required: true })}
                >
                  {options?.provincias?.map((item) => {
                    return (
                      <option key={`lugarNacimiento_${item.value}`} value={item.label}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            ) : (
              <div className={Style.inputContainerQuarter}>
                <label><b style={{ color: 'red' }}>*</b>&nbsp;Lugar de nacimiento</label>
                <input
                  tabIndex={9}
                  onKeyPress={(event) => {
                    if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  type='text'
                  {...register("lugarNacimiento", {
                    required: true,
                    maxLength: 255,
                  })}
                  className={Style["input"]}
                />
                {errors.lugarNacimiento?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
            )}
            <div className={Style.inputContainerQuarter}>
              <label><b style={{ color: 'red' }}>*</b>&nbsp;Estado civil</label>
              <select
                className={Style["select"]}
                {...register("estadoCivil", { required: true })}
              >
                {options?.estadoCivil?.map((item) => {
                  return (
                    <option
                      key={`estadoCivil_${item.value}`}
                      value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
              {errors.estadoCivil?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerQuarter}>
              <label><b style={{ color: 'red' }}>*</b>&nbsp;Dirección de domicilio</label>
              <input
                tabIndex={10}
                onKeyPress={(event) => {
                  if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z0-9\s]+$/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className={Style["input"]}
                type='text'
                {...register("direccionDomicilio", {
                  required: true,
                  maxLength: 255,
                })}
              />
              {errors.direccionDomicilio?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
          </div>
          <div className={Style.mainContainerForm}>
            <div className={Style.inputContainerQuarter}>
              <label><b style={{ color: 'red' }}>*</b>&nbsp;País de domicilio</label>
              <select
                className={Style["select"]}
                {...register("paisDomicilio", { required: true })}
                value={selectedPaisDomicilio}
                onChange={handlePaisDomicilioChange}
              >
                {options?.pais?.map((item) => {
                  return (
                    <option key={`paisDomicilio_${item.label}`} value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
              {errors.paisDomicilio?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            {paisDomicilio === "ECUADOR" && (
              <div className={Style.inputContainerQuarter}>
                <label><b style={{ color: 'red' }}>*</b>&nbsp;Provincia</label>
                <select
                  className={Style["select"]}
                  {...register("provincia", { required: true })}
                  value={selectedProvincia}
                  onChange={handleProvinciaChange}
                >
                  {options?.provincias?.map(item => (
                    <option key={`provincia_${item.value}`} value={item.label}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {paisDomicilio !== "ECUADOR" && (
              <div className={Style.inputContainerQuarter}>
                <label><b style={{ color: 'red' }}>*</b>&nbsp;Ciudad</label>
                <input
                  tabIndex={11}
                  className={Style["input"]}
                  type='text'
                  {...register("ciudad", {
                    required: true,
                    maxLength: 255,
                  })}
                />
                {errors.ciudad?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
            )}
            {paisDomicilio === "ECUADOR" && showCantones && (
              <div className={Style.inputContainerQuarter}>
                <label><b style={{ color: 'red' }}>*</b>&nbsp;Cantón</label>
                <select
                  className={Style["select"]}
                  {...register("canton", { required: true })}
                  value={selectedCanton}
                  onChange={handleCantonChange}
                >
                  {optionsCiudades?.filter(ciudad => ciudad.label !== "Seleccionar")?.map(item => {
                    return (
                      <option key={item.value} value={item.label}>
                        {item.label}
                      </option>
                    );
                  })}
                </select>
                {errors.canton?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
            )}
          </div>
          <div className={Style.mainContainerForm}>
            <div className={Style.inputContainerQuarter}>
              <label>Correo electrónico</label>
              <input
                className={Style["input"]}
                disabled
                value={dataInfo.client.correo}
                type='email'
              />
              {errors.correoElectronico?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>N° Teléfono celular</label>
              <input
                className={Style["input_number"]}
                disabled
                value={dataInfo.client.telefono}
                type='number'
              />
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>N° Teléfono 2</label>
              <input
                className={Style["input_number"]}
                {...register("telefono2", {})}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                //value={dataInfo.client.telefono2}
                maxLength={"10"}
                type='text'
              />
            </div>
            <div className={Style.inputContainerQuarter}>
              <label><b style={{ color: 'red' }}>*</b>&nbsp;País de residencia fiscal</label>
              <select
                className={Style["select"]}
                {...register("paisResidenciaFiscal", { required: true })}
              >
                {options?.pais?.map((item) => {
                  return (
                    <option key={`paisResidenciaFiscal_${item.value}`} value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
              {errors.paisResidenciaFiscal?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
          </div>
          <div style={{ backgroundColor: "#deebf7" }}>
            <h2 style={{ fontWeight: 600 }}>Preguntas preliminares</h2>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={Style.inputPreguntas}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;¿Ha sido diagnosticado con alguna enfermedad por la cual se
                encuentre tomando medicación continua o algún tipo de tratamiento?
              </label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <label style={{ padding: "10px 10px" }}>Si</label>
                  <input
                    tabIndex={12}
                    {...register("preguntaPreliminar1", { required: true })}
                    type='radio'
                    value='Si'
                  //onChange={handleInputChange}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ padding: "10px 10px" }}>No</label>
                  <input
                    tabIndex={13}
                    {...register("preguntaPreliminar1", { required: true })}
                    type='radio'
                    value='No'
                  />
                </div>
              </div>
            </div>
            {errors.preguntaPreliminar1?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
            {preguntaPreliminar1 === "Si" && (
              <div className={Style.inputContainer35}>
                <div style={{ padding: "10px 0px" }}>
                  <label><b style={{ color: 'red' }}>*</b>&nbsp;Enfermedades</label>
                  {defaultEnfermedades?.length >= 0 && (
                    <Controller
                      control={control}
                      name='opcionesPreguntaPreliminar1'
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className='basic-single'
                          classNamePrefix='select'
                          inputRef={ref}
                          onChange={onChange}
                          styles={colourStyles}
                          options={lstEnfermedades && lstEnfermedades}
                          isMulti
                          isSearchable
                          isLoading={options ? false : true}
                          menuPosition='fixed'
                          menuPlacement={"auto"}
                          defaultValue={defaultEnfermedades}
                          placeholder={"Seleccionar"}
                        />
                      )}
                    />
                  )}
                  {defaultEnfermedades === undefined && (
                    <Controller
                      control={control}
                      name='opcionesPreguntaPreliminar1'
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className='basic-single'
                          classNamePrefix='select'
                          inputRef={ref}
                          onChange={onChange}
                          styles={colourStyles}
                          options={lstEnfermedades && lstEnfermedades}
                          isMulti
                          isSearchable
                          isLoading={options ? false : true}
                          menuPosition='fixed'
                          menuPlacement={"auto"}
                          defaultValue={defaultEnfermedades}
                          placeholder={"Seleccionar"}
                        />
                      )}
                    />
                  )}
                  {errors.personeria?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
                </div>
              </div>
            )}
            <div className={Style.inputPreguntas}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;¿Es piloto o miembro de tripulación y/o realiza actividades de
                carreras de vehículos, motorizados, paracaídas, buceo, etc.?
              </label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <label style={{ padding: "10px 10px" }}>Si</label>
                  <input
                    tabIndex={14}
                    {...register("preguntaPreliminar2", { required: true })}
                    type='radio'
                    value='Si'
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ padding: "10px 10px" }}>No</label>
                  <input
                    tabIndex={15}
                    {...register("preguntaPreliminar2", { required: true })}
                    type='radio'
                    value='No'
                  />
                </div>
              </div>
            </div>
            {errors.preguntaPreliminar2?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
            {preguntaPreliminar2 === "Si" && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px 15px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    tabIndex={16}
                    type='checkbox'
                    {...register("opcion1PreguntaPreliminar2", {})}
                  />
                  <label style={{ paddingLeft: 10 }}>
                    Piloto o miembro de tripulación
                  </label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    tabIndex={17}
                    type='checkbox'
                    {...register("opcion2PreguntaPreliminar2", {})}
                  />
                  <label style={{ paddingLeft: 10 }}>
                    Carreras Auto, Motocicleta, Carro de Nieve, Bote de Moto
                  </label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    tabIndex={18}
                    type='checkbox'
                    {...register("opcion3PreguntaPreliminar2", {})}
                  />
                  <label style={{ paddingLeft: 10 }}>
                    Deportes Submarino buceo
                  </label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    tabIndex={19}
                    type='checkbox'
                    {...register("opcion4PreguntaPreliminar2", {})}
                  />
                  <label style={{ paddingLeft: 10 }}>
                    Deportes Aéreos (paracaidismo)
                  </label>
                </div>
              </div>
            )}
            <div className={Style.inputPreguntas}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;¿Durante los últimos diez años ha consumido marihuana, cocaína,
                sedante o cualquier sustancia controlada?
              </label>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex" }}>
                  <label style={{ padding: "10px 10px" }}>Si</label>
                  <input
                    tabIndex={20}
                    {...register("preguntaPreliminar3", { required: true })}
                    type='radio'
                    value='Si'
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label style={{ padding: "10px 10px" }}>No</label>
                  <input
                    tabIndex={21}
                    {...register("preguntaPreliminar3", { required: true })}
                    type='radio'
                    value='No'
                  />
                </div>
              </div>
            </div>
            {errors.preguntaPreliminar3?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
          <div style={{ backgroundColor: "#deebf7" }}>
            <h2 style={{ fontWeight: 600 }}><b style={{ color: 'red' }}>*</b>&nbsp;Persona que paga el seguro</h2>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                padding: "10px 15px",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ width: "5%" }}>
                  <input
                    tabIndex={22}
                    {...register("personaPagaSeguro", { required: true })}
                    type='radio'
                    value='El asegurado'
                  />
                </div>
                <div>
                  <label style={{ padding: "0px 10px" }}>El asegurado</label>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ width: "5%" }}>
                  <input
                    tabIndex={23}
                    {...register("personaPagaSeguro", { required: true })}
                    type='radio'
                    value='Una tercera persona'
                  />
                </div>
                <div>
                  <label style={{ padding: "0px 10px" }}>Una tercera persona</label>
                </div>
              </div>
            </div>
            {errors.personaPagaSeguro?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
          {personaPagaSeguro === "Una tercera persona" && (
            <>
              <div className={Style.mainContainerForm}>
                <div className={Style.inputContainerQuarter}>
                  <label><b style={{ color: 'red' }}>*</b>&nbsp;Primer apellido</label>
                  <input
                    tabIndex={24}
                    onKeyPress={(event) => {
                      if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className={Style["input"]}
                    type='text'
                    {...register("primerApellidoPersonaPagaSeguro", {
                      required: true,
                    })}
                    value={primerApellidoTercera}
                    onInput={(e) => setPrimerApellidoTercera(e.target.value)}
                  />
                  {errors.primerApellidoPersonaPagaSeguro?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
                </div>
                <div className={Style.inputContainerQuarter}>
                  <label>Segundo apellido</label>
                  <input
                    tabIndex={25}
                    onKeyPress={(event) => {
                      if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className={Style["input"]}
                    type='text'
                    value={segundoApellidoTercera}
                    onInput={(e) => setSegundoApellidoTercera(e.target.value)}
                    {...register("segundoApellidoPersonaPagaSeguro")}
                  />
                </div>
                <div className={Style.inputContainerQuarter}>
                  <label><b style={{ color: 'red' }}>*</b>&nbsp;Primer nombre</label>
                  <input
                    tabIndex={26}
                    onKeyPress={(event) => {
                      if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className={Style["input"]}
                    type='text'
                    {...register("primerNombrePersonaPagaSeguro", {
                      required: true,
                    })}
                    value={primerNombreTercera}
                    onInput={(e) => setPrimerNombreTercera(e.target.value)}
                  />
                  {errors.primerNombrePersonaPagaSeguro?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
                </div>
                <div className={Style.inputContainerQuarter}>
                  <label>Segundo nombre</label>
                  <input
                    tabIndex={27}
                    onKeyPress={(event) => {
                      if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className={Style["input"]}
                    type='text'
                    value={segundoNombreTercera}
                    onInput={(e) => setSegundoNombreTercera(e.target.value)}
                    {...register("segundoNombrePersonaPagaSeguro")}
                  />
                </div>
              </div>
              <div className={Style.mainContainerForm}>
                <div className={Style.inputContainerQuarter}>
                  <label><b style={{ color: 'red' }}>*</b>&nbsp;Cédula</label>
                  <input
                    tabIndex={28}
                    onKeyPress={(event) => {
                      if (!/[0-9]+$/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    className={Style["input_number"]}
                    type='text'
                    onInput={(e) => setCedulaPersonaPagaSeguro(e.target.value)}
                    value={cedulaPersonaPagaSeguro}
                    maxLength={10}
                    {...register("cedulaPersonaPagaSeguro", { required: true })}
                  />
                  {errors.cedulaPersonaPagaSeguro?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
                </div>
                <div className={Style.inputContainerQuarter}>
                  <label><b style={{ color: 'red' }}>*</b>&nbsp;Parentesco</label>

                  <select
                    className={Style["select"]}
                    {...register("parentesco", { required: true })}
                  >
                    {lstParentescos &&
                      lstParentescos.length > 0 &&
                      lstParentescos.map((item) => {
                        return (
                          <option key={item.value} value={item.value}>
                            {item.value}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div className={Style.inputContainerQuarter}>
                  {!isValidateOfac ? (
                    <input
                      tabIndex={29}
                      className={Style["submit"]}
                      // type='submit'
                      value='Validar'
                      onClick={validateTerceraPersona}
                    />
                  ) : (
                    <div className={Spin.ldsDualRing}></div>
                  )}
                </div>
              </div>
            </>
          )}
        </>) : (
        <div className={Spin.ldsDualRing}></div>
      )}
    </div>
  );
}
