/*eslint-disable*/
import axios from "axios";

const initData = {
  //api: "http://localhost:8080/api/",
  api: "https://appservice-sdp-bv2.azurewebsites.net/api/",
  //api: "https://apiriocert.segurosdelpichincha.com/api/"
};

export const postConexion = async (urlApi, objeto) => {
  try {
    let url = initData.api + urlApi;
    const response = await axios.post(url, objeto).catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 400:
            if (err.response.data.message === "Token does not exists.")
              throw new Error(`No hay permiso para actualizar tu contraseña`);
            else
              throw new Error(
                `El correo o contraseña ingresados son incorrectos`
              );
          case 403:
            if (
              err.response.data.message ===
              "This code is already expired by Admin!"
            )
              throw new Error(`Tu código no es valido`);
            else {
              localStorage.setItem("accessToken", null);
              throw new Error(`La autorización ha caducado, intenta de nuevo`);
            }
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", {
                  refreshToken: refreshToken,
                })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 404:
            throw new Error(
              `El correo o contraseña ingresados son incorrectos`
            );
          case 409:
            if (err.response.data.message === "Email address not valid")
              throw new Error(`Correo electrónico no válido`);
            else throw new Error(`El usuario ya poseé una cuenta`);
          case 430:
            throw new Error(
              `Su último pago no es válido o no tienes datos de pago registrados. `
            );
          case 500:
            console.error(err)
            return { status: 500, message: "Error interno del servidor" };
          default:
            throw new Error(
              `La api responde pero con error ${err.response.status}`
            );
        }
      } else if (err.request) {
        throw new Error(`No existe conexión`);
      } else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  } catch (err) {
    return Promise.reject((err.message && err) || "Something went wrong");
  }
};

export const getConexionParams = async (urlApi, params) => {
  try {
    let url = initData.api + urlApi;
    let objParam = {
      params: params,
    };
    const response = await axios.get(url, objParam).catch((err) => {

      if (err.response) {
        switch (err.response.status) {
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", {
                  refreshToken: refreshToken,
                })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 404:
            throw new Error(`Servicio no encontrado`);
          case 409:
            throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            // if (
            //   err.response.data.message ===
            //   "No existe ningun candidato con los filtros enviados"
            // )
            throw new Error(err.response.data.message);
          //else throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            localStorage.setItem("accessToken", null);
            throw new Error(
              `La api responde pero con error ${err.response.status}`
            );
        }
      } else if (err.request) {
        throw new Error(`No existe conexión`);
      } else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  } catch (err) {
    if (
      err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo"
    )
      window.location.reload();
    return Promise.reject((err.message && err) || "Something went wrong");
  }
};

export const getConexion = async (urlApi, headers = "") => {
  try {
    let url = initData.api + urlApi;
    const response = await axios.get(url).catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", {
                  refreshToken: refreshToken,
                })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 404:
            throw new Error(`Servicio no encontrado`);
          case 409:
            throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            if (
              err.response.data.message ===
              "Probably the document was disbled by admin."
            )
              throw new Error(`El documento se encuentra desactivado.`);
            else throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            localStorage.setItem("accessToken", null);
            throw new Error(
              `La api responde pero con error ${err.response.status}`
            );
        }
      } else if (err.request) {
        throw new Error(`No existe conexión`);
      } else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  } catch (err) {
    if (
      err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo"
    )
      window.location.reload();
    return Promise.reject((err.message && err) || "Something went wrong");
  }
};

export const putConexion = async (urlApi, objeto) => {
  try {
    let url = initData.api + urlApi;
    let auth = {
      headers: { Authorization: localStorage.getItem("accessToken") },
    };
    const response = await axios.put(url, objeto, auth).catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", {
                  refreshToken: refreshToken,
                })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 404:
            throw new Error(`Servicio no encontrado`);
          case 409:
            throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            throw new Error(
              `La api responde pero con error ${err.response.status}`
            );
        }
      } else if (err.request) {
        throw new Error(`No existe conexión`);
      } else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  } catch (err) {
    if (
      err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo"
    )
      window.location.reload();
    return Promise.reject((err.message && err) || "Something went wrong");
  }
};

export const deleteConexion = async (urlApi, objeto) => {
  try {
    let url = initData.api + urlApi;
    const response = await axios.delete(url, { data: objeto }).catch((err) => {
      if (err.response) {
        switch (err.response.status) {
          case 401:
            const originalRequest = err.config;
            let refreshToken = localStorage.getItem("refreshToken");
            if (refreshToken)
              return axios
                .post(initData.api + "users/token/refresh", {
                  refreshToken: refreshToken,
                })
                .then((res) => {
                  if (res.status === 200) {
                    localStorage.setItem("accessToken", res.data.accessToken);
                    return axios(originalRequest);
                  }
                });
            break;
          case 403:
            localStorage.setItem("accessToken", null);
            throw new Error(`La autorización ha caducado, intenta de nuevo`);
          case 404:
            throw new Error(`Servicio no encontrado`);
          case 409:
            throw new Error(`El usuario ya poseé una cuenta`);
          case 500:
            throw new Error(`Por favor intente de nuevo, más tarde`);
          default:
            throw new Error(
              `La api responde pero con error ${err.response.status}`
            );
        }
      } else if (err.request) {
        throw new Error(`No existe conexión`);
      } else {
        throw err;
      }
    });
    const resp = response.data;
    return resp;
  } catch (err) {
    if (
      err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo"
    )
      window.location.reload();
    return Promise.reject((err.message && err) || "Something went wrong");
  }
};

export const postConexionFile = async (
  urlApi,
  obj
  // file,
  // type,
  // candidate_id,
  // email,
  // contentType,
  // product_type
) => {
  try {
    let url = initData.api + urlApi;
    let formData = new FormData();

    if (Array.isArray(obj.file)) {
      obj.file.map((document) => {
        formData.append("file", document);
      });
    } else {
      formData.append("file", obj.file);
    }

    let content = "";
    if (obj.contentType) content = obj.contentType;
    else content = "multipart/form-data";

    if (obj.type) formData.append("type", obj.type);

    if (obj.product_type) formData.append("product_type", obj.product_type);

    if (obj.candidate_id) formData.append("candidate_id", obj.candidate_id);

    if (obj.email) formData.append("email", obj.email);

    const headers = {
      "Content-Type": content,
      // Authorization: localStorage.getItem("accessToken"),
    };

    const response = await axios.post(url, formData, {
      headers: headers,
    });

    // catch((err) => {
    //   if (err.response) {
    //     switch (err.response.status) {
    //       case 400:
    //         throw new Error(
    //           `El correo o contraseña ingresados son incorrectos`
    //         );
    //       case 401:
    //         const originalRequest = err.config;
    //         let refreshToken = localStorage.getItem("refreshToken");
    //         if (refreshToken)
    //           return axios
    //             .post(initData.api + "users/token/refresh", {
    //               refreshToken: refreshToken,
    //             })
    //             .then((res) => {
    //               if (res.status === 200) {
    //                 localStorage.setItem("accessToken", res.data.accessToken);
    //                 return axios(originalRequest);
    //               }
    //             });
    //         break;
    //       case 403:
    //         localStorage.setItem("accessToken", null);
    //         throw new Error(`La autorización ha caducado, intenta de nuevo`);
    //       case 404:
    //         throw new Error(
    //           `El correo o contraseña ingresados son incorrectos`
    //         );
    //       case 409:
    //         throw new Error(`El usuario ya poseé una cuenta`);
    //       default:
    //         throw new Error(
    //           `La api responde pero con error ${err.response.status}`
    //         );
    //     }
    //   } else if (err.request) {
    //     throw new Error(`No existe conexión`);
    //   } else {
    //     throw err;
    //   }
    // });
    const resp = response.data;
    return resp;
  } catch (err) {
    if (
      err.message === "Tu autorización ha caducado inicia de nuevo tu pedido" ||
      err.message === "La autorización ha caducado, intenta de nuevo"
    )
      window.location.reload();
    return Promise.reject((err.message && err) || "Something went wrong");
  }
};

export const submit = async (urlApi, data, method) => {
  let url = initData.api + urlApi;
  let res = await axios({
    method: method,
    url: url,
    data: data,
  });
  return res;
};

export const submitGet = async (urlApi) => {
  try {
    let url = initData.api + urlApi;
    let res = await axios.get(url);
    return res;
  } catch (err) {
    return err;
  }
};

export const submitV2 = async (urlApi, data) => {
  return new Promise(async function (resolve, reject) {
    let url = initData.api + urlApi;
    await axios
      .post(url, data)
      .then(async (response) => {
        resolve({ code: true, message: response });
      })
      .catch(async (error) => {
        resolve({ code: false, message: error.response });
      });
  });
};

export const submitQuery = async (urlApi, data, method) => {
  try {
    let queryParams = jsonToQueryString(data);
    let url = initData.api + urlApi + queryParams;
    let res = await axios({
      method: method,
      url: url
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getConexionParamsV2 = async (urlApi, params) => {
  try {
    let url = initData.api + urlApi;
    let objParam = {
      params: params,
    };
    let res = await axios.get(url, objParam);
    return res;
  } catch (err) {
    return err;
  }
};

function jsonToQueryString(json) {
  return '?' +
    Object.keys(json).map(function (key) {
      return encodeURIComponent(key) + '=' +
        encodeURIComponent(json[key]);
    }).join('&');
}
