import React, { useContext } from 'react';
import { AuthContext, Animate, Row, Card, Form, useNavigate, useLocation } from 'components/lib';

export function SigninOTP(props) {

  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AuthContext);

  const token = location?.search?.substring(7);
  if (!token) navigate('/signin');

  return (
    <Animate type='pop'>
      <Row title='Ingresa el código de verificación' desc='Ingrese el código de verificación de su aplicación de autenticación'>

        <Card restrictWidth center>
          <Form
            method='post'
            url='/api/auth/otp'
            data={{
              code: {
                type: 'text',
                label: 'Código de verificación',
                required: true
              },
              jwt: {
                type: 'hidden',
                value: token,
              }
            }}
            buttonText='Verificar'
            callback={context.signin}
          />

          <div className='mt-4'>
            ¿Perdió el acceso a su aplicación de autenticación? Ingrese su código de respaldo arriba.
          </div>

        </Card>

      </Row>
    </Animate>
  )
}