import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { BackButton } from "components/backButton/button";
import useViewPort from "components/hooks/useViewPort";
import { Animate, Button, Table, ViewContext, useNavigate } from "components/lib";
import { useContext, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Img2 from "../../../assets/img/fotoFormularios-1.png";
import Img1 from "../../../assets/img/myQuotesHeader.jpg";
import tablaPriori from "../../../assets/img/tabla priori.png";
import { SubmitQuery } from "../../../components/bridges/BridgeServices";
import { default as GlobalStyle, default as Input } from "../../../css/globalStyle.module.scss";
import Style from "../../../views/qualification/qualofication.module.scss";

export function MyQuotes(props) {
    const viewContext = useContext(ViewContext);
    const navigate = useNavigate();
    const [screenSize] = useViewPort();
    const [allCandidates, setAllCandidates] = useState([]);
    const [data, setData] = useState({});
    const [dataSearch, setDataSearch] = useState({
        name: "",
        dateBegin: "",
        dateEnd: "",
        date: "",
    });

    const handleDate = (date) => {
        if (date === null) {
            setDataSearch({
                ...dataSearch,
                date: null,
            });
        } else {
            setDataSearch({
                ...dataSearch,
                dateBegin: parseDate(date[0]),
                dateEnd: parseDate(date[1]),
                date,
            });
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setDataSearch({ ...dataSearch, [name]: value });
    };

    const parseDate = (str) => {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    };

    const handleSearchData = async () => {

        let obj = {
            name: dataSearch.name,
            fecha_desde: dataSearch.dateBegin,
            fecha_hasta: dataSearch.dateEnd,
            product_type: "priori",
        };

        await SubmitQuery("candidateByFilter/priori", obj, "get")
            .then((value) => {
                if (value?.status && value.status === 200 && value?.data?.data) {
                    viewContext.notification.show(`${value?.data?.data?.length} registros recuperados`, "success", true);
                    setAllCandidates(value?.data?.data)
                    makeDataTable(value?.data?.data)
                } else {
                    viewContext.notification.show(value?.response?.data?.message, "error", true);
                }
            })
            .catch((error) => {
                viewContext.handleError(error);
            })
            .finally(() => {
                viewContext.handleError("La Promesa está finalizada");
            });

    };

    const makeDataTable = (lstCandidates) => {
        let tempDataTable = {
            header: [
                { name: "id", title: "ID", sort: false },
                { name: "name", title: "Nombre", sort: false },
                { name: "date", title: "Fecha", sort: false },
                { name: "product", title: "Producto", sort: false },
                { name: "plan", title: "Plan", sort: false },
                { name: "status", title: "Estado", sort: false },
                { name: "detail", title: "Detalles", sort: false }
            ],
            body: [],
        };
        let tempObjectCultivation = {};

        lstCandidates.map((item, idx) => {
            tempObjectCultivation.id = item.id
            tempObjectCultivation.detail = item;
            tempObjectCultivation.name = (
                <div>{`${item.detail.nombre} ${item.detail.apellido}`}</div>
            );
            tempObjectCultivation.date = (
                <div>{`${item.updated_at.split("T")[0]}`}</div>
            );
            tempObjectCultivation.product = (
                <div>
                    {`${item?.product_type}`}
                </div>
            );
            tempObjectCultivation.plan = (
                <div>{`${item?.product?.namePlan}`}</div>
            );
            tempObjectCultivation.status = <div>{`${item.estado}`}</div>;
            tempObjectCultivation.observations = <div></div>;
            tempDataTable.body.push(tempObjectCultivation);
            tempObjectCultivation = {};
        });
        setData(tempDataTable);
    };

    const viewProduct = (data, callback) => {
        //save data.details in local storage
        localStorage.setItem("plan_details", JSON.stringify(data.detail));
        navigate(`/vendedor/priori/cotizaciones/${data.id}`, {
            state: data.detail,
        });
    };

    return (
        <Animate type='pop'>
            <div className={GlobalStyle.buttonPosition}>
                <BackButton go='/vendedor/priori/prioriManagement' />
            </div>
            {allCandidates.length === 0 ?
                <>
                    <div className={Style.mainContainerPriori}>
                        <div style={{ display: "flex", justifyContent: "left", padding: "50px 0px 0px 0px" }}>
                            <img
                                alt='logo priori'
                                src={Img1}
                            />
                        </div>
                        <br></br>
                        <div style={{ display: "flex", justifyContent: "left" }}>
                            {/* <br></br> */}
                            <div>
                                <img

                                    alt='logo perfil'
                                    src={Img2}
                                />
                            </div>
                            <div style={{ width: '50px' }}>

                            </div>
                            <div>
                                <h2 className={Style.myQuotesSubtitle}>Mis Cotizaciones</h2>
                                {/* <br></br><br></br> */}
                                <div style={{ display: "flex", justifyContent: "left" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 10,
                                        }}
                                    >
                                        <label>Buscar por nombre de candidato</label>
                                        <input
                                            className={Input["input_number"]}
                                            value={dataSearch.name}
                                            name='name'
                                            type='text'
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            padding: 10,
                                        }}
                                    >
                                        <label>Buscar por fecha</label>
                                        <DateRangePicker
                                            onChange={handleDate}
                                            value={dataSearch.date}
                                            required
                                        />
                                    </div>
                                    {/* <br></br><br></br> */}
                                </div>
                                <div style={{ marginLeft: '12px' }}>
                                    <div>
                                        <Button color='blue' text='Buscar' action={handleSearchData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> :
                <>
                    <div>
                        <h2 className={Style.myQuotesSubtitleTable}>Mis Cotizaciones</h2><br></br>
                        <div style={{ display: "flex", justifyContent: "left" }}>
                            <img
                                alt='logo priori2'
                                src={tablaPriori}
                            />
                            <h3 className={Style.myQuotesText}>&nbsp;&nbsp;&nbsp;PRIORI - Ahorro</h3>
                        </div>
                        <br></br><br></br>
                        {screenSize.dynamicWidth > 1024 ? (
                            <Table
                                id='table-Cotizaciones'
                                //search
                                show={[
                                    "name",
                                    "date",
                                    "product",
                                    "plan",
                                    "status"
                                ]}
                                data={data}
                                loading={Object.entries(allCandidates).length === 0 ? true : false}
                                hideActionsHeaders={true}
                                actions={{ edit: viewProduct }}
                            />
                        ) : (
                            <Scrollbars
                                style={{
                                    width: "100%",
                                    height: "600px",
                                }}
                            >
                                <Table
                                    id='table-Cotizaciones'
                                    //search
                                    show={[
                                        "name",
                                        "date",
                                        "product",
                                        "plan",
                                        "status"
                                    ]}
                                    data={data}
                                    loading={Object.entries(allCandidates).length === 0 ? true : false}
                                    hideActionsHeaders={true}
                                    actions={{ edit: viewProduct }}
                                />
                            </Scrollbars>
                        )}
                    </div>
                </>}

        </Animate>
    );
}
