import React, { useEffect, useState } from "react";
import { Animate, Grid } from "components/lib";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { Controller, useForm } from "react-hook-form";
import Style from "../agricultureSeller.module.scss";
import { colourStyles } from "../../../../css/globalStyles";
import "../../../../css/datePicker.css";

export function StepOne(props) {

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const {
    options,
    client,
    handleSelectChange,
    handleCityHomeChange,
    handleInputChange,
    handleDate,
    handleDatePass,
    handleIncomingDate,
    handleExpireDate,
    validateClient,
    loadingTable,
    flagIsValidEmail
  } = props;

  const onSubmit = (data) => {
    validateClient();
  };

  function verificarCedula(cedula) {
    if (
      typeof cedula == "string" &&
      cedula.length === 10 &&
      /^\d+$/.test(cedula)
    ) {
      let digitos = cedula.split("").map(Number);
      let codigo_provincia = digitos[0] * 10 + digitos[1];
      if (
        codigo_provincia >= 1 &&
        (codigo_provincia <= 24 || codigo_provincia === 30)
      ) {
        let digito_verificador = digitos.pop();

        let digito_calculado =
          digitos.reduce(function (valorPrevio, valorActual, indice) {
            return (
              valorPrevio -
              ((valorActual * (2 - (indice % 2))) % 9) -
              (valorActual === 9) * 9
            );
          }, 1000) % 10;
        return digito_calculado === digito_verificador;
      }
    }
    return false;
  }

  function verificarCelular(celular) {
    if (celular.length === 0 || celular.length > 11) return false;
    let numero = celular;
    let ok = 1;
    for (let i = 0; i < numero.length && ok === 1; i++) {
      let n = parseInt(numero.charAt(i));
      if (isNaN(n)) ok = 0;
    }
    if (ok === 0) {
      return false;
    }
    return true;
  }

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);

    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }

    return isValid;
  };

  const [tmpLugarNac] = useState("");

  const setGeneroOptions = (per) => {
    let curVec = options.genero;
    setFilteredGenero(curVec);
  };

  const [filteredGenero, setFilteredGenero] = useState([]);
  const [defaultSelectId, setDefaultSelectId] = useState();
  const [defaultSelectGenero, setDefaultSelectGenero] = useState();
  const [defaultSelectPersoneria, setDefaultSelectPersoneria] = useState();
  const [defaultSelectNacionalidad, setDefaultSelectNacionalidad] = useState();
  const [defaultSelectEstadoCivil, setDefaultSelectEstadoCivil] = useState();
  const [defaultSelectProvinciaDomicilio, setDefaultSelectProvinciaDomicilio] = useState();
  const [defaultSelectCiudadDomicilio, setDefaultSelectCiudadDomicilio] = useState();
  const [defaultSelectActividadEconomica, setDefaultSelectActividadEconomica] = useState();

  useEffect(() => {
    let tempDefaulSelect = defaultSelectId;
    options.tipoId.map((item) => {
      if (item.value === client.tipoId) {
        tempDefaulSelect = item;
        setValue("tipoId", item.value);
      }
    });
    setDefaultSelectId((prev) => tempDefaulSelect);
  }, [client.tipoId]);

  useEffect(() => {
    let tempDefaulSelectGenero = defaultSelectGenero;
    options.genero.map((item) => {
      if (item.value === client.genero) {
        tempDefaulSelectGenero = item;
        setValue("genero", item.value);
      }
    });
    setDefaultSelectGenero(tempDefaulSelectGenero);
  }, [client.genero]);

  useEffect(() => {
    let tempDefaultSelect = defaultSelectPersoneria;
    options.personeria.map((item) => {
      if (item.value === client.personeria) {
        tempDefaultSelect = item;
        setValue("personeria", item.value);
      }
    });
    setDefaultSelectPersoneria(tempDefaultSelect);
  }, [client.personeria]);

  useEffect(() => {
    let tempDefaultSelect = defaultSelectNacionalidad;
    options.nacionalidades.map((item) => {
      if (item.value === client.nacionalidad) {
        tempDefaultSelect = item;
        setValue("nacionalidades", item.value);
      }
    });
    setDefaultSelectNacionalidad(tempDefaultSelect);
  }, [client.nacionalidad]);

  useEffect(() => {
    let tempDefaultSelect = defaultSelectEstadoCivil;
    options.estadoCivil.map((item) => {
      if (item.value === client.estadoCivil) {
        tempDefaultSelect = item;
        setValue("estadoCivil", item.value);
      }
    });
    setDefaultSelectEstadoCivil(tempDefaultSelect);
  }, [client.estadoCivil]);

  useEffect(() => {
    let tempDefaultSelect = defaultSelectProvinciaDomicilio;
    options.provincias.map((item) => {
      if (item.value === client.provinciaDomicilio) {
        tempDefaultSelect = item;
        setValue("provinciasDomicilio", item.value);
      }
    });
    setDefaultSelectProvinciaDomicilio(tempDefaultSelect);
  }, [client.provinciaDomicilio]);

  useEffect(() => {
    let tempDefaultSelect = defaultSelectCiudadDomicilio;

    // if (options.ciudades.length === 0) return;
    options?.ciudadesDomicilio?.map((item) => {
      if (item.value === client.cantonDomicilio) {
        tempDefaultSelect = item;
        setValue("ciudadesDomicilio", item.value);
      }
    });
    setDefaultSelectCiudadDomicilio(tempDefaultSelect);
  }, [client.cantonDomicilio, options.ciudadesDomicilio]);

  useEffect(() => {
    let tempDefaultSelect = defaultSelectActividadEconomica;
    options.actividadesEconomicas.map((item) => {
      if (item.value === client.actividadEconomica) {
        tempDefaultSelect = item;
        setValue("actividadEconomica", item.value);
      }
    });
    setDefaultSelectActividadEconomica(tempDefaultSelect);
  }, [client.actividadEconomica]);

  useEffect(() => {
    setGeneroOptions();
    // setEstadoMigratorioOption();
  }, [options]);

  useEffect(() => {
    if (client?.fechaNacimiento) {
      setValue("fechaNacimiento", client?.fechaNacimiento);
    }
  }, [client?.fechaNacimiento]);

  useEffect(() => {
    if (client?.caducidadPasaporte) {
      setValue("caducidadPasaporte", client?.caducidadPasaporte);
    }
  }, [client?.caducidadPasaporte]);

  useEffect(() => {
    if (client?.ingresoPais) {
      setValue("ingresoPais", client?.ingresoPais);
    }
  }, [client?.ingresoPais]);

  useEffect(() => {
    if (client?.expedicionPasaporte) {
      setValue("expedicionPasaporte", client?.expedicionPasaporte);
    }
  }, [client?.expedicionPasaporte]);

  return (
    <Animate type='pop'>
      <form onSubmit={handleSubmit(onSubmit)}>
        {options.personeria && options.personeria.length ? (
          <section>
            <div>
              <section>
                <span className={Style.titleContainer}>
                  <h2 className={Style["title"]}>
                    Ingresar información del candidato
                  </h2>
                  <p>
                    <span className={Style.fontRequired}>*</span> Campos
                    requeridos
                  </p>
                </span>
                <Grid cols='2'>
                  <div className={Style.containerInput}>
                    <label>
                      Personería <span className={Style.fontRequired}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name='personeria'
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className='select'
                          classNamePrefix='select-container'
                          inputRef={ref}
                          onChange={(e) => {
                            handleSelectChange(e);
                            onChange(e.value);
                            // setValue("personeria", e.value);
                          }}
                          // styles={{
                          //     menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          // }}
                          styles={colourStyles}
                          options={options && options.personeria}
                          isSearchable
                          isLoading={options ? false : true}
                          menuPosition='fixed'
                          menuPlacement={"auto"}
                          placeholder='Seleccionar'
                          noOptionsMessage={() => "No hay opciones"}
                          value={defaultSelectPersoneria}
                        />
                      )}
                    />
                    {errors.personeria?.type === "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                  </div>
                </Grid>
                <Grid cols='2'>
                  <div className={Style.containerInput}>
                    <label>
                      Tipo de documento{" "}
                      <span className={Style.fontRequired}>*</span>
                    </label>
                    <Controller
                      control={control}
                      name='tipoId'
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className='basic-single'
                          classNamePrefix='select'
                          inputRef={ref}
                          onChange={(e) => {
                            handleSelectChange(e);
                            onChange(e.value);
                          }}
                          styles={colourStyles}
                          options={options && options.filteredTipoId}
                          isSearchable
                          isLoading={options ? false : true}
                          menuPosition='fixed'
                          menuPlacement={"auto"}
                          value={defaultSelectId}
                          placeholder='Seleccionar'
                          noOptionsMessage={() => "No hay opciones"}
                        />
                      )}
                    />
                    {errors.tipoId?.type === "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                  </div>
                  <div className={Style.containerInput}>
                    <label>
                      Número de documento{" "}
                      <span className={Style.fontRequired}>*</span>
                    </label>
                    <input
                      {...register("numeroDocumento", {
                        required: true,
                        validate: {
                          incorrectNumber: (v) => {
                            return verificarCedula(v);
                          },
                        },
                      })}
                      value={client.numeroDocumento}
                      name='numeroDocumento'
                      type='text'
                      onChange={handleInputChange}
                      onKeyPress={(event) => {
                        let regExpPattern =
                          client.tipoId === "00" || client.tipoId === "02"
                            ? /[0-9]/
                            : /[a-zA-Z0-9]/;
                        if (!regExpPattern.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={
                        client.tipoId === "00"
                          ? "10"
                          : client.tipoId === "02"
                            ? "13"
                            : "100"
                      }
                      className={Style["input_number"]}
                    />
                    {errors.numeroDocumento?.type === "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                    {errors.numeroDocumento?.type === "incorrectNumber" &&
                      client.tipoId === "00" && (
                        <p style={{ color: "red" }}>
                          Número del documento incorrecto
                        </p>
                      )}
                  </div>
                </Grid>
                {client.personeria === "N" && (
                  <>
                    <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label>
                          Apellido paterno{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          onKeyPress={(event) => {
                            if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("apellidoPaterno", { required: true })}
                          value={
                            client.apellidoPaterno.charAt(0).toUpperCase() +
                            client.apellidoPaterno.slice(1).toLowerCase()
                          }
                          name='apellidoPaterno'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                        {errors.apellidoPaterno?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>Apellido materno</label>
                        <input
                          value={
                            client.apellidoMaterno.charAt(0).toUpperCase() +
                            client.apellidoMaterno.slice(1).toLowerCase()
                          }
                          name='apellidoMaterno'
                          type='text'
                          onChange={handleInputChange}
                          onKeyPress={(event) => {
                            if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className={Style["input"]}
                        />
                      </div>
                      <div className={Style.containerInput}>
                        <label>
                          Primer nombre{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          onKeyPress={(event) => {
                            if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("primerNombre", { required: true })}
                          value={
                            client.primerNombre.charAt(0).toUpperCase() +
                            client.primerNombre.slice(1).toLowerCase()
                          }
                          name='primerNombre'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                        {errors.primerNombre?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>Segundo nombre</label>
                        <input
                          value={
                            client.segundoNombre.charAt(0).toUpperCase() +
                            client.segundoNombre.slice(1).toLowerCase()
                          }
                          name='segundoNombre'
                          type='text'
                          onChange={handleInputChange}
                          onKeyPress={(event) => {
                            if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className={Style["input"]}
                        />
                      </div>
                    </Grid>
                    <div className={Style.containerWithDateInput}>
                      <div className={Style.containerInput}>
                        <label>
                          Género <span className={Style.fontRequired}>*</span>
                        </label>
                        <Controller
                          control={control}
                          name='genero'
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              className='basic-single'
                              classNamePrefix='select'
                              inputRef={ref}
                              onChange={(e) => {
                                handleSelectChange(e);
                                onChange(e.value);
                              }}
                              // noOptionsMessage={() => "No hay opciones"}                              
                              styles={colourStyles}
                              options={filteredGenero}
                              isSearchable
                              isLoading={options ? false : true}
                              menuPosition='fixed'
                              menuPlacement={"auto"}
                              value={defaultSelectGenero}
                              placeholder='Seleccionar'
                            />
                          )}
                        />
                        {errors.genero?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>
                          Nacionalidad{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <Controller
                          control={control}
                          // defaultValue={default_value}
                          name='nacionalidades'
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              placeholder='Seleccionar'
                              noOptionsMessage={() => "No hay opciones"}
                              className='basic-single'
                              classNamePrefix='select'
                              // styles={{
                              //     menuPortal: (base) => ({...base, zIndex: 9999}),
                              // }}
                              styles={colourStyles}
                              options={options && options.nacionalidades}
                              isSearchable
                              isLoading={options ? false : true}
                              menuPosition='fixed'
                              menuPlacement={"auto"}
                              onChange={(e) => {
                                handleSelectChange(e);
                                onChange(e.value);
                              }}
                              value={defaultSelectNacionalidad}
                            />
                          )}
                        />
                        {errors.nacionalidades?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                        {/* <input
                      value={client.nacionalidad}
                      name='nacionalidad'
                      type='text'
                      onChange={handleInputChange}
                    /> */}
                      </div>
                      <div className={Style.containerInput}>
                        <label>
                          Estado civil{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <Controller
                          control={control}
                          // defaultValue={default_value}
                          name='estadoCivil'
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              placeholder='Seleccionar'
                              noOptionsMessage={() => "No hay opciones"}
                              className='basic-single'
                              classNamePrefix='select'
                              // styles={{
                              //     menuPortal: (base) => ({...base, zIndex: 9999}),
                              // }}
                              styles={colourStyles}
                              options={options && options.estadoCivil}
                              isSearchable
                              isLoading={options ? false : true}
                              menuPosition='fixed'
                              menuPlacement={"auto"}
                              onChange={(e) => {
                                handleSelectChange(e);
                                onChange(e.value);
                              }}
                              value={defaultSelectEstadoCivil}
                            />
                          )}
                        />
                        {errors.estadoCivil?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <div>
                          <label style={{ paddingRight: 15 }}>
                            Fecha de nacimiento{" "}
                            <span className={Style.fontRequired}>*</span>
                          </label>
                          <Controller
                            control={control}
                            // defaultValue={default_value}
                            name='fechaNacimiento'
                            rules={{ required: true }}
                            render={({
                              field: { onChange, value, name, ref },
                            }) => (
                              <DatePicker
                                {...register("fechaNacimiento", {
                                  required: true,
                                })}
                                onChange={(e) => {
                                  handleDate(e);
                                  onChange(e);
                                  setValue("fechaNacimiento", e);
                                }}
                                value={client.fechaNacimientoSinFormato}
                                className={Style["react-date-picker"]}
                              />
                            )}
                          />
                          {errors.fechaNacimiento?.type === "required" && (
                            <p style={{ color: "red" }}>
                              Este campo es requerido
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label>
                          Correo <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          {...register("correo", {
                            required: true,
                            validate: handleEmailValidation,
                          })}
                          value={client.correo}
                          name='correo'
                          type='text'
                          onChange={handleInputChange}
                          // pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                          className={Style["input"]}
                        />
                        {errors.correo?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                        {errors.correo?.type === "validate" && (
                          <p style={{ color: "red" }}>Correo invalido</p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>
                          N° Teléfono celular{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <div className={Style.prefixGroup}>
                          <span className={Style.prefix}>+593</span>
                          <input
                            {...register("celular", {
                              required: true,
                              validate: {
                                incorrectPhone: (v) => {
                                  return verificarCelular(v);
                                },
                              },
                            })}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={client.celular}
                            type='text'
                            onChange={handleInputChange}
                            maxLength={"10"}
                            className={Style["input_number_with_prefix"]}
                          />
                        </div>
                        {errors.celular?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                        {errors.celular?.type === "incorrectPhone" && (
                          <p style={{ color: "red" }}>
                            Teléfono celular incorrecto
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>N° Teléfono fijo</label>
                        <div className={Style.prefixGroup}>
                          <span className={Style.prefix}>+593</span>
                          <input
                            {...register("telefono", {
                              required: true,
                              validate: {
                                incorrectLandLine: (v) => {
                                  return verificarCelular(v);
                                },
                              },
                            })}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            value={client.telefono}
                            name='telefono'
                            type='text'
                            onChange={handleInputChange}
                            maxLength={"10"}
                            className={Style["input_number_with_prefix"]}
                          />
                        </div>
                        {/* {errors.telefono?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                        {errors.telefono?.type === "incorrectLandLine" && (
                          <p style={{ color: "red" }}>
                            Teléfono fijo incorrecto
                          </p>
                        )} */}
                      </div>
                    </Grid>
                    <Grid cols='4'>
                      <div style={{ display: "none", flexDirection: "column" }}>
                        <label>
                          Lugar de nacimiento{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          {...register("lugarNacimiento", { required: true })}
                          value={tmpLugarNac}
                          name='lugarNacimiento'
                          type='text'
                          onChange={handleInputChange}
                          disabled={true}
                          className={Style["input"]}
                        />
                        {/*{errors.lugarNacimiento?.type === "required" && (*/}
                        {/*    <p style={{color: "red"}}>Este campo es requerido</p>*/}
                        {/*)}*/}
                      </div>
                      {/* <div className={Style.containerInput}>
                        <label>Provincia de nacimiento</label>
                        <Controller
                          control={control}
                          // defaultValue={default_value}
                          name='provincias'
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              placeholder='Seleccionar'
                              noOptionsMessage={() => "No hay opciones"}
                              className='basic-single'
                              classNamePrefix='select'
                              // styles={{
                              //     menuPortal: (base) => ({...base, zIndex: 9999}),
                              // }}
                              styles={colourStyles}
                              options={options && options.provincias}
                              isSearchable
                              isLoading={options ? false : true}
                              menuPosition='fixed'
                              menuPlacement={"auto"}
                              onChange={(e) => {
                                handleSelectChange(e);
                                onChange(e.value);
                                setLugarNacProv(null);
                              }}
                            />
                          )}
                        />
                        {errors.provincias?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div> */}
                      {/* {options.ciudades.length > 0 ||
                      client.provincia === "" ? (
                        <>
                          <div className={Style.containerInput}>
                            <label>Cantón de nacimiento</label>
                            <Controller
                              control={control}
                              name='ciudades'
                              rules={{ required: true }}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => (
                                <Select
                                  placeholder='Seleccionar'
                                  noOptionsMessage={() => "No hay opciones"}
                                  isDisabled={
                                    options && options.ciudades.length === 0
                                      ? true
                                      : false
                                  }
                                  className='basic-single'
                                  classNamePrefix='select'
                                  // styles={{
                                  //     menuPortal: (base) => ({...base, zIndex: 9999}),
                                  // }}
                                  styles={colourStyles}
                                  options={options && options.ciudades}
                                  isSearchable
                                  //defaultValue={options?.ciudades[0]}
                                  isLoading={options ? false : true}
                                  menuPosition='fixed'
                                  menuPlacement={"auto"}
                                  onChange={(e) => {
                                    handleSelectChange(e);
                                    handleCityChange();
                                    setLugarNacProv(null);
                                    onChange(e.value);
                                  }}
                                />
                              )}
                            />
                            {errors.ciudades?.type === "required" && (
                              <p style={{ color: "red" }}>
                                Este campo es requerido
                              </p>
                            )}
                          </div>
                          {options.parroquias.length > 0 ||
                          client.canton === "" ? (
                            <div className={Style.containerInput}>
                              <label>Parroquia de nacimiento</label>
                              <Controller
                                control={control}
                                // defaultValue={default_value}
                                name='parroquias'
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, value, name, ref },
                                }) => (
                                  <Select
                                    placeholder='Seleccionar'
                                    noOptionsMessage={() => "No hay opciones"}
                                    isDisabled={
                                      options && options.parroquias.length === 0
                                        ? true
                                        : false
                                    }
                                    className='basic-single'
                                    classNamePrefix='select'
                                    // styles={{
                                    //     menuPortal: (base) => ({
                                    //         ...base,
                                    //         zIndex: 9999
                                    //     }),
                                    // }}
                                    styles={colourStyles}
                                    options={options && options.parroquias}
                                    isSearchable
                                    isLoading={options ? false : true}
                                    menuPosition='fixed'
                                    menuPlacement={"auto"}
                                    onChange={(e) => {
                                      handleSelectChange(e);
                                      onChange(e.value);
                                      setLugarNacProv(e);
                                    }}
                                  />
                                )}
                              />
                              {errors.parroquias?.type === "required" && (
                                <p style={{ color: "red" }}>
                                  Este campo es requerido
                                </p>
                              )}
                            </div>
                          ) : (
                            <div className={Style.ldsDualRing}></div>
                          )}
                        </>
                      ) : (
                        <div className={Style.ldsDualRing}></div>
                      )} */}
                    </Grid>
                    <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label>Dirección de domicilio</label>
                        <input
                          {...register("direccionDomicilio", {
                            // required: true,
                          })}
                          value={client.direccionDomicilio}
                          name='direccionDomicilio'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                        {/* {errors.direccionDomicilio?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )} */}
                      </div>
                      <div className={Style.containerInput}>
                        <label>Calle principal</label>
                        <input
                          {...register("callePrincipal")}
                          value={client.callePrincipal}
                          name='callePrincipal'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                        {/* {errors.callePrincipal?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )} */}
                      </div>
                      <div className={Style.containerInput}>
                        <label>Calle transversal</label>
                        <input
                          {...register("calleTransversal")}
                          value={client.calleTransversal}
                          name='calleTransversal'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                        {/* {errors.calleTransversal?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )} */}
                      </div>
                      <div className={Style.containerInput}>
                        <label>Numeración</label>
                        <input
                          {...register("numeracion")}
                          value={client.numeracion}
                          name='numeracion'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input_number"]}
                        />
                        {/* {errors.numeracion?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )} */}
                      </div>
                    </Grid>
                    <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label>
                          Provincia de domicilio{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <Controller
                          control={control}
                          // defaultValue={default_value}
                          name='provinciasDomicilio'
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              placeholder='Seleccionar'
                              noOptionsMessage={() => "No hay opciones"}
                              className='basic-single'
                              classNamePrefix='select'
                              // styles={{
                              //     menuPortal: (base) => ({...base, zIndex: 9999}),
                              // }}
                              styles={colourStyles}
                              options={options && options.provinciasDomicilio}
                              isSearchable
                              isLoading={options ? false : true}
                              menuPosition='fixed'
                              menuPlacement={"auto"}
                              onChange={(e) => {
                                handleSelectChange(e);
                                onChange(e.value);
                              }}
                              value={defaultSelectProvinciaDomicilio}
                            />
                          )}
                        />
                        {errors.provinciasDomicilio?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      {options.ciudadesDomicilio.length > 0 ||
                        client.provinciaDomicilio === "" ? (
                        <>
                          <div className={Style.containerInput}>
                            <label>
                              Cantón de domicilio{" "}
                              <span className={Style.fontRequired}>*</span>
                            </label>
                            <Controller
                              control={control}
                              // defaultValue={default_value}
                              name='ciudadesDomicilio'
                              rules={{ required: true }}
                              render={({
                                field: { onChange, value, name, ref },
                              }) => (
                                <Select
                                  placeholder='Seleccionar'
                                  noOptionsMessage={() => "No hay opciones"}
                                  isDisabled={
                                    options &&
                                      options.ciudadesDomicilio.length === 0
                                      ? true
                                      : false
                                  }
                                  className='basic-single'
                                  classNamePrefix='select'
                                  // styles={{
                                  //     menuPortal: (base) => ({...base, zIndex: 9999}),
                                  // }}
                                  styles={colourStyles}
                                  options={options && options.ciudadesDomicilio}
                                  isSearchable
                                  isLoading={options ? false : true}
                                  menuPosition='fixed'
                                  menuPlacement={"auto"}
                                  onChange={(e) => {
                                    handleSelectChange(e);
                                    handleCityHomeChange();
                                    onChange(e.value);
                                  }}
                                  value={defaultSelectCiudadDomicilio}
                                />
                              )}
                            />
                            {errors.ciudadesDomicilio?.type === "required" && (
                              <p style={{ color: "red" }}>
                                Este campo es requerido
                              </p>
                            )}
                          </div>
                          {/* {options.parroquiasDomicilio.length > 0 ||
                          client.cantonDomicilio === "" ? (
                            <div className={Style.containerInput}>
                              <label>Parroquia de domicilio</label>
                              <Controller
                                control={control}
                                // defaultValue={default_value}
                                name='parroquiasDomicilio'
                                rules={{ required: true }}
                                render={({
                                  field: { onChange, value, name, ref },
                                }) => (
                                  <Select
                                    placeholder='Seleccionar'
                                    noOptionsMessage={() => "No hay opciones"}
                                    isDisabled={
                                      options &&
                                      options.parroquiasDomicilio.length === 0
                                        ? true
                                        : false
                                    }
                                    className='basic-single'
                                    classNamePrefix='select'
                                    styles={colourStyles}
                                    options={
                                      options && options.parroquiasDomicilio
                                    }
                                    isSearchable
                                    isLoading={options ? false : true}
                                    menuPosition='fixed'
                                    menuPlacement={"auto"}
                                    onChange={(e) => {
                                      handleSelectChange(e);
                                      onChange(e.value);
                                    }}
                                  />
                                )}
                              />
                              {errors.parroquiasDomicilio?.type ===
                                "required" && (
                                <p style={{ color: "red" }}>
                                  Este campo es requerido
                                </p>
                              )}
                            </div>
                          ) : (
                            <div className={Style.ldsDualRing}></div>
                          )} */}
                        </>
                      ) : (
                        <div className={Style.ldsDualRing}></div>
                      )}
                    </Grid>
                    <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label>
                          Actividad económica{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <Controller
                          control={control}
                          // defaultValue={default_value}
                          name='actividadEconomica'
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              placeholder='Seleccionar'
                              noOptionsMessage={() => "No hay opciones"}
                              className='basic-single'
                              classNamePrefix='select'
                              // styles={{
                              //     menuPortal: (base) => ({...base, zIndex: 9999}),
                              // }}
                              styles={colourStyles}
                              options={options && options.actividadesEconomicas}
                              isSearchable
                              isLoading={options ? false : true}
                              menuPosition='fixed'
                              menuPlacement={"auto"}
                              onChange={(e) => {
                                handleSelectChange(e);
                                onChange(e.value);
                              }}
                              value={defaultSelectActividadEconomica}
                            />
                          )}
                        />
                        {errors.actividadEconomica?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>Ingresos anuales</label>
                        <input
                          {...register("ingresosAnuales", {
                            // required: true,
                            value: 1000,
                          })}
                          value='1000'
                          disabled
                          name='ingresosAnuales'
                          type='text'
                          // onChange={handleInputChange}
                          // onKeyPress={(event) => {
                          // let regExpPattern = /[0-9]/;
                          // if (!regExpPattern.test(event.key)) {
                          // event.preventDefault();
                          // }
                          // }}
                          className={Style["input_number"]}
                        />
                        {errors.ingresosAnuales?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>Patrimonio</label>
                        <input
                          {...register("patrimonio", {
                            // required: true,
                            value: 10000,
                          })}
                          value='10000'
                          disabled
                          name='patrimonio'
                          type='text'
                          // onChange={handleInputChange}
                          // onKeyPress={(event) => {
                          // let regExpPattern = /[0-9]/;
                          // if (!regExpPattern.test(event.key)) {
                          // event.preventDefault();
                          // }
                          // }}
                          className={Style["input_number"]}
                        />
                        {errors.patrimonio?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                    </Grid>
                    {/* <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label>Persona políticamente expuesta</label>
                        <Controller
                          control={control}
                          // defaultValue={default_value}
                          name='personaPolitica'
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              placeholder='Seleccionar'
                              noOptionsMessage={() => "No hay opciones"}
                              className='basic-single'
                              classNamePrefix='select'
                              // styles={{
                              //     menuPortal: (base) => ({...base, zIndex: 9999}),
                              // }}
                              styles={colourStyles}
                              options={[
                                {
                                  type: "personaPolitica",
                                  label: "Si",
                                  value: "Si",
                                },
                                {
                                  type: "personaPolitica",
                                  label: "No",
                                  value: "No",
                                },
                              ]}
                              isSearchable
                              isLoading={options ? false : true}
                              menuPosition='fixed'
                              menuPlacement={"auto"}
                              onChange={(e) => {
                                handleSelectChange(e);
                                onChange(e.value);
                              }}
                            />
                          )}
                        />
                        {errors.personaPolitica?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                    </Grid> */}
                  </>
                )}
                {client.personeria === "J" && (
                  <>
                    <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label>
                          Razón social{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          onKeyPress={(event) => {
                            if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("razonSocial", { required: true })}
                          value={client.razonSocial}
                          name='razonSocial'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                        {errors.razonSocial?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>
                          Dirección{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          {...register("direccion", { required: true })}
                          value={client.direccion}
                          name='direccion'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                        {errors.direccion?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>Dirección de domicilio</label>
                        <input
                          {...register("direccionDomicilio", {
                            required: true,
                          })}
                          value={client.direccionDomicilio}
                          name='direccionDomicilio'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                      </div>
                    </Grid>
                    <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label>
                          Correo eletrónico{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          {...register("correo", {
                            required: true,
                            validate: handleEmailValidation,
                          })}
                          value={client.correo}
                          name='correo'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                        {errors.correo?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                        {errors.correo?.type === "validate" && (
                          <p style={{ color: "red" }}>Correo invalido</p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>
                          Celular
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <div className={Style.prefixGroup}>
                          <span className={Style.prefix}>+593</span>
                          <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("celular", {
                              required: true,
                              validate: {
                                incorrectPhone: (v) => {
                                  return verificarCelular(v);
                                },
                              },
                            })}
                            value={client.celular}
                            name='celular'
                            type='text'
                            maxLength={"10"}
                            onChange={handleInputChange}
                            className={Style["input_number"]}
                          />
                        </div>
                        {errors.celular?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                        {errors.celular?.type === "incorrectPhone" && (
                          <p style={{ color: "red" }}>
                            Teléfono celular incorrecto
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>N° Teléfono fijo</label>
                        <div className={Style.prefixGroup}>
                          <span className={Style.prefix}>+593</span>
                          <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            {...register("telefono", {
                              // required: true,
                              validate: {
                                incorrectLandLine: (v) => {
                                  return verificarCelular(v);
                                },
                              },
                            })}
                            value={client.telefono}
                            name='telefono'
                            type='text'
                            onChange={handleInputChange}
                            maxLength={"10"}
                            className={Style["input_number"]}
                          />
                        </div>
                        {/* {errors.telefono?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )} */}
                      </div>
                    </Grid>
                    <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label style={{ marginBottom: "inherit" }}>
                          Representante legal{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          onKeyPress={(event) => {
                            if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("representanteLegal", {
                            required: true,
                          })}
                          value={client.representanteLegal}
                          name='representanteLegal'
                          type='text'
                          onChange={handleInputChange}
                          className={Style["input"]}
                        />
                        {errors.representanteLegal?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>
                          Identificación del representante legal{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          onKeyPress={(event) => {
                            let regExpPattern = /[0-9]/;
                            if (!regExpPattern.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          {...register("numeroDocumentoRepresentanteLegal", {
                            required: true,
                          })}
                          value={client.numeroDocumentoRepresentanteLegal}
                          name='numeroDocumentoRepresentanteLegal'
                          type='text'
                          onChange={handleInputChange}
                          maxLength={"10"}
                          className={Style["input_number"]}
                        />
                        {errors.numeroDocumentoRepresentanteLegal?.type ===
                          "required" && (
                            <p style={{ color: "red" }}>
                              Este campo es requerido
                            </p>
                          )}
                      </div>
                    </Grid>
                    <Grid cols='4'>
                      <div className={Style.containerInput}>
                        <label>
                          Actividad económica{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <Controller
                          control={control}
                          // defaultValue={default_value}
                          name='actividadEconomica'
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              placeholder='Seleccionar'
                              noOptionsMessage={() => "No hay opciones"}
                              className='basic-single'
                              classNamePrefix='select'
                              // styles={{
                              //     menuPortal: (base) => ({...base, zIndex: 9999}),
                              // }}
                              styles={colourStyles}
                              options={options && options.actividadesEconomicas}
                              isSearchable
                              isLoading={options ? false : true}
                              menuPosition='fixed'
                              menuPlacement={"auto"}
                              onChange={(e) => {
                                handleSelectChange(e);
                                onChange(e.value);
                              }}
                              value={defaultSelectActividadEconomica}
                            />
                          )}
                        />
                        {errors.actividadEconomica?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>
                          Ingresos anuales (USD){" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          {...register("ingresosAnuales", {
                            // required: true,
                            value: 1000,
                          })}
                          disabled
                          value='1000'
                          name='ingresosAnuales'
                          type='text'
                          // onChange={handleInputChange}
                          // onKeyPress={(event) => {
                          //   let regExpPattern = /[0-9]/;
                          //   if (!regExpPattern.test(event.key)) {
                          //     event.preventDefault();
                          //   }
                          // }}
                          className={Style["input_number"]}
                        />
                        {errors.ingresosAnuales?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                      <div className={Style.containerInput}>
                        <label>
                          Patrimonio{" "}
                          <span className={Style.fontRequired}>*</span>
                        </label>
                        <input
                          {...register("patrimonio", { value: 10000 })}
                          disabled
                          value='10000'
                          name='patrimonio'
                          type='text'
                          // onChange={handleInputChange}
                          // onKeyPress={(event) => {
                          //   let regExpPattern = /[0-9]/;
                          //   if (!regExpPattern.test(event.key)) {
                          //     event.preventDefault();
                          //   }
                          // }}
                          className={Style["input_number"]}
                        />
                        {errors.patrimonio?.type === "required" && (
                          <p style={{ color: "red" }}>
                            Este campo es requerido
                          </p>
                        )}
                      </div>
                    </Grid>
                  </>
                )}
              </section>
              {client.tipoId === "01" && client.personeria === "N" && (
                <section style={{ paddingBottom: "20px" }}>
                  <span
                    style={{
                      display: "flex",
                      fontWeight: 700,
                      color: "#0F265C",
                    }}
                  >
                    <h2>Ingresar pasaporte</h2>
                  </span>
                  <div className={Style.containerWithDateInput}>
                    <div className={Style.containerInput}>
                      <label>
                        Estado migratorio{" "}
                        <span className={Style.fontRequired}>*</span>
                      </label>
                      <input
                        {...register("estadoMigratorio", {
                          required: true,
                        })}
                        value={client.estadoMigratorio}
                        name='estadoMigratorio'
                        type='text'
                        onChange={handleInputChange}
                        className={Style["inputState"]}
                      />
                      {errors.estadoMigratorio?.type === "required" && (
                        <p style={{ color: "red" }}>Este campo es requerido</p>
                      )}
                    </div>
                    <div className={Style.containerInput}>
                      <label>
                        Fecha de expedición pasaporte{" "}
                        <span className={Style.fontRequired}>*</span>
                      </label>
                      <Controller
                        control={control}
                        // defaultValue={default_value}
                        name='expedicionPasaporte'
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <DatePicker
                            {...register("expedicionPasaporte", {
                              required: true,
                            })}
                            onChange={(e) => {
                              handleDatePass(e);
                              setValue("expedicionPasaporte", e);
                              onChange(e);
                            }}
                            value={client.expedicionPasaporteSinFormato}
                          />
                        )}
                      />
                      {errors.expedicionPasaporte?.type === "required" && (
                        <p style={{ color: "red" }}>Este campo es requerido</p>
                      )}
                    </div>
                    <div className={Style.containerInput}>
                      <label>
                        Fecha último ingreso al país{" "}
                        <span className={Style.fontRequired}>*</span>
                      </label>
                      <Controller
                        control={control}
                        // defaultValue={default_value}
                        name='ingresoPais'
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <DatePicker
                            {...register("ingresoPais", {
                              required: true,
                            })}
                            onChange={(e) => {
                              handleIncomingDate(e);
                              setValue("ingresoPais", e);
                              onChange(e);
                            }}
                            value={client.ingresoPaisSinFormato}
                          />
                        )}
                      />
                      {errors.ingresoPais?.type === "required" && (
                        <p style={{ color: "red" }}>Este campo es requerido</p>
                      )}
                      {/* <input
                      value={client.ingresoPais}
                      name='ingresoPais'
                      type='text'
                      onChange={handleInputChange}
                    /> */}
                    </div>
                    <div className={Style.containerInput}>
                      <label>
                        Fecha caducidad pasaporte{" "}
                        <span className={Style.fontRequired}>*</span>
                      </label>
                      <Controller
                        control={control}
                        // defaultValue={default_value}
                        name='caducidadPasaporte'
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <DatePicker
                            {...register("caducidadPasaporte", {
                              required: true,
                            })}
                            onChange={(e) => {
                              handleExpireDate(e);
                              setValue("caducidadPasaporte", e);
                              onChange(e);
                            }}
                            value={client.caducidadPasaporteSinFormato}
                          />
                        )}
                      />
                      {errors.caducidadPasaporte?.type === "required" && (
                        <p style={{ color: "red" }}>Este campo es requerido</p>
                      )}
                      {/* <input
                      value={client.caducidadPasaporte}
                      name='caducidadPasaporte'
                      type='text'
                      onChange={handleInputChange}
                    /> */}
                    </div>
                  </div>
                </section>
              )}
              <section>
                {loadingTable && flagIsValidEmail ? (
                  <div className={Style.ldsDualRingContainer}>
                    <div className={Style.ldsDualRing}></div>
                  </div>
                ) : (<>
                  <div>
                    <input
                      className={Style["submit"]}
                      type='submit'
                      value='Validar Cliente'
                      onClick={validateClient}
                    />
                  </div>
                </>)}
              </section>
            </div>
          </section>
        ) : (
          <div className={Style.ldsDualRingContainer}>
            <div className={Style.ldsDualRing}></div>
          </div>
        )}
      </form>
    </Animate>
  );

}
