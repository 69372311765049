import { getBanks, getCard, getParentescoTitular, lstOfac } from "components/bridges/BridgeServices";
import { ViewContext } from "components/lib";
import { useContext, useEffect, useState } from "react";
import { Title } from "./title";
import { useDataContext } from "../../../../../context/DataContext";
import InputMask from 'react-input-mask';
import Style from "../../../../../css/globalStyle.module.scss";

export function AutorizacionDebito(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  const {
    autorizacionDebito,
    setAutorizacionDebito,
    setIsLoading,
    savedCedulaTerceraPersona,
    setSavedCedulaTerceraPersona,
  } = props;
  const [isRetreived, setIsRetreived] = useState(false);

  useEffect(() => {

    if (savedCedulaTerceraPersona !== autorizacionDebito.numeroIdentificacion && autorizacionDebito.numeroIdentificacion.length > 0) {
      setAutorizacionDebito({ ...autorizacionDebito, isValidOFAC: false });
    }

  }, [autorizacionDebito.numeroIdentificacion]);

  const viewContext = useContext(ViewContext);
  const [bancos, setBancos] = useState([]);
  const [tarjetas, setTarjetas] = useState([]);
  const [lstParentescos, setLstParentescos] = useState([]);
  const [isFocusedFechaVencimiento, setIsFocusedFechaVencimiento] = useState(false);
  const [isFocusedNumeroTarjeta, setIsFocusedNumeroTarjeta] = useState(false);

  const handleInputChangeFechaVencimiento = (e) => {
    const numericInput = e.target.value.replace(/[^0-9]/g, '');
    setAutorizacionDebito({ ...autorizacionDebito, fechaVencimientoTarjeta: numericInput });
  };

  const handleFocusFechaVencimiento = () => {
    setIsFocusedFechaVencimiento(true);
    setIsFocusedNumeroTarjeta(false);
  };

  const handleBlurFechaVencimiento = () => {
    setIsFocusedFechaVencimiento(false);
  };

  //Masked section
  //Credit Card
  const [originalCardNumber, setOriginalCardNumber] = useState('');
  const [maskedCardNumber, setMaskedCardNumber] = useState('');

  const maskCardNumber = (cardNumber) => {
    const visibleDigits = 6;
    const lastDigits = 4;

    if (cardNumber.length < visibleDigits + lastDigits) {
      return cardNumber;
    }

    const maskedDigits = cardNumber.substring(0, visibleDigits) + '*'.repeat(cardNumber.length - visibleDigits - lastDigits) + cardNumber.substring(cardNumber.length - lastDigits);
    return maskedDigits;
  };

  const handleInputChange = (e) => {
    const inputCardNumber = e.target.value.replace(/\s/g, ''); // Eliminar espacios en blanco
    setOriginalCardNumber(inputCardNumber);
    setMaskedCardNumber(maskCardNumber(inputCardNumber));
  };

  //Account Number
  const [originalValue, setOriginalValue] = useState('');
  const [maskedValue, setMaskedValue] = useState('');

  const handleChangeAcc = (event) => {
    const inputValue = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    setOriginalValue(inputValue);

    const length = inputValue.length;
    let masked = '';
    if (length > 0) {
      const firstSegmentLength = Math.ceil(length / 4);
      const secondSegmentLength = Math.floor(length / 4);
      const thirdSegmentLength = Math.floor(length / 4);
      const fourthSegmentLength = length - firstSegmentLength - secondSegmentLength - thirdSegmentLength;
      const firstSegment = inputValue.slice(0, firstSegmentLength);
      inputValue.slice(firstSegmentLength, firstSegmentLength + secondSegmentLength).padStart(secondSegmentLength, '0');
      inputValue.slice(firstSegmentLength + secondSegmentLength, firstSegmentLength + secondSegmentLength + thirdSegmentLength).padStart(thirdSegmentLength, '0');
      const fourthSegment = inputValue.slice(firstSegmentLength + secondSegmentLength + thirdSegmentLength, firstSegmentLength + secondSegmentLength + thirdSegmentLength + fourthSegmentLength);

      masked = `${firstSegment} ${'*'.repeat(secondSegmentLength)} ${'*'.repeat(thirdSegmentLength)} ${fourthSegment}`;
    }
    setMaskedValue(masked);
  };

  //Sending values via callback to parent component
  const handleValuesChange = (originalValue, maskedValue, originalCardNumber, maskedCardNumber) => {
    props.setValuesCallback(originalValue, maskedValue, originalCardNumber, maskedCardNumber);
  };

  useEffect(() => {
    if (!isRetreived) {
      if (autorizacionDebito.medioCobro === 'CUENTA_CORRIENTE' || autorizacionDebito.medioCobro === 'CUENTA_AHORRO') {
        setIsRetreived(true);
        setOriginalValue(autorizacionDebito?.numeroCuenta);
        setMaskedValue(autorizacionDebito?.numeroCuentaMasked);
      } else {
        setOriginalCardNumber(autorizacionDebito?.numeroTarjeta);
        setMaskedCardNumber(autorizacionDebito?.numeroTarjetaMasked);
      }
    }
    handleValuesChange(originalValue, maskedValue, originalCardNumber, maskedCardNumber);
  }, [originalValue, maskedValue, originalCardNumber, maskedCardNumber]);

  useEffect(() => {
    async function getParentesco() {
      const respParentescosV2 = localStorage.getItem('parentescos')
      let respParentescosJson;
      if (respParentescosV2) {
        respParentescosJson = JSON.parse(respParentescosV2)?.data
      }
      const respParentescos = state?.parentescos?.data ? state.parentescos.data : respParentescosJson ? respParentescosJson : await getParentescoTitular();

      const data = [];
      respParentescos.map((item) => {
        data.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "parentesco",
        });
      });
      setLstParentescos(data);
    }
    getParentesco();
  }, []);

  const getCatalogs = async () => {
    if (bancos.length > 0 && tarjetas.length > 0) {
      return;
    }
    try {
      const respCardsV2 = localStorage.getItem('cards')
      let respCardsJson;
      if (respCardsV2) {
        respCardsJson = JSON.parse(respCardsV2)?.data
      }
      const respCards = state?.cards?.data ? state.cards.data : respCardsJson ? respCardsJson : await getCard();

      const respBanksV2 = localStorage.getItem('banks')
      let respBanksJson;
      if (respBanksV2) {
        respBanksJson = JSON.parse(respBanksV2)?.data
      }
      const respBanks = state?.banks?.data ? state.banks.data : respBanksJson ? respBanksJson : await getBanks();

      const tmpBancos = [];
      respBanks.map((item) => {
        tmpBancos.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "bancos",
        });
      });
      setBancos(tmpBancos);
      if (autorizacionDebito.bancoCuenta === '') {
        setAutorizacionDebito({ ...autorizacionDebito, bancoCuenta: tmpBancos[0].value, bancoCuentaStr: tmpBancos[0].label });
      }
      const tmpTarjetas = [];
      respCards.map((item) => {
        tmpTarjetas.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "tarjetas",
        });
      });
      setTarjetas(tmpTarjetas);
    } catch (error) {
      viewContext.handleError(error);
    }
  };

  useEffect(() => {
    getCatalogs();
  }, [bancos, tarjetas]);

  const IsOfacValid = async () => {

    const dataOfac = JSON.parse(localStorage.getItem("loginListasOfac"));
    let objUserOfac = {
      resource: "/rest/jwt/listasofacext",
      data: {
        tipoCliente: "N",
        tipoId: "00",
        cedula: autorizacionDebito.numeroIdentificacion,
        primerNombre: autorizacionDebito.primerNombre,
        segundoNombre: autorizacionDebito.segundoNombre,
        primerApellido: autorizacionDebito.primerApellido,
        segundoApellido: autorizacionDebito.segundoApellido,
        idUsuario: dataOfac.idUsuario,
        codigoPlataforma: dataOfac.codigoPlataforma,
        correoUsuario: dataOfac.correoUsuario,
      },
      httpVerb: "POST",
    };

    const resp = await lstOfac(objUserOfac);
    if (resp?.message?.data?.data?.Info === "OK") {
      return {
        code: true,
        message: `Validación exitosa`,
      };
    } else {
      return {
        code: false,
        message: resp?.message?.data?.data?.Info,
      };
    }

  };

  const verificarCedula = (ci) => {
    let isNumeric = true;
    let total = 0,
      individual;

    for (let position = 0; position < 10; position++) {
      // Obtiene cada posición del número de cédula
      // Se convierte a string en caso de que 'ci' sea un valor numérico
      individual = ci.toString().substring(position, position + 1)

      if (isNaN(individual)) {
        return { code: false, message: `No puede ingresar caracteres: ${ci}` };
      } else {
        // Si la posición es menor a 9
        if (position < 9) {
          // Si la posición es par, osea 0, 2, 4, 6, 8.
          if (position % 2 == 0) {
            // Si el número individual de la cédula es mayor a 5
            if (parseInt(individual) * 2 > 9) {
              // Se duplica el valor, se obtiene la parte decimal y se aumenta uno 
              // y se lo suma al total
              total += 1 + ((parseInt(individual) * 2) % 10);
            } else {
              // Si el número individual de la cédula es menor que 5 solo se lo duplica
              // y se lo suma al total
              total += parseInt(individual) * 2;
            }
            // Si la posición es impar (1, 3, 5, 7)
          } else {
            // Se suma el número individual de la cédula al total
            total += parseInt(individual);
          }
        }
      }
    }

    if ((total % 10) != 0) {
      total = (total - (total % 10) + 10) - total;
    } else {
      total = 0;
    }


    if (isNumeric) {
      // El total debe ser igual al último número de la cédula
      // La cédula debe contener al menos 10 dígitos
      if (ci.toString().length != 10) {
        return { code: false, message: `La c\u00E9dula debe ser de: 10 d\u00EDgitos: ${ci}` };
      }

      // El número de cédula no debe ser cero
      if (parseInt(ci, 10) == 0) {
        alert("La c\u00E9dula ingresada no puede ser cero.");
        return { code: false, message: `La c\u00E9dula ingresada no puede ser cero: ${ci}` };
      }

      // El total debe ser igual al último número de la cédula
      if (total != parseInt(individual)) {
        return { code: false, message: `La c\u00E9dula ingresada no es v\u00E1lida: ${ci}` };
      }

      return { code: true, message: `La c\u00E9dula ingresada es v\u00E1lida: ${ci}` };
    }

    // Si no es un número  
    return { code: true, message: `El dato solo puede contener numeros: ${ci}` };
  }

  function isDataValid() {
    if (autorizacionDebito.primerNombre === '') {
      viewContext.notification.show(
        `Debe ingresar el primer nombre`,
        "error"
      );
      return false;
    }
    // if (autorizacionDebito.segundoNombre === '') {
    //   viewContext.notification.show(
    //     `Debe ingresar el segundo nombre`,
    //     "error"
    //   );
    //   return false;
    // }
    if (autorizacionDebito.primerApellido === '') {
      viewContext.notification.show(
        `Debe ingresar el primer apellido`,
        "error"
      );
      return false;
    }
    if (autorizacionDebito.segundoApellido === '') {
      viewContext.notification.show(
        `Debe ingresar el segundo apellido`,
        "error"
      );
      return false;
    }

    if (autorizacionDebito.numeroIdentificacion === '') {
      viewContext.notification.show(
        `Debe ingresar el número de identificación`,
        "error"
      );
      return false;
    }
    const validCi = verificarCedula(autorizacionDebito.numeroIdentificacion);
    if (!validCi.code) {
      viewContext.notification.show(
        validCi.message,
        "error"
      );
      return false;
    }
    return true;
  }

  const validateOFAC = async () => {
    const dataValid = isDataValid();
    if (!dataValid) {
      return;
    }
    setIsLoading(true);
    const validOFAC = await IsOfacValid();
    setIsLoading(false);
    let tmpValidOfac = false;
    if (validOFAC.code) {
      tmpValidOfac = true;
      viewContext.notification.show(
        validOFAC.message,
        "success"
      );
      setSavedCedulaTerceraPersona(autorizacionDebito.numeroIdentificacion);
      setAutorizacionDebito({ ...autorizacionDebito, isValidOFAC: true });
    } else {
      console.error('falso: ', validOFAC.message);
      viewContext.notification.show(
        validOFAC.message,
        "error"
      );
      setAutorizacionDebito({ ...autorizacionDebito, isValidOFAC: false });
    }
  }

  return (
    <div>
      <Title whiteTitle='5. Formulario Autorización de Débito' className="" />
      <div className={Style.inputContainerDeclaracion}>
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerMedicaEnfermedadesLabel}>
            <h2 style={{ fontWeight: 700 }}>
              Elija quien es la persona que paga el seguro:
            </h2>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label htmlFor="AutorizacionDebito-radio-me" className={Style['bigger-radio-label']} style={{ width: '200px' }}>El/la asegurado/a</label>
              <input
                id="AutorizacionDebito-radio-me"
                required={true}
                type='radio'
                className={Style['bigger-radio']}
                value='Me'
                name='AutorizacionDebito-answer'
                checked={autorizacionDebito.answer === "Me"}
                onChange={(e) => {
                  setAutorizacionDebito({ ...autorizacionDebito, answer: e.target.value });
                }}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label htmlFor="AutorizacionDebito-radio-other" className={Style['bigger-radio-label']} style={{ width: '230px' }}>Una tercera persona</label>
              <input
                id="AutorizacionDebito-radio-other"
                required={true}
                type='radio'
                className={Style['bigger-radio']}
                value='Other'
                name='AutorizacionDebito-answer'
                checked={autorizacionDebito.answer === "Other"}
                onChange={(e) => {
                  setAutorizacionDebito({ ...autorizacionDebito, answer: e.target.value });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {autorizacionDebito.answer === "Other" && (
        <>
          <div className={Style.mainContainerForm}>
            <div className={Style.inputContainerQuarter}>
              <label>Primer nombre</label>
              <input
                value={autorizacionDebito.primerNombre}
                type='text'
                className={Style["input"]}
                onChange={(e) => {
                  const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
                  if (e.target.value === '' || alphanumericExtendedRegex.test(e.target.value)) {
                    setAutorizacionDebito({ ...autorizacionDebito, primerNombre: e.target.value.toUpperCase() });
                  }
                }}
              />
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>Segundo nombre</label>
              <input
                value={autorizacionDebito.segundoNombre}
                type='text'
                className={Style["input"]}
                onChange={(e) => {
                  const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
                  if (e.target.value === '' || alphanumericExtendedRegex.test(e.target.value)) {
                    setAutorizacionDebito({ ...autorizacionDebito, segundoNombre: e.target.value.toUpperCase() });
                  }
                }}
              />
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>Primer apellido</label>
              <input
                value={autorizacionDebito.primerApellido}
                type='text'
                className={Style["input"]}
                onChange={(e) => {
                  const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
                  if (e.target.value === '' || alphanumericExtendedRegex.test(e.target.value)) {
                    setAutorizacionDebito({ ...autorizacionDebito, primerApellido: e.target.value.toUpperCase() });
                  }
                }}
              />
            </div>
            <div className={Style.inputContainerQuarter}>
              <label>Segundo apellido</label>
              <input
                value={autorizacionDebito.segundoApellido}
                type='text'
                className={Style["input"]}
                onChange={(e) => {
                  const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
                  if (e.target.value === '' || alphanumericExtendedRegex.test(e.target.value)) {
                    setAutorizacionDebito({ ...autorizacionDebito, segundoApellido: e.target.value.toUpperCase() });
                  }
                }}
              />
            </div>
          </div>
          <div className={Style.mainContainerForm}>

            <div className={Style.inputContainerQuarter}>
              <label>Número de identificación</label>
              <input
                className={Style["input_number"]}
                value={autorizacionDebito.numeroIdentificacion}
                type='text'
                onChange={(e) => {
                  const autorizacionDebitoTmp = autorizacionDebito;
                  if (autorizacionDebito.isValidOFAC && savedCedulaTerceraPersona === autorizacionDebito.numeroIdentificacion) {
                    autorizacionDebitoTmp.primerNombre = '';
                    autorizacionDebitoTmp.segundoNombre = '';
                    autorizacionDebitoTmp.primerApellido = '';
                    autorizacionDebitoTmp.segundoApellido = '';
                  }
                  const inputText = e.target.value;
                  const numericInput = inputText.replace(/[^0-9]/g, '');
                  autorizacionDebitoTmp.numeroIdentificacion = numericInput;
                  setAutorizacionDebito({ ...autorizacionDebitoTmp });
                  // const inputText = e.target.value;
                  // const numericInput = inputText.replace(/[^0-9]/g, '');
                  // setAutorizacionDebito({ ...autorizacionDebito, numeroIdentificacion: numericInput });
                }}
                maxLength={10}
                pattern="[0-9]*" // Optional: HTML pattern attribute for browsers that support it
              //disabled={autorizacionDebito.isValidOFAC}
              />
            </div>

            <div className={Style.inputContainerQuarter}>
              <label>Parentesco</label>
              <select
                className={Style["select"]}
                value={autorizacionDebito.parentesco}
                onChange={(e) => {
                  const parentescoStr = lstParentescos.filter((item) => item.value === e.target.value)[0].label;
                  setAutorizacionDebito({ ...autorizacionDebito, parentesco: e.target.value, parentescoStr: parentescoStr });
                }}
              >
                {lstParentescos && lstParentescos.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className={Style.containerButtons}>
              <input
                type='submit'
                value='Validar cédula'
                className={Style.buttonFinalizar}
                onClick={() => validateOFAC()}
                style={{ marginTop: '8px' }}
              />
            </div>
          </div>
        </>
      )}

      <div className={Style.inputContainerMedicaEnfermedadesLabel} style={{ padding: '14px' }}>
        Seleccione el medio de cobro por el cual se debitará el seguro del cliente
      </div>

      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerEstiloVida}>
          <label className={Style['radio-button-label']}>
            <input
              type="radio"
              name="AutorizacionDebito-radio-medio-cobro"
              required={true}
              value='CUENTA_CORRIENTE'
              checked={autorizacionDebito.medioCobro === "CUENTA_CORRIENTE"}
              onChange={(e) => setAutorizacionDebito({ ...autorizacionDebito, medioCobro: e.target.value })}
            />
            <span>CUENTA CORRIENTE</span>
          </label>
        </div>
        <div className={Style.inputContainerEstiloVida}>
          <label className={Style['radio-button-label']}>
            <input
              type="radio"
              name="AutorizacionDebito-radio-medio-cobro"
              required={true}
              value='CUENTA_AHORRO'
              checked={autorizacionDebito.medioCobro === "CUENTA_AHORRO"}
              onChange={(e) => setAutorizacionDebito({ ...autorizacionDebito, medioCobro: e.target.value })}
            />
            <span>CUENTA AHORRO</span>
          </label>
        </div>
        <div className={Style.inputContainerEstiloVida}>
          <label className={Style['radio-button-label']}>
            <input
              type="radio"
              name="AutorizacionDebito-radio-medio-cobro"
              required={true}
              value='TARJETA_CREDITO'
              checked={autorizacionDebito.medioCobro === "TARJETA_CREDITO"}
              onChange={(e) => setAutorizacionDebito({ ...autorizacionDebito, medioCobro: e.target.value })}
            />
            <span>TARJETA DE CRÉDITO</span>
          </label>
        </div>
      </div>
      <div className={Style['horizontal-divider']}></div>
      {autorizacionDebito.medioCobro === "TARJETA_CREDITO" && (
        <>
          <div className={Style.mainContainerForm}>
            <div className={Style.inputContainer35}>
              <label>Tarjeta de Crédito</label>
              <select
                className={Style["select"]}
                required={true}
                value={autorizacionDebito.targetaCredito}
                onChange={(e) => {
                  const targetaCreditoStr = tarjetas.filter((item) => item.value === e.target.value)[0].label;
                  setAutorizacionDebito({ ...autorizacionDebito, targetaCredito: e.target.value, targetaCreditoStr: targetaCreditoStr });
                }}
              >
                <option key={''} value={''}>
                  {'Selecciona una opción'}
                </option>
                {tarjetas && tarjetas.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={Style.inputContainer35}>
              <label>Número de tarjeta</label>
              <input
                type="password"
                id="creditCardInput"
                value={originalCardNumber}
                onChange={handleInputChange}
                placeholder="XXXX XXXX XXXX XXXX"
                required={true}
                className={Style["input"]}
                maxLength={16}
                minLength={16}
              />
            </div>
            <div className={Style.labelAccNumber}>
              <p>{maskedCardNumber}</p>
            </div>
          </div>
          <div className={Style.mainContainerForm}>
            <div className={Style.inputContainer35}>
              <label>Banco emisor de tarjeta de Crédito</label>
              <select
                className={Style["select"]}
                value={autorizacionDebito.bancoTarjetaCredito}
                onChange={(e) => {
                  const bancoTarjetaCreditoStr = bancos.filter((item) => item.value === e.target.value)[0].label;
                  setAutorizacionDebito({ ...autorizacionDebito, bancoTarjetaCredito: e.target.value, bancoTarjetaCreditoStr: bancoTarjetaCreditoStr });
                }}
              >
                <option key={''} value={''}>
                  {'Selecciona una opción'}
                </option>
                {bancos && bancos.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={Style.inputContainer35}>
              <label>Fecha de vencimiento de tarjeta</label>
              {isFocusedFechaVencimiento ? (
                <InputMask
                  required={true}
                  mask="99/9999"
                  value={autorizacionDebito.fechaVencimientoTarjeta}
                  onChange={handleInputChangeFechaVencimiento}
                  onFocus={handleFocusFechaVencimiento}
                  onBlur={handleBlurFechaVencimiento}
                  placeholder="MM/YYYY"
                  className={Style["input"]}
                />
              ) : (
                <input
                  type="text"
                  value={autorizacionDebito.fechaVencimientoTarjeta !== '' ? '*'.repeat(autorizacionDebito.fechaVencimientoTarjeta.length) : ''}
                  onFocus={handleFocusFechaVencimiento}
                  readOnly
                  className={Style["input"]}
                />
              )}
            </div>
          </div>
        </>
      )}
      {(autorizacionDebito.medioCobro === "CUENTA_AHORRO" || autorizacionDebito.medioCobro === "CUENTA_CORRIENTE") && (
        <>
          <div className={Style.mainContainerForm}>
            <div className={Style.inputContainer35}>
              <label>Seleccione el Banco</label>
              <select
                className={Style["select"]}
                value={autorizacionDebito.bancoCuenta}
                selected={autorizacionDebito.bancoCuenta}
                onChange={(e) => {
                  const bancoCuentaStr = bancos.filter((item) => item.value === e.target.value)[0].label;
                  setAutorizacionDebito({ ...autorizacionDebito, bancoCuenta: e.target.value, bancoCuentaStr: bancoCuentaStr });
                }}
              >
                {bancos && bancos.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className={Style.inputContainer35}>
              <label>Número de cuenta</label>
              <input
                type="password"
                onChange={handleChangeAcc}
                value={originalValue}
                className={Style["input_number"]} />

            </div>
            <div className={Style.labelAccNumber}>
              <p>{maskedValue}</p>
            </div>
          </div>
        </>
      )}

      <div style={{ marginBottom: '20px' }}>
      </div>
    </div>
  );

}
