import { BackButton } from "components/backButton/button";
import {
  Submit,
  buscaEmisionSdp,
  getCandidateById,
  getCountry,
  getProvince
} from "components/bridges/BridgeServices";
import {
  Animate,
  Content,
  ViewContext,
  useAPI,
  useLocation,
} from "components/lib";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import GlobalStyle from "../../../../css/globalStyle.module.scss";
import Style from "../../agriculture/agricultureSeller.module.scss";
import { ActividadEconomica } from "./components/actividadEconomica";
import { AutorizacionDebito } from "./components/autorizacionDebito";
import { Beneficiarios } from "./components/beneficiarios";
import { DatosPersonales } from "./components/datosPersonales";
import { DeclaracionPersonaPolitica } from "./components/declaracionPersonaPolitica";
import { DeclaracionesModal } from "./components/declaracionesModal";
import { useDataContext } from "../../../../context/DataContext";

export function FormRequestVinculation(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();

  let url = useLocation();
  let vecUrl = url.pathname.split("/");
  let candidateId = vecUrl[vecUrl.length - 1];
  const candidate = useAPI(`/api/candidate/${candidateId}`, "get");
  const viewContext = useContext(ViewContext);
  const formsFilled = useAPI(`/api/formByFilter?candidate_id=${candidateId}&product_name=priori&type=priori_vinculacion`);
  const formId = useRef(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [acceptedDeclararion, setAcceptedDeclararion] = useState(false);
  const navigate = useNavigate();

  const [datosPersonales, setDatosPersonales] = useState(
    {
      primerNombre: '',
      segundoNombre: '',
      primerApellido: '',
      segundoApellido: '',
      tipoIdentificacion: '00',
      numeroIdentificacion: '',
      isValidOFAC: null,
      pais: '',
      pasiStr: '',
      provincia: '',
      provinciaStr: '',
      canton: '',
      cantonStr: '',
      residenciaFiscal: '',
      residenciaFiscalStr: '',
      direccionDomicilio: '',
      correoElectronico: '',
      telefono: '',
    }
  );

  const [pep, setPep] = useState({
    answer: "No",
    institution: '',
    position: '',
    positionCompletionDate: new Date(),
  });

  const [actividadEconomica, setActividadEconomica] = useState({
    actividadEconomica: '000000S01',
    actividadEconomicaStr: 'Jubilado',
    ingresos: 1,
    ingresosStr: '0.00 - 500.00',
    patrimonio: 9,
    patrimonioStr: '0.00 - 10,000.00',
  });

  const [autorizacionDebito, setAutorizacionDebito] = useState(
    {
      primerNombre: '',
      segundoNombre: '',
      primerApellido: '',
      segundoApellido: '',
      tipoIdentificacion: '00',
      numeroIdentificacion: '',
      isValidOFAC: false,
      answer: "Other",
      medioCobro: 'CUENTA_CORRIENTE',
      targetaCredito: '',
      targetaCreditoStr: '',
      numeroTarjeta: '',
      bancoTarjetaCredito: '',
      bancoTarjetaCreditoStr: '',
      bancoCuenta: '20106',
      bancoCuentaStr: 'BANCA INTESA SANPAOLO',
      numeroCuenta: '',
      fechaVencimientoTarjeta: '',
      parentesco: '01',
      parentescoStr: 'MADRE'
    }
  );

  const [declaracionesVisible, setDeclaracionesVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState({
    personeria: [],
    tipoId: [],
    genero: [],
    tipoPago: [],
    tipoPlan: [],
    planOptions: [],
    branchProduct: {},
    nacionalidades: [],
    provincias: [],
    ciudades: [],
    ciudadesActividad: [],
    cantones: [],
    estadoCivil: [],
    pais: [],
    actividadEconomica: [],
  });

  const [savedCedula, setSavedCedula] = useState('');
  const [savedCedulaTerceraPersona, setSavedCedulaTerceraPersona] = useState('');
  const [isEmited, setIsEmited] = useState(false);

  const getOptions = async () => {
    try {
      setIsLoading(true);
      let tempOptions = {
        ...options,
      };

      const respCountryV2 = localStorage.getItem('countries')
      let respCountryJson;
      if (respCountryV2) {
        respCountryJson = JSON.parse(respCountryV2)?.data
      }
      const respNacionality = state?.countries?.data ? state.countries.data :
        respCountryJson ? respCountryJson : await getCountry();

      const respProvincesV2 = localStorage.getItem('provinces')
      let respProvincesJson;
      if (respProvincesV2) {
        respProvincesJson = JSON.parse(respProvincesV2)?.data
      }
      const respProvinces = state?.provinces?.data ? state.provinces.data :
        respProvincesJson ? respProvincesJson : await getProvince();

      tempOptions.personeria.push({
        value: "",
        label: "Seleccionar",
      });

      tempOptions.tipoId.push({
        value: "",
        label: "Seleccionar",
      });

      tempOptions.genero.push({
        value: "",
        label: "Seleccionar",
      });

      tempOptions.tipoPago.push({
        value: "",
        label: "Seleccionar",
      });

      tempOptions.nacionalidades.push({
        value: "",
        label: "Seleccionar",
      });
      respNacionality.map((item) => {
        tempOptions.nacionalidades.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "nacionalidad",
        });
      });

      tempOptions.pais.push({
        value: "",
        label: "Seleccionar",
      });

      tempOptions.provincias.push({
        value: "",
        label: "Seleccionar",
      });
      respProvinces.map((item) => {
        tempOptions.provincias.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "provincia",
        });
      });

      tempOptions.actividadEconomica.push({
        value: "",
        label: "Seleccionar",
        type: "actividadEconomica",
      });

      setOptions(tempOptions);
      setIsLoading(false);
    } catch (error) {
      viewContext.handleError(error);
      setIsLoading(false);
    }
  };

  const verificarCedula = (ci) => {
    let isNumeric = true;
    let total = 0,
      individual;

    for (let position = 0; position < 10; position++) {
      // Obtiene cada posición del número de cédula
      // Se convierte a string en caso de que 'ci' sea un valor numérico
      individual = ci.toString().substring(position, position + 1)

      if (isNaN(individual)) {
        return { code: false, message: `No puede ingresar caracteres: ${ci}` };
      } else {
        // Si la posición es menor a 9
        if (position < 9) {
          // Si la posición es par, osea 0, 2, 4, 6, 8.
          if (position % 2 == 0) {
            // Si el número individual de la cédula es mayor a 5
            if (parseInt(individual) * 2 > 9) {
              // Se duplica el valor, se obtiene la parte decimal y se aumenta uno 
              // y se lo suma al total
              total += 1 + ((parseInt(individual) * 2) % 10);
            } else {
              // Si el número individual de la cédula es menor que 5 solo se lo duplica
              // y se lo suma al total
              total += parseInt(individual) * 2;
            }
            // Si la posición es impar (1, 3, 5, 7)
          } else {
            // Se suma el número individual de la cédula al total
            total += parseInt(individual);
          }
        }
      }
    }

    if ((total % 10) != 0) {
      total = (total - (total % 10) + 10) - total;
    } else {
      total = 0;
    }


    if (isNumeric) {
      // El total debe ser igual al último número de la cédula
      // La cédula debe contener al menos 10 dígitos
      if (ci.toString().length != 10) {
        return { code: false, message: `La c\u00E9dula debe ser de: 10 d\u00EDgitos: ${ci}` };
      }

      // El número de cédula no debe ser cero
      if (parseInt(ci, 10) == 0) {
        alert("La c\u00E9dula ingresada no puede ser cero.");
        return { code: false, message: `La c\u00E9dula ingresada no puede ser cero: ${ci}` };
      }

      // El total debe ser igual al último número de la cédula
      if (total != parseInt(individual)) {
        return { code: false, message: `La c\u00E9dula ingresada no es v\u00E1lida: ${ci}` };
      }

      return { code: true, message: `La c\u00E9dula ingresada es v\u00E1lida: ${ci}` };
    }

    // Si no es un número  
    return { code: true, message: `El dato solo puede contener numeros: ${ci}` };
  }

  function isAllFormDataValid() {
    console.table({ ...datosPersonales })
    //VALIDACION DATOS PERSONALES
    if (datosPersonales.primerNombre === '') {
      return { error: true, message: 'Debe ingresar el primer nombre' };
    }
    if (datosPersonales.primerApellido === '') {
      return { error: true, message: 'Debe ingresar el primer apellido' };
    }
    if (datosPersonales.segundoApellido === '') {
      return { error: true, message: 'Debe ingresar el segundo apellido' };
    }
    if (datosPersonales.numeroIdentificacion === '') {
      return { error: true, message: 'Debe ingresar el número de identificación' };
    }
    const validCi = verificarCedula(datosPersonales.numeroIdentificacion);
    if (!validCi.code) {
      return { error: true, message: validCi.message };
    }
    if (datosPersonales.pais === '') {
      return { error: true, message: 'Debe ingresar el país' };
    }
    if (datosPersonales.provincia === '') {
      return { error: true, message: 'Debe ingresar la provincia' };
    }
    if (datosPersonales.canton === '') {
      return { error: true, message: 'Debe ingresar el cantón' };
    }
    if (datosPersonales.residenciaFiscal === '') {
      return { error: true, message: 'Debe ingresar la residencia fiscal' };
    }
    if (datosPersonales.direccionDomicilio === '') {
      return { error: true, message: 'Debe ingresar la dirección de domicilio' };
    }
    if (datosPersonales.correoElectronico === '') {
      return { error: true, message: 'Debe ingresar el correo electrónico' };
    }
    if (datosPersonales.telefono === '') {
      return { error: true, message: 'Debe ingresar el teléfono' };
    }
    if (datosPersonales.isValidOFAC === false) {
      return { error: true, message: 'Cédula no válida' };
    }

    //VALIDACION PEP
    if (pep.answer === 'Si') {
      if (pep.institution === '') {
        return { error: true, message: 'Debe ingresar la institución' };
      }
      if (pep.position === '') {
        return { error: true, message: 'Debe ingresar el cargo' };
      }
      if (pep.positionCompletionDate === '') {
        return { error: true, message: 'Debe ingresar la fecha de finalización del cargo' };
      }
    }

    //VALIDACION ACTIVIDAD ECONOMICA
    if (actividadEconomica.actividadEconomica === '') {
      return { error: true, message: 'Debe ingresar la actividad económica' };
    }
    if (actividadEconomica.ingresos === '') {
      return { error: true, message: 'Debe ingresar los ingresos' };
    }
    if (actividadEconomica.patrimonio === '') {
      return { error: true, message: 'Debe ingresar el patrimonio' };
    }

    //VALIDACION AUTORIZACION DEBITO
    if (autorizacionDebito.answer === 'Other') {
      if (autorizacionDebito.primerNombre === '') {
        return { error: true, message: 'Debe ingresar el primer nombre del terecero' };
      }
      if (autorizacionDebito.primerApellido === '') {
        return { error: true, message: 'Debe ingresar el primer apellido del terecero' };
      }
      if (autorizacionDebito.segundoApellido === '') {
        return { error: true, message: 'Debe ingresar el segundo apellido del terecero' };
      }
      if (autorizacionDebito.tipoIdentificacion === '') {
        return { error: true, message: 'Debe ingresar el tipo de identificación del terecero' };
      }
      if (autorizacionDebito.numeroIdentificacion === '') {
        return { error: true, message: 'Debe ingresar el número de identificación del terecero' };
      }
      if (autorizacionDebito.isValidOFAC === false) {
        return { error: true, message: 'Cédula del tercero no válida' };
      }
    }

    if (autorizacionDebito.medioCobro === '') {
      return { error: true, message: 'Debe ingresar el medio de cobro' };
    }

    if (autorizacionDebito.medioCobro === 'CUENTA_CORRIENTE' || autorizacionDebito.medioCobro === 'CUENTA_AHORRO') {
      autorizacionDebito.numeroCuenta = returnedValues.originalValue;
      autorizacionDebito.numeroCuentaMasked = returnedValues.maskedValue;
      autorizacionDebito.numeroTarjeta = '';
      autorizacionDebito.bancoTarjetaCredito = '';
      autorizacionDebito.fechaVencimientoTarjeta = '';
      autorizacionDebito.targetaCredito = '';
      delete autorizacionDebito['numeroTarjetaMasked'];
      if (autorizacionDebito.bancoCuenta === '') {
        return { error: true, message: 'Debe ingresar el banco de la cuenta' };
      }
      if (autorizacionDebito.numeroCuenta === '') {
        return { error: true, message: 'Debe ingresar el número de cuenta' };
      }
    } else {
      autorizacionDebito.numeroTarjeta = returnedValues.originalCardNumber;
      autorizacionDebito.numeroTarjetaMasked = returnedValues.maskedCardNumber;
      autorizacionDebito.numeroCuenta = '';
      delete autorizacionDebito['numeroCuentaMasked'];
      if (autorizacionDebito.targetaCredito === '') {
        return { error: true, message: 'Debe ingresar el tipo de tarjeta de crédito' };
      }
      if (autorizacionDebito.numeroTarjeta === '') {
        return { error: true, message: 'Debe ingresar el número de la tarjeta de crédito' };
      }
      if (autorizacionDebito.bancoTarjetaCredito === '') {
        return { error: true, message: 'Debe ingresar el banco de la tarjeta de crédito' };
      }
      if (autorizacionDebito.fechaVencimientoTarjeta === '') {
        return { error: true, message: 'Debe ingresar la fecha de vencimiento de la tarjeta de crédito' };
      }
    }

    return { error: false, message: '' };
  }

  async function updateStatus() {
    const allFormDataValid = isAllFormDataValid();
    let estado = 'Candidato';
    if (!allFormDataValid.error) {
      estado = 'Pendiente de documentos';
    }
    let obj = {
      id: candidateId,
      estado: estado,
    };
    await Submit("candidate/updateStep", obj, "patch").then(
      (resp) => {
        // viewContext.notification.show("Formulario guardado", "success", true);
      }
    );
  }

  function isExpirationDateValid(expirationDate) {
    let month = parseInt(expirationDate.substring(0, 2), 10);
    let year = parseInt(expirationDate.substring(2), 10);
    month -= 1;
    let lastDayOfMonth = new Date(year, month + 1, 0);
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (lastDayOfMonth < currentDate) {
      return false;
    } else {
      return true;
    }
  }

  async function saveOnly(estado = "Candidato") {
    setIsLoading(true);

    const obj = {};
    obj.detail = {
      datosPersonales: datosPersonales,
      pep: pep,
      actividadEconomica: actividadEconomica,
      autorizacionDebito: autorizacionDebito,
      estado: estado,
      acceptedDeclararion: acceptedDeclararion,
    };

    const allFormDataValid = isAllFormDataValid();
    let pdf = true;
    if (allFormDataValid.error) {
      pdf = false;
    }

    if (formId.current === null || formId.current === undefined || formId.current === "") {
      obj.candidate_id = candidateId;
      obj.product_name = "priori";
      obj.type = "priori_vinculacion";
      obj.pdf = pdf;
      await Submit("form", obj, "post")
        .then(async (value) => {
          await updateStatus();
          viewContext.notification.show("Datos guardados exitosamente: ", "success", true);
          setIsLoading(false);
        })
        .catch((error) => {
          viewContext.handleError(error);
          setIsLoading(false);
        });
    } else {
      obj.id = formId.current;
      obj.pdf = pdf;
      await Submit("form", obj, "patch")
        .then(async (value) => {
          await updateStatus();
          viewContext.notification.show("Datos guardados exitosamente: ", "success", true);
          setIsLoading(false);
        })
        .catch((error) => {
          viewContext.handleError(error);
          setIsLoading(false);
        });
    }
  }

  async function saveAndUploadDocuments() {

    if (autorizacionDebito.medioCobro === 'TARJETA_CREDITO' && !isExpirationDateValid(autorizacionDebito.fechaVencimientoTarjeta)) {
      viewContext.notification.show("Fecha de vencimiento de tarjeta inválida", "error", true);
      return;
    }
    await saveOnly("Pendiente de documentos");

    const allFormDataValid = isAllFormDataValid();
    if (allFormDataValid.error) {
      viewContext.notification.show(allFormDataValid.message, "error", true);
      return;
    }
    await updateStatus();
    navigate(`/formCargaDocumentos/${candidateId}`);
  }

  const disableButton = () => {
    if (acceptedDeclararion === false) {
      return true;
    }

    if (datosPersonales.isValidOFAC && autorizacionDebito.answer === 'Me') {
      return false;
    }
    if (autorizacionDebito.isValidOFAC && autorizacionDebito.answer === 'Other') {
      return false;
    }

    return true;
  }

  useEffect(() => {
    async function getEmitStatus() {
      let curCandidate = await getCandidateById(candidateId);
      let idProveedor = curCandidate.data[0]?.emitesdp_request?.astr_id_proveedor;
      if (idProveedor === null || idProveedor === undefined || idProveedor === '') {
        return;
      }
      try {
        let responseBusca = await buscaEmisionSdp(idProveedor, candidateId);
        let response = JSON.parse(responseBusca?.data?.message);
        if (response.resultado === 'OK') {
          setIsEmited(true);
        }
      }
      catch (error) {
        setIsEmited(false);
      }

    }
    getEmitStatus();
  }, []);

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {

    async function tryGetFormData() {
      if (!formsFilled.loading && formsFilled.data) {
        formsFilled.data.map(async (item) => {
          formId.current = item.id;
          await fillFormWithSavedData(item.detail);
        });
      }
    }
    tryGetFormData();

  }, [formsFilled]);

  useEffect(() => {
    if (formId.current === null || formId.current === undefined) {
      fillFormWithDefauldData();
    }
  }, [candidate]);

  useEffect(() => {
    setIsButtonDisabled(disableButton());
  }, [datosPersonales, autorizacionDebito, acceptedDeclararion]);

  async function fillFormWithSavedData(detail) {
    if (detail.datosPersonales !== null && detail.datosPersonales !== undefined) {
      setDatosPersonales(detail.datosPersonales);
      setSavedCedula(detail.datosPersonales.numeroIdentificacion);
    }

    if (detail.pep !== null && detail.pep !== undefined) {
      setPep(detail.pep);
    }

    if (detail.actividadEconomica !== null && detail.actividadEconomica !== undefined) {
      setActividadEconomica(detail.actividadEconomica);
    }

    if (detail.autorizacionDebito !== null && detail.autorizacionDebito !== undefined) {
      setAutorizacionDebito(detail.autorizacionDebito);
      setSavedCedulaTerceraPersona(detail.autorizacionDebito.numeroIdentificacion);
    }

    if (detail.acceptedDeclararion !== null && detail.acceptedDeclararion !== undefined) {
      setAcceptedDeclararion(detail.acceptedDeclararion);
    } else {
      setAcceptedDeclararion(false);
    }
  }

  async function fillFormWithDefauldData() {
    const candidateDataArray = candidate.data;
    if (candidateDataArray === null || candidateDataArray === undefined) return;

    const candidateFormDetails = candidateDataArray.find((item) => {
      if (item.id === candidateId && item.product_type === "priori") {
        return item.detail;
      }
      return null;
    });
    if (candidateFormDetails === null || candidateFormDetails === undefined) return;
    setDatosPersonales({
      ...datosPersonales,
      primerNombre: candidateFormDetails.detail.nombre,
      primerApellido: candidateFormDetails.detail.apellido,
      correoElectronico: candidateFormDetails.detail.correo,
      telefono: candidateFormDetails.detail.telefono,
    });
  }

  const [returnedValues, setReturnedValues] = useState({
    originalValue: '',
    maskedValue: '',
  });

  // Función para recibir los valores devueltos desde AutorizacionDebito
  const handleReturnedValues = (originalValue, maskedValue, originalCardNumber, maskedCardNumber) => {
    setReturnedValues({
      originalValue,
      maskedValue,
      originalCardNumber,
      maskedCardNumber
    });
  };

  return (
    <Animate type='pop'>
      <div className={GlobalStyle.buttonPosition}>
        <BackButton go={-1} />
      </div>
      <section className={GlobalStyle.form}>
        <Content>
          <div style={{ padding: "15px 0px" }}>
            <p className={GlobalStyle.prioriTextTitle}>
              Formulario de Vinculación
            </p>
          </div>
          {!isLoading ? (
            <>
              <DatosPersonales
                options={options}
                datosPersonales={datosPersonales}
                setDatosPersonales={setDatosPersonales}
                setIsLoading={setIsLoading}
                savedCedula={savedCedula}
                setSavedCedula={setSavedCedula}
                setPep={setPep}
                setActividadEconomica={setActividadEconomica}
                setAutorizacionDebito={setAutorizacionDebito}
              />
              {datosPersonales.isValidOFAC && (
                <>
                  <DeclaracionPersonaPolitica
                    pep={pep}
                    setPep={setPep}
                  />
                  <ActividadEconomica
                    actividadEconomica={actividadEconomica}
                    setActividadEconomica={setActividadEconomica}
                  />
                  <Beneficiarios />
                  <AutorizacionDebito
                    autorizacionDebito={autorizacionDebito}
                    setAutorizacionDebito={setAutorizacionDebito}
                    setIsLoading={setIsLoading}
                    savedCedulaTerceraPersona={savedCedulaTerceraPersona}
                    setSavedCedulaTerceraPersona={setSavedCedulaTerceraPersona}
                    setValuesCallback={handleReturnedValues}
                  />
                  <div style={{ padding: '12px' }}>
                    <label>
                      <input
                        checked={acceptedDeclararion ? 'checked' : ''}
                        type="checkbox"
                        name="terminos"
                        style={{ marginRight: '10px' }}
                        onChange={() => setAcceptedDeclararion(!acceptedDeclararion)}
                      />

                      He comunicado las
                      <button
                        type="button"
                        onClick={() => setDeclaracionesVisible(true)}
                        style={{ textDecoration: 'underline', background: 'none', border: 'none', padding: 0, color: 'blue', cursor: 'pointer', paddingLeft: '10px' }} // Estilo para subrayar y hacer que parezca menos un botón
                      >
                        Declaraciones y autorizaciones de póliza
                      </button>
                    </label>

                    <DeclaracionesModal isOpen={declaracionesVisible} onClose={() => setDeclaracionesVisible(false)} />
                  </div>
                </>
              )}

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className={Style.containerButtons}>
                  <input
                    type='submit'
                    value='Guardar sin Finalizar'
                    className={!isEmited ? GlobalStyle.buttonFinalizar : GlobalStyle.buttonFinalizarDisabled}
                    onClick={() => saveOnly()}
                    style={{ marginTop: '8px' }}
                  />
                </div>
                <div className={Style.containerButtons} style={{ paddingLeft: '10px' }} >
                  <input
                    type='submit'
                    value='Guardar y Cargar Documentos'
                    className={isEmited || isButtonDisabled ? GlobalStyle.buttonFinalizarDisabled : GlobalStyle.buttonFinalizar}
                    onClick={() => saveAndUploadDocuments()}
                    style={{ marginTop: '8px' }}
                    disabled={isButtonDisabled}
                  />
                </div>
              </div>

            </>
          ) : (
            <div className={Style.ldsDualRing}></div>
          )}
        </Content>
      </section>
    </Animate>
  );

}
