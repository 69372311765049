/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import React, { useContext, useState, useEffect } from "react";
import {
  Animate,
  AuthContext,
  ViewContext,
  Form,
  Card,
  Row,
  useLocation,
  useNavigate,
} from "components/lib";
import Style from "./signin.module.scss";

export function Signin(props) {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();

  // state
  const [form, setForm] = useState({
    email: {
      label: "Usuario (correo electrónico)",
      type: "email",
      required: true,
      placeholder: "Ingresa tu usuario",
    },
    password: {
      label: "Contraseña",
      type: "password",
      required: true,
      placeholder: "Ingresa tu contraseña",
      complexPassword: true,
    },
    forgotpassword: {
      type: "link",
      url: "/forgotpassword",
      text: "Olvidé mi contraseña",
    },
  });

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes("error")) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf("?error=") + 7));
      viewContext.notification.show(msg, "error");
    }
  }, [location.search, viewContext.notification]);

  return (
    <Animate type='pop'>
      <Row>
        <Card restrictWidth center>
          <h2 className={Style.title}>Iniciar de Sesión</h2>
          <Form
            data={form}
            method='POST'
            url={"/api/auth"}
            buttonText={"Ingresar"}
            callback={(res) => {
              res.data['2fa_required'] ? navigate(`/signin/otp?token=${res.data.token}`) : navigate(authContext.signin(res));
            }}
          />
        </Card>
      </Row>
    </Animate>
  );
}
