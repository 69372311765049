import Style from "../../../../../css/globalStyle.module.scss";

export function DeclaracionesModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className={Style['modal']}>
      <div className={Style["modal-content"]}>
        <span className={Style["close"]} onClick={onClose}>&times;</span>
        <div style={{ padding: "15px 0px" }}>
          <p className={Style.prioriTextTitle}>
            DECLARACIONES
          </p>
        </div>
        <div style={{ padding: "15px 0px" }}>
          <ol type="1">
            <li className={Style.liTextSize}>Declaro que la información proporcionada en la presente solicitud es verdadera, completa y actualizada y que constituye la base sobre la que solicito el seguro. Cualquier omisión, inexactitud u ocultamiento en las mismas, eximirá a Seguros del Pichincha de toda obligación de indemnización.</li>
            <hr className={Style.divider} />
            <li className={Style.liTextSize}>Declaro conocer y aceptar que es mi obligación actualizar anualmente mis datos personales, comunicar y documentar de manera inmediata a Seguros del Pichincha cualquier cambio en la información o documentación que he proporcionado.</li>
            <hr className={Style.divider} />
            <li className={Style.liTextSize}>Durante la vigencia de la relación contractual con Seguros del Pichincha, me comprometo a proveer la información y la documentación que me sea solicitada.</li>
            <hr className={Style.divider} />
            <li className={Style.liTextSize}>Declaro que no se podrá suscribir pólizas al portador, con nombres incompletos, ficticios, bajo cualquier otra modalidad que encubra la identidad del solicitante, asegurado, contratante o beneficiarios de la póliza de seguro.</li>
            <hr className={Style.divider} />
            <li className={Style.liTextSize}>Declaro que el dinero utilizado para realizar las transacciones con Seguros del Pichincha no proviene de ninguna actividad relacionada con el lavado de activos, el financiamiento del terrorismo, el financiamiento de otros delitos, ni es producto de algún delito. Así mismo declaro que realizo y realizaré operaciones lícitas con Seguros del Pichincha desde el inicio de la relación comercial hasta la finalización de esta.</li>
          </ol>
        </div>

        <div style={{ padding: "15px 0px" }}>
          <p className={Style.prioriTextTitle}>
            AUTORIZACIONES
          </p>
        </div>
        <ol type="1">
          <li className={Style.liTextSize}>Autorizo a Seguros del Pichincha a realizar el análisis y verificaciones que considere pertinente, autorizo a todas las personas naturales, jurídicas de derecho público o privado a facilitar a Seguros del Pichincha toda información que ésta requiera, así como solicitar documentación adicional para identificar plenamente al cliente, en cumplimiento de la política conozca a su cliente, requerida por los entes de control.</li>
          <hr className={Style.divider} />
          <li className={Style.liTextSize}>Ningún historial médico existente sustituye mi obligación de contestar con veracidad las preguntas sobre mi historial de médico/estado de salud, en la presente solicitud. La información que se detalla en este documento es importante y necesario, por cuanto constituye fuente de selección del riesgo. La falta de veracidad u omisión liberará a Seguros del Pichincha del cumplimiento de sus obligaciones. Seguros del Pichincha considerará la presente solicitud como elemento sustancial para la evaluación y posterior emisión de la póliza. El solicitante tiene el deber absoluto de proveer la información veraz, sin importar cualquier declaración a lo contrario hecha por el agente. La falta de contestar verídica cualquier pregunta y/o omisión de la información en la solicitud del seguro podrá acarrear su anulación.</li>
          <hr className={Style.divider} />
          <li className={Style.liTextSize}>Autorizo a cualquier prestador o proveedor de servicios no médicos, como, por ejemplo, establecimientos educativos, deportivos, o cualquier otra entidad pública o privada portadora o tenedora de información que no sea médica sobre mi persona para que suministre a Seguros del Pichincha toda la información que le sea solicitada.</li>
          <hr className={Style.divider} />
          <li className={Style.liTextSize}>Autorizo a Seguros del Pichincha, a obtener toda la información que considere necesaria, de cualquier médico, prestador de salud, aseguradoras, empresas de medicina prepagada nacional o internacional, información sobre exámenes, tratamientos, diagnóstico, antecedentes médicos, etc. pero no limitada a exámenes o tratamientos médicos realizados, o que en el futuro se me practiquen mientras se encuentran vigente la póliza. Cualquier acción por parte del asegurado o sus médicos que busquen obstaculizar la liberación de la información solicitada, liberará a Seguros del Pichincha toda responsabilidad.</li>
          <hr className={Style.divider} />
          <li className={Style.liTextSize}>Autorizo de manera libre, expresa e informada a Seguros del Pichincha, a utilizar, tratar, transferir y almacenar mi información personal proporcionada o cualquier información que sea de conocimiento público conforme a la ley, con el objetivo de contactarme, por medio de llamada telefónica, mensaje de texto vía telefónica, correo electrónico, correo directo u otro medio a fin de: formalizar e instrumentar el contrato de seguro y la relación comercial; así como, con fines publicitarios, comerciales de nuevos productos de la aseguradora; estadísticos y para efectuar estudios de perfil del consumidor. Así como autorizo expresamente a Seguros del Pichincha a transferir los datos a terceros proveedores con las finalidades antes indicadas. Esta autorización de tratamiento y conservación de datos personales se mantendrá vigente hasta el fin de la relacional contractual entre las partes y, posterior a ello por cinco (5) años más.</li>
          <br />
          <p className={Style.liTextSize}>Puede ejercer sus derechos contemplados en la normativa, a través de una solicitud dirigida a <a href="mailto:gestiondeinformacion@seg-pichincha.com" className={Style.emailLink}>
            gestiondeinformacion@seg-pichincha.com
          </a>  o en cualquiera de los establecimientos físicos de Seguros del Pichincha SA.</p>
          <hr className={Style.divider} />
          <li className={Style.liTextSize}>En caso de que mi solicitud de contratación de seguro sea aprobada, conozco que Seguros del Pichincha emitirá la póliza en el que se detallan coberturas, condiciones y demás; y autorizo que toda la información vinculada con el producto sea enviada al correo electrónico señalado en el presente documento.</li>
          <hr className={Style.divider} />
          <li className={Style.liTextSize}>Adicionalmente reconozco que los mensajes de datos tienen igual valor jurídico que los documentos escritos y su eficacia y valoración se somete a la Ley de Comercio Electrónico, Firmas y Mensajes De Datos.</li>
        </ol>
      </div>
    </div>
  );
}

