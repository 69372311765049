import React, { useEffect, useState } from "react";
import { getCitiesLocal, getProvince } from "components/bridges/BridgeServices";
import { Title } from "./title";
import { useDataContext } from "../../../../../context/DataContext";
import Style from "../../../../../css/globalStyle.module.scss";

export function ActividadEconomica(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  const { register, options, watch, errors, optionsCiudadesActividades, setValue, prevData } = props;
  const paisDomicilioActividadEconomica = watch("paisDomicilioActividadEconomica");
  const provinciaSeleccionada = watch("provinciaActividadEconomica");
  const actividadIndependienteNegocio = watch("actividadIndependienteNegocio");
  const cantonSeleccionado = watch("cantonActividadEconomica");

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);
    return isValid;
  };

  const [showCantones, setShowCantones] = useState(false);
  useEffect(() => {
    if (provinciaSeleccionada !== undefined && provinciaSeleccionada !== "" && provinciaSeleccionada !== null && provinciaSeleccionada !== "-Seleccionar") {
      setShowCantones(true);
    } else {
      setShowCantones(false);
    }
  }, [provinciaSeleccionada]);

  const [selectedCanton, setSelectedCanton] = useState('');
  useEffect(() => {
    // Check if initialCanton matches any option
    const matchingOption = optionsCiudadesActividades?.find(option => option.label === cantonSeleccionado);
    if (matchingOption) {
      setSelectedCanton(cantonSeleccionado);
      setValue('cantonActividadEconomica', cantonSeleccionado); // Set initial value in react-hook-form
    }
  }, [cantonSeleccionado, optionsCiudadesActividades, setValue]);

  useEffect(() => {
    if ((options.actividadEconomica && options.actividadEconomica.length > 0) &&
      (actividadIndependienteNegocio === undefined || actividadIndependienteNegocio === "" || actividadIndependienteNegocio === null)) {
      setValue('actividadIndependienteNegocio', options.actividadEconomica[0].label);
    }
  }, [actividadIndependienteNegocio]);

  const handleCantonChange = (event) => {
    const newValue = event.target.value;
    setSelectedCanton(newValue);
    setValue('cantonActividadEconomica', newValue);
  };

  ////////////////////////////////////
  const [selectedPaisDomicilioAE, setSelectedPaisDomicilioAE] = useState('');
  const [selectedProvinciaActividadEconomica, setSelectedProvinciaActividadEconomica] = useState('');
  useEffect(() => {
    if (prevData?.provinciaActividadEconomica !== undefined && prevData?.provinciaActividadEconomica !== "" && prevData?.provinciaActividadEconomica !== null) {
      setSelectedProvinciaActividadEconomica(prevData?.provinciaActividadEconomica);
    }
  }, [prevData?.provinciaActividadEconomica])

  const handlePaisDomicilioChangeAE = (event) => {
    const newValue = event.target.value;
    setSelectedPaisDomicilioAE(newValue);
    setValue('paisDomicilioActividadEconomica', newValue);
    if (newValue === 'ECUADOR') {
      setValue('provinciaActividadEconomica', options.provincias[0].label);
    } else {
      setValue('provinciaActividadEconomica', '');
    }
  };

  useEffect(() => {
    if (options?.pais?.length > 0) {
      if (prevData?.paisDomicilioActividadEconomica === '') {
        setValue('paisDomicilioActividadEconomica', options.pais[0].label);
      } else {
        setSelectedPaisDomicilioAE(prevData.paisDomicilioActividadEconomica);
      }
    }
  }, [prevData]);

  const handleProvinciaActividadEconomicaChange = async (event) => {
    const newValue = event.target.value;
    setSelectedProvinciaActividadEconomica(newValue);
    setValue('provinciaActividadEconomica', newValue);

    // Get Provinces Data
    const respProvincesV2 = localStorage.getItem('provinces')
    let respProvincesJson;
    if (respProvincesV2) {
      respProvincesJson = JSON.parse(respProvincesV2)?.data
    }
    const respProvinces = state?.provinces?.data ? state.provinces.data : respProvincesJson ? respProvincesJson : await getProvince();
    // Find the selected value of pronvince
    const foundedProv = respProvinces.find(ele => ele.astr_descripcion === newValue);
    // Get Ciudades Data
    const respCiudadesV2 = localStorage.getItem('ciudades')
    let respCiudadesJson;
    if (respCiudadesV2) {
      respCiudadesJson = JSON.parse(respCiudadesV2)?.data
    }
    const respCiudades = state?.ciudades ? state.ciudades : respCiudadesJson ? respCiudadesJson : await getCitiesLocal();
    let tmpResp = getValueByKey(respCiudades?.data ? respCiudades.data : respCiudades, foundedProv?.astr_id);
    console.log('tmpResp:', tmpResp)
    setValue('cantonActividadEconomica', tmpResp.length > 0 ? tmpResp[0]?.astr_descripcion : '');
  };

  function getValueByKey(obj, key) {
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    } else {
      return undefined;
    }
  }
  ////////////////////////////////////

  return (
    <div>
      <Title whiteTitle='4. Sección actividad económica principal del propuesto asegurado' />
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerfifth}>
          <label style={{ marginBottom: "auto" }}>
            <b style={{ color: 'red' }}>*</b>&nbsp;Actividad principal
          </label>
          <select
            {...register("actividadPrincipal", { required: true })}
            className={Style["select"]}
          >
            <option value='Empleado privado'>Empleado privado</option>
            <option value='Empleado público'> Empleado público</option>
            <option value='Jubilado'> Jubilado</option>
            <option value='Estudiante'> Estudiante</option>
            <option value='Quehaceres domésticos'>{" "}Quehaceres domésticos</option>
          </select>
          {errors.actividadPrincipal?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerfifth}>
          <label><b style={{ color: 'red' }}>*</b>&nbsp;Nombre de la empresa</label>
          <input
            tabIndex={30}
            type='text'
            {...register("nombreEmpresa", { required: true, maxLength: 255 })}
            className={Style["input"]}
          />
          {errors.nombreEmpresa?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerfifth}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;Actividad de la empresa
          </label>
          <input
            tabIndex={31}
            type='text'
            {...register("actividadEmpresa", {
              required: true,
              maxLength: 254,
            })}
            className={Style["input"]}
          />
          {errors.actividadEmpresa?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerfifth}>
          <label style={{ marginBottom: "auto" }}>
            <b style={{ color: 'red' }}>*</b>&nbsp;Cargo
          </label>
          <input
            tabIndex={32}
            type='text'
            {...register("cargo", { required: true, maxLength: 255 })}
            className={Style["input"]}
          />
          {errors.cargo?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerfifth}>
          <label style={{ marginBottom: "auto" }}>
            <b style={{ color: 'red' }}>*</b>&nbsp;Años en el empleo
          </label>
          <select
            {...register("aniosEmpleo", { required: true })}
            className={Style["select"]}
          >
            <option value='Menos de un año'>Menos de un año</option>
            <option value=' 1 a 3 años'> 1 a 3 años</option>
            <option value=' 4 a 5 años'> 4 a 5 años</option>
            <option value=' 6 o más años'> 6 o más años</option>
          </select>
          {errors.aniosEmpleo?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
      </div>
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerHalf}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;Actividad independiente que realiza y/o giro de su negocio
          </label>
          <select
            {...register("actividadIndependienteNegocio", { required: true })}
            className={Style["select"]}
          >
            {options?.actividadEconomica?.map((item) => {
              return (
                <option
                  key={`actividadIndependienteNegocio${item.value}`}
                  value={item.label}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
          {errors.actividadIndependienteNegocio?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerHalf}>
          <label style={{ marginBottom: "auto" }}>
            <b style={{ color: 'red' }}>*</b>&nbsp;Dirección actividad económica
          </label>
          <input
            tabIndex={33}
            type='text'
            {...register("direccionActividadEconomica", {
              required: true,
              maxLength: 255,
            })}
            className={Style["input"]}
          />
          {errors.direccionActividadEconomica?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
      </div>
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerQuarter}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;País de domicilio
          </label>
          <select
            className={Style["select"]}
            {...register("paisDomicilioActividadEconomica", { required: true })}
            value={selectedPaisDomicilioAE}
            onChange={handlePaisDomicilioChangeAE}
          >
            {options?.pais?.map((item) => {
              return (
                <option
                  key={`paisDomicilioActividadEconomica${item.value}`}
                  value={item.label}
                >
                  {item.label}
                </option>
              );
            })}
          </select>
          {errors.paisDomicilioActividadEconomica?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        {paisDomicilioActividadEconomica === "ECUADOR" && (
          <div className={Style.inputContainerQuarter}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Provincia
            </label>
            <select
              className={Style["select"]}
              {...register("provinciaActividadEconomica", { required: true })}
              value={selectedProvinciaActividadEconomica}
              onChange={handleProvinciaActividadEconomicaChange}
            >
              {options?.provincias?.map(item => (
                <option key={`provinciaActividadEconomica_${item.value}`} value={item.label}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {paisDomicilioActividadEconomica !== "ECUADOR" && (
          <div className={Style.inputContainerQuarter}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Ciudad
            </label>
            <input
              tabIndex={34}
              {...register("ciudadActividadEconomica", {
                required: true,
                maxLength: 255,
              })}
              className={Style["input"]}
              type='text'
            />
            {errors.ciudadActividadEconomica?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        )}
        {paisDomicilioActividadEconomica === "ECUADOR" && showCantones && (
          <div className={Style.inputContainerQuarter}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Cantón
            </label>
            <select
              className={Style["select"]}
              {...register("cantonActividadEconomica", { required: true })}
              value={selectedCanton}
              onChange={handleCantonChange}
            >
              {optionsCiudadesActividades?.map((item) => {
                return (
                  <option key={item.value} value={item.label}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>
        )}
      </div>
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerQuarter}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;Correo electrónico laboral
          </label>
          <input
            tabIndex={35}
            {...register("correoElectronicoLaboral", {
              required: true,
              validate: handleEmailValidation,
            })}
            type='email'
            className={Style["input"]}
          />
          {errors.correoElectronicoLaboral?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerQuarter}>
          <label>
            <b style={{ color: 'red' }}>*</b>&nbsp;N° Teléfono</label>
          <input
            tabIndex={36}
            {...register("telefonoLaboralActividadEconomica", {
              required: true,
            })}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            type='text'
            maxLength={"10"}
            className={Style["input_number"]}
          />
          {errors.telefonoLaboralActividadEconomica?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
      </div>
    </div>
  );
}
