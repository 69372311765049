/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, { useContext, useEffect, useState } from "react";
import {
  Animate,
  Content,
  TitleProduct,
  Catalogue,
  Cultivation,
  ConfigBranch,
  Button,
  ViewContext,
  useAPI,
  useNavigate,
} from "components/lib";
import {
  getBranch,
  getBranchXProduct,
  Submit,
} from "components/bridges/BridgeServices";
import { BackButton } from "components/backButton/button";
import Style from "../../../css/globalStyle.module.scss";

export function ConfigAgriculture(props) {
  const viewContext = useContext(ViewContext);
  const dataCatalog = useAPI("/api/catalog/agro");
  const [id, setId] = useState({});
  const navigate = useNavigate();

  const [agriculture, setAgriculture] = useState({
    catalogue: {
      typeIrrigation: [],
      typeSeed: [],
      parish: [
        {
          codigoProvincia: "1",
          codigoCanton: "1",
          codigoParroquia: "50",
          nombreParroquia: "CUENCA",
        },
      ],
    },
    typeCultivation: [],
    cultivations: [],
    configRamo: { contribuciones: [], dias_vigencia_cotizacion: "" },
    cultivationSelected: "Arroz",
  });
  const [servicesData, setServicesData] = useState();

  const getGeneralData = async () => {
    try {
      let tempData = {};
      let obj = {
        ramo: "40",
        ahorro: "N",
      };
      const responseBranchXProduct = await getBranchXProduct(obj);
      tempData.branchProduct = responseBranchXProduct;
      obj = {
        ramo: "40",
      };
      const responseBranch = await getBranch(obj);
      tempData.branch = responseBranch;
      setServicesData(tempData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveProduct = async () => {
    let objRiego = {
      id: id.tipo_riego,
      detail: { detail: agriculture.catalogue.typeIrrigation },
    };

    let objSemilla = {
      id: id.tipo_semilla,
      detail: { detail: agriculture.catalogue.typeSeed },
    };

    let objCultivos = {
      id: id.cultivos,
      detail: { detail: agriculture.cultivations },
    };

    let objParametros = {
      id: id.parametros_ramo,
      detail: {
        detail: [
          {
            ramo: "AGROPECUARIO",
            codigo_sip: "40",
            contribuciones: {
              seguro_campesino: agriculture.configRamo.contribuciones[0].active,
              supercias: agriculture.configRamo.contribuciones[1].active,
            },
            dias_vigencia_cotizacion:
              agriculture.configRamo.dias_vigencia_cotizacion,
          },
        ],
      },
    };

    try {
      await Submit("catalog", objRiego, "patch")
        .then((value) => {
          viewContext.notification.show(value.data.message, "success", true);
        })
        .catch((error) => {
          viewContext.handleError(error);
        })
        .finally(() => {
          viewContext.handleError(
            "La Promesa está finalizada, lo que significa que ha sido resuelta o rechazada."
          );
        });

      await Submit("catalog", objSemilla, "patch")
        .then((value) => {
          viewContext.notification.show(value.data.message, "success", true);
        })
        .catch((error) => {
          viewContext.handleError(error);
        })
        .finally(() => {
          viewContext.handleError(
            "La Promesa está finalizada, lo que significa que ha sido resuelta o rechazada."
          );
        });

      await Submit("catalog", objCultivos, "patch")
        .then((value) => {
          viewContext.notification.show(value.data.message, "success", true);
        })
        .catch((error) => {
          viewContext.handleError(error);
        })
        .finally(() => {
          viewContext.handleError(
            "La Promesa está finalizada, lo que significa que ha sido resuelta o rechazada."
          );
        });

      await Submit("catalog", objParametros, "patch")
        .then((value) => {
          viewContext.notification.show(value.data.message, "success", true);
          navigate("/producto");
        })
        .catch((error) => {
          viewContext.handleError(error);
        })
        .finally(() => {
          viewContext.handleError(
            "La Promesa está finalizada, lo que significa que ha sido resuelta o rechazada."
          );
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fillCatalog = () => {
    if (dataCatalog.data && servicesData) {
      let tempBranchProduct = servicesData.branch[0].contribuciones;
      tempBranchProduct.map((item) => {
        item.active = false;
      });

      let tempCatalog = { ...agriculture };
      let tempId = {};
      dataCatalog.data.map((catalog) => {
        if (catalog.name === "tipo_riego") {
          tempCatalog.catalogue.typeIrrigation = catalog.detail.detail;
          tempId.tipo_riego = catalog.id;
        }
        if (catalog.name === "tipo_semilla") {
          tempCatalog.catalogue.typeSeed = catalog.detail.detail;
          tempId.tipo_semilla = catalog.id;
        }
        if (catalog.name === "parroquia") {
        }
        if (catalog.name === "tipo_cultivo") {
          tempCatalog.typeCultivation = catalog.detail.detail;
          tempId.tipo_cultivo = catalog.id;
        }
        if (catalog.name === "cultivos") {
          tempCatalog.cultivations = catalog.detail.detail;
          tempId.cultivos = catalog.id;
        }
        if (catalog.name === "parametros_ramo") {
          tempId.parametros_ramo = catalog.id;
          let tempBranchProduct = servicesData.branch[0].contribuciones;
          tempBranchProduct.map((item) => {
            item.active = false;
          });
          tempBranchProduct.map((item) => {
            if (item.astr_descripcion === "Seguro Campesino") {
              item.active =
                catalog.detail.detail[0].contribuciones.seguro_campesino;
            }
            if (
              item.astr_descripcion === "Super de compañias valores y seguros"
            ) {
              item.active = catalog.detail.detail[0].contribuciones.supercias;
            }
          });
          tempCatalog.configRamo.contribuciones = tempBranchProduct;
          tempCatalog.configRamo.dias_vigencia_cotizacion =
            catalog.detail.detail[0].dias_vigencia_cotizacion;
        }
      });

      setAgriculture(tempCatalog);
      setId(tempId);
    }
  };

  useEffect(() => {
    fillCatalog();
  }, [dataCatalog, servicesData]);

  useEffect(() => {
    getGeneralData();
  }, []);

  return (
    <Animate type='pop'>
      <div className={Style.buttonPosition}>
        <BackButton go={-1} />
      </div>
      <section className={Style.paddingLeft}>
        <Content>
          <div style={{ padding: "15px 0px" }}>
            <p className={Style.textTitle}>Configuración agrícola</p>
          </div>
          <section>
            <TitleProduct blackTitle='Catálogos'>
              <Catalogue
                agriculture={agriculture}
                setAgriculture={setAgriculture}
              />
            </TitleProduct>
          </section>
          <section>
            <TitleProduct blackTitle='Cultivos'>
              <Cultivation
                agriculture={agriculture}
                setAgriculture={setAgriculture}
              />
            </TitleProduct>
          </section>
          <section>
            <TitleProduct blackTitle='Parámetro del ramo'>
              <ConfigBranch
                agriculture={agriculture}
                setAgriculture={setAgriculture}
              />
            </TitleProduct>
          </section>
          <section>
            <div style={{ padding: "15px 0px", display: "flex" }}>
              <Button
                color='blue'
                text={"Guardar"}
                action={handleSaveProduct}
              />
            </div>
          </section>
        </Content>
      </section>
    </Animate>
  );
}
