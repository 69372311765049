/***
 *
 *   ACCOUNT
 *   Index page for account functions
 *
 **********/
import { useEffect } from "react";
import {
  Animate,
  Card,
  useAPI,
  Loader,
  Button,
} from "components/lib";
import Style from "./salesForce.module.scss";
import ProfileIcon from "../../assets/icons/perfil-de-usuario.svg";
import PasswordIcon from "../../assets/icons/password.svg";

export function Account(props) {
  const user = useAPI("/api/user");

  if (user.loading) return <Loader />;

  return (
    <Animate>
      <div className={Style.section}>
        <p className={Style.textTitle}>Tu cuenta</p>
        <div className={Style.mainContainer}>
          <div className={Style.leftCard}>
            <Card className={Style.cardContainerLeft}>
              <div className={Style.subcontainerLeft}>
                <div>
                  <img alt='logo perfil' src={ProfileIcon} />
                  <h2 className={Style.subtitle}>Perfil</h2>
                  <hr className={Style.divider} />
                </div>
                <div>
                  <div>Consulta la información de tu perfil</div>
                </div>
                <div>
                  <Button
                    fullWidth
                    color='blue'
                    text={`Consultar`}
                    goto='/account/profile'
                  />
                </div>
              </div>
            </Card>
          </div>
          <div className={Style.leftCard}>
            <Card className={Style.cardContainerLeft}>
              <div className={Style.subcontainerLeft}>
                <div>
                  <img alt='logo perfil' src={PasswordIcon} />
                  <h2 className={Style.subtitle}>Contraseña</h2>
                  <hr className={Style.divider} />
                </div>
                <div>
                  <div>
                    {user?.data?.["has_password"]
                      ? "Cambia tu contraseña frecuentemente"
                      : "Crea una contraseña segura"}
                  </div>
                </div>
                <div>
                  <Button
                    sizeBtn={"100%"}
                    color='blue'
                    text={user?.data?.["has_password"] ? "Actualizar" : "Crear"}
                    goto='/account/password'
                  />
                </div>
              </div>
            </Card>
          </div>
          {/*2Fa de Autenticacion*/}
          {/*<div className={Style.leftCard}>
            <Card className={Style.cardContainerLeft}>
              <div className={Style.subcontainerLeft}>
                <div>
                  <img alt='logo 2fa' src={AuthenticationIcon} />
                  <h2 className={Style.subtitle}>2do-Factor de Autenticación</h2>
                  <hr className={Style.divider} />
                </div>
                <div>
                  <div>Asegura tu cuenta</div>
                </div>
                <div >
                  <Button
                    sizeBtn={"100%"}
                    color='blue'
                    text={user?.data?.["2fa_enabled"] ? "Administrar" : "Habilitar"}
                    goto='/account/2fa'
                  />
                </div>
              </div>
            </Card>
          </div>*/}

        </div>

      </div>
    </Animate>
  );
}
