import { RolDetails } from "views/profile/rolDetails";
import { Dashboard } from "views/dashboard/dashboard";
import { Help } from "views/dashboard/help";
import { OnboardingView } from "views/onboarding/onboarding";
import { Menu } from "views/menu/menu";
import { Product } from "views/product/product";
import { ListProducts } from "views/product/listProducts/listProducts";
import { Life } from "views/product/life/life";
import { Agriculture } from "views/product/agriculture/agriculture";
import { ConfigAgriculture } from "views/product/agriculture/configAgriculture";
import { Seller } from "views/seller/seller";
import { LifeSeller } from "views/seller/life/lifeSeller";
import { CandidateLife } from "views/seller/life/candidate";
import { PriceLife } from "views/seller/life/price";
import { PriceClientLife } from "views/seller/life/priceClientLife";
import { FormRequestInsurance } from "views/seller/life/forms/formRequestInsurance";
import { FormDebit } from "views/seller/life/forms/formDebit";
import { CandidateAgriculture } from "views/seller/agriculture/candidate";
import { PriceAgriculture } from "views/seller/agriculture/price";
import { AgroManagement } from "views/seller/agriculture/agroManagement";
import { PrioriManagement } from "views/seller/priori/prioriManagement";
import { PriceAgroLife } from "views/seller/agriculture/priceAgroLife";
import { AgricultureSeller } from "views/seller/agriculture/agricultureSeller";
import { Profile } from "views/profile/profile";
import { Rol } from "views/profile/rol/rol";
import { IndexQualification } from "views/qualification/index";
import { IndexQualification as IndexQualificationLife } from "views/qualification/life/index";
import { Qualification } from "views/qualification/qualification";
import { PriceAgricultureQualification } from "views/qualification/priceQualification";
import { PriceLifeQualification } from "views/qualification/life/priceQualification";
import { CandidatePriori } from "views/seller/priori/candidate";
import { MyQuotes } from "views/seller/priori/myQuotes";
import { FormRequestVinculation } from "views/seller/priori/forms/formRequestVinculation";
import { FormCargaDocumentos } from "views/seller/priori/forms/formCargaDocumentos";
import { FormVerificacionOTP } from "views/seller/priori/forms/formVerificacionOTP";
import { PrioriQuotes } from "views/seller/priori/prioriQuotes";

const Routes = [
  {
    path: "/dashboard",
    view: Dashboard,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: "Welcome",
  },
  {
    path: "/help",
    view: Help,
    layout: "app",
    permission: "user",
    title: "Get Help",
  },
  {
    path: "/menu",
    view: Menu,
    layout: "menu",
    permission: "user",
    title: "Menu",
  },
  {
    path: "/productos",
    view: ListProducts,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/producto",
    view: Product,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/producto/vida",
    view: Life,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/producto/vida/:id",
    view: Life,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/producto/agricola",
    view: Agriculture,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/producto/agricola/:product/:id",
    view: Agriculture,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/producto/agricola/config",
    view: ConfigAgriculture,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor",
    view: Seller,
    layout: "app",
    permission: "user",
    title: "Vendedor",
  },
  {
    path: "/vendedor/vida",
    view: LifeSeller,
    layout: "app",
    permission: "user",
    title: "Vida",
  },
  {
    path: "/vendedor/vida/candidato",
    view: CandidateLife,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor/vida/cotizaciones",
    view: PriceLife,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor/vida/cotizaciones/:id",
    view: PriceClientLife,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/formularioSolicitudSeguro/:id",
    view: FormRequestInsurance,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/formularioVinculacion/:id",
    view: FormRequestVinculation,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/formCargaDocumentos/:id",
    view: FormCargaDocumentos,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/formVerificacionOTP/:id",
    view: FormVerificacionOTP,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/formularioAutorizacionDebito/:id",
    view: FormDebit,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor/agricola",
    view: AgricultureSeller,
    layout: "app",
    permission: "user",
    title: "Seguro agrícola",
  },
  {
    path: "/vendedor/agricola/candidato",
    view: CandidateAgriculture,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor/agricola/cotizaciones",
    view: PriceAgriculture,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor/agricola/cotizaciones/:id",
    view: PriceAgroLife,
    layout: "app",
    permission: "user",
    title: "Agrícola",
  },
  {
    path: "/vendedor/agricola/agroManagement",
    view: AgroManagement,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/profile",
    view: Profile,
    layout: "app",
    permission: "user",
    title: "Perfíl",
  },
  {
    path: "/profile/rol",
    view: Rol,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/profile/rolDetails/:id",
    view: RolDetails,
    layout: "app",
    permission: "user",
    title: "Detalle de Rol",
  },
  {
    path: "/qualification",
    view: IndexQualification,
    layout: "app",
    permission: "user",
    title: "Calificador agrícola",
  },
  {
    path: "/qualification/qualification",
    view: Qualification,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/qualification/price",
    view: PriceAgricultureQualification,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/qualificationLife",
    view: IndexQualificationLife,
    layout: "app",
    permission: "user",
    title: "Calificador vida",
  },
  {
    path: "/qualificationLife/price",
    view: PriceLifeQualification,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor/priori/prioriManagement",
    view: PrioriManagement,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor/priori/prioriManagement/candidato",
    view: CandidatePriori,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor/priori/prioriManagement/myQuotes",
    view: MyQuotes,
    layout: "app",
    permission: "user",
    title: "",
  },
  {
    path: "/vendedor/priori/cotizaciones/:id",
    view: PrioriQuotes,
    layout: "app",
    permission: "user",
    title: "",
  }
];

export default Routes;
