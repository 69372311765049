import "react-app-polyfill/ie9";
import { createRoot } from "react-dom/client";
import App from "./app/app";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

const welcome = () => console.log("Welcome to RIO 🚀");
welcome();
