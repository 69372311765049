/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, { useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Animate,
  Content,
  InputProduct,
  TitleProduct,
  CreatePlan,
  ValuesPlan,
  RequirementsPlan,
  SalesForce,
  Button,
  ViewContext,
  useNavigate,
  useLocation,
  useAPI,
} from "components/lib";
import Select from "react-select";
import {
  getBranch,
  getBranchXProduct,
  Submit,
} from "components/bridges/BridgeServices";
import {
  CheckBox,
  Heading,
  Page,
  PageContent,
  Grid,
  Box,
  ResponsiveContext,
} from "grommet";
import StyleGlobal from "../../../css/globalStyle.module.scss";
import { colourStyles } from "../../../css/globalStyles";
import { BackButton } from "components/backButton/button";
import { SetViewCandidateOther } from "utils/getPermissionsOther";

const methodCalculation = ["Creciente", "Nivelada"];

let objGeneralData = {
  codeProduct: "",
  mainCoverage: "",
  branch: "",
  product: "",
  calculationMethod: { Creciente: false, Nivelada: false },
  ageMin: "",
  ageMax: "",
  coberturas: [],
};

// columns, rows and areas are for Grid with a known number of contents / boxes.

// if size if small, we only 1 column
// if size if medium, we only 2 column
// if size if large or xlarge, we 3 three columns
const columns = {
  small: ["auto"],
  medium: ["auto", "auto"],
  large: ["auto", "auto"],
  xlarge: ["auto", "auto"],
};

// if size if small, we have 3 rows
// if size if medium, we have 2 rows
// if size if large or xlarge, we have 1 row
const rows = {
  small: ["xsmall", "xsmall"],
  medium: ["xsmall"],
  large: ["xsmall"],
  xlarge: ["xsmall"],
};

// set the different areas you need for every size
const areas = {
  small: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [0, 1], end: [0, 1] },
  ],
  medium: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
  ],
  large: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
  ],
  xlarge: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
  ],
};

// set the different areas you need for every size

const ResponsiveGrid = ({
  children,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {(size) => {
      // take into consideration if not array is sent but a simple string
      let columnsVal = columns;
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size];
        }
      }

      let rowsVal = rows;
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size];
        }
      }

      // also if areas is a simple array not an object of arrays for different sizes
      let areasVal = areas;
      if (areas && !Array.isArray(areas)) areasVal = areas[size];

      return (
        <Grid
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
        >
          {children}
        </Grid>
      );
    }}
  </ResponsiveContext.Consumer>
);

export function Life(props) {
  const rawUser = JSON.parse(localStorage.getItem('profile'))
  const userPermissions = JSON.parse(localStorage.getItem("user_permissions"));
  const productsLife = useAPI("/api/product/life");
  const viewContext = useContext(ViewContext);
  const [defaultProduct, setDefaultProduct] = useState();
  const [optionsCodeProducts, setOptionsCodeProduct] = useState([]);
  const [servicesData, setServicesData] = useState();
  const [generalData, setGeneralData] = useState(objGeneralData);
  const [planList, setPlanList] = useState([]);
  const [planValues, setPlanValues] = useState([]);
  const [optionsFeatures, setOptionsFeatures] = useState();
  const [productSelect, setProductSelect] = useState();
  const navigate = useNavigate();
  let url = useLocation();
  let vecUrl = url.pathname.split("/");
  let id = "";
  if (vecUrl.length > 3) {
    id = vecUrl[3];
  }

  const size = useContext(ResponsiveContext);

  const [salesForce, setSalesForce] = useState({
    broker: [],
    sponsor: [],
    sdp: [],
  });
  const [requirements, setRequirements] = useState([
    {
      ageMin: "",
      ageMax: "",
      mountMin: "",
      mountMax: "",
      description: "",
    },
  ]);

  const fillData = async () => {
    if (servicesData && productsLife.data) {
      let getProduct = "";
      productsLife.data.map((product) => {
        if (product.id === id) {
          getProduct = product;
        }
      });

      let tempOptionsProduct = [];
      servicesData.branchProduct.map((product) => {
        let findedProduct = productsLife.data.find(
          (ele) =>
            ele.detail.cod_producto.astr_id_producto.localeCompare(
              product.astr_id_producto
            ) === 0
        );
        if (!findedProduct) {
          tempOptionsProduct.push({
            value: product.astr_id_producto,
            label: product.astr_descripcion,
          });
        }
      });
      setOptionsCodeProduct(tempOptionsProduct);

      if (getProduct !== "" && id !== "") {
        let tempOptionFeatures = [];

        // identify the product to edit
        let getGeneralBranchProduct = servicesData.branchProduct.find(
          (product) =>
            product.astr_id_producto ===
            getProduct.detail.cod_producto.astr_id_producto
        );

        //set selected product
        servicesData.branchProduct.map((product) => {
          if (
            getProduct.detail.cod_producto.astr_id_producto ===
            product.astr_id_producto
          ) {
            setProductSelect({
              value: product.astr_id_producto,
              label: product.astr_descripcion,
            });
          }
        });

        //set method calculation in general data
        let tempCreciente =
          getProduct?.detail?.cod_producto?.tipo?.includes("creciente");
        let tempNivelada =
          getProduct?.detail?.cod_producto?.tipo?.includes("nivelada");

        //set requiriments
        let tempRequirements = [];
        getProduct.detail.requisitos.map((requirement) => {
          tempRequirements.push({
            ageMin: requirement.edad_desde,
            ageMax: requirement.edad_hasta,
            mountMin: requirement.monto_desde,
            mountMax: requirement.monto_hasta,
            description: requirement.requisitos,
          });
        });

        setRequirements(tempRequirements);
        setSalesForce({
          broker: getProduct.detail.fuerza_de_ventas.broker,
          sponsor: getProduct.detail.fuerza_de_ventas.sponsor,
          sdp: getProduct.detail.fuerza_de_ventas.fv,
        });

        setPlanValues(getProduct.detail.planes[0]);

        let tempFeatures = [];
        Object.entries(getProduct.detail.planes[0]).map((plan) => {
          let tempDataFeatures = [];
          plan[1].values.map((feature) => {
            getGeneralBranchProduct.coberturas.map((coverage, index) => {
              if (feature === coverage.astr_codigo_cob) {
                tempDataFeatures.push({
                  value: coverage.astr_codigo_cob,
                  label: coverage.astr_cob_descrip_print,
                  astr_codigo_cob: coverage.astr_codigo_cob,
                  astr_cob_bd_inc_sa: coverage.astr_cob_bd_inc_sa,
                  adbl_vm_sa: coverage.adbl_vm_sa,
                  adbl_qt_tasa: coverage.adbl_qt_tasa,
                  astr_obtener_prima: coverage.astr_obtener_prima,
                  adbl_vm_pri: coverage.adbl_vm_pri,
                  astr_basica: coverage.astr_basica,
                  astr_principal: coverage.astr_principal,
                  adbl_cob_mon_min: coverage.adbl_cob_mon_min,
                  adbl_cob_mon_max: coverage.adbl_cob_mon_max,
                  aint_cob_edad_min: coverage.aint_cob_edad_min,
                  aint_cob_edad_max: coverage.aint_cob_edad_max,
                  aint_cob_edad_perm: coverage.aint_cob_edad_perm,
                  astr_cob_descrip_print: coverage.astr_cob_descrip_print,
                  astr_descripcion: coverage.astr_descripcion,
                });
              }
            });
          });
          tempFeatures.push({
            features: tempDataFeatures,
            alternative: [],
          });
        });

        Object.entries(getProduct.detail.valores_asegurados).map(
          (plan, indexPlan) => {
            plan[1].map((alternative) => {
              alternative.alternativas.map((feature, indexFeature) => {
                // feature.map((value, indexValue) => {
                if (
                  alternative.alternativas.length >
                  tempFeatures[indexPlan].alternative.length
                ) {
                  tempFeatures[indexPlan].alternative.push([]);
                }
                tempFeatures[indexPlan].alternative[indexFeature].push({
                  value: feature.valor_alternativa,
                  uuid: feature.uuidAlternativa,
                });
                // });
              });
            });
          }
        );
        servicesData.branchProduct.map((product, indexProduct) => {
          if (
            getProduct.detail.cod_producto.astr_id_producto ===
            product.astr_id_producto
          ) {
            product.coberturas.map((coverage) => {
              tempOptionFeatures.push({
                value: coverage.astr_codigo_cob,
                label: coverage.astr_cob_descrip_print,
                astr_codigo_cob: coverage.astr_codigo_cob,
                astr_cob_bd_inc_sa: coverage.astr_cob_bd_inc_sa,
                adbl_vm_sa: coverage.adbl_vm_sa,
                adbl_qt_tasa: coverage.adbl_qt_tasa,
                astr_obtener_prima: coverage.astr_obtener_prima,
                adbl_vm_pri: coverage.adbl_vm_pri,
                astr_basica: coverage.astr_basica,
                astr_principal: coverage.astr_principal,
                adbl_cob_mon_min: coverage.adbl_cob_mon_min,
                adbl_cob_mon_max: coverage.adbl_cob_mon_max,
                aint_cob_edad_min: coverage.aint_cob_edad_min,
                aint_cob_edad_max: coverage.aint_cob_edad_max,
                aint_cob_edad_perm: coverage.aint_cob_edad_perm,
                astr_cob_descrip_print: coverage.astr_cob_descrip_print,
                astr_descripcion: coverage.astr_descripcion,
              });
            });
          }
        });

        let indexMainCoverage = tempOptionFeatures.findIndex(
          (coverage) => coverage.astr_principal === "S"
        );
        tempOptionFeatures.splice(indexMainCoverage, 1);

        setOptionsFeatures(tempOptionFeatures);
        setPlanList(tempFeatures);
        servicesData.branchProduct.map((product, index) => {
          if (
            product.astr_id_producto ===
            getProduct.detail.cod_producto.astr_id_producto
          ) {
            setGeneralData({
              ...generalData,
              calculationMethod: {
                Creciente: tempCreciente,
                Nivelada: tempNivelada,
              },
              branch: servicesData.branch[0].astr_descripcion,
              ageMin: servicesData.branchProduct[index].aint_edad_min,
              ageMax: servicesData.branchProduct[index].aint_edad_max,
              codeProduct: servicesData.branchProduct[index].astr_id_producto,
              mainCoverage: getMainCoverage(
                servicesData.branchProduct[index].coberturas
              )[0].astr_cob_descrip_print,
              product: servicesData.branchProduct[index].astr_descripcion,
              mountMax: servicesData.branchProduct[index].adbl_mon_max,
              mountMin: servicesData.branchProduct[index].adbl_mon_min,
              astr_mon_id: servicesData.branchProduct[index].astr_mon_id,
              aint_edad_perm: servicesData.branchProduct[index].aint_edad_perm,
              astr_ahorro: servicesData.branchProduct[index].astr_ahorro,
              astr_ramo: servicesData.branchProduct[index].astr_ramo,
              adbl_vm_der_emision:
                servicesData.branchProduct[index].adbl_vm_der_emision,
              coberturas: servicesData.branchProduct[index].coberturas,
            });
          }
        });
      } else {
        let tempOptionFeatures = [];
        setDefaultProduct(tempOptionsProduct[0]);
        let tempFeatures = [
          {
            features: [],
            alternative: [[0]],
          },
        ];
        servicesData.branchProduct[0].coberturas.map((coverage, index) => {
          if (coverage.astr_principal === "S") {
            tempFeatures[0].features.push({
              value: coverage.astr_codigo_cob,
              label: coverage.astr_cob_descrip_print,
              astr_codigo_cob: coverage.astr_codigo_cob,
              astr_cob_bd_inc_sa: coverage.astr_cob_bd_inc_sa,
              adbl_vm_sa: coverage.adbl_vm_sa,
              adbl_qt_tasa: coverage.adbl_qt_tasa,
              astr_obtener_prima: coverage.astr_obtener_prima,
              adbl_vm_pri: coverage.adbl_vm_pri,
              astr_basica: coverage.astr_basica,
              astr_principal: coverage.astr_principal,
              adbl_cob_mon_min: coverage.adbl_cob_mon_min,
              adbl_cob_mon_max: coverage.adbl_cob_mon_max,
              aint_cob_edad_min: coverage.aint_cob_edad_min,
              aint_cob_edad_max: coverage.aint_cob_edad_max,
              aint_cob_edad_perm: coverage.aint_cob_edad_perm,
              astr_cob_descrip_print: coverage.astr_cob_descrip_print,
              astr_descripcion: coverage.astr_descripcion,
            });
            setPlanList(tempFeatures);
          }
          tempOptionFeatures.push({
            value: coverage.astr_codigo_cob,
            label: coverage.astr_cob_descrip_print,
            astr_codigo_cob: coverage.astr_codigo_cob,
            astr_cob_bd_inc_sa: coverage.astr_cob_bd_inc_sa,
            adbl_vm_sa: coverage.adbl_vm_sa,
            adbl_qt_tasa: coverage.adbl_qt_tasa,
            astr_obtener_prima: coverage.astr_obtener_prima,
            adbl_vm_pri: coverage.adbl_vm_pri,
            astr_basica: coverage.astr_basica,
            astr_principal: coverage.astr_principal,
            adbl_cob_mon_min: coverage.adbl_cob_mon_min,
            adbl_cob_mon_max: coverage.adbl_cob_mon_max,
            aint_cob_edad_min: coverage.aint_cob_edad_min,
            aint_cob_edad_max: coverage.aint_cob_edad_max,
            aint_cob_edad_perm: coverage.aint_cob_edad_perm,
            astr_cob_descrip_print: coverage.astr_cob_descrip_print,
            astr_descripcion: coverage.astr_descripcion,
          });
        });

        let indexMainCoverage = tempOptionFeatures.findIndex(
          (coverage) => coverage.astr_principal === "S"
        );
        tempOptionFeatures.splice(indexMainCoverage, 1);

        setOptionsFeatures(tempOptionFeatures);
        setGeneralData({
          ...generalData,
          branch: servicesData.branch[0].astr_descripcion,
          ageMin: servicesData.branchProduct[0].aint_edad_min,
          ageMax: servicesData.branchProduct[0].aint_edad_max,
          codeProduct: servicesData.branchProduct[0].astr_id_producto,
          mainCoverage: getMainCoverage(
            servicesData.branchProduct[0].coberturas
          )[0].astr_cob_descrip_print,
          product: servicesData.branchProduct[0].astr_descripcion,
          mountMax: servicesData.branchProduct[0].adbl_mon_max,
          mountMin: servicesData.branchProduct[0].adbl_mon_min,
          astr_mon_id: servicesData.branchProduct[0].astr_mon_id,
          aint_edad_perm: servicesData.branchProduct[0].aint_edad_perm,
          astr_ahorro: servicesData.branchProduct[0].astr_ahorro,
          astr_ramo: servicesData.branchProduct[0].astr_ramo,
          adbl_vm_der_emision:
            servicesData.branchProduct[0].adbl_vm_der_emision,
          coberturas: servicesData.branchProduct[0].coberturas,
        });
      }
    }
  };

  const getGeneralData = async () => {
    try {
      let tempData = {};
      let obj = {
        ramo: "20",
        ahorro: "S",
      };
      const responseBranchXProduct = await getBranchXProduct(obj);
      tempData.branchProduct = responseBranchXProduct;
      obj = {
        ramo: "20",
      };
      const responseBranch = await getBranch(obj);
      tempData.branch = responseBranch;
      setServicesData(tempData);
    } catch (error) {
      console.error(error);
    }
  };

  const getMainCoverage = (coverages) => {
    let tempCoverage = coverages.filter(
      (coverage) => coverage.astr_principal === "S"
    );
    setGeneralData({ ...generalData, mainCoverage: tempCoverage });
    return tempCoverage;
  };

  const getTitle = () => {
    let title = "";
    Object.keys(generalData.calculationMethod).map((key, idx) => {
      if (generalData.calculationMethod[key] === true) {
        title += key + " - ";
      }
    });

    return title;
  };

  const handleChangeCalculation = (e) => {
    let tempObject = { ...generalData };
    tempObject.calculationMethod[e.target.name] = e.target.checked;

    setGeneralData(tempObject);
  };

  const handleChangeProduct = (e) => {
    navigate("/producto/vida", { replace: true });
    let productExist = false;

    let tempOptionFeatures = [];
    let index = servicesData.branchProduct
      .map((tempValue) => tempValue.astr_id_producto)
      .indexOf(e.value);
    let tempFeatures = [
      {
        features: [],
        alternative: [[0]],
      },
    ];

    productsLife.data.map((data) => {
      if (
        data.detail.cod_producto.astr_id_producto ===
        servicesData.branchProduct[index].astr_id_producto
      ) {
        productExist = true;
      }
    });

    setProductSelect({
      value: servicesData.branchProduct[index].astr_id_producto,
      label: servicesData.branchProduct[index].astr_descripcion,
    });

    if (productExist) {
      let getProduct = productsLife.data.find(
        (item) =>
          item.detail.cod_producto.astr_id_producto ===
          servicesData.branchProduct[index].astr_id_producto
      );
      let tempOptionFeatures = [];

      // identify the product to edit
      let getGeneralBranchProduct = servicesData.branchProduct.find(
        (product) =>
          product.astr_id_producto ===
          getProduct.detail.cod_producto.astr_id_producto
      );

      //set selected product
      servicesData.branchProduct.map((product) => {
        if (getProduct.astr_id_producto === product.astr_id_producto) {
          setProductSelect({
            value: product.astr_id_producto,
            label: product.astr_descripcion,
          });
        }
      });

      //set method calculation in general data
      let tempCreciente =
        getProduct.detail.cod_producto.tipo.includes("creciente");
      let tempNivelada =
        getProduct.detail.cod_producto.tipo.includes("nivelada");

      //set requiriments
      let tempRequirements = [];
      getProduct.detail.requisitos.map((requirement) => {
        tempRequirements.push({
          ageMin: requirement.edad_desde,
          ageMax: requirement.edad_hasta,
          mountMin: requirement.monto_desde,
          mountMax: requirement.monto_hasta,
          description: requirement.requisitos,
        });
      });

      setRequirements(tempRequirements);
      setSalesForce({
        broker: getProduct.detail.fuerza_de_ventas.broker,
        sponsor: getProduct.detail.fuerza_de_ventas.sponsor,
        sdp: getProduct.detail.fuerza_de_ventas.fv,
      });

      let tempFeatures = [];
      Object.entries(getProduct.detail.planes[0]).map((plan) => {
        let tempDataFeatures = [];
        plan[1].map((feature) => {
          getGeneralBranchProduct.coberturas.map((coverage, index) => {
            if (feature === coverage.astr_codigo_cob) {
              tempDataFeatures.push({
                value: coverage.astr_codigo_cob,
                label: coverage.astr_cob_descrip_print,
                astr_codigo_cob: coverage.astr_codigo_cob,
                astr_cob_bd_inc_sa: coverage.astr_cob_bd_inc_sa,
                adbl_vm_sa: coverage.adbl_vm_sa,
                adbl_qt_tasa: coverage.adbl_qt_tasa,
                astr_obtener_prima: coverage.astr_obtener_prima,
                adbl_vm_pri: coverage.adbl_vm_pri,
                astr_basica: coverage.astr_basica,
                astr_principal: coverage.astr_principal,
                adbl_cob_mon_min: coverage.adbl_cob_mon_min,
                adbl_cob_mon_max: coverage.adbl_cob_mon_max,
                aint_cob_edad_min: coverage.aint_cob_edad_min,
                aint_cob_edad_max: coverage.aint_cob_edad_max,
                aint_cob_edad_perm: coverage.aint_cob_edad_perm,
                astr_cob_descrip_print: coverage.astr_cob_descrip_print,
                astr_descripcion: coverage.astr_descripcion,
              });
            }
          });
        });
        tempFeatures.push({
          features: tempDataFeatures,
          alternative: [],
        });
      });

      Object.entries(getProduct.detail.valores_asegurados).map(
        (plan, indexPlan) => {
          plan[1].map((alternative) => {
            alternative.alternativas.map((feature, indexFeature) => {
              // feature.map((value, indexValue) => {
              if (
                alternative.alternativas.length >
                tempFeatures[indexPlan].alternative.length
              ) {
                tempFeatures[indexPlan].alternative.push([]);
              }
              tempFeatures[indexPlan].alternative[indexFeature].push(
                feature.valor_alternativa
              );
              // });
            });
          });
        }
      );

      servicesData.branchProduct.map((product, indexProduct) => {
        if (
          getProduct.detail.cod_producto.astr_id_producto ===
          product.astr_id_producto
        ) {
          product.coberturas.map((coverage) => {
            tempOptionFeatures.push({
              value: coverage.astr_codigo_cob,
              label: coverage.astr_cob_descrip_print,
              astr_codigo_cob: coverage.astr_codigo_cob,
              astr_cob_bd_inc_sa: coverage.astr_cob_bd_inc_sa,
              adbl_vm_sa: coverage.adbl_vm_sa,
              adbl_qt_tasa: coverage.adbl_qt_tasa,
              astr_obtener_prima: coverage.astr_obtener_prima,
              adbl_vm_pri: coverage.adbl_vm_pri,
              astr_basica: coverage.astr_basica,
              astr_principal: coverage.astr_principal,
              adbl_cob_mon_min: coverage.adbl_cob_mon_min,
              adbl_cob_mon_max: coverage.adbl_cob_mon_max,
              aint_cob_edad_min: coverage.aint_cob_edad_min,
              aint_cob_edad_max: coverage.aint_cob_edad_max,
              aint_cob_edad_perm: coverage.aint_cob_edad_perm,
              astr_cob_descrip_print: coverage.astr_cob_descrip_print,
              astr_descripcion: coverage.astr_descripcion,
            });
          });
        }
      });

      tempOptionFeatures.shift();
      setOptionsFeatures(tempOptionFeatures);
      setPlanList(tempFeatures);
      servicesData.branchProduct.map((product, index) => {
        if (
          product.astr_id_producto ===
          getProduct.detail.cod_producto.astr_id_producto
        ) {
          setGeneralData({
            ...generalData,
            calculationMethod: {
              Creciente: tempCreciente,
              Nivelada: tempNivelada,
            },
            branch: servicesData.branch[0].astr_descripcion,
            ageMin: servicesData.branchProduct[index].aint_edad_min,
            ageMax: servicesData.branchProduct[index].aint_edad_max,
            codeProduct: servicesData.branchProduct[index].astr_id_producto,
            mainCoverage: getMainCoverage(
              servicesData.branchProduct[index].coberturas
            )[0].astr_cob_descrip_print,
            product: servicesData.branchProduct[index].astr_descripcion,
            mountMax: servicesData.branchProduct[index].adbl_mon_max,
            mountMin: servicesData.branchProduct[index].adbl_mon_min,
            astr_mon_id: servicesData.branchProduct[index].astr_mon_id,
            aint_edad_perm: servicesData.branchProduct[index].aint_edad_perm,
            astr_ahorro: servicesData.branchProduct[index].astr_ahorro,
            astr_ramo: servicesData.branchProduct[index].astr_ramo,
            adbl_vm_der_emision:
              servicesData.branchProduct[index].adbl_vm_der_emision,
            coberturas: servicesData.branchProduct[index].coberturas,
          });
        }
      });
    } else {
      servicesData.branchProduct[index].coberturas.map(
        (coverage, indexCoverage) => {
          if (coverage.astr_principal === "S") {
            tempFeatures[0].features.push({
              value: coverage.astr_codigo_cob,
              label: coverage.astr_cob_descrip_print,
              astr_codigo_cob: coverage.astr_codigo_cob,
              astr_cob_bd_inc_sa: coverage.astr_cob_bd_inc_sa,
              adbl_vm_sa: coverage.adbl_vm_sa,
              adbl_qt_tasa: coverage.adbl_qt_tasa,
              astr_obtener_prima: coverage.astr_obtener_prima,
              adbl_vm_pri: coverage.adbl_vm_pri,
              astr_basica: coverage.astr_basica,
              astr_principal: coverage.astr_principal,
              adbl_cob_mon_min: coverage.adbl_cob_mon_min,
              adbl_cob_mon_max: coverage.adbl_cob_mon_max,
              aint_cob_edad_min: coverage.aint_cob_edad_min,
              aint_cob_edad_max: coverage.aint_cob_edad_max,
              aint_cob_edad_perm: coverage.aint_cob_edad_perm,
              astr_cob_descrip_print: coverage.astr_cob_descrip_print,
              astr_descripcion: coverage.astr_descripcion,
            });
          }
          tempOptionFeatures.push({
            value: coverage.astr_codigo_cob,
            label: coverage.astr_cob_descrip_print,
            astr_codigo_cob: coverage.astr_codigo_cob,
            astr_cob_bd_inc_sa: coverage.astr_cob_bd_inc_sa,
            adbl_vm_sa: coverage.adbl_vm_sa,
            adbl_qt_tasa: coverage.adbl_qt_tasa,
            astr_obtener_prima: coverage.astr_obtener_prima,
            adbl_vm_pri: coverage.adbl_vm_pri,
            astr_basica: coverage.astr_basica,
            astr_principal: coverage.astr_principal,
            adbl_cob_mon_min: coverage.adbl_cob_mon_min,
            adbl_cob_mon_max: coverage.adbl_cob_mon_max,
            aint_cob_edad_min: coverage.aint_cob_edad_min,
            aint_cob_edad_max: coverage.aint_cob_edad_max,
            aint_cob_edad_perm: coverage.aint_cob_edad_perm,
            astr_cob_descrip_print: coverage.astr_cob_descrip_print,
            astr_descripcion: coverage.astr_descripcion,
          });
        }
      );
      let indexMainCoverage = tempOptionFeatures.findIndex(
        (coverage) => coverage.astr_principal === "S"
      );
      tempOptionFeatures.splice(indexMainCoverage, 1);
      setOptionsFeatures(tempOptionFeatures);
      setGeneralData({
        ...generalData,
        branch: servicesData.branch[0].astr_descripcion,
        ageMin: servicesData.branchProduct[index].aint_edad_min,
        ageMax: servicesData.branchProduct[index].aint_edad_max,
        codeProduct: servicesData.branchProduct[index].astr_id_producto,
        mainCoverage: getMainCoverage(
          servicesData.branchProduct[index].coberturas
        )[0].astr_cob_descrip_print,
        product: servicesData.branchProduct[index].astr_descripcion,
        mountMax: servicesData.branchProduct[index].adbl_mon_max,
        mountMin: servicesData.branchProduct[index].adbl_mon_min,
        astr_mon_id: servicesData.branchProduct[index].astr_mon_id,
        aint_edad_perm: servicesData.branchProduct[index].aint_edad_perm,
        astr_ahorro: servicesData.branchProduct[index].astr_ahorro,
        astr_ramo: servicesData.branchProduct[index].astr_ramo,
        adbl_vm_der_emision:
          servicesData.branchProduct[index].adbl_vm_der_emision,
        coberturas: servicesData.branchProduct[index].coberturas,
      });

      setPlanList(tempFeatures);
    }

  };

  const handleSaveProduct = async () => {
    let mappedRequirements = [];
    let mappedPlans = {};
    let valuePlans = [];
    let alternativeZero = false;
    let textAlternative = "";
    planList.map((plan, indexPlan) => {
      if (plan.alternative.length === 0) {
        textAlternative += `Plan ${indexPlan + 1} - Sin alternativas, `;
        alternativeZero = true;
      } else {
        plan.alternative.map((alternativeValue, indexAlternativa) => {
          let addText = false;
          alternativeValue.map((tempValue) => {
            if (typeof tempValue === "object") {
              if (tempValue.value === undefined) {
                addText = true;
                alternativeZero = true;
              }
            } else {
              if (tempValue === 0) {
                addText = true;
                alternativeZero = true;
              }
            }
          });
          if (addText) {
            textAlternative += `Plan ${indexPlan + 1} - Alternativa ${indexAlternativa + 1
              }, `;
            addText = false;
          }
        });
      }
    });


    if (alternativeZero) {
      viewContext.notification.show(
        `Las siguientes alternativas tienen valores en 0: ${textAlternative}`,
        "error",
        false
      );
      return;
    } else {
      requirements.map((requirement) =>
        mappedRequirements.push({
          edad_desde: requirement.ageMin,
          edad_hasta: requirement.ageMax,
          monto_desde: requirement.mountMin,
          monto_hasta: requirement.mountMax,
          requisitos: requirement.description,
        })
      );
      for (let index = 0; index < planList.length; index++) {
        let tempPlan = [];
        planList[index].features.map((feature) => {
          tempPlan.push(feature.value);
        });
        mappedPlans[`plan${index + 1}`] = {};
        if (planValues.hasOwnProperty(`plan${index + 1}`)) {
          mappedPlans[`plan${index + 1}`]["uuid"] =
            planValues[`plan${index + 1}`].uuid;
        } else {
          mappedPlans[`plan${index + 1}`]["uuid"] = `plan${uuidv4()}`;
        }
        mappedPlans[`plan${index + 1}`]["values"] = tempPlan;
      }

      let tempIdAlternativas = [];

      planList.map((plan, indexPlan) => {
        tempIdAlternativas.push({
          plan: `plan${indexPlan + 1}`,
          uid: [],
        });
        plan.alternative.map((item) => {
          if (item[0].hasOwnProperty("uuid")) {
            let tempUuid2 = "";
            item.map((tempItem) => {
              tempUuid2 = tempItem.uuid;
            });
            tempIdAlternativas[indexPlan].uid.push(tempUuid2);
          } else {
            let tempUuid = uuidv4();
            tempIdAlternativas[indexPlan].uid.push(tempUuid);
          }
        });
      });

      let tempAlternative = {};
      planList.map((plan, indexPlan) => {
        tempAlternative[`plan${indexPlan + 1}`] = [];
        plan.features.map((feature, indexFeature) => {
          tempAlternative[`plan${indexPlan + 1}`].push({
            astr_codigo_cob: feature.value,
            alternativas: [],
          });
          plan.alternative.map((alternative, indexAlternative) => {
            alternative.map((value, indexValue) => {
              if (indexFeature === indexValue) {
                tempAlternative[`plan${indexPlan + 1}`][
                  indexFeature
                ].alternativas.push({
                  // nombre_alternativa: `ALTERNATIVA${indexFeature + 1}`,
                  uuidAlternativa:
                    tempAlternative[`plan${indexPlan + 1}`][indexFeature]
                      .alternativas.length === 0
                      ? tempIdAlternativas[indexPlan].uid[0]
                      : tempIdAlternativas[indexPlan].uid[
                      tempAlternative[`plan${indexPlan + 1}`][indexFeature]
                        .alternativas.length
                      ],
                  valor_alternativa: id !== "" ? value.value : value,
                });
              }
            });
          });
        });
      });

      valuePlans = tempAlternative;

      let methodCalculate =
        generalData.calculationMethod.Creciente &&
          generalData.calculationMethod.Nivelada
          ? "creciente/nivelada"
          : generalData.calculationMethod.Creciente
            ? "creciente"
            : generalData.calculationMethod.Nivelada
              ? "nivelada"
              : "undefined";

      let obj = {
        type: "life",
        detail: {
          cod_producto: {
            astr_ramo: generalData.astr_ramo,
            astr_id_producto: generalData.codeProduct,
            astr_descripcion: generalData.product,
            astr_mon_id: generalData.astr_mon_id,
            adbl_mon_min: generalData.mountMin,
            adbl_mon_max: generalData.mountMax,
            aint_edad_min: generalData.ageMin,
            aint_edad_max: generalData.ageMax,
            aint_edad_perm: generalData.aint_edad_perm,
            astr_ahorro: generalData.astr_ahorro,
            adbl_vm_der_emision: generalData.adbl_vm_der_emision,
            coberturas: generalData.coberturas,
            tipo: methodCalculate,
          },
          ramo: servicesData.branch[0],
          planes: [mappedPlans],
          valores_asegurados: valuePlans,
          requisitos: mappedRequirements,
          fuerza_de_ventas: {
            broker: salesForce.broker,
            sponsor: salesForce.sponsor,
            fv: salesForce.sdp,
          },
        },
      };

      let getProduct = "";
      let productExist = false;

      productsLife.data.map((product) => {
        if (product.id === id) {
          getProduct = product;
        }
        if (
          product.detail.cod_producto.astr_id_producto ===
          generalData.codeProduct
        ) {
          productExist = true;
        }
      });

      try {
        if (id !== "" && productExist) {
          obj.id = getProduct.id;
          await Submit("product", obj, "patch")
            .then((value) => {
              viewContext.notification.show(
                value.data.message,
                "success",
                true
              );
              navigate("/producto");
            })
            .catch((error) => {
              viewContext.notification.show(
                error.response.data.message,
                "error",
                true
              );
            })
            .finally(() => {
              viewContext.handleError(
                "La Promesa está finalizada, lo que significa que ha sido resuelta o rechazada."
              );
            });
        } else {
          await Submit("product", obj, "post")
            .then((value) => {
              viewContext.notification.show(
                value.data.message,
                "success",
                true
              );
              navigate("/producto");
            })
            .catch((error) => {
              viewContext.handleError(error);
            })
            .finally(() => {
              viewContext.handleError(
                "La Promesa está finalizada, lo que significa que ha sido resuelta o rechazada."
              );
            });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fillData();
  }, [servicesData, productsLife]);

  useEffect(() => {
    getGeneralData();
  }, []);

  return (
    <Page>
      <PageContent>
        <div className={StyleGlobal.buttonPosition}>
          <BackButton go={-1} />
        </div>
        <Animate type='pop'>
          <section className={StyleGlobal.paddingLeft}>
            <Content>
              <section>
                {/* <div style={{ padding: "15px 0px" }}>
                  <p className={StyleGlobal.textTitle}>Vida</p>
                </div> */}
                <Box
                  justify='center'
                  align='center'
                  direction={"column"}
                  height='100%'
                  width={size.indexOf("small") > -1 ? "100%" : "50%"}
                  pad='small'
                >
                  <Box height='100%' width={"100%"} pad='small'>
                    <Heading margin='none' level={4}>
                      Selecciona
                      <span style={{ fontSize: "small" }}>
                        &nbsp;&nbsp;(Ingresa el código o el nombre del producto)
                      </span>
                    </Heading>
                  </Box>
                  <Box height='100%' width={"100%"} pad='small'>
                    <Select
                      value={productSelect}
                      className='basic-single'
                      classNamePrefix='select'
                      defaultValue={defaultProduct}
                      styles={colourStyles}
                      options={optionsCodeProducts}
                      isSearchable
                      isLoading={
                        optionsCodeProducts.length === 0 ? true : false
                      }
                      placeholder={"Seleccionar"}
                      menuPosition='fixed'
                      menuPlacement={"auto"}
                      onChange={handleChangeProduct}
                      isDisabled={id.localeCompare("") === 0 ? false : true}
                    />
                  </Box>
                </Box>
                <ResponsiveGrid
                  rows={rows}
                  columns={columns}
                  gap='small'
                  areas={areas}
                  margin='small'
                >
                  <Box gridArea='side1'>
                    <InputProduct
                      label='Código producto'
                      name='product'
                      disabled
                      value={generalData.codeProduct}
                    />
                  </Box>
                  <Box gridArea='side2'>
                    <InputProduct
                      label='Nombre producto'
                      name='product'
                      disabled
                      value={generalData.product}
                    />
                  </Box>
                </ResponsiveGrid>
                <ResponsiveGrid
                  rows={rows}
                  columns={columns}
                  gap='small'
                  areas={areas}
                  margin='small'
                >
                  <Box gridArea='side1'>
                    <InputProduct
                      label='Cobertura principal'
                      name='coveragePrincipal'
                      disabled
                      value={generalData.mainCoverage}
                    />
                  </Box>
                  <Box gridArea='side2' height={"100%"} justify='center'>
                    <Box
                      justify='center'
                      align='center'
                      direction={"column"}
                      height='100%'
                    >
                      <Box
                        height='100%'
                        width={"100%"}
                        pad='small'
                      // background='red'
                      >
                        <Heading margin='none' level={4}>
                          Método de cálculo
                        </Heading>
                      </Box>
                      <Box
                        height='100%'
                        width={"100%"}
                        pad='small'
                        direction='row'
                      >
                        {methodCalculation.map((key, idx) => {
                          return (
                            <Box
                              key={`tableMethodCalculation${idx}`}
                              pad={
                                idx === 1
                                  ? "11px 0px 11px 11px"
                                  : "11px 0px 11px 0px"
                              }
                            >
                              <CheckBox
                                // checked={checked}
                                // onChange={(event) => setChecked(event.target.checked)}
                                // type='checkbox'
                                // label={key}
                                name={key}
                                checked={generalData.calculationMethod[key]}
                                onChange={handleChangeCalculation}
                                label={key}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    </Box>
                  </Box>
                </ResponsiveGrid>
                <Box
                  justify='center'
                  align='center'
                  direction={"column"}
                  height='100%'
                  width={size.indexOf("small") > -1 ? "100%" : "50%"}
                  pad='small'
                >
                  <Box height='100%' width={"100%"}>
                    <InputProduct
                      label='Ramo'
                      name='ramo'
                      disabled
                      value={generalData.branch}
                    />
                  </Box>
                </Box>
              </section>
              <section>
                <ResponsiveGrid
                  rows={rows}
                  columns={columns}
                  areas={areas}
                  margin='small'
                >
                  <Box
                    gridArea='side1'
                    direction='row'
                    align='center'
                    margin={"10px"}
                  >
                    <Heading level='3' margin='none'>
                      Edades asegurados
                    </Heading>
                  </Box>
                  <Box gridArea='side2' direction='row' align='center'>
                    <Box background={"#E6E8F4"}>
                      <InputProduct
                        label='Mínima'
                        name='ageMin'
                        value={generalData.ageMin}
                        disabled
                      />
                    </Box>
                    <Box background={"#E6E8F4"}>
                      <InputProduct
                        label='Máxima'
                        name='ageMax'
                        value={generalData.ageMax}
                        disabled
                      />
                    </Box>
                  </Box>
                </ResponsiveGrid>
              </section>
              <section>
                <Box
                  background={"#e9f7fb"}
                  width='100%'
                  margin={"30px 0px 0px 0px"}
                  pad={"50px"}
                  align='center'
                >
                  <Heading level='3' margin='none' textAlign='center'>
                    Creación de planes
                  </Heading>
                </Box>
                <TitleProduct blackTitle={`Creación de planes ${getTitle()}`}>
                  <CreatePlan
                    planList={planList}
                    setPlanList={setPlanList}
                    options={optionsFeatures}
                    planValues={planValues}
                    setPlanValues={setPlanValues}
                  />
                </TitleProduct>
              </section>
              <section>
                <TitleProduct
                  blackTitle={`Valores asegurados plan ${getTitle()}`}
                >
                  <ValuesPlan planList={planList} setPlanList={setPlanList} />
                </TitleProduct>
              </section>
              <section style={{ padding: "30px 0px 0px 0px" }}>
                <Box
                  style={{
                    borderBottom: "1px solid #0F265C",
                    paddingBottom: "10px",
                  }}
                >
                  <TitleProduct
                    style={{ border: "10px bold #000000" }}
                    whiteTitle='Requisitos de asegurabilidad'
                  />
                </Box>
                <RequirementsPlan
                  requirements={requirements}
                  setRequirements={setRequirements}
                  generalData={generalData}
                />
              </section>
              <section>
                <TitleProduct blackTitle='Fuerza de ventas'>
                  <SalesForce
                    salesForce={salesForce}
                    setSalesForce={setSalesForce}
                  />
                </TitleProduct>
              </section>
              <section>
                {SetViewCandidateOther(
                  rawUser,
                  userPermissions,
                  ["c", "u"],
                  "confprod"
                ) && (
                    <div style={{ margin: "20px 0px", display: "flex" }}>
                      <Button
                        text={"Guardar producto"}
                        action={handleSaveProduct}
                        color='blue'
                      />
                    </div>
                  )}
              </section>
            </Content>
          </section>
        </Animate>
      </PageContent>
    </Page>
  );
}
