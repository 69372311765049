export const typeIrrigation = [
  {
    codigo: "V",
    descripcion: "GRAVEDAD ",
  },
  {
    codigo: "A",
    descripcion: "ASPERSION ",
  },
  {
    codigo: "G",
    descripcion: "GOTEO ",
  },
];

export const typeSeed = [
  {
    codigo: "R",
    descripcion: "RECICLADA ",
  },
  {
    codigo: "C",
    descripcion: "CERTIFICADA ",
  },
];

export const typeCultivation = [
  {
    codigo: "P",
    descripcion: "Perenne ",
  },
  {
    codigo: "C",
    descripcion: "Ciclo Corto ",
  },
];

export const parishData = [
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "CUENCA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "BAÑOS",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "CUMBE",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "CHAUCHA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "CHECA (JIDCAY) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "CHIQUINTAD",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "LLACAO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "MOLLETURO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "NULTI",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "OCTAVIO CORDERO PALACIOS (SANTA ROSA) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "60",
    nombreParroquia: "PACCHA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "61",
    nombreParroquia: "QUINGEO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "62",
    nombreParroquia: "RICAURTE",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "63",
    nombreParroquia: "SAN JOAQUIN",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "64",
    nombreParroquia: "SANTA ANA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "65",
    nombreParroquia: "SAYAUSI",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "66",
    nombreParroquia: "SIDCAY",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "67",
    nombreParroquia: "SININCAY",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "68",
    nombreParroquia: "TARQUI",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "69",
    nombreParroquia: "TURI",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "70",
    nombreParroquia: "VALLE",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "1",
    codigoParroquia: "71",
    nombreParroquia: "VICTORIA DEL PORTETE (IRQUIS)",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "GIRON",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "ASUNCION",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "SAN GERARDO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "GUALACEO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "DANIEL CORDOVA TORAL (EL ORIENTE) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "JADAN",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "MARIANO MORENO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "3",
    codigoParroquia: "56",
    nombreParroquia: "REMIGIO CRESPO TORAL (GULAG)",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "3",
    codigoParroquia: "57",
    nombreParroquia: "SAN JUAN",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "3",
    codigoParroquia: "58",
    nombreParroquia: "ZHIDMAD",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "3",
    codigoParroquia: "59",
    nombreParroquia: "LUIS CORDERO VEGA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "3",
    codigoParroquia: "60",
    nombreParroquia: "SIMON BOLIVAR (CAB. EN GAÑANZOL)",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: " NABON",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "COCHAPATA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "EL PROGRESO (CAB.EN ZHOTA) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "4",
    codigoParroquia: "53",
    nombreParroquia: "LAS NIEVES (CHAYA)",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "PAUTE",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "BULAN (JOSE VICTOR IZQUIERDO) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "CHICAN (GUILLERMO ORTEGA) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "5",
    codigoParroquia: "54",
    nombreParroquia: "EL CABO  ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "5",
    codigoParroquia: "56",
    nombreParroquia: "GUARAINAG",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "5",
    codigoParroquia: "59",
    nombreParroquia: "SAN CRISTOBAL (CARLOS ORDOÑEZ LAZO) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "5",
    codigoParroquia: "61",
    nombreParroquia: "TOMEBAMBA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "5",
    codigoParroquia: "62",
    nombreParroquia: "DUG DUG",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: " PUCARA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "SAN RAFAEL DE SHARUG",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: " SAN FERNANDO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "CHUMBLIN",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: " SANTA ISABEL",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "8",
    codigoParroquia: "51",
    nombreParroquia: "ABDON CALDERON  (LA UNION) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "8",
    codigoParroquia: "52",
    nombreParroquia: "EL CARMEN DE PIJILI",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "8",
    codigoParroquia: "53",
    nombreParroquia: "ZHAGLLI (SHAGLLI)",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: " SIGSIG",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "9",
    codigoParroquia: "51",
    nombreParroquia: "CUCHIL (CUTCHIL) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "9",
    codigoParroquia: "52",
    nombreParroquia: "JIMA (GIMA)",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "9",
    codigoParroquia: "53",
    nombreParroquia: "GUEL",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "9",
    codigoParroquia: "54",
    nombreParroquia: "LUDO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "9",
    codigoParroquia: "55",
    nombreParroquia: "SAN BARTOLOME",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "9",
    codigoParroquia: "56",
    nombreParroquia: "SAN JOSE DE RARANGA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "10",
    codigoParroquia: "50",
    nombreParroquia: "SAN FELIPE DE OÑA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "10",
    codigoParroquia: "51",
    nombreParroquia: "SUSUDEL",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "11",
    codigoParroquia: "50",
    nombreParroquia: "CHORDELEG",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "11",
    codigoParroquia: "51",
    nombreParroquia: "PRINCIPAL",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "11",
    codigoParroquia: "52",
    nombreParroquia: "LA UNION",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "11",
    codigoParroquia: "53",
    nombreParroquia: "LUIS GALARZA ORELLANA (CAB.EN DELEGSOL) ",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "11",
    codigoParroquia: "54",
    nombreParroquia: "SAN MARTIN DE PUZHIO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "12",
    codigoParroquia: "50",
    nombreParroquia: " EL PAN",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "12",
    codigoParroquia: "53",
    nombreParroquia: "SAN VICENTE",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "13",
    codigoParroquia: "50",
    nombreParroquia: " SEVILLA DE ORO",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "13",
    codigoParroquia: "51",
    nombreParroquia: "AMALUZA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "13",
    codigoParroquia: "52",
    nombreParroquia: "PALMAS",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "14",
    codigoParroquia: "50",
    nombreParroquia: " GUACHAPALA",
  },
  {
    codigoProvincia: "1",
    codigoCanton: "15",
    codigoParroquia: "50",
    nombreParroquia: "CAMILO PONCE ENRIQUEZ",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: " GUARANDA",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "FACUNDO VELA",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "JULIO E. MORENO (CATANAHUAN GRANDE)",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "SALINAS",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "SAN LORENZO",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "SAN SIMON (YACOTO)",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "SANTAFE (SANTA FE)",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "SIMIATUG",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "1",
    codigoParroquia: "60",
    nombreParroquia: "SAN LUIS DE PAMBIL",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: " CHILLANES",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "SAN JOSE DEL TAMBO (TAMBOPAMBA)",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "SAN JOSE DE CHIMBO",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "ASUNCION (ASANCOTO) ",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "MAGDALENA (CHAPACOTO)",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "SAN SEBASTIAN",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "3",
    codigoParroquia: "55",
    nombreParroquia: "TELIMBELA",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: " ECHEANDIA",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "SAN MIGUEL",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "BALSAPAMBA",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "BILOVAN",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "REGULO DE MORA",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "5",
    codigoParroquia: "54",
    nombreParroquia: "SAN PABLO  (SAN PABLO DE ATENAS)",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "5",
    codigoParroquia: "55",
    nombreParroquia: "SANTIAGO",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "5",
    codigoParroquia: "56",
    nombreParroquia: "SAN VICENTE",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: " CALUMA ",
  },
  {
    codigoProvincia: "2",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: " LAS NAVES",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "AZOGUES",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "COJITAMBO",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "GUAPAN",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "JAVIER LOYOLA (CHUQUIPATA) ",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "LUIS CORDERO",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "PINDILIG",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "RIVERA",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "SAN MIGUEL",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "1",
    codigoParroquia: "60",
    nombreParroquia: "TADAY",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: " BIBLIAN",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "NAZON (CAB. EN PAMPA DE DOMINGUEZ)",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "SAN FRANCISCO DE SAGEO",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "TURUPAMBA",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "JERUSALEN",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "CAÑAR",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "CHONTAMARCA",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "CHOROCOPTE",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "GENERAL MORALES (SOCARTE) ",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "GUALLETURO",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "55",
    nombreParroquia: "HONORATO VASQUEZ (TAMBO VIEJO) ",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "56",
    nombreParroquia: "INGAPIRCA",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "57",
    nombreParroquia: "JUNCAL",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "58",
    nombreParroquia: "SAN ANTONIO",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "61",
    nombreParroquia: "ZHUD",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "62",
    nombreParroquia: "VENTURA",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "3",
    codigoParroquia: "63",
    nombreParroquia: "DUCUR",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: " LA TRONCAL",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "MANUEL J. CALLE",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "PANCHO NEGRO",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "EL TAMBO ",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: " DELEG",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "SOLANO",
  },
  {
    codigoProvincia: "3",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "SUSCAL",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: " TULCAN",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "EL CARMELO (EL PUN) ",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "JULIO ANDRADE (OREJUELA) ",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "MALDONADO",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "PIOTER",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "TOBAR DONOSO (LA BOCANA DE CAMUMBI) ",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "TUFIÑO",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "URBINA (TAYA)",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "EL CHICAL",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "1",
    codigoParroquia: "61",
    nombreParroquia: "SANTA MARTHA DE CUBA",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: " BOLIVAR",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "GARCIA MORENO",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "LOS ANDES",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "MONTE OLIVO",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "SAN VICENTE DE PUSIR",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "2",
    codigoParroquia: "55",
    nombreParroquia: "SAN RAFAEL",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "EL ANGEL",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "EL GOALTAL",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "LA LIBERTAD (ALIZO)",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "SAN ISIDRO",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "MIRA (CHONTAHUASI)",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "CONCEPCION",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "JIJON Y CAAMAÑO (CAB. EN RIO BLANCO)",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "4",
    codigoParroquia: "53",
    nombreParroquia: "JUAN MONTALVO (SAN IGNACIO DE QUIL)",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "SAN GABRIEL",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "CRISTOBAL COLON",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "CHITAN DE NAVARRETE",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "FERNANDEZ SALVADOR",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "5",
    codigoParroquia: "54",
    nombreParroquia: "LA PAZ",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "5",
    codigoParroquia: "55",
    nombreParroquia: "PIARTAL",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "HUACA",
  },
  {
    codigoProvincia: "4",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "MARISCAL SUCRE",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "LATACUNGA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "ALAQUES (ALAQUEZ) ",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "BELISARIO QUEVEDO (GUANAILIN)",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "GUAITACAMA (GUAYTACAMA) ",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "JOSEGUANGO BAJO",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "MULALO",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "11 DE NOVIEMBRE (ILINCHISI)",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "POALO",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "SAN JUAN DE PASTOCALLE",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "61",
    nombreParroquia: "TANICUCHI",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "1",
    codigoParroquia: "62",
    nombreParroquia: "TOACASO",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "LA MANA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "GUASAGANDA (CAB.EN GUASAGANDA CENTRO)",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "PUCAYACU",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "EL CORAZON",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "MORASPUNGO",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "PINLLOPATA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "RAMON CAMPAÑA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "PUJILI",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "ANGAMARCA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "4",
    codigoParroquia: "53",
    nombreParroquia: "GUANGAJE",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "4",
    codigoParroquia: "55",
    nombreParroquia: "LA VICTORIA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "4",
    codigoParroquia: "56",
    nombreParroquia: "PILALO",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "4",
    codigoParroquia: "57",
    nombreParroquia: "TINGO",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "4",
    codigoParroquia: "58",
    nombreParroquia: "ZUMBAHUA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "SAN MIGUEL",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "ANTONIO JOSE HOLGUIN  (SANTA LUCIA)",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "CUSUBAMBA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "MULALILLO",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "5",
    codigoParroquia: "54",
    nombreParroquia: "MULLIQUINDIL (SANTA ANA)",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "5",
    codigoParroquia: "55",
    nombreParroquia: "PANSALEO",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "SAQUISILI, ",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "CANCHAGUA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "CHANTILIN",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "6",
    codigoParroquia: "53",
    nombreParroquia: "COCHAPAMBA",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "SIGCHOS",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "CHUGCHILLAN",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "7",
    codigoParroquia: "52",
    nombreParroquia: "ISINLIVI",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "7",
    codigoParroquia: "53",
    nombreParroquia: "LAS PAMPAS",
  },
  {
    codigoProvincia: "5",
    codigoCanton: "7",
    codigoParroquia: "54",
    nombreParroquia: "PALO QUEMADO",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "RIOBAMBA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "CACHA (CAB. EN MACHANGARA) ",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "CALPI",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "CUBIJIES",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "FLORES",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "LICAN",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "LICTO",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "PUNGALA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "PUNIN",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "QUIMIAG",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "60",
    nombreParroquia: "SAN JUAN",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "1",
    codigoParroquia: "61",
    nombreParroquia: "SAN LUIS",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "ALAUSI",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "ACHUPALLAS",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "GUASUNTOS",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "HUIGRA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "55",
    nombreParroquia: "MULTITUD",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "56",
    nombreParroquia: "PISTISHI (NARIZ DEL DIABLO)",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "57",
    nombreParroquia: "PUMALLACTA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "58",
    nombreParroquia: "SEVILLA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "59",
    nombreParroquia: "SIBAMBE",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "2",
    codigoParroquia: "60",
    nombreParroquia: "TIXAN",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "VILLA LA UNION (CAJABAMBA)",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "CAÑI",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "COLUMBE",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "JUAN DE VELASCO (PANGOR)",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "SANTIAGO DE QUITO (CAB. EN SAN ANTONIO DE QUITO)",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "CHAMBO",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "CHUNCHI",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "CAPZOL",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "COMPUD",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "GONZOL",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "5",
    codigoParroquia: "54",
    nombreParroquia: "LLAGOS",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "GUAMOTE",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "CEBADAS",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "PALMIRA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "GUANO",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "GUANANDO",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "52",
    nombreParroquia: "ILAPO",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "53",
    nombreParroquia: "LA PROVIDENCIA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "54",
    nombreParroquia: "SAN ANDRES",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "55",
    nombreParroquia: "SAN GERARDO DE PACAICAGUAN",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "56",
    nombreParroquia: "SAN ISIDRO DE PATULU",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "57",
    nombreParroquia: "SAN JOSE DEL CHAZO",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "58",
    nombreParroquia: "SANTA FE DE GALAN",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "7",
    codigoParroquia: "59",
    nombreParroquia: "VALPARAISO",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "PALLATANGA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "PENIPE",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "9",
    codigoParroquia: "51",
    nombreParroquia: "EL ALTAR",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "9",
    codigoParroquia: "52",
    nombreParroquia: "MATUS",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "9",
    codigoParroquia: "53",
    nombreParroquia: "PUELA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "9",
    codigoParroquia: "54",
    nombreParroquia: "SAN ANTONIO DE BAYUSHIG",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "9",
    codigoParroquia: "55",
    nombreParroquia: "LA CANDELARIA",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "9",
    codigoParroquia: "56",
    nombreParroquia: "BILBAO (CAB.EN QUILLUYACU)",
  },
  {
    codigoProvincia: "6",
    codigoCanton: "10",
    codigoParroquia: "50",
    nombreParroquia: "CUMANDA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "MACHALA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "EL RETIRO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "ARENILLAS",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "CHACRAS",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "PALMALES",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "2",
    codigoParroquia: "55",
    nombreParroquia: "CARCABON",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "PACCHA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "AYAPAMBA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "CORDONCILLO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "MILAGRO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "SAN JOSE",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "3",
    codigoParroquia: "55",
    nombreParroquia: "SAN JUAN DE CERRO AZUL",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "BALSAS",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "BELLAMARIA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "CHILLA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "EL GUABO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "BARBONES (SUCRE) ",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "LA IBERIA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "6",
    codigoParroquia: "53",
    nombreParroquia: "TENDALES (CAB.EN PUERTO TENDALES)",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "6",
    codigoParroquia: "54",
    nombreParroquia: "RIO BONITO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "HUAQUILLAS",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "MARCABELI",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "8",
    codigoParroquia: "51",
    nombreParroquia: "EL INGENIO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "PASAJE",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "9",
    codigoParroquia: "51",
    nombreParroquia: "BUENAVISTA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "9",
    codigoParroquia: "52",
    nombreParroquia: "CASACAY",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "9",
    codigoParroquia: "53",
    nombreParroquia: "LA PEAÑA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "9",
    codigoParroquia: "54",
    nombreParroquia: "PROGRESO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "9",
    codigoParroquia: "55",
    nombreParroquia: "UZHCURRUMI",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "9",
    codigoParroquia: "56",
    nombreParroquia: "CAÑAQUEMADA ",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "10",
    codigoParroquia: "50",
    nombreParroquia: "PIÑAS",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "10",
    codigoParroquia: "51",
    nombreParroquia: "CAPIRO (CAB. EN LA CAPILLA DE CAPIRO)",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "10",
    codigoParroquia: "52",
    nombreParroquia: "LA BOCANA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "10",
    codigoParroquia: "53",
    nombreParroquia: "MOROMORO (CAB. EN EL VADO)",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "10",
    codigoParroquia: "54",
    nombreParroquia: "PIEDRAS",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "10",
    codigoParroquia: "55",
    nombreParroquia: "SAN ROQUE (AMBROSIO MALDONADO)",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "10",
    codigoParroquia: "56",
    nombreParroquia: "SARACAY",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "11",
    codigoParroquia: "50",
    nombreParroquia: "PORTOVELO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "11",
    codigoParroquia: "51",
    nombreParroquia: "CURTINCAPA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "11",
    codigoParroquia: "52",
    nombreParroquia: "MORALES",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "11",
    codigoParroquia: "53",
    nombreParroquia: "SALATI",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "12",
    codigoParroquia: "50",
    nombreParroquia: "SANTA ROSA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "12",
    codigoParroquia: "51",
    nombreParroquia: "BELLAVISTA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "12",
    codigoParroquia: "52",
    nombreParroquia: "JAMBELI",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "12",
    codigoParroquia: "53",
    nombreParroquia: "LA AVANZADA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "12",
    codigoParroquia: "54",
    nombreParroquia: "SAN ANTONIO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "12",
    codigoParroquia: "55",
    nombreParroquia: "TORATA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "12",
    codigoParroquia: "56",
    nombreParroquia: "VICTORIA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "12",
    codigoParroquia: "57",
    nombreParroquia: "BELLAMARIA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "50",
    nombreParroquia: "ZARUMA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "51",
    nombreParroquia: "ABAÑIN",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "52",
    nombreParroquia: "ARCAPAMBA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "53",
    nombreParroquia: "GUANAZAN",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "54",
    nombreParroquia: "GUIZHAGUIÑA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "55",
    nombreParroquia: "HUERTAS",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "56",
    nombreParroquia: "MALVAS",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "57",
    nombreParroquia: "MULUNCAY GRANDE",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "58",
    nombreParroquia: "SINSAO",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "13",
    codigoParroquia: "59",
    nombreParroquia: "SALVIAS",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "14",
    codigoParroquia: "50",
    nombreParroquia: "LA VICTORIA",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "14",
    codigoParroquia: "51",
    nombreParroquia: "LA LIBERTAD",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "14",
    codigoParroquia: "52",
    nombreParroquia: "EL PARAISO ",
  },
  {
    codigoProvincia: "7",
    codigoCanton: "14",
    codigoParroquia: "53",
    nombreParroquia: "SAN ISIDRO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "ESMERALDAS",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "CAMARONES (CAB. EN SAN VICENTE) ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "CRNEL. CARLOS CONCHA TORRES (CAB.EN HUELE)",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "CHINCA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "MAJUA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "1",
    codigoParroquia: "63",
    nombreParroquia: "SAN MATEO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "1",
    codigoParroquia: "65",
    nombreParroquia: "TABIAZO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "1",
    codigoParroquia: "66",
    nombreParroquia: "TACHINA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "1",
    codigoParroquia: "68",
    nombreParroquia: "VUELTA LARGA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "VALDEZ (LIMONES)",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "ANCHAYACU",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "ATAHUALPA (CAB. EN CAMARONES) ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "BORBON",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "LA TOLA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "55",
    nombreParroquia: "LUIS VARGAS TORRES (CAB. EN PLAYA DE ORO)",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "56",
    nombreParroquia: "MALDONADO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "57",
    nombreParroquia: "PAMPANAL DE BOLIVAR     ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "58",
    nombreParroquia: "SAN FRANCISCO DE ONZOLE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "59",
    nombreParroquia: "SANTO DOMINGO DE ONZOLE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "60",
    nombreParroquia: "SELVA ALEGRE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "61",
    nombreParroquia: "TELEMBI",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "62",
    nombreParroquia: "COLON ELOY DEL MARIA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "63",
    nombreParroquia: "SAN JOSE DE CAYAPAS",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "2",
    codigoParroquia: "64",
    nombreParroquia: "TIMBIRE  ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "MUISNE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "BOLIVAR",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "DAULE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "GALERA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "QUINGUE (OLMEDO PERDOMO FRANCO) ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "3",
    codigoParroquia: "55",
    nombreParroquia: "SALIMA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "3",
    codigoParroquia: "56",
    nombreParroquia: "SAN FRANCISCO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "3",
    codigoParroquia: "57",
    nombreParroquia: "SAN GREGORIO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "3",
    codigoParroquia: "58",
    nombreParroquia: "SAN JOSE DE CHAMANGA (CAB.EN CHAMANGA)",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "ROSA ZARATE (QUININDE)",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "CUBE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "CHURA (CHANCAMA) (CAB. EN EL YERBERO)",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "4",
    codigoParroquia: "53",
    nombreParroquia: "MALIMPIA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "4",
    codigoParroquia: "54",
    nombreParroquia: "VICHE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "4",
    codigoParroquia: "55",
    nombreParroquia: "LA UNION",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "SAN LORENZO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "ALTO TAMBO (CAB. EN GUADUAL) ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "ANCON (PICHANGAL) (CAB. EN PALMA REAL) ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "CALDERON",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "54",
    nombreParroquia: "CARONDELET",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "55",
    nombreParroquia: "5 DE JUNIO (CAB. EN UIMBI) ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "56",
    nombreParroquia: "CONCEPCION",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "57",
    nombreParroquia: "MATAJE (CAB. EN SANTANDER)",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "58",
    nombreParroquia: "SAN JAVIER DE CACHAVI (CAB. EN SAN JAVIER) ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "59",
    nombreParroquia: "SANTA RITA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "60",
    nombreParroquia: "TAMBILLO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "61",
    nombreParroquia: "TULULBI (CAB. EN RICAURTE)",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "5",
    codigoParroquia: "62",
    nombreParroquia: "URBINA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "ATACAMES",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "LA UNION",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "SUA  (CAB. EN LA BOCANA) ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "6",
    codigoParroquia: "53",
    nombreParroquia: "TONCHIGÜE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "6",
    codigoParroquia: "54",
    nombreParroquia: "TONSUPA",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "RIOVERDE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "CHONTADURO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "7",
    codigoParroquia: "52",
    nombreParroquia: "CHUMUNDE ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "7",
    codigoParroquia: "53",
    nombreParroquia: "LAGARTO",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "7",
    codigoParroquia: "54",
    nombreParroquia: "MONTALVO (CAB. EN HORQUETA) ",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "7",
    codigoParroquia: "55",
    nombreParroquia: "ROCAFUERTE",
  },
  {
    codigoProvincia: "8",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "LA CONCORDIA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "GUAYAQUIL",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "JUAN GOMEZ RENDON (PROGRESO) ",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "MORRO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "POSORJA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "PUNA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "TENGUEL",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "ALFREDO BAQUERIZO MORENO (JUJAN)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "BALAO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "BALZAR",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "COLIMES",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "SAN JACINTO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "DAULE",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "JUAN BAUTISTA AGUIRRE (LOS TINTOS) ",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "6",
    codigoParroquia: "53",
    nombreParroquia: "LAUREL",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "6",
    codigoParroquia: "54",
    nombreParroquia: "LIMONAL",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "6",
    codigoParroquia: "56",
    nombreParroquia: "LOS LOJAS (ENRIQUE BAQUERIZO MORENO)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "ELOY ALFARO (DURAN)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "VELASCO IBARRA (EL EMPALME)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "8",
    codigoParroquia: "51",
    nombreParroquia: "GUAYAS (PUEBLO NUEVO) ",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "8",
    codigoParroquia: "52",
    nombreParroquia: "EL ROSARIO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "EL TRIUNFO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "10",
    codigoParroquia: "50",
    nombreParroquia: "MILAGRO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "10",
    codigoParroquia: "51",
    nombreParroquia: "CHOBO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "10",
    codigoParroquia: "53",
    nombreParroquia: "MARISCAL SUCRE (HUAQUES)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "10",
    codigoParroquia: "54",
    nombreParroquia: "ROBERTO ASTUDILLO (CAB. EN CRUCE DE VENECIA) ",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "11",
    codigoParroquia: "50",
    nombreParroquia: "NARANJAL",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "11",
    codigoParroquia: "51",
    nombreParroquia: "JESUS MARIA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "11",
    codigoParroquia: "52",
    nombreParroquia: "SAN CARLOS",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "11",
    codigoParroquia: "53",
    nombreParroquia: "SANTA ROSA DE FLANDES",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "11",
    codigoParroquia: "54",
    nombreParroquia: "TAURA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "12",
    codigoParroquia: "50",
    nombreParroquia: "NARANJITO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "13",
    codigoParroquia: "50",
    nombreParroquia: "PALESTINA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "14",
    codigoParroquia: "50",
    nombreParroquia: "PEDRO CARBO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "14",
    codigoParroquia: "51",
    nombreParroquia: "VALLE DE LA VIRGEN ",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "14",
    codigoParroquia: "52",
    nombreParroquia: "SABANILLA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "16",
    codigoParroquia: "50",
    nombreParroquia: "SAMBORONDON",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "16",
    codigoParroquia: "51",
    nombreParroquia: "TARIFA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "18",
    codigoParroquia: "50",
    nombreParroquia: "SANTA LUCIA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "19",
    codigoParroquia: "50",
    nombreParroquia: "EL SALITRE (LAS RAMAS)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "19",
    codigoParroquia: "51",
    nombreParroquia: "GRAL. VERNAZA (DOS ESTEROS) ",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "19",
    codigoParroquia: "52",
    nombreParroquia: "LA VICTORIA (ÑAUZA)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "19",
    codigoParroquia: "53",
    nombreParroquia: "JUNQUILLAL",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "20",
    codigoParroquia: "50",
    nombreParroquia: "SAN JACINTO DE YAGUACHI",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "20",
    codigoParroquia: "53",
    nombreParroquia: "GRAL. PEDRO J. MONTERO (BOLICHE) ",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "20",
    codigoParroquia: "55",
    nombreParroquia: "YAGUACHI VIEJO (CONE)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "20",
    codigoParroquia: "56",
    nombreParroquia: "VIRGEN DE FATIMA",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "21",
    codigoParroquia: "50",
    nombreParroquia: "GENERAL VILLAMIL (PLAYAS)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "22",
    codigoParroquia: "50",
    nombreParroquia: "SIMON BOLIVAR",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "22",
    codigoParroquia: "51",
    nombreParroquia: "CRNEL.LORENZO DE GARAICOA (PEDREGAL)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "23",
    codigoParroquia: "50",
    nombreParroquia: "CORONEL MARCELINO MARIDUEÑA (SAN CARLOS)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "24",
    codigoParroquia: "50",
    nombreParroquia: "LOMAS DE SARGENTILLO",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "25",
    codigoParroquia: "50",
    nombreParroquia: "NARCISA DE JESUS",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "27",
    codigoParroquia: "50",
    nombreParroquia: "GENERAL ANTONIO ELIZALDE (BUCAY)",
  },
  {
    codigoProvincia: "9",
    codigoCanton: "28",
    codigoParroquia: "50",
    nombreParroquia: "ISIDRO AYORA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "SAN MIGUEL DE IBARRA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "AMBUQUI",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "ANGOCHAGUA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "CAROLINA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "LA ESPERANZA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "LITA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "SALINAS",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "SAN ANTONIO",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "ATUNTAQUI",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "IMBAYA (SAN LUIS DE COBUENDO) ",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "SAN FRANCISCO DE NATABUELA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "SAN JOSE DE CHALTURA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "SAN ROQUE",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "COTACACHI",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "APUELA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "GARCIA MORENO  (LLURIMAGUA) ",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "IMANTAG",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "PEÑAHERRERA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "3",
    codigoParroquia: "55",
    nombreParroquia: "PLAZA GUTIERREZ",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "3",
    codigoParroquia: "56",
    nombreParroquia: "QUIROGA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "3",
    codigoParroquia: "57",
    nombreParroquia: "6 DE JULIO DE CUELLAJE (CAB. EN CUELLAJE)",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "3",
    codigoParroquia: "58",
    nombreParroquia: "VACAS GALINDO (EL CHURO) (CAB.EN SAN MIGUEL ALTO)",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "OTAVALO",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "DR. MIGUEL EGAS CABEZAS (PEGUCHE) ",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "EUGENIO ESPEJO (CALPAQUI) ",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "53",
    nombreParroquia: "GONZALEZ SUAREZ",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "54",
    nombreParroquia: "PATAQUI",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "55",
    nombreParroquia: "SAN JOSE DE QUICHINCHE ",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "56",
    nombreParroquia: "SAN JUAN DE ILUMAN",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "57",
    nombreParroquia: "SAN PABLO",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "58",
    nombreParroquia: "SAN RAFAEL",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "4",
    codigoParroquia: "59",
    nombreParroquia: "SELVA ALEGRE (CAB.EN SAN MIGUEL DE PAMPLONA)",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "PIMAMPIRO",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "CHUGA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "MARIANO ACOSTA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "SAN FRANCISCO DE SIGSIPAMBA",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "URCUQUI",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "CAHUASQUI",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "LA MERCED DE BUENOS AIRES",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "6",
    codigoParroquia: "53",
    nombreParroquia: "PABLO ARENAS",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "6",
    codigoParroquia: "54",
    nombreParroquia: "SAN BLAS",
  },
  {
    codigoProvincia: "10",
    codigoCanton: "6",
    codigoParroquia: "55",
    nombreParroquia: "TUMBABIRO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "LOJA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "CHANTACO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "CHUQUIRIBAMBA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "EL CISNE",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "GUALEL",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "JIMBILLA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "MALACATOS (VALLADOLID) ",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "SAN LUCAS",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "SAN PEDRO DE VILCABAMBA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "SANTIAGO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "60",
    nombreParroquia: "TAQUIL (MIGUEL RIOFRIO)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "61",
    nombreParroquia: "VILCABAMBA  (VICTORIA)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "62",
    nombreParroquia: "YANGANA (ARSENIO CASTILLO)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "1",
    codigoParroquia: "63",
    nombreParroquia: "QUINARA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "CARIAMANGA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "COLAISACA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "EL LUCERO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "UTUANA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "SANGUILLIN",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "CATAMAYO (LA TOMA)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "EL TAMBO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "GUAYQUICHUMA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "SAN PEDRO DE LA BENDITA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "ZAMBI",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "CELICA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "CRUZPAMBA (CAB. EN CARLOS BUSTAMANTE)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "4",
    codigoParroquia: "55",
    nombreParroquia: "POZUL (SAN JUAN DE POZUL)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "4",
    codigoParroquia: "56",
    nombreParroquia: "SABANILLA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "4",
    codigoParroquia: "57",
    nombreParroquia: "TNTE. MAXIMILIANO RODRIGUEZ LOAIZA ",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "CHAGUARPAMBA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "BUENAVISTA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "EL ROSARIO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "SANTA RUFINA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "5",
    codigoParroquia: "54",
    nombreParroquia: "AMARILLOS",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "AMALUZA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "BELLAVISTA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "JIMBURA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "6",
    codigoParroquia: "53",
    nombreParroquia: "SANTA TERESITA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "6",
    codigoParroquia: "54",
    nombreParroquia: "27 DE ABRIL (CAB. EN LA NARANJA) ",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "6",
    codigoParroquia: "55",
    nombreParroquia: "EL INGENIO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "6",
    codigoParroquia: "56",
    nombreParroquia: "EL AIRO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "GONZANAMA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "CHANGAIMINA (LA LIBERTAD) ",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "7",
    codigoParroquia: "53",
    nombreParroquia: "NAMBACOLA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "7",
    codigoParroquia: "54",
    nombreParroquia: "PURUNUMA (EGUIGUREN) ",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "7",
    codigoParroquia: "56",
    nombreParroquia: "SACAPALCA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "MACARA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "8",
    codigoParroquia: "51",
    nombreParroquia: "LARAMA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "8",
    codigoParroquia: "52",
    nombreParroquia: "LA VICTORIA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "8",
    codigoParroquia: "53",
    nombreParroquia: "SABIANGO (LA CAPILLA)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "CATACOCHA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "9",
    codigoParroquia: "51",
    nombreParroquia: "CANGONAMA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "9",
    codigoParroquia: "52",
    nombreParroquia: "GUACHANAMA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "9",
    codigoParroquia: "54",
    nombreParroquia: "LAURO GUERRERO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "9",
    codigoParroquia: "56",
    nombreParroquia: "ORIANGA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "9",
    codigoParroquia: "57",
    nombreParroquia: "SAN ANTONIO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "9",
    codigoParroquia: "58",
    nombreParroquia: "CASANGA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "9",
    codigoParroquia: "59",
    nombreParroquia: "YAMANA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "10",
    codigoParroquia: "50",
    nombreParroquia: "ALAMOR",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "10",
    codigoParroquia: "51",
    nombreParroquia: "CIANO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "10",
    codigoParroquia: "52",
    nombreParroquia: "EL ARENAL",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "10",
    codigoParroquia: "53",
    nombreParroquia: "EL LIMO (MARIANA DE JESUS)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "10",
    codigoParroquia: "54",
    nombreParroquia: "MERCADILLO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "10",
    codigoParroquia: "55",
    nombreParroquia: "VICENTINO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "50",
    nombreParroquia: "SARAGURO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "51",
    nombreParroquia: "EL PARAISO DE CELEN",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "52",
    nombreParroquia: "EL TABLON  ",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "53",
    nombreParroquia: "LLUZHAPA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "54",
    nombreParroquia: "MANU",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "55",
    nombreParroquia: "SAN ANTONIO DE QUMBE (CUMBE)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "56",
    nombreParroquia: "SAN PABLO DE TENTA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "57",
    nombreParroquia: "SAN SEBASTIAN DE YULUC",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "58",
    nombreParroquia: "SELVA ALEGRE",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "59",
    nombreParroquia: "URDANETA (PAQUISHAPA)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "11",
    codigoParroquia: "60",
    nombreParroquia: "SUMAYPAMBA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "12",
    codigoParroquia: "50",
    nombreParroquia: "SOZORANGA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "12",
    codigoParroquia: "51",
    nombreParroquia: "NUEVA FATIMA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "12",
    codigoParroquia: "52",
    nombreParroquia: "TACAMOROS",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "13",
    codigoParroquia: "50",
    nombreParroquia: "ZAPOTILLO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "13",
    codigoParroquia: "51",
    nombreParroquia: "CAZADEROS (CAB.EN MANGAURCO) ",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "13",
    codigoParroquia: "52",
    nombreParroquia: "GARZAREAL",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "13",
    codigoParroquia: "53",
    nombreParroquia: "LIMONES",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "13",
    codigoParroquia: "54",
    nombreParroquia: "PALETILLAS",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "13",
    codigoParroquia: "55",
    nombreParroquia: "BOLASPAMBA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "14",
    codigoParroquia: "50",
    nombreParroquia: "PINDAL",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "14",
    codigoParroquia: "51",
    nombreParroquia: "CHAQUINAL",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "14",
    codigoParroquia: "52",
    nombreParroquia: "12 DE DICIEMBRE (CAB.EN ACHIOTES)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "15",
    codigoParroquia: "50",
    nombreParroquia: "QUILANGA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "15",
    codigoParroquia: "51",
    nombreParroquia: "FUNDOCHAMBA",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "15",
    codigoParroquia: "52",
    nombreParroquia: "SAN ANTONIO DE LAS ARADAS (CAB. EN LAS ARADAS)",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "16",
    codigoParroquia: "50",
    nombreParroquia: "OLMEDO",
  },
  {
    codigoProvincia: "11",
    codigoCanton: "16",
    codigoParroquia: "51",
    nombreParroquia: "LA TINGUE",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "BABAHOYO",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "CARACOL",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "FEBRES CORDERO (LAS JUNTAS) (CAB. EN MATA DE CACAO)",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "PIMOCHA",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "LA UNION",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "BABA",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "GUARE",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "ISLA DE BEJUCAL",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "MONTALVO",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "PUEBLOVIEJO",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "PUERTO PECHICHE",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "SAN JUAN",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "QUEVEDO",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "SAN CARLOS",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "5",
    codigoParroquia: "55",
    nombreParroquia: "LA ESPERANZA",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "CATARAMA",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "RICAURTE",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "VENTANAS",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "7",
    codigoParroquia: "52",
    nombreParroquia: "ZAPOTAL",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "VINCES",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "8",
    codigoParroquia: "51",
    nombreParroquia: "ANTONIO SOTOMAYOR (CAB. EN PLAYAS DE VINCES) ",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "PALENQUE",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "10",
    codigoParroquia: "50",
    nombreParroquia: "SAN JACINTO DE BUENA FE",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "10",
    codigoParroquia: "51",
    nombreParroquia: "PATRICIA PILAR",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "11",
    codigoParroquia: "50",
    nombreParroquia: "VALENCIA",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "12",
    codigoParroquia: "50",
    nombreParroquia: "MOCACHE",
  },
  {
    codigoProvincia: "12",
    codigoCanton: "13",
    codigoParroquia: "50",
    nombreParroquia: "QUINSALOMA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "PORTOVIEJO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "ABDON CALDERON (SAN FRANCISCO)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "ALHAJUELA (BAJO GRANDE) ",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "CRUCITA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "PUEBLO NUEVO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "RIOCHICO (RIO CHICO)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "SAN PLACIDO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "CHIRIJOS",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "CALCETA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "MEMBRILLO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "QUIROGA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "CHONE",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "BOYACA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "CANUTO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "CONVENTO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "CHIBUNGA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "3",
    codigoParroquia: "55",
    nombreParroquia: "ELOY ALFARO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "3",
    codigoParroquia: "56",
    nombreParroquia: "RICAURTE",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "3",
    codigoParroquia: "57",
    nombreParroquia: "SAN ANTONIO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "EL CARMEN",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "WILFRIDO LOOR MOREIRA (MAICITO)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "SAN PEDRO DE SUMA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "FLAVIO ALFARO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "SAN FRANCISCO DE NOVILLO (CAB. EN  NOVILLO)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "ZAPALLO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "JIPIJAPA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "AMERICA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "EL ANEGADO (CAB. EN ELOY ALFARO)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "6",
    codigoParroquia: "53",
    nombreParroquia: "JULCUY",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "6",
    codigoParroquia: "54",
    nombreParroquia: "LA UNION",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "6",
    codigoParroquia: "56",
    nombreParroquia: "MEMBRILLAL",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "6",
    codigoParroquia: "57",
    nombreParroquia: "PEDRO PABLO GOMEZ",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "6",
    codigoParroquia: "58",
    nombreParroquia: "PUERTO DE CAYO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "JUNIN",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "MANTA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "8",
    codigoParroquia: "51",
    nombreParroquia: "SAN LORENZO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "8",
    codigoParroquia: "52",
    nombreParroquia: "SANTA MARIANITA (BOCA DE PACOCHE)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "MONTECRISTI",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "9",
    codigoParroquia: "52",
    nombreParroquia: "LA PILA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "10",
    codigoParroquia: "50",
    nombreParroquia: "PAJAN",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "10",
    codigoParroquia: "51",
    nombreParroquia: "CAMPOZANO (LA PALMA DE PAJAN)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "10",
    codigoParroquia: "52",
    nombreParroquia: "CASCOL",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "10",
    codigoParroquia: "53",
    nombreParroquia: "GUALE",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "10",
    codigoParroquia: "54",
    nombreParroquia: "LASCANO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "11",
    codigoParroquia: "50",
    nombreParroquia: "PICHINCHA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "11",
    codigoParroquia: "51",
    nombreParroquia: "BARRAGANETE ",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "11",
    codigoParroquia: "52",
    nombreParroquia: "SAN SEBASTIAN",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "12",
    codigoParroquia: "50",
    nombreParroquia: "ROCAFUERTE",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "13",
    codigoParroquia: "50",
    nombreParroquia: "SANTA ANA DE VUELTA LARGA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "13",
    codigoParroquia: "51",
    nombreParroquia: "AYACUCHO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "13",
    codigoParroquia: "52",
    nombreParroquia: "HONORATO VASQUEZ (CAB. EN VASQUEZ)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "13",
    codigoParroquia: "53",
    nombreParroquia: "LA UNION",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "13",
    codigoParroquia: "55",
    nombreParroquia: "SAN PABLO (CAB. EN PUEBLO NUEVO)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "14",
    codigoParroquia: "50",
    nombreParroquia: "BAHIA DE CARAQUEZ",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "14",
    codigoParroquia: "53",
    nombreParroquia: "CHARAPOTO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "14",
    codigoParroquia: "57",
    nombreParroquia: "SAN ISIDRO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "15",
    codigoParroquia: "50",
    nombreParroquia: "TOSAGUA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "15",
    codigoParroquia: "51",
    nombreParroquia: "BACHILLERO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "15",
    codigoParroquia: "52",
    nombreParroquia: "ANGEL PEDRO GILER (LA ESTANCILLA)",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "16",
    codigoParroquia: "50",
    nombreParroquia: "SUCRE",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "16",
    codigoParroquia: "51",
    nombreParroquia: "BELLAVISTA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "16",
    codigoParroquia: "52",
    nombreParroquia: "NOBOA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "16",
    codigoParroquia: "53",
    nombreParroquia: "ARQ. SIXTO DURAN BALLEN",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "17",
    codigoParroquia: "50",
    nombreParroquia: "PEDERNALES",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "17",
    codigoParroquia: "51",
    nombreParroquia: "COJIMIES",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "17",
    codigoParroquia: "52",
    nombreParroquia: "10 DE AGOSTO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "17",
    codigoParroquia: "53",
    nombreParroquia: "ATAHUALPA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "18",
    codigoParroquia: "50",
    nombreParroquia: "OLMEDO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "19",
    codigoParroquia: "50",
    nombreParroquia: "PUERTO LOPEZ",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "19",
    codigoParroquia: "51",
    nombreParroquia: "MACHALILLA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "19",
    codigoParroquia: "52",
    nombreParroquia: "SALANGO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "20",
    codigoParroquia: "50",
    nombreParroquia: "JAMA",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "21",
    codigoParroquia: "50",
    nombreParroquia: "JARAMIJO",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "22",
    codigoParroquia: "50",
    nombreParroquia: "SAN VICENTE",
  },
  {
    codigoProvincia: "13",
    codigoCanton: "22",
    codigoParroquia: "51",
    nombreParroquia: "CANOA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "MACAS",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "ALSHI (CAB. EN 9 DE OCTUBRE) ",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "GENERAL PROAÑO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "SAN ISIDRO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "SEVILLA DON BOSCO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "SINAI",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "1",
    codigoParroquia: "60",
    nombreParroquia: "ZUÑA (ZUÑAC)",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "1",
    codigoParroquia: "62",
    nombreParroquia: "CUCHAENTZA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "1",
    codigoParroquia: "64",
    nombreParroquia: "RIO BLANCO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "GUALAQUIZA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "AMAZONAS (ROSARIO DE CUYES) ",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "BERMEJOS",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "BOMBOIZA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "CHIGÜINDA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "2",
    codigoParroquia: "55",
    nombreParroquia: "EL ROSARIO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "2",
    codigoParroquia: "56",
    nombreParroquia: "NUEVA TARQUI",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "2",
    codigoParroquia: "57",
    nombreParroquia: "SAN MIGUEL DE CUYES",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "2",
    codigoParroquia: "58",
    nombreParroquia: "EL IDEAL",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "GENERAL LEONIDAS PLAZA GUTIERREZ (LIMON)",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "INDANZA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "SAN ANTONIO (CAB. EN SAN ANTONIO CENTRO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "3",
    codigoParroquia: "56",
    nombreParroquia: "SAN MIGUEL DE CONCHAY",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "3",
    codigoParroquia: "57",
    nombreParroquia: "SANTA SUSANA DE CHIVIAZA (CAB. EN CHIVIAZA)",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "3",
    codigoParroquia: "58",
    nombreParroquia: "YUNGANZA (CAB. EN EL ROSARIO)",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "PALORA (METZERA)",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "ARAPICOS",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "CUMANDA (CAB. EN COLONIA AGRICOLA SEVILLA DEL ORO)",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "4",
    codigoParroquia: "54",
    nombreParroquia: "SANGAY (CAB. EN NAYAMANACA)",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "4",
    codigoParroquia: "55",
    nombreParroquia: "16 DE AGOSTO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "SANTIAGO DE MENDEZ",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "COPAL",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "CHUPIANZA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "PATUCA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "5",
    codigoParroquia: "54",
    nombreParroquia: "SAN LUIS DE EL ACHO (CAB. EN EL ACHO) ",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "5",
    codigoParroquia: "56",
    nombreParroquia: "TAYUZA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "5",
    codigoParroquia: "57",
    nombreParroquia: "SAN FRANCISCO DE CHINIMBIMI",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "SUCUA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "ASUNCION",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "HUAMBI",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "6",
    codigoParroquia: "55",
    nombreParroquia: "SANTA MARIANITA DE JESUS",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "HUAMBOYA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "CHIGUAZA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "SAN JUAN BOSCO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "8",
    codigoParroquia: "51",
    nombreParroquia: "PAN DE AZUCAR",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "8",
    codigoParroquia: "52",
    nombreParroquia: "SAN CARLOS DE LIMON  ",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "8",
    codigoParroquia: "53",
    nombreParroquia: "SAN JACINTO DE WAKAMBEIS ",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "8",
    codigoParroquia: "54",
    nombreParroquia: "SANTIAGO DE PANANZA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "TAISHA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "9",
    codigoParroquia: "51",
    nombreParroquia: "HUASAGA (CAB. EN WAMPUIK) ",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "9",
    codigoParroquia: "52",
    nombreParroquia: "MACUMA  ",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "9",
    codigoParroquia: "53",
    nombreParroquia: "TUUTINENTZA ",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "9",
    codigoParroquia: "54",
    nombreParroquia: "PUMPUENTSA",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "10",
    codigoParroquia: "50",
    nombreParroquia: "LOGROÑO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "10",
    codigoParroquia: "51",
    nombreParroquia: "YAUPI",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "10",
    codigoParroquia: "52",
    nombreParroquia: "SHIMPIS",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "11",
    codigoParroquia: "50",
    nombreParroquia: "PABLO SEXTO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "12",
    codigoParroquia: "50",
    nombreParroquia: "SANTIAGO",
  },
  {
    codigoProvincia: "14",
    codigoCanton: "12",
    codigoParroquia: "51",
    nombreParroquia: "SAN JOSE DE MORONA",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "TENA",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "AHUANO",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "CHONTAPUNTA",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "PANO",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "PUERTO MISAHUALLI",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "PUERTO NAPO",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "TALAG",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "ARCHIDONA",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "COTUNDO",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "SAN PABLO DE USHPAYACU",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "EL CHACO",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "GONZALO DIAZ DE PINEDA (EL BOMBON) ",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "LINARES",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "4",
    codigoParroquia: "53",
    nombreParroquia: "OYACACHI",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "4",
    codigoParroquia: "54",
    nombreParroquia: "SANTA ROSA",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "4",
    codigoParroquia: "55",
    nombreParroquia: "SARDINAS",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "BAEZA",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "COSANGA",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "7",
    codigoParroquia: "52",
    nombreParroquia: "CUYUJA",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "7",
    codigoParroquia: "53",
    nombreParroquia: "PAPALLACTA",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "7",
    codigoParroquia: "54",
    nombreParroquia: "SAN FRANCISCO DE BORJA (VIRGILIO DAVILA)",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "7",
    codigoParroquia: "56",
    nombreParroquia: "SUMACO",
  },
  {
    codigoProvincia: "15",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "CARLOS JULIO AROSEMENA TOLA",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "PUYO",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "CANELOS",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "DIEZ  DE AGOSTO",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "FATIMA",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "MONTALVO (ANDOAS) ",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "POMONA",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "RIO CORRIENTES ",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "RIO TIGRE",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "61",
    nombreParroquia: "SARAYACU",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "62",
    nombreParroquia: "SIMON BOLIVAR  (CAB. EN MUSHULLACTA)",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "63",
    nombreParroquia: "TARQUI",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "64",
    nombreParroquia: "TENIENTE HUGO ORTIZ",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "65",
    nombreParroquia: "VERACRUZ (INDILLAMA) (CAB. EN INDILLAMA)",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "1",
    codigoParroquia: "66",
    nombreParroquia: "EL TRIUNFO",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "MERA",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "MADRE TIERRA",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "SHELL",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "SANTA CLARA",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "SAN JOSE",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "ARAJUNO",
  },
  {
    codigoProvincia: "16",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "CURARAY",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia:
      "QUITO DISTRITO METROPOLITANO, CABECERA CANTONAL, CAPITAL PROVINCIAL Y DE LA REPUBLICA DEL ECUADOR",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "ALANGASI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "AMAGUAÑA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "ATAHUALPA (HABASPAMBA) ",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "CALACALI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "CALDERON (CARAPUNGO) ",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "CONOCOTO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "CUMBAYA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "CHAVEZPAMBA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "CHECA (CHILPA) ",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "60",
    nombreParroquia: "EL QUINCHE",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "61",
    nombreParroquia: "GUALEA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "62",
    nombreParroquia: "GUANGOPOLO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "63",
    nombreParroquia: "GUAYLLABAMBA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "64",
    nombreParroquia: "LA MERCED",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "65",
    nombreParroquia: "LLANO CHICO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "66",
    nombreParroquia: "LLOA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "68",
    nombreParroquia: "NANEGAL",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "69",
    nombreParroquia: "NANEGALITO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "70",
    nombreParroquia: "NAYON",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "71",
    nombreParroquia: "NONO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "72",
    nombreParroquia: "PACTO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "74",
    nombreParroquia: "PERUCHO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "75",
    nombreParroquia: "PIFO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "76",
    nombreParroquia: "PINTAG",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "77",
    nombreParroquia: "POMASQUI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "78",
    nombreParroquia: "PUELLARO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "79",
    nombreParroquia: "PUEMBO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "80",
    nombreParroquia: "SAN ANTONIO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "81",
    nombreParroquia: "SAN JOSE DE MINAS",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "83",
    nombreParroquia: "TABABELA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "84",
    nombreParroquia: "TUMBACO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "85",
    nombreParroquia: "YARUQUI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "1",
    codigoParroquia: "86",
    nombreParroquia: "ZAMBIZA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "CAYAMBE",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "ASCAZUBI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "CANGAHUA ",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "OLMEDO (PESILLO)",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "OTON",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "2",
    codigoParroquia: "55",
    nombreParroquia: "SANTA ROSA DE CUZUBAMBA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "MACHACHI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "ALOAG",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "ALOASI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "CUTUGLAHUA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "EL CHAUPI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "3",
    codigoParroquia: "55",
    nombreParroquia: "MANUEL CORNEJO ASTORGA (TANDAPI)",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "3",
    codigoParroquia: "56",
    nombreParroquia: "TAMBILLO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "3",
    codigoParroquia: "57",
    nombreParroquia: "UYUMBICHO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "TABACUNDO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "LA ESPERANZA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "MALCHINGUI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "4",
    codigoParroquia: "53",
    nombreParroquia: "TOCACHI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "4",
    codigoParroquia: "54",
    nombreParroquia: "TUPIGACHI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "SANGOLQUI",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "COTOGCHOA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "RUMIPAMBA",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "SAN MIGUEL DE LOS BANCOS ",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "MINDO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "PEDRO VICENTE MALDONADO",
  },
  {
    codigoProvincia: "17",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "PUERTO QUITO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "AMBATO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "AMBATILLO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "ATAHUALPA (CHISALATA) ",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "AUGUSTO N. MARTINEZ (MUNDUGLEO)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "CONSTANTINO FERNANDEZ (CAB. EN CULLITAHUA)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "HUACHI GRANDE",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "IZAMBA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "JUAN BENIGNO VELA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "MONTALVO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "PASA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "60",
    nombreParroquia: "PICAIGUA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "61",
    nombreParroquia: "PILAGÜIN (PILAHÜIN)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "62",
    nombreParroquia: "QUISAPINCHA (QUIZAPINCHA)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "63",
    nombreParroquia: "SAN BARTOLOME DE PINLLOG",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "64",
    nombreParroquia: "SAN FERNANDO (PASA SAN FERNANDO)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "65",
    nombreParroquia: "SANTA ROSA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "66",
    nombreParroquia: "TOTORAS",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "67",
    nombreParroquia: "CUNCHIBAMBA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "1",
    codigoParroquia: "68",
    nombreParroquia: "UNAMUNCHO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "BAÑOS DE AGUA SANTA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "LLIGUA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "RIO NEGRO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "RIO VERDE",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "ULBA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "CEVALLOS",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "MOCHA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "PINGUILI",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "PATATE",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "EL TRIUNFO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "LOS ANDES (CAB. EN POATUG) ",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "SUCRE (CAB. EN SUCRE-PATATE URCU)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "QUERO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "RUMIPAMBA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "YANAYACU - MOCHAPATA (CAB. EN YANAYACU) ",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "PELILEO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "BENITEZ (PACHANLICA) ",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "7",
    codigoParroquia: "52",
    nombreParroquia: "BOLIVAR",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "7",
    codigoParroquia: "53",
    nombreParroquia: "COTALO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "7",
    codigoParroquia: "54",
    nombreParroquia: "CHIQUICHA (CAB. EN CHIQUICHA GRANDE)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "7",
    codigoParroquia: "55",
    nombreParroquia: "EL ROSARIO (RUMICHACA)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "7",
    codigoParroquia: "56",
    nombreParroquia: "GARCIA MORENO (CHUMAQUI)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "7",
    codigoParroquia: "57",
    nombreParroquia: "GUAMBALO (HUAMBALO)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "7",
    codigoParroquia: "58",
    nombreParroquia: "SALASACA",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "PILLARO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "8",
    codigoParroquia: "51",
    nombreParroquia: "BAQUERIZO MORENO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "8",
    codigoParroquia: "52",
    nombreParroquia: "EMILIO MARIA TERAN (RUMIPAMBA)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "8",
    codigoParroquia: "53",
    nombreParroquia: "MARCOS ESPINEL (CHACATA) ",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "8",
    codigoParroquia: "54",
    nombreParroquia: "PRESIDENTE URBINA (CHAGRAPAMBA -PATZUCUL)",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "8",
    codigoParroquia: "55",
    nombreParroquia: "SAN ANDRES",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "8",
    codigoParroquia: "56",
    nombreParroquia: "SAN JOSE DE POALO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "8",
    codigoParroquia: "57",
    nombreParroquia: "SAN MIGUELITO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "TISALEO",
  },
  {
    codigoProvincia: "18",
    codigoCanton: "9",
    codigoParroquia: "51",
    nombreParroquia: "QUINCHICOTO",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "ZAMORA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "CUMBARATZA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "GUADALUPE",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "IMBANA (LA VICTORIA DE IMBANA)",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "SABANILLA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "TIMBARA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "SAN CARLOS DE LAS MINAS",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "ZUMBA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "CHITO",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "EL CHORRO",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "LA CHONTA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "2",
    codigoParroquia: "56",
    nombreParroquia: "PUCAPAMBA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "2",
    codigoParroquia: "59",
    nombreParroquia: "SAN ANDRES",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "GUAYZIMI",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "ZURMI",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "NUEVO PARAISO",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "28 DE MAYO (SAN JOSE DE YACUAMBI)",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "LA PAZ",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "TUTUPALI",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "YANTZAZA (YANZATZA)",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "CHICAÑA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "LOS ENCUENTROS",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "EL PANGUI",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "EL GUISME ",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "PACHICUTZA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "6",
    codigoParroquia: "53",
    nombreParroquia: "TUNDAYME",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "ZUMBI",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "8",
    codigoParroquia: "50",
    nombreParroquia: "PALANDA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "8",
    codigoParroquia: "51",
    nombreParroquia: "EL PORVENIR DEL CARMEN",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "8",
    codigoParroquia: "52",
    nombreParroquia: "SAN FRANCISCO DEL VERGEL",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "8",
    codigoParroquia: "53",
    nombreParroquia: "VALLADOLID",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "8",
    codigoParroquia: "54",
    nombreParroquia: "LA CANELA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "9",
    codigoParroquia: "50",
    nombreParroquia: "PAQUISHA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "9",
    codigoParroquia: "51",
    nombreParroquia: "BELLAVISTA",
  },
  {
    codigoProvincia: "19",
    codigoCanton: "9",
    codigoParroquia: "52",
    nombreParroquia: "NUEVO QUITO",
  },
  {
    codigoProvincia: "20",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "PUERTO BAQUERIZO MORENO",
  },
  {
    codigoProvincia: "20",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "EL PROGRESO",
  },
  {
    codigoProvincia: "20",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia:
      "ISLA SANTA MARIA (FLOREANA) (CAB. EN  PTO. VELASCO IBARRA)",
  },
  {
    codigoProvincia: "20",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "PUERTO VILLAMIL",
  },
  {
    codigoProvincia: "20",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "TOMAS DE BERLANGA (SANTO TOMAS)",
  },
  {
    codigoProvincia: "20",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "PUERTO AYORA",
  },
  {
    codigoProvincia: "20",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "BELLAVISTA",
  },
  {
    codigoProvincia: "20",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "SANTA ROSA (INCLUYE LA ISLA BALTRA)",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "NUEVA LOJA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "DURENO",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "GENERAL FARFAN",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "EL ENO",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "PACAYACU",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "JAMBELI",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "SANTA CECILIA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "LUMBAQUI",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "EL REVENTADOR   ",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "GONZALO PIZARRO ",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "PUERTO LIBRE",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "PUERTO EL CARMEN DEL PUTUMAYO",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "PALMA ROJA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "PUERTO BOLIVAR (PUERTO MONTUFAR)",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "PUERTO RODRIGUEZ",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "SANTA ELENA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "SHUSHUFINDI",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "LIMONCOCHA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "PAÑACOCHA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "4",
    codigoParroquia: "53",
    nombreParroquia: "SAN ROQUE (CAB. EN SAN VICENTE)",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "4",
    codigoParroquia: "54",
    nombreParroquia: "SAN PEDRO DE LOS COFANES",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "4",
    codigoParroquia: "55",
    nombreParroquia: "SIETE DE JULIO",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "5",
    codigoParroquia: "50",
    nombreParroquia: "LA BONITA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "5",
    codigoParroquia: "51",
    nombreParroquia: "EL PLAYON DE SAN FRANCISCO",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "5",
    codigoParroquia: "52",
    nombreParroquia: "LA SOFIA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "5",
    codigoParroquia: "53",
    nombreParroquia: "ROSA FLORIDA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "5",
    codigoParroquia: "54",
    nombreParroquia: "SANTA BARBARA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "6",
    codigoParroquia: "50",
    nombreParroquia: "EL DORADO DE CASCALES",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "6",
    codigoParroquia: "51",
    nombreParroquia: "SANTA ROSA DE SUCUMBIOS",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "6",
    codigoParroquia: "52",
    nombreParroquia: "SEVILLA",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "7",
    codigoParroquia: "50",
    nombreParroquia: "TARAPOA ",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "7",
    codigoParroquia: "51",
    nombreParroquia: "CUYABENO",
  },
  {
    codigoProvincia: "21",
    codigoCanton: "7",
    codigoParroquia: "52",
    nombreParroquia: "AGUAS NEGRAS",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "PUERTO FRANCISCO DE ORELLANA (EL COCA)",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "DAYUMA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "TARACOA (CAB. EN NUEVA ESPERANZA: YUCA)",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "ALEJANDRO LABAKA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "EL DORADO",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "EL EDEN",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "GARCIA MORENO",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "INES ARANGO (CAB. EN WESTERN)",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "58",
    nombreParroquia: "LA BELLEZA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "59",
    nombreParroquia: "NUEVO PARAISO (CAB. EN UNIoN CHIMBORAZO)",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "60",
    nombreParroquia: "SAN JOSE DE GUAYUSA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "1",
    codigoParroquia: "61",
    nombreParroquia: "SAN LUIS DE ARMENIA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "NUEVO ROCAFUERTE",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "2",
    codigoParroquia: "51",
    nombreParroquia: "CAPITAN AUGUSTO RIVADENEYRA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "2",
    codigoParroquia: "52",
    nombreParroquia: "CONONACO",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "2",
    codigoParroquia: "53",
    nombreParroquia: "SANTA MARIA DE HUIRIRIMA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "2",
    codigoParroquia: "54",
    nombreParroquia: "TIPUTINI",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "2",
    codigoParroquia: "55",
    nombreParroquia: "YASUNI",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "LA JOYA DE LOS SACHAS",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "ENOKANQUI ",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "POMPEYA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "3",
    codigoParroquia: "53",
    nombreParroquia: "SAN CARLOS",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "3",
    codigoParroquia: "54",
    nombreParroquia: "SAN SEBASTIAN DEL COCA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "3",
    codigoParroquia: "55",
    nombreParroquia: "LAGO SAN PEDRO",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "3",
    codigoParroquia: "56",
    nombreParroquia: "RUMIPAMBA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "3",
    codigoParroquia: "57",
    nombreParroquia: "TRES DE NOVIEMBRE",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "3",
    codigoParroquia: "58",
    nombreParroquia: "UNION MILAGREÑA",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "4",
    codigoParroquia: "50",
    nombreParroquia: "LORETO",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "4",
    codigoParroquia: "51",
    nombreParroquia: "AVILA (CAB. EN HUIRUNO) ",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "4",
    codigoParroquia: "52",
    nombreParroquia: "PUERTO MURIALDO",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "4",
    codigoParroquia: "53",
    nombreParroquia: "SAN JOSE DE PAYAMINO",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "4",
    codigoParroquia: "54",
    nombreParroquia: "SAN JOSE DE DAHUANO",
  },
  {
    codigoProvincia: "22",
    codigoCanton: "4",
    codigoParroquia: "55",
    nombreParroquia: "SAN VICENTE DE HUATICOCHA",
  },
  {
    codigoProvincia: "23",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "SANTO DOMINGO DE LOS COLORADOS",
  },
  {
    codigoProvincia: "23",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "ALLURIQUIN",
  },
  {
    codigoProvincia: "23",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "PUERTO LIMON",
  },
  {
    codigoProvincia: "23",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "LUZ DE AMERICA ",
  },
  {
    codigoProvincia: "23",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "SAN JACINTO DEL BUA",
  },
  {
    codigoProvincia: "23",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "VALLE HERMOSO",
  },
  {
    codigoProvincia: "23",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "EL ESFUERZO",
  },
  {
    codigoProvincia: "23",
    codigoCanton: "1",
    codigoParroquia: "57",
    nombreParroquia: "SANTA MARIA DEL TOACHI",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "1",
    codigoParroquia: "50",
    nombreParroquia: "SANTA ELENA",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "1",
    codigoParroquia: "51",
    nombreParroquia: "ATAHUALPA",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "1",
    codigoParroquia: "52",
    nombreParroquia: "COLONCHE",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "1",
    codigoParroquia: "53",
    nombreParroquia: "CHANDUY",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "1",
    codigoParroquia: "54",
    nombreParroquia: "MANGLARALTO",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "1",
    codigoParroquia: "55",
    nombreParroquia: "SIMON BOLIVAR (JULIO MORENO)",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "1",
    codigoParroquia: "56",
    nombreParroquia: "SAN JOSE DE ANCON",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "2",
    codigoParroquia: "50",
    nombreParroquia: "LA LIBERTAD",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "3",
    codigoParroquia: "50",
    nombreParroquia: "SALINAS",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "3",
    codigoParroquia: "51",
    nombreParroquia: "ANCONCITO",
  },
  {
    codigoProvincia: "24",
    codigoCanton: "3",
    codigoParroquia: "52",
    nombreParroquia: "JOSE LUIS TAMAYO (MUEY)",
  },
];
