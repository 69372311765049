import React, { Fragment, useState, useEffect } from "react";
import {
  Animate,
  AccountNav,
  Card,
  Form,
  Message,
  useAPI,
} from "components/lib";
import Style from "./salesForce.module.scss";
import GlobalStyle from "../../css/globalStyle.module.scss";
import PasswordIcon from "../../assets/icons/password.svg";
import { BackButton } from "components/backButton/button";

export function TwoFA(props) {
  // get the user state
  const user = useAPI("/api/user");
  const [qrCode, setQrCode] = useState(undefined);
  const [enabled, setEnabled] = useState(undefined);
  const [backupCode, setBackupCode] = useState(undefined);

  useEffect(() => {
    setEnabled(user.data?.["2fa_enabled"]);
  }, [user.data]);

  return (
    <Fragment>
      <Animate>
        <Card title='Autenticación de dos factores' restrictWidth className={props.className} loading={user.loading}>

          <Form
            url='/api/user/2fa'
            method='put'
            submitOnChange
            data={{
              '2fa_enabled': {
                type: 'switch',
                label: 'Habilitar la autenticación de dos factores',
                default: user?.data?.['2fa_enabled']
              }
            }}
            callback={res => {

              setQrCode(res.data.data.qr_code);

              if (!res.data.data['2fa_enabled'])
                setEnabled(false);

            }}
          />

          <br></br>
          <br></br>

          {qrCode ?
            <Fragment>
              <Message
                title='Escanear código QR'
                type='info'
                text='Escanee el código QR a continuación con una aplicación de autenticación como Google Authenticator e ingrese el código de verificación.' />

              <img src={qrCode} alt='Escanee esto con su aplicación de autenticación' style={{ marginBottom: '1em', marginLeft: '-0.75em' }} />

              <Form
                method='post'
                url='/api/user/2fa/verify'
                buttonText='Verificar'
                data={{
                  code: {
                    label: 'Código de verificación',
                    type: 'text',
                  }
                }}
                callback={res => {

                  setQrCode(null);
                  setEnabled(true);
                  setBackupCode(res.data.data.backup_code)
                }

                }
              />
            </Fragment> :
            <Fragment>

              {enabled ?
                <Fragment>
                  {backupCode ?
                    <Message
                      title='Tu código de respaldo'
                      type='success'
                      text='Guarde su código de respaldo en un lugar seguro.'>
                      <Form data={{
                        code: {
                          type: 'text',
                          value: backupCode
                        }
                      }} />
                    </Message> :
                    <Message
                      title='2FA habilitado'
                      type='success'
                      text='¡Buen trabajo! La autenticación de dos factores está habilitada en su cuenta.'
                    />}
                </Fragment> :
                <Message
                  title='Habilitar 2FA'
                  type='warning'
                  text={'Recomendamos habilitar 2FA en su cuenta para mayor seguridad.'}
                />
              }
            </Fragment>
          }
        </Card>
      </Animate>
    </Fragment>
  );
}
