import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { BackButton } from "components/backButton/button";
import { getFilterDataPricesV2 } from "components/bridges/BridgeServices";
import useViewPort from "components/hooks/useViewPort";
import { Animate, Button, Content, Grid, ModalCustom, Table, ViewContext, useNavigate } from "components/lib";
import { useContext, useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { buscaEmisionSdp, contratoXParametros, getCandidateAgroCsvList, getCandidateById }
  from "../../../components/bridges/BridgeServices";
import "../../../css/DateRangePicker.css";
import { default as GlobalStyle, default as Input } from "../../../css/globalStyle.module.scss";
import Style from "./agricultureSeller.module.scss";

export function AgroManagement(props) {
  const context = useContext(ViewContext);
  const [screenSize] = useViewPort();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [dataSeach, setDataSearch] = useState({
    number: "",
    id: "",
    dateBegin: "",
    dateEnd: "",
    date: "",
    secuencial: "",
    channel: "",
  });
  const [allCandidates, setAllCandidates] = useState([]);
  const [open, setOpen] = useState(false);
  const [buscaEmisionSdpObj, setBuscaEmisionSdpObj] = useState(undefined);
  const [loadingTable, setLoadingTable] = useState(false);
  const [modalPdf, setModalPdf] = useState(false);
  const [labelPdf, setLabelPdf] = useState("Descargando el certificado...");
  const [canEdit, setCanEdit] = useState(true);

  useEffect(() => {
    const user_permissions = JSON.parse(localStorage.getItem("user_permissions"));
    const agro = user_permissions?.agro?.gestion;
    if (agro !== null && agro !== undefined && agro.c !== undefined) {
      setCanEdit(agro.c);//update ermission
    }

  }, [])

  function validarFechas(obj) {
    if (!obj.fecha_desde || !obj.fecha_hasta) {
      return false;
    }
    let fechaDesde = new Date(obj.fecha_desde);
    let fechaHasta = new Date(obj.fecha_hasta);
    if (isNaN(fechaDesde.getTime()) || isNaN(fechaHasta.getTime())) {
      return false;
    }
    // Verificar si la diferencia entre las fechas es menor o igual a 6 meses
    let seisMesesEnMilisegundos = 2 * 30 * 24 * 60 * 60 * 1000;
    if (Math.abs(fechaHasta - fechaDesde) > seisMesesEnMilisegundos) {
      return false;
    }
    return true;
  }

  const handleSearchData = async () => {

    const user = JSON.parse(localStorage.getItem("profile"));
    const isSuperAdmin = user.data.flag_uno === true;

    let obj = {
      nro_documento: dataSeach.id,
      fecha_desde: dataSeach.dateBegin,
      fecha_hasta: dataSeach.dateEnd,
      product_type: "agro",
      secuencial: dataSeach.secuencial,
      canal: dataSeach.channel,
    };

    if (obj.secuencial === '') {
      if (!validarFechas(obj)) {
        context.notification.show("Las fechas no son válidas.", "error", true);
        return;
      }
    }

    setLoadingTable(true);

    await getFilterDataPricesV2(obj, isSuperAdmin).then((res) => {
      let serverCode = res?.status;
      if (serverCode === 200) {
        let respCandidates = res?.data?.data;
        let candidates = [];
        respCandidates.map(async (item) => {
          if (item.step_two_request !== null) {
            candidates.push(item);
          }
        });
        setAllCandidates(candidates);
        setLoadingTable(false);
      } else {
        setAllCandidates([]);
        setLoadingTable(false)
        let tmpMessage = res?.response?.data?.message ? res?.response?.data?.message : 'Algo salió mal'
        context.notification.show(tmpMessage, "error", true);
      }
    });

  };

  const parseDate = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleDate = (date) => {
    if (date === null) {
      setDataSearch({
        ...dataSeach,
        date: null,
      });
    } else {
      setDataSearch({
        ...dataSeach,
        dateBegin: parseDate(date[0]),
        dateEnd: parseDate(date[1]),
        date,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const alphanumericExtendedRegex = /^[0-9 ]+$/;
    if (value === '' || alphanumericExtendedRegex.test(value)) {
      setDataSearch({ ...dataSeach, [name]: value });
    }
  };

  async function editCandidate(data, callback) {

    context.modal.show(
      {
        title: "Emitir Póliza",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          secuencial: {
            label: "No. de solicitud",
            type: "text",
            required: true,
            readonly: true,
            value: data.secuencial,
          },
          estado: {
            label: "Estado",
            type: "radio",
            options: ["Emitir"],
            required: true,
            errorMessage: "Por favor selecciona una opción",
          },
        },
        buttonText: "Actualizar",
        url: "api/candidate/updateStepAgroEmitir",
        method: "PATCH",
      },
      (res) => {
        handleSearchData();
      }
    );
  }

  async function searchCotizacion(data) {
    setModalPdf(true);
    try {
      let responseCandidate = await getCandidateById(data.id);

      let astr_id_proveedor = responseCandidate?.data[0].emitesdp_request?.astr_id_proveedor;
      let responseBusca = await buscaEmisionSdp(astr_id_proveedor, data.id)
      let jsonToParse = responseBusca?.data ? responseBusca?.data?.message : responseBusca?.response?.data?.message;
      let jsonBusca = JSON.parse(jsonToParse)
      //Cuando la api buscaemisionsdp responde OK => descargar PDF con contratoXParametros
      //Sino mostrat pop up con mapeando la respuesta en un pop up
      if (jsonBusca?.resultado === 'OK') {
        let objContratosXParametros = {
          "agencia": jsonBusca.astr_agencia,
          "ramo": jsonBusca.astr_ramo,
          "poliza": jsonBusca.astr_poliza,
          "cotizacion": "",
          "endoso": jsonBusca.aint_endoso.toString(),
          "certificado": jsonBusca.aint_certificado.toString(),
        }
        await contratoXParametros(objContratosXParametros).then((res) => {
          if (res?.status !== 500) {
            const base64Url = 'data:application/pdf;base64,' + encodeURIComponent(res?.astr_poliza_firmada);
            const link = document.createElement('a');
            link.href = base64Url;
            link.download = 'myPDF.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setModalPdf(false);
          } else {
            setLabelPdf("Error al descargar el PDF")
          }
        })

      } else {
        setModalPdf(false);
        setBuscaEmisionSdpObj(jsonBusca ? jsonBusca : {})
        setOpen(true);
      }
    } catch (e) {
      setModalPdf(false);
      context.notification.show(e.resultado, "error", false);
    }
    finally {
      handleSearchData();
    }

  }

  async function generateCsvList() {
    let secuencial = allCandidates
      .map(function (elem) {
        return elem.secuencial;
      })
      .join("-");
    let respCsv = await getCandidateAgroCsvList(secuencial);
    window.location.href = respCsv?.data;
  }

  const makeDataTable = () => {
    let tempDataTable = {
      header: [
        { name: "detail", title: "Detalles", sort: false },
        { name: "actionsPrice", title: "Acciones", sort: false },
        { name: "numberSolicitud", title: "No. de solicitud", sort: false },
        { name: "ci", title: "Cédula", sort: false },
        { name: "fechaIngreso", title: "Fecha ingreso de solicitud", sort: false },
        { name: "estado", title: "Estado", sort: false },
        { name: "cultivo", title: "Cultivo", sort: false },
        { name: "hectareas", title: "Hectáreas", sort: false },
        { name: "montoAsegurado", title: "Suma asegurada", sort: false },
        { name: "primaTotal", title: "Prima total", sort: false },
        { name: "poliza", title: "Póliza", sort: false },
        { name: "factura", title: "Factura", sort: false },
        { name: "plan", title: "Plan", sort: false },
        { name: "vigencia", title: "Vigencia", sort: false },
        { name: "observaciones", title: "Observaciones", sort: false },
      ],
      body: [],
    };
    let tempObjectCultivation = {};
    allCandidates.map((item, idx) => {

      tempObjectCultivation.detail = item;
      if (item?.estado === "Anulado") {
        tempObjectCultivation.actionsPrice = (
          <div style={{ display: "flex" }}>
            <Button
              icon='eye'
              name='view'
              action={() => {
                navigate(`/vendedor/agricola/cotizaciones/${item.id}`, { state: item });
              }}
              tooltip='Ver cotización'
            />
          </div>
        );
      } else if (item?.estado === "No Emitido") {
        tempObjectCultivation.actionsPrice = (
          <div style={{ display: "flex" }}>
            <Button
              icon='eye'
              name='view'
              action={() => {
                navigate(`/vendedor/agricola/cotizaciones/${item.id}`, { state: item });
              }}
              tooltip='Ver cotización'
            />
            <Button
              icon='toggle-left'
              tooltip='Generar póliza'
              name='emit'
              action={() => {
                editCandidate(item);
              }}
            />
            <Button
              icon='search'
              tooltip='Buscar póliza'
              name='search'
              action={() => {
                searchCotizacion(item);
              }}
            />
          </div>
        );
      } else if (item?.estado === "En Proceso" || item?.estado === "Emitido") {
        tempObjectCultivation.actionsPrice = (
          <div style={{ display: "flex" }}>
            <Button
              icon='eye'
              name='view'
              action={() => {
                navigate(`/vendedor/agricola/cotizaciones/${item.id}`, { state: item });
              }}
              tooltip='Ver cotización'
            />
            <Button
              icon='search'
              tooltip='Buscar póliza'
              name='search'
              action={() => {
                searchCotizacion(item);
              }}
            />
          </div>
        );
      } else {
        tempObjectCultivation.actionsPrice = (
          <div style={{ display: "flex" }}>

            {
              item?.estado !== "Pendiente" && item?.estado !== "Negar" && (
                <Button
                  icon='eye'
                  name='view'
                  action={() => {
                    navigate(`/vendedor/agricola/cotizaciones/${item.id}`, { state: item });
                  }}
                  tooltip='Ver cotización'
                />
              )
            }
            {
              canEdit && item?.estado !== "Pendiente" && item?.estado !== "Negar" && (
                <Button
                  icon='toggle-left'
                  tooltip='Generar póliza'
                  name='emit'
                  action={() => {
                    editCandidate(item);
                  }}
                />
              )
            }
          </div>
        );
      }

      tempObjectCultivation.numberSolicitud = (
        <div>{item.secuencial.toString().padStart(4, "0")}</div>
      );
      tempObjectCultivation.ci = <div>{item.nro_documento}</div>;
      tempObjectCultivation.fechaIngreso = (
        <div>{item.created_at.split("T")[0]}</div>
      );
      if (item?.estado === "Aprobar") {
        tempObjectCultivation.estado = (
          <div style={{ fontWeight: "bold", textTransform: "uppercase" }}>Aprobado Manual</div>
        );
      } else if (item?.estado === "Negar") {
        tempObjectCultivation.estado = (
          <div style={{ fontWeight: "bold", textTransform: "uppercase" }}>Negado</div>
        );
      } else {
        tempObjectCultivation.estado = (
          <div style={{ fontWeight: "bold" }}>{item?.estado.toUpperCase()}</div>
        );
      }
      tempObjectCultivation.cultivo = (
        <div>
          {item?.step_two_request?.description?.cultivo_Asegurar?.toUpperCase()}
        </div>
      );
      tempObjectCultivation.hectareas = (
        <div style={{ textAlign: "right" }}>
          {item?.step_two_request === null
            ? ""
            : parseFloat(
              item?.step_two_request?.description?.superficie
            )?.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
        </div>
      );
      tempObjectCultivation.montoAsegurado = (
        <div style={{ textAlign: "right" }}>
          {item?.step_two_response?.description?.valorAsegurado?.toLocaleString(
            "en-US",
            { minimumFractionDigits: 2, maximumFractionDigits: 2 }
          )}
        </div>
      );
      tempObjectCultivation.primaTotal = (
        <div style={{ textAlign: "right" }}>
          {item?.estado === "Negar"
            ? ""
            : item?.step_two_response?.description?.prima?.toLocaleString(
              "en-US",
              { minimumFractionDigits: 2, maximumFractionDigits: 2 }
            )}
        </div>
      );
      tempObjectCultivation.poliza = (
        <div style={{ textAlign: "right" }}>
          {item?.buscaemisionsdp_response?.astr_poliza}
        </div>
      );
      tempObjectCultivation.factura = (
        <div style={{ textAlign: "right" }}> </div>
      );
      tempObjectCultivation.plan = (
        <div>
          {item?.step_two_request?.description?.plan?.namePlan}
        </div>
      );
      tempObjectCultivation.vigencia = (
        <div>
          {item?.step_two_request?.description?.fecha_siembra_vigencia}
        </div>
      );
      tempObjectCultivation.observaciones = (
        <div>
          {item?.estado === "Pendiente"
            ? item?.step_two_response?.description?.observaciones
            : item?.rules?.observaciones}
        </div>
      );
      tempDataTable.body.push(tempObjectCultivation);
      tempObjectCultivation = {};
    });
    setData(tempDataTable);
  };

  useEffect(() => {
    makeDataTable();
  }, [allCandidates]);

  const closeModal = () => {
    setOpen(false);
    window.location.reload(true);
  };

  return (
    <Animate type='pop'>
      <div className={GlobalStyle.buttonPosition}>
        <BackButton action={() => navigate("/dashboard/#2")} />
      </div>
      <section className={GlobalStyle.paddingLeft}>
        <Content>
          <div style={{ padding: "15px 0px" }}>
            <p className={GlobalStyle.textTitle}>Gestión Agrícola</p>
          </div>
          <div style={{ paddingBottom: 20 }}>
            <div className={GlobalStyle.priceInputSection}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Número de solicitud:</label>
                <input
                  className={Input["input_number"]}
                  value={dataSeach.secuencial}
                  name='secuencial'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Canal:</label>
                <input
                  className={Input["input_number"]}
                  value={dataSeach.channel}
                  name='channel'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Número de documento:</label>
                <input
                  className={Input["input_number"]}
                  value={dataSeach.id}
                  name='id'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Fecha ingreso de solicitud:</label>
                <DateRangePicker
                  onChange={handleDate}
                  value={dataSeach.date}
                  required
                />
              </div>
            </div>
          </div>
          <div>
            {loadingTable ? (
              <div className={Style.ldsDualRingContainer}>
                <div className={Style.ldsDualRing}></div>
              </div>
            ) : (<>
              {/* Search section */}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div>
                  <Button color='blue' text='Buscar' action={handleSearchData} />
                </div>
              </div>
              <br></br><br></br>
              <Scrollbars style={{ width: "100%", height: "600px" }}>
                <Table
                  id='table-Cotizaciones'
                  show={[
                    "actionsPrice",
                    "numberSolicitud",
                    "ci",
                    "fechaIngreso",
                    "estado",
                    "cultivo",
                    "hectareas",
                    "montoAsegurado",
                    "primaTotal",
                    "poliza",
                    "plan",
                    "vigencia",
                    "observaciones",
                  ]}
                  data={data}
                  loading={Object.entries(data).length === 0 ? true : false}
                />
              </Scrollbars>
              {/* DOWNLOAD SECTION */}
              <div style={{ padding: "20px 0px", textAlign: "right" }}>
                {allCandidates.length > 0 ? (
                  <Button
                    color='blue'
                    text='Descargar Tabla'
                    action={generateCsvList}
                  />
                ) : (
                  <p></p>
                )}
              </div>

            </>)}
          </div>

          <ModalCustom
            display={
              <div>
                <div style={{ textAlign: 'center' }}>
                  <p className={GlobalStyle.textTitle}>Verificar Emisión</p>
                </div>
                <Grid>
                  <p><b>resultado:</b> {buscaEmisionSdpObj?.resultado}</p>
                  <p><b>astr_agencia:</b> {buscaEmisionSdpObj?.astr_agencia}</p>
                  <p><b>astr_ramo:</b> {buscaEmisionSdpObj?.astr_ramo}</p>
                  <p><b>astr_poliza:</b> {buscaEmisionSdpObj?.astr_poliza}</p>
                  <p><b>aint_certificado:</b> {buscaEmisionSdpObj?.aint_certificado}</p>
                  <p><b>aint_endoso:</b> {buscaEmisionSdpObj?.aint_endoso}</p>
                </Grid>
              </div>
            }
            width={screenSize.dynamicWidth < 650 ? "100%" : "500px"}
            height='auto'
            open={open}
            closeModal={closeModal}
          />

          <ModalCustom
            display={
              <div>
                <div style={{ textAlign: 'center' }}>
                  <p className={GlobalStyle.textTitle}>{`${labelPdf}`}</p>
                </div>
                <div className={Style.ldsDualRingContainer}>
                  <div className={Style.ldsDualRing}></div>
                </div>
              </div>
            }
            width={screenSize.dynamicWidth < 650 ? "100%" : "500px"}
            height='auto'
            open={modalPdf}
            closeModal={() => setModalPdf(false)}
          />

        </Content>
      </section>
    </Animate>
  );
}
