/*eslint-disable*/
import { createContext, useReducer, useMemo } from "react";

const initialState = {
    userPermissions: null,
    nacionalities: null,
    banks: null,
    countries: null,
    wayPaid: null,
    cards: null,
    activities: null,
    provinces: [],
    cantones: [],
    maritalStatus: null,
    typePaid: null,
    branchXProducts: null,
    branch: null,
    sponsors: null,
    brokers: null,
    salesForces: null,
    usuariosSDP: null,
    provincies: null,
    typeid: [],
    person: null,
    gender: null,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "SET_PROVINCES":
            return {
                ...state,
                provinces: action.payload,
            };
        case "SET_TYPEID":
            return {
                ...state,
                typeid: action.payload,
            };
        default:
            return state;
    }
};

const DataContextOld = createContext({
    ...initialState,
    setPermissions: () => { },
});

export const DataProvider = (props) => {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, initialState);

    const setPermissions = (permissions) => {
        try {

            dispatch({
                type: "SETPERMISSIONS",
                payload: {
                    userPermissions: permissions,
                },
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <DataContextOld.Provider
            value={{
                state,
                setPermissions
            }}
        >
            {children}
        </DataContextOld.Provider>
    );
};

export default DataContextOld;