import React from "react";
import { Animate, Content, TabView, Card, useAPI, Table, useNavigate, ViewContext } from "components/lib";
import { useEffect, useState } from "react";
import { Submit } from "components/bridges/BridgeServices";
import { useContext } from "react";
import { BackButton } from "components/backButton/button";
import Style from "../../../css/globalStyle.module.scss";
import { SetViewCandidateOther } from "utils/getPermissionsOther";
import useViewPort from "../../../components/hooks/useViewPort";
import { Scrollbars } from "react-custom-scrollbars-2";

export function ListProducts(props) {
  const rawUser = JSON.parse(localStorage.getItem('profile'))
  const userPermissions = JSON.parse(localStorage.getItem("user_permissions"));
  const productsLife = useAPI("/api/product/life");
  const productsAgro = useAPI("/api/product/agro");
  const [screenSize] = useViewPort();
  const [dataAgro, setDataAgro] = useState({ byPlan: [], byCycle: [] });
  const [dataLife, setDataLife] = useState([]);
  const viewContext = useContext(ViewContext);
  const navigate = useNavigate();

  const getPlansLife = () => {
    let tempDataTable = {
      header: [
        { name: "id", title: "Id", sort: false },
        { name: "ramo", title: "Ramo", sort: true },
        { name: "name", title: "Nombre del producto", sort: true },
        { name: "plans", title: "N° Planes", sort: true },
        { name: "alternatives", title: "N° Alternativas", sort: true },
        { name: "idProduct", title: "Codigo Producto", sort: true },
      ],
      body: [],
    };
    let tempObjectRow = {};
    if (productsLife.data && productsAgro.data) {
      let planAlternatives = "";
      productsLife.data.map((row, index) => {
        Object.entries(row.detail.valores_asegurados).map((item, idx) => {
          if (idx !== 0) {
            planAlternatives += " / ";
          }
          planAlternatives += `${item[0]}: ${item[1][0].alternativas.length}`;
        });
        tempObjectRow.id = row.id;
        tempObjectRow.ramo = row.detail.ramo.astr_descripcion;
        tempObjectRow.name = row.detail.cod_producto.astr_descripcion;
        tempObjectRow.idProduct = row.detail.cod_producto.astr_id_producto;
        tempObjectRow.plans = Object.keys(row.detail.planes[0]).length;
        tempObjectRow.alternatives = planAlternatives;
        tempDataTable.body.push(tempObjectRow);
        tempObjectRow = {};
        planAlternatives = "";
      });
      setDataLife(tempDataTable);

      tempDataTable = {
        header: [
          { name: "id", title: "Id", sort: false },
          { name: "cultivo", title: "Cultivo", sort: true },
          { name: "plan", title: "Plan", sort: true },
          { name: "cycle", title: "Por ciclos", sort: true },
          { name: "value", title: "Por valor", sort: true },
        ],
        body: [],
      };
      tempObjectRow = {};

      productsAgro.data?.length > 0 &&
        productsAgro.data[0].detail.cultivo.map((row, index) => {
          let titleTemp = "";
          row.cultivationPlan.map((row, idx) => {
            if (idx !== 0) {
              titleTemp += " / ";
            }
            titleTemp += row.namePlan;
          });
          tempObjectRow.id = row.id;
          tempObjectRow.cultivo = row.name;
          tempObjectRow.plan = titleTemp;
          tempObjectRow.cycle = row.cultivationCycle.length;
          tempObjectRow.value = row.cultivationValueInsured.length;
          tempDataTable.body.push(tempObjectRow);
          tempObjectRow = {};
        });
    }
    setDataAgro(tempDataTable);
  };

  function editLife(data, callback) {
    navigate(`/producto/vida/${data.id}`, { state: productsLife.data });
  }

  async function deleteLife(data, callback) {
    let canDelete = SetViewCandidateOther(
      rawUser,
      userPermissions,
      ["r"],
      "confprod"
    );
    if (canDelete) {
      let idProduct = data.id;
      let codProducto = data.idProduct;
      let url = `product/getByIdProducto/${codProducto}/producto`;

      await Submit(url, "", "get").then(async (resp) => {
        // canDelete = !resp.data.data.hasSonds;
        if (resp.data.data.hasSons) {
          viewContext.notification.show(
            "No se puede eliminar el producto",
            "error"
          );
        } else {
          url = `product/${idProduct}`;
          await Submit(url, "", "delete").then((resp) => {
            viewContext.notification.show("Producto eliminado", "sucess");
            window.location.reload();
          });
        }
      });
    } else {
      viewContext.notification.show(
        "No tiene permisos para eliminar productos",
        "error"
      );
    }
  }

  function editAgro(data, callback) {
    let productIdAgro = productsAgro.data && productsAgro.data.length > 0 ? productsAgro.data[0].id : undefined;
    navigate(`/producto/agricola/${data.cultivo}/${productIdAgro}`, { state: productsAgro.data });
  }

  useEffect(() => {
    if (productsLife.data) getPlansLife();
    if (productsAgro.data) getPlansLife();
  }, [productsLife, productsAgro]);

  return (
    <Animate type='pop'>
      <div className={Style.buttonPosition}>
        <BackButton go={-1} />
      </div>
      <Content>
        <section className={Style.paddingLeft}>
          <div style={{ padding: "15px 0px" }}>
            <p className={Style.textTitle}>Productos</p>
          </div>
          {screenSize.dynamicWidth > 1024 ? (
            <TabView
              name='configCultivation'
              labels={["Producto vida", "Producto agrícola"]}
            >
              <Card>
                <Table
                  search
                  loading={productsLife.loading}
                  data={dataLife}
                  show={["ramo", "name", "plans", "alternatives"]}
                  actions={{ edit: editLife, delete: deleteLife }}
                />
              </Card>
              <Card>
                <Table
                  search
                  loading={productsAgro.loading}
                  data={dataAgro}
                  show={["cultivo", "plan", "cycle", "value"]}
                  actions={{ edit: editAgro }}
                />
              </Card>
            </TabView>
          ) : (
            <Scrollbars
              style={{
                width: "100%",
                height: "600px",
              }}
            >
              <TabView
                name='configCultivation'
                labels={["Producto vida", "Producto agrícola"]}
              >
                <Card>
                  <Table
                    search
                    loading={productsLife.loading}
                    data={dataLife}
                    show={["ramo", "name", "plans", "alternatives"]}
                    actions={{ edit: editLife, delete: deleteLife }}
                  />
                </Card>
                <Card>
                  <Table
                    search
                    loading={productsAgro.loading}
                    data={dataAgro}
                    show={["cultivo", "plan", "cycle", "value"]}
                    actions={{ edit: editAgro }}
                  />
                </Card>
              </TabView>
            </Scrollbars>
          )}
        </section>
      </Content>
    </Animate>
  );
}
