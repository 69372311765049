class CryptrUtils {   
    getRandomChar() {
        const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        return chars.charAt(Math.floor(Math.random() * chars.length));
    }
    encrypt(str) {
        let encodedStr = btoa(str);
        let alteredStr = '';
        for (let i = 0; i < encodedStr.length; i++) {
            alteredStr += encodedStr[i];
            if ((i + 1) % 3 === 0) {
                alteredStr += this.getRandomChar();
            }
        }

        return alteredStr;
    }
    decrypt(str) {
        let restoredStr = '';
        for (let i = 0; i < str.length; i++) {
            if ((i + 1) % 4 !== 0) {
                restoredStr += str[i];
            }
        }
        return atob(restoredStr);
    }

    // base64Decode(str) {
    //     const percentEncodedStr = atob(str).split('').map(function(c) {
    //         return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    //     }).join('');
    //     return decodeURIComponent(percentEncodedStr);
    // }
    // base64Encode(str) {
    //     const encodedStr = encodeURIComponent(str).split('').map(function(c) {
    //         return '%' + c.charCodeAt(0).toString(16);
    //     }).join('');    
    //     return btoa(encodedStr);
    // }
    
}

export default new CryptrUtils();
