import { getProvince } from "components/bridges/BridgeServices";
import { Icon, Table } from "components/lib";
import { Button } from "grommet";
import { Add } from "grommet-icons";
import { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import Input from "../../../../css/globalStyle.module.scss";
import useViewPort from "../../../hooks/useViewPort";
import Style from "./byValue.module.scss";
import { useDataContext } from "../../../../context/DataContext";

export function ByValue(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  const { agriculture, setAgriculture } = props;
  const [data, setData] = useState();
  const [screenSize] = useViewPort();
  const [province, setProvince] = useState();
  const [cultivationDefault, setCultivationDefault] = useState(
    agriculture.cultivations.length > 0
      ? agriculture.cultivations[0].nombre_del_cultivo
      : ""
  );

  const handleDeleteCultivation = (indexCultivation) => {
    const agricultureList = { ...agriculture };
    agricultureList.plansCultivation.map((item) => {
      if (item.name === agricultureList.cultivationSelected) {
        item.cultivationValueInsured.splice(indexCultivation, 1);
      }
    });
    setAgriculture(agricultureList);
  };

  const handleInputDataChange = (e, indexData, nameSection) => {
    const { name, value } = e.target;
    const agricultureList = { ...agriculture };
    const indexCultivation = agriculture.plansCultivation.findIndex(
      (object) => object.name === agricultureList.cultivationSelected
    );

    agricultureList.plansCultivation[indexCultivation][nameSection][indexData][
      name
    ] = value;
    setAgriculture(agricultureList);
  };
  const [cultiv, setCultiv] = useState(null);

  const handleSelectBoxChange = (e) => {
    const { value } = e.target;
    const agricultureList = { ...agriculture };
    agricultureList.cultivationSelected = value;
    setCultivationDefault(value);
    setAgriculture(agricultureList);
    setCultiv(value);
  };

  const handleAddRow = (nameSection) => {
    const agricultureList = { ...agriculture };
    const item = {
      nameCultivation:
        cultivationDefault === ""
          ? agriculture.cultivations[0].nombre_del_cultivo
          : cultivationDefault,
      province: province[0].astr_descripcion,
      valueMax: 0,
    };
    const indexCultivation = agriculture.plansCultivation.findIndex(
      (object) => object.name === agricultureList.cultivationSelected
    );

    if (indexCultivation === -1) {
      agricultureList.plansCultivation.push({
        name: agricultureList.cultivationSelected,
        cultivationPlan: [],
        cultivationCycle: [],
        cultivationValueInsured: [item],
      });
    } else {
      agricultureList.plansCultivation[indexCultivation][nameSection].push(
        item
      );
    }
    setAgriculture((prevRequirements) => ({
      ...prevRequirements,
    }));
  };

  const getPlanByName = (cultivationPlan) => {
    return cultivationPlan.filter(
      (item) => item.name === agriculture.cultivationSelected
    );
  };

  const makeDataTable = (cultivationPlan, handleInputDataChange) => {
    if (province) {
      let tempDataTable = {
        header: [
          { name: "nombre_cultivo", title: "Nombre del cultivo", sort: false },
          { name: "provincia", title: "Provincia", sort: false },
          {
            name: "valor_máximo",
            title: "Valor máximo asegurado (USD) POR HECTÁREA",
            sort: false,
          },
        ],
        body: [],
      };
      let tempObjectCultivation = {};
      let newCultivationArray = getPlanByName(cultivationPlan);
      let nameSection = "cultivationValueInsured";
      if (newCultivationArray.length > 0) {
        newCultivationArray[0][nameSection].map(
          (cultivation, indexCultivation) => {
            tempObjectCultivation.nombre_cultivo = cultivation.nameCultivation;
            tempObjectCultivation.provincia = (
              <select
                style={{ width: "150px" }}
                name={"province"}
                id={nameSection}
                value={cultivation.province !== "" && cultivation.province}
                onChange={(e) =>
                  handleInputDataChange(e, indexCultivation, nameSection)
                }
              >
                {province.map((option, index) => {
                  return (
                    <option
                      key={option.astr_descripcion + index}
                      value={`${option.astr_descripcion}`}
                    >
                      {option.astr_descripcion}
                    </option>
                  );
                })}
              </select>
            );
            tempObjectCultivation.valor_máximo = (
              <div>
                <input
                  type='number'
                  value={cultivation.valueMax}
                  name='valueMax'
                  className={Input["roboto"]}
                  onChange={(e) =>
                    handleInputDataChange(e, indexCultivation, nameSection)
                  }
                />
                <button
                  style={{ padding: 5 }}
                  onClick={(e) => handleDeleteCultivation(indexCultivation)}
                >
                  <Icon image={"minus"} size={18} />
                </button>
              </div>
            );

            tempDataTable.body.push(tempObjectCultivation);
            tempObjectCultivation = {};
          }
        );
      }
      setData(tempDataTable);
    }
  };

  const getGeneralData = async () => {
    try {
      const respProvincesV2 = localStorage.getItem('provinces')
      let respProvincesJson;
      if (respProvincesV2) {
        respProvincesJson = JSON.parse(respProvincesV2)?.data
      }
      const respProvinces = state?.provinces?.data ? state.provinces.data : respProvincesJson ? respProvincesJson : await getProvince();
      setProvince(respProvinces);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    makeDataTable(agriculture.plansCultivation, handleInputDataChange);
  }, [agriculture, province, cultivationDefault]);

  useEffect(() => {
    getGeneralData();
  }, []);

  return (
    <div className={Style.byValue}>
      <div style={{ width: "50%" }}>
        <label>Cultivo</label>
        <select
          style={{ width: "150px" }}
          name={"cultivations"}
          id={"cultivations"}
          onChange={(e) => handleSelectBoxChange(e)}
          value={cultiv}
        >
          {agriculture.cultivations.map((option, i) => {
            return (
              <option
                key={`${option.nombre_del_cultivo}${option.tipo_del_cultivo}${option.vigencia}Options${i}`}
                value={option.nombre_del_cultivo}
              >
                {option.nombre_del_cultivo}
              </option>
            );
          })}
        </select>
      </div>
      {screenSize.dynamicWidth > 1024 ? (
        <div>
          <Table data={data} />
          <Button primary onClick={() => handleAddRow("cultivationValueInsured")}>
            <Add size='medium' />
          </Button>
        </div>
      ) : (
        <Scrollbars
          style={{
            width: "100%",
            height: "600px",
          }}
        >
          <div>
            <Table data={data} />
            <Button primary onClick={() => handleAddRow("cultivationValueInsured")}>
              <Add size='medium' />
            </Button>
          </div>
        </Scrollbars>
      )}
    </div>
  );
}
