import {
  getActivity,
  getPatrimonio,
  getRangoIngresos,
} from "components/bridges/BridgeServices";
import { useEffect, useState } from "react";
import Style from "../../../../../css/globalStyle.module.scss";
import { Title } from "./title";
import { useDataContext } from "../../../../../context/DataContext";

export function ActividadEconomica(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();

  const {
    actividadEconomica,
    setActividadEconomica,
  } =
    props;

  const [catalogoPatrimonio, setCatalogoPatrimonio] = useState([]);
  const [catalogoActividad, setCatalogoActividad] = useState([]);
  const [catalogoRangoIngresos, setCatalogoRangoIngresos] = useState([]);

  useEffect(() => {
    async function getRangopatrimonio() {
      const respPatrimonioV2 = localStorage.getItem('patrimonios')
      let respPatrimonioJson;
      if (respPatrimonioV2) {
        respPatrimonioJson = JSON.parse(respPatrimonioV2)?.data
      }
      const respPatrimonio = state?.patrimonios?.data ? state.patrimonios.data : respPatrimonioJson ? respPatrimonioJson : await getPatrimonio();

      const data = [];
      respPatrimonio.map((item) => {
        data.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "patrimonio",
        });
      });
      setCatalogoPatrimonio(data);
    }
    getRangopatrimonio();
  }, []);

  useEffect(() => {
    async function getActividad() {
      const respActivityV2 = localStorage.getItem('activities')
      let respActivityJson;
      if (respActivityV2) {
        respActivityJson = JSON.parse(respActivityV2)?.data
      }
      const respActivity = state?.activities?.data ? state.activities.data : respActivityJson ? respActivityJson : await getActivity();

      const data = [];
      respActivity.map((item) => {
        data.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "actividad",
        });
      });
      setCatalogoActividad(data);
    }
    getActividad();
  }, []);

  useEffect(() => {
    async function getIngresos() {
      const respRangosV2 = localStorage.getItem('rangos')
      let respRangosJson;
      if (respRangosV2) {
        respRangosJson = JSON.parse(respRangosV2)?.data
      }
      const respRangos = state?.rangos?.data ? state.rangos.data : respRangosJson ? respRangosJson : await getRangoIngresos();

      const data = [];
      respRangos.map((item) => {
        data.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "ingresos",
        });
      });
      setCatalogoRangoIngresos(data);
    }
    getIngresos();
  }, []);

  return (
    <div>
      <Title whiteTitle='3. Sección actividad económica principal del propuesto asegurado' />
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainer35}>
          <label style={{ marginBottom: "auto" }}>Actividad ecónomica</label>
          <select
            value={actividadEconomica.actividadEconomica}
            required={true}
            className={Style["select"]}
            onChange={(e) => {
              const actividadEconomicaStr = catalogoActividad.filter((item) => item.value === e.target.value)[0].label;
              setActividadEconomica({
                ...actividadEconomica,
                actividadEconomica: e.target.value,
                actividadEconomicaStr: actividadEconomicaStr,
              });
            }}
          >
            {catalogoActividad?.map((item, index) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })
            }
          </select>
        </div>
        <div className={Style.inputContainer35}>
          <label>Aproximado de tus ingresos</label>
          <select
            value={actividadEconomica.ingresos}
            required={true}
            className={Style["select"]}
            onChange={(e) => {
              const ingresosStr = catalogoRangoIngresos.filter((item) => item.value === e.target.value)[0].label;
              setActividadEconomica({
                ...actividadEconomica,
                ingresos: e.target.value,
                ingresosStr: ingresosStr,
              });
            }}
          >
            {catalogoRangoIngresos?.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })
            }
          </select>
        </div>
        <div className={Style.inputContainer35}>
          <label>Aproximado de tu patrimonio</label>
          <select
            value={actividadEconomica.patrimonio}
            required={true}
            className={Style["select"]}
            onChange={(e) => {
              const patrimonioStr = catalogoPatrimonio.filter((item) => item.value === e.target.value)[0].label;
              setActividadEconomica({
                ...actividadEconomica,
                patrimonio: e.target.value,
                patrimonioStr: patrimonioStr,
              });
            }}
          >
            {catalogoPatrimonio?.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })
            }
          </select>

        </div>
      </div>
      <div style={{ marginBottom: '20px' }}>
      </div>
    </div>
  );
}
