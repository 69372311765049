/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React from "react";
import { Animate, Row, Form, Card } from "components/lib";

export function ForgotPassword(props) {
  return (
    <Animate type='pop'>
      <Row title='Restablecer su contraseña'>
        <Card restrictWidth center>
          <p className='mb-5'>
            Ingrese su dirección de correo electrónico y le enviaremos
            instrucciones para restablecer su contraseña.
          </p>

          <Form
            data={{
              email: {
                label: "Email",
                type: "email",
                required: true,
              },
            }}
            url='/api/auth/password/reset/request'
            method='POST'
            buttonText='Restablecer la contraseña'
          />
        </Card>
      </Row>
    </Animate>
  );
}
