import { createContext, useReducer, useRef, useContext } from "react";
import {
  getActivityV2,
  getBanksV2,
  getCardV2,
  getCountryV2,
  getNacionalityV2,
  getProvinceV2,
  getTypeIdV2,
  getCitiesV2,
  getWayPaidV2,
  getMaritalStatusV2,
  getTypePaidV2,
  getBranchXProductV2,
  getBranchXProductV3,
  getBranchV2,
  getSponsorV2,
  getBrokersV2,
  getSalesForcesV2,
  getUsuariosSDPV2,
  getPersonV2,
  getGenderV2,
  getCitiesLocal,
  getPatrimonioV2,
  getRangoIngresosV2,
  getParentescoTitularV2
} from "components/bridges/BridgeServices";

const initialState = {
  userPermissions: null,
  nacionalities: null,
  banks: null,
  countries: null,
  wayPaid: null,
  cards: null,
  activities: null,
  provinces: null,
  cantones: null,
  maritalStatus: null,
  typePaid: null,
  branchXProducts: null,
  branchXProductsAgro: null,
  branch: null,
  sponsors: null,
  brokers: null,
  salesForces: null,
  usuariosSDP: null,
  provincies: null,
  typeid: null,
  person: null,
  gender: null,
  ciudades: null,

  patrimonios: null,
  rangos: null,
  parentescos: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PROVINCES":
      return { ...state, provinces: action.payload };
    case "SET_TYPEID":
      return { ...state, typeid: action.payload };
    case "SET_NACIONALITIES":
      return { ...state, nacionalities: action.payload };
    case "SET_BANKS":
      return { ...state, banks: action.payload };
    case "SET_COUNTRIES":
      return { ...state, countries: action.payload };
    case "SET_WAYPAID":
      return { ...state, wayPaid: action.payload };
    case "SET_CARDS":
      return { ...state, cards: action.payload };
    case "SET_ACTIVITIES":
      return { ...state, activities: action.payload };
    case "SET_CANTONES":
      return { ...state, cantones: action.payload };
    case "SET_MARITALSTATUS":
      return { ...state, maritalStatus: action.payload };
    case "SET_TYPEPAID":
      return { ...state, typePaid: action.payload };
    case "SET_BRANCHXPRODUCTS":
      return { ...state, branchXProducts: action.payload };
    case "SET_BRANCHXPRODUCTSAGRO":
      return { ...state, branchXProductsAgro: action.payload };
    case "SET_BRANCH":
      return { ...state, branch: action.payload };
    case "SET_SPONSORS":
      return { ...state, sponsors: action.payload };
    case "SET_BROKERS":
      return { ...state, brokers: action.payload };
    case "SET_SALESFORCES":
      return { ...state, salesForces: action.payload };
    case "SET_USUARIOSSDP":
      return { ...state, usuariosSDP: action.payload };
    case "SET_PROVINCIATES":
      return { ...state, provincies: action.payload };
    case "SET_PERSON":
      return { ...state, person: action.payload };
    case "SET_GENDER":
      return { ...state, gender: action.payload };
    case "SETPERMISSIONS":
      return { ...state, userPermissions: action.payload };
    case "SET_CIUDADES":
      return { ...state, ciudades: action.payload };

    case "SET_PATRIMONIOS":
      return { ...state, patrimonios: action.payload };
    case "SET_RANGOS":
      return { ...state, rangos: action.payload };
    case "SET_PARENTESCOS":
      return { ...state, parentescos: action.payload };

    default:
      return state;
  }
};

const DataContext = createContext(initialState);

export const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const callStatusRef = useRef(new Map());

  const fetchData = async (key, actionType, serviceFunction) => {
    if (callStatusRef.current.has(key)) {
      callStatusRef.current.set(key, 'fetching');
      try {
        const response = await serviceFunction();
        if (key === 'ciudades' && response) {
          const data = { data: response };
          dispatch({ type: actionType, payload: data });
          localStorage.setItem(key, JSON.stringify(data));
          callStatusRef.current.set(key, 200);
        } else {
          if (response.status === 200) {
            const data = response.data;
            dispatch({ type: actionType, payload: data });
            localStorage.setItem(key, JSON.stringify(data));
            callStatusRef.current.set(key, 200);
          } else {
            console.error(`Error fetching ${key}: ${response.statusText}`);
            callStatusRef.current.set(key, response.status);
          }
        }
      } catch (error) {
        console.error(`Error fetching ${key}: ${error.message}`);
        callStatusRef.current.set(key, 'error');
      }
    }
  };

  const getNacionalities = () => fetchData("nacionalities", "SET_NACIONALITIES", getNacionalityV2);
  const getAllBanks = () => fetchData("banks", "SET_BANKS", getBanksV2);
  const getCountries = () => fetchData("countries", "SET_COUNTRIES", getCountryV2);
  const getWayPaids = () => fetchData("wayPaid", "SET_WAYPAID", getWayPaidV2);
  const getCards = () => fetchData("cards", "SET_CARDS", getCardV2);
  const getActivities = () => fetchData("activities", "SET_ACTIVITIES", getActivityV2);
  const fetchProvinces = () => fetchData("provinces", "SET_PROVINCES", getProvinceV2);
  const fetchCantones = () => fetchData("canton", "SET_CANTON", getCitiesV2);
  const fetchTypeId = () => fetchData("typeid", "SET_TYPEID", getTypeIdV2);
  const getMaritalStatuses = () => fetchData("maritalStatus", "SET_MARITALSTATUS", getMaritalStatusV2);
  const getTypePaid = () => fetchData("typePaid", "SET_TYPEPAID", getTypePaidV2);
  const getBranchXProducts = () => fetchData("branchXProducts", "SET_BRANCHXPRODUCTS", getBranchXProductV2);
  const getBranchXProductsAgro = () => fetchData("branchXProductsAgro", "SET_BRANCHXPRODUCTSAGRO", getBranchXProductV3);
  const getBranch = () => fetchData("branch", "SET_BRANCH", getBranchV2);
  const getSponsors = () => fetchData("sponsors", "SET_SPONSORS", getSponsorV2);
  const getBrokers = () => fetchData("brokers", "SET_BROKERS", getBrokersV2);
  const getSalesForces = () => fetchData("salesForces", "SET_SALESFORCES", getSalesForcesV2);
  const getUsuariosSDP = () => fetchData("usuariosSDP", "SET_USUARIOSSDP", getUsuariosSDPV2);
  const getPerson = () => fetchData("person", "SET_PERSON", getPersonV2);
  const getGender = () => fetchData("gender", "SET_GENDER", getGenderV2);
  const getCiudadesCatalog = () => fetchData("ciudades", "SET_CIUDADES", getCitiesLocal);

  const getPatrimonios = () => fetchData("patrimonios", "SET_PATRIMONIOS", getPatrimonioV2);
  const getRangos = () => fetchData("rangos", "SET_RANGOS", getRangoIngresosV2);
  const getParentescos = () => fetchData("parentescos", "SET_PARENTESCOS", getParentescoTitularV2);

  return (
    <DataContext.Provider
      value={{
        state,
        callStatusMap: callStatusRef.current,
        getNacionalities,
        getAllBanks,
        getCountries,
        getWayPaids,
        getCards,
        getActivities,
        fetchProvinces,
        fetchCantones,
        getMaritalStatuses,
        getTypePaid,
        getBranchXProducts,
        getBranchXProductsAgro,
        getBranch,
        getSponsors,
        getBrokers,
        getSalesForces,
        getUsuariosSDP,
        fetchTypeId,
        getPerson,
        getGender,
        getCiudadesCatalog,

        getPatrimonios,
        getRangos,
        getParentescos
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);

export default DataContext;
