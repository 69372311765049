import React, { useContext, useEffect, useState } from "react";
import {
  Animate,
  Button,
  Card,
  Content,
  Icon,
  useLocation,
  useNavigate,
  ModalCustomv2,
  Form,
  useAPI,
  ViewContext
} from "components/lib";
import { formatNumber } from "utils/formatNumber";
import { Title } from "./forms/components/title";
import {
  postFormSigned,
  enviaOtp,
  Submit,
  saveCandidateOtp,
  updateCandidateOtp,
  postFillForm,
  postFillForms
} from "components/bridges/BridgeServices";
import { useForm } from "react-hook-form";
import Style from "../agriculture/price.module.scss";
import StyleV2 from "../agriculture/agricultureSeller.module.scss";
import { BackButton } from "components/backButton/button";
import GlobalStyle from "../../../css/globalStyle.module.scss";
import { SetViewCandidate } from "utils/getPermissions";
import useViewPort from "../../../components/hooks/useViewPort";
import { SubmitQuery } from "../../../components/bridges/BridgeServices";

const formulariosEnLinea = [
  {
    title: "Formulario de solicitud de seguro",
    href: "formularioSolicitudSeguro",
  },
  { title: "Autorización de débito", href: "formularioAutorizacionDebito" },
];

const formulariosCliente = [
  { url: "", title: "Recibo de servicio básico", nameForm: "servicioBasico" },
  {
    url: "",
    title: "Impuesto a la renta último año",
    nameForm: "impuestoRenta",
  },
  { url: "", title: "Cédula asegurado", nameForm: "cedulaAsegurado" },
  { url: "", title: "Cédula cónyuge", nameForm: "cedulaConyuge" },
  {
    url: "",
    title: "Formulario de beneficiario",
    nameForm: "formularioBeneficiario",
  },
  {
    url: "",
    title: "Autorización de débito diferente del asegurado",
    nameForm: "debitoDiferenteAsegurado",
  },
  { url: "", title: "Contratante persona natural", nameForm: "personaNatural" },
  {
    url: "",
    title: "Contratante persona jurídica",
    nameForm: "personaJuridica",
  },
  {
    url: "",
    title: "Cédula contratante distinto al asegurado",
    nameForm: "cedulaDiferenteAsegurado",
  },
];

export function PriceClientLife(props) {
  const rawUser = JSON.parse(localStorage.getItem('profile'))
  const userPermissions = JSON.parse(localStorage.getItem("user_permissions"));
  const context = useContext(ViewContext);
  const [screenSize] = useViewPort();
  const [formFile, setFormFile] = useState({});
  const navigate = useNavigate();
  const dataOfac = JSON.parse(localStorage.getItem("loginListasOfac"));
  const userProfile = JSON.parse(localStorage.getItem("user"));
  const userProfileInfo = JSON.parse(localStorage.getItem("profile")).data;
  const [oldPage, setOldPage] = useState();
  const [flagFormSolicitudFinished, setFlagFormSolicitudFinished] = useState(false);
  const [flagFormAutoFinished, setFlagFormAutoFinished] = useState(false);
  const viewContext = useContext(ViewContext);
  let loginListasOfac = JSON.parse(localStorage.getItem("loginListasOfac"));
  const [step, setStep] = useState(0);
  const [planSelect, setPlanSelect] = useState();
  const [curDataProjectionObj, setCurDataProjectionObj] = useState({});
  const [textButton, setTextButton] = useState({
    seguro: "Completar",
    debito: "Completar",
  });
  const [formCliente, setFormCliente] = useState(formulariosCliente);
  let url = useLocation();
  const dataPrice = url.state;
  const urlFormsFilled = planSelect ? `/api/formByFilter?candidate_id=${dataPrice.id}&product_name=product${planSelect.idx + 1}` : '';
  const formsFilled = useAPI(urlFormsFilled);
  let formsFilledV2;
  const formsUploadedCatalog = useAPI(`/api/signed_form/getByCandidateId/${dataPrice.id}`);
  const [validToSendToSDP, setValidToSendToSDP] = useState(false);
  const [hasSolicitudSeguroUrl, setHasSolicitudSeguroUrl] = useState(false);

  useEffect(() => {
    if (!formsFilled?.loading && formsFilled?.data) {
      processForm();
      let findedSolicitudSeguro = formsFilled.data.find(ele => ele?.type === "solicitud_seguro");
      if (findedSolicitudSeguro) {
        findedSolicitudSeguro?.url ? setHasSolicitudSeguroUrl(true) : setHasSolicitudSeguroUrl(false);
      }
    }
  }, [formsFilled?.loading, formsFilled?.data]);

  const handleSelectPlan = async (plan, idx) => {
    localStorage.setItem("productSelected", `${idx}`);
    setPlanSelect({ plan, idx });
    setStep(1);
    let dataProjection = plan[1]?.dataProjection?.data?.proyeccion;
    setCurDataProjectionObj(dataProjection);
    localStorage.setItem(
      "oldPageData",
      JSON.stringify({
        planSelect: { plan, idx },
        step: 1,
        curData: dataProjection,
      })
    );
    let jsonParams = { candidate_id: dataPrice.id, product_name: `product${idx + 1}` };
    await SubmitQuery("formByFilter", jsonParams, "get")
      .then(async (value) => {
        formsFilledV2 = value?.data;
        await processForm();
      })
      .catch((error) => {
        viewContext.handleError(error);
      })
  };

  let thirdPartyPay = false;
  const getPersonPay = () => {
    formsFilled?.data?.map((item) => {
      if (item.type === "solicitud_seguro") {
        if (item.detail.personaPagaSeguro === "Una tercera persona") {
          thirdPartyPay = true;
        }
      }
    });
  };
  // Controlling sended forms
  const [isformsReady, setIsFormsReady] = useState(false);
  const checkFilledForms = () => {
    let stateSolicitudSeg = false;
    let stateAutoDebito = false;
    if (!thirdPartyPay) {
      //Check 2 forms filled
      formsFilled?.data?.map((item) => {
        if (item.type === "solicitud_seguro") {
          if (item.detail.estado === "Completo" && item?.url) {
            stateSolicitudSeg = true;
          }
        }
        if (item.type === "autorizacion_debito") {
          if (item.detail.estado === "Completo" && item?.url) {
            stateAutoDebito = true;
          }
        }
      });
    } else {
      // Just solicitud_seguro
      formsFilled?.data?.map((item) => {
        if (item.type === "solicitud_seguro") {
          if (item.detail.estado === "Completo" && item?.url) {
            stateSolicitudSeg = true;
            stateAutoDebito = true;
          }
        }
      });

    }
    return stateSolicitudSeg && stateAutoDebito;
  }
  useEffect(() => {
    if (!formsFilled?.loading && formsFilled?.data) {
      setIsFormsReady(checkFilledForms());
    }
  }, [!formsFilled?.loading, formsFilled?.data]);

  const uploadFileForm = async (fileData) => {
    let tempFormCliente = formCliente;
    try {
      let obj = {
        file: fileData.file,
        type: fileData.type,
        candidate_id: dataPrice?.id,
        email: loginListasOfac?.correoUsuario,
      };
      if (!obj?.file || !obj?.type || !obj?.candidate_id || !obj?.email)
        viewContext.notification.show("Archivo no seleccionado", "error");

      const respFile = await postFormSigned(obj);
      tempFormCliente.map((file) => {
        if (file.title === fileData.type) {
          file.url = respFile.data?.message[0].url;
        }
      });
      setFormCliente(tempFormCliente);
      viewContext.notification.show(
        "Archivo subído exitosamente!",
        "success",
        true
      );
      let reqUpdatePrice = {
        id: dataPrice?.id,
        estado: "Pendiente de documentos",
      };
      await Submit("candidate/updateStep", reqUpdatePrice, "patch").then(
        (resp) => {
          // viewContext.notification.show("Formulario guardado", "success", true);
        }
      );
    } catch (error) {
      console.error(error?.response?.data);
      viewContext.notification.show(error?.response?.data?.message, "error");
    }
  };

  const { handleSubmit, formState } = useForm();

  const { isSubmitting } = formState;

  function submitForm(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  }

  const [estadoEnvioOTP, setEstadoEnvioOTP] = useState("Pendiente");
  const sendOtp = async () => {
    if (!hasSolicitudSeguroUrl) {
      viewContext.notification.show("Primero debes presionar el boton 'Enviar a revisión del cliente' en el Paso 1", "error", false);
      return
    }
    if (thirdPartyPay) {
      if (!flagFormSolicitudFinished) {
        viewContext.notification.show("Debe completar los formularios para enviar la información", "error", true);
        setIsSendingForm(false);
        return;
      }
    } else {
      if (!flagFormSolicitudFinished || !flagFormAutoFinished) {
        viewContext.notification.show("Debe completar los formularios para enviar la información", "error", true);
        setIsSendingForm(false);
        return;
      }
    }
    let objSendOtp = {
      data: {
        astr_tipo_id_cliente: dataPrice?.detail?.tipo_documento,
        astr_num_id_cliente: dataPrice?.nro_documento,
        astr_codigo_agente: dataOfac.idUsuario,
        astr_codigo_ramo:
          dataPrice?.product?.product1?.plan?.cod_producto?.astr_ramo,
        astr_codigo_producto:
          dataPrice?.product?.product1?.plan?.cod_producto?.astr_id_producto,
        astr_num_celular: dataPrice?.detail?.telefono,
        astr_num_email: dataPrice?.detail?.correo,
      },
    };
    const respOTP = await enviaOtp(objSendOtp);
    if (respOTP?.astr_exitoso && respOTP?.astr_exitoso === "S") {
      viewContext.notification.show(
        `OTP enviado al celular: ${objSendOtp?.data?.astr_num_celular}`,
        "success",
        true
      );
      setEstadoEnvioOTP("Enviado");
      let objCandidateOtp = {};
      const candidateOtpV2 = await saveCandidateOtp(
        "candidate_otp/" + dataPrice.id
      );
      if (candidateOtpV2?.data && Object.keys(candidateOtpV2.data).length === 0) {
        objCandidateOtp = {
          candidate_id: dataPrice?.id,
          request: objSendOtp.data,
          response: respOTP,
          estado_autorizacion_cliente: "Enviado",
        };
        await Submit("candidate_otp", objCandidateOtp, "POST");
        let convertedDate = convertTZ(candidateOtpV2.data.updated_at);
        setFechaEnvioOtp(convertedDate.toString());
      } else {
        objCandidateOtp = {
          request: objSendOtp.data,
          response: respOTP,
          estado_autorizacion_cliente: "Reenviado",
        };
        await updateCandidateOtp(
          "candidate_otp/" + dataPrice.id,
          objCandidateOtp
        );
        setEstadoEnvioOTP("Reenviado");
        let convertedDate = convertTZ(candidateOtpV2.data.updated_at);
        setFechaEnvioOtp(convertedDate.toString());
      }
    } else {
      viewContext.notification.show(`OTP no enviado`, "error");
    }
  };

  const [estadoVerificarOTP, setEstadoVerificarOTP] = useState("Pendiente");
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  async function showVerificaOtpv2(item, data) {
    let res = item?.data?.data;
    setIsSendingOTP(true);
    if (res?.astr_exitoso && res?.astr_exitoso === "S") {
      context.notification.show("OTP verificado", "success", true);
      setEstadoVerificarOTP("Verificado");
      let objCandidateOtp = {
        codigo_otp: data.astr_OTP,
        estado_validacion_vendedor: "Validado",
      };
      //await enviarFormularios(true);
      await updateCandidateOtp(
        "candidate_otp/" + dataPrice.id,
        objCandidateOtp
      );
      let convertedDate = convertTZv2();
      setFechaVerificaOtp(convertedDate.toString());
      window.location.reload();
    } else {
      if (res?.astr_exitoso && res?.astr_exitoso === "N") {
        viewContext.notification.show(
          `OTP no enviado => ${res?.astr_msg_error}`,
          "error"
        );
        setEstadoVerificarOTP("Fallido");
      } else {
        viewContext.notification.show(`OTP no enviado`, "error");
        setEstadoVerificarOTP("Fallido");
      }
    }
    setIsSendingOTP(false);
    setOpen(false);
  }

  let urlCandidateOtp = "/api/candidate_otp/" + dataPrice.id;
  const [isCandidateOtpApiFetched, setIsCandidateOtpApiFetched] = useState(false);
  let candidateOtp = useAPI(!isCandidateOtpApiFetched ? urlCandidateOtp : '');
  const [fechaEnvioOtp, setFechaEnvioOtp] = useState("");
  const [fechaVerificaOtp, setFechaVerificaOtp] = useState("");
  const [isSendingForm, setIsSendingForm] = useState(false);
  const [isSendingOTP, setIsSendingOTP] = useState(false);
  const [isSendingDocs, setIsSendingDocs] = useState(false);
  const [otpValidado, setOtpValidado] = useState(false);

  useEffect(() => {
    if (!candidateOtp.loading && candidateOtp?.data && Object.keys(candidateOtp?.data).length > 0) {
      setEstadoEnvioOTP(candidateOtp.data.estado_autorizacion_cliente);
      setEstadoVerificarOTP(candidateOtp.data.estado_validacion_vendedor);
      let convertedDate1 = convertTZ(candidateOtp.data.created_at);
      setFechaEnvioOtp(convertedDate1.toString());
      let codigo_otp = candidateOtp.data.codigo_otp;
      let convertedDate2 = convertTZ(candidateOtp.data.updated_at);
      setFechaVerificaOtp(codigo_otp ? convertedDate2.toString() : "");
      if (candidateOtp?.data?.estado_validacion_vendedor && candidateOtp?.data?.estado_validacion_vendedor === "Validado") {
        setOtpValidado(true);
      }
    }
  }, [candidateOtp?.data, candidateOtp.loading], otpValidado);

  function convertTZ(date) {
    return new Date(date).toLocaleString("es-ES", {
      timeZone: "UTC",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    });
  }

  function convertTZv2() {
    return new Date().toLocaleString("en-US",
      { timeZone: "America/Guayaquil" }
    );
  }

  const setUrlFormUploaded = () => {
    if (formsUploadedCatalog?.data && formsUploadedCatalog?.data.length > 0) {
      let tempFormUploaded = formsUploadedCatalog.data;
      let tempFormCliente = formCliente;
      tempFormUploaded = tempFormUploaded.reverse();

      tempFormCliente.map((form) => {
        tempFormUploaded.map((file) => {
          if (form.title === file.type) {
            form.url = file?.url;
          }
        });
      });
      setFormCliente(tempFormCliente);
    }
  };

  const updateFinalPrice = async () => {
    setIsSendingDocs(true);
    let reqUpdatePrice = {
      id: dataPrice?.id,
      estado: "En suscripción",
      cotizacion: planSelect.idx + 1
    };
    await Submit("candidate/updateStep", reqUpdatePrice, "patch").then(
      (resp) => {
        viewContext.notification.show("Documentación enviada", "success", true);
        setIsSendingDocs(false);
      }
    );
  };

  const getEdad = (dateString) => {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  };

  const getElementsInArray = (arrayForm) => {
    let tempArrayForm = [];

    // if (Array.isArray(arrayForm) && arrayForm.length > 0) {
    let tempsObjects = Object.keys(arrayForm).filter((element) =>
      element.includes("PregAseg")
    );
    let lengthObject = tempsObjects.length / 5;

    for (let i = 0; i < lengthObject; i++) {
      let vecFechaTratamiento =
        arrayForm[`fechaTratamientoPregAseg${i}`]?.split("T");
      let strFechaTratamiento =
        vecFechaTratamiento?.length > 0
          ? vecFechaTratamiento[0]
          : arrayForm[`fechaTratamientoPregAseg${i}`];
      let fecha = new Date(strFechaTratamiento);
      let tempObj = {
        [`literalPregAseg`]: arrayForm.detailQuestion[i],
        [`diagnoticoTratamientoPregAseg`]:
          arrayForm[`diagnoticoTratamientoPregAseg${i}`],
        [`duracionTratamientoPregAseg`]:
          arrayForm[`duracionTratamientoPregAseg${i}`],
        [`medicoHospitalPregAseg`]: arrayForm[`medicoHospitalPregAseg${i}`],
        [`fechaTratamientoPregAseg`]:
          fecha.getFullYear() + "-" + (fecha.getUTCMonth() + 1),
      };
      tempArrayForm.push(tempObj);
    }
    return tempArrayForm;
  };

  const getExecutive = async () => {
    let user = JSON.parse(localStorage.getItem("loginListasOfac"));
    return user.correoUsuario;
  };
  let nombreEjecutivo = "";
  const setExecutiveName = (data) => {
    let executiveInfo = data;
    nombreEjecutivo = executiveInfo.nombre + " " + executiveInfo.apellido;
  };

  const getExecutiveType = async () => {
    return new Promise(async (resolve) => {
      let typeExecutive = "Seguros del Pichincha";
      let userLS = JSON.parse(localStorage.getItem("user"));
      if (userLS?.flag_uno) {
        resolve(typeExecutive)
      } else {
        let user = JSON.parse(localStorage.getItem("loginListasOfac"));
        await Submit(
          "invite/getByEmail?email=" + user.correoUsuario,
          null,
          "get"
        ).then((value) => {
          let objItermediary = value?.data?.data;
          if (objItermediary) {
            setExecutiveName(value?.data?.data?.intermediary);
            let curIntermediary = objItermediary.intermediary;
            let tipoSdp = typeof curIntermediary?.sdp;
            let tipoSponsor = typeof curIntermediary?.sponsor;
            let tipoFv = typeof curIntermediary?.fv;
            let tipoBroker = typeof curIntermediary?.broker;

            if (tipoSdp === "object") typeExecutive = "Seguros del Pichincha";
            if (tipoSponsor === "object")
              typeExecutive = curIntermediary?.sponsor?.astr_nombre_asesor;
            if (tipoFv === "object") typeExecutive = curIntermediary?.fv;
            if (tipoBroker === "object")
              typeExecutive = curIntermediary?.broker?.astr_nombre_asesor;

            resolve(typeExecutive);
          } else {
            resolve(typeExecutive);
          }
        });
      }
    });
  };

  const enviarFormularios = async (hasOPT) => {
    try {
      const formatCurrencyLocal = async (req) => {
        let newReq = [];
        for (let entry of req) {
          let curContext = entry.context;
          for (const property in curContext) {
            let curValue = curContext[property];
            if (typeof curValue === 'string' && curValue.toString().startsWith('$')) {
              let newCurrency = await formatToCurrency(curContext[property])
              curContext[property] = newCurrency;
            }
          }
          entry.context = curContext;
          newReq.push(entry);
        }
        return newReq;
      }

      setIsSendingForm(true);
      let data, dataAuth, idForm, idFormAuth, textFirmaDelegada;
      if (hasOPT) {
        textFirmaDelegada = 'Firma delegada con Código OTP';
      } else {
        textFirmaDelegada = estadoVerificarOTP === 'Pendiente' ? '' : 'Firma delegada con Código OTP';
      }
      if (thirdPartyPay) {
        if (!flagFormSolicitudFinished) {
          viewContext.notification.show("Debe completar los formularios para enviar la información", "error", true);
          setIsSendingForm(false);
          return;
        }
      } else {
        if (!flagFormSolicitudFinished || !flagFormAutoFinished) {
          viewContext.notification.show("Debe completar los formularios para enviar la información", "error", true);
          setIsSendingForm(false);
          return;
        }
      }

      formsFilled.data.map((itemAuth) => {
        if (itemAuth.type === "solicitud_seguro") {
          data = itemAuth.detail;
          idForm = itemAuth.id;
        }
        if (itemAuth.type === "autorizacion_debito") {
          dataAuth = itemAuth.detail;
          idFormAuth = itemAuth.id;

          let cedulaFlag = dataPrice?.detail?.tipo_documento === "00";
          dataAuth["ced"] = cedulaFlag ? "X" : "";
          dataAuth["pas"] = !cedulaFlag ? "X" : "";

          let isTarjeta = itemAuth?.detail?.medio.toString().toLowerCase() === "tarjeta";
          let isCte = itemAuth?.detail?.medio.toString().toLowerCase() === "cuenta corriente";
          dataAuth["aho"] = !isCte && !isTarjeta ? "X" : "";
          dataAuth["cor"] = isCte ? "X" : "";
          dataAuth["cred"] = isTarjeta ? "X" : "";
          dataAuth.textFirmaDelegada = textFirmaDelegada;
        }
      });

      let fechaActual = new Date();
      let dd = String(fechaActual.getDate()).padStart(2, "0");
      let mm = String(fechaActual.getMonth() + 1).padStart(2, "0");
      let yyyy = fechaActual.getFullYear();
      let fin_actual_str = "(dia) " + dd + " (mes) " + mm + " (año) " + yyyy;

      const coverturasAdicionales = data.coberturasAdicionales;
      data.coberturasAdicionales = coverturasAdicionales.filter(obj => obj !== null);
      let vecProv = data.provincia?.split("-");
      let vecTiempoCargo = data?.declaracionPoliticaPregunta1Tiempo ? data.declaracionPoliticaPregunta1Tiempo?.split("T") : [];
      let strTiempoCargo = vecTiempoCargo.length > 0 ? vecTiempoCargo[0] : data.declaracionPoliticaPregunta1Tiempo;
      let req = [];
      if (thirdPartyPay) {
        req = [{
          type: "solicitud_seguro",
          email: userProfileInfo.email,
          toEmail: planSelect?.plan?.[1].client?.correo.toLowerCase(),
          form_id: idForm,
          context: {
            textFirmaDelegada: textFirmaDelegada,
            primerApellido: data.primerApellido.toUpperCase(),
            segundoApellido: data.segundoApellido.toUpperCase(),
            primerNombre: data.primerNombre.toUpperCase(),
            segundoNombre: data.segundoNombre.toUpperCase(),
            c: data.tipoIdentificacion === "00" ? "X" : "",
            p: data.tipoIdentificacion === "01" ? "X" : "",
            numeroIdentificacion: data.numeroIdentificacion,
            m: data.genero === "1" ? "X" : "",
            f: data.genero === "2" ? "X" : "",
            o: "",
            fechaActual: fin_actual_str,
            edad: getEdad(data.fechaNacimiento),
            anio: data.fechaNacimiento?.split("-")[0],
            mes: data.fechaNacimiento?.split("-")[1],
            dia: data.fechaNacimiento?.split("-")[2],
            lugarNacimiento: data.lugarNacimiento,
            nacionalidad: data.nacionalidad,
            sol: data.estadoCivil === "SOLTERO/A" ? "X" : "",
            cas: data.estadoCivil === "CASADO/A" ? "X" : "",
            div: data.estadoCivil === "DIVORCIADO/A" ? "X" : "",
            sep: "",
            viu: data.estadoCivil === "VIUDO/A" ? "X" : "",
            uni: data.estadoCivil === "UNION DE HECHO" ? "X" : "",
            otro: "",
            direccionDomicilio: data.direccionDomicilio.toUpperCase(),
            provincia: data.provincia,
            canton: data.paisDomicilio === 'ECUADOR' ? data.canton : '',
            ciudad: data.paisDomicilio !== 'ECUADOR' ? data.ciudad : '',
            pais: data.paisDomicilio,
            celular: planSelect?.plan?.[1].client?.telefono,
            paisResidenciaFiscal: data.paisResidenciaFiscal,
            telefono2: data.telefono2,
            correo: planSelect?.plan?.[1].client?.correo,
            primerApellidoConyuge: data.primerApellidoConyuge,
            segundoApellidoConyuge: data.segundoApellidoConyuge,
            primerNombreConyuge: data.primerNombreConyuge,
            segundoNombreConyuge: data.segundoNombreConyuge,
            ci_co: data.tipoIdentificacionConyuge === "Cedula" ? "X" : "",
            pa_co: data.tipoIdentificacionConyuge === "Pasaporte" ? "X" : "",
            numeroIdentificacionConyuge: data.numeroIdentificacionConyuge,
            ep: data.actividadPrincipal === "Empleado privado" ? "X" : "",
            eu: data.actividadPrincipal === "Empleado público" ? "X" : "",
            ju: data.actividadPrincipal === "Jubilado" ? "X" : "",
            est: data.actividadPrincipal === "Estudiante" ? "X" : "",
            que: data.actividadPrincipal === "Quehaceres domésticos" ? "X" : "",
            nombreEmpresa: data.nombreEmpresa.toUpperCase(),
            actividadEmpresa: data.actividadEmpresa.toUpperCase(),
            actividades:
              data.opcion2PreguntaPreliminar2 ||
                data.opcion3PreguntaPreliminar2 ||
                data.opcion4PreguntaPreliminar2
                ? "X"
                : "",
            noActividades:
              !data.opcion2PreguntaPreliminar2 &&
                !data.opcion3PreguntaPreliminar2 &&
                !data.opcion4PreguntaPreliminar2
                ? "X"
                : "",
            cargo: data.cargo.toUpperCase(),
            aniosEmpleo: data.aniosEmpleo,
            actividadIndependienteNegocio: data.actividadIndependienteNegocio,
            direccionActividadEconomica:
              data.direccionActividadEconomica.toUpperCase(),
            provinciaActividadEconomica: data.provinciaActividadEconomica,
            cantonActividadEconomica: data.paisDomicilioActividadEconomica === 'ECUADOR' ? data.cantonActividadEconomica : '',
            ciudadActividadEconomica: data.paisDomicilioActividadEconomica !== 'ECUADOR' ? data.ciudadActividadEconomica : '',
            paisDomicilioActividadEconomica: data.paisDomicilioActividadEconomica,
            telefonoLaboralActividadEconomica:
              data.telefonoLaboralActividadEconomica,
            correoElectronicoLaboral:
              data.correoElectronicoLaboral.toUpperCase(),
            ingresoMensualActividadEconomica:
              data.ingresoMensualActividadEconomica,
            otroIngresoDiferenteActividadEconomica:
              data.otroIngresoDiferenteActividadEconomica,
            fuentesOtrosIngresos: data.fuenteOtrosIngresos.toUpperCase(),
            totalIngresoMensuales: data.totalIngresoMensuales,
            totalEgresosMensuales: data.totalEgresosMensuales,
            totalActivos: data.totalActivos,
            totalPasivos: data.totalPasivos,
            si_info:
              data.solicitantePolizaDiferenteAsegurado === "Si" ? "X" : "",
            no_info:
              data.solicitantePolizaDiferenteAsegurado === "No" ? "X" : "",
            persona_natural:
              data.tipoPersonaSolicitanteDiferenteAsegurado === "N"
                ? data.nombreCompleto
                : "",
            persona_juridica:
              data.tipoPersonaSolicitanteDiferenteAsegurado === "J"
                ? data.nombreCompleto
                : "",
            labels_datos_beneficiarios: [
              "nombreCompletoBeneficiario",
              "porcentajeBeneficiario",
              "parentescoBeneficiario",
              "numeroIdentificacionBeneficiario",
              "numeroTelefonoBeneficiario",
              "correoElectronicoBeneficiario",
            ],
            datos_beneficiarios: data?.beneficiariosPrincipales,
            labels_datos_beneficiarios_con: [
              "nombreCompletoBeneficiarioContingente",
              "porcentajeBeneficiarioContingente",
              "parentescoBeneficiarioContingente",
              "numeroIdentificacionBeneficiarioContingente",
              "telefonoBeneficiarioContingente",
              "correoElectronicoBeneficiarioContingente",
            ],
            datos_beneficiarios_con: data?.beneficiariosContigentes,
            producto: data.producto,
            coberturaPrincipal: data.coberturaPrincipal,
            sumaAsegurada: data.sumaAsegurada,
            coberturaAdicional:
              data.coberturasAdicionales.length > 0
                ? data.coberturasAdicionales[0].coberturaAdicional
                : "",
            sumaAseguradaAdicional:
              data.coberturasAdicionales.length > 0
                ? data.coberturasAdicionales[0].sumaAsegurada
                : "",
            mensual: data.modalidadPago === "MENSUAL" ? "X" : "",
            trimes: data.modalidadPago === "TRIMESTRAL" ? "X" : "",
            semestral: data.modalidadPago === "SEMESTRAL" ? "X" : "",
            anual: data.modalidadPago === "ANUAL" ? "X" : "",
            aporte: data.Aporte,
            tieneActualmenteSeguroVida:
              data.tieneActualmenteSeguroVida === "Si" ? "X" : "",
            noTieneActualmenteSeguroVida:
              data.tieneActualmenteSeguroVida === "No" ? "X" : "",
            compania: data.compania.toUpperCase(),
            montoAsegurado: data.montoAsegurado,
            anioEmision: data.anioEmision,
            polizaReemplazaPolizaVigente:
              data.polizaReemplazaPolizaVigente === "Si" ? "X" : "",
            polizaNoReemplazaPolizaVigente:
              data.polizaReemplazaPolizaVigente === "No" ? "X" : "",
            polizaPlazo: data.polizaPlazo,
            solicitudPendienteCompania:
              data.solicitudPendienteCompania === "Si" ? "X" : "",
            noSolicitudPendienteCompania:
              data.solicitudPendienteCompania === "No" ? "X" : "",
            nombreCompania: data.nombreCompania,
            beneficiosSeguroVidaUltimosAnios:
              data.beneficiosSeguroVidaUltimosAnios === "Si" ? "X" : "",
            NoBeneficiosSeguroVidaUltimosAnios:
              data.beneficiosSeguroVidaUltimosAnios === "No" ? "X" : "",
            describeBeneficio: data.describeBeneficio.toUpperCase(),
            companiaSeguros: data.companiaSeguros.toUpperCase(),
            tipoPoliza: data.tipoPoliza.toUpperCase(),
            monto: data.monto,
            pesoKg: data.pesoKg,
            alturaCm: data.alturaCm,
            imc: (
              parseFloat(data.pesoKg) /
              ((parseFloat(data.alturaCm) / 100) *
                (parseFloat(data.alturaCm) / 100))
            )
              .toFixed(0)
              .toString(),
            fuma: data.fuma === "Si" ? "X" : "",
            noFuma: data.fuma === "No" ? "X" : "",
            cambioPeso: data.cambioPeso === "Si" ? "X" : "",
            noCambioPeso: data.cambioPeso === "No" ? "X" : "",
            aumentoPeso: data.aumentoPeso === "Si" ? "X" : "",
            noAumentoPeso: data.aumentoPeso === "No" ? "X" : "",
            causaCambioPeso: data.causaCambioPeso,
            consultaMedico: data.preguntaPreliminar1 === "Si" ? "X" : "",
            noConsultaMedico: data.preguntaPreliminar1 === "No" ? "X" : "",
            esPilotoActividades: data.preguntaPreliminar2 === "Si" ? "X" : "",
            noEsPilotoActividades: data.preguntaPreliminar2 === "No" ? "X" : "",
            esPiloto: data.preguntaPreliminar2 === "Si" ? "X" : "",
            noEsPiloto: data.preguntaPreliminar2 === " No" ? "X" : "",
            consumoSubstancia: data.preguntaPreliminar3 === "Si" ? "X" : "",
            noConsumoSubstancia: data.preguntaPreliminar3 === "No" ? "X" : "",
            medicamentosTratamiento:
              data.preguntaPreliminar4 === "Si" ? "X" : "",
            noMedicamentosTratamiento:
              data.preguntaPreliminar4 === "No" ? "X" : "",
            dolorPecho: data.preguntaPreliminar5 === "Si" ? "X" : "",
            noDolorPecho: data.preguntaPreliminar5 === "No" ? "X" : "",
            mareo: data.preguntaPreliminar6 === "Si" ? "X" : "",
            noMareo: data.preguntaPreliminar6 === "No" ? "X" : "",
            cancer: data.preguntaPreliminar7 === "Si" ? "X" : "",
            noCancer: data.preguntaPreliminar7 === "No" ? "X" : "",
            vih: data.preguntaPreliminar8 === "Si" ? "X" : "",
            noVih: data.preguntaPreliminar8 === "No" ? "X" : "",
            anemia: data.preguntaPreliminar9 === "Si" ? "X" : "",
            noAnemia: data.preguntaPreliminar9 === "No" ? "X" : "",
            ahogo: data.preguntaPreliminar10 === "Si" ? "X" : "",
            noAhogo: data.preguntaPreliminar10 === "No" ? "X" : "",
            hepatitis: data.preguntaPreliminar11 === "Si" ? "X" : "",
            noHepatitis: data.preguntaPreliminar11 === "No" ? "X" : "",
            azucar: data.preguntaPreliminar12 === "Si" ? "X" : "",
            noAzucar: data.preguntaPreliminar12 === "No" ? "X" : "",
            diabetes: data.preguntaPreliminar13 === "Si" ? "X" : "",
            noDiabetes: data.preguntaPreliminar13 === "No" ? "X" : "",
            artritis: data.preguntaPreliminar14 === "Si" ? "X" : "",
            noArtritis: data.preguntaPreliminar14 === "No" ? "X" : "",
            ojos: data.preguntaPreliminar15 === "Si" ? "X" : "",
            noOjos: data.preguntaPreliminar15 === "No" ? "X" : "",
            operacion: data.preguntaPreliminar16 === "Si" ? "X" : "",
            noOperacion: data.preguntaPreliminar16 === "No" ? "X" : "",
            quimio: data.preguntaPreliminar17 === "Si" ? "X" : "",
            noQuimio: data.preguntaPreliminar17 === "No" ? "X" : "",
            historial: data.preguntaPreliminar18 === "Si" ? "X" : "",
            noHistorial: data.preguntaPreliminar18 === "No" ? "X" : "",
            dieta: data.preguntaPreliminar19 === "Si" ? "X" : "",
            noDieta: data.preguntaPreliminar19 === "No" ? "X" : "",
            rechazo: data.preguntaPreliminar20 === "Si" ? "X" : "",
            noRechazo: data.preguntaPreliminar20 === "No" ? "X" : "",
            admitido: data.preguntaPreliminar21 === "Si" ? "X" : "",
            noAdmitido: data.preguntaPreliminar21 === "No" ? "X" : "",
            chequeo: data.preguntaPreliminar22 === "Si" ? "X" : "",
            noChequeo: data.preguntaPreliminar22 === "No" ? "X" : "",
            utero: data.preguntaPreliminar23 === "Si" ? "X" : "",
            noUtero: data.preguntaPreliminar23 === "No" ? "X" : "",
            anticonceptivo: data.preguntaPreliminar24 === "Si" ? "X" : "",
            noAnticonceptivo: data.preguntaPreliminar24 === "No" ? "X" : "",
            embarazada: data.preguntaPreliminar25 === "Si" ? "X" : "",
            noEmbarazada: data.preguntaPreliminar25 === "No" ? "X" : "",
            semanas:
              data.preguntaPreliminar25 === "Si"
                ? data.semanasPreguntaPreliminar25
                : "",
            prostata: data.preguntaPreliminar26 === "Si" ? "X" : "",
            noProstata: data.preguntaPreliminar26 === "No" ? "X" : "",
            pregAdicionales: data.literal11,
            fechaTratamiento: data.fechaTratamiento,
            diagnostico: data.diagnoticoTratamiento,
            duracionTratamiento: data.duracionTratamiento,
            medicoTratamiento: data.medicoHospital0,
            tabaco: data.estiloVidaPregunta4 === "Si" ? "X" : "",
            noTabaco: data.estiloVidaPregunta4.trim() === "No" ? "X" : "",
            frecuencia: data.estiloVidaPregunta4Frecuencia,
            cantidad: data.estiloVidaPregunta4Cantidad,
            arresto: data.estiloVidaPregunta5 === "Si" ? "X" : "",
            noArresto: data.estiloVidaPregunta5.trim() === "No" ? "X" : "",
            manejar: data.estiloVidaPregunta6 === "Si" ? "X" : "",
            noManejar: data.estiloVidaPregunta6.trim() === "No" ? "X" : "",
            viajado: data.estiloVidaPregunta7 === "Si" ? "X" : "",
            noViajado: data.estiloVidaPregunta7.trim() === "No" ? "X" : "",
            labels_lugares: ["lugar"],
            lugares: data?.estiloVidaPregunta7Datos,
            viaje: data.estiloVidaPregunta8 === "Si" ? "X" : "",
            noViaje: data.estiloVidaPregunta8.trim() === "No" ? "X" : "",
            lugar: data?.estiloVidaPregunta8Datos1?.toUpperCase(),
            trabajoViaje: data.estiloVidaPregunta9 === "Si" ? "X" : "",
            noTrabajoViaje: data.estiloVidaPregunta9.trim() === "No" ? "X" : "",
            beneDisca: data.estiloVidaPregunta10 === "Si" ? "X" : "",
            noBeneDisca: data.estiloVidaPregunta10.trim() === "No" ? "X" : "",
            bebidas: data.estiloVidaPregunta11 === "Si" ? "X" : "",
            noBebidas: data.estiloVidaPregunta11.trim() === "No" ? "X" : "",
            frecuenciaTomar: data.estiloVidaPregunta11Frecuencia,
            cantidadTomar: data.estiloVidaPregunta11Cantidad,
            deporte: data.estiloVidaPregunta12 === "Si" ? "X" : "",
            noDeporte: data.estiloVidaPregunta12.trim() === "No" ? "X" : "",
            nombreDeporte: data?.estiloVidaPregunta12Deporte,
            aficionado: data?.estiloVidaPregunta12Deporte,
            profesional: data?.estiloVidaPregunta12Deporte,
            labels_preguntaAdicional: [
              "literalPregAseg",
              "fechaTratamientoPregAseg",
              "diagnosticoTratamientoPregAseg",
              "duracionTratamientoPregAseg",
              "medicoHospitalPregAseg",
            ],
            preguntaAdicional: getElementsInArray(data),
            labels_detallesEstiloVida: ["numero", "detalle"],
            detallesEstiloVida: data?.preguntaAdicional1Numero,
            cargoPublico: data.declaracionPoliticaPregunta1 === "Si" ? "X" : "",
            noCargoPublico:
              data.declaracionPoliticaPregunta1.trim() === "No" ? "X" : "",
            institucionCargo: data.declaracionPoliticaPregunta1Institucion,
            cargoPub: data.declaracionPoliticaPregunta1Cargo,
            tiempoCargo: strTiempoCargo,
            familiarPep: data.declaracionPoliticaPregunta2 === "Si" ? "X" : "",
            noFamiliarPep:
              data.declaracionPoliticaPregunta2 === "No" ? "X" : "",
            nombreFamiliar: data.declaracionPoliticaPregunta2NombreApellido,
            institucionPep: data.declaracionPoliticaPregunta2Institucion,
            cargoPep: data.declaracionPoliticaPregunta2Cargo,
            parentescoPep: data.declaracionPoliticaPregunta2Parentesco,
            nombreAgencia: await getExecutiveType(),
            correoEjecutivo: await getExecutive(),
            nombreEjecutivo: nombreEjecutivo,
            broker: await getExecutiveType(),
          },
        }];
        //Formatting currency values
        let newReq = await formatCurrencyLocal(req);
        await postFillForms(newReq).then((resp) => {
          if (resp) {
            setIsFormsReady(true);
            setHasSolicitudSeguroUrl(true);
            viewContext.notification.show(
              resp?.message ? resp.message : "Documentos enviados",
              "success",
              false
            );
          } else {
            viewContext.notification.show("No se pudo enviar el correo", "error", true);
          }
        });
      } else {
        req = [
          {
            type: "solicitud_seguro",
            email: userProfileInfo.email,
            toEmail: planSelect?.plan?.[1].client?.correo.toLowerCase(),
            form_id: idForm,
            context: {
              textFirmaDelegada: textFirmaDelegada,
              primerApellido: data.primerApellido.toUpperCase(),
              segundoApellido: data.segundoApellido.toUpperCase(),
              primerNombre: data.primerNombre.toUpperCase(),
              segundoNombre: data.segundoNombre.toUpperCase(),
              c: data.tipoIdentificacion === "00" ? "X" : "",
              p: data.tipoIdentificacion === "01" ? "X" : "",
              numeroIdentificacion: data.numeroIdentificacion,
              m: data.genero === "1" ? "X" : "",
              f: data.genero === "2" ? "X" : "",
              o: "",
              fechaActual: fin_actual_str,
              edad: getEdad(data.fechaNacimiento),
              anio: data.fechaNacimiento?.split("-")[0],
              mes: data.fechaNacimiento?.split("-")[1],
              dia: data.fechaNacimiento?.split("-")[2],
              lugarNacimiento: data.lugarNacimiento,
              nacionalidad: data.nacionalidad,
              sol: data.estadoCivil === "SOLTERO/A" ? "X" : "",
              cas: data.estadoCivil === "CASADO/A" ? "X" : "",
              div: data.estadoCivil === "DIVORCIADO/A" ? "X" : "",
              sep: "",
              viu: data.estadoCivil === "VIUDO/A" ? "X" : "",
              uni: data.estadoCivil === "UNION DE HECHO" ? "X" : "",
              otro: "",
              direccionDomicilio: data.direccionDomicilio.toUpperCase(),
              provincia: data?.provincia,
              canton: data.paisDomicilio === 'ECUADOR' ? data.canton : '',
              ciudad: data.paisDomicilio !== 'ECUADOR' ? data.ciudad : '',
              pais: data.paisDomicilio,
              celular: planSelect?.plan?.[1].client?.telefono,
              paisResidenciaFiscal: data.paisResidenciaFiscal,
              telefono2: data.telefono2,
              correo: planSelect?.plan?.[1].client?.correo,
              primerApellidoConyuge: data.primerApellidoConyuge,
              segundoApellidoConyuge: data.segundoApellidoConyuge,
              primerNombreConyuge: data.primerNombreConyuge,
              segundoNombreConyuge: data.segundoNombreConyuge,
              ci_co: data.tipoIdentificacionConyuge === "Cedula" ? "X" : "",
              pa_co: data.tipoIdentificacionConyuge === "Pasaporte" ? "X" : "",
              numeroIdentificacionConyuge: data.numeroIdentificacionConyuge,
              ep: data.actividadPrincipal === "Empleado privado" ? "X" : "",
              eu: data.actividadPrincipal === "Empleado público" ? "X" : "",
              ju: data.actividadPrincipal === "Jubilado" ? "X" : "",
              est: data.actividadPrincipal === "Estudiante" ? "X" : "",
              que:
                data.actividadPrincipal === "Quehaceres domésticos" ? "X" : "",
              nombreEmpresa: data.nombreEmpresa.toUpperCase(),
              actividadEmpresa: data.actividadEmpresa.toUpperCase(),
              actividades:
                data.opcion2PreguntaPreliminar2 ||
                  data.opcion3PreguntaPreliminar2 ||
                  data.opcion4PreguntaPreliminar2
                  ? "X"
                  : "",
              noActividades:
                !data.opcion2PreguntaPreliminar2 &&
                  !data.opcion3PreguntaPreliminar2 &&
                  !data.opcion4PreguntaPreliminar2
                  ? "X"
                  : "",
              cargo: data.cargo.toUpperCase(),
              aniosEmpleo: data.aniosEmpleo,
              actividadIndependienteNegocio: data.actividadIndependienteNegocio,
              direccionActividadEconomica:
                data.direccionActividadEconomica.toUpperCase(),
              provinciaActividadEconomica: data.provinciaActividadEconomica,
              cantonActividadEconomica: data.paisDomicilioActividadEconomica === 'ECUADOR' ? data.cantonActividadEconomica : '',
              ciudadActividadEconomica: data.paisDomicilioActividadEconomica !== 'ECUADOR' ? data.ciudadActividadEconomica : '',
              paisDomicilioActividadEconomica: data.paisDomicilioActividadEconomica,
              telefonoLaboralActividadEconomica:
                data.telefonoLaboralActividadEconomica,
              correoElectronicoLaboral:
                data.correoElectronicoLaboral.toUpperCase(),
              ingresoMensualActividadEconomica:
                data.ingresoMensualActividadEconomica,
              otroIngresoDiferenteActividadEconomica:
                data.otroIngresoDiferenteActividadEconomica,
              fuentesOtrosIngresos: data.fuenteOtrosIngresos.toUpperCase(),
              totalIngresoMensuales: data.totalIngresoMensuales,
              totalEgresosMensuales: data.totalEgresosMensuales,
              totalActivos: data.totalActivos,
              totalPasivos: data.totalPasivos,
              si_info:
                data.solicitantePolizaDiferenteAsegurado === "Si" ? "X" : "",
              no_info:
                data.solicitantePolizaDiferenteAsegurado === "No" ? "X" : "",
              persona_natural:
                data.tipoPersonaSolicitanteDiferenteAsegurado === "N"
                  ? data.nombreCompleto
                  : "",
              persona_juridica:
                data.tipoPersonaSolicitanteDiferenteAsegurado === "J"
                  ? data.nombreCompleto
                  : "",
              labels_datos_beneficiarios: [
                "nombreCompletoBeneficiario",
                "porcentajeBeneficiario",
                "parentescoBeneficiario",
                "numeroIdentificacionBeneficiario",
                "numeroTelefonoBeneficiario",
                "correoElectronicoBeneficiario",
              ],
              datos_beneficiarios: data?.beneficiariosPrincipales,
              labels_datos_beneficiarios_con: [
                "nombreCompletoBeneficiarioContingente",
                "porcentajeBeneficiarioContingente",
                "parentescoBeneficiarioContingente",
                "numeroIdentificacionBeneficiarioContingente",
                "telefonoBeneficiarioContingente",
                "correoElectronicoBeneficiarioContingente",
              ],
              datos_beneficiarios_con: data?.beneficiariosContigentes,
              producto: data.producto,
              coberturaPrincipal: data.coberturaPrincipal,
              sumaAsegurada: data.sumaAsegurada,
              coberturaAdicional:
                data.coberturasAdicionales.length > 0
                  ? data.coberturasAdicionales[0].coberturaAdicional
                  : "",
              sumaAseguradaAdicional:
                data.coberturasAdicionales.length > 0
                  ? data.coberturasAdicionales[0].sumaAsegurada
                  : "",
              mensual: data.modalidadPago === "MENSUAL" ? "X" : "",
              trimes: data.modalidadPago === "TRIMESTRAL" ? "X" : "",
              semestral: data.modalidadPago === "SEMESTRAL" ? "X" : "",
              anual: data.modalidadPago === "ANUAL" ? "X" : "",
              aporte: data.Aporte,
              tieneActualmenteSeguroVida:
                data.tieneActualmenteSeguroVida === "Si" ? "X" : "",
              noTieneActualmenteSeguroVida:
                data.tieneActualmenteSeguroVida === "No" ? "X" : "",
              compania: data.compania.toUpperCase(),
              montoAsegurado: data.montoAsegurado,
              anioEmision: data.anioEmision,
              polizaReemplazaPolizaVigente:
                data.polizaReemplazaPolizaVigente === "Si" ? "X" : "",
              polizaNoReemplazaPolizaVigente:
                data.polizaReemplazaPolizaVigente === "No" ? "X" : "",
              polizaPlazo: data.polizaPlazo,
              solicitudPendienteCompania:
                data.solicitudPendienteCompania === "Si" ? "X" : "",
              noSolicitudPendienteCompania:
                data.solicitudPendienteCompania === "No" ? "X" : "",
              nombreCompania: data.nombreCompania,
              beneficiosSeguroVidaUltimosAnios:
                data.beneficiosSeguroVidaUltimosAnios === "Si" ? "X" : "",
              NoBeneficiosSeguroVidaUltimosAnios:
                data.beneficiosSeguroVidaUltimosAnios === "No" ? "X" : "",
              describeBeneficio: data.describeBeneficio.toUpperCase(),
              companiaSeguros: data.companiaSeguros.toUpperCase(),
              tipoPoliza: data.tipoPoliza.toUpperCase(),
              monto: data.monto,
              pesoKg: data.pesoKg,
              alturaCm: data.alturaCm,
              imc: (
                parseFloat(data.pesoKg) /
                ((parseFloat(data.alturaCm) / 100) *
                  (parseFloat(data.alturaCm) / 100))
              )
                .toFixed(0)
                .toString(),
              fuma: data.fuma === "Si" ? "X" : "",
              noFuma: data.fuma === "No" ? "X" : "",
              cambioPeso: data.cambioPeso === "Si" ? "X" : "",
              noCambioPeso: data.cambioPeso === "No" ? "X" : "",
              aumentoPeso: data.aumentoPeso === "Si" ? "X" : "",
              noAumentoPeso: data.aumentoPeso === "No" ? "X" : "",
              causaCambioPeso: data.causaCambioPeso,
              consultaMedico: data.preguntaPreliminar1 === "Si" ? "X" : "",
              noConsultaMedico: data.preguntaPreliminar1 === "No" ? "X" : "",
              esPilotoActividades: data.preguntaPreliminar2 === "Si" ? "X" : "",
              noEsPilotoActividades:
                data.preguntaPreliminar2 === "No" ? "X" : "",
              esPiloto: data.preguntaPreliminar2 === "Si" ? "X" : "",
              noEsPiloto: data.preguntaPreliminar2 === "No" ? "X" : "",
              consumoSubstancia: data.preguntaPreliminar3 === "Si" ? "X" : "",
              noConsumoSubstancia: data.preguntaPreliminar3 === "No" ? "X" : "",
              medicamentosTratamiento:
                data.preguntaPreliminar4 === "Si" ? "X" : "",
              noMedicamentosTratamiento:
                data.preguntaPreliminar4 === "No" ? "X" : "",
              dolorPecho: data.preguntaPreliminar5 === "Si" ? "X" : "",
              noDolorPecho: data.preguntaPreliminar5 === "No" ? "X" : "",
              mareo: data.preguntaPreliminar6 === "Si" ? "X" : "",
              noMareo: data.preguntaPreliminar6 === "No" ? "X" : "",
              cancer: data.preguntaPreliminar7 === "Si" ? "X" : "",
              noCancer: data.preguntaPreliminar7 === "No" ? "X" : "",
              vih: data.preguntaPreliminar8 === "Si" ? "X" : "",
              noVih: data.preguntaPreliminar8 === "No" ? "X" : "",
              anemia: data.preguntaPreliminar9 === "Si" ? "X" : "",
              noAnemia: data.preguntaPreliminar9 === "No" ? "X" : "",
              ahogo: data.preguntaPreliminar10 === "Si" ? "X" : "",
              noAhogo: data.preguntaPreliminar10 === "No" ? "X" : "",
              hepatitis: data.preguntaPreliminar11 === "Si" ? "X" : "",
              noHepatitis: data.preguntaPreliminar11 === "No" ? "X" : "",
              azucar: data.preguntaPreliminar12 === "Si" ? "X" : "",
              noAzucar: data.preguntaPreliminar12 === "No" ? "X" : "",
              diabetes: data.preguntaPreliminar13 === "Si" ? "X" : "",
              noDiabetes: data.preguntaPreliminar13 === "No" ? "X" : "",
              artritis: data.preguntaPreliminar14 === "Si" ? "X" : "",
              noArtritis: data.preguntaPreliminar14 === "No" ? "X" : "",
              ojos: data.preguntaPreliminar15 === "Si" ? "X" : "",
              noOjos: data.preguntaPreliminar15 === "No" ? "X" : "",
              operacion: data.preguntaPreliminar16 === "Si" ? "X" : "",
              noOperacion: data.preguntaPreliminar16 === "No" ? "X" : "",
              quimio: data.preguntaPreliminar17 === "Si" ? "X" : "",
              noQuimio: data.preguntaPreliminar17 === "No" ? "X" : "",
              historial: data.preguntaPreliminar18 === "Si" ? "X" : "",
              noHistorial: data.preguntaPreliminar18 === "No" ? "X" : "",
              dieta: data.preguntaPreliminar19 === "Si" ? "X" : "",
              noDieta: data.preguntaPreliminar19 === "No" ? "X" : "",
              rechazo: data.preguntaPreliminar20 === "Si" ? "X" : "",
              noRechazo: data.preguntaPreliminar20 === "No" ? "X" : "",
              admitido: data.preguntaPreliminar21 === "Si" ? "X" : "",
              noAdmitido: data.preguntaPreliminar21 === "No" ? "X" : "",
              chequeo: data.preguntaPreliminar22 === "Si" ? "X" : "",
              noChequeo: data.preguntaPreliminar22 === "No" ? "X" : "",
              utero: data.preguntaPreliminar23 === "Si" ? "X" : "",
              noUtero: data.preguntaPreliminar23 === "No" ? "X" : "",
              anticonceptivo: data.preguntaPreliminar24 === "Si" ? "X" : "",
              noAnticonceptivo: data.preguntaPreliminar24 === "No" ? "X" : "",
              embarazada: data.preguntaPreliminar25 === "Si" ? "X" : "",
              noEmbarazada: data.preguntaPreliminar25 === "No" ? "X" : "",
              semanas:
                data.preguntaPreliminar25 === "Si"
                  ? data.semanasPreguntaPreliminar25
                  : "",
              prostata: data.preguntaPreliminar26 === "Si" ? "X" : "",
              noProstata: data.preguntaPreliminar26 === "No" ? "X" : "",
              pregAdicionales: data.literal11,
              fechaTratamiento: data.fechaTratamiento,
              diagnostico: data.diagnoticoTratamiento,
              duracionTratamiento: data.duracionTratamiento,
              medicoTratamiento: data.medicoHospital0,
              tabaco: data.estiloVidaPregunta4 === "Si" ? "X" : "",
              noTabaco: data.estiloVidaPregunta4.trim() === "No" ? "X" : "",
              frecuencia: data.estiloVidaPregunta4Frecuencia,
              cantidad: data.estiloVidaPregunta4Cantidad,
              arresto: data.estiloVidaPregunta5 === "Si" ? "X" : "",
              noArresto: data.estiloVidaPregunta5.trim() === "No" ? "X" : "",
              manejar: data.estiloVidaPregunta6 === "Si" ? "X" : "",
              noManejar: data.estiloVidaPregunta6.trim() === "No" ? "X" : "",
              viajado: data.estiloVidaPregunta7 === "Si" ? "X" : "",
              noViajado: data.estiloVidaPregunta7.trim() === "No" ? "X" : "",
              labels_lugares: ["lugar"],
              lugares: data?.estiloVidaPregunta7Datos,
              viaje: data.estiloVidaPregunta8 === "Si" ? "X" : "",
              noViaje: data.estiloVidaPregunta8.trim() === "No" ? "X" : "",
              lugar: data?.estiloVidaPregunta8Datos1?.toUpperCase(),
              trabajoViaje: data.estiloVidaPregunta9 === "Si" ? "X" : "",
              noTrabajoViaje:
                data.estiloVidaPregunta9.trim() === "No" ? "X" : "",
              beneDisca: data.estiloVidaPregunta10 === "Si" ? "X" : "",
              noBeneDisca: data.estiloVidaPregunta10.trim() === "No" ? "X" : "",
              bebidas: data.estiloVidaPregunta11 === "Si" ? "X" : "",
              noBebidas: data.estiloVidaPregunta11.trim() === "No" ? "X" : "",
              frecuenciaTomar: data.estiloVidaPregunta11Frecuencia,
              cantidadTomar: data.estiloVidaPregunta11Cantidad,
              deporte: data.estiloVidaPregunta12 === "Si" ? "X" : "",
              noDeporte: data.estiloVidaPregunta12.trim() === "No" ? "X" : "",
              nombreDeporte: data?.estiloVidaPregunta12Deporte,
              aficionado: data?.estiloVidaPregunta12Deporte,
              profesional: data?.estiloVidaPregunta12Deporte,
              labels_preguntaAdicional: [
                "literalPregAseg",
                "fechaTratamientoPregAseg",
                "diagnosticoTratamientoPregAseg",
                "duracionTratamientoPregAseg",
                "medicoHospitalPregAseg",
              ],
              preguntaAdicional: getElementsInArray(data),
              labels_detallesEstiloVida: ["numero", "detalle"],
              detallesEstiloVida: data?.preguntaAdicional1Numero,
              cargoPublico:
                data.declaracionPoliticaPregunta1 === "Si" ? "X" : "",
              noCargoPublico:
                data.declaracionPoliticaPregunta1.trim() === "No" ? "X" : "",
              institucionCargo: data.declaracionPoliticaPregunta1Institucion,
              cargoPub: data.declaracionPoliticaPregunta1Cargo,
              tiempoCargo: strTiempoCargo,
              familiarPep:
                data.declaracionPoliticaPregunta2 === "Si" ? "X" : "",
              noFamiliarPep:
                data.declaracionPoliticaPregunta2 === "No" ? "X" : "",
              nombreFamiliar: data.declaracionPoliticaPregunta2NombreApellido,
              institucionPep: data.declaracionPoliticaPregunta2Institucion,
              cargoPep: data.declaracionPoliticaPregunta2Cargo,
              parentescoPep: data.declaracionPoliticaPregunta2Parentesco,
              nombreAgencia: await getExecutiveType(),
              correoEjecutivo: await getExecutive(),
              nombreEjecutivo: nombreEjecutivo,
              broker: await getExecutiveType(),
            },
          },
          {
            type: "autorizacion_debito",
            email: userProfileInfo.email,
            toEmail: planSelect?.plan?.[1].client?.correo.toLowerCase(),
            form_id: idFormAuth,
            context: dataAuth,
          },
        ];
        //Formatting currency values
        let newReq = await formatCurrencyLocal(req);
        await postFillForms(newReq).then((resp) => {
          if (resp) {
            setIsFormsReady(true);
            setHasSolicitudSeguroUrl(true);
            viewContext.notification.show(
              resp?.message ? resp.message : "Documentos enviados",
              "success",
              false
            );
          } else {
            viewContext.notification.show("No se pudo enviar el correo", "error", true);
          }
        });
      }
      setIsSendingForm(false);
      getFormValidData();
    } catch (error) {
      viewContext.notification.show(error, "error", true);
      console.error(error);
      setIsSendingForm(false);
    }
  };

  const formatToCurrency = async (valueToParse) => {
    let numberStr = Number(valueToParse.replace(/[^0-9.-]+/g, ""));
    let USDollar = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    return USDollar.format(numberStr)

  }

  const sendTablaAhorro = async () => {
    let idForm;
    formsFilled?.data?.map((item) => {
      idForm = item.id;
    });

    let vecAniosAporte = curDataProjectionObj.map((item) => {
      return item.aint_anio;
    });
    let vecEdad = curDataProjectionObj.map((item) => {
      return item.adec_edad;
    });
    let vecCuotaTotalAnual = curDataProjectionObj.map((item) => {
      return item.adec_prima_total;
    });
    let vecValorDisponible = curDataProjectionObj.map((item) => {
      return item.adec_tasa_garantizada;
    });
    let vecBeneficioMuerte = curDataProjectionObj.map((item) => {
      return item.adec_valor_prima_ahorro;
    });
    let vecValorRescate = curDataProjectionObj.map((item) => {
      return item.adec_tasa_proyectada;
    });

    let curDataProjectionObjV2 = [];
    for (let entry of curDataProjectionObj) {
      curDataProjectionObjV2.push({
        aniosaporte: entry.aint_anio,
        edad: entry.adec_edad,
        cuotatotalanual: entry.adec_prima_total,
        valordisponible: entry.adec_tasa_garantizada,
        beneficiomuerte: entry.adec_valor_prima_ahorro,
        valorrescate: entry.adec_tasa_proyectada,
      });
    }

    let req = {
      type: "tabla_ahorro",
      email: userProfileInfo.email,
      toEmail: userProfileInfo.email,
      form_id: idForm,
      context: {
        aniosaporte: vecAniosAporte,
        edad: vecEdad,
        cuotatotalanual: vecCuotaTotalAnual,
        valordisponible: vecValorDisponible,
        beneficiomuerte: vecBeneficioMuerte,
        valorrescate: vecValorRescate,
        labels_proyeccion: [
          "aniosaporte",
          "edad",
          "cuotatotalanual",
          "valordisponible",
          "beneficiomuerte",
          "valorrescate",
        ],
        proyeccion: curDataProjectionObjV2,
      },
    };

    await postFillForm(req).then((resp) => {
      viewContext.notification.show("Tabla generada", "success", true);
      window.open(resp.data.message.pdf_url, "_blank");
    });
  };

  const processForm = async () => {
    let dataV2 = formsFilledV2 ? formsFilledV2 : formsFilled;
    let tempTextButton = textButton;
    dataV2?.data?.map((item) => {
      if (
        item.type === "solicitud_seguro" &&
        item.detail.estado !== "Incompleto"
      ) {
        tempTextButton.seguro = "Finalizado";
        setFlagFormSolicitudFinished(true);
      }
      if (
        item.type === "autorizacion_debito" &&
        item.detail.estado !== "Incompleto"
      ) {
        tempTextButton.debito = "Finalizado";
        setFlagFormAutoFinished(true);
      }
    });
    setTextButton(tempTextButton);
  };

  useEffect(() => {
    getFormValidData();
  }, []);

  function getFormValidData() { }

  useEffect(() => {
    if (!formsUploadedCatalog.loading && formsUploadedCatalog?.data) {
      setUrlFormUploaded();
    }
  }, [formsUploadedCatalog.loading, formsUploadedCatalog?.data]);

  useEffect(() => {
    getFormValidData();
  }, []);

  useEffect(() => {
    let getOldData = JSON.parse(localStorage.getItem("oldPageData"));
    if (getOldData !== null) {
      setOldPage(getOldData);
    }
  }, []);

  useEffect(() => {
    if (oldPage !== null && oldPage !== undefined) {
      setStep(oldPage?.step);
      setPlanSelect(oldPage?.planSelect);
      setCurDataProjectionObj(oldPage?.curData);
    }
  }, [oldPage]);

  const sendToCustomerReview = () => {
    return textButton.seguro === "Finalizado" && textButton.debito === "Finalizado";
  }

  return (
    <Animate type='pop'>
      <div className={GlobalStyle.buttonPosition}>
        {step === 0 && (
          <BackButton
            action={() => {
              navigate(-1);
              localStorage.removeItem("oldPageData");
            }}
          />
        )}
        {step === 1 && planSelect && (
          <BackButton
            action={() => {
              setStep(0);
              localStorage.removeItem("oldPageData");
            }}
          />
        )}
      </div>

      {findPagos(dataPrice) ?
        <section className={GlobalStyle.paddingLeft}>
          {step === 0 && (
            <Content>
              <div style={{ padding: "15px 0px" }}>
                <p className={GlobalStyle.textTitle}>Vida</p>
              </div>
              <Card>
                <div className={GlobalStyle.mainContainerPriceClienteOne}>
                  <div className={GlobalStyle.mainContainerPriceClienteOneLeft}>
                    <div style={{ margin: "10px 10px" }}>
                      <h2 style={{ fontWeight: 600 }}>Datos del cliente:</h2>
                    </div>
                    <div style={{ margin: "10px 10px" }}>
                      <p style={{ fontWeight: 600 }}>Nombres</p>
                      <p>{`${dataPrice.detail.primer_nombre} ${dataPrice.detail.apellido_paterno}`}</p>
                    </div>
                    <div style={{ margin: "10px 10px" }}>
                      <p style={{ fontWeight: 600 }}>Cédula</p>
                      <p className={GlobalStyle["roboto"]}>
                        {dataPrice.nro_documento}
                      </p>
                    </div>
                    <div style={{ margin: "10px 10px" }}>
                      <p style={{ fontWeight: 600 }}>Asesor</p>
                      <p>{userProfile?.name}</p>
                    </div>
                    <div style={{ margin: "10px 10px" }}>
                      <p style={{ fontWeight: 600 }}>Fecha</p>
                      <p className={GlobalStyle["roboto"]}>
                        {dataPrice.updated_at?.split("T")[0]}
                      </p>
                    </div>
                    <div style={{ margin: "10px 10px" }}>
                      <p style={{ fontWeight: 600 }}>Producto</p>
                      <p>
                        {
                          dataPrice?.product?.product1?.plan.cod_producto
                            ?.astr_descripcion
                        }
                      </p>
                    </div>
                    <div style={{ margin: "10px 10px" }}>
                      <p style={{ fontWeight: 600 }}>Plan</p>
                      <p className={GlobalStyle["roboto"]}>
                        {dataPrice?.product?.product1?.plan?.textPlan}
                      </p>
                    </div>
                    <div style={{ margin: "10px 10px" }}>
                      <p style={{ fontWeight: 600 }}>Estado</p>
                      <p>{dataPrice?.estado}</p>
                    </div>
                  </div>
                  <div className={GlobalStyle.mainContainerPriceClienteOneRight}>
                    <h2
                      style={{
                        fontWeight: 600,
                        fontSize: "20px",
                        textAlign: "center",
                      }}
                    >
                      Cotizaciones generadas
                    </h2>
                    {Object.entries(dataPrice.product).map((item, idx) => {
                      if (item.length >= 2) {
                        let testingRecord = item[1];
                        if (!testingRecord?.dataProjection?.data?.proyeccion)
                          return
                        if (item[0] !== "user_id") {
                          return (
                            <div
                              key={`buttonIndividualPrice${idx}`}
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                color='blue'
                                text={`Cotización ${idx + 1} `}
                                action={() => handleSelectPlan(item, idx)}
                              />
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </div>
              </Card>
            </Content>
          )}
          {step === 1 && planSelect && (
            <Content>
              <Card>
                <div
                  style={{
                    height: "40px",
                    width: "150px",
                    backgroundColor: "#e6e8f4",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h2
                    style={{ fontSize: "20px", fontWeight: 600 }}
                  >{`Cotización ${planSelect.idx + 1} `}</h2>
                </div>
                <div className={GlobalStyle.mainContainerPriceClienteTwo}>
                  <div
                    className={GlobalStyle.mainContainerPriceClienteTwoGeneral}
                  >
                    <div style={{ margin: "10px 10px" }}>
                      <p>
                        <span style={{ fontWeight: 600 }}>{`${dataPrice.product.product1.plan.cod_producto
                          .astr_descripcion
                          } - ${dataPrice.product.product1.plan.textPlan.toUpperCase()} - ALTERNATIVA ${dataPrice.product.product1.plan.alternativeNumber
                          } `}</span>
                      </p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {planSelect.plan[1].plan.alternativesSelected.map(
                        (item, idx) => {
                          return (
                            <div
                              key={`coveragesIndividualPlan${idx} `}
                              style={{ display: "flex", width: "100%" }}
                            >
                              <div
                                className={
                                  GlobalStyle.mainContainerPriceClienteTwoNamePlan
                                }
                              >
                                <p
                                  style={{
                                    color: "white",
                                    fontWeight: 400,
                                    fontSize: "13px",
                                  }}
                                >{`${item.astr_cob_descrip_print} `}</p>
                              </div>
                              <div
                                className={
                                  GlobalStyle.mainContainerPriceClienteTwoValuePlan
                                }
                              >
                                <p
                                  style={{
                                    color: "white",
                                    fontWeight: 400,
                                    fontSize: "13px",
                                  }}
                                  className={GlobalStyle["roboto"]}
                                >{`$${formatNumber(
                                  planSelect.plan[1].plan.valuesSelected[idx]
                                )} `}</p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div
                    className={GlobalStyle.mainContainerPriceClienteTwoGeneral}
                  >
                    <div className={GlobalStyle.marginText}>
                      <p>
                        <span style={{ fontWeight: 600 }}>Total cuota</span>
                      </p>
                    </div>
                    <div>
                      <div className={GlobalStyle.marginText}>
                        <p className={GlobalStyle["roboto"]}>
                          {`${planSelect.plan[1].client.typePaid === "M"
                            ? "MENSUAL"
                            : planSelect.plan[1].client.typePaid === "A"
                              ? "ANUAL"
                              : planSelect.plan[1].client.typePaid === "S"
                                ? "SEMESTRAL"
                                : "TRIMESTRAL"
                            } $${formatNumber(planSelect.plan[1].dataProjection?.data?.pagos ?
                              planSelect.plan[1].dataProjection.data.pagos[0].adec_pag_valor_total.toFixed(2) : 0)} `}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={GlobalStyle.mainContainerPriceClienteTwoGeneral}
                  >
                    <div className={GlobalStyle.marginText}>
                      <p style={{ fontWeight: 600 }}>Tabla de ahorro</p>
                    </div>
                    <div
                      className={GlobalStyle.marginText}
                      style={{ width: "100%" }}
                    >
                      <Button
                        small
                        color='blue'
                        text='Ver'
                        action={() => sendTablaAhorro()}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ padding: "20px 0px", width: "100%" }}>
                  <h2 style={{ fontWeight: 600, fontSize: "20px" }}>
                    Documentos necesarios:
                  </h2>
                  <p>
                    Completar toda la información puede llevar 20 minutos
                    aproximadamente, aconsejamos que tu cliente cuente con toda la
                    información disponible para poder realizar la cotización.
                  </p>
                </div>
                <div style={{ width: "100%" }}>
                  <Title blackTitle='Paso 1: Formulario de solicitud'>
                    <div>
                      {formulariosEnLinea.map((item, idx) => {
                        getPersonPay();
                        if (
                          item.href === "formularioAutorizacionDebito" &&
                          !thirdPartyPay
                        ) {
                          return (
                            <div
                              key={`formulariosEnLinea${item.title} `}
                              className={GlobalStyle.formOnline}
                            >
                              <div className={GlobalStyle.formOnlineleft}>
                                <div className={GlobalStyle.inputFormOnlineleft}>
                                  <Icon image='edit' color='#0f265c' size={30} />
                                </div>
                                <div style={{ width: "50%", padding: 10 }}>
                                  {item.title}
                                </div>
                              </div>
                              <div className={GlobalStyle.formOnlineRight}>
                                <Button
                                  small
                                  color='blue'
                                  text={
                                    item.title ===
                                      "Formulario de solicitud de seguro"
                                      ? textButton.seguro
                                      : textButton.debito
                                  }
                                  action={() =>
                                    navigate(`/${item.href}/${dataPrice.id}`, {
                                      state: planSelect,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          );
                        } else if (item.href === "formularioSolicitudSeguro") {
                          return (
                            <div
                              key={`formulariosEnLinea${item.title} `}
                              className={GlobalStyle.formOnline}
                            >
                              <div className={GlobalStyle.formOnlineleft}>
                                <div className={GlobalStyle.inputFormOnlineleft}>
                                  <Icon image='edit' color='#0f265c' size={30} />
                                </div>
                                <div className={GlobalStyle.inputFormOnlineRight}>
                                  {item.title}
                                </div>
                              </div>
                              <div className={GlobalStyle.formOnlineRight}>
                                <Button
                                  small
                                  color='blue'
                                  text={
                                    item.title ===
                                      "Formulario de solicitud de seguro"
                                      ? textButton.seguro
                                      : textButton.debito
                                  }
                                  action={() =>
                                    navigate(`/${item.href}/${dataPrice.id}`, {
                                      state: planSelect,
                                    })
                                  }
                                />
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                    {SetViewCandidate(
                      rawUser,
                      userPermissions,
                      ["u"],
                      "cotizaciones"
                    ) && (
                        <>
                          {!isSendingForm ? (
                            <Button
                              color='blue'
                              text={`Enviar a revisión del cliente`}
                              action={() => enviarFormularios(false)}
                              className={sendToCustomerReview() ? GlobalStyle.buttonEnabled : GlobalStyle.buttonDisabled}
                            />
                          ) : (
                            <div className={StyleV2.dualRingPCL}></div>
                          )}
                        </>
                      )}
                  </Title>
                </div>
                <div style={{ width: "100%", paddingTop: 15 }}>
                  <div style={{ padding: "10px 0px" }}>
                    <div>
                      <Title blackTitle='Paso 2: Carga de documentos adicionales'>
                        {formCliente.map((item, idx) => {
                          return (
                            <div
                              key={`formulariosCliente${item.title}`}
                              className={GlobalStyle.aditionalDocuments}
                            >
                              {formsUploadedCatalog?.data?.length > 0 && item.url !== "" ? (
                                <>
                                  <div
                                    className={GlobalStyle.textaditionalDocuments}
                                  >
                                    {item.title}
                                  </div>
                                  <div
                                    className={
                                      GlobalStyle.buttonaditionalDocuments
                                    }
                                  >
                                    <Button
                                      url={item.url}
                                      target='_blank'
                                      icon='download'
                                    />
                                  </div>
                                  <div
                                    className={
                                      GlobalStyle.notificationaditionalDocuments
                                    }
                                  >
                                    <p>Archivo subido</p>
                                  </div>
                                  <div
                                    className={GlobalStyle.formaditionalDocuments}
                                  >
                                    <form onSubmit={handleSubmit(submitForm)}>
                                      {!isSubmitting ? (
                                        <Button
                                          small
                                          color='blue'
                                          text={`Volver a subir`}
                                          action={() => {
                                            let tempFormCliente = formCliente;
                                            tempFormCliente.map((file) => {
                                              if (file.title === item.title) {
                                                file.url = "";
                                              }
                                            });
                                            setFormCliente([...tempFormCliente]);
                                          }}
                                        />
                                      ) : (
                                        <div
                                          className={StyleV2.dualRingPCL}
                                        ></div>
                                      )}
                                    </form>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className={GlobalStyle.textaditionalDocuments}
                                  >
                                    {item.title}
                                  </div>
                                  <div
                                    className={
                                      GlobalStyle.buttonaditionalDocuments
                                    }
                                  ></div>
                                  <div
                                    className={
                                      GlobalStyle.notificationaditionalDocuments
                                    }
                                  >
                                    <input
                                      className={GlobalStyle.customFileInput}
                                      type='file'
                                      onChange={(e) =>
                                        setFormFile({
                                          ...formFile,
                                          [item.title]: e.target.files[0],
                                        })
                                      }
                                    />
                                    <p>
                                      {formFile[item.title] &&
                                        `${formFile[item.title].name}`}
                                    </p>
                                  </div>
                                  <div
                                    className={GlobalStyle.formaditionalDocuments}
                                  >
                                    <form onSubmit={handleSubmit(submitForm)}>
                                      {!isSubmitting ? (
                                        <Button
                                          disabled={
                                            formFile[item.title] === "" ||
                                              formFile[item.title] === undefined
                                              ? true
                                              : false
                                          }
                                          small
                                          color='blue'
                                          text={`Subir archivo`}
                                          action={() =>
                                            uploadFileForm({
                                              type: item.title,
                                              file: formFile[item.title],
                                            })
                                          }
                                        />
                                      ) : (
                                        <div
                                          className={StyleV2.dualRingPCL}
                                        ></div>
                                      )}
                                    </form>
                                  </div>
                                </>
                              )}
                            </div>
                          );
                        })}
                        <div style={{ padding: "10px 0px" }}>
                          {/* <Button
                          small
                          color='blue'
                          text={`Enviar al cliente`}
                          // action={() => handleSelectPlan(item, idx)}
                        /> */}
                        </div>
                      </Title>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 0px",
                  }}
                >
                  <Title blackTitle='Paso 3: Autorización y validación'>
                    <div className={GlobalStyle.containerOtp}>
                      <div className={GlobalStyle.containerOtpOneTitle}>
                        <h2 style={{ fontWeight: 700, fontSize: "15px" }}>
                          Estado autorización del cliente
                        </h2>
                      </div>
                      <div className={GlobalStyle.containerOtpOneButton}>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <form onSubmit={handleSubmit(submitForm)}>
                            {!otpValidado && (
                              <>
                                <Button
                                  small
                                  color='blue'
                                  text='&nbsp;&nbsp;Enviar OTP&nbsp;&nbsp;'
                                  action={() => sendOtp()}
                                />
                              </>
                            )}
                          </form>
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div className={Style.envioOtp}>
                            <p style={{ fontWeight: 600 }}>{estadoEnvioOTP}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={GlobalStyle.containerOtpOneTitle}>
                      <p style={{ fontWeight: 300 }}>{fechaEnvioOtp}</p>
                    </div>

                    {!isSendingOTP ? (
                      <>
                        <div className={GlobalStyle.containerOtp}>
                          <div className={GlobalStyle.containerOtpOneTitle}>
                            <h2 style={{ fontWeight: 700, fontSize: "15px" }}>
                              Validación del vendedor
                            </h2>
                          </div>
                          <div className={GlobalStyle.containerOtpOneButton}>
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {!otpValidado && (
                                <Button
                                  small
                                  color='blue'
                                  text={`Verificar OTP`}
                                  action={() => setOpen(true)}
                                  disabled={estadoEnvioOTP === "Pendiente"}
                                />
                              )}
                            </div>
                            <div
                              style={{
                                width: "50%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <div className={Style.envioOtp}>
                                <p style={{ fontWeight: 600 }}>
                                  {estadoVerificarOTP}
                                </p>
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                      </>
                    ) : (
                      <div className={StyleV2.dualRingPCL}></div>
                    )}

                    <div className={GlobalStyle.containerOtpOneTitle}>
                      <p style={{ fontWeight: 300 }}>{fechaVerificaOtp}</p>
                    </div>

                    <div style={{ padding: "20px 0px" }}>
                      Confirmo que todos los documentos están correctos por parte
                      del cliente
                    </div>

                    {SetViewCandidate(
                      rawUser,
                      userPermissions,
                      ["u"],
                      "cotizaciones"
                    ) && (
                        <>
                          {!isSendingDocs ? (
                            <div style={{ padding: "10px 0px" }}>
                              {estadoVerificarOTP === 'Validado' &&
                                <>
                                  {!isformsReady ?
                                    <p style={{ color: 'red' }}>Debes presionar el boton "Enviar a revisión del cliente" </p> :
                                    <Button
                                      small
                                      color='blue'
                                      text={`Valido y envío a SDP`}
                                      action={() => updateFinalPrice()}
                                      className={validToSendToSDP ? GlobalStyle.buttonEnabled : GlobalStyle.buttonDisabled}
                                      disabled={!otpValidado}
                                    />}
                                </>
                              }
                            </div>
                          ) : (
                            <div className={StyleV2.dualRingPCL}></div>
                          )}
                        </>
                      )}
                  </Title>
                </div>
              </Card>
            </Content>
          )}
        </section>
        :
        <h2 style={{ fontWeight: 600 }}>Problemas de configuracion con el producto:
          {dataPrice?.product?.product1?.plan.cod_producto?.astr_descripcion} </h2>
      }

      {/*Custom Modal */}
      <ModalCustomv2
        display={
          <Form
            data={{
              astr_tipo_id_cliente: {
                type: "hidden",
                value: dataPrice?.detail?.tipo_documento,
              },
              astr_num_id_cliente: {
                type: "hidden",
                value: dataPrice?.nro_documento,
              },
              astr_codigo_agente: {
                type: "hidden",
                value: dataOfac.idUsuario,
              },
              astr_codigo_ramo: {
                type: "hidden",
                value:
                  dataPrice?.product?.product1?.plan?.cod_producto?.astr_ramo,
              },
              astr_codigo_producto: {
                type: "hidden",
                value:
                  dataPrice?.product?.product1?.plan?.cod_producto
                    ?.astr_id_producto,
              },
              astr_num_celular: {
                type: "hidden",
                value: dataPrice?.detail?.telefono,
              },
              astr_OTP: {
                label: "OTP",
                type: "text",
                required: true,
                value: "",
                errorMessage: "Por favor ingresa el OTP",
              },
              candidateId: {
                type: "hidden",
                value: dataPrice?.id,
              }
            }}
            buttonText='Verificar'
            url='api/bridgesdp'
            method='POST'
            callback={showVerificaOtpv2}
            isOtp={true}
          />
        }
        width={screenSize.dynamicWidth < 650 ? "100%" : "500px"}
        height='auto'
        open={open}
        closeModal={closeModal}
      />
    </Animate>
  );

  function findPagos(data) {
    if (!data || typeof data !== 'object') {
      return null;
    }

    if (data.pagos) {
      return data.pagos;
    }

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const result = findPagos(data[key]);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }
}
