import React, { Fragment, useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  ViewContext,
  Card,
  Table,
  Button,
  Animate,
  TitleRow,
  ModalCustom,
  Grid,
  InputProduct,
  RadioProduct,
} from "components/lib";
import { Submit, getSalesForces, getSponsor, getBrokers, getUsuariosSDP, getSdpInvites } from "components/bridges/BridgeServices";
import { colourStyles } from "../../css/globalStyles";
import { BackButton } from "components/backButton/button";
import { Scrollbars } from "react-custom-scrollbars-2";
import useViewPort from "../../components/hooks/useViewPort";
import CryptrUtils from '../../utils/CryptrUtils';
import Style from "../../css/globalStyle.module.scss";
import Select from "react-select";
import { useDataContext } from "../../context/DataContext";

export function Users(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  const context = useContext(ViewContext);
  const [data, setData] = useState({ loading: false });
  const [dataRol, setDataRol] = useState({ loading: false });
  const [rol, setRol] = useState([]);
  const [dataList, setDataList] = useState();
  const [screenSize] = useViewPort();
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState({
    email: "",
    name: "",
    lastName: "",
    typeUser: "",
    user: "",
    rol: "",
  });
  const [open, setOpen] = useState(false);
  const [dataTable, setDataTable] = useState({
    header: [
      { name: "nombre", title: "Nombre", sort: true },
      { name: "apellido", title: "Apellido", sort: true },
      { name: "email", title: "Correo electrónico", sort: true },
      { name: "date_created", title: "Fecha creación", sort: true },
      { name: "rol", title: "Rol", sort: true },
      { name: "status", title: "Estado", sort: true },
    ],
    body: users,
  });

  const closeModal = () => {
    setOpen(false);
  };

  const fillData = async () => {
    const getUsers = await getCachedData();
    if (getUsers) {
      let lstCodUsersSDP = [];
      for (let entry of getUsers.sdpInvites) {
        let tmpObjSdp = entry?.intermediary?.sdp;
        lstCodUsersSDP.push(tmpObjSdp);
      }

      let tempData = {
        brokers: [],
        sponsors: [],
        salesForce: [],
        sdpv2: [],
      };
      getUsers.brokers.map((data) =>
        tempData.brokers.push({
          value: JSON.stringify(data),
          label: data.astr_nombre_asesor,
          type: data.astr_tipo_id_asesor,
        })
      );
      getUsers.sponsors.map((data) =>
        tempData.sponsors.push({
          value: JSON.stringify(data),
          label: data.astr_nombre_asesor,
          type: data.astr_tipo_id_asesor,
        })
      );
      getUsers.salesForce.map((data) =>
        tempData.salesForce.push({
          value: JSON.stringify(data),
          label: data.astr_nombre_asesor,
          type: data.astr_tipo_id_asesor,
        })
      );
      getUsers.sdp.map((data) => {
        //Filter users that are already linked
        let previusLinked = lstCodUsersSDP.find(
          (ele) =>
            ele?.astr_codigo_usuario?.trim() === data?.astr_codigo_usuario?.trim()
        );
        if (!previusLinked) {
          tempData.sdpv2.push({
            value: JSON.stringify(data),
            label: data.astr_nombre_asesor,
            type: data.astr_tipo_id_asesor,
          });
        }
      });
      setDataList(tempData);
    }
    await Submit("rol", "", "get").then((resp) => {
      const encryptedRoles = resp?.data?.data;
      const decryptedRoles = CryptrUtils.decrypt(encryptedRoles);
      const rolesJson = JSON.parse(decodeURIComponent(decryptedRoles));
      setDataRol({ loading: false, data2: rolesJson });
    });
    await Submit("account/users/getAll", "", "get").then((resp) => {
      const encryptedUsers = resp?.data?.data?.users;
      const decryptedUsers = CryptrUtils.decrypt(encryptedUsers);
      const usersJson = JSON.parse(decodeURIComponent(decryptedUsers));
      const encryptedInvites = resp?.data?.data?.invites;
      const decryptedInvites = CryptrUtils.decrypt(encryptedInvites);
      const invitesJson = JSON.parse(decodeURIComponent(decryptedInvites));
      setData({ data: { users: usersJson, invites: invitesJson } });
    });
  };

  const getCachedData = async () => {
    let lstUserByTypes = {};
    const rawUserObj = localStorage.getItem("user");
    if (rawUserObj && JSON.parse(rawUserObj)?.is_super_admin) {
      //sponsors
      const respSponsorsV2 = localStorage.getItem('sponsors')
      let respSponsorsJson;
      if (respSponsorsV2) {
        respSponsorsJson = JSON.parse(respSponsorsV2)?.data
      }
      lstUserByTypes.sponsors = state?.sponsors?.data ? state.sponsors.data : respSponsorsJson ? respSponsorsJson : await getSponsor();;
      //brokers
      const respBrokersV2 = localStorage.getItem('brokers')
      let respBrokersJson;
      if (respBrokersV2) {
        respBrokersJson = JSON.parse(respBrokersV2)?.data
      }
      lstUserByTypes.brokers = state?.brokers?.data ? state.brokers.data : respBrokersJson ? respBrokersJson : await getBrokers();
      //fv
      const respFVV2 = localStorage.getItem('salesForces')
      let respFVJson;
      if (respFVV2) {
        respFVJson = JSON.parse(respFVV2)?.data
      }
      lstUserByTypes.salesForce = state?.salesForces?.data ? state.salesForces.data : respFVJson ? respFVJson : await getSalesForces();
      //sdp
      const responseSDPV2 = localStorage.getItem('usuariosSDP')
      let responseSDPJson;
      if (responseSDPV2) {
        responseSDPJson = JSON.parse(responseSDPV2)?.data
      }
      lstUserByTypes.sdp = state?.usuariosSDP?.data ? state.usuariosSDP.data : responseSDPJson ? responseSDPJson : await getSalesForces();
      const responseInvites = await getSdpInvites();
      lstUserByTypes.sdpInvites = responseInvites;
    }
    return lstUserByTypes
  }

  useEffect(() => {
    fillData();
  }, []);

  function editUser(data, callback) {
    context.modal.show(
      {
        title: "Actualizar Usuario",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: "Nombre",
            type: "text",
            required: true,
            value: data.nombre.toUpperCase(),
            errorMessage: "Por favor ingresa un nombre",
          },
          apellido: {
            label: "Apellido",
            type: "text",
            required: true,
            value: data.apellido.toUpperCase(),
            errorMessage: "Por favor ingresa un nombre",
          },
          email: {
            label: "Correo electrónico",
            type: "email",
            value: data.email,
            required: true,
          },
        },
        buttonText: "Guardar",
        url: "/api/user",
        method: "PATCH",
      },
      (res) => {
        context.notification.show(
          data.name + " ha sido actualizado",
          "success",
          true
        );
        callback(res);
      }
    );
  }

  function deleteUser(data, callback) {
    context.modal.show(
      {
        title: "Borrar Usuario",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
        },
        buttonText: "Borrar Usuario",
        text: `Estás seguro de que deseas eliminar ${data.nombre}?`,
        url: "/api/user",
        method: "DELETE",
        destructive: true,
      },
      () => {
        context.notification.show(
          data.name + " fue eliminado",
          "success",
          true
        );
        callback();
      }
    );
  }

  function deleteInvite(data, callback) {
    context.modal.show(
      {
        title: "Eliminar Invitación",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
        },
        buttonText: "Eliminar Invitación",
        text: `Estas seguro de que deseas eliminar la invitación para: ${data.email}?`,
        url: "/api/invite",
        method: "DELETE",
        destructive: true,
      },
      (res) => {
        let curId = res.id.value;
        let tmpUser = users.filter((ele) => ele.id !== curId);
        setUsers(tmpUser);
        setDataTable({ ...dataTable, body: tmpUser });
        context.notification.show(
          `${data.email}', se ha eliminado la invitación`,
          "success",
          true
        );
        callback();
      }
    );
  }

  async function resendInvite(data) {
    try {
      await axios({
        url: "/api/invite",
        method: "post",
        data: { email: data.email },
      });

      context.notification.show(
        `Invitación re-enviada a ${data.email}`,
        "success",
        true
      );
    } catch (err) {
      context.handleError(err);
    }
  }

  useEffect(() => {
    let list = [];
    const dataRolJson = dataRol?.data2 ? dataRol.data2 : [];
    if (dataRolJson.length > 0) {
      list = dataRolJson.map((x) => {
        return {
          id: x.id,
          name: x.name,
          created_at: x.created_at,
          label: x.name,
          value: x.id,
          type: "rol",
        };
      });
    }

    setRol(list);
  }, [dataRol]);

  useEffect(() => {
    // format the user list
    let list = [];
    if (dataRol?.data2) {
      if (data?.data?.users?.length) {
        list = data.data.users.map((x) => {
          let tmpRol = dataRol.data2.find(
            (ele) => ele.id === x.intermediary.rol
          );
          return {
            id: x.id,
            nombre: x.nombre,
            apellido: x.apellido,
            email: x.email,
            date_created: x.date_created,
            rol: tmpRol ? tmpRol.name : "",
            permission: x.permission,
            status: "Registrado",
          };
        });
      }

      if (data?.data?.invites?.length > 0) {
        data.data.invites.forEach((x) => {
          let tmpRol = dataRol.data2.find(
            (ele) => ele.id === x.intermediary.rol
          );
          list.push({
            id: x.id,
            nombre: x.intermediary?.nombre,
            apellido: x.intermediary?.apellido,
            email: x.email,
            date_created: x.date_sent,
            rol: tmpRol ? tmpRol.name : "",
            permission: x.permission || "user",
            status: "Invitado",
          });
        });
      }

      setUsers(list);
      setDataTable({ ...dataTable, body: list });
    }
  }, [data]);

  // attach the per row actions for invites
  if (users.length) {
    users.forEach((u) => {
      if (u.status === "Invitado") {
        u.actions = {
          invite: resendInvite,
          delete: deleteInvite,
        };
      }
      if (u.status === "Registrado") {
        u.actions = {
          edit: editUser,
          delete: deleteUser,
        };
      }
    });
  }

  const onChangeValue = (e) => {
    let tempForm = { ...form };
    if (e.target) {
      tempForm = { ...form, [e.target.name]: e.target.value };
    } else if (e.type && e.type === "rol") {
      tempForm = { ...form, rol: e.value };
    } else {
      tempForm = { ...form, user: e.value };
    }
    setForm(tempForm);
  };

  const inviteUser = async () => {
    let userObj =
      form.typeUser === "sponsor" ||
        form.typeUser === "broker" ||
        form.typeUser === "sdp" ||
        form.typeUser === "sdpv2"
        ? JSON.parse(form.user)
        : "";
    //always create with rol permission
    let obj = {
      email: form.email.toUpperCase(),
      nombre: form.name.toUpperCase(),
      apellido: form.lastname.toUpperCase(),
      permission: "admin",
      sponsor: form.typeUser === "sponsor" ? userObj : "",
      broker: form.typeUser === "broker" ? userObj : "",
      sdp: form.typeUser === "sdp" ? userObj : "",
      sdpv2: form.typeUser === "sdpv2" ? userObj : "",
      rol: form.rol,
      business_user: form.typeUser === "sdpv2" ? true : false,
    };
    //Sanitizing object
    obj.email = obj.email.trim();
    obj.nombre = obj.nombre.trim();
    obj.apellido = obj.apellido.trim();

    try {
      await Submit("invite", obj, "post")
        .then((value) => {
          context.notification.show(value.data.message, "success", true);
          closeModal();
          //Update LocalStorage
          if (form.typeUser === "sdpv2") {
            let lstUserByTypes = JSON.parse(
              localStorage.getItem("lstUserByTypes")
            );
            let objV2 = { ...obj };
            delete objV2["email"];
            delete objV2["permission"];
            delete objV2["business_user"];
            objV2["fv"] = objV2["sdp"];
            objV2["sdp"] = objV2["sdpv2"];
            delete objV2["sdpv2"];
            lstUserByTypes.sdpInvites.push({ intermediary: objV2 });
            localStorage.setItem(
              "lstUserByTypes",
              JSON.stringify(lstUserByTypes)
            );
          }
          //Update table
          let tmpRol = dataRol.data2.find((ele) => ele.id === obj.rol);
          const state = [...users];
          state.push({
            id: value.data.data[0].id,
            nombre: obj.nombre,
            apellido: obj.apellido,
            email: obj.email,
            date_created: value.data.data[0].date_sent,
            rol: tmpRol.name,
            permission: obj.permission,
            status: "Invitado",
            actions: {
              invite: resendInvite,
              delete: deleteInvite,
            },
          });

          setUsers(state);
          setDataTable({ ...dataTable, body: state });
        })
        .catch((error) => {
          context.handleError(error);
          //console.error("The Promise is rejected!", error);
        })
        .finally(() => {
          context.handleError(
            "La Promesa está finalizada, lo que significa que ha sido resuelta o rechazada."
          );
        });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Fragment>
      <Animate>
        <div className={Style.buttonPosition}>
          <BackButton go={-1} />
        </div>
        <div>
          <TitleRow title='Administrar usuarios'>
            <Button
              color='blue'
              small
              text='Invitar usuario'
              action={() => setOpen(true)}
            />
          </TitleRow>

          <Card>
            {screenSize.dynamicWidth > 1024 ? (
              <Table
                search
                className='restrict-width'
                data={dataTable}
                loading={data.loading}
                show={[
                  "nombre",
                  "apellido",
                  "email",
                  "date_created",
                  "rol",
                  "status",
                ]}
                //show={['name', 'email', 'plan']}
                badge={{
                  col: "status",
                  color: "blue",
                  condition: [
                    { value: "registrado", color: "green" },
                    { value: "invitado", color: "blue" },
                  ],
                }}
                actions={{ edit: editUser, delete: deleteUser, email: true }}
              />
            ) : (
              <Scrollbars
                style={{
                  width: "100%",
                  height: "600px",
                }}
              >
                <Table
                  search
                  className='restrict-width'
                  data={dataTable}
                  loading={data.loading}
                  show={[
                    "nombre",
                    "apellido",
                    "email",
                    "date_created",
                    "rol",
                    "status",
                  ]}
                  //show={['name', 'email', 'plan']}
                  badge={{
                    col: "status",
                    color: "blue",
                    condition: [
                      { value: "registrado", color: "green" },
                      { value: "invitado", color: "blue" },
                    ],
                  }}
                  actions={{ edit: editUser, delete: deleteUser, email: true }}
                />
              </Scrollbars>
            )}
            <ModalCustom
              display={
                <div>
                  <h2>Invitar usuario</h2>
                  <Grid>
                    <InputProduct
                      label='Correo electrónico'
                      onChange={onChangeValue}
                      name='email'
                    />
                  </Grid>
                  <Grid col='2'>
                    <InputProduct
                      label='Nombre'
                      onChange={onChangeValue}
                      name='name'
                    />
                    <InputProduct
                      label='Apellido'
                      onChange={onChangeValue}
                      name='lastname'
                    />
                  </Grid>
                  <RadioProduct
                    title={"Tipo"}
                    options={[
                      { label: "Broker", value: "broker", name: "typeUser" },
                      { label: "Sponsor", value: "sponsor", name: "typeUser" },
                      {
                        label: "Fuerza de Ventas",
                        value: "sdp",
                        name: "typeUser",
                      },
                      { label: "SDP", value: "sdpv2", name: "typeUser" },
                    ]}
                    onChange={onChangeValue}
                  />
                  {(form.typeUser === "sdp" ||
                    form.typeUser === "sdpv2" ||
                    form.typeUser === "broker" ||
                    form.typeUser === "sponsor") && (
                      <div style={{ margin: "12px 0px", paddingTop: 15 }}>
                        <label>Seleccionar Usuario: </label>
                        <Select
                          // className='basic-single'
                          // classNamePrefix='select'
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          options={
                            form?.typeUser === "sdpv2"
                              ? dataList?.sdpv2
                              : form?.typeUser === "broker"
                                ? dataList?.brokers
                                : form?.typeUser === "sponsor"
                                  ? dataList?.sponsors
                                  : dataList?.salesForce
                          }
                          isSearchable
                          // isLoading={optionsCodeProducts.length === 0 ? true : false}
                          placeholder={"Seleccionar"}
                          // menuPosition='fixed'
                          // menuPlacement={"auto"}
                          onChange={onChangeValue}
                        />
                      </div>
                    )}
                  <div style={{ margin: "12px 0px", paddingTop: 15 }}>
                    <label>Rol: </label>
                    <Select
                      // className='basic-single'
                      // classNamePrefix='select'
                      name='rol'
                      styles={colourStyles}
                      options={rol}
                      isSearchable
                      // isLoading={optionsCodeProducts.length === 0 ? true : false}
                      placeholder={"Seleccionar"}
                      // menuPosition='fixed'
                      // menuPlacement={"auto"}
                      onChange={onChangeValue}
                    />
                  </div>
                  <div>
                    <Button text='Guardar' action={inviteUser} />
                  </div>
                </div>
              }
              width={screenSize.dynamicWidth < 650 ? "100%" : "500px"}
              height='auto'
              open={open}
              closeModal={closeModal}
            />
          </Card>
        </div>
      </Animate>
    </Fragment>
  );
}
