import React, { Fragment, useState, useEffect } from "react";
import { Loader, Icon } from "components/lib";
import Style from "./configBranch.module.scss";
import Input from "../../../css/globalStyle.module.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import useViewPort from "../../hooks/useViewPort";

export function ConfigBranch(props) {
  const { agriculture, setAgriculture } = props;
  const [screenSize] = useViewPort();

  const titles = ["Ramo", "Agropecuario"];

  const handleChange = (e) => {
    const { name, value } = e.target;
    const agricultureList = { ...agriculture };
    if (name === "dias_vigencia_cotizacion") {
      agricultureList.configRamo[name] = value;
    }
    if (
      name === "Seguro Campesino" ||
      name === "Super de compañias valores y seguros"
    ) {
      agricultureList.configRamo.contribuciones.map((contribution, idx) => {
        if (contribution.astr_descripcion === name) {
          contribution.active = e.target.checked;
        }
      });
    }

    setAgriculture(agricultureList);
  };

  useEffect(() => { }, []);

  return (
    <div className={Style.configBranch}>
      <Scrollbars
        style={{
          width: "100%",
          height: "250px",
        }}
      >
        <table style={{ width: screenSize.dynamicWidth > 1024 ? "100%" : "175%", marginBottom: 20 }}>
          <thead>
            <tr>
              {titles.map((title) => {
                return <th key={`lifeproducttable${title}`}>{title}</th>;
              })}
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                <p>Código SIP</p>
              </th>
              <td>
                <p className={Input["roboto"]}>40</p>
              </td>
            </tr>
            <tr>
              <th rowSpan={agriculture.configRamo.contribuciones.length}>
                Contribuciones e impuestos
              </th>
              <td>
                {agriculture.configRamo.contribuciones.length > 0 && (
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>
                      <p>
                        {
                          agriculture.configRamo.contribuciones[0]
                            .astr_descripcion
                        }
                      </p>
                    </div>
                    <div style={{ paddingLeft: 5, width: "50%" }}>
                      <input
                        type='checkbox'
                        id={
                          agriculture.configRamo.contribuciones[0]
                            .astr_descripcion
                        }
                        name={
                          agriculture.configRamo.contribuciones[0]
                            .astr_descripcion
                        }
                        value={
                          agriculture.configRamo.contribuciones[0]
                            .astr_descripcion
                        }
                        checked={agriculture.configRamo.contribuciones[0].active}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
              </td>
            </tr>
            {agriculture.configRamo.contribuciones.map((config, idx) => {
              return (
                idx > 0 && (
                  <tr key={`tableConfigBranch${idx}${config.astr_descripcion}`}>
                    <td>
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "50%" }}>
                          <p>{config.astr_descripcion}</p>
                        </div>
                        <div style={{ paddingLeft: 5, width: "50%" }}>
                          <input
                            type='checkbox'
                            id={config.astr_descripcion}
                            name={config.astr_descripcion}
                            value={config.astr_descripcion}
                            checked={config.active}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              );
            })}
            <tr>
              <th>Días de vigencia de la cotización</th>
              <td>
                <input
                  style={{
                    backgroundColor: "#F7F7F8",
                    padding: "5px 8px",
                    minHeight: "53px",
                    boxShadow: "inset 0px 3px 3px #00000007",
                    borderRadius: "5px",
                    border: 0,
                    opacity: 1,
                    textTransform: "uppercase",
                  }}
                  type='number'
                  value={agriculture.configRamo.dias_vigencia_cotizacion}
                  name='dias_vigencia_cotizacion'
                  className={Input["roboto"]}
                  onChange={handleChange}
                />
                <label>DÍAS</label>
              </td>
            </tr>
          </tbody>
        </table>
      </Scrollbars>
    </div>
  );
}
