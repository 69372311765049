export const SetViewCandidate = (rawUser, strUserPermissions, action, type) => {
  let isView = false;
  if (rawUser.data && rawUser.data.flag_uno) {
    isView = true;
  } else {
    let typeUp = typeof strUserPermissions;
    let userPermissions = typeUp=== 'object'? strUserPermissions: JSON.parse(strUserPermissions);
    if (userPermissions) {
      Object.keys(userPermissions.vida[type]).map((key) => {
        action.map((valueAction) => {
          if (key === valueAction) {
            if (userPermissions.vida[type][key]) {
              isView = true;
            }
          }
        });
      });
    }
  }
  return isView;
};
