export const colourStyles = {
  control: (styles) => ({
    ...styles,
    padding: "5px 8px",
    minHeight: "53px",
    border: "0px",
    background: "#F7F7F8 0% 0% no-repeat padding-box",
    boxShadow: "inset 0px 3px 3px #00000007",
    borderRadius: "5px",
    opacity: "1",
    textTransform: "uppercase",
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      background: "#F7F7F8 0% 0% no-repeat padding-box",
      opacity: "1",
      color: isSelected ? "#9FA0A7" : "#9FA0A7",
      textTransform: "uppercase",
    };
  },
};
