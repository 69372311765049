/***
 *
 *   ONBOARDING LAYOUT
 *   Simple layout to focus on user onboarding actions
 *
 **********/

import { Logo, Content, Icon, useNavigate } from "components/lib";
import React, { Fragment } from "react";
import Style from "./menu.module.scss";

export function MenuLayout(props) {
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className={Style.background}>
        <section className={Style.wrapper}>
          <Content>
            <Logo color='blue' noMargin className={Style.logo} />
            <nav className={Style.nav}>
              <button
                className={Style.button}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  navigate("/account/profile");
                }}
              >
                <Icon
                  className={Style.icon}
                  image='user'
                  color='#10265c'
                  size={30}
                />
                <span className={Style.textButton}>Perfil de usuario</span>
              </button>
            </nav>
          </Content>
        </section>
        <section>
          <main className={Style.onboarding}>
            {<props.children {...props.data} />}
          </main>
        </section>
      </div>
    </Fragment>
  );
}
