/***
 *
 *   PROGRESS STEPS
 *   Steps are used to indicate the current point in a
 *   multi-stage process, such as filling in a long form
 *
 *   PROPS
 *   items: array of objects containing keys: name, url
 *   and completed (bool)
 *
 **********/

import React from "react";
import { useState } from "react";
import { useEffect } from "react";

function useViewPort() {
  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  return [screenSize, getDimension];
}

export default useViewPort;
