import React, { useEffect } from "react";
import { Animate } from "components/lib";
import { useForm } from "react-hook-form";
import Spin from "../../agriculture/agricultureSeller.module.scss";
import Select from "react-select";
import { colourStyles } from "../../../../css/globalStyles";
import Style from "../../../../css/globalStyle.module.scss";
import useViewPort from "components/hooks/useViewPort";

export function StepTwo(props) {
  const { formState, handleSubmit } = useForm();

  const { isSubmitting } = formState;

  const {
    options,
    plan,
    generatePrice,
    handleInputChange,
    handleChangeAlternativeSelected,
    handleChangePlanSelected,
    handleSelectPlan,
    handleChangeSelectOptions,
    isLoading,
    limitAge,
    setPlan
  } = props;

  const [screenSize] = useViewPort();

  const formatToCurrency = (strNumber) => {
    return Number(strNumber)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  function submitForm(data) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 4000);
    });
  }

  const resetRadioState = () => {
  }

  const flushSelections = () => {
    let tmpPlan = { ...plan };
    if (tmpPlan.hasOwnProperty('alternativeNumber')) {
      delete tmpPlan['alternativeNumber'];
    }
    if (tmpPlan.hasOwnProperty('textAlternativa')) {
      delete tmpPlan['textAlternativa'];
    }
    if (tmpPlan.hasOwnProperty('uuidAlternativaSelected')) {
      delete tmpPlan['uuidAlternativaSelected'];
    }
    if (tmpPlan.hasOwnProperty('valuesSelected')) {
      delete tmpPlan['valuesSelected'];
    }
    setPlan(tmpPlan)
  }

  useEffect(() => {
    if (plan?.textPlan) {
      flushSelections()
    }
  }, [plan?.textPlan]);

  return (
    <Animate type='pop'>
      <form onSubmit={handleSubmit(submitForm)}>
        <div>
          <section>
            <span style={{ display: "flex", justifyContent: "center" }}>
              <h2 className={Style["title"]}>
                Ingresar información del producto
              </h2>
            </span>
          </section>
          <section>
            <div className={Style.containerStepTwo}>
              <div className={Style.subContainerStepTwo}>
                <label>Producto</label>
                <Select
                  className='basic-single'
                  classNamePrefix='select'
                  styles={colourStyles}
                  options={options ? options.planOptions : []}
                  isSearchable
                  isLoading={!options}
                  menuPosition='fixed'
                  menuPlacement={"auto"}
                  onChange={handleSelectPlan}
                  placeholder='Seleccionar'
                />
              </div>
              <div className={Style.subContainerStepTwo}>
                <label style={{ display: "block" }}>Opción</label>
                <input
                  value={"Creciente"}
                  name='creciente'
                  type='text'
                  disabled={true}
                  className={Style["input"]}
                />
              </div>
              <div className={Style.subContainerStepTwo}>
                <label>Condición de fumador</label>
                <Select
                  className='basic-single'
                  classNamePrefix='select'
                  styles={colourStyles}
                  options={[
                    { label: "Si", value: "S" },
                    { label: "No", value: "N" },
                  ]}
                  isSearchable
                  isLoading={!options}
                  menuPosition='fixed'
                  menuPlacement={"auto"}
                  onChange={handleChangeSelectOptions}
                  placeholder='Seleccionar'
                />
              </div>
            </div>
          </section>
          {!limitAge.isValid ? (
            <section>
              <div style={{ width: "100%", paddingTop: 30 }}>
                <div className={Style.containerStepTwo}>
                  <div className={Style.containerOne}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ padding: 10 }}>
                        <h2 style={{ fontWeight: "700", color: "#0F265C" }}>
                          Planes
                        </h2>
                      </div>
                      {/* Planes Radio Button */}
                      {plan !== undefined &&
                        Object.entries(plan.planes[0]).map((plan, idx) => (
                          <div
                            key={`planStepTwo${idx}`}
                            style={{ padding: 10 }}
                          >
                            <input
                              id='state'
                              type='radio'
                              value={idx}
                              name='plan'
                              onClick={resetRadioState}
                              onChange={(e) => handleChangePlanSelected(e, idx)}
                            />
                            <label style={{ paddingLeft: 20 }}>{`Plan ${idx + 1
                              }`}</label>
                          </div>
                        ))}
                      <div style={{ padding: 10 }}>
                        <h2 style={{ fontWeight: "700", color: "#0F265C" }}>
                          Alternativas
                        </h2>
                      </div>
                      {/* Alternativas Radio Button */}
                      {plan !== undefined &&
                        plan.textPlan !== undefined &&
                        plan.textPlan !== "" &&
                        plan.valores_asegurados[
                          plan.textPlan
                        ][0].alternativas?.map((planItem, idx) => (
                          <div
                            key={`typeAlternativeStepTwo${idx}`}
                            style={{ padding: 10 }}
                          >
                            <input
                              id='state'
                              type='radio'
                              value='inactive'
                              name='typeAlternative'
                              checked={((plan?.alternativeNumber - 1) === idx)}
                              //checked={true}
                              onChange={(e) => {
                                handleChangeAlternativeSelected(e, idx)
                              }
                              }
                            />
                            <label
                              style={{ paddingLeft: 20 }}
                              className={Style["roboto"]}
                            >{`Alternativa ${idx + 1} - $${planItem.valor_alternativa
                              }`}</label>
                          </div>
                        ))}
                      {screenSize.dynamicWidth <= 768 && (
                        <div className={Style.containerTwo}>
                          <div className={Style.mainContainerPlan}>
                            {plan !== undefined &&
                              plan.alternativesSelected !== undefined &&
                              plan.alternativesSelected.map((item, idx) => {
                                return (
                                  <div
                                    key={`alternativeLabelStepTwo${idx}`}
                                    className={Style.containerNamePlan}
                                  >
                                    <p
                                      style={{
                                        color: "white",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {item.label}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                          <div className={Style.mainContainerPlan}>
                            {plan !== undefined &&
                              plan.textPlan !== undefined &&
                              plan.valuesSelected !== undefined &&
                              plan.valuesSelected.map((item, idx) => {
                                return (
                                  <div
                                    key={`coveragesValuesStepTwo${idx}`}
                                    className={Style.containerValuePlan}
                                  >
                                    <p
                                      style={{
                                        color: "white",
                                        fontWeight: "500",
                                      }}
                                      className={Style["roboto"]}
                                    >
                                      ${formatToCurrency(item)}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}
                      <div style={{ padding: 10 }}>
                        <h2 style={{ fontWeight: "700", color: "#0F265C" }}>
                          Frecuencia de pago
                        </h2>
                      </div>
                      {plan !== undefined &&
                        plan.valuesSelected !== undefined &&
                        options &&
                        options.tipoPago !== undefined &&
                        options.tipoPago.map((item, idx) => {
                          return (
                            <div
                              key={`typePaidStepTwo${idx}`}
                              style={{ padding: 10 }}
                            >
                              <input
                                id='state'
                                type='radio'
                                value={item.value}
                                name='typePaid'
                                onChange={(e) => handleInputChange(e)}
                              />
                              <label style={{ paddingLeft: 20 }}>
                                {item.label}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {screenSize.dynamicWidth > 768 && (
                    <div className={Style.containerTwo}>
                      {/* COBERTURAS BLUE BOX*/}
                      <div className={Style.mainContainerPlan}>
                        {plan !== undefined &&
                          plan.alternativesSelected !== undefined &&
                          plan.alternativesSelected.map((item, idx) => {
                            return (
                              <div
                                key={`alternativeLabelStepTwo${idx}`}
                                className={Style.containerNamePlan}
                              >

                                <p
                                  style={{ color: "white", fontWeight: "500" }}
                                >
                                  {item.label}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                      {/* VALORES COBERTURAS BLUE BOX*/}
                      <div className={Style.mainContainerPlan}>
                        {plan !== undefined &&
                          plan.textPlan !== undefined &&
                          plan.valuesSelected !== undefined &&
                          plan.valuesSelected.map((item, idx) => {
                            return (
                              <div
                                key={`coveragesValuesStepTwo${idx}`}
                                className={Style.containerValuePlan}
                              >
                                <p
                                  style={{ color: "white", fontWeight: "500" }}
                                  className={Style["roboto"]}
                                >
                                  ${formatToCurrency(item)}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div style={{ paddingTop: "25px" }}>
                {!isSubmitting && !isLoading ? (
                  <input
                    className={Style["submit"]}
                    type='submit'
                    value='Cotizar'
                    onClick={generatePrice}
                  />
                ) : (
                  <div className={Spin.ldsDualRing}></div>
                )}
              </div>
            </section>
          ) : (
            <section>
              <div style={{ paddingTop: "25px", paddingLeft: "60px" }}>
                <p>La edad permitida para este plan es</p>
              </div>
              <div>
                <div
                  className={Style["roboto"]}
                >{`Edad desde: ${limitAge.min}`}</div>
                <div
                  className={Style["roboto"]}
                >{`Edad Hasta: ${limitAge.max}`}</div>
              </div>
            </section>
          )}
        </div>
      </form>
    </Animate>
  );
}
