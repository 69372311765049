/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: select plan and make payment
 *
 **********/

import React, { useContext } from "react";
import { AuthContext, Animate, Row, Card, Form, Link } from "components/lib";
import Style from "./signup.module.scss";

export function Signup(props) {
  const context = useContext(AuthContext);

  return (
    <Animate type='pop'>
      <Row>
        <Card loading={false} restrictWidth center>
          <h2 className={Style.title}>Registro</h2>
          {/* <SocialSignin network={["facebook", "twitter"]} showOr signup /> */}
          <Form
            data={{
              name: {
                label: "Nombre",
                type: "text",
                required: true,
                errorMessage: "Por favor ingresa tu nombre",
              },
              email: {
                label: "Usuario (correo electrónico)",
                type: "email",
                required: true,
              },
              password: {
                label: "Contraseña",
                type: "password",
                required: true,
                complexPassword: true,
              },
              confirm_password: {
                type: "hidden",
                value: null,
              },
            }}
            url='/api/account'
            method='POST'
            buttonText='Enviar'
            callback={context.signin}
          />

          <div className='mt-4'>
            ¿Ya estás registrado? <Link url='/signin' text='Iniciar Sesión' />
          </div>
        </Card>
      </Row>
    </Animate>
  );
}
