import React, { useState, useEffect } from "react";
import { Table } from "components/lib";
import CurrencyInput from "react-currency-input-field";
import { Box, Button, Text, TextArea, TextInput, ThemeContext } from "grommet";
import { Scrollbars } from "react-custom-scrollbars-2";
import { Add, Subtract } from "grommet-icons";
import Input from "../../../css/globalStyle.module.scss";
import { deepMerge } from "grommet/utils";
import { grommet } from "grommet/themes";

export function RequirementsPlan(props) {
  const { requirements, setRequirements, generalData } = props;
  const [data, setData] = useState();

  const customTheme = deepMerge(grommet, {
    textInput: {
      extend: () => `
          border: 0px;
          minHeight: 53px;
      `,
      container: {
        extend: () => `
          border-radius: 5px;
          background: #F7F7F8 0% 0% no-repeat padding-box;
        `,
      },
      placeholder: {
        extend: () => `
          width: 100%;
          color: #1e1a11;
        `,
      },
      suggestions: {
        extend: () => `
          background: #c9c19f;
          color: #3d3522;
          li {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
          }
        `,
      },
    },
  });

  const handleChangeMount = (value, name, values, idx) => {

    if(value === "" || value === undefined || value === null){
      value = 0;
    }

    const rows = [...requirements];
    let parseValue = parseFloat(value);
    let maxMount = parseFloat(generalData.mountMax);
    let minMount = parseFloat(generalData.mountMin);
    if (name === "mountMin") {
      if(parseValue < minMount){
        parseValue = minMount;
      }
      if(parseValue > maxMount){
        parseValue = maxMount;
      }
    }

    if (name === "mountMax") {
      if(parseValue < minMount){
        parseValue = minMount;
      }
      if(parseValue > maxMount){
        parseValue = maxMount;
      }     
    }

    rows[idx] = { ...rows[idx], [name]: parseValue };
    setRequirements(rows);
   
  };

  const handleChange = (e, idx) => {
    const { name, value } = e.target;
    const rows = [...requirements];
    //const numberRegex = /^[0-9\b]+$/;
    const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/; // Incluye letras, números, ñ, Ñ y vocales acentuadas
    let parseValue = parseFloat(value);
    let maxAge = parseFloat(generalData.ageMax);
    let minAge = parseFloat(generalData.ageMin);
    
    // Validación para la descripción, permitiendo solo alfanuméricos
    if (name === "description" && (value === '' || alphanumericExtendedRegex.test(value))) {
      rows[idx] = { ...rows[idx], [name]: value };
      setRequirements(rows);
    }
  
   
    if (name === "ageMin") {
      if(parseValue < minAge){
        parseValue = minAge;
      }
      if(parseValue > maxAge){
        parseValue = maxAge;
      }
      if(parseValue > rows[idx].ageMax){
        parseValue = rows[idx].ageMax;
      }
      rows[idx] = { ...rows[idx], [name]: parseValue };
      setRequirements(rows);
    }

    if (name === "ageMax") {
      if(parseValue < minAge){
        parseValue = minAge;
      }
      if(parseValue > maxAge){
        parseValue = maxAge;
      }
      if(parseValue < rows[idx].ageMin){
        parseValue = rows[idx].ageMin;
      }
      rows[idx] = { ...rows[idx], [name]: parseValue };
      setRequirements(rows);
    }

  };
  

  const handleAddRow = () => {
    const item = {
      ageMin: "",
      ageMax: "",
      mountMin: "",
      mountMax: "",
      description: "",
    };
    setRequirements((prevRequirements) => [...prevRequirements, item]);
  };

  const handleRemoveSpecificRow = (idx) => {
    const rows = [...requirements];
    rows.splice(idx, 1);
    setRequirements(rows);
  };

  const makeDataTable = () => {
    if (requirements.length > 0) {
      let tempDataTable = {
        header: [
          { name: "ageMin", title: "Edad desde", sort: false },
          { name: "ageMax", title: "Edad hasta", sort: false },
          { name: "mountMin", title: "Monto desde", sort: false },
          { name: "mountMax", title: "Monto hasta", sort: false },
          { name: "description", title: "Requisitos", sort: false },
        ],
        body: [],
      };
      let tempObjectCultivation = {};
      requirements.map((item, idx) => {
        tempObjectCultivation.ageMin = (
          <Box
            direction='row'
            width={idx > 0 ? "210px" : "210px"}
            align='center'
          >
            {idx > 0 ? (
              <td>
                <Box pad={"small"}>
                  <Button
                    size='medium'
                    primary
                    onClick={() => handleRemoveSpecificRow(idx)}
                  >
                    <Subtract size='medium' />
                  </Button>
                </Box>
              </td>
            ) : (
              <td>
                <Box pad={"medium"}></Box>
              </td>
            )}
            <ThemeContext.Extend value={customTheme}>
              <TextInput
                type='number'
                name='ageMin'
                value={item.ageMin}
                onChange={(e) => handleChange(e, idx)}
                placeholder={generalData && generalData.ageMin}
                className={Input["roboto"]}
              />
            </ThemeContext.Extend>
            <Text margin={"small"} textAlign='center' alignSelf='center'>
              años
            </Text>
          </Box>
        );
        tempObjectCultivation.ageMax = (
          <Box direction='row' width={"150px"}>
            <ThemeContext.Extend value={customTheme}>
              <TextInput
                type='number'
                name='ageMax'
                value={item.ageMax}
                onChange={(e) => handleChange(e, idx)}
                placeholder={generalData && generalData.ageMax}
                className={Input["roboto"]}
              />
            </ThemeContext.Extend>
            <Text margin={"small"} textAlign='center' alignSelf='center'>
              años
            </Text>
          </Box>
        );
        tempObjectCultivation.mountMin = (
          <Box width={"small"}>
            <CurrencyInput
              id='mountMin'
              name='mountMin'
              decimalsLimit={2}
              prefix={"$"}
              onValueChange={(value, _, values) => {
                handleChangeMount(value, _, values, idx);
              }}
              className={Input["input_number"]}
              value={item.mountMin}
            />
          </Box>
        );
        tempObjectCultivation.mountMax = (
          <Box width={"small"}>
            <CurrencyInput
              id='mountMax'
              name='mountMax'
              decimalsLimit={2}
              prefix={"$"}
              onValueChange={(value, _, values) => {
                handleChangeMount(value, _, values, idx);
              }}
              className={Input["input_number"]}
              value={item.mountMax}
            />
          </Box>
        );
        tempObjectCultivation.description = (
          <Box direction='row' width={"medium"}>
            <ThemeContext.Extend value={customTheme}>
              <TextArea
                placeholder='Ingresa el requisito'
                name='description'
                value={item.description}
                className={Input["textArea"]}
                onChange={(e) => handleChange(e, idx)}
              />
            </ThemeContext.Extend>
          </Box>
        );
        tempDataTable.body.push(tempObjectCultivation);
        tempObjectCultivation = {};
      });
      setData(tempDataTable);
    }
  };

  useEffect(() => {
    makeDataTable();
  }, [requirements]);

  return (
    <div style={{ paddingBottom: 15 }}>
      <div>
        <Scrollbars
          style={{
            width: "100%",
            height: "250px",
          }}
        >
          <Table data={data} />
          <Box pad={"small"}>
            <div>
              <Button primary onClick={handleAddRow}>
                <Add size='medium' />
              </Button>
            </div>
          </Box>
        </Scrollbars>
      </div>
    </div>
  );
}
