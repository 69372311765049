import React, { Fragment, useState, useEffect } from "react";
import { Loader, Table, SwitchProduct } from "components/lib";
import Style from "./modalContent.module.scss";
import Modal from "react-modal";
import {Scrollbars} from "react-custom-scrollbars-2";
import useViewPort from "../hooks/useViewPort";

Modal.setAppElement("#root");

export function ModalCustom(props) {
  const { closeModal, display, height, width, open } = props;
  const [screenSize] = useViewPort();

  const customStyles = {
    overlay: { zIndex: 15 },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {}, []);

  return (
    <Modal
      isOpen={open}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel='Custom Modal'
    >
      <div className={Style.modalContent}>
        {screenSize.dynamicWidth > 325 ? (
            <>
        <button onClick={closeModal}>X</button>
        <div
          style={{ height: height ? height : 350, width: width ? width : 350 }}
        >
          {display}
        </div>
            </>
        ) : (
            <Scrollbars autoHeight autoHeightMin={300} autoHeightMax={400} style={{width: "100%",}}>
        <button onClick={closeModal}>X</button>
        <div
          style={{ height: height ? height : 350, width: width ? width : 350 }}
        >
          {display}
        </div>
            </Scrollbars>
          )}
      </div>
    </Modal>
  );
}
