import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  Animate,
  TitleRow,
  Card,
  Table,
  Button,
  ViewContext,
  useAPI,
  useNavigate,
} from "components/lib";
import useViewPort from "../../../components/hooks/useViewPort";
import { BackButton } from "components/backButton/button";
import Style from "../../../css/globalStyle.module.scss";
import { Scrollbars } from "react-custom-scrollbars-2";

export function Rol(props) {
  const context = useContext(ViewContext);
  const data = useAPI("/api/rol");
  const [rol, setRol] = useState([]);
  const [screenSize] = useViewPort();
  const navigate = useNavigate();

  function createRol() {
    context.modal.show(
      {
        title: "Agregar Rol",
        form: {
          name: {
            label: "Nombre",
            type: "text",
            required: true,
          },
        },
        buttonText: "Crear",
        url: "/api/rol",
        method: "POST",
      },
      (form, res) => {
        window.location.reload();
        /*if (res.length) {
          const state = [...rol];

          res.forEach((rol) => {
            if (!state.find((x) => x.id === rol.id)) {
              state.push({
                id: rol.id,
                secuencial: rol.secuencial.toString().padStart(4, "0"),
                name: rol.name.toUpperCase(),
                created_at: rol.created_at,
              });
            }
          });

          setRol(state);
        }*/
      }
    );
  }

  function editRol(data, callback) {
    context.modal.show(
      {
        title: "Actualizar Rol",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: "Nombre",
            type: "text",
            required: true,
            value: data.name.toUpperCase(),
            errorMessage: "Por favor ingresa un nombre",
          },
        },
        buttonText: "Actualizar",
        url: "/api/rol",
        method: "PATCH",
      },
      (res) => {
        let state = [...rol];
        let index = state.map(e => e.id).indexOf(res?.id?.value);
        if (index) {
          state[index].name = res?.name?.value;
          setRol(state);
        }
        context.notification.show(
          data.name + " ha sido actualizado",
          "success",
          true
        );
        callback(res);
      }
    );
  }
//abustamante_todo
  function deleteRol(data, callback) {
    context.modal.show(
      {
        title: "Borrar Rol",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
        },
        buttonText: "Borrar Rol",
        text: `Estás seguro de que deseas eliminar ${data.name}?`,
        url: "/api/rol",
        method: "DELETE",
        destructive: true,
      },
      () => {
        context.notification.show(
          data.name + " fue eliminado",
          "success",
          true
        );
        callback();
        navigate("/profile");
      }
    );
  }

  useEffect(() => {
    let list = [];
    if (data?.data?.length) {
      list = data.data.map((x) => {
        return {
          id: x.id,
          secuencial: x.secuencial.toString().padStart(4, "0"),
          name: x.name,
          created_at: x.created_at,
        };
      });
    }
    setRol(list);
  }, [data]);

  let tmpRols = {
    header: [
      { name: "id", title: "Identificador", sort: true },
      { name: "secuencial", title: "Secuencial", sort: true },
      { name: "name", title: "Nombre", sort: true },
      { name: "created_at", title: "Fecha creación", sort: true },
    ],
    body: rol,
  };

  return (
    <Fragment>
      <Animate>
        <div className={Style.buttonPosition}>
          <BackButton go={'/profile'} />
        </div>
        <div>
          <TitleRow title='Administrar Roles'>
            <Button small text='Agregar rol' action={createRol} color='blue' />
          </TitleRow>
          <Card>
            {screenSize.dynamicWidth > 1024 ? (
              <Table
                search
                className='restrict-width'
                data={tmpRols}
                loading={data.loading}
                show={["secuencial", "name", "created_at"]}
                actions={{
                  edit: editRol,
                  delete: deleteRol,
                  view: { url: "/profile/rolDetails" },
                }}
              />
            ) : (
              <Scrollbars
                style={{
                  width: "100%",
                  height: "600px",
                }}
              >
                <Table
                  search
                  className='restrict-width'
                  data={tmpRols}
                  loading={data.loading}
                  show={["secuencial", "name", "created_at"]}
                  actions={{
                    edit: editRol,
                    delete: deleteRol,
                    view: { url: "/profile/rolDetails" },
                  }}
                />
              </Scrollbars>
            )}
          </Card>
        </div>
      </Animate>
    </Fragment>
  );

}
