import React from "react";
import { Button as ButtonGrommet } from "grommet";
import { Title } from "./title";
import Style from "../../../../../css/globalStyle.module.scss";
import { Add, Subtract } from "grommet-icons";
import useViewPort from "components/hooks/useViewPort";

export function EstiloVida(props) {
  const {
    errors,
    register,
    watch,
    estiloVidaPregunta7DatosFields,
    estiloVidaPregunta7DatosAppend,
    estiloVidaPregunta7DatosRemove,
    estiloVidaPregunta12DeporteFields,
    estiloVidaPregunta12DeporteAppend,
    estiloVidaPregunta12DeporteRemove,
    preguntaAdicional1NumeroFields,
    preguntaAdicional1NumeroAppend,
    preguntaAdicional1NumeroRemove,
  } = props;
  const estiloVidaPregunta4 = watch("estiloVidaPregunta4");
  const estiloVidaPregunta7 = watch("estiloVidaPregunta7");
  const estiloVidaPregunta8 = watch("estiloVidaPregunta8");
  const estiloVidaPregunta11 = watch("estiloVidaPregunta11");
  const estiloVidaPregunta12 = watch("estiloVidaPregunta12");

  const [screenSize] = useViewPort();

  return (
    <div>
      <Title whiteTitle='9. Sección estilo de vida' />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerEstiloVidaLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;1. ¿Ha consumido cualquier derivado del tabaco en los últimos 12
              meses?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "10px 10px" }}>Si</label>
              <input
                tabIndex={126}
                {...register("estiloVidaPregunta4", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "10px 10px" }}>No</label>
              <input
                tabIndex={127}
                {...register("estiloVidaPregunta4", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.estiloVidaPregunta4?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        {estiloVidaPregunta4 === "Si" && (
          <div className={Style.inputContainerMedica}>
            <div className={Style.inputContainerEstiloDeporte}>
              <label>
                <b style={{ color: 'red' }}>*</b>&nbsp;Frecuencia
              </label>
              <select
                {...register("estiloVidaPregunta4Frecuencia", {
                  required: true,
                })}
                className={Style["select"]}
              >
                <option value='Semanal'>Semanal</option>
                <option value=' Mensual'> Mensual</option>
              </select>
              {errors.estiloVidaPregunta4Frecuencia?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
            <div className={Style.inputContainerEstiloDeporte}>
              <label><b style={{ color: 'red' }}>*</b>&nbsp;Cantidad</label>
              <input
                tabIndex={128}
                {...register("estiloVidaPregunta4Cantidad", {
                  required: true,
                })}
                type='number'
                className={Style["input_number"]}
              />
              {errors.estiloVidaPregunta4Cantidad?.type === "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
            </div>
          </div>
        )}
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerEstiloVidaLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;2. ¿Ha sido investigado, arrestado o declarado culpable por un
              delito?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "10px 10px" }}>Si</label>
              <input
                tabIndex={129}
                {...register("estiloVidaPregunta5", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "10px 10px" }}>No</label>
              <input
                tabIndex={130}
                {...register("estiloVidaPregunta5", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.estiloVidaPregunta5?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerEstiloVidaLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;3. ¿Ha sido condenado por manejar bajo la influencia de alcohol
              y/o drogas o se le ha suspendido o revocado su licencia de
              conducir o ha tenido más de una infracción de tránsito?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "10px 10px" }}>Si</label>
              <input
                tabIndex={131}
                {...register("estiloVidaPregunta6", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "10px 10px" }}>No</label>
              <input
                tabIndex={132}
                {...register("estiloVidaPregunta6", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.estiloVidaPregunta6?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerEstiloVidaLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;4. ¿Ha viajado más de una vez cada dos meses o vivido fuera de su
              país de residencia durante el último año, o tiene la intención de
              hacerlo durante el próximo año?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "10px 10px" }}>Si</label>
              <input
                tabIndex={133}
                {...register("estiloVidaPregunta7", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "10px 10px" }}>No</label>
              <input
                tabIndex={134}
                {...register("estiloVidaPregunta7", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.estiloVidaPregunta7?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        {estiloVidaPregunta7 === "Si" && (
          <>
            <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Lugares</h2>
            <div>
              <div style={{ padding: "10px 0px" }}>
                <ButtonGrommet
                  primary
                  onClick={() =>
                    estiloVidaPregunta7DatosAppend({
                      lugar: "",
                    })
                  }
                >
                  <Add size='medium' />
                </ButtonGrommet>
              </div>
              {estiloVidaPregunta7DatosFields.map((item, index) => (
                <div
                  key={`estilovida7${item.id}`}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 15px",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ padding: "10px 15px", display: "flex" }}>
                      <input
                        tabIndex={135}
                        type='text'
                        className={Style["input"]}
                        {...register(
                          `estiloVidaPregunta7Datos.${index}.lugar`,
                          {
                            required: true,
                            maxLength: 255,
                          }
                        )}
                      />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingLeft: "10px",
                        }}
                      >
                        <ButtonGrommet
                          primary
                          onClick={() => estiloVidaPregunta7DatosRemove(index)}
                        >
                          <Subtract size='medium' />
                        </ButtonGrommet>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerEstiloVidaLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;5. ¿Viaja o piensa viajar fuera del país en los próximos dos años?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "10px 10px" }}>Si</label>
              <input
                tabIndex={137}
                {...register("estiloVidaPregunta8", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "10px 10px" }}>No</label>
              <input
                tabIndex={138}
                {...register("estiloVidaPregunta8", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.estiloVidaPregunta8?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        {estiloVidaPregunta8 === "Si" && (
          <>
            <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Lugar</h2>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ padding: "10px 15px" }}>
                <input
                  tabIndex={139}
                  type='text'
                  className={Style["input"]}
                  {...register("estiloVidaPregunta8Datos1", {
                    required: true,
                    maxLength: 255,
                  })}
                />
              </div>
            </div>
          </>
        )}
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerEstiloVidaLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;6. ¿Por obligaciones de mi trabajo requiero viajar fuera de mi
              país de residencia más de una vez cada dos meses?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "10px 10px" }}>Si</label>
              <input
                tabIndex={140}
                {...register("estiloVidaPregunta9", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "10px 10px" }}>No</label>
              <input
                tabIndex={141}
                {...register("estiloVidaPregunta9", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.estiloVidaPregunta9?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerEstiloVidaLabel}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;7. ¿Alguna vez ha solicitado o recibido beneficios por
              incapacidad?
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "10px 10px" }}>Si</label>
              <input
                tabIndex={142}
                {...register("estiloVidaPregunta10", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "10px 10px" }}>No</label>
              <input
                tabIndex={143}
                {...register("estiloVidaPregunta10", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.estiloVidaPregunta10?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerEstiloVidaLabel}>
            <label><b style={{ color: 'red' }}>*</b>&nbsp;8. ¿Consume bebidas alcohólicas?</label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "10px 10px" }}>Si</label>
              <input
                tabIndex={145}
                {...register("estiloVidaPregunta11", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "10px 10px" }}>No</label>
              <input
                tabIndex={146}
                {...register("estiloVidaPregunta11", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.estiloVidaPregunta11?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        {estiloVidaPregunta11 === "Si" && (
          <>
            <div className={Style.inputContainerMedica}>
              <div className={Style.inputContainerEstiloDeporte}>
                <label><b style={{ color: 'red' }}>*</b>&nbsp;Frecuencia</label>
                <select
                  className={Style["select"]}
                  {...register("estiloVidaPregunta11Frecuencia", {
                    required: true,
                  })}
                >
                  <option value='Anual'>Anual</option>
                  <option value='Mensual'> Mensual</option>
                  <option value='Diario'>Diario</option>
                  <option value='Semanal'> Semanal</option>
                </select>
              </div>
              <div className={Style.inputContainerEstiloDeporte}>
                <label><b style={{ color: 'red' }}>*</b>&nbsp;Cantidad</label>
                <input
                  {...register("estiloVidaPregunta11Cantidad", {
                    required: true,
                  })}
                  type='number'
                  className={Style["input_number"]}
                />
              </div>
            </div>
          </>
        )}
        <div className={Style.inputContainerEstiloVida}>
          <div className={Style.inputContainerEstiloVidaLabel}>
            <label><b style={{ color: 'red' }}>*</b>&nbsp;9. ¿Practica algún deporte?</label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex" }}>
              <label style={{ padding: "10px 10px" }}>Si</label>
              <input
                tabIndex={147}
                {...register("estiloVidaPregunta12", { required: true })}
                type='radio'
                value='Si'
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <label style={{ padding: "10px 10px" }}>No</label>
              <input
                tabIndex={148}
                {...register("estiloVidaPregunta12", { required: true })}
                type='radio'
                value='No'
              />
            </div>
          </div>
        </div>
        {errors.estiloVidaPregunta12?.type === "required" && (
          <p style={{ color: "red" }}>Este campo es requerido</p>
        )}
        {estiloVidaPregunta12 === "Si" && (
          <div>
            {estiloVidaPregunta12DeporteFields.map((item, index) => (
              <div
                key={`estilovida7${item.id}`}
                className={Style.inputContainerMedica}
              >
                <div className={Style.inputContainerEstiloDeporte}>
                  <label><b style={{ color: 'red' }}>*</b>&nbsp;Nombre del deporte que practica</label>
                  <input
                    tabIndex={149}
                    {...register(
                      `estiloVidaPregunta12Deporte.${index}.deporte`,
                      {
                        required: true,
                      }
                    )}
                    type='text'
                    className={Style["input"]}
                  />
                </div>
                <div className={Style.inputContainerEstiloDeporte}>
                  <label><b style={{ color: 'red' }}>*</b>&nbsp;Lo realiza como</label>
                  <select
                    {...register(
                      `estiloVidaPregunta12Deporte.${index}.experiencia`,
                      {
                        required: true,
                      }
                    )}
                    className={Style["select"]}
                  >
                    <option value='Aficionado'>Aficionado</option>
                    <option value=' Profesional'> Profesional</option>
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <ButtonGrommet
                    primary
                    onClick={() => estiloVidaPregunta12DeporteRemove(index)}
                  >
                    <Subtract size='medium' />
                  </ButtonGrommet>
                </div>
              </div>
            ))}
            <div style={{ padding: "10px 10px" }}>
              <ButtonGrommet
                primary
                onClick={() =>
                  estiloVidaPregunta12DeporteAppend({
                    deporte: "",
                    experiencia: "",
                  })
                }
              >
                <Add size='medium' />
              </ButtonGrommet>
            </div>
          </div>
        )}
      </div>
      <h2 style={{ fontWeight: 600, paddingBottom: "30px" }}>
        Describa detalles adicionales de las preguntas afirmativas de la "1" a
        la "9"
      </h2>
      {screenSize.dynamicWidth > 768 ? (
        <>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "10%",
                display: "flex",
                flexDirection: "column",
                padding: "10px 15px",
              }}
            >
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Número</h2>
            </div>
            <div
              style={{
                width: "90%",
                display: "flex",
                flexDirection: "column",
                padding: "10px 15px",
              }}
            >
              <h2 style={{ fontWeight: 600, color: "#0F265C" }}>Detalles</h2>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {preguntaAdicional1NumeroFields.map((item, index) => (
              <div
                key={`estilovida7${item.id}`}
                style={{ width: "100%", display: "flex" }}
              >
                <div
                  style={{
                    width: "10%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 15px",
                  }}
                >
                  <input
                    tabIndex={150}
                    type='text'
                    className={Style["input_number"]}
                    {...register(`preguntaAdicional1Numero.${index}.numero`, {
                      required: true,
                    })}
                  />
                </div>
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    flexDirection: "column",
                    padding: "10px 15px",
                  }}
                >
                  <input
                    tabIndex={151}
                    type='text'
                    className={Style["input"]}
                    {...register(`preguntaAdicional1Numero.${index}.detalle`, {
                      required: true,
                    })}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <ButtonGrommet
                    primary
                    onClick={() => preguntaAdicional1NumeroRemove(index)}
                  >
                    <Subtract size='medium' />
                  </ButtonGrommet>
                </div>
              </div>
            ))}
            <div style={{ padding: "10px 0px" }}>
              <ButtonGrommet
                primary
                onClick={() =>
                  preguntaAdicional1NumeroAppend({
                    numero: "",
                    detalle: "",
                  })
                }
              >
                <Add size='medium' />
              </ButtonGrommet>
            </div>
          </div>
        </>
      ) : (
        <>
          <div style={{ display: "flex" }}></div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {preguntaAdicional1NumeroFields.map((item, index) => (
              <div
                key={`estilovida7${item.id}`}
                className={Style.inputContainerEstiloGeneralPreguntas}
                style={{ borderTop: index !== 0 && "#10265C 1px solid" }}
              >
                <div className={Style.inputContainerEstiloVidaNumeral}>
                  <div className={Style.inputContainerMedicaLiteralRespuesta}>
                    <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                      Número
                    </h2>
                  </div>
                  <div className={Style.inputContainerMedicaLiteralRespuesta}>
                    <input
                      type='text'
                      className={Style["input_number"]}
                      {...register(`preguntaAdicional1Numero.${index}.numero`, {
                        required: true,
                      })}
                    />
                  </div>
                </div>
                <div className={Style.inputContainerEstiloVidaNumeral}>
                  <div className={Style.inputContainerMedicaLiteralRespuesta}>
                    <h2 style={{ fontWeight: 600, color: "#0F265C" }}>
                      Detalles
                    </h2>
                  </div>
                  <div className={Style.inputContainerMedicaLiteralRespuesta}>
                    <input
                      type='text'
                      className={Style["input"]}
                      {...register(
                        `preguntaAdicional1Numero.${index}.detalle`,
                        {
                          required: true,
                        }
                      )}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    <ButtonGrommet
                      primary
                      onClick={() => preguntaAdicional1NumeroRemove(index)}
                    >
                      <Subtract size='medium' />
                    </ButtonGrommet>
                  </div>
                </div>
              </div>
            ))}
            <div style={{ padding: "10px 10px" }}>
              <ButtonGrommet
                primary
                onClick={() =>
                  preguntaAdicional1NumeroAppend({
                    numero: "",
                    detalle: "",
                  })
                }
              >
                <Add size='medium' />
              </ButtonGrommet>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
