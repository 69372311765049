import React, { useState, useEffect } from "react";
import { Icon, Table } from "components/lib";
import { typeCultivation } from "../../../dictionary/agriculture";
import Style from "./cultivation.module.scss";
import { Button } from "grommet";
import { Add } from "grommet-icons";
import Input from "../../../css/globalStyle.module.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import useViewPort from "../../hooks/useViewPort";

export function Cultivation(props) {
  const { agriculture, setAgriculture } = props;
  const [screenSize] = useViewPort();
  const [data, setData] = useState();

  const handleChange = (e, idx, nameSection) => {
    const { name, value } = e.target;
    const agricultureList = { ...agriculture };

    const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;

    if(value === '' || alphanumericExtendedRegex.test(value)){
      agricultureList[nameSection][idx] = {
        ...agricultureList[nameSection][idx],
        [name]: value,
      };
      setAgriculture(agricultureList);
    }
   
  };

  const handleAddRow = (nameSection) => {
    const agricultureList = { ...agriculture };
    const item = {
      nombre_del_cultivo: "",
      tipo_del_cultivo: "C",
      vigencia: "120",
    };
    agricultureList[nameSection].push(item);
    // setAgriculture((prevRequirements) => [...prevRequirements, item]);
    setAgriculture((prevRequirements) => ({
      ...prevRequirements,
    }));
  };

  const handleRemoveSpecificRow = (idx, nameSection) => {
    const agricultureList = { ...agriculture };
    agricultureList[nameSection].splice(idx, 1);
    setAgriculture(agricultureList);
  };

  const makeDataTable = (cultivationPlan, handleInputDataChange) => {
    if (agriculture) {
      let tempDataTable = {
        header: [
          { name: "name", title: "Nombre del cultivo", sort: false },
          { name: "type", title: "Tipo del cultivo", sort: false },
          { name: "validity", title: "Vigencia (Dias)", sort: false },
        ],
        body: [],
      };
      let tempObjectCultivation = {};
      cultivationPlan.map((cultivation, idx) => {
        tempObjectCultivation.name = (
          <input
            component='input'
            type='text'
            name='nombre_del_cultivo'
            style={{
              backgroundColor: "#F7F7F8",
              padding: "5px 8px",
              minHeight: "53px",
              boxShadow: "inset 0px 3px 3px #00000007",
              borderRadius: "5px",
              border: 0,
              opacity: 1,
              textTransform: "uppercase",
            }}
            value={cultivation.nombre_del_cultivo}
            onChange={(e) => handleChange(e, idx, "cultivations")}
          />
        );
        tempObjectCultivation.type = (
          <select
            name={"tipo_del_cultivo"}
            id={"tipo_del_cultivo"}
            style={{
              width: "150px",
              backgroundColor: "#F7F7F8",
              padding: "5px 8px",
              minHeight: "53px",
              boxShadow: "inset 0px 3px 3px #00000007",
              borderRadius: "5px",
              border: 0,
              opacity: 1,
              textTransform: "uppercase",
            }}
            value={cultivation.tipo_del_cultivo}
            onChange={(e) => handleChange(e, idx, "cultivations")}
          >
            {typeCultivation.map((option) => {
              return (
                <option
                  key={`tableCultivations-cultivationsOptions${option.codigo}${idx}`}
                  // defaultValue={
                  //   cultivation.tipo_del_cultivo === option.codigo
                  //     ? "selected"
                  //     : null
                  // }
                  value={option.codigo}
                >
                  {option.descripcion}
                </option>
              );
            })}
          </select>
        );
        tempObjectCultivation.validity = (
          <div>
            <input
              type='number'
              name='vigencia'
              style={{
                backgroundColor: "#F7F7F8",
                padding: "5px 8px",
                minHeight: "53px",
                boxShadow: "inset 0px 3px 3px #00000007",
                borderRadius: "5px",
                border: 0,
                opacity: 1,
                textTransform: "uppercase",
              }}
              value={cultivation.vigencia}
              className={Input["roboto"]}
              onChange={(e) => handleChange(e, idx, "cultivations")}
            />
            {(
              <button
                style={{ background: "#0F265C 0% 0% no-repeat padding-box", opacity: 1, borderRadius: "15px", color: "white", padding: 3, height: "25px", alignSelf: "center", marginLeft: "5px" }}
                onClick={() => handleRemoveSpecificRow(idx, "cultivations")}
              >
                <Icon image={"minus"} size={18} />
              </button>
            )}
          </div>
        );
        tempDataTable.body.push(tempObjectCultivation);
        tempObjectCultivation = {};
      });
      setData(tempDataTable);
    }
  };

  useEffect(() => {
    makeDataTable(agriculture.cultivations, handleChange);
  }, [agriculture]);

  return (
    <div className={Style.cultivation}>
      {screenSize.dynamicWidth > 1024 ? (
        <div style={{ paddingBottom: 15 }}>
          <Table data={data} />
          <Button primary onClick={() => handleAddRow("cultivations")}>
            <Add size='medium' />
          </Button>
          {/* <button
          style={{ padding: 5 }}
          onClick={() => handleAddRow("cultivations")}
        >
          <Icon image={"plus"} size={18} />
        </button> */}
        </div>
      ) : (
        <Scrollbars
          style={{
            width: "100%",
            height: "250px",
          }}
        >
          <div style={{ paddingBottom: 15 }}>
            <Table data={data} />
            <Button primary onClick={() => handleAddRow("cultivations")}>
              <Add size='medium' />
            </Button>
            {/* <button
          style={{ padding: 5 }}
          onClick={() => handleAddRow("cultivations")}
        >
          <Icon image={"plus"} size={18} />
        </button> */}
          </div>
        </Scrollbars>
      )}
    </div>
  );
}
