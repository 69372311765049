import { BackButton } from "components/backButton/button";
import { Animate, MenuCard, useLocation } from "components/lib";
import { Box, Grid as GridGroomet, ResponsiveContext } from "grommet";
import { useContext, useEffect, useState } from "react";
import paso1 from "../../../assets/img/paso1.png";
import paso2 from "../../../assets/img/paso2.png";
import Style from "../../../views/qualification/qualofication.module.scss";

const columns = {
    small: ["auto"],
    medium: ["auto", "auto"],
    large: ["auto", "auto"],
    xlarge: ["auto", "auto"],
};

const rows = {
    small: ["auto", "auto", "auto"],
    medium: ["auto", "auto", "auto"],
    large: ["auto", "auto", "auto"],
    xlarge: ["auto", "auto", "auto"],
};

// set the different areas you need for every size
const areas = {
    small: [
        { name: "side1", start: [0, 0], end: [0, 0] },
        { name: "side2", start: [0, 1], end: [0, 1] },
        { name: "side3", start: [0, 2], end: [0, 2] },
    ],
    medium: [
        { name: "side1", start: [0, 0], end: [0, 0] },
        { name: "side2", start: [1, 0], end: [1, 0] },
        { name: "side3", start: [0, 1], end: [0, 1] },
    ],
    large: [
        { name: "side1", start: [0, 0], end: [0, 0] },
        { name: "side2", start: [1, 0], end: [1, 0] },
        { name: "side3", start: [0, 1], end: [0, 1] },
    ],
    xlarge: [
        { name: "side1", start: [0, 0], end: [0, 0] },
        { name: "side2", start: [1, 0], end: [1, 0] },
        { name: "side3", start: [0, 1], end: [0, 1] },
    ],
};

// set the different areas you need for every size
const ResponsiveGrid = ({
    children,
    overrideColumns,
    overrideRows,
    areas,
    ...props
}) => (
    <ResponsiveContext.Consumer>
        {(size) => {
            // take into consideration if not array is sent but a simple string
            let columnsVal = columns;
            if (columns) {
                if (columns[size]) {
                    columnsVal = columns[size];
                }
            }

            let rowsVal = rows;
            if (rows) {
                if (rows[size]) {
                    rowsVal = rows[size];
                }
            }

            // also if areas is a simple array not an object of arrays for different sizes
            let areasVal = areas;
            if (areas && !Array.isArray(areas)) areasVal = areas[size];

            return (
                <GridGroomet
                    {...props}
                    areas={!areasVal ? undefined : areasVal}
                    rows={!rowsVal ? size : rowsVal}
                    columns={!columnsVal ? size : columnsVal}
                    justify='center'
                >
                    {children}
                </GridGroomet>
            );
        }}
    </ResponsiveContext.Consumer>
);


export function PrioriQuotes(props) {
    useContext(ResponsiveContext);
    let url = useLocation();
    let vecUrl = url.pathname.split("/");
    let candidateId = vecUrl[vecUrl.length - 1];
    //const formsFilled = useAPI(`/api/formByFilter?candidate_id=${candidateId}&product_name=priori&type=priori_vinculacion`);
    //const plan = url.state;
    //get plan_details from local storage
    const plan = JSON.parse(localStorage.getItem('plan_details'));
    const [showDocumnetsCard, setShowDocumnetsCard] = useState(false)

    useEffect(() => {
        if (plan.estado === 'Pendiente de documentos' || plan.estado === 'En suscripción' || plan.estado === 'Emitido') {
            setShowDocumnetsCard(true);
        } else {
            setShowDocumnetsCard(false);
        }

    }, []);

    return (
        <Animate type='pop'>
            <div className={Style.buttonPosition}>
                <BackButton go='/vendedor/priori/prioriManagement/myQuotes' />
            </div>
            <div>
                <div style={{ display: "flex", justifyContent: "center", padding: "50px 0px 0px 0px" }}>
                    <h2 style={{ fontSize: "30px", color: "#0f265c", fontWeight: 600 }}>Cotización {plan.secuencial} / ${plan.product.planCode}</h2>
                </div>
                <div style={{ display: "flex", justifyContent: "center", padding: "50px 0px 0px 0px" }}>
                    <h3 style={{ fontSize: "20px", color: "#0f265c", fontWeight: 400 }}>PRIORI - Ahorro ${plan.product.planCode}</h3>
                </div>
                <div style={{ display: "flex", justifyContent: "center", padding: "50px 0px 0px 0px" }}>
                    <p><strong>En 5 minutos contaras con tu Póliza</strong> garantiza que el cliente tenga a la mano la documentación requerida.</p>
                </div>
                <Box
                    justify='center'
                    align='center'
                >
                    <ResponsiveGrid
                        rows={rows}
                        columns={columns}
                        gap='small'
                        areas={areas}
                        margin='small'
                    >
                        <Box gridArea='side1' className={Style.boxContainer}>
                            <MenuCard
                                label='Paso 1'
                                name='revenues'
                                title='Formulario de vinculación'
                                goto={'/formularioVinculacion/' + candidateId}
                                icon={paso1}
                            />
                        </Box>
                        {showDocumnetsCard && (
                            <Box
                                gridArea='side2'
                                align='start'
                                className={Style.boxContainer}
                            >
                                <MenuCard
                                    label='Paso 2'
                                    center
                                    name='revenues'
                                    title='Carga de documentos'
                                    goto={'/formCargaDocumentos/' + candidateId}
                                    icon={paso2}
                                />
                            </Box>
                        )}

                    </ResponsiveGrid>
                </Box>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <p><strong>Importante: </strong> Si el pagador es un tercero, adjunte la cédula y <a style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} target="_blank" href='https://segurosdelpichincha.com/docs/priori/AUTORIZACION-DE-DEBITO-TECERA-PERSONA.pdf' rel="noreferrer" >autorización de débito</a>.</p>

                </div>
                <div style={{ paddingBottom: '50px' }}></div>
            </div>
        </Animate>
    );
}
