import { Submit } from "components/bridges/BridgeServices";
import { useEffect, useState } from "react";
import img from "../../assets/img/Enmascarar.png";
import Style from "./dashboard.module.scss";
import StyleV2 from "../seller/agriculture/agricultureSeller.module.scss";
import { TerminosCondicionesGenericoModal } from "./terminosCondicionesGenericoModal";
import { useDataContext } from "../../context/DataContext";

export function Dashboard(props) {
  const userInformation = JSON.parse(localStorage.getItem("user"));
  const [termsModalVisible, setTermsModalVisible] = useState(false);

  //*************Start of section Data Context*************//
  const [textLoadingCatalogs, setTextLoadingCatalogs] = useState('Cargando catálogos...')
  const [contextLoaded, setContextLoaded] = useState(false)
  const {
    state,
    callStatusMap,
    getAllBanks,
    getCountries,
    getBranchXProducts,
    getBranchXProductsAgro,
    getWayPaids,
    getCards,
    getMaritalStatuses,
    getActivities,
    getNacionalities,
    fetchProvinces,
    getTypePaid,
    fetchTypeId,
    getPerson,
    getGender,
    getCiudadesCatalog,
    getPatrimonios,
    getRangos,
    getParentescos,
    getSponsors,
    getBrokers,
    getSalesForces,
    getUsuariosSDP,
    getBranch
  } = useDataContext();

  const [percent, setPercent] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      const fetchActions = [
        { action: getAllBanks, stateKey: 'banks', percent: 5 },
        { action: getCountries, stateKey: 'countries', percent: 10 },
        { action: getBranchXProducts, stateKey: 'branchXProducts', percent: 15 },
        { action: getWayPaids, stateKey: 'wayPaid', percent: 20 },
        { action: getCards, stateKey: 'cards', percent: 25 },
        { action: getMaritalStatuses, stateKey: 'maritalStatus', percent: 30 },
        { action: getActivities, stateKey: 'activities', percent: 35 },
        { action: getNacionalities, stateKey: 'nacionalities', percent: 40 },
        { action: fetchProvinces, stateKey: 'provinces', percent: 45 },
        { action: getTypePaid, stateKey: 'typePaid', percent: 50 },
        { action: fetchTypeId, stateKey: 'typeid', percent: 55 },
        { action: getPerson, stateKey: 'person', percent: 60 },
        { action: getGender, stateKey: 'gender', percent: 65 },
        { action: getCiudadesCatalog, stateKey: 'ciudades', percent: 70 },
        { action: getPatrimonios, stateKey: 'patrimonios', percent: 75 },
        { action: getRangos, stateKey: 'rangos', percent: 80 },
        { action: getParentescos, stateKey: 'parentescos', percent: 85 },
        { action: getBranchXProductsAgro, stateKey: 'branchXProductsAgro', percent: 90 },
        { action: getBranch, stateKey: 'branch', percent: 92 },
        { action: getSponsors, stateKey: 'sponsors', percent: 95 },
        { action: getBrokers, stateKey: 'brokers', percent: 96 },
        { action: getSalesForces, stateKey: 'salesForces', percent: 97 },
        { action: getUsuariosSDP, stateKey: 'usuariosSDP', percent: 100 }
      ];
      for (const { action, stateKey, percent } of fetchActions) {
        if (!state[stateKey] && (!callStatusMap.has(stateKey) || callStatusMap.get(stateKey) === 'error')) {
          callStatusMap.set(stateKey, 'fetching'); // Mark as fetching
          try {
            await action();
            setPercent(percent);
          } catch (error) {
            console.error(`Error fetching ${stateKey}: ${error.message}`);
            callStatusMap.set(stateKey, 'error'); // Mark as error on failure
          }
        }
      }
      let failedKeys = [...callStatusMap.entries()]
        .filter(({ 1: v }) => v === 500)
        .map(([k]) => k);
      //2nd-attemp failed calls
      if (failedKeys.length > 0) {
        setTextLoadingCatalogs('Reintentado catálogos...');
        let newFetchActions = [];
        setTimeout(async () => {
          for (let entry of failedKeys) {
            const findedFetchActions = fetchActions.find(ele => ele.stateKey === entry);
            if (findedFetchActions) {
              newFetchActions.push(findedFetchActions);
            }
          }
          for (const { action, stateKey, percent } of newFetchActions) {
            try {
              callStatusMap.set(stateKey, 'fetching'); // Mark as fetching
              await action();
              setPercent(percent);
            } catch (error) {
              console.error(`Error fetching ${stateKey}: ${error.message}`);
              callStatusMap.set(stateKey, 'error'); // Mark as error on failure
            }
          }
          let failedKeysV2 = [...callStatusMap.entries()]
            .filter(({ 1: v }) => v === 500)
            .map(([k]) => k);
          //3rd-attemp failed calls
          if (failedKeysV2.length > 0) {
            setTextLoadingCatalogs('Reintentado catálogos 2...');
            let newFetchActionsV2 = [];
            setTimeout(async () => {
              for (let entry of failedKeysV2) {
                const findedFetchActionsV2 = fetchActions.find(ele => ele.stateKey === entry);
                if (findedFetchActionsV2) {
                  newFetchActionsV2.push(findedFetchActionsV2);
                }
              }
              for (const { action, stateKey, percent } of newFetchActionsV2) {
                try {
                  callStatusMap.set(stateKey, 'fetching'); // Mark as fetching
                  await action();
                  setPercent(percent);
                } catch (error) {
                  console.error(`Error fetching ${stateKey}: ${error.message}`);
                  callStatusMap.set(stateKey, 'error'); // Mark as error on failure
                }
              }
              setContextLoaded(true);
            }, "2000");
          } else {
            setContextLoaded(true);
          }
        }, "2000");
      } else {
        setContextLoaded(true);
      }
    };

    fetchData();
  }, []);
  //**************End of section Data Context**************//

  const rawUserStr = localStorage.getItem('rawUser');
  let rawUser;
  if (rawUserStr) {
    rawUser = parseJwt(rawUserStr);
  }

  useEffect(() => {
    if (rawUser?.loading === false && rawUser?.data !== null) {
      let rawUserObj = parseJwt(rawUser?.data);
      const terms_conditions = rawUserObj.terms_conditions;
      if (rawUserObj.is_agro_user === true) {
        if (terms_conditions !== null && terms_conditions !== undefined && terms_conditions !== '' && terms_conditions !== 'null') {
          setTermsModalVisible(false);
        } else {
          setTermsModalVisible(true);
        }
      } else {
        setTermsModalVisible(false);
      }
    }
  }, [rawUser]);

  function parseJwt(token) {
    if (token) {
      let base64Url = token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    } else {
      return undefined;
    }
  }

  const handleAcceptTerms = async () => {
    await Submit("user/acceptTC", null, 'patch').then(
      (resp) => {
      }
    );
    const rawUser = JSON.parse(localStorage.getItem('profile'));
    rawUser.terms_conditions = true;
    localStorage.setItem('profile', JSON.stringify(rawUser));
    setTermsModalVisible(false);
  };

  return (
    <div className={Style.section}>
      <p className={Style.textTitle}>Inicio</p>
      <div className={Style.mainContainer}>
        <div className={Style.welcomeTitle}>Bienvenido</div>
        <div className={Style.textTitle}>{`${userInformation.name}!`}</div>
        <div className={Style.centerImageContainer}>
          {!contextLoaded ?
            <>
              <div className={StyleV2.ldsDualRing}></div>
              <div className={Style.textCatalog}>{textLoadingCatalogs}</div>
              <div className={Style.textCatalogPercent}>{percent}%</div>
            </> :
            <img
              className={Style.imgHome}
              src={img}
              alt='Man working on computer'
            />
          }
        </div>
      </div>
      <TerminosCondicionesGenericoModal isOpen={termsModalVisible} onClose={handleAcceptTerms} file="https://storagesdpfe.blob.core.windows.net/generated-docs/terms.html" />
    </div>
  );
}
