import { Submit, getSoloTarifa, postFillFormPriori, postFormSave } from 'components/bridges/BridgeServices';
import { Container } from 'components/container/container';
import { Animate, Button, Chart, Grid, ViewContext, useAPI, useNavigate } from 'components/lib';
import { Text } from 'components/text/text';
import { useContext, useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { formatNumber } from 'utils/formatNumber';
import plan30 from '../../../../assets/img/30.png';
import plan60 from '../../../../assets/img/60.png';
import plan90 from '../../../../assets/img/90.png';
import datos from '../../../../assets/img/datos-Candidato.png';
import manSdp from '../../../../assets/img/sdpHombre.png';
import GlobalStyle from "../../../../css/globalStyle.module.scss";
import Style from "../style.module.scss";

const normalTextStyle = {
  size: "15px",
  color: "#0f265c",
  padding: "0.5em 0",
};

const data = {
  labels: [],
  datasets: [{
    label: 'Plan 30',
    data: [],
    borderWidth: 0,
  }]
};
const data2 = {
  labels: [],
  datasets: [{
    label: 'Plan 60',
    data: [],
    borderWidth: 0,
  }]
};
const data3 = {
  labels: [],
  datasets: [{
    label: 'Plan 90',
    data: [],
    borderWidth: 0,
  }]
};

const StepTwo = (props) => {
  const { client, step, setStep } = props;
  const viewContext = useContext(ViewContext);
  const productsLife = useAPI("/api/product/life");
  const navigate = useNavigate();

  const [plans, setPlans] = useState([]);
  const [dataProjection, setDataProjection] = useState([]);
  // const [requestSDP, setRequestSDP] = useState([]);
  const [hideButtonProyection, setHideButtonProyection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorProyeccion, setErrorProyeccion] = useState(false);
  const chart30Ref = useRef(null);
  const chart60Ref = useRef(null);
  const chart90Ref = useRef(null);
  const [sendEmailButtonDisabled, setSendEmailButtonDisabled] = useState(true);
  const [loadingComplete, setLoadingComplete] = useState(false);

  const [dataChart, setDataChart] = useState({
    data: { ...data },
    data2: { ...data2 },
    data3: { ...data3 },
  });

  const getProductsLife = async () => {

    try {
      setIsLoading(true);
      let tempPlans = [...plans];

      let planData30 = productsLife.data.filter((product) => {
        return product.detail.cod_producto.astr_descripcion === "PRIORI - AHORRO 30"
      });

      if (planData30.length > 0) {
        planData30[0]["namePlan"] = "Plan 30";
        planData30[0]["imagePlan"] = plan30;
        planData30[0]["isChecked"] = false;
        tempPlans.push(planData30[0]);
      }

      let planData60 = productsLife.data.filter((product) => {
        return product.detail.cod_producto.astr_descripcion === "PRIORI - AHORRO 60"
      });

      if (planData60.length > 0) {
        planData60[0]["namePlan"] = "Plan 60";
        planData60[0]["imagePlan"] = plan60;
        planData60[0]["isChecked"] = false;
        tempPlans.push(planData60[0]);
      }

      let planData90 = productsLife.data.filter((product) => {
        return product.detail.cod_producto.astr_descripcion === "PRIORI - AHORRO 90"
      });

      if (planData90.length > 0) {
        planData90[0]["namePlan"] = "Plan 90";
        planData90[0]["imagePlan"] = plan90;
        planData90[0]["isChecked"] = false;
        tempPlans.push(planData90[0]);
      }

      const selected = tempPlans.some((plan) => plan.isChecked === true);
      if (selected) {
        setSendEmailButtonDisabled(false);
      } else {
        setSendEmailButtonDisabled(true);
      }

      // tempPlans.push(planData30[0], planData60[0], planData90[0]);
      setPlans(tempPlans);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCheckboxChange = (indexPlan) => {
    // Actualiza el estado inverso del checkbox
    let tempPlans = [...plans];
    tempPlans[indexPlan].isChecked = !tempPlans[indexPlan].isChecked;

    // Desactivar los demas checkbox
    // tempPlans.forEach((plan, index) => {
    //   if (index !== indexPlan) {
    //     plan.isChecked = false;
    //   }
    // });

    const selected = tempPlans.some((plan) => plan.isChecked === true);
    if (selected) {
      setSendEmailButtonDisabled(false);
    } else {
      setSendEmailButtonDisabled(true);
    }


    setPlans(tempPlans);
  };

  const InformationContainerRender = (props) => {

    const { imagePlan, namePlan, indexPlan, coberturas, valores, loadingComplete } = props;

    // let tempCoberturas = coberturas;

    coberturas.sort((a, b) => {
      if (a.astr_principal < b.astr_principal) {
        return 1;
      }
      if (a.astr_principal > b.astr_principal) {
        return -1;
      }
      return 0;
    }
    );

    coberturas.sort((a, b) => {
      if (a.adbl_cob_mon_min === 0 && a.adbl_cob_mon_max === 0) {
        return 1;
      }
      if (b.adbl_cob_mon_min === 0 && b.adbl_cob_mon_max === 0) {
        return -1;
      }
      return 0;
    }
    );

    return (
      <Container backgroundColor="#f7f7f8" >
        <div style={{ display: "grid", gap: 15 }}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <img src={imagePlan} alt={namePlan} />
          </div>
          <div style={{ display: "flex", justifyContent: "center", margin: "20px" }}>
            <label className={Style.containeraCheckBox}>
              <input
                type='checkbox'
                checked={plans[indexPlan].isChecked}
                onChange={() => handleCheckboxChange(indexPlan)}
                disabled={!loadingComplete}
              />
              <span className={Style.checkmark}></span>
            </label>
          </div>
          <Text text={namePlan} size="24px" weigth={800} color="#0f265c" align={"center"} />

          {coberturas.map((cobertura, index) => {
            return (
              <CoberturasTextRender
                key={`priori-corberturas-${index}`}
                coberturaText={cobertura.astr_cob_descrip_print}
                priceCobertura={valores[index].alternativas[0].valor_alternativa}
              />
            )
          }
          )}
        </div>
      </Container>
    )
  }

  const CoberturasTextRender = (props) => {

    const { coberturaText, priceCobertura } = props;

    const formatNumber = (numero) => {
      let numeroFormateado = parseFloat(numero).toLocaleString('es-ES', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      });

      // let numeroFormateado = parseFloat(numero).toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, '.');;

      return numeroFormateado;
    }

    return (
      <>
        <hr className={Style.horizontalLine} />
        <Text text={coberturaText} size="24px" weigth={800} color="#0f265c" align={"center"} />
        <Text text={`$${formatNumber(priceCobertura)}`} size="18px" color="#000000" align={"center"} />
      </>
    )
  }

  const calcularEdad = (fecha) => {
    let hoy = new Date();
    let cumpleanos = new Date(fecha);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    let m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    return edad;
  };

  const getValoresProyeccion = async () => {
    try {

      let today = new Date();

      let selectedPlan = [...plans];
      let requests = [];

      selectedPlan.map((plan) => {

        let tempSelectedPlan = plan;

        let tempCoberturas = [];

        tempSelectedPlan.detail.cod_producto.coberturas.forEach((cobertura, index) => {
          let tipoCobertura = "0";
          let valorAsegurado = tempSelectedPlan.detail.valores_asegurados.plan1.filter((valor) => {
            return valor.astr_codigo_cob === cobertura.astr_codigo_cob;
          }
          );

          if (cobertura.astr_principal === "S") {
            tipoCobertura = "1";

            tempCoberturas.unshift({
              adec_valor_aseg: parseFloat(valorAsegurado[0].alternativas[0].valor_alternativa),
              astr_cobertura: cobertura.astr_codigo_cob,
              astr_tipo_cobertura: tipoCobertura,
            });
          } else {
            tempCoberturas.push({
              adec_valor_aseg: parseFloat(valorAsegurado[0].alternativas[0].valor_alternativa),
              astr_cobertura: cobertura.astr_codigo_cob,
              astr_tipo_cobertura: tipoCobertura,
            });
          }
        }
        );

        let objReq = {
          resource: "/rest/jwt/soloproyeccion",
          data: {
            adt_fecha_nac: new Date(client.fechaNacimiento).toISOString().split("T")[0] + "T00:00:00",
            adt_fecha_cotiz: today.toISOString().split("T")[0] + "T00:00:00",
            astr_sexo: "1",
            astr_fumador: "N",
            aint_edad: calcularEdad(client.fechaNacimiento),
            astr_producto: tempSelectedPlan.detail.cod_producto.astr_descripcion,
            astr_frec_pago: client.frecuenciaPago,
            aint_aniversario: "1",
            coberturas: tempCoberturas,
          },
          httpVerb: "GET",
        };

        requests.push(objReq);
        plan.requestSDP = objReq;

      });

      let tempDataProjection = [...dataProjection];

      const promises = requests.map(async (request) => {
        try {
          const value = await Submit("bridgesdp", request, "post");
          value.data["namePlan"] = request.data.astr_producto === "PRIORI - AHORRO 30" ? "Plan 30" : request.data.astr_producto === "PRIORI - AHORRO 60" ? "Plan 60" : "Plan 90";
          if (value.data?.data && value.data?.data?.proyeccion.length > 0) {
            tempDataProjection.push(value.data);
          } else {
            window.alert("No se encontraron datos de proyección");
            setErrorProyeccion(true);
            setStep(0);
          }

          viewContext.notification.show(`configurando proyección ${value.data["namePlan"]}`, "success", true);
        } catch (error) {
          console.error('error: ', error);
          viewContext.handleError(error);
          setErrorProyeccion(true);
          setStep(0);
        } finally {
        }
      });


      await Promise.all(promises);


      if (errorProyeccion) {
        setStep(step - 1);
        return;
      }

      tempDataProjection.sort((a, b) => {
        if (a.namePlan < b.namePlan) {
          return -1;
        }
        if (a.namePlan > b.namePlan) {
          return 1;
        }
        return 0;
      }
      );

      setDataProjection(tempDataProjection);
      let tempDataChart = { ...dataChart };
      let edad = calcularEdad(client.fechaNacimiento);

      tempDataProjection[0].data.proyeccion.map((item) => {
        if (item.adec_edad === (edad + 10)) {
          tempDataChart.data.labels.push(`A tus ${edad + 10} años`);
          tempDataChart.data.datasets[0].data.push(item.adec_valor_ahorro);
        } else if (item.adec_edad === (edad + 20)) {
          tempDataChart.data.labels.push(`A tus ${edad + 20} años`);
          tempDataChart.data.datasets[0].data.push(item.adec_valor_ahorro);
        } else if (item.adec_edad === 70) {
          tempDataChart.data.labels.push("A tus 70 años");
          tempDataChart.data.datasets[0].data.push(item.adec_valor_ahorro);
        }
      });



      tempDataProjection[1].data.proyeccion.map((item) => {
        if (item.adec_edad === (edad + 10)) {
          tempDataChart.data2.labels.push(`A tus ${edad + 10} años`);
          tempDataChart.data2.datasets[0].data.push(item.adec_valor_ahorro);
        } else if (item.adec_edad === (edad + 20)) {
          tempDataChart.data2.labels.push(`A tus ${edad + 20} años`);
          tempDataChart.data2.datasets[0].data.push(item.adec_valor_ahorro);
        } else if (item.adec_edad === 70) {
          tempDataChart.data2.labels.push("A tus 70 años");
          tempDataChart.data2.datasets[0].data.push(item.adec_valor_ahorro);
        }
      });




      tempDataProjection[2].data.proyeccion.map((item) => {
        if (item.adec_edad === (edad + 10)) {
          tempDataChart.data3.labels.push(`A tus ${edad + 10} años`);
          tempDataChart.data3.datasets[0].data.push(item.adec_valor_ahorro);
        } else if (item.adec_edad === (edad + 20)) {
          tempDataChart.data3.labels.push(`A tus ${edad + 20} años`);
          tempDataChart.data3.datasets[0].data.push(item.adec_valor_ahorro);
        } else if (item.adec_edad === 70) {
          tempDataChart.data3.labels.push("A tus 70 años");
          tempDataChart.data3.datasets[0].data.push(item.adec_valor_ahorro);
        }
      });



      setDataChart(tempDataChart);

      setHideButtonProyection(true);
      setIsLoading(false);
      setLoadingComplete(true);
    } catch (error) {
      if (errorProyeccion) {
        setStep(step - 1);
      }
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }


  async function createCandidate(plan) {
    let reqSaveCandidate = {
      product_type: "priori",
      estado: "Precandidato",
      detail: {
        nombre: client.nombre,
        apellido: client.apellido,
        correo: client.correo,
        telefono: client.telefono,
        fechaNacimiento: client.fechaNacimiento,
        frecuenciaPago: client.frecuenciaPago,
        asesor: client.asesor,
        plan: plan,
      },
      product: plan,
    }

    const candidateId = await Submit("candidate", reqSaveCandidate, "post")
      .then((value) => {
        return value.data.data[0].id;
      })
      .catch((error) => {
        return null;
      })

    return candidateId;
  }

  function getProductName(namePlan) {
    return namePlan === "Plan 30" ? "PRIORI - AHORRO 30" : namePlan === "Plan 60" ? "PRIORI - AHORRO 60" : "PRIORI - AHORRO 90";
  }


  const sendMail = async () => {

    setIsLoading(true);
    let today = new Date();
    let selectedPlan = plans.filter((plan) => {
      return plan.isChecked === true;
    });

    const mapPromise = selectedPlan.map(async (individualPlan) => {
      const type = individualPlan.namePlan === "Plan 30" ? "AP30" : individualPlan.namePlan === "Plan 60" ? "AP60" : "AP90";
      const planCode = individualPlan.namePlan === "Plan 30" ? "30" : individualPlan.namePlan === "Plan 60" ? "60" : "90";
      const url = await uploadAndGetUrl(type);
      const astr_id_producto = individualPlan.namePlan === "Plan 30" ? "00199" : individualPlan.namePlan === "Plan 60" ? "00200" : "00201";
      individualPlan.astr_id_producto = astr_id_producto;
      individualPlan.url = url;
      individualPlan.planCode = planCode;
      individualPlan.detail.coberturas = [];
      individualPlan.detail.cod_producto.coberturas.forEach((cobertura, index) => {
        let tipoCobertura = "0";
        let valorAsegurado = individualPlan.detail.valores_asegurados.plan1.filter((valor) => {
          return valor.astr_codigo_cob === cobertura.astr_codigo_cob;
        }
        );
        if (cobertura.astr_principal === "S") {
          tipoCobertura = "1";

          individualPlan.detail.coberturas.unshift({
            adec_valor_aseg: parseFloat(valorAsegurado[0].alternativas[0].valor_alternativa),
            astr_cobertura: cobertura.astr_codigo_cob,
            astr_tipo_cobertura: tipoCobertura,
          });
        } else {
          individualPlan.detail.coberturas.push({
            adec_valor_aseg: parseFloat(valorAsegurado[0].alternativas[0].valor_alternativa),
            astr_cobertura: cobertura.astr_codigo_cob,
            astr_tipo_cobertura: tipoCobertura,
          });
        }
      });
    });
    viewContext.notification.show("Configurando y guardando (1/3)", "success", true);
    await Promise.all(mapPromise);

    const planPromise = selectedPlan.map(async (individualPlan) => {
      const candidateId = await createCandidate(individualPlan);
      individualPlan.candidateId = candidateId;
    });

    await Promise.all(planPromise);



    const promisesFromSave = selectedPlan.map(async (individualPlan, index) => {
      let reqPostFormSave = {
        type: "priori_cotizacion",
        detail: {
          adt_fecha_nac: new Date(client.fechaNacimiento).toISOString().split("T")[0] + "T00:00:00",
          adt_fecha_cotiz: today.toISOString().split("T")[0] + "T00:00:00",
          astr_sexo: "1",
          astr_fumador: "N",
          aint_edad: calcularEdad(client.fechaNacimiento),
          astr_producto: getProductName(individualPlan.namePlan),
          astr_frec_pago: client.frecuenciaPago,
          coberturas: individualPlan.detail.coberturas
        },
        product_name: `product${index + 1}`,
        candidate_id: individualPlan.candidateId,
      };

      let objUpdateStepTwo = {
        id: individualPlan.candidateId,
        step: "Paso 2",
        step_two_request: {
          description: [],
        },
        step_two_response: {
          description: [],
        },
      };

      objUpdateStepTwo.step_two_request.description.push(reqPostFormSave);
      const postFormSavePromise = postFormSave(reqPostFormSave).then((resp) => {
        individualPlan.form = resp.data[0];
      });
      return Promise.all([postFormSavePromise]);
    });
    await Promise.all(promisesFromSave);
    viewContext.notification.show("Configurando y guardando (2/3)", "success", true);

    //////////////////// obtener valor de pago mensual o anual adec_pag_valor_total
    let hasError = false;
    const promiseValorTotal = selectedPlan.map(async (individualPlan, index) => {

      let obj = {
        adt_fecha_nac: new Date(client.fechaNacimiento).toISOString().split("T")[0] + "T00:00:00",
        adt_fecha_cotiz: today.toISOString().split("T")[0] + "T00:00:00",
        astr_sexo: "1",
        astr_fumador: "N",
        aint_edad: calcularEdad(client.fechaNacimiento),
        astr_producto: getProductName(individualPlan.namePlan),
        aint_aniversario: 1,
        astr_frec_pago: client.frecuenciaPago,
        coberturas: individualPlan.detail.coberturas
      };

      let promiseRequest = null;
      try {
        promiseRequest = getSoloTarifa(obj).then(async (resp) => {

          if (resp === null || resp === undefined || resp.data === null || resp.data === undefined || resp.data.pagos === null || resp.data.pagos === undefined || resp.data.pagos.length === 0) {
            hasError = true;
            await deleteCandidate(individualPlan.candidateId);
            return null;
          }
          individualPlan.cuota_total = resp.data.pagos[0].adec_pag_valor_total;
        });

      } catch (error) {
        hasError = true;
        return null;
      }

      return Promise.all([promiseRequest]);
    });

    await Promise.all(promiseValorTotal);

    if (hasError) {
      window.alert("Error en el servicio de tarifa");
      viewContext.handleError("Error en el servicio de tarifa");
      hasError = true;
      setStep(0);
    } else {
      const form_ids = selectedPlan.map(obj => obj.form.id);
      const requestSDPs = selectedPlan.map(obj => obj.requestSDP);
      let request = {
        type: "priori_cotizacion",
        email: client.correo,
        toEmail: client.correo.toString().toLowerCase(),
        form_id: form_ids,
        context: [],
        requestSdp: requestSDPs,
      };

      const contextList = [];
      const promises = selectedPlan.map(async (individualPlan, index) => {

        let vecAniosAporte = [],
          vecEdad = [],
          vecCuotaTotalAnual = [],
          vecValorDisponible = [],
          vecBeneficioMuerte = [],
          vecValorRescate = [];

        let selectedDataProjection = dataProjection.filter((data) => {
          return data.namePlan === individualPlan.namePlan;
        });

        let dataProj = selectedDataProjection[0].data?.proyeccion;
        let dataProjObj = [];
        for (let entry of dataProj) {
          let tmpObj = {
            aniosaporte: entry.aint_anio,
            edad: entry.adec_edad,
            cuotatotalanual: entry.adec_prima_total,
            valordisponible: entry.adec_tasa_garantizada,
            beneficiomuerte: entry.adec_valor_prima_ahorro,
            valorrescate: entry.adec_tasa_garantizada,
            valorahorro: entry.adec_valor_ahorro,
          };
          dataProjObj.push(tmpObj);
          vecAniosAporte.push(entry.aint_anio);
          vecEdad.push(entry.adec_edad);
          vecCuotaTotalAnual.push(entry.adec_prima_total);
          vecValorDisponible.push(entry.adec_tasa_garantizada);
          vecBeneficioMuerte.push(entry.adec_valor_prima_ahorro);
          vecValorRescate.push(entry.adec_tasa_garantizada);
        }

        let profileData = JSON.parse(localStorage.getItem("profile"));


        let ahorro_10 = dataProj
          ? dataProj[9]?.adec_valor_ahorro
          : 0;
        let ahorro_20 = dataProj.length > 19
          ? dataProj[19]?.adec_valor_ahorro : 0;

        let ahorro_70 = dataProj
          ? dataProj[dataProj.length - 1]?.adec_valor_ahorro
          : 0;

        let strCoberturaPrincipal = individualPlan.detail.cod_producto.coberturas
          .map((item, idx) => {
            if (item.astr_principal === "S") return item.astr_cob_descrip_print;
            else return null;
          })
          .filter(Boolean)
          .join(",");


        let strCoberturaSecundaria = individualPlan.detail.cod_producto.coberturas
          .map((item, idx) => {
            if (item.astr_principal === "N") return item.astr_cob_descrip_print;
            else return null;
          })
          .filter(Boolean)
          .join(",");


        const parts = individualPlan.url.split('/');
        const fileName = parts[parts.length - 2] + '/' + parts[parts.length - 1];
        const folder = parts[3];
        let tmpEjecutivo = await getExecutiveType();

        const context = {
          "#firma_revelador": {
            b: folder,
            f: fileName,
            h: 8,
            type: individualPlan.planCode,
          },
          nombres: client.nombre + " " + client.apellido,
          fechaNacimiento: client.fechaNacimiento,
          genero: "Masculino",
          telefono: client.telefono,
          correo: client.correo,
          ejecutivo: tmpEjecutivo,
          cedula: "9999999999",
          asesor: profileData.data.nombre + " " + profileData.data.apellido,
          correoAsesor: profileData.data.email,
          fecha: new Date().toISOString().split("T")[0],
          fin_vigencia: "",
          producto: getProductName(individualPlan.namePlan),
          estatus: "CANDIDATO",
          cuota_total: individualPlan.cuota_total,
          ahorro_10: ahorro_10,
          ahorro_20: ahorro_20,
          ahorro_70: ahorro_70,
          frecuencia: client.frecuenciaPago === "A" ? "Anual" : "Mensual",
          fumador: "N",
          cober_principal_monto: individualPlan.detail.coberturas[0].adec_valor_aseg,
          cober_secundaria_monto: individualPlan.detail.coberturas[1].adec_valor_aseg,
          cober_principal_nombre: strCoberturaPrincipal,
          cober_secundaria_nombre: strCoberturaSecundaria,
          edad: vecEdad,
          cuotatotalanual: vecCuotaTotalAnual,
          valordisponible: vecValorDisponible,
          beneficiomuerte: vecBeneficioMuerte,
          valorrescate: vecValorRescate,
          labels_proyeccion: [
            "aniosaporte",
            "edad",
            "cuotatotalanual",
            "valordisponible",
            "beneficiomuerte",
            "valorrescate"
          ],
          proyeccion: dataProjObj,
        };
        contextList.push(context);
      });

      await Promise.all([promises]);

      request.context = contextList;
      await postFillFormPriori(request).then((resp) => {

      });

      await Promise.all(promises);
      viewContext.notification.show("Configurando y guardando (3/3)", "success", true);
      setIsLoading(false);
      navigate("/vendedor/priori/prioriManagement");
    }




  };

  async function deleteCandidate(candidateId) {
    await Submit(`candidate/${candidateId}`, {}, "delete");
  }

  const getExecutiveType = async () => {
    let typeExecutive = "Seguros del Pichincha";
    let profileData = JSON.parse(localStorage.getItem("profile"));
    let curIntermediary = profileData?.data?.intermediary;
    if (curIntermediary) {
      let tipoSdp = typeof curIntermediary?.sdp;
      let tipoSponsor = typeof curIntermediary?.sponsor;
      let tipoFv = typeof curIntermediary?.fv;
      let tipoBroker = typeof curIntermediary?.broker;

      if (tipoSdp === "object") {
        typeExecutive = "Seguros del Pichincha";
      }
      if (tipoSponsor === "object") {
        typeExecutive = curIntermediary?.sponsor?.astr_nombre_asesor;
      }
      if (tipoFv === "object") {
        typeExecutive = curIntermediary?.fv?.astr_nombre_asesor;
      }
      if (tipoBroker === "object") {
        typeExecutive = curIntermediary?.broker?.astr_nombre_asesor;
      }
    }
    return typeExecutive;
  };

  const onePlanSelected = (plans) => {
    plans.some((plan) => plan.isChecked === true);
  }


  useEffect(() => {
    if (!productsLife.loading && productsLife.data) {
      setDataProjection([]);
      setHideButtonProyection(false);
      setIsLoading(false);
      setPlans([]);
      setErrorProyeccion(false);

      let tempDataChart = { ...dataChart };

      tempDataChart.data.labels = [];
      tempDataChart.data.datasets[0].data = [];
      tempDataChart.data2.labels = [];
      tempDataChart.data2.datasets[0].data = [];
      tempDataChart.data3.labels = [];
      tempDataChart.data3.datasets[0].data = [];

      setDataChart(tempDataChart);

      // setRequestSDP([]);
      getProductsLife();

    }
  }, [productsLife]);

  useEffect(() => {
    if (plans.length > 0 && !onePlanSelected(plans) && dataProjection.length === 0) {
      setIsLoading(true);
      getValoresProyeccion();
    }
  }, [plans]);

  async function uploadAndGetUrl(type) {
    const rawUser = JSON.parse(localStorage.getItem('profile'));
    const base64 = type === 'AP30' ? chart30Ref.current.toBase64Image() : type === 'AP60' ? chart60Ref.current.toBase64Image() : chart90Ref.current.toBase64Image();
    const obj = {};
    obj["data"] = base64;
    obj["email"] = rawUser.data.email;
    obj["type"] = `${type}`;
    const result = await Submit("upload_file", obj, "post");
    const imageUrl = result.data.data.message;
    return imageUrl;
  };



  return (
    <Animate type='pop'>
      <Grid cols='1'>
        <Text text="Cotización" size="30px" weigth={800} color="#0f265c" />
      </Grid>
      <Grid cols='2'>
        <Container backgroundColor="#dddee6" height="280px">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={manSdp} alt="Man with laptop" />
          </div>
        </Container>
        <Container backgroundColor="#e6e7f4" padding="1em 3em" height="280px">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={datos} alt="Logo Priori" />
            <Text text="Datos del Candidato" size="20px" weigth={800} color="#0f265c" />
          </div>
          <hr className={Style.horizontalLine} />
          <Text text={`Nombre: ${client?.nombre}`} {...normalTextStyle} />
          <Text text={`Apellido: ${client?.apellido}`} {...normalTextStyle} />
          <Text text={`Fecha de nacimiento: ${client?.fechaNacimiento}`} {...normalTextStyle} />
          <Text text={`Correo: ${client?.correo}`} {...normalTextStyle} />
          <Text text={`Teléfono: ${client?.telefono}`} {...normalTextStyle} />
        </Container>
      </Grid>
      <Grid cols='1'>
        <Container backgroundColor="#f7f7f8" >
          <Text text="Planes" size="30px" weigth={800} color="#0f265c" />
        </Container>
      </Grid>
      <Grid cols={plans.length}>
        {plans.map((plan, index) => {
          return (
            <InformationContainerRender
              key={`planpriori-${index}`}
              imagePlan={plan.imagePlan}
              namePlan={plan.namePlan}
              coberturas={plan.detail.cod_producto.coberturas}
              valores={plan.detail.valores_asegurados.plan1}
              indexPlan={index}
              loadingComplete={loadingComplete}
            />
          )
        })}
      </Grid>
      <Grid cols='1'>
        <Text text="En todos nuestros planes cuentas con una cobertura de muerte accidental y muerte por cualquier causa."
          size="18px" weigth={500} color="#000000" align={"center"} />
      </Grid>
      {hideButtonProyection &&
        <Grid cols={plans.length}>
          {plans.map((plan, index) => {
            return (
              plan.namePlan === "Plan 30" ?
                <Chart ref={chart30Ref} key={"Chart30"} type="bar" legend={false} data={dataChart.data} color="priori30" /> : plan.namePlan === "Plan 60" ?
                  <Chart ref={chart60Ref} key={"Chart60"} type="bar" legend={false} data={dataChart.data2} color="priori60" /> : plan.namePlan === "Plan 90" ?
                    <Chart ref={chart90Ref} key={"Chart90"} type="bar" legend={false} data={dataChart.data3} color="priori90" /> : null
            )
          })}
        </Grid>
      }
      {/* && !hideButtonProyection  */}
      {isLoading && !hideButtonProyection &&
        <Grid cols='1'>
          <div style={{ display: "flex" }}>
            <div
              className={Style.ldsDualRing}
            ></div>
          </div>
        </Grid>
      }
      <Grid cols={dataProjection.length}>

        {dataProjection.length > 0 && dataProjection.map((item, idx) => {
          return (
            <div key={`table-proyeccion-priori-candidato-${idx}`} style={{ padding: "20px 5px" }}>
              <Scrollbars style={{ width: "100%", height: "450px" }}>
                <table className={Style.tg}>
                  <thead>
                    <tr>
                      <th className={Style.tg0pkyHead}>Tu edad</th>
                      <th className={Style.tg0pkyHead}>Tu ahorro</th>
                      <th className={Style.tg0pkyHead}>Tu valor de rescate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      item.data.proyeccion?.map((item, idx) => {
                        return (
                          <tr key={`keyProjection-priori-candidato-${idx}`}>
                            <td className={Style.tg0pky}>{item.adec_edad}</td>
                            <td className={Style.tg0pky}>
                              {item.adec_valor_ahorro === 0
                                ? `$0.00`
                                : `$${formatNumber(item.adec_valor_ahorro)}`}
                            </td>
                            <td className={Style.tg0pky}>
                              {item.adec_tasa_garantizada === 0
                                ? `$0.00`
                                : `$${formatNumber(item.adec_tasa_garantizada)}`}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </Scrollbars>
            </div>)
        }
        )}
      </Grid>
      {hideButtonProyection &&
        <>
          {!isLoading ?
            <Grid cols='1'>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  //disabled={sendEmailButtonDisabled}
                  //applyColor={!onePlanSelected(plans) ? "#455680" : '#0f265c'}
                  text='Enviar información'
                  className={sendEmailButtonDisabled ? GlobalStyle.buttonFinalizarDisabled : GlobalStyle.buttonFinalizar}
                  action={() => {
                    sendMail();
                    // setIsLoading(true);
                    // saveCandidate();
                    // setSentMail(true);
                    // sendInformationEmail();
                  }}
                />
              </div>
            </Grid>
            :
            <div
              className={Style.ldsDualRing}
            ></div>
          }
        </>
      }
    </Animate>
  );
}

export { StepTwo };
