import React, { useEffect, useState } from "react";
import { Title } from "./title";
import CurrencyInput from "react-currency-input-field";
import { formatNumber } from "utils/formatNumber";
import Style from "../../../../../css/globalStyle.module.scss";

export function InformacionFinanciera(props) {
  const {
    register,
    watch,
    errors,
    valuesEconomicos,
    setValuesEconomicos,
    setValue,
  } = props;
  const ingresoMensualActividadEconomica = watch("ingresoMensualActividadEconomica");
  const otroIngresoDiferenteActividadEconomica = watch("otroIngresoDiferenteActividadEconomica");
  const totalEgresosMensuales = watch("totalEgresosMensuales");
  const totalActivos = watch("totalActivos");
  const totalPasivos = watch("totalPasivos");
  const [errorMessage, setErrorMessage] = useState({
    ingresoMensualActividadEconomica: "",
    otroIngresoDiferenteActividadEconomica: "",
    totalEgresosMensuales: "",
    totalActivos: "",
    totalPasivos: "",
  });
  const limit = 100000000;
  const prefix = "$";

  const handleOnValueChange = (value, _, values, nameState) => {
    // setValues(values);
    // setRawValue(value === undefined ? "undefined" : value || " ");

    if (!value) {
      // setClassName("");
      // setValue("");
      setValuesEconomicos({ ...valuesEconomicos, [nameState]: "" });
      return;
    }

    if (Number.isNaN(Number(value))) {
      setErrorMessage({ ...errorMessage, [_]: "Por favor ingresa un numero válido" });
      // setClassName("is-invalid");
      return;
    }

    if (Number(value) > limit) {
      setErrorMessage({
        ...errorMessage,
        [_]: `Valor Máximo: ${prefix}${formatNumber(limit)}`,
      });
      // setClassName("is-invalid");
      // setValue(value);
      return;
    }

    if (Number(value) >= limit) {
      setErrorMessage({ ...errorMessage, [_]: `` });
      // setClassName("is-invalid");
      // setValue(value);
      return;
    }

    setValuesEconomicos({ ...valuesEconomicos, [nameState]: value });
    // setClassName("is-valid");
    // setValue(value);
  };

  useEffect(() => {
    let tempIngreso = ingresoMensualActividadEconomica ?
      Number(ingresoMensualActividadEconomica.replace(/[^0-9.-]+/g, "")) : 0;
    let tempOtroIngreso = otroIngresoDiferenteActividadEconomica ?
      Number(otroIngresoDiferenteActividadEconomica.replace(/[^0-9.-]+/g, "")) : 0;
    let tempEgreso = totalEgresosMensuales ?
      Number(totalEgresosMensuales.replace(/[^0-9.-]+/g, "")) : 0;
    let tempTotalIngreso = parseFloat(tempIngreso) + parseFloat(tempOtroIngreso);
    let tempActivos = totalActivos ? Number(totalActivos.replace(/[^0-9.-]+/g, "")) : 0;
    let tempPasivos = totalPasivos ? Number(totalPasivos.replace(/[^0-9.-]+/g, "")) : 0;
    let tempPatrimonio = parseFloat(tempActivos ? tempActivos : 0) - parseFloat(tempPasivos ? tempPasivos : 0);

    if (tempTotalIngreso !== 0) {
      setValuesEconomicos(prevValues => ({
        ...prevValues,
        ingreso: tempTotalIngreso.toFixed(2),
      }));
    }

    setValuesEconomicos(prevValues => ({
      ...prevValues,
      actividadPrincipal: tempIngreso,
      otroIngreso: tempOtroIngreso,
      egreso: tempEgreso,
      activos: tempActivos,
      pasivos: tempPasivos,
      patrimonio: tempPatrimonio.toFixed(2)
    }));

    setValue(
      "totalIngresoMensuales",
      isNaN(tempTotalIngreso) ? `$0.00` : `$${formatNumber(tempTotalIngreso)}`
    );
    setValue(
      "patrimonio",
      isNaN(tempPatrimonio) ? `$0.00` : `$${formatNumber(tempPatrimonio)}`
    );
  }, [ingresoMensualActividadEconomica, otroIngresoDiferenteActividadEconomica,
    totalEgresosMensuales, totalActivos, totalPasivos]);

  return (
    <div>
      <Title whiteTitle='5. Sección información financiera del propuesto asegurado' />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={Style.inputPreguntas}>
          <div className={Style.inputContainerHalfNoPadding}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Ingresos mensuales de su actividad económica principal
            </label>
          </div>
          <div className={Style.inputContainerFinanciero}>
            <CurrencyInput
              tabIndex={37}
              {...register("ingresoMensualActividadEconomica", {
                required: true,
                min: 0,
                value: valuesEconomicos.actividadPrincipal,
              })}
              id='ingresoMensualActividadEconomica'
              name='ingresoMensualActividadEconomica'
              decimalsLimit={2}
              prefix={"$"}
              decimalSeparator="."
              groupSeparator=","
              onValueChange={(value, _, values) => {
                handleOnValueChange(value, _, values, "actividadPrincipal");
              }}
              className={Style["input_numberFinanciero"]}
              value={
                valuesEconomicos.actividadPrincipal
                  ? valuesEconomicos.actividadPrincipal
                  : 0
              }
            />
            {errorMessage.ingresoMensualActividadEconomica !== "" && (
              <p style={{ color: "red", marginLeft: 10 }}>
                {errorMessage.ingresoMensualActividadEconomica}
              </p>
            )}
            {errors.ingresoMensualActividadEconomica?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </div>
        <div className={Style.inputPreguntas}>
          <div className={Style.inputContainerHalfNoPadding}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Otros ingresos mensuales diferentes a la actividad económica principal
            </label>
          </div>
          <div className={Style.inputContainerFinanciero}>
            <CurrencyInput
              tabIndex={38}
              {...register("otroIngresoDiferenteActividadEconomica", {
                required: true,
                min: 0,
              })}
              id='otroIngresoDiferenteActividadEconomica'
              name='otroIngresoDiferenteActividadEconomica'
              decimalsLimit={2}
              prefix={"$"}
              decimalSeparator="."
              groupSeparator=","
              onValueChange={(value, _, values) => {
                handleOnValueChange(value, _, values, "otroIngreso");
              }}
              className={Style["input_numberFinanciero"]}
              value={
                valuesEconomicos.otroIngreso ? valuesEconomicos.otroIngreso : 0
              }
            />
            {errorMessage.otroIngresoDiferenteActividadEconomica !== "" && (
              <p style={{ color: "red", marginLeft: 10 }}>
                {errorMessage.otroIngresoDiferenteActividadEconomica}
              </p>
            )}
            {errors.otroIngresoDiferenteActividadEconomica?.type ===
              "required" && (
                <p style={{ color: "red" }}>Este campo es requerido</p>
              )}
          </div>
        </div>
        <div className={Style.inputPreguntas}>
          <div className={Style.inputContainerHalfThree}>
            <label>Fuente de otros ingresos</label>
          </div>
          <div className={Style.inputContainerFinancieroOtros}>
            <input
              tabIndex={39}
              type='text'
              {...register("fuenteOtrosIngresos")}
              className={Style["input"]}
            />
            {errors.fuenteOtrosIngresos?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </div>
        <div className={Style.inputPreguntas}>
          <div className={Style.inputContainerHalfNoPadding}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Total ingresos mensuales
            </label>
          </div>
          <div className={Style.inputContainerFinanciero}>
            <input
              tabIndex={40}
              disabled
              value={
                isNaN(valuesEconomicos.ingreso)
                  ? `$0.00`
                  : `$${formatNumber(valuesEconomicos.ingreso)}`
              }
              type='text'
              className={Style["input_numberFinanciero"]}
              {...register("totalIngresoMensuales", {
                required: true,
                value: isNaN(valuesEconomicos.ingreso)
                  ? `$0.00`
                  : `$${formatNumber(valuesEconomicos.ingreso)}`,
              })}
            />
            {errors.totalIngresoMensuales?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </div>
        <div className={Style.inputPreguntas}>
          <div className={Style.inputContainerHalfNoPadding}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Total egresos mensuales
            </label>
          </div>
          <div className={Style.inputContainerFinanciero}>
            <CurrencyInput
              tabIndex={41}
              id='totalEgresosMensuales'
              name='totalEgresosMensuales'
              decimalsLimit={2}
              prefix={"$"}
              onValueChange={(value, _, values) => {
                handleOnValueChange(value, _, values, "egreso");
              }}
              className={Style["input_numberFinanciero"]}
              value={valuesEconomicos.egreso}
              {...register("totalEgresosMensuales", { required: true })}
            />
            <p style={{ color: "red", marginLeft: 10 }}>
              {errorMessage.totalEgresosMensuales !== "" &&
                errorMessage.totalEgresosMensuales}
            </p>
            {errors.totalEgresosMensuales?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#DEEBF7" }}>
        <h2 style={{ fontWeight: 600 }}>Situación financiera</h2>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className={Style.inputPreguntas}>
          <div className={Style.inputContainerHalfNoPadding}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Total activos
            </label>
          </div>
          <div className={Style.inputContainerFinanciero}>
            <CurrencyInput
              tabIndex={42}
              {...register("totalActivos", { required: true })}
              id='totalActivos'
              name='totalActivos'
              decimalsLimit={2}
              prefix={"$"}
              decimalSeparator="."
              groupSeparator=","
              onValueChange={(value, _, values) => {
                handleOnValueChange(value, _, values, "activos");
              }}
              className={Style["input_numberFinanciero"]}
              value={valuesEconomicos.activos ? valuesEconomicos.activos : 0}
            />
            <p style={{ color: "red", marginLeft: 10 }}>
              {errorMessage.totalActivos !== "" && errorMessage.totalActivos}
            </p>
            {errors.totalActivos?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </div>
        <div className={Style.inputPreguntas}>
          <div className={Style.inputContainerHalfNoPadding}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Total pasivos
            </label>
          </div>
          <div className={Style.inputContainerFinanciero}>
            <CurrencyInput
              tabIndex={43}
              {...register("totalPasivos", { required: true })}
              id='totalPasivos'
              name='totalPasivos'
              decimalsLimit={2}
              prefix={"$"}
              decimalSeparator="."
              groupSeparator=","
              onValueChange={(value, _, values) => {
                handleOnValueChange(value, _, values, "pasivos");
              }}
              className={Style["input_numberFinanciero"]}
              value={valuesEconomicos.pasivos ? valuesEconomicos.pasivos : 0}
            />
            <p style={{ color: "red", marginLeft: 10 }}>
              {errorMessage.totalPasivos !== "" && errorMessage.totalPasivos}
            </p>
            {errors.totalPasivos?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </div>
        <div className={Style.inputPreguntas}>
          <div className={Style.inputContainerHalfNoPadding}>
            <label>
              <b style={{ color: 'red' }}>*</b>&nbsp;Patrimonio
            </label>
          </div>
          <div className={Style.inputContainerFinanciero}>
            <input
              tabIndex={44}
              {...register("patrimonio", {
                required: true,
                value: isNaN(valuesEconomicos.patrimonio)
                  ? `$0.00`
                  : `$${formatNumber(valuesEconomicos.patrimonio)}`,
              })}
              disabled
              value={
                isNaN(valuesEconomicos.patrimonio)
                  ? `$0.00`
                  : `$${formatNumber(valuesEconomicos.patrimonio)}`
              }
              type='text'
              className={Style["input_numberFinanciero"]}
            />
            {errors.patrimonio?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
