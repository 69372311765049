import React, { useContext, useState, useEffect } from "react";
import {
  Animate,
  Button,
  Content,
  Table,
  ViewContext,
} from "components/lib";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { getFilterDataPrices, Submit } from "components/bridges/BridgeServices";
import { getCandidateAgroCsvList } from "../../../components/bridges/BridgeServices";
import Style from "../../../css/globalStyle.module.scss";
import "../../../css/DateRangePicker.css";
import { BackButton } from "components/backButton/button";
import useData from "../../../components/hooks/useGeneralData";
import { SetViewCandidate } from "utils/getPermissions";
import { Scrollbars } from "react-custom-scrollbars-2";

export function PriceLifeQualification(props) {
  const context = useContext(ViewContext);
  const [data, setData] = useState({});
  const [dataSeach, setDataSearch] = useState({
    nombre: "",
    asesor: "",
    id: "",
    dateBegin: "",
    dateEnd: "",
    date: "",
    secuencial: "",
    channel: "",
  });
  const [allCandidates, setAllCandidates] = useState([]);
  const { userPermissions, setPermissions } = useData();
  let objItermediary = {};
  //const rawUser = useAPI("/api/user/getById?id=" + user.userId, "get");
  //const rawUser = JSON.parse(localStorage.getItem('profile'));

  const getUserAttribs = async (rol_id) => {
    await Submit("rol_attributionByRolId?rolId=" + rol_id, null, "get").then(
      (value) => {
        let tempValue = value?.data?.data;
        localStorage.setItem(
          "user_permissions",
          JSON.stringify(tempValue?.detail)
        );
        setPermissions(tempValue.detail);
      }
    );
  };

  const getInvite = async () => {
    const rawUser = JSON.parse(localStorage.getItem('profile'));
    if (rawUser?.data) {
      if (rawUser.data.flag_uno) {
        setViewCandidate();
      } else {
        await Submit(
          "invite/getByEmail?email=" + rawUser.data.email,
          null,
          "get"
        ).then((value) => {
          objItermediary = value.data.data;
          let rolId = objItermediary?.intermediary?.rol;
          if (rolId) getUserAttribs(rolId);
        });
      }
    }
  };

  const setViewCandidate = () => {
    let isView = false;
    const rawUser = JSON.parse(localStorage.getItem('profile'));
    if (rawUser.data && rawUser.data.flag_uno) {
      isView = true;
    } else {
      if (userPermissions) {
        Object.keys(userPermissions.vida.calificacion).map((key) => {
          if (key === "u") {
            if (userPermissions.vida.calificacion[key]) {
              isView = true;
            }
          }
        });
      }
    }
    return isView;
  };

  const handleSearchData = async () => {
    let obj = {
      nombre: dataSeach.nombre,
      asesor: dataSeach.asesor,
      nro_documento: dataSeach.id,
      fecha_desde: dataSeach.dateBegin,
      fecha_hasta: dataSeach.dateEnd,
      product_type: "life",
      secuencial: dataSeach.secuencial,
      canal: dataSeach.channel,
    };

    try {
      let isAdmin = true;
      await getFilterDataPrices(obj, isAdmin).then((res) => {
        let candidates = [];
        if (res?.data) {
          res.data.map(async (item) => {
            if (item.product !== null && item.product !== undefined) {
              candidates.push(item);
            }
          });
          setAllCandidates(candidates);
        } else {
          context.notification.show("No existen registros", "error", true);
          setAllCandidates([]);
        }
      });

    } catch (e) {
      context.notification.show(e + '', "error", true);
      setAllCandidates([]);
    }


  };

  const parseDate = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleDate = (date) => {
    if (date === null) {
      setDataSearch({
        ...dataSeach,
        date: null,
      });
    } else {
      setDataSearch({
        ...dataSeach,
        dateBegin: parseDate(date[0]),
        dateEnd: parseDate(date[1]),
        date,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const alphanumericExtendedRegex = /^[0-9 ]+$/;
    if (value === '' || alphanumericExtendedRegex.test(value)) {
      setDataSearch({ ...dataSeach, [name]: value });
    }
  };

  const makeDataTable = () => {
    let tempDataTable = {
      header: [
        // { name: "detail", title: "Detalles", sort: false },
        // { name: "actionsPrice", title: "Acciones", sort: false },
        { name: "nombre", title: "Nombre", sort: false },
        { name: "ci", title: "Cédula", sort: false },
        {
          name: "fecha",
          title: "Fecha",
          sort: false,
        },
        { name: "producto", title: "Producto", sort: false },
        { name: "plan", title: "Plan", sort: false },
        { name: "estado", title: "Estado", sort: false },
        { name: "grupoCorporativo", title: "Grupo corporativo", sort: false },
        { name: "asesor", title: "Asesor", sort: false },
        { name: "totalCuota", title: "Total cuota", sort: false },
        {
          name: "modalidadPago",
          title: "Modalidad de pago",
          sort: false,
        },
        { name: "observaciones", title: "Observaciones", sort: false },
        { name: "calificar", title: "Calificar", sort: false },
      ],
      body: [],
    };
    let tempObjectCultivation = {};

    allCandidates.map((item, idx) => {
      tempObjectCultivation.detail = item;

      tempObjectCultivation.id = <div>{item.id}</div>;
      tempObjectCultivation.secuencial = item.secuencial;
      tempObjectCultivation.nombre = (
        <div>{`${item.detail.primer_nombre} ${item.detail.apellido_paterno}`}</div>
      );
      tempObjectCultivation.ci = <div>{item.nro_documento}</div>;
      tempObjectCultivation.fecha = <div>{item.created_at.split("T")[0]}</div>;
      tempObjectCultivation.producto = (
        <div>
          {item.product?.product1?.plan?.cod_producto?.astr_descripcion}
        </div>
      );
      tempObjectCultivation.plan = (
        <div>{item.product?.product1?.plan?.textPlan}</div>
      );
      tempObjectCultivation.estado = (
        <div style={{ fontWeight: "bold" }}>{item?.estado}</div>
      );
      tempObjectCultivation.grupoCorporativo = <div>{item?.canal}</div>;
      tempObjectCultivation.asesor = (
        <div>{item.product?.product1?.client?.asesor}</div>
      );
      tempObjectCultivation.totalCuota = (
        <div>
          {item.product?.product1?.dataProjection?.data?.pagos !== undefined &&
            item.product?.product1?.dataProjection?.data?.pagos !== null
            ? item.product?.product1?.dataProjection?.data?.pagos[0]
              .adec_pag_valor_total
            : 0}
        </div>
      );

      tempObjectCultivation.modalidadPago = (
        <div>
          {item.product?.product1?.client?.typePaid === "M"
            ? "MENSUAL"
            : item.product?.product1?.client?.typePaid === "A"
              ? "ANUAL"
              : item.product?.product1?.client?.typePaid === "S"
                ? "SEMESTRAL"
                : "TRIMESTRAL"}
        </div>
      );

      tempObjectCultivation.observaciones = (
        <div>
          {item?.estado === "Pendiente"
            ? item?.step_two_response?.description?.observaciones
            : item?.rules?.observaciones}
        </div>
      );
      const rawUser = JSON.parse(localStorage.getItem('profile'));

      tempObjectCultivation.calificar = (
        <Button
          disabled={
            item.estado === "Aprobado" ||
              item.estado === "Negar" ||
              item.estado === "En suscripción" ||
              SetViewCandidate(rawUser, userPermissions, ["u"], "calificacion")
              ? false
              : true
          }
          icon='edit'
          action={() => editCandidate(item)}
          className={Style.actionButton}
        />
        // <button onClick={() => editCandidate(item)}>text</button>
      );
      tempDataTable.body.push(tempObjectCultivation);
      tempObjectCultivation = {};
    });
    setData(tempDataTable);
  };

  function editCandidate(data, callback) {
    context.modal.show(
      {
        title: "Calificar",
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          secuencial: {
            required: true,
            readonly: true,
            value: data.secuencial,
            type: "hidden",
          },
          nro_documento: {
            label: "Cédula",
            value: data.nro_documento,
            readonly: true,
          },
          nombres: {
            label: "Nombres",
            value: `${data.detail.primer_nombre} ${data.detail.apellido_paterno}`,
            readonly: true,
          },
          grupoCorporativo: {
            label: "Grupo corporativo",
            value: data.canal,
            readonly: true,
          },
          asesor: {
            label: "Asesor",
            value: data.product?.product1?.client?.asesor,
            readonly: true,
          },
          observaciones: {
            label: "Observaciones",
            type: "text",
            required: true,
            value: "",
            errorMessage: "Por favor ingresa una observación",
          },
          estado: {
            label: "Estado",
            type: "radio",
            options: [
              "Aprobado",
              "Negado",
              "Emitido",
              "Pendiente de documentos",
            ],
            required: true,
            errorMessage: "Por favor selecciona una opción",
          },
        },
        buttonText: "Actualizar",
        url: "api/candidate/updateStepLife",
        method: "PATCH",
      },
      (res) => {
        context.notification.show(
          data.secuencial + " ha sido actualizado",
          "success",
          true
        );
        // callback(res);
        window.location.reload(true);
      }
    );
  }

  useEffect(() => {
    makeDataTable();
  }, [allCandidates]);

  useEffect(() => {
    getInvite();
  }, []);

  async function generateCsvList() {
    let secuencial = allCandidates
      .map(function (elem) {
        return elem.secuencial;
      })
      .join("-");
    let respCsv = await getCandidateAgroCsvList(secuencial, "life");
    //window.location.href = respCsv?.data;
    window.open(respCsv?.data, '_blank', 'noreferrer');
  }

  return (
    <Animate type='pop'>
      <div className={Style.buttonPosition}>
        <BackButton go={-1} />
      </div>
      <section className={Style.paddingLeft}>
        <Content>
          <div style={{ padding: "15px 0px" }}>
            <p className={Style.textTitle}>Calificación vida</p>
          </div>
          <div style={{ paddingBottom: 20 }}>
            <div className={Style.qualificationInputSection}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Nombre:</label>
                <input
                  className={Style["input"]}
                  value={dataSeach.nombre}
                  name='nombre'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Asesor:</label>
                <input
                  className={Style["input"]}
                  value={dataSeach.asesor}
                  name='asesor'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Grupo corporativo:</label>
                <input
                  className={Style["input"]}
                  value={dataSeach.channel}
                  name='channel'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Número de documento:</label>
                <input
                  className={Style["input"]}
                  value={dataSeach.id}
                  name='id'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Filtrar por fecha:</label>
                <DateRangePicker onChange={handleDate} value={dataSeach.date} />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Button color='blue' text='Buscar' action={handleSearchData} />
              </div>
            </div>
          </div>
          <div>
            <Scrollbars
              style={{
                width: "100%",
                height: "600px",
              }}
            >
              <Table
                id='table-Cotizaciones'
                show={[
                  "nombre",
                  "ci",
                  "fecha",
                  "producto",
                  "plan",
                  "estado",
                  "grupoCorporativo",
                  "asesor",
                  "totalCuota",
                  "modalidadPago",
                  "observaciones",
                  "calificar",
                ]}
                data={data}
                loading={Object.entries(data).length === 0 ? true : false}
              />
            </Scrollbars>
          </div>
          <div style={{ padding: "20px 0px", textAlign: "right" }}>
            {allCandidates.length > 0 ? (
              <Button
                color='blue'
                text='Descargar Tabla'
                action={generateCsvList}
              />
            ) : (
              <p></p>
            )}
          </div>
        </Content>
      </section>
    </Animate>
  );
}
