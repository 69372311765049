import React from 'react';
import Style from './text.module.scss';

const Text = ({ text, color, size, weigth, padding, align, fontFamily }) => {

  const style = {
    color: color || '#000',
    fontSize: size || '1em',
    fontWeight: weigth || 'normal',
    padding: padding || '0',
    textAlign: align || 'left'
  }

  return (
    <div style={{ ...style }} className={Style.fontImportant}>
      {text}
    </div>
  );
}

export { Text };