import React, { useContext, useEffect, useState } from "react";
import {
  Animate, Content, TitleProduct, ConfigCultivation, Button, ViewContext, useNavigate, useLocation, SalesForce
} from "components/lib";
import { getBranch, getBranchXProduct, Submit, getAgroProducts, getAgroCatalog } from "components/bridges/BridgeServices";
import { BackButton } from "components/backButton/button";
import Style from "../../../css/globalStyle.module.scss";
import { useDataContext } from "../../../context/DataContext";

export function Agriculture(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  const [rawProductsAgro, setRawProductsAgro] = useState([]);
  const navigate = useNavigate();
  const viewContext = useContext(ViewContext);
  const [agriculture, setAgriculture] = useState({
    cultivations: [],
    plansCultivation: [],
    cultivationSelected: "",
  });
  const [servicesData, setServicesData] = useState();
  let url = useLocation();
  let vecUrl = url.pathname.split("/");
  let id = "";
  let idProduct = '';
  if (vecUrl.length > 3) {
    id = decodeURI(vecUrl[3]);
  }
  if (vecUrl.length > 4) {
    idProduct = vecUrl[4];
  }

  const getGeneralData = async () => {
    try {
      let tempData = {};
      const respBranchXProductV2 = localStorage.getItem('branchXProductsAgro');
      let respBranchXProductJson;
      if (respBranchXProductV2) {
        respBranchXProductJson = JSON.parse(respBranchXProductV2)?.data
      }
      const resBranchXProduct = state?.branchXProductsAgro?.data ? state.branchXProductsAgro.data :
        respBranchXProductJson ? respBranchXProductJson : await getBranchXProduct({ ramo: "40", ahorro: "N" });
      tempData.branchProduct = resBranchXProduct;
      const responseBranchV2 = localStorage.getItem('branch');
      let responseBranchJson;
      if (responseBranchV2) {
        responseBranchJson = JSON.parse(responseBranchV2)?.data
      }
      const responseBranch = state?.branch?.data ? state.branch.data : responseBranchJson ? responseBranchJson : await getBranch({ ramo: "40" });
      tempData.branch = responseBranch;
      setServicesData(tempData);
    } catch (error) {
      console.error(error);
    }
  };

  const fillData = (productsAgro, dataCatalog) => {
    if (productsAgro?.data?.length > 0 && dataCatalog?.data?.length > 0) {
      //if (servicesData && dataCatalog.data && productsAgro.data) {
      let tempBranchProduct = [];
      let tempCultivation = [];
      dataCatalog.data.map((catalog) => {
        if (catalog.name === "cultivos") {
          tempCultivation = catalog.detail.detail;
        }
      });
      productsAgro.data.map((product) => {
        if (product.id === idProduct) {
          product?.detail?.cultivo.map((cultivo) => {
            if (cultivo.name === id) {
              setSalesForce({
                broker: cultivo?.fuerza_de_ventas?.broker ? cultivo.fuerza_de_ventas.broker : [],
                sponsor: cultivo?.fuerza_de_ventas?.sponsor ? cultivo.fuerza_de_ventas.sponsor : [],
                sdp: cultivo?.fuerza_de_ventas?.fv ? cultivo.fuerza_de_ventas.fv : [],
              });
            }
          })
        }
      });
      setAgriculture({
        ...agriculture,
        plansCultivation:
          productsAgro.data.length > 0
            ? productsAgro.data[0].detail.cultivo
            : [],
        cultivationPlan: tempBranchProduct,
        cultivations: tempCultivation,
        cultivationSelected:
          id !== "" ? id : tempCultivation[0].nombre_del_cultivo,
      });
    }
  };

  const handleSaveProduct = async () => {
    try {
      // Search for selected cultivo
      let obj = {
        type: "agro",
        detail: {
          product: servicesData?.branchProduct[0],
          cultivo: agriculture.plansCultivation
        }
      }
      if (rawProductsAgro.data.length === 0) {
        await Submit("product", obj, "post")
          .then((value) => {
            viewContext.notification.show(value.data.message, "success", true);
            navigate("/producto");
          })
          .catch((error) => {
            viewContext.handleError(error);
          })
          .finally(() => {
            viewContext.handleError("La Promesa está finalizada, lo que significa que ha sido resuelta o rechazada.");
          });
      } else {
        obj.id = rawProductsAgro.data[0].id;
        await Submit("product", obj, "patch")
          .then((value) => {
            viewContext.notification.show(value.data.message, "success", true);
            navigate("/producto");
          })
          .catch((error) => {
            viewContext.handleError(error);
          })
          .finally(() => {
            viewContext.handleError("La Promesa está finalizada, lo que significa que ha sido resuelta o rechazada.");
          });
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const rawDbProd = await getAgroProducts();
      setRawProductsAgro({ data: rawDbProd?.data });
      const rawDbCat = await getAgroCatalog();
      fillData({ data: rawDbProd?.data }, { data: rawDbCat?.data });
    }
    fetchData()
      .catch(console.error);
  }, [])

  useEffect(() => {
    if (id !== "") {
      setAgriculture({ ...agriculture, cultivationSelected: id });
    }
  }, [id]);

  useEffect(() => {
    getGeneralData();
  }, []);

  const [salesForce, setSalesForce] = useState({
    broker: [],
    sponsor: [],
    sdp: [],
  });

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Animate type='pop'>
      <div className={Style.buttonPosition}>
        <BackButton go={-1} />
      </div>
      <section className={Style.paddingLeft} style={{ width: dimensions.width / 2 }}>
        <Content>
          <section style={{ width: dimensions.width - dimensions.width / 8 }}>
            <TitleProduct blackTitle='Cultivo'>
              <ConfigCultivation
                agriculture={agriculture}
                setAgriculture={setAgriculture}
                servicesData={servicesData}
                setServicesData={setServicesData}
              />
            </TitleProduct>
          </section>

          {idProduct === '' ? <></> : <section>
            <TitleProduct blackTitle='Fuerza de ventas'>
              <SalesForce
                salesForce={salesForce}
                setSalesForce={setSalesForce}
              />
            </TitleProduct>
          </section>}

          <section>
            <div style={{ padding: "15px 0px", display: "flex" }}>
              <Button
                text={"Guardar"}
                action={handleSaveProduct}
                color='blue'
              />
            </div>
          </section>
        </Content>
      </section>
    </Animate>
  );
}
