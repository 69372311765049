import React, { useEffect, useState } from "react";
import { Animate, Grid } from "components/lib";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { Controller, useForm } from "react-hook-form";
import Style from "../../../../css/globalStyle.module.scss";
import Spin from "../../agriculture/agricultureSeller.module.scss";
import { colourStyles } from "../../../../css/globalStyles";
import "../../../../css/datePicker.css";

export function StepOne(props) {
  const {
    setValue,
    register,
    watch,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const {
    options,
    client,
    handleSelectChange,
    handleInputChange,
    handleDate,
    handleDatePass,
    handleIncomingDate,
    handleExpireDate,
    validateClient,
    isLoading,
  } = props;

  const personeria = watch("personeria");

  const [tipoDocumento, setTipoDocumento] = useState("");
  const [defaultSelectId, setDefaultSelectId] = useState([]);
  const [defaultSelectGenero, setDefaultSelectGenero] = useState([]);
  const [defaultEstadoMigratorio, setDefaultEstadoMigratorio] = useState([]);

  const onSubmit = (data) => {
    validateClient();
  };

  if (tipoDocumento?.value) {
    client.tipoId = tipoDocumento.value;
  }

  function verificarCedula(cedula) {
    if (
      typeof cedula === "string" &&
      cedula.length === 10 &&
      /^\d+$/.test(cedula)
    ) {
      let digitos = cedula.split("").map(Number);
      let codigo_provincia = digitos[0] * 10 + digitos[1];

      if (
        codigo_provincia >= 1 &&
        (codigo_provincia <= 24 || codigo_provincia === 30)
      ) {
        let digito_verificador = digitos.pop();
        let digito_calculado =
          digitos.reduce(function (valorPrevio, valorActual, indice) {
            return (
              valorPrevio -
              ((valorActual * (2 - (indice % 2))) % 9) -
              (valorActual === 9) * 9
            );
          }, 1000) % 10;
        return digito_calculado === digito_verificador;
      }
    }
    return false;
  }

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);
    return isValid;
  };

  const [filteredTipoId, setFilteredTipoId] = useState([]);
  const [filteredGenero, setFilteredGenero] = useState([]);
  const [filteredEstadoMigratorio, setFilteredEstadoMigratorio] = useState([]);

  const setPersoneriaOptions = (per) => {
    let tmpVec = [];
    let curVec = options.tipoId;
    tmpVec = curVec.filter((ele) => ele.value === "00" || ele.value === "01");
    setFilteredTipoId(tmpVec);
  };

  const setGeneroOptions = (per) => {
    let curVec = options.genero;
    setFilteredGenero(curVec);
  };

  const setEstadoMigratorioOption = (per) => {
    let curVec = options.estadoMigratorio;
    setFilteredEstadoMigratorio(curVec);
  };

  useEffect(() => {
    if (personeria) {
      handleSelectChange(personeria);
    }
  }, [personeria]);

  useEffect(() => {
    setPersoneriaOptions();
    setGeneroOptions();
    setEstadoMigratorioOption();
  }, [options]);

  useEffect(() => {
    let tempDefaulSelect = defaultSelectId;
    options.tipoId.map((item) => {
      if (item.value === client.tipoId) {
        tempDefaulSelect.shift();
        tempDefaulSelect.push(item);
        setValue("tipo_documento", item.value);
      }
    });
    setDefaultSelectId((prev) => tempDefaulSelect);
  }, [client.tipoId]);

  useEffect(() => {
    let tempDefaulSelectGenero = defaultSelectGenero;
    // tempDefaulSelect.genero = [];
    options.genero.map((item) => {
      if (item.value === client.genero) {
        tempDefaulSelectGenero.shift();
        tempDefaulSelectGenero.push(item);
        setValue("genero", item.value);
      }
    });
    setDefaultSelectGenero(tempDefaulSelectGenero);
  }, [client.genero]);

  useEffect(() => {
    let tempDefaulSelectMigratorio = defaultEstadoMigratorio;
    options.estadoMigratorio.map((item) => {
      if (item.value === client.genero) {
        tempDefaulSelectMigratorio.shift();
        tempDefaulSelectMigratorio.push(item);
        setValue("estadoMigratorio", item.value);
      }
    });
    setDefaultEstadoMigratorio(tempDefaulSelectMigratorio);
  }, [client.estadoMigratorio]);

  return (
    <Animate type='pop'>
      <form onSubmit={handleSubmit(onSubmit)}>
        {options.personeria && options.personeria.length ? (
          <section>
            <div>
              <section>
                <span style={{ display: "flex", justifyContent: "center" }}>
                  <h2 className={Style["title"]}>
                    Ingresar información del candidato
                  </h2>
                </span>
                <Grid cols='2'>
                  <div className={Style.containerInput}>
                    <label>Personería</label>
                    <Controller
                      control={control}
                      name='personeria'
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          placeholder='Seleccionar'
                          className='basic-single'
                          classNamePrefix='select'
                          inputRef={ref}
                          onChange={(choice) => {
                            onChange(choice.value);
                          }}
                          styles={colourStyles}
                          options={options?.personeria}
                          isSearchable
                          isLoading={!options}
                          menuPosition='fixed'
                          menuPlacement={"auto"}
                          defaultValue={{
                            value: "N",
                            label: "Natural",
                            type: "personeria",
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid>
                <Grid cols='2'>
                  <div className={Style.containerInput}>
                    <label>Tipo de documento</label>
                    <Controller
                      control={control}
                      name='tipo_documento'
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className='basic-single'
                          classNamePrefix='select'
                          noOptionsMessage={() => "No hay opciones"}
                          inputRef={ref}
                          onChange={(choice) => {
                            setTipoDocumento(choice);
                            onChange(choice.value);
                            client.numeroDocumento = "";
                          }}
                          styles={colourStyles}
                          options={filteredTipoId}
                          isSearchable
                          isLoading={!options}
                          menuPosition='fixed'
                          menuPlacement={"auto"}
                          defaultValue={defaultSelectId}
                          placeholder='Seleccionar'
                        />
                      )}
                    />
                    {errors.tipo_documento?.type == "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                  </div>
                  <div className={Style.containerInput}>
                    <label>Número de documento</label>
                    <input
                      {...register(
                        "numeroDocumento",
                        // { required: true },
                        {
                          validate: {
                            incorrectNumber: (v) => {
                              return verificarCedula(v);
                            },
                          },
                        }
                      )}
                      value={client.numeroDocumento}
                      name='numeroDocumento'
                      type='text'
                      onChange={handleInputChange}
                      onKeyPress={(event) => {
                        let regExpPattern =
                          client.tipoId === "00" || client.tipoId === "02"
                            ? /[0-9]/
                            : /[a-zA-Z0-9]/;

                        if (!regExpPattern.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={
                        client.tipoId === "00"
                          ? "10"
                          : client.tipoId === "02"
                            ? "13"
                            : "100"
                      }
                      className={Style["input_number"]}
                    />
                    {errors.numeroDocumento?.type == "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                    {errors.numeroDocumento?.type == "incorrectNumber" && (
                      <p style={{ color: "red" }}>
                        Número del documento incorrecto
                      </p>
                    )}
                  </div>
                </Grid>
                <Grid cols='3'>
                  <div className={Style.containerInput}>
                    <label>Primer nombre</label>
                    <input
                      onKeyPress={(event) => {
                        if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("primerNombre", { required: true })}
                      value={
                        client.primerNombre.charAt(0).toUpperCase() +
                        client.primerNombre.slice(1).toLowerCase()
                      }
                      name='primerNombre'
                      type='text'
                      onChange={handleInputChange}
                      className={Style["input"]}
                    />
                    {errors.primerNombre?.type == "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                  </div>
                  <div className={Style.containerInput}>
                    <label>Segundo nombre</label>
                    <input
                      onKeyPress={(event) => {
                        if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={
                        client.segundoNombre.charAt(0).toUpperCase() +
                        client.segundoNombre.slice(1).toLowerCase()
                      }
                      name='segundoNombre'
                      type='text'
                      onChange={handleInputChange}
                      className={Style["input"]}
                    />
                  </div>
                </Grid>
                <div className={Style.containerWithDateInput}>
                  <div className={Style.containerInput}>
                    <label>Apellido paterno</label>
                    <input
                      onKeyPress={(event) => {
                        if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      {...register("apellidoPaterno", { required: true })}
                      value={
                        client.apellidoPaterno.charAt(0).toUpperCase() +
                        client.apellidoPaterno.slice(1).toLowerCase()
                      }
                      name='apellidoPaterno'
                      type='text'
                      onChange={handleInputChange}
                      className={Style["input"]}
                    />
                    {errors.apellidoPaterno?.type == "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                  </div>
                  <div className={Style.containerInput}>
                    <label>Apellido materno</label>
                    <input
                      onKeyPress={(event) => {
                        if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={
                        client.apellidoMaterno.charAt(0).toUpperCase() +
                        client.apellidoMaterno.slice(1).toLowerCase()
                      }
                      name='apellidoMaterno'
                      type='text'
                      onChange={handleInputChange}
                      className={Style["input"]}
                    />
                  </div>
                  <div className={Style.containerInput}>
                    <label style={{ paddingRight: 15 }}>
                      Fecha de nacimiento
                    </label>
                    <DatePicker
                      {...register("fechaNacimiento", {
                        required: true,
                        value: client.fechaNacimientoSinFormato,
                      })}
                      name='fechaNacimiento'
                      onChange={(e) => {
                        handleDate(e);
                        setValue("fechaNacimiento", e);
                      }}
                      value={client.fechaNacimientoSinFormato}
                    />
                    {errors.fechaNacimiento?.type == "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                  </div>
                </div>
                <Grid cols='3'>
                  <div className={Style.containerInput}>
                    <label>Género</label>
                    <Controller
                      control={control}
                      name='genero'
                      rules={{ required: true }}
                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          className='basic-single'
                          classNamePrefix='select'
                          inputRef={ref}
                          onChange={(e) => {
                            handleSelectChange(e);
                            onChange(e.value);
                          }}
                          styles={colourStyles}
                          options={filteredGenero}
                          isSearchable
                          isLoading={!options}
                          menuPosition='fixed'
                          menuPlacement={"auto"}
                          defaultValue={defaultSelectGenero}
                          placeholder='Seleccionar'
                        />
                      )}
                    />
                    {errors.genero?.type == "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                  </div>
                  <div className={Style.containerInput}>
                    <label>Teléfono/Celular</label>
                    <input
                      {...register("telefono", { required: true })}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      value={client.telefono}
                      name='telefono'
                      type='text'
                      onChange={handleInputChange}
                      maxLength={"10"}
                      className={Style["input_number"]}
                    />
                    {errors.telefono?.type == "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                  </div>
                  <div className={Style.containerInput}>
                    <label>Correo</label>
                    <input
                      {...register("correo", {
                        required: true,
                        validate: handleEmailValidation,
                      })}
                      value={client.correo}
                      name='correo'
                      type='text'
                      onChange={handleInputChange}
                      className={Style["input"]}
                    // pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
                    />
                    {errors.correo?.type == "required" && (
                      <p style={{ color: "red" }}>Este campo es requerido</p>
                    )}
                    {errors.correo?.type == "validate" && (
                      <p style={{ color: "red" }}>Correo invalido</p>
                    )}
                  </div>
                </Grid>
              </section>
              {client.tipoId === "01" && (
                <section>
                  <span
                    style={{
                      display: "flex",
                      padding: "10px 0px",
                      fontWeight: 700,
                      color: "#0F265C",
                    }}
                  >
                    <h2>Ingresar pasaporte</h2>
                  </span>
                  <div className={Style.containerWithDateInput}>
                    <div className={Style.containerInput}>
                      <label>Estado migratorio</label>
                      <Controller
                        control={control}
                        name='estadoMigratorio'
                        rules={{ required: true }}
                        render={({ field: { onChange, value, name, ref } }) => (
                          <Select
                            className='basic-single'
                            classNamePrefix='select'
                            inputRef={ref}
                            onChange={(e) => {
                              handleSelectChange(e);
                              onChange(e.value);
                            }}
                            styles={colourStyles}
                            options={filteredEstadoMigratorio}
                            isSearchable
                            isLoading={!options}
                            menuPosition='fixed'
                            menuPlacement={"auto"}
                            defaultValue={defaultEstadoMigratorio}
                            placeholder='Seleccionar'
                          />
                        )}
                      />
                      {errors.estadoMigratorio?.type == "required" && (
                        <p style={{ color: "red" }}>Este campo es requerido</p>
                      )}
                    </div>
                    <div className={Style.containerInput}>
                      <label>Fecha de expedición pasaporte</label>
                      <DatePicker
                        {...register("expedicionPasaporte", {
                          required: true,
                          value: client.expedicionPasaporteSinFormato,
                        })}
                        name='fechaNacimiento'
                        onChange={(e) => {
                          handleDatePass(e);
                          setValue("expedicionPasaporte", e);
                        }}
                        value={client.expedicionPasaporteSinFormato}
                      />
                      {errors.expedicionPasaporte?.type == "required" && (
                        <p style={{ color: "red" }}>Este campo es requerido</p>
                      )}
                    </div>
                    <div className={Style.containerInput}>
                      <label>Fecha último ingreso al país</label>
                      <DatePicker
                        {...register("ingresoPais", {
                          required: true,
                          value: client.ingresoPaisSinFormato,
                        })}
                        name='ingresoPais'
                        onChange={(e) => {
                          handleIncomingDate(e);
                          setValue("ingresoPais", e);
                        }}
                        value={client.ingresoPaisSinFormato}
                      />
                      {errors.ingresoPais?.type == "required" && (
                        <p style={{ color: "red" }}>Este campo es requerido</p>
                      )}
                    </div>
                  </div>
                  <div style={{ paddingTop: 5 }}>
                    <div className={Style.containerInput}>
                      <label>Fecha caducidad pasaporte</label>
                      <DatePicker
                        {...register("caducidadPasaporte", {
                          required: true,
                          value: client.caducidadPasaporteSinFormato,
                        })}
                        name='fechaNacimiento'
                        onChange={(e) => {
                          handleExpireDate(e);
                          setValue("caducidadPasaporte", e);
                        }}
                        value={client.caducidadPasaporteSinFormato}
                      />
                      {errors.caducidadPasaporte?.type == "required" && (
                        <p style={{ color: "red" }}>Este campo es requerido</p>
                      )}
                    </div>
                  </div>
                </section>
              )}
              <section style={{ marginTop: 30 }}>
                <div>
                  {!isLoading ? (
                    <input
                      style={{
                        cursor: "pointer",
                        padding: "1em 3em",
                        backgroundColor: "#0F265C",
                        color: "white",
                        borderRadius: "0.25em",
                        fontSize: "1em",
                        fontWeight: 600,
                        textAlign: "center",
                        textDecoration: "none",
                      }}
                      type='submit'
                      value='Validar Cliente'
                      onClick={validateClient}
                    />
                  ) : (
                    <div className={Spin.ldsDualRing}></div>
                  )}
                </div>
              </section>
            </div>
          </section>
        ) : (
          <div className={Spin.ldsDualRingContainer}>
            <div className={Spin.ldsDualRing}></div>
          </div>
        )}
      </form>
    </Animate>
  );
}
