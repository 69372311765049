import React from "react";
import Axios from "axios";
import { Grommet } from "grommet";

// components
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PrivateRoute, AuthProvider } from "./auth";
import { View } from "components/lib";

// 404
import { NotFound } from "views/error/404";

// tailwind css
import "../css/output.css";
import { DataProvider } from "context/DataContext";
import { deepMerge } from "grommet/utils";
import { grommet } from "grommet/themes";

// import "../fonts/Raleway-VariableFont.ttf";
// import "../fonts/Roboto-Bold.ttf";
// import "../fonts/Roboto-Medium.ttf";
// import "../fonts/Roboto-Regular.ttf";
// import "../fonts/Roboto-Thin.ttf";
import "../css/font-style.css";

// settings
const Settings = require("settings.json");

const routes = [
  ...require("routes/setup").default,
  ...require("routes/account").default,
  ...require("routes/app").default,
  ...require("routes/auth").default,
  ...require("routes/website").default,
];

export default function App(props) {
  const user = JSON.parse(localStorage.getItem("user"));
  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  }

  const colors = {
    active: "#00369c",
    black: "#000000",
    brand: "#0F265C",
    focus: "#00168",
    placeholder: "#9FA0A7 ",
    selected: "#00369C",
    text: "#000000",
    icon: "#FFFFFF",
    white: "#FFFFFF",
    error: "#FF0000 ",
    success: "#00C745 ",
    warning: "#FD5C3F ",
    background: "#eaf7fb",
    ["dark-3"]: "#FFFFFF",
    ["accent-1"]: "#95D4E9",
  };

  const myTheme = deepMerge(grommet, {
    global: {
      // font: {
      //   family: "Raleway",
      // },
      colors,
      breakpoints: {
        small: {
          value: 768,
        },
        medium: {
          value: 1024,
        },
        large: 3000,
      },
    },
  });

  // render the routes
  return (
    <Grommet theme={myTheme} full>
      <AuthProvider>
        <BrowserRouter>
          <DataProvider>
            <Routes>
              {routes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={
                      route.permission ? (
                        <PrivateRoute permission={route.permission}>
                          <View
                            display={route.view}
                            layout={route.layout}
                            title={route.title}
                          />
                        </PrivateRoute>
                      ) : (
                        <View
                          display={route.view}
                          layout={route.layout}
                          title={route.title}
                        />
                      )
                    }
                  />
                );
              })}

              {/* 404 */}
              <Route
                path='*'
                element={
                  <View
                    display={NotFound}
                    layout='home'
                    title='404 Not Found'
                  />
                }
              />
            </Routes>
          </DataProvider>
        </BrowserRouter>
      </AuthProvider>
    </Grommet>
  );
}
