/***
 *
 *   LOGO
 *   Replace the image in /images with your own logo
 *
 **********/

import React from "react";
import { Link, ClassHelper } from "components/lib";
import LogoWhite from "./images/SDP-logo-blanco1.svg";
import LogoMarkWhite from "./images/SDP-logo-blancoV2.png";
import LogoColor from "./images/SDP-logo.svg";
import LogoMarkColor from "./images/logo-mark-color.svg";
import Style from "./logo.module.scss";

export function Logo(props) {
  const Logo = {
    color: {
      logo: LogoColor,
      mark: LogoMarkColor,
    },
    white: {
      logo: LogoWhite,
      mark: LogoMarkWhite,
    },
  };

  const logoStyle = ClassHelper(Style, {
    // noMargin: props.noMargin,
    [props.noMargin]: props.spaceItems,
    className: props.className,
  });

  return (
    <Link url='/' className={logoStyle}>
      <img
        src={
          Logo[props.color ? "color" : "white"][props.mark ? "mark" : "logo"]
        }
        alt='Logo'
      />
    </Link>
  );
}
