import { BackButton } from "components/backButton/button";
import {
  getActivity,
  getBranchXProduct,
  getCountry,
  getGender,
  getMaritalStatus,
  getNacionality,
  getPerson,
  getProvince,
  getTypeId,
  getTypePaid,
  postFormSave,
  Submit,
  getCitiesLocal
} from "components/bridges/BridgeServices";
import {
  Animate,
  Content,
  useAPI,
  useLocation,
  ViewContext,
} from "components/lib";
import { useContext, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SetViewCandidate } from "utils/getPermissions";
import { useDataContext } from "../../../../context/DataContext";
import GlobalStyle from "../../../../css/globalStyle.module.scss";
import Style from "../../agriculture/agricultureSeller.module.scss";
import { ActividadEconomica } from "./components/actividadEconomica";
import { Beneficiarios } from "./components/beneficiarios";
import { DatosPersonales } from "./components/datosPersonales";
import { DeclaracionPersonaPolitica } from "./components/declaracionPersonaPolitica";
import { EstiloVida } from "./components/estiloVida";
import { formCompleted } from "./components/formCreated";
import { HistoriaMedica } from "./components/historiaMedica";
import { InformacionConyuge } from "./components/informacionConyuge";
import { InformacionFinanciera } from "./components/informacionFinanciera";
import { InformacionSolicitante } from "./components/informacionSolicitante";
import { PlanSeguro } from "./components/planSeguro";
import { SegurosExistentes } from "./components/segurosExistentes";

const defaultSelectOption1 = {
  a: false,
  b: false,
  c: false,
  d: false,
  e: false,
  f: false,
  g: false,
  h: false,
  i: false,
  j: false,
};

export function FormRequestInsurance(props) {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const rawUser = JSON.parse(localStorage.getItem('profile'))
  const userPermissions = JSON.parse(localStorage.getItem("user_permissions"));
  let productSelected = localStorage.getItem("productSelected");
  const viewContext = useContext(ViewContext);
  const {
    watch,
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    formState,
    setValue,
    getValues,
    trigger
  } = useForm({ mode: "onChange" });
  const {
    fields: coberturasFields,
    append: coberturasAppend,
    remove: coberturasRemove,
  } = useFieldArray({ control, name: "coberturasAdicionales" });
  const {
    fields: beneficiariosPrincipalesFields,
    append: beneficiariosPrincipalesAppend,
    remove: beneficiariosPrincipalesRemove,
  } = useFieldArray({ control, name: "beneficiariosPrincipales" });
  const {
    fields: beneficiariosContigentesFields,
    append: beneficiariosContigentesAppend,
    remove: beneficiariosContigentesRemove,
  } = useFieldArray({ control, name: "beneficiariosContigentes" });
  const {
    fields: estiloVidaPregunta7DatosFields,
    append: estiloVidaPregunta7DatosAppend,
    remove: estiloVidaPregunta7DatosRemove,
  } = useFieldArray({ control, name: "estiloVidaPregunta7Datos" });
  const {
    fields: estiloVidaPregunta12DeporteFields,
    append: estiloVidaPregunta12DeporteAppend,
    remove: estiloVidaPregunta12DeporteRemove,
  } = useFieldArray({ control, name: "estiloVidaPregunta12Deporte" });
  const {
    fields: preguntaAdicional1NumeroFields,
    append: preguntaAdicional1NumeroAppend,
    remove: preguntaAdicional1NumeroRemove,
  } = useFieldArray({ control, name: "preguntaAdicional1Numero" });
  const [isLoading, setIsLoading] = useState(false);
  const [checkedQuestion, setCheckedQuestion] = useState(defaultSelectOption1);
  const [form, setForm] = useState({});
  const [options, setOptions] = useState({
    personeria: [],
    tipoId: [],
    genero: [],
    tipoPago: [],
    tipoPlan: [],
    planOptions: [],
    branchProduct: {},
    nacionalidades: [],
    provincias: [],
    ciudades: [],
    ciudadesActividad: [],
    cantones: [],
    estadoCivil: [],
    pais: [],
    actividadEconomica: [],
  });
  const [optionsCiudades, setOptionsCiudades] = useState([]);
  const [optionsCiudadesActividades, setOptionsCiudadesActividades] = useState();
  const [valuesEconomicos, setValuesEconomicos] = useState({
    actividadPrincipal: 0,
    otroIngreso: 0,
    ingreso: 0,
    egreso: 0,
    activos: 0,
    pasivos: 0,
    patrimonio: 0,
  });
  const [monto, setMonto] = useState({ montoAsegurado: 0, monto: 0 });
  const [detailQuestion, setDetailQuestion] = useState([]);
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();

  let url = useLocation();
  let vecUrl = url.pathname.split("/");
  let candidateId = vecUrl[vecUrl.length - 1];
  const formsFilled = useAPI(`/api/formByFilter?candidate_id=${candidateId}&product_name=product${(parseInt(productSelected) + 1)}`);
  let dataInfo = url.state.plan[1];
  let estadoCivil = watch("estadoCivil");
  let provincia = watch("provincia");
  let provinciaActividadEconomica = watch("provinciaActividadEconomica");
  let opcionesPreguntaPreliminar1 = watch("opcionesPreguntaPreliminar1");
  let peso = watch("pesoKg");
  let altura = watch("alturaCm");
  let preguntaPreliminar1Section3 = watch("preguntaPreliminar1");
  let genero = watch("genero");

  const reviewQuestions = (question, section, tempCheck) => {
    let tempOptions = [];
    tempOptions = opcionesPreguntaPreliminar1.filter((item) => {
      return item.section === `${section}`;
    });

    if (tempOptions.length > 0) {
      tempOptions.map((item) => {
        if (question.toLowerCase().includes(item.value.toLowerCase())) {
          tempCheck = { ...tempCheck, [`${section}`]: true };
          switch (section) {
            case "a":
              setValue("preguntaPreliminar5", "Si");
              break;
            case "b":
              setValue("preguntaPreliminar6", "Si");
              break;
            case "c":
              setValue("preguntaPreliminar7", "Si");
              break;
            case "d":
              setValue("preguntaPreliminar8", "Si");
              break;
            case "e":
              setValue("preguntaPreliminar9", "Si");
              break;
            case "g":
              setValue("preguntaPreliminar11", "Si");
              break;
            case "i":
              setValue("preguntaPreliminar13", "Si");
              break;
            case "j":
              setValue("preguntaPreliminar14", "Si");
              break;
            case "f":
              setValue("preguntaPreliminar10", "Si");
              break;
            case "h":
              setValue("preguntaPreliminar12", "Si");
              break;
            case "k":
              setValue("preguntaPreliminar15", "Si");
              break;
            default:
              break;
          }
        }
      });
    } else {
      tempCheck = { ...tempCheck, [`${section}`]: false };
      switch (section) {
        case "a":
          setValue("preguntaPreliminar5", "No");
          break;
        case "b":
          setValue("preguntaPreliminar6", "No");
          break;
        case "c":
          setValue("preguntaPreliminar7", "No");
          break;
        case "d":
          setValue("preguntaPreliminar8", "No");
          break;
        case "e":
          setValue("preguntaPreliminar9", "No");
          break;
        case "g":
          setValue("preguntaPreliminar11", "No");
          break;
        case "i":
          setValue("preguntaPreliminar13", "No");
          break;
        case "j":
          setValue("preguntaPreliminar14", "No");
          break;
        default:
          break;
      }
    }

    tempOptions = [];
    return tempCheck;
  };

  const isContainArray = () => {
    let tempCheck = { ...checkedQuestion };
    if (
      opcionesPreguntaPreliminar1 !== undefined &&
      opcionesPreguntaPreliminar1.length > 0
    ) {
      tempCheck = reviewQuestions(
        `Dolor en el pecho, hipertensión arterial, arritmia, soplo cardíaco,
        infarto al miocardio, anginia de pecho, aneurisma, fiebre reumática, enfermedades del corazón, enfermedades de los vasos sanguíneos`,
        "a",
        tempCheck
      );
      tempCheck = reviewQuestions(
        `Mareos, desmayos, epilepsia o convulsiones, jaquecas, alteración del habla, parálisis o apoplejía, derrame,
          accidente cerebrovascular, traumatismo craneal, trastorno nervioso mental`,
        "b",
        tempCheck
      );
      tempCheck = reviewQuestions(
        `Cáncer, tumor o pólipos, enfermedades de la piel. alergias,
          ganglios linfáticos o quistes`,
        "c",
        tempCheck
      );
      tempCheck = reviewQuestions(`VIH o Sida`, "d", tempCheck);
      tempCheck = reviewQuestions(
        `Anemia, leucemia, flebitis, tromboflebitis, trastornos de la
          sangre, vasculares o del bazo`,
        "e",
        tempCheck
      );
      tempCheck = reviewQuestions(
        `Ahogo, ronquera, tos persistente, bronquitis, pleuresía,
          asma, alergia, enfisema, tuberculosis, desorden respiratorio crónico`,
        "f",
        tempCheck
      );
      tempCheck = reviewQuestions(
        `Ictericia, cirrosis, hepatitis, hemorragia intestinal,
          apendicitis, úlcera, diverticulitis, hernia, colitis, hemorroides,
          esofagitis, dispepsia, trastornos digestivos del hígado, páncreas
          o vesícula biliar`,
        "g",
        tempCheck
      );
      tempCheck = reviewQuestions(
        `Azúcar, laminea, pus en la orina. infecciones urinarias, enfermedades venéreas, cálculos, quistes u otros desordenes de los riñones, vejiga, tracto urinario, próstata u órganos reproductivos`,
        "h",
        tempCheck
      );
      tempCheck = reviewQuestions(
        `Diabetes, hipoglucemia, desórdenes de la tiroides,
        trastornos del páncreas, trastornos glandulares`,
        "i",
        tempCheck
      );
      tempCheck = reviewQuestions(
        `Artritis, reumatismo, ciática, neuritis, gota, desórdenes de
        la columna vertebral, Desórdenes de músculos, Desórdenes de huesos, Desórdenes de articulaciones, Deformidad del colágeno, cojera, amputación`,
        "j",
        tempCheck
      );
      tempCheck = reviewQuestions(
        `Enfermedades de ojos, Enfermedades de oído, Enfermedades de garganta`,
        "k",
        tempCheck
      );
    } else {
      tempCheck = { ...defaultSelectOption1 };
    }
    setCheckedQuestion(tempCheck);
  };

  const getOptions = async () => {
    try {
      setIsLoading(true);
      let tempOptions = {
        ...options,
      };
      const respIdV2 = localStorage.getItem('typeid')
      let respIdJson;
      if (respIdV2) {
        respIdJson = JSON.parse(respIdV2)?.data
      }
      const respPersonV2 = localStorage.getItem('person')
      let respPersonJson;
      if (respPersonV2) {
        respPersonJson = JSON.parse(respPersonV2)?.data
      }
      const respGenderV2 = localStorage.getItem('gender')
      let respGenderJson;
      if (respGenderV2) {
        respGenderJson = JSON.parse(respGenderV2)?.data
      }
      const respTypePaidV2 = localStorage.getItem('typePaid')
      let respTypePaidJson;
      if (respTypePaidV2) {
        respTypePaidJson = JSON.parse(respTypePaidV2)?.data
      }
      const respNacionalityV2 = localStorage.getItem('nacionalities')
      let respNacionalityJson;
      if (respNacionalityV2) {
        respNacionalityJson = JSON.parse(respNacionalityV2)?.data
      }

      const respProvincesV2 = localStorage.getItem('provinces')
      let respProvincesJson;
      if (respProvincesV2) {
        respProvincesJson = JSON.parse(respProvincesV2)?.data
      }
      const respMaritalStatusV2 = localStorage.getItem('maritalStatus')
      let respMaritalStatusJson;
      if (respMaritalStatusV2) {
        respMaritalStatusJson = JSON.parse(respMaritalStatusV2)?.data
      }
      const respCountryV2 = localStorage.getItem('countries')
      let respCountryJson;
      if (respCountryV2) {
        respCountryJson = JSON.parse(respCountryV2)?.data
      }
      const respActivityV2 = localStorage.getItem('activities')
      let respActivityJson;
      if (respActivityV2) {
        respActivityJson = JSON.parse(respActivityV2)?.data
      }

      const respId = state?.typeid?.data ? state.typeid.data : respIdJson ? respIdJson : await getTypeId();
      const respPerson = state?.person?.data ? state.person.data : respPersonJson ? respPersonJson : await getPerson();
      const respGender = state?.gender?.data ? state.gender.data : respGenderJson ? respGenderJson : await getGender();
      const respTypePaid = state?.typePaid?.data ? state.typePaid.data : respTypePaidJson ? respTypePaidJson : await getTypePaid();
      const respNacionality = state?.nacionalities?.data ? state.nacionalities.data : respNacionalityJson ? respNacionalityJson : await getNacionality();
      const respProvinces = state?.provinces?.data ? state.provinces.data : respProvincesJson ? respProvincesJson : await getProvince();
      const respMaritalStatus = state?.maritalStatus?.data ? state.maritalStatus.data : respMaritalStatusJson ? respMaritalStatusJson : await getMaritalStatus();
      const respCountry = state?.countries?.data ? state.countries.data : respCountryJson ? respCountryJson : await getCountry();
      const respActivity = state?.activities?.data ? state.activities.data : respActivityJson ? respActivityJson : await getActivity();

      respPerson.map((item) => {
        tempOptions.personeria.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "tipoPersonaSolicitanteDiferenteAsegurado",
        });
      });
      tempOptions.tipoId.push({
        value: "",
        label: "Seleccionar",
      });
      respId.map((item) => {
        tempOptions.tipoId.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "tipoId",
        });
      });
      respGender.map((item) => {
        tempOptions.genero.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "genero",
        });
      });
      tempOptions.tipoPago.push({
        value: "",
        label: "Seleccionar",
      });
      respTypePaid.map((item) => {
        tempOptions.tipoPago.push({
          value: item.astr_id,
          label: item.astr_descripcion,
        });
      });
      respNacionality.map((item) => {
        tempOptions.nacionalidades.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "nacionalidad",
        });
      });
      respCountry.map((item) => {
        tempOptions.pais.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "nacionalidad",
        });
      });
      respProvinces.map((item) => {
        tempOptions.provincias.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "provincia",
        });
      });
      respMaritalStatus.map((item) => {
        tempOptions.estadoCivil.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "estadoCivil",
        });
      });
      if (tempOptions.estadoCivil.length > 0) {
        if (tempOptions.estadoCivil[0].label && tempOptions.estadoCivil[0].label !== "") {
          setValue('estadoCivil', tempOptions.estadoCivil[0].label);
        }
      }
      respActivity.map((item) => {
        tempOptions.actividadEconomica.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "actividadEconomica",
        });
      });
      let obj = {
        ramo: "20",
        ahorro: "S",
      };
      const respBranchXProductV2 = localStorage.getItem('branchXProducts')
      let respBranchXProductJson;
      if (respBranchXProductV2) {
        respBranchXProductJson = JSON.parse(respBranchXProductV2)?.data
      }
      const respBranchXProduct = state?.branchXProducts?.data ? state.branchXProducts.data :
        respBranchXProductJson ? respBranchXProductJson : await getBranchXProduct(obj);
      tempOptions.branchProduct = respBranchXProduct;
      setOptions(tempOptions);
      setIsLoading(false);
    } catch (error) {
      viewContext.handleError(error);
      setIsLoading(false);
    }
  };

  const getCitiesOptions = async () => {
    try {
      const respProvincesV2 = localStorage.getItem('provinces')
      let respProvincesJson;
      if (respProvincesV2) {
        respProvincesJson = JSON.parse(respProvincesV2)?.data
      }
      const respCiudadesV2 = localStorage.getItem('ciudades')
      let respCiudadesJson;
      if (respCiudadesV2) {
        respCiudadesJson = JSON.parse(respCiudadesV2)?.data
      }
      const respProvinces = state?.provinces?.data ? state.provinces.data : respProvincesJson ? respProvincesJson : await getProvince();
      const foundedProv = respProvinces.find(ele => ele.astr_descripcion === provincia);
      const respCiudades = state?.ciudades ? state.ciudades : respCiudadesJson ? respCiudadesJson : await getCitiesLocal();
      let tmpResp = getValueByKey(respCiudades?.data ? respCiudades.data : respCiudades, foundedProv?.astr_id);
      let tempCities = [];
      tmpResp.map((item) => {
        tempCities.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          astr_id: item.astr_id,
          astr_descripcion: item.astr_descripcion,
          astr_padre: item.astr_padre,
          type: "canton",
        });
      });
      setOptionsCiudades(tempCities);
    } catch (error) {
      console.log(error)
    }
  };

  const getCitiesActivityOptions = async () => {
    try {
      const respProvincesV2 = localStorage.getItem('provinces')
      let respProvincesJson;
      if (respProvincesV2) {
        respProvincesJson = JSON.parse(respProvincesV2)?.data
      }
      const respCiudadesV2 = localStorage.getItem('ciudades')
      let respCiudadesJson;
      if (respCiudadesV2) {
        respCiudadesJson = JSON.parse(respCiudadesV2)?.data
      }
      const respProvinces = state?.provinces?.data ? state.provinces.data : respProvincesJson ? respProvincesJson : await getProvince();
      const foundedProv = respProvinces.find(ele => ele.astr_descripcion === provinciaActividadEconomica);
      const respCiudades = state?.ciudades ? state.ciudades : respCiudadesJson ? respCiudadesJson : await getCitiesLocal();
      let tmpResp = getValueByKey(respCiudades?.data ? respCiudades.data : respCiudades, foundedProv?.astr_id);
      let tempCities = [];
      tmpResp.map((item) => {
        tempCities.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          astr_id: item.astr_id,
          astr_descripcion: item.astr_descripcion,
          astr_padre: item.astr_padre,
          type: "canton",
        });
      });
      setOptionsCiudadesActividades(tempCities);
    } catch (error) { }
  };

  const onSubmit = async (data) => {
    // formCompleted
    let indexForm = Object.keys(data);
    let idForm = "";

    Object.keys(formCompleted).map((section) => {
      formCompleted[section].map((question) => {
        indexForm.map((item) => {
          if (question.propertyApp === item) {
            question.value = data[item];
          }
        });
      });
    });

    let existForm = false;
    if (formsFilled?.data) {
      formsFilled.data.map((item) => {
        if (item.type === "solicitud_seguro") {
          existForm = true;
          idForm = item.id;
        }
      });
    }

    data["estado"] = "Completo";
    data["detailQuestion"] = detailQuestion;
    try {
      let req = {
        type: "solicitud_seguro",
        detail: data,
        // id: candidateId,
        product_name: `product${(parseInt(productSelected) + 1).toString()}`,
      };
      const coverturasAdicionales = req.detail.coberturasAdicionales;
      req.detail.coberturasAdicionales = coverturasAdicionales.filter(obj => obj !== null);
      if (existForm) {
        req["id"] = idForm;
        await Submit("form", req, "patch").then((resp) => {
          viewContext.notification.show("Formulario guardado", "success", true);
          // idForm = resp.data[0].id;
        });
      } else {
        req["candidate_id"] = candidateId;
        await postFormSave(req).then((resp) => {
          viewContext.notification.show("Formulario guardado", "success", true);
          idForm = resp.data[0].id;
        });
      }
      let reqUpdatePrice = {
        id: candidateId,
        estado: "Pendiente de documentos",
      };
      await Submit("candidate/updateStep", reqUpdatePrice, "patch").then(
        (resp) => {
          setIsSubmitting(false);
          navigate(-1);
        }
      );
    } catch (error) {
      viewContext.notification.show(error, "error", true);
      setIsSubmitting(false);
      console.error(error);
    }
  };

  const getValuesForm = () => {
    try {
      let tempValuesEconomicos = valuesEconomicos;
      let tempMonto = monto;

      if (!formsFilled.loading && formsFilled.data && options) {
        formsFilled.data.forEach((item) => {
          if (item.type === "solicitud_seguro") {
            Object.entries(item.detail).forEach(([question, value]) => {
              switch (question) {
                case "ingresoMensualActividadEconomica":
                  tempValuesEconomicos.actividadPrincipal = parseFloat(value.toString().replace(/[$, ]/g, ''));;
                  break;
                case "otroIngresoDiferenteActividadEconomica":
                  tempValuesEconomicos.otroIngreso = parseFloat(value.toString().replace(/[$, ]/g, ''));;
                  break;
                case "totalIngresoMensuales":
                  tempValuesEconomicos.ingreso = parseFloat(value.toString().replace(/[$, ]/g, ''));;
                  break;
                case "totalEgresosMensuales":
                  tempValuesEconomicos.egreso = parseFloat(value.toString().replace(/[$, ]/g, ''));;
                  break;
                case "totalActivos":
                  tempValuesEconomicos.activos = parseFloat(value.toString().replace(/[$, ]/g, ''));;
                  break;
                case "totalPasivos":
                  tempValuesEconomicos.pasivos = parseFloat(value.toString().replace(/[$, ]/g, ''));;
                  break;
                case "patrimonio":
                  tempValuesEconomicos.patrimonio = parseFloat(value.toString().replace(/[$, ]/g, ''));;
                  break;
                case "monto":
                  tempMonto.monto = parseFloat(value.toString().replace(/[$, ]/g, ''));;
                  break;
                case "montoAsegurado":
                  tempMonto.montoAsegurado = parseFloat(value.toString().replace(/[$, ]/g, ''));;
                  break;
                default:
                  if (question.includes("fechaTratamiento")) {
                    setValue(question, value);
                  } else {
                    setValue(question, value);
                  }
              }
              setValue(question, value);
            });
          }
        });
        setValuesEconomicos(tempValuesEconomicos);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getFormIncomplete = async () => {
    try {
      setIsSubmitting(true);
      let data = getValues();
      data.coberturasAdicionales = data.coberturasAdicionales.filter(item => item !== null);
      let idForm = "";
      let existForm = false;
      data["estado"] = "Incompleto";
      formsFilled.data.map((item) => {
        if (item.type === "solicitud_seguro") {
          existForm = true;
          idForm = item.id;
        }
      });
      let req = {
        type: "solicitud_seguro",
        detail: data,
        product_name: `product${(parseInt(productSelected) + 1).toString()}`,
      };
      if (existForm) {
        req["id"] = idForm;
        await Submit("form", req, "patch").then((resp) => {
          viewContext.notification.show("Formulario guardado", "success", true);
          navigate(-1);
        });
      } else {
        req["candidate_id"] = candidateId;
        await postFormSave(req).then((resp) => {
          viewContext.notification.show("Formulario guardado", "success", true);
          setIsSubmitting(false);
          navigate(-1);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const finalizeAndSave = async () => {
    const result = await trigger();
    if (result) {
      setIsSubmitting(true);
      const data = getValues();
      data.coberturasAdicionales = data.coberturasAdicionales.filter(item => item !== null || item.coberturaAdicional !== undefined);
      await onSubmit(data);
    } else {
      onError(formState?.errors);
    }
  }

  useEffect(() => {
    getValuesForm();
  }, [formsFilled, options]);

  useEffect(() => {
    isContainArray();
  }, [opcionesPreguntaPreliminar1]);

  useEffect(() => {
    if (provincia !== "" && provincia !== undefined) {
      getCitiesOptions();
    }
  }, [provincia]);

  useEffect(() => {
    if (provinciaActividadEconomica !== "" && provinciaActividadEconomica !== undefined) {
      getCitiesActivityOptions();
    }
  }, [provinciaActividadEconomica]);

  useEffect(() => {
    getOptions();
  }, []);

  const getLowestTabIndexError = (errors) => {
    let lowestTabIndex = Infinity;
    let lowestTabIndexElement = null;

    Object.values(errors).forEach(error => {
      const errorElement = error.ref;

      if (errorElement) {
        const tabIndex = parseInt(errorElement.getAttribute('tabIndex'), 10);

        if (!isNaN(tabIndex) && tabIndex < lowestTabIndex) {
          lowestTabIndex = tabIndex;
          lowestTabIndexElement = errorElement;
        }
      }
    });

    return lowestTabIndexElement;
  };

  const onError = (errors) => {
    const lowestTabIndexElement = getLowestTabIndexError(errors);
    if (lowestTabIndexElement) {
      lowestTabIndexElement.scrollIntoView({ behavior: "smooth" });
      lowestTabIndexElement.focus();
    } else {
      Object.keys(errors).map((item) => {
        if (item === "beneficiariosPrincipales") {
          const lowestTabIndexElement = getLowestTabIndexError(errors.beneficiariosPrincipales[0]);
          if (lowestTabIndexElement) {
            lowestTabIndexElement.scrollIntoView({ behavior: "smooth" });
            lowestTabIndexElement.focus();
          }
        }
      });
    }
  };

  function getValueByKey(obj, key) {
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    } else {
      return undefined;
    }
  }

  return (
    <Animate type='pop'>
      <div className={GlobalStyle.buttonPosition}>
        <BackButton go={-1} />
      </div>
      <section className={GlobalStyle.form}>
        <Content>
          {!isSubmitting ? (
            <>
              <div style={{ padding: "15px 0px" }}>
                <p className={GlobalStyle.textTitle}>
                  Formulario de solicitud de seguro
                </p>
              </div>
              {!isLoading ? (
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                  <InformacionSolicitante
                    errors={errors}
                    register={register}
                    watch={watch}
                    options={options}
                    form={form}
                    setForm={setForm}
                    setValue={setValue}
                  />
                  <PlanSeguro
                    dataInfo={dataInfo}
                    errors={errors}
                    register={register}
                    watch={watch}
                    options={options}
                    coberturasFields={coberturasFields}
                    coberturasRemove={coberturasRemove}
                    coberturasAppend={coberturasAppend}
                    form={form}
                    setForm={setForm}
                  />
                  <DatosPersonales
                    register={register}
                    options={options}
                    watch={watch}
                    dataInfo={dataInfo}
                    errors={errors}
                    control={control}
                    setValue={setValue}
                    optionsCiudades={optionsCiudades}
                    prevData={getValues()}
                  />
                  {(estadoCivil === "CASADO/A" || estadoCivil === "UNION DE HECHO") && (
                    <InformacionConyuge
                      register={register}
                      errors={errors}
                      form={form}
                      setForm={setForm}
                    />
                  )}
                  <ActividadEconomica
                    errors={errors}
                    register={register}
                    options={options}
                    watch={watch}
                    provinciaActividadEconomica={provinciaActividadEconomica}
                    form={form}
                    setForm={setForm}
                    optionsCiudadesActividades={optionsCiudadesActividades}
                    setValue={setValue}
                    prevData={getValues()}
                  />
                  <InformacionFinanciera
                    errors={errors}
                    register={register}
                    watch={watch}
                    form={form}
                    setForm={setForm}
                    valuesEconomicos={valuesEconomicos}
                    setValuesEconomicos={setValuesEconomicos}
                    setValue={setValue}
                  />
                  <Beneficiarios
                    errors={errors}
                    register={register}
                    beneficiariosPrincipalesFields={beneficiariosPrincipalesFields}
                    beneficiariosPrincipalesAppend={beneficiariosPrincipalesAppend}
                    beneficiariosPrincipalesRemove={beneficiariosPrincipalesRemove}
                    beneficiariosContigentesFields={beneficiariosContigentesFields}
                    beneficiariosContigentesAppend={beneficiariosContigentesAppend}
                    beneficiariosContigentesRemove={beneficiariosContigentesRemove}
                    form={form}
                    setForm={setForm}
                    watch={watch}
                    setValue={setValue}
                  />
                  <SegurosExistentes
                    errors={errors}
                    register={register}
                    watch={watch}
                    form={form}
                    setForm={setForm}
                    monto={monto}
                    setMonto={setMonto}
                  />
                  <HistoriaMedica
                    errors={errors}
                    register={register}
                    watch={watch}
                    dataInfo={dataInfo}
                    peso={peso}
                    altura={altura}
                    preguntaPreliminar1Section3={preguntaPreliminar1Section3}
                    form={form}
                    setForm={setForm}
                    opcionesPreguntaPreliminar1={opcionesPreguntaPreliminar1}
                    checkedQuestion={checkedQuestion}
                    genero={genero}
                    control={control}
                    detailQuestion={detailQuestion}
                    setDetailQuestion={setDetailQuestion}
                  />
                  <EstiloVida
                    errors={errors}
                    register={register}
                    watch={watch}
                    estiloVidaPregunta7DatosFields={estiloVidaPregunta7DatosFields}
                    estiloVidaPregunta7DatosAppend={estiloVidaPregunta7DatosAppend}
                    estiloVidaPregunta7DatosRemove={estiloVidaPregunta7DatosRemove}
                    estiloVidaPregunta12DeporteFields={estiloVidaPregunta12DeporteFields}
                    estiloVidaPregunta12DeporteAppend={estiloVidaPregunta12DeporteAppend}
                    estiloVidaPregunta12DeporteRemove={estiloVidaPregunta12DeporteRemove}
                    preguntaAdicional1NumeroFields={preguntaAdicional1NumeroFields}
                    preguntaAdicional1NumeroAppend={preguntaAdicional1NumeroAppend}
                    preguntaAdicional1NumeroRemove={preguntaAdicional1NumeroRemove}
                    form={form}
                    setForm={setForm}
                  />
                  <DeclaracionPersonaPolitica
                    errors={errors}
                    register={register}
                    watch={watch}
                    form={form}
                    setForm={setForm}
                    control={control}
                    setValue={setValue}
                    prevData={getValues()}
                  />
                  {SetViewCandidate(
                    rawUser,
                    userPermissions,
                    ["u"],
                    "cotizaciones"
                  ) && (
                      <div className={GlobalStyle.containerButtons}>
                        {!formState.isValid && (
                          <button
                            type="button"
                            className={GlobalStyle.buttonPendiente}
                            onClick={getFormIncomplete}
                          >
                            Pendiente
                          </button>
                        )}
                        <button
                          type="button"
                          className={GlobalStyle.buttonFinalizar}
                          onClick={finalizeAndSave}
                        >Finalizar y guardar
                        </button>
                      </div>
                    )}
                </form>
              ) : (
                <div className={Style.ldsDualRing}></div>
              )}
            </>
          ) : (
            <div className={Style.ldsDualRing}></div>
          )}
        </Content>
      </section>
    </Animate>
  );
}
