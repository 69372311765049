/***
 *
 *   BUTTON
 *   Can be a standard button, icon button or with loading animation
 *
 *   PROPS
 *   text: button label
 *   action: callback function executed on click
 *   params: object passed to the callback function (optional)
 *   color: red/blue (default: green)
 *   icon: icon image (optional)
 *   iconPack: icon pack to use
 *   iconSize: icon size
 *   iconButton: true or false
 *   alignIcon: left or right
 *   small: render a smaller button
 *   textOnly: text only
 *   outline: outline button
 *   rounded: round the corners
 *   className: pass a custom class object
 *   fullWidth: extend to full width of parent container
 *   loading: boolean to toggle loading animation (optional)
 *
 **********/

import React, { Fragment } from "react";
import { useNavigate } from "components/lib";

import FeatherIcon from "feather-icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Style from "./button.module.scss";
import useViewPort from "components/hooks/useViewPort";

const packs = {
  fontawesome: FontAwesomeIcon,
  feather: FeatherIcon,
};

const Icon = packs["feather"];

export function BackButton(props) {
  const navigate = useNavigate();
  const [screenSize] = useViewPort();

  return (
    <div className={Style.inRow}>
      <div
        onClick={() => {
          if (props.go) navigate(props.go);
          if (props.action) props.action();
        }}
        className={Style.background}
      >
        <Icon icon={"arrow-left"} className={Style.arrow} size={25} />
      </div>
      <button
        className={Style.backButton}
        onClick={() => {
          if (props.go) navigate(props.go);
          if (props.action) props.action();
        }}
      >
        {screenSize.dynamicWidth > 768 ? `Regresar` : ``}
      </button>
    </div>
  );
}
