/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React from "react";
import { MenuCard, Animate, useAPI } from "components/lib";
import { useState } from "react";
import { Box, Grid as GridGroomet, ResponsiveContext } from "grommet";
import productoAgricola from "components/logo/images/menu/productoAgricola.svg";
import ConfigProduct from "components/logo/images/menu/cconfiguracionProductos.png";
import productoCreado from "components/logo/images/menu/productosCreados.svg";
import config from "../../assets/icons/configuracion-agricola.svg";
import catalogo from "../../assets/icons/catalogo-agricola.svg";
import { useContext } from "react";
import Style from "./product.module.scss";
import GlobalStyle from "../../css/globalStyle.module.scss";
import { BackButton } from "components/backButton/button";
import { SetViewCandidateOther } from "utils/getPermissionsOther";
import useViewPort from "../../components/hooks/useViewPort";

// columns, rows and areas are for Grid with a known number of contents / boxes.

// if size if small, we only 1 column
// if size if medium, we only 2 column
// if size if large or xlarge, we 3 three columns
const columns = {
  small: ["auto"],
  medium: ["auto", "auto"],
  large: ["auto", "auto"],
  xlarge: ["auto", "auto"],
};

// if size if small, we have 3 rows
// if size if medium, we have 2 rows
// if size if large or xlarge, we have 1 row
const rows = {
  small: ["auto", "auto", "auto"],
  medium: ["auto", "auto"],
  large: ["auto"],
  xlarge: ["auto"],
};

// set the different areas you need for every size
const areas = {
  small: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [0, 1], end: [0, 1] },
    { name: "side3", start: [0, 2], end: [0, 2] },
  ],
  medium: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
  large: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [2, 0], end: [2, 0] },
  ],
  xlarge: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [2, 0], end: [2, 0] },
  ],
};

// set the different areas you need for every size

const ResponsiveGrid = ({
  children,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {(size) => {
      // take into consideration if not array is sent but a simple string
      let columnsVal = columns;
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size];
        }
      }

      let rowsVal = rows;
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size];
        }
      }

      // also if areas is a simple array not an object of arrays for different sizes
      let areasVal = areas;
      if (areas && !Array.isArray(areas)) areasVal = areas[size];

      return (
        <GridGroomet
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
          justify='center'
        >
          {children}
        </GridGroomet>
      );
    }}
  </ResponsiveContext.Consumer>
);

export function Product(props) {
  const [step, setStep] = useState(0);
  const size = useContext(ResponsiveContext);
  const rawUser = JSON.parse(localStorage.getItem('profile'))
  const userPermissions = JSON.parse(localStorage.getItem("user_permissions"));
  const [screenSize] = useViewPort();

  return (
    <Animate type='pop'>
      {step === 1 && (
        <div className={GlobalStyle.buttonPosition}>
          <BackButton action={() => setStep(0)} />
        </div>
      )}
      <div className={GlobalStyle.mainContainer}>
        <div className={GlobalStyle.section}>
          <p className={GlobalStyle.textTitle}>Producto</p>
        </div>
        <Box
          height={size.indexOf("small") > -1 ? undefined : "75vh"}
          justify='center'
          align='center'
        >
          {step === 0 && (
            <ResponsiveGrid
              rows={rows}
              columns={columns}
              gap='small'
              areas={areas}
              margin='small'
            >
              {SetViewCandidateOther(
                rawUser,
                userPermissions,
                ["c", "r", "u", "d"],
                "confprod"
              ) && (
                  <Box gridArea='side1' className={Style.boxContainerProduct}>
                    <MenuCard
                      name='revenues'
                      title='Configuración producto vida'
                      goto='/producto/vida'
                      icon={ConfigProduct}
                    />
                  </Box>
                )}
              {SetViewCandidateOther(
                rawUser,
                userPermissions,
                ["c", "r", "u", "d"],
                "confprod"
              ) && (
                  <Box gridArea='side2' className={Style.boxContainerProduct}>
                    <MenuCard
                      name='revenues'
                      title='Configuración producto agrícola'
                      onClick={() => setStep(1)}
                      icon={productoAgricola}
                    />
                  </Box>
                )}
              <Box gridArea='side3' className={Style.boxContainerProduct}>
                <MenuCard
                  name='revenues'
                  title='Productos creados'
                  goto='/productos'
                  icon={productoCreado}
                />
              </Box>
            </ResponsiveGrid>
          )}
          {step === 1 && (
            <>
              <GridGroomet
                height={size.indexOf("small") > -1 ? "60vh" : "60vh"}
                areas={[
                  { name: "main", start: [0, 0], end: [0, 0] },
                  { name: "side", start: [1, 0], end: [1, 0] },
                ]}
                rows={["small"]}
                columns={["flex", "flex"]}
                gap='medium'
                alignContent='center'
                style={{ gridTemplateRows: "350px", display: screenSize.dynamicWidth > 1024 ? undefined : "contents" }}
              >
                <Box gridArea='main' align='end' className={Style.boxContainer}>
                  <MenuCard
                    center
                    name='revenues'
                    title='Catálogos agrícola'
                    goto='/producto/agricola/config'
                    icon={catalogo}
                  />
                </Box>
                <Box
                  gridArea='side'
                  align='start'
                  className={Style.boxContainerLast}
                >
                  <MenuCard
                    center
                    name='revenues'
                    title='Configuración agrícola'
                    goto='/producto/agricola'
                    icon={config}
                  />
                </Box>
              </GridGroomet>
            </>
          )}
        </Box>
        <div className={Style.blockMenu}></div>
      </div>
    </Animate>
  );
}
