/***
 *
 *   PASSWORD
 *   Update the users password
 *
 *   if the user has a password show old and new inputs
 *   if no existing password (eg. in case of social signin)
 *   then allow the user to set a password on their account
 *
 **********/

import React, { Fragment, useContext, useState } from "react";
import {
  AuthContext,
  AccountNav,
  Animate,
  Card,
  Form,
  Message,
} from "components/lib";
import Style from "./salesForce.module.scss";
import GlobalStyle from "../../css/globalStyle.module.scss";
import AuthenticationIcon from "../../assets/icons/factorAutenticacion.svg";
import Img from "../../assets/img/fotoFormulario.png";
import { BackButton } from "components/backButton/button";

export function Password(props) {
  const context = useContext(AuthContext);
  const [done, setDone] = useState(false);

  return (
    <Fragment>
      {/* <AccountNav /> */}
      <Animate>
        <div className={GlobalStyle.buttonPosition}>
          <BackButton go={"/account"} />
        </div>
        <div className={Style.section}>
          <p className={Style.textTitle}>Cambiar contraseña</p>
          <div className={Style.mainContainer}>
            <div className={Style.centerCard}>
              <div className={Style.centerCardInside}>
                <div className={Style.centerCardInsideLeft}>
                  <img
                    className={Style.imgRounded}
                    alt='logo perfil'
                    src={Img}
                  />
                </div>
                <div className={Style.centerCardInsideRight}>
                  <img
                    className={Style.imgPadding}
                    alt='logo perfil'
                    src={AuthenticationIcon}
                  />
                  <Card restrictWidth className={props.className}>
                    <h2 className={Style.subtitle}>Actualizar contraseña</h2>
                    <hr className={Style.divider} />
                    {!done ? (
                      <Form
                        url='/api/user/password'
                        method='PUT'
                        buttonText='Guardar'
                        data={{
                          ...(context.user.has_password && {
                            oldpassword: {
                              label: "Antigua contraseña",
                              type: "password",
                              required: true,
                            },
                            has_password: {
                              type: "hidden",
                              value: true,
                            },
                          }),
                          newpassword: {
                            label: "Nueva contraseña",
                            type: "password",
                            required: true,
                            complexPassword: true,
                          },
                        }}
                        callback={() => {
                          setDone(true);
                          context.update({ has_password: true });
                        }}
                      />
                    ) : (
                      <Message
                        title='Contraseña Guardada'
                        type='success'
                        text='Se ha grabado su nueva contraseña exitosamente.'
                      />
                    )}
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Animate>
    </Fragment>
  );
}
