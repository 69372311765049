/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   noPadding: remove the padding
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/

import React from "react";
import { Heading, TextInput, Box } from "grommet";

export function InputProduct(props) {

  const handleOnChange = (e) => {
    const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
    if (e.target.value === "" || alphanumericExtendedRegex.test(e.target.value)) {
      props.onChange(e);
    }
  }

  return (
    <Box>
      {props.label ? (
        <Box
          justify='center'
          align='center'
          direction={"column"}
          height='100%'
          width={"100%"}
        >
          <Box
            height='100%'
            width={"100%"}
            pad='small'
            // background='red'
          >
            <Heading margin='none' style={{ fontWeight: 600, color: "#0F265C" }} level={4}>
              {props.label}
            </Heading>
          </Box>
          <Box
            height='100%'
            width={"100%"}
            pad='small'
            // background='black'
          >
            <TextInput
              style={{
                backgroundColor: "#F7F7F8",
                padding: "5px 8px",
                minHeight: "53px",
                boxShadow: "inset 0px 3px 3px #00000007",
                borderRadius: "5px",
                border: 0,
                opacity: 1,
                textTransform: "uppercase",
              }}
              // placeholder='type here'
              // value={value}
              // onChange={(event) => setValue(event.target.value)}
              key={props.key}
              disabled={props.disabled ? true : false}
              // className={Style.input}
              // type='text'
              id={props.name}
              name={props.name}
              value={props.value}
              onChange={handleOnChange}
            />
          </Box>
        </Box>
      ) : (
        <Box justify='center' align='center' direction={"column"} height='100%'>
          <Box
            height='100%'
            width={"100%"}
            pad='small'
            // background='black'
          >
            <TextInput
              // placeholder='type here'
              // value={value}
              // onChange={(event) => setValue(event.target.value)}
              disabled={props.disabled ? true : false}
              style={{
                backgroundColor: "#F7F7F8",
                padding: "5px 8px",
                minHeight: "53px",
                boxShadow: "inset 0px 3px 3px #00000007",
                borderRadius: "5px",
                border: 0,
                opacity: 1,
                textTransform: "uppercase",
              }}
              // className={Style.input}
              // type='text'
              id={props.name}
              name={props.name}
              value={props.value}
              onChange={handleOnChange}
            />
            {/* <input
            disabled={props.disabled ? true : false}
            className={Style.input}
            type='text'
            id={props.name}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
          /> */}
          </Box>
        </Box>
      )}
    </Box>
  );
}
