import { Title } from "./title";

export function Beneficiarios(props) {
  return (
    <div>
      <Title whiteTitle='4. Sección beneficiarios' />
      <h2 style={{ fontWeight: 400, marginBottom: '20px' }}>Recuerde que en el caso de fallecimiento cubierto por este producto, la suma asegurada será entregada a sus herederos legales, de acuerdo a lo establecido en el Código Civil</h2>
      <h2 style={{ fontWeight: 700 }}>Si usted requiere designar beneficiarios distintos a sus herederos legales, puede comunicarse a la línea 1800-400-400</h2>
      <div style={{ marginBottom: '20px' }}>
      </div>
    </div>
  );
}
