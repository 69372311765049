import React, { useState, useEffect, createRef } from "react";
import { Icon, InputProduct, Grid, Table } from "components/lib";
import { parishData } from "../../../dictionary/agriculture";
import Style from "./catalogue.module.scss";
import { Button } from "grommet";
import { Add } from "grommet-icons";
import { Scrollbars } from "react-custom-scrollbars-2";
import useViewPort from "../../hooks/useViewPort";

export function Catalogue(props) {
  const { agriculture, setAgriculture } = props;
  const [data, setData] = useState({ irrigation: {}, seed: {}, parish: {} });
  const [height, setHeight] = useState();
  const contentRef = createRef();
  const [screenSize] = useViewPort();

  //Features Actions
  // handle input change
  const handleInputDataChange = (e, nameSubsection, idx) => {
    const { name, value, selectedIndex } = e.target;
    const agricultureList = { ...agriculture };
    if (name === "parish") {
      agricultureList.catalogue[name][idx].nombreParroquia = value;
      parishData.map((option) => {
        if (option.nombreParroquia === value) {
          agricultureList.catalogue[name][idx].codigoCanton =
            option.codigoCanton;
          agricultureList.catalogue[name][idx].codigoParroquia =
            option.codigoParroquia;
          agricultureList.catalogue[name][idx].codigoProvincia =
            option.codigoProvincia;
        }
      });
    } else {
      agricultureList.catalogue[nameSubsection][idx].descripcion = value;
    }
    setAgriculture(agricultureList);
  };

  // handle click event of the Remove button feature
  const handleRemoveDataClick = (e, nameSubsection, idx) => {
    const agricultureList = { ...agriculture };
    agricultureList.catalogue[nameSubsection].splice(idx, 1);
    setAgriculture(agricultureList);
  };

  // handle click event of the Add button feature
  const handleAddDataClick = (nameSubsection) => {
    const agricultureList = { ...agriculture };
    if (nameSubsection === " parish") {
      agricultureList.catalogue[nameSubsection].push({
        codigoProvincia: "1",
        codigoCanton: "1",
        codigoParroquia: "50",
        nombreParroquia: "CUENCA",
      });
    } else {
      agricultureList.catalogue[nameSubsection].push({
        codigo: "CR",
        descripcion: "NUEVO ELEMENTO",
      });
    }
    setAgriculture((prevRequirements) => ({
      ...prevRequirements,
    }));
  };

  const makeDataTypes = () => {
    let tempObjectTable = {};
    let tempTableIrrigation = { body: [] };
    let tempTableSeed = { body: [] };
    let tempTableParish = { body: [] };

    agriculture.catalogue.typeIrrigation.map((type, idx) => {
      tempObjectTable.name = idx === 0 ? <h3 style={{background: "#95D4E9 0% 0% no-repeat padding-box", borderRadius: "10px", opacity: 1, padding: "15px 30px", color: "#0F265C"}}>Tipo de riego</h3> : "  ";
      tempObjectTable.description = (
        <div style={{ display: "flex" }}>
          <InputProduct
            name='typeIrrigation'
            key='typeIrrigation'
            value={type.descripcion}
            onChange={(e) => handleInputDataChange(e, "typeIrrigation", idx)}
          />
          <button
            style={{background: "#0F265C 0% 0% no-repeat padding-box", opacity: 1, borderRadius: "15px", color: "white", padding: 3, height: "25px", alignSelf: "center"}}
            onClick={(e) => handleRemoveDataClick(e, "typeIrrigation", idx)}
          >
            <Icon image={"minus"} size={18} />
          </button>
        </div>
      );
      tempTableIrrigation.body.push(tempObjectTable);
      tempObjectTable = {};
    });

    tempTableIrrigation.body.push({
      name: "",
      add: (
        <Button primary onClick={() => handleAddDataClick("typeIrrigation")}>
          <Add size='medium' />
        </Button>
        // <button
        //   style={{ padding: 5 }}
        //   onClick={() => handleAddDataClick("typeIrrigation")}
        // >
        //   <Icon image={"plus"} size={18} />
        // </button>
      ),
    });

    tempObjectTable = {};
    agriculture.catalogue.typeSeed.map((type, idx) => {
      tempObjectTable.name = idx === 0 ? <h3 style={{background: "#95D4E9 0% 0% no-repeat padding-box", borderRadius: "10px", opacity: 1, padding: "15px", color: "#0F265C"}}>Tipo de semilla</h3> : "  ";
      tempObjectTable.description = (
        <div style={{ display: "flex" }}>
          <InputProduct
            name='typeSeed'
            key='typeSeed'
            value={type.descripcion}
            onChange={(e) => handleInputDataChange(e, "typeSeed", idx)}
          />

          <button
            style={{background: "#0F265C 0% 0% no-repeat padding-box", opacity: 1, borderRadius: "15px", color: "white", padding: 3, height: "25px", alignSelf: "center"}}
            onClick={(e) => handleRemoveDataClick(e, "typeSeed", idx)}
          >
            <Icon image={"minus"} size={18} />
          </button>
        </div>
      );
      tempTableSeed.body.push(tempObjectTable);
      tempObjectTable = {};
    });

    tempTableSeed.body.push({
      name: "",
      description: (
        <Button primary onClick={() => handleAddDataClick("typeSeed")}>
          <Add size='medium' />
        </Button>
        // <button
        //   style={{ padding: 5 }}
        //   onClick={() => handleAddDataClick("typeSeed")}
        // >
        //   <Icon image={"plus"} size={18} />
        // </button>
      ),
    });

    tempObjectTable = {};
    agriculture.catalogue.parish.map((type, idx) => {
      tempObjectTable.name = idx === 0 ? <h3 style={{background: "#95D4E9 0% 0% no-repeat padding-box", borderRadius: "10px", opacity: 1, padding: "15px", color: "#0F265C"}}>Parroquias</h3> : "  ";
      tempObjectTable.codigoParroquia = (
        <InputProduct
          disabled
          name='parish'
          key='parish'
          value={type.codigoParroquia}
        />
      );
      tempObjectTable.description = (
        <select
          style={{ width: "150px", height: "50px", background: "#F7F7F8 0% 0% no-repeat padding-box", padding: "5px 8px", boxShadow: "inset 0px 3px 3px #00000008",
            borderRadius: "5px" }}
          name='parish'
          id={"parish"}
          onChange={(e) => handleInputDataChange(e, "", idx)}
        >
          {parishData.map((option) => {
            return (
              <option
                key={`catalogue${option.codigoProvincia}${option.codigoCanton}${option.codigoParroquia}${option.nombreParroquia}`}
                defaultValue={
                  type.nombreParroquia === option.nombreParroquia
                    ? "selected"
                    : null
                }
                value={option.codigo}
              >
                {option.nombreParroquia}
              </option>
            );
          })}
        </select>
      );
      tempTableParish.body.push(tempObjectTable);
      tempObjectTable = {};
    });
    // tempTableParish.body.push({
    //   name: "",
    //   add: (
    //     <Button primary onClick={() => handleAddDataClick("parish")}>
    //       <Add size='medium' />
    //     </Button>
    //     // <button
    //     //   style={{ padding: 5 }}
    //     //   onClick={() => handleAddDataClick("parish")}
    //     // >
    //     //   <Icon image={"plus"} size={18} />
    //     // </button>
    //   ),
    // });

    setData({
      ...data,
      irrigation: tempTableIrrigation,
      seed: tempTableSeed,
      parish: tempTableParish,
    });
  };

  useEffect(() => {
    const content = contentRef.current;
    setHeight((content && content.clientHeight) || 0);
  }, [contentRef]);

  useEffect(() => {
    makeDataTypes();
  }, [agriculture]);

  return (
    <div className={Style.createPlan}>
        {screenSize.dynamicWidth > 1024 ? (
      <>
        <Grid cols='2' spaceItems='flexStart'>
          <div style={{ width: "50%" }}>
            <Table data={data ? data.irrigation : []} />
          </div>
          <div style={{ width: "50%" }}>
            <Table data={data ? data.seed : []} />
          </div>
        </Grid>
        <Grid spaceItems='flexStart'>
          <div>
            <Table data={data ? data.parish : []} />
          </div>
        </Grid>
      </>
        ) : (
      <>
        <Scrollbars
            style={{
              width: "100%",
              height: "250px",
            }}
        >
        <div>
          <div style={{ width: "125%" }}>
            <Table data={data ? data.irrigation : []} />
          </div>
          <div style={{ width: "125%" }}>
            <Table data={data ? data.seed : []} />
          </div>
        </div>
        <div>
          <div style={{ width: "150%" }}>
            <Table data={data ? data.parish : []} />
          </div>
        </div>
        </Scrollbars>
      </>
        )}
      {/* <div style={{ marginTop: 20 }}>{JSON.stringify(agriculture)}</div> */}
    </div>
  );
}
