import React from 'react';
import { Label, Error } from 'components/lib';
import Style from './input.tailwind.js';

export function CardInput(props){

  const error = props.errorMessage || 'Please provide valid credit card details';

  return(
    <div className={ Style.input }>

      <Label
        text={ props.label }
        required={ props.required }
        for={ props.name }
      />

      { props.valid === false && <Error message={ error }/> }

    </div>
  );
}
