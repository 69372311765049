import React from "react";
import Style from "../../../../../css/globalStyle.module.scss";
import { Button as ButtonGrommet } from "grommet";
import { Title } from "./title";
import { useEffect } from "react";
import { useState } from "react";
import { Add, Subtract } from "grommet-icons";

export function Beneficiarios(props) {
  const [percentage, setPercentage] = useState(0);
  const [percentageBeneficiarios, setPercentageBeneficiarios] = useState(0);
  const {
    register,
    beneficiariosContigentesFields,
    beneficiariosContigentesAppend,
    beneficiariosContigentesRemove,
    beneficiariosPrincipalesFields,
    beneficiariosPrincipalesAppend,
    beneficiariosPrincipalesRemove,
    errors,
    watch,
    setValue,
  } = props;
  const beneficiariosPrincipales = watch("beneficiariosPrincipales");
  const beneficiariosContigentes = watch("beneficiariosContigentes");

  const lstParentescos = [
    { value: "Padres" },
    { value: "Hijos" },
    { value: "Abuelos" },
    { value: "Hermanos" },
    { value: "Nietos" },
    { value: "Tios" },
    { value: "Sobrinos" },
    { value: "Bisabuelos" },
    { value: "Primos hermanos" },
    { value: "Tatarabuelos" },
    { value: "Tataranietos" },
    { value: "Cónyuge" },
    { value: "Conviviente" },
    { value: "Padres del cónyuge" },
    { value: "Hijos del cónyuge" },
    { value: "Yernos" },
    { value: "Nueras" },
    { value: "Abuelos del cónyuge" },
    { value: "Padrastros" },
    { value: "Nieto político" },
  ];

  const calculatePercentageBeneficiaries = (value) => {
    let tempPercentage = percentage;
    if (tempPercentage > 100) {
      tempPercentage = 0;
    }
    tempPercentage = tempPercentage + parseFloat(value);
    setPercentage(tempPercentage);
    return tempPercentage;
  };

  const calculatePercentageContigentes = (value) => {
    let tempPercentage = percentageBeneficiarios;
    if (tempPercentage > 100) {
      tempPercentage = 0;
    }
    tempPercentage = tempPercentage + parseFloat(value);
    setPercentageBeneficiarios(tempPercentage);
    return tempPercentage;
  };

  useEffect(() => {
    if (beneficiariosPrincipalesFields.length === 0) {
      beneficiariosPrincipalesAppend({
        nombreCompletoBeneficiario: "",
        porcentajeBeneficiario: 0,
        parentescoBeneficiario: "",
        numeroIdentificacionBeneficiario: "",
        numeroTelefonoBeneficiario: "",
        correoElectronicoBeneficiario: "",
      });
    }
  }, []);

  function verificarCedula(cedula) {
    if (
      typeof cedula == "string" &&
      cedula.length == 10 &&
      /^\d+$/.test(cedula)
    ) {
      var digitos = cedula.split("").map(Number);
      var codigo_provincia = digitos[0] * 10 + digitos[1];

      //if (codigo_provincia >= 1 && (codigo_provincia <= 24 || codigo_provincia == 30) && digitos[2] < 6) {

      if (
        codigo_provincia >= 1 &&
        (codigo_provincia <= 24 || codigo_provincia == 30)
      ) {
        var digito_verificador = digitos.pop();

        var digito_calculado =
          digitos.reduce(function (valorPrevio, valorActual, indice) {
            return (
              valorPrevio -
              ((valorActual * (2 - (indice % 2))) % 9) -
              (valorActual == 9) * 9
            );
          }, 1000) % 10;
        return digito_calculado === digito_verificador;
      }
    }
    return false;
  }

  return (
    <div>
      <Title whiteTitle='6. Sección beneficiarios' />
      <h2 style={{ fontWeight: 600 }}>Beneficiarios principales</h2>
      <div style={{ paddingBottom: "15px" }}>
        <div style={{ padding: "10px 0px" }}>
          <ButtonGrommet
            primary
            onClick={() =>
              beneficiariosPrincipalesAppend({
                nombreCompletoBeneficiario: "",
                porcentajeBeneficiario: 0,
                parentescoBeneficiario: "",
                numeroIdentificacionBeneficiario: "",
                numeroTelefonoBeneficiario: "",
                correoElectronicoBeneficiario: "",
              })
            }
          >
            <Add size='medium' />
          </ButtonGrommet>
        </div>
        {beneficiariosPrincipalesFields.map((item, index) => (
          <div key={`beneficiarios${item.id}`} style={{ display: "flex" }}>
            <div className={Style.inputContainerBeneficiarios}>
              <div className={Style.inputContainerFinancierofifth}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;Nombres completos
                </label>
                <input
                  tabIndex={45}
                  type='text'
                  {...register(
                    `beneficiariosPrincipales.${index}.nombreCompletoBeneficiario`,
                    {
                      required: true,
                      maxLength: 255,
                      pattern: `//^[A-Za-z]+$//i`,
                    }
                  )}
                  className={Style["input"]}
                />
                {errors?.beneficiariosPrincipales &&
                  errors?.beneficiariosPrincipales[index]?.nombreCompletoBeneficiario?.type === "required" &&
                  (<p style={{ color: "red" }}>Este campo es requerido</p>)
                }
              </div>
              <div className={Style.inputContainer16}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;Porcentaje
                </label>
                <input
                  tabIndex={46}
                  type='number'
                  {...register(
                    `beneficiariosPrincipales.${index}.porcentajeBeneficiario`,
                    {
                      required: true,
                      min: 0,
                    }
                  )}
                  className={Style["input_number"]}
                  onBlur={(e) => {
                    let x = calculatePercentageBeneficiaries(e.target.value);
                    if (x > 100) {
                      beneficiariosPrincipales.map((value, i) => {
                        setValue(
                          `beneficiariosPrincipales.${i}.porcentajeBeneficiario`,
                          "0"
                        );
                      });
                    }
                  }}
                />
                {errors?.beneficiariosPrincipales &&
                  errors?.beneficiariosPrincipales[index]?.porcentajeBeneficiario?.type === "required" &&
                  (<p style={{ color: "red" }}>Este campo es requerido</p>)
                }
                {percentage > 101 && (
                  <p style={{ color: "red" }}>El porcentaje debe sumar 100%</p>
                )}
              </div>
              <div className={Style.inputContainer16}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;Parentesco
                </label>
                <select
                  {...register(
                    `beneficiariosPrincipales.${index}.parentescoBeneficiario`,
                    { required: true }
                  )}
                  className={Style["select"]}
                >
                  {lstParentescos?.map((item) => {
                    return (
                      <option
                        key={`beneficiariosPrincipales${item.value}`}
                        value={item.value}
                      >
                        {item.value}
                      </option>
                    );
                  })}
                </select>
                {errors?.beneficiariosPrincipales &&
                  errors?.beneficiariosPrincipales[index]?.parentescoBeneficiario?.type === "required" &&
                  (<p style={{ color: "red" }}>Este campo es requerido</p>)
                }
              </div>
              <div className={Style.inputContainer16}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;N° Identificación
                </label>
                <input
                  tabIndex={47}
                  {...register(
                    `beneficiariosPrincipales.${index}.numeroIdentificacionBeneficiario`,
                    {
                      validate: {
                        incorrectNumber: (v) => {
                          return verificarCedula(v);
                        },
                      },
                    }
                  )}
                  type='text'
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={"10"}
                  className={Style["input_number"]}
                />
                {errors?.beneficiariosPrincipales &&
                  errors?.beneficiariosPrincipales[index]?.numeroIdentificacionBeneficiario?.type &&
                  (<p style={{ color: "red" }}>Este campo es requerido</p>)
                }
              </div>
              <div className={Style.inputContainer16}>
                <label>N° Teléfono</label>
                <input
                  tabIndex={48}
                  type='tel'
                  {...register(
                    `beneficiariosPrincipales.${index}.numeroTelefonoBeneficiario`
                  )}
                  className={Style["input_number"]}
                />
              </div>
              <div className={Style.inputContainer16}>
                <label>Correo electrónico</label>
                <input
                  tabIndex={49}
                  type='email'
                  {...register(
                    `beneficiariosPrincipales.${index}.correoElectronicoBeneficiario`
                  )}
                  className={Style["input"]}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <ButtonGrommet
                  primary
                  onClick={() => beneficiariosPrincipalesRemove(index)}
                >
                  <Subtract size='medium' />
                </ButtonGrommet>
              </div>
            </div>
          </div>
        ))}
      </div>
      <h2 style={{ fontWeight: 600 }}>Beneficiarios contingentes</h2>
      <div style={{ paddingBottom: "20px" }}>
        <div style={{ padding: "10px 0px" }}>
          <ButtonGrommet
            primary
            onClick={() =>
              beneficiariosContigentesAppend({
                nombreCompletoBeneficiarioContingente: "",
                porcentajeBeneficiarioContingente: 0,
                parentescoBeneficiarioContingente: "",
                numeroIdentificacionBeneficiarioContingente: "",
                telefonoBeneficiarioContingente: "",
                correoElectronicoBeneficiarioContingente: "",
              })
            }
          >
            <Add size='medium' />
          </ButtonGrommet>
        </div>
        {beneficiariosContigentesFields.map((item, index) => (
          <div key={`beneficiarios${item.id}`} style={{ display: "flex" }}>
            <div className={Style.inputContainerBeneficiarios}>
              <div className={Style.inputContainerfifth}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;Nombres completos
                </label>
                <input
                  tabIndex={50}
                  type='text'
                  {...register(
                    `beneficiariosContigentes.${index}.nombreCompletoBeneficiarioContingente`,
                    {
                      required: true,
                      maxLength: 255,
                      pattern: `//^[A-Za-z]+$//i`,
                    }
                  )}
                  className={Style["input"]}
                />
                {errors[
                  `beneficiariosContigentes.${index}.nombreCompletoBeneficiarioContingente`
                ]?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
              </div>
              <div className={Style.inputContainer16}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;Porcentaje
                </label>
                <input
                  tabIndex={51}
                  type='number'
                  {...register(
                    `beneficiariosContigentes.${index}.porcentajeBeneficiarioContingente`,
                    {
                      required: true,
                      min: 0,
                    }
                  )}
                  className={Style["input_number"]}
                  onBlur={(e) => {
                    let x = calculatePercentageContigentes(e.target.value);
                    if (x > 100) {
                      beneficiariosContigentes.map((value, i) => {
                        setValue(
                          `beneficiariosContigentes.${i}.porcentajeBeneficiarioContingente`,
                          "0"
                        );
                      });
                    }
                  }}
                />
                {errors[
                  `beneficiariosContigentes.${index}.porcentajeBeneficiarioContingente`
                ]?.type === "required" &&
                  percentageBeneficiarios < 101 && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
                {percentageBeneficiarios > 101 && (
                  <p style={{ color: "red" }}>El porcentaje debe sumar 100%</p>
                )}
              </div>
              <div className={Style.inputContainer16}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;Parentesco
                </label>
                <select
                  {...register(
                    `beneficiariosContigentes.${index}.parentescoBeneficiarioContingente`,
                    { required: true }
                  )}
                  className={Style["select"]}
                >
                  {lstParentescos?.map((item) => {
                    return (
                      <option
                        key={`beneficiariosContigentes${item.value}`}
                        value={item.value}
                      >
                        {item.value}
                      </option>
                    );
                  })}
                </select>
                {errors[
                  `beneficiariosContigentes.${index}.parentescoBeneficiarioContingente`
                ]?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
              </div>
              <div className={Style.inputContainer16}>
                <label>
                  <b style={{ color: 'red' }}>*</b>&nbsp;N° Identificación
                </label>
                <input
                  tabIndex={52}
                  {...register(
                    `beneficiariosContigentes.${index}.numeroIdentificacionBeneficiarioContingente`,
                    // { required: true },
                    {
                      validate: {
                        incorrectNumber: (v) => {
                          return verificarCedula(v);
                        },
                      },
                    }
                  )}
                  type='text'
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  maxLength={"10"}
                  className={Style["input_number"]}
                />
                {errors[
                  `beneficiariosContigentes.${index}.numeroIdentificacionBeneficiarioContingente`
                ]?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
              </div>
              <div className={Style.inputContainer16}>
                <label>N° Teléfono</label>
                <input
                  tabIndex={53}
                  type='tel'
                  {...register(
                    `beneficiariosContigentes.${index}.telefonoBeneficiarioContingente`
                  )}
                  className={Style["input_number"]}
                />
              </div>
              <div className={Style.inputContainer16}>
                <label>Correo electrónico</label>
                <input
                  tabIndex={54}
                  type='email'
                  {...register(
                    `beneficiariosContigentes.${index}.correoElectronicoBeneficiarioContingente`
                  )}
                  className={Style["input"]}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <ButtonGrommet
                  primary
                  onClick={() => beneficiariosContigentesRemove(index)}
                >
                  <Subtract size='medium' />
                </ButtonGrommet>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
