/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import { Animate, Content, Grid, MenuCard } from "components/lib";

export function Seller(props) {
  return (
    <Animate type='pop'>
      <section>
        <Content>
          <Grid cols='2'>
            <MenuCard
              name='revenues'
              title='Seguro agrícola'
              goto='/vendedor/agricola'
            />
            <MenuCard
              center
              name='revenues'
              title='Seguro vida con ahorro'
              goto='/vendedor/vida'
            />
          </Grid>
        </Content>
      </section>
    </Animate>
  );
}
