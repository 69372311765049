import React, { Fragment, useState, useContext, useEffect } from "react";
import { TitleRow, useAPI, Card, Grid, ViewContext, Button, useNavigate } from "components/lib";
import { useLocation } from "react-router-dom";
import { CheckBox } from "grommet";
import { Submit } from "components/bridges/BridgeServices";
import Style from "../account/salesForce.module.scss";
import CryptrUtils from '../../utils/CryptrUtils';

export function RolDetails() {
  let globalSelection = {
    confperfiles: {
      usuarios: {
        c: false,
        r: false,
        u: false,
        d: false,
      },
      roles: {
        c: false,
        r: false,
        u: false,
        d: false,
      },
    },
    confprod: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    dashboard: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    priori: {
      c: false,
      r: false,
      u: false,
      d: false,
    },
    agro: {
      candidato: {
        c: false,
        r: false,
        u: false,
        d: false,
      },
      cotizaciones: {
        c: false,
        r: false,
        u: false,
        d: false,
      },
      calificacion: {
        c: false,
        r: false,
        u: false,
        d: false,
      },
    },
    vida: {
      candidato: {
        c: false,
        r: false,
        u: false,
        d: false,
      },
      cotizaciones: {
        c: false,
        r: false,
        u: false,
        d: false,
      },
      calificacion: {
        c: false,
        r: false,
        u: false,
        d: false,
      },
    }
  };
  const [globalSelectionv2, setGlobalSelectionv2] = useState(globalSelection);
  const [globalRol, setGlobalRol] = useState({});
  const [lstAppModules, setLstAppModules] = useState({});
  const context = useContext(ViewContext);
  let url = useLocation();
  let vecUrl = url.pathname.split("/");
  let nVecUrl = vecUrl.length;
  let rolId = vecUrl.length > 0 ? vecUrl[nVecUrl - 1] : "";

  let rawRol = useAPI("/api/rolById?rolId=" + rolId, "post");
  let rawRolAttrib = useAPI("/api/rol_attributionByRolId?rolId=" + rolId, "get");
  let rawAppModules = useAPI("/api/app_module", "get");
  const navigate = useNavigate();

  if (rawRol && rawRol?.data) {
    const decryptedRol = CryptrUtils.decrypt(rawRol?.data);
    const deepCopy = JSON.parse(decodeURIComponent(decryptedRol));
    if (Object.keys(globalRol).length === 0) {
      if (rawRolAttrib?.data && !rawRolAttrib?.data?.detail?.priori) {
        rawRolAttrib.data.detail.priori = {
          c: false,
          r: false,
          u: false,
          d: false
        }
      }
      deepCopy["secuencialMask"] = deepCopy?.secuencial
        .toString()
        .padStart(4, "0");
      setGlobalRol(deepCopy);
    }
  }

  //Checks section
  function handleChangeChk(ele) {
    let tmpVar = { ...globalSelectionv2 };
    let curEleName = ele.target.id;
    let splittedName = curEleName.split("_");
    if (splittedName.length > 1) {
      //confperfiles
      if (splittedName[0].localeCompare("confperfiles") === 0) {
        if (splittedName[1].localeCompare("user") === 0) {
          if (splittedName[2].localeCompare("c") === 0) {
            tmpVar.confperfiles.usuarios.c = !tmpVar.confperfiles.usuarios.c;
          }
          if (splittedName[2].localeCompare("r") === 0) {
            tmpVar.confperfiles.usuarios.r = !tmpVar.confperfiles.usuarios.r;
          }
          if (splittedName[2].localeCompare("u") === 0) {
            tmpVar.confperfiles.usuarios.u = !tmpVar.confperfiles.usuarios.u;
          }
          if (splittedName[2].localeCompare("d") === 0) {
            tmpVar.confperfiles.usuarios.d = !tmpVar.confperfiles.usuarios.d;
          }
        } else {
          if (splittedName[1].localeCompare("rol") === 0) {
            if (splittedName[2].localeCompare("c") === 0) {
              tmpVar.confperfiles.roles.c = !tmpVar.confperfiles.roles.c;
            }
            if (splittedName[2].localeCompare("r") === 0) {
              tmpVar.confperfiles.roles.r = !tmpVar.confperfiles.roles.r;
            }
            if (splittedName[2].localeCompare("u") === 0) {
              tmpVar.confperfiles.roles.u = !tmpVar.confperfiles.roles.u;
            }
            if (splittedName[2].localeCompare("d") === 0) {
              tmpVar.confperfiles.roles.d = !tmpVar.confperfiles.roles.d;
            }
          }
        }
      }
      //confprod
      if (splittedName[0].localeCompare("confprod") === 0) {
        if (splittedName[1].localeCompare("c") === 0) {
          tmpVar.confprod.c = !tmpVar.confprod.c;
        }
        if (splittedName[1].localeCompare("r") === 0) {
          tmpVar.confprod.r = !tmpVar.confprod.r;
        }
        if (splittedName[1].localeCompare("u") === 0) {
          tmpVar.confprod.u = !tmpVar.confprod.u;
        }
        if (splittedName[1].localeCompare("d") === 0) {
          tmpVar.confprod.d = !tmpVar.confprod.d;
        }
      }
      //dashboard
      if (splittedName[0].localeCompare("dashboard") === 0) {
        if (splittedName[1].localeCompare("c") === 0) {
          tmpVar.dashboard.c = !tmpVar.dashboard.c;
        }
        if (splittedName[1].localeCompare("r") === 0) {
          tmpVar.dashboard.r = !tmpVar.dashboard.r;
        }
        if (splittedName[1].localeCompare("u") === 0) {
          tmpVar.dashboard.u = !tmpVar.dashboard.u;
        }
        if (splittedName[1].localeCompare("d") === 0) {
          tmpVar.dashboard.d = !tmpVar.dashboard.d;
        }
      }
      //priori
      if (splittedName[0].localeCompare("priori") === 0) {
        if (splittedName[1].localeCompare("c") === 0) {
          tmpVar.priori.c = !tmpVar.priori.c;
        }
        if (splittedName[1].localeCompare("r") === 0) {
          tmpVar.priori.r = !tmpVar.priori.r;
        }
        if (splittedName[1].localeCompare("u") === 0) {
          tmpVar.priori.u = !tmpVar.priori.u;
        }
        if (splittedName[1].localeCompare("d") === 0) {
          tmpVar.priori.d = !tmpVar.priori.d;
        }
      }
      //agro
      if (splittedName[0].localeCompare("agro") === 0) {
        if (splittedName[1].localeCompare("candidato") === 0) {
          if (splittedName[2].localeCompare("c") === 0) {
            tmpVar.agro.candidato.c = !tmpVar.agro.candidato.c;
          }
          if (splittedName[2].localeCompare("r") === 0) {
            tmpVar.agro.candidato.r = !tmpVar.agro.candidato.r;
          }
          if (splittedName[2].localeCompare("u") === 0) {
            tmpVar.agro.candidato.u = !tmpVar.agro.candidato.u;
          }
          if (splittedName[2].localeCompare("d") === 0) {
            tmpVar.agro.candidato.d = !tmpVar.agro.candidato.d;
          }
        } else {
          if (splittedName[1].localeCompare("cotizaciones") === 0) {
            if (splittedName[2].localeCompare("c") === 0) {
              tmpVar.agro.cotizaciones.c = !tmpVar.agro.cotizaciones.c;
            }
            if (splittedName[2].localeCompare("r") === 0) {
              tmpVar.agro.cotizaciones.r = !tmpVar.agro.cotizaciones.r;
            }
            if (splittedName[2].localeCompare("u") === 0) {
              tmpVar.agro.cotizaciones.u = !tmpVar.agro.cotizaciones.u;
            }
            if (splittedName[2].localeCompare("d") === 0) {
              tmpVar.agro.cotizaciones.d = !tmpVar.agro.cotizaciones.d;
            }
          } else {
            if (splittedName[1].localeCompare("calificacion") === 0) {
              if (splittedName[2].localeCompare("c") === 0) {
                tmpVar.agro.calificacion.c = !tmpVar.agro.calificacion.c;
              }
              if (splittedName[2].localeCompare("r") === 0) {
                tmpVar.agro.calificacion.r = !tmpVar.agro.calificacion.r;
              }
              if (splittedName[2].localeCompare("u") === 0) {
                tmpVar.agro.calificacion.u = !tmpVar.agro.calificacion.u;
              }
              if (splittedName[2].localeCompare("d") === 0) {
                tmpVar.agro.calificacion.d = !tmpVar.agro.calificacion.d;
              }
            }
          }
        }
      }
      //vida
      if (splittedName[0].localeCompare("vida") === 0) {
        if (splittedName[1].localeCompare("candidato") === 0) {
          if (splittedName[2].localeCompare("c") === 0) {
            tmpVar.vida.candidato.c = !tmpVar.vida.candidato.c;
          }
          if (splittedName[2].localeCompare("r") === 0) {
            tmpVar.vida.candidato.r = !tmpVar.vida.candidato.r;
          }
          if (splittedName[2].localeCompare("u") === 0) {
            tmpVar.vida.candidato.u = !tmpVar.vida.candidato.u;
          }
          if (splittedName[2].localeCompare("d") === 0) {
            tmpVar.vida.candidato.d = !tmpVar.vida.candidato.d;
          }
        } else {
          if (splittedName[1].localeCompare("cotizaciones") === 0) {
            if (splittedName[2].localeCompare("c") === 0) {
              tmpVar.vida.cotizaciones.c = !tmpVar.vida.cotizaciones.c;
            }
            if (splittedName[2].localeCompare("r") === 0) {
              tmpVar.vida.cotizaciones.r = !tmpVar.vida.cotizaciones.r;
            }
            if (splittedName[2].localeCompare("u") === 0) {
              tmpVar.vida.cotizaciones.u = !tmpVar.vida.cotizaciones.u;
            }
            if (splittedName[2].localeCompare("d") === 0) {
              tmpVar.vida.cotizaciones.d = !tmpVar.vida.cotizaciones.d;
            }
          } else {
            if (splittedName[1].localeCompare("calificacion") === 0) {
              if (splittedName[2].localeCompare("c") === 0) {
                tmpVar.vida.calificacion.c = !tmpVar.vida.calificacion.c;
              }
              if (splittedName[2].localeCompare("r") === 0) {
                tmpVar.vida.calificacion.r = !tmpVar.vida.calificacion.r;
              }
              if (splittedName[2].localeCompare("u") === 0) {
                tmpVar.vida.calificacion.u = !tmpVar.vida.calificacion.u;
              }
              if (splittedName[2].localeCompare("d") === 0) {
                tmpVar.vida.calificacion.d = !tmpVar.vida.calificacion.d;
              }
            }
          }
        }
      }
    }
    setGlobalSelectionv2(tmpVar);
  }

  const saveRolDetails = async () => {
    //Updating
    if (rawRolAttrib?.data) {
      let objRolAttrib = {
        rol_id: globalRol.id,
        detail: globalSelectionv2,
      };
      await Submit(
        "rol_attribution",
        objRolAttrib,
        "patch"
      );
      context.notification.show("Se ha actualizado el rol", "success", true);
      navigate("/profile/rol");
    } else {
      //inserting
      let objRolAttrib = {
        rol_id: globalRol.id,
        detail: globalSelectionv2,
      };
      await Submit(
        "rol_attribution",
        objRolAttrib,
        "post"
      );
      context.notification.show("Se ha creado el rol", "success", true);
      navigate("/profile/rol");
    }
  };

  useEffect(() => {
    if (rawAppModules && rawAppModules?.data) {
      if (Object.keys(lstAppModules).length === 0) {
        setLstAppModules(rawAppModules?.data);
      }
    }
  }, [rawAppModules]);

  useEffect(() => {
    if (rawRolAttrib?.data) {
      setGlobalSelectionv2(rawRolAttrib.data.detail);
    }
  }, [rawRolAttrib]);

  function redirect() {
    navigate("/profile/rol");
  }

  return (
    <Fragment>
      <p>
        <strong>Identificador:</strong> {globalRol.secuencialMask}
      </p>
      <p>
        <strong>Nombre:</strong> {globalRol.name}
      </p>
      <br></br>

      <TitleRow title='Módulos'></TitleRow>

      <Grid cols='3'>
        <Card backgroundColor={"#EAF7FB"}>
          <h3 style={{ fontWeight: "bold", color: "#0F265C" }}>
            Configuración de productos
          </h3>
          <hr className={Style.divider} />
          <CheckBox
            type='checkbox'
            id='confprod_c'
            name='confprod_c'
            onChange={handleChangeChk}
            checked={globalSelectionv2.confprod.c}
            label='Crear'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='confprod_r'
            name='confprod_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2.confprod.r}
            label='Leer'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='confprod_u'
            name='confprod_u'
            onChange={handleChangeChk}
            checked={globalSelectionv2.confprod.u}
            label='Actualizar'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='confprod_d'
            name='confprod_d'
            onChange={handleChangeChk}
            checked={globalSelectionv2.confprod.d}
            label='Eliminar'
            pad={"5px 0px 5px 0px"}
          />
          <br></br>
          <br></br>
        </Card>

        <Card backgroundColor={"#EAF7FB"}>
          <h3 style={{ fontWeight: "bold", color: "#0F265C" }}>Dashboard</h3>
          <hr className={Style.divider} />
          <CheckBox
            type='checkbox'
            id='dashboard_c'
            name='dashboard_c'
            onChange={handleChangeChk}
            checked={globalSelectionv2.dashboard.c}
            label='Crear'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='dashboard_r'
            name='dashboard_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2.dashboard.r}
            label='Leer'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='dashboard_r'
            name='dashboard_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2.dashboard.r}
            label='Actualizar'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='dashboard_d'
            name='dashboard_d'
            onChange={handleChangeChk}
            checked={globalSelectionv2.dashboard.d}
            label='Eliminar'
            pad={"5px 0px 5px 0px"}
          />
          <br></br>
          <br></br>
        </Card>

        <Card backgroundColor={"#EAF7FB"}>
          <h3 style={{ fontWeight: "bold", color: "#0F265C" }}>PRIORI Ahorro</h3>
          <hr className={Style.divider} />
          <CheckBox
            type='checkbox'
            id='priori_c'
            name='priori_c'
            onChange={handleChangeChk}
            checked={globalSelectionv2?.priori?.c}
            label='Crear'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='priori_r'
            name='priori_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2?.priori?.r}
            label='Leer'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='priori_u'
            name='priori_u'
            onChange={handleChangeChk}
            checked={globalSelectionv2?.priori?.u}
            label='Actualizar'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='priori_d'
            name='priori_d'
            onChange={handleChangeChk}
            checked={globalSelectionv2?.priori?.d}
            label='Eliminar'
            pad={"5px 0px 5px 0px"}
          />
          <br></br>
          <br></br>
        </Card>

      </Grid>

      <Grid cols='3'>
        <Card backgroundColor={"#EAF7FB"}>
          <h3 style={{ fontWeight: "bold", color: "#0F265C" }}>Agropecuario</h3>
          <hr className={Style.divider} />
          <p>
            <strong>Candidato</strong>
          </p>
          <CheckBox
            type='checkbox'
            id='agro_candidato_c'
            name='agro_candidato_c'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.candidato.c}
            label='Crear'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='agro_candidato_r'
            name='agro_candidato_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.candidato.r}
            label='Leer'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='agro_candidato_u'
            name='agro_candidato_u'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.candidato.u}
            label='Actualizar'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='agro_candidato_d'
            name='agro_candidato_d'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.candidato.d}
            label='Eliminar'
            pad={"5px 0px 5px 0px"}
          />
          <br></br>
          <br></br>
          <p>
            <strong>Cotizaciones</strong>
          </p>
          <CheckBox
            type='checkbox'
            id='agro_cotizaciones_c'
            name='agro_cotizaciones_c'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.cotizaciones.c}
            label='Crear'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='agro_cotizaciones_r'
            name='agro_cotizaciones_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.cotizaciones.r}
            label='Leer'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='agro_cotizaciones_u'
            name='agro_cotizaciones_u'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.cotizaciones.u}
            label='Actualizar'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='agro_cotizaciones_d'
            name='agro_cotizaciones_d'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.cotizaciones.d}
            label='Eliminar'
            pad={"5px 0px 5px 0px"}
          />
          <br></br>
          <br></br>
          <p>
            <strong>Calificación</strong>
          </p>
          <CheckBox
            type='checkbox'
            id='agro_calificacion_c'
            name='agro_calificacion_c'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.calificacion.c}
            label='Crear'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='agro_calificacion_r'
            name='agro_calificacion_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.calificacion.r}
            label='Leer'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='agro_calificacion_u'
            name='agro_calificacion_u'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.calificacion.u}
            label='Actualizar'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='agro_calificacion_d'
            name='agro_calificacion_d'
            onChange={handleChangeChk}
            checked={globalSelectionv2.agro.calificacion.d}
            label='Eliminar'
            pad={"5px 0px 5px 0px"}
          />
          <br></br>
          <br></br>
        </Card>

        <Card backgroundColor={"#EAF7FB"}>
          <h3 style={{ fontWeight: "bold", color: "#0F265C" }}>
            Vida con Ahorro
          </h3>
          <hr className={Style.divider} />
          <p>
            <strong>Candidato</strong>
          </p>
          <CheckBox
            type='checkbox'
            id='vida_candidato_c'
            name='vida_candidato_c'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.candidato.c}
            label='Crear'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='vida_candidato_r'
            name='vida_candidato_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.candidato.r}
            label='Leer'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='vida_candidato_u'
            name='vida_candidato_u'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.candidato.u}
            label='Actualizar'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='vida_candidato_d'
            name='vida_candidato_d'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.candidato.d}
            label='Eliminar'
            pad={"5px 0px 5px 0px"}
          />
          <br></br>
          <br></br>
          <p>
            <strong>Cotizaciones</strong>
          </p>
          <CheckBox
            type='checkbox'
            id='vida_cotizaciones_c'
            name='vida_cotizaciones_c'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.cotizaciones.c}
            label='Crear'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='vida_cotizaciones_r'
            name='vida_cotizaciones_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.cotizaciones.r}
            label='Leer'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='vida_cotizaciones_u'
            name='vida_cotizaciones_u'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.cotizaciones.u}
            label='Actualizar'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='vida_cotizaciones_d'
            name='vida_cotizaciones_d'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.cotizaciones.d}
            label='Eliminar'
            pad={"5px 0px 5px 0px"}
          />
          <br></br>
          <br></br>
          <p>
            <strong>Calificación</strong>
          </p>
          <CheckBox
            type='checkbox'
            id='vida_calificacion_c'
            name='vida_calificacion_c'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.calificacion.c}
            label='Crear'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='vida_calificacion_r'
            name='vida_calificacion_r'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.calificacion.r}
            label='Leer'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='vida_calificacion_u'
            name='vida_calificacion_u'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.calificacion.u}
            label='Actualizar'
            pad={"5px 0px 5px 0px"}
          />
          <CheckBox
            type='checkbox'
            id='vida_calificacion_d'
            name='vida_calificacion_d'
            onChange={handleChangeChk}
            checked={globalSelectionv2.vida.calificacion.d}
            label='Eliminar'
            pad={"5px 0px 5px 0px"}
          />
          <br></br>
          <br></br>
        </Card>
      </Grid>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div style={{ marginRight: "10px" }}>
          <Button
            fullWidth
            text='Regresar'
            className='inline-block'
            action={redirect}
            color={"blue"}
          />
        </div>
        <div>
          <Button
            fullWidth
            text='Guardar'
            color={"blue"}
            className='inline-block'
            action={saveRolDetails}
          />
        </div>
      </div>
    </Fragment>
  );
}
