/***
 *
 *   GRID
 *   Responsive one-to-six column grid layout
 *
 *   PROPS
 *   cols: number of columns (default: 2, max: 6)
 *   spaceItems: give display flex in spaceEvenly/center/flexEnd/flexStart
 **********/

import React from "react";
import { ClassHelper } from "components/lib";
import Style from "./grid.module.scss";

export function Grid(props) {
  const cols = {};
  const colNames = ["one", "two", "three", "four", "five", "six"];

  if (props.cols) {
    for (let i = 0; i < parseInt(props.cols); i++) cols[colNames[i]] = true;
  }

  const css = ClassHelper(Style, {
    ...cols,
    ...{ grid: true },
    [props.spaceItems]: props.spaceItems,
  });

  return (
    <section className={css}>
      {props.children?.length > 1
        ? props.children.map((child, index) => {
            return child;
          })
        : props.children}
    </section>
  );
}
