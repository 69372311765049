/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/

import React from "react";
import { Animate, Grid, Row, Content, Link, Icon } from "components/lib";
// import Style from "./footer.tailwind.js";
import Style from "./footer.module.scss";

export function Footer(props) {
  return (
    <Animate type='slideup'>
      <footer className={Style.footer}>
        <Row color='transparent'>
          <Content>
            <Grid cols='4'>
              <div className={Style.sections}>
                <h2 className={Style.titleSection}>Seguros</h2>
                <Link
                  url='/'
                  text='Persona'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Empresa'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Brokers'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Video'
                  className={Style.link}
                  color='white'
                />
              </div>
              <div className={Style.sections}>
                <h2 className={Style.titleSection}>Servicios</h2>
                <Link
                  url='/'
                  text='Información de mis seguros'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Aviso de siniestro'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Formularios'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Club Pichincha'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Reportar un Siniestro'
                  className={Style.link}
                  color='white'
                />
              </div>
              <div className={Style.sections}>
                <h2 className={Style.titleSection}>Recursos</h2>
                <Link
                  url='/'
                  text='Blog'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Preguntas Frecuentes'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Glosario'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Educación Financieria'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Superintendencia de Compañías'
                  className={Style.link}
                  color='white'
                />
              </div>
              <div className={Style.sections}>
                <h2 className={Style.titleSection}>Seguros del Pichincha</h2>
                <Link
                  url='/'
                  text='Quiénes Somos'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Responsabilidad Solcial'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Transparencia y Gobierno Corporativo'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Únete a nuestro equipo'
                  className={Style.link}
                  color='white'
                />
                <Link
                  url='/'
                  text='Canales de atención'
                  className={Style.link}
                  color='white'
                />
              </div>
            </Grid>
            <div className={Style.icons}>
              <Icon
                className={Style.firstIcon}
                image='facebook'
                color='#96d4e9'
                size={30}
              />
              <Icon
                className={Style.iconFooter}
                image='instagram'
                color='#96d4e9'
                size={30}
              />
              <Icon
                className={Style.iconFooter}
                image='linkedin'
                color='#96d4e9'
                size={30}
              />
              <Icon
                className={Style.iconFooter}
                image='twitter'
                color='#96d4e9'
                size={30}
              />
              <Icon
                className={Style.iconFooter}
                image='youtube'
                color='#96d4e9'
                size={30}
              />
            </div>
          </Content>
        </Row>
      </footer>
    </Animate>
  );
}
