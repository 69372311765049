import React, { useContext, useEffect } from "react";
import { MenuCard, Animate, useAPI } from "components/lib";
import { Box, Grid as GridGroomet, ResponsiveContext } from "grommet";
import people from "components/logo/images/menu/Usuarios.svg";
import document from "components/logo/images/menu/productosCreados.svg";
import Style from "./qualofication.module.scss";
import useData from "../../components/hooks/useGeneralData";
import { Submit } from "../../components/bridges/BridgeServices";

const columns = {
  small: ["auto"],
  medium: ["auto", "auto"],
  large: ["auto", "auto"],
  xlarge: ["auto", "auto"],
};

const rows = {
  small: ["auto", "auto", "auto"],
  medium: ["auto", "auto", "auto"],
  large: ["auto", "auto", "auto"],
  xlarge: ["auto", "auto", "auto"],
};

// set the different areas you need for every size
const areas = {
  small: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [0, 1], end: [0, 1] },
    { name: "side3", start: [0, 2], end: [0, 2] },
  ],
  medium: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
  large: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
  xlarge: [
    { name: "side1", start: [0, 0], end: [0, 0] },
    { name: "side2", start: [1, 0], end: [1, 0] },
    { name: "side3", start: [0, 1], end: [0, 1] },
  ],
};

// set the different areas you need for every size

const ResponsiveGrid = ({
  children,
  overrideColumns,
  overrideRows,
  areas,
  ...props
}) => (
  <ResponsiveContext.Consumer>
    {(size) => {
      // take into consideration if not array is sent but a simple string
      let columnsVal = columns;
      if (columns) {
        if (columns[size]) {
          columnsVal = columns[size];
        }
      }

      let rowsVal = rows;
      if (rows) {
        if (rows[size]) {
          rowsVal = rows[size];
        }
      }

      // also if areas is a simple array not an object of arrays for different sizes
      let areasVal = areas;
      if (areas && !Array.isArray(areas)) areasVal = areas[size];

      return (
        <GridGroomet
          {...props}
          areas={!areasVal ? undefined : areasVal}
          rows={!rowsVal ? size : rowsVal}
          columns={!columnsVal ? size : columnsVal}
          justify='center'
        >
          {children}
        </GridGroomet>
      );
    }}
  </ResponsiveContext.Consumer>
);

export function IndexQualification(props) {
  const size = useContext(ResponsiveContext);
  const { userPermissions, setPermissions } = useData();
  const rawUser = JSON.parse(localStorage.getItem('profile'))
  const setViewCandidate = () => {
    let isView = false;
    if (rawUser.data && rawUser.data.flag_uno) {
      isView = true;
    } else {
      if (userPermissions) {
        Object.keys(userPermissions.agro.calificacion).map((key) => {
          if (key === "c" || key === "d" || key === "u") {
            if (userPermissions.agro.calificacion[key]) {
              isView = true;
            }
          }
        });
      }
    }
    return isView;
  };

  return (
    <Animate type='pop'>
      <div className={Style.mainContainer}>
        <Box
          height={size.indexOf("small") > -1 ? "75vh" : "75vh"}
          justify='center'
          align='center'
        >
          <ResponsiveGrid
            rows={rows}
            columns={columns}
            gap='small'
            areas={areas}
            margin='small'
          >
            {setViewCandidate() ? (
              <>
                <Box gridArea='side1' className={Style.boxContainer}>
                  <MenuCard
                    name='revenues'
                    title='Nuevas solicitudes'
                    goto='/qualification/qualification'
                    icon={people}
                  />
                </Box>
                <Box
                  gridArea='side2'
                  align='start'
                  className={Style.boxContainer}
                >
                  <MenuCard
                    center
                    name='revenues'
                    title='Cotizaciones'
                    goto='/qualification/price'
                    icon={document}
                  />
                </Box>
              </>
            ) : (
              <Box
                gridArea='side2'
                align='start'
                className={Style.boxContainer}
              >
                <MenuCard
                  center
                  name='revenues'
                  title='Cotizaciones'
                  goto='/qualification/price'
                  icon={document}
                />
              </Box>
            )}
          </ResponsiveGrid>
        </Box>
        <div className={Style.blockMenu}></div>
      </div>
    </Animate>
  );
}
