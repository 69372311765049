import { ByCycle, ByPlan, ByValue, Card, TabView } from "components/lib";
import { useEffect } from "react";
import Style from "./configCultivation.module.scss";
import "./react-tabs.scss";

export function ConfigCultivation(props) {
  const { agriculture, setAgriculture, servicesData, setServicesData } = props;

  return (
    <div className={Style.configCultivation}>
      <TabView name='configCultivation' labels={["Cultivos por plan", "Cultivos por ciclo", "Cultivos por valor asegurado"]}>
        <Card>
          <ByPlan agriculture={agriculture} setAgriculture={setAgriculture} servicesData={servicesData} />
        </Card>
        <Card>
          <ByCycle agriculture={agriculture} setAgriculture={setAgriculture} />
        </Card>
        <Card>
          <ByValue agriculture={agriculture} setAgriculture={setAgriculture} />
        </Card>
      </TabView>
    </div>
  );
}
