import React from "react";
import { Title } from "./title";
import { formatNumber } from "utils/formatNumber";
import Style from "../../../../../css/globalStyle.module.scss";

export function PlanSeguro(props) {
  const {
    errors,
    register,
    dataInfo
  } = props;
  const getCoberturaPrincipal = (coberturas) => {
    let tempCobertura = coberturas.find(
      (cobertura) => cobertura.astr_principal === "S"
    );
    return tempCobertura.astr_cob_descrip_print;
  };

  const getCoberturasSecundarias = (cobertura) => {
    if (cobertura.astr_principal === "N") {
      return true;
    } else {
      return false;
    }
  };

  const getSumaAsegurada = () => {
    let tempCoberturaPrincipal = dataInfo.plan.cod_producto.coberturas.find(
      (cobertura) => cobertura.astr_principal === "S"
    );
    let tempCobertura = dataInfo.coberturas.find(
      (cobertura) =>
        cobertura.astr_cobertura === tempCoberturaPrincipal.astr_codigo_cob
    );
    return tempCobertura.adec_valor_aseg;
  };

  const getSumaAseguradaAnexos = (astr_codigo_cob) => {
    let tempCobertura = dataInfo.coberturas.find(
      (cobertura) => cobertura.astr_cobertura === astr_codigo_cob
    );
    return tempCobertura.adec_valor_aseg;
  };

  return (
    <div>
      <Title whiteTitle='2. Sección plan de seguro' />
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerSecond}>
          <label>
            Producto
          </label>
          <input
            tabIndex={2}
            value={dataInfo.plan.cod_producto.astr_descripcion}
            disabled
            type='text'
            {...register("producto", {
              required: true,
              maxLength: 255,
              value: dataInfo.plan.cod_producto.astr_descripcion,
            })}
            className={Style["input"]}
          />
          {errors.producto?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerSecond}>
          <label>
            Cobertura principal
          </label>
          <input
            tabIndex={3}
            value={getCoberturaPrincipal(dataInfo.plan.cod_producto.coberturas)}
            disabled
            type='text'
            {...register("coberturaPrincipal", {
              value: getCoberturaPrincipal(
                dataInfo.plan.cod_producto.coberturas
              ),
              required: true,
              maxLength: 254,
            })}
            className={Style["input"]}
          />
        </div>
        <div className={Style.inputContainerSecond}>
          <label>
            Suma asegurada
          </label>
          <input
            tabIndex={4}
            disabled
            value={`$${formatNumber(getSumaAsegurada())}`}
            type='text'
            {...register("sumaAsegurada", {
              required: true,
              maxLength: 254,
              value: `$${formatNumber(getSumaAsegurada())}`,
            })}
            className={Style["input_number"]}
          />
        </div>
      </div>
      {dataInfo.plan.alternativesSelected.map((item, idx) => (
        <div key={`Sección plan de segurogetCoberturasSecundarias${idx}`}>
          {getCoberturasSecundarias(item) && (
            <div className={Style.mainContainerForm}>
              <div className={Style.inputContainerSecond}></div>
              <div className={Style.inputContainerSecond}>
                <label>
                  Cobertura adicional
                </label>
                <input
                  tabIndex={5}
                  value={item.astr_cob_descrip_print}
                  disabled
                  type='text'
                  {...register(
                    `coberturasAdicionales.${idx}.coberturaAdicional`,
                    {
                      required: false,
                      min: 1,
                      value: item.astr_cob_descrip_print,
                    }
                  )}
                  className={Style["input"]}
                />
              </div>
              <div className={Style.inputContainerSecond}>
                <label>
                  Suma asegurada de anexos
                </label>
                <input
                  tabIndex={6}
                  disabled
                  value={`$${formatNumber(
                    getSumaAseguradaAnexos(item.astr_codigo_cob)
                  )}`}
                  type='text'
                  {...register(`coberturasAdicionales.${idx}.sumaAsegurada`, {
                    required: false,
                    min: 1,
                    value: `$${formatNumber(
                      getSumaAseguradaAnexos(item.astr_codigo_cob)
                    )}`,
                  })}
                  className={Style["input_number"]}
                />
              </div>
            </div>
          )}
        </div>
      ))}

      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerSecond}>
          <label>
            Modalidad de pago
          </label>
          <input
            tabIndex={7}
            value={
              dataInfo?.client?.typePaid === "M"
                ? "MENSUAL"
                : dataInfo?.client?.typePaid === "A"
                  ? "ANUAL"
                  : dataInfo?.client?.typePaid === "S"
                    ? "SEMESTRAL"
                    : "TRIMESTRAL"
            }
            type='text'
            {...register("modalidadPago", {
              value:
                dataInfo?.client?.typePaid === "M"
                  ? "MENSUAL"
                  : dataInfo?.client?.typePaid === "A"
                    ? "ANUAL"
                    : dataInfo?.client?.typePaid === "S"
                      ? "SEMESTRAL"
                      : "TRIMESTRAL",
            })}
            className={Style["input"]}
            disabled
          />
          {errors.modalidadPago?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerSecond}>
          <label>
            Aporte
          </label>
          <input
            tabIndex={8}
            value={`$${dataInfo?.dataProjection?.data?.pagos[0]?.adec_pag_valor_total}`}
            disabled
            type='text'
            {...register("Aporte", {
              value: `$${dataInfo?.dataProjection?.data?.pagos[0]?.adec_pag_valor_total}`,
              required: true,
              maxLength: 254,
            })}
            className={Style["input_number"]}
          />
          {errors.Aporte?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
      </div>
    </div>
  );
}
