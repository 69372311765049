/***
 *
 *   MENU
 *
 *
 **********/

import React from "react";
import {
  ClassHelper,
  MenuItem,
  Grid,
  Animate,
  Feedback,
  useAPI,
  Header,
} from "components/lib";
import Style from "./menu.module.scss";

import Logo from "./images/help-circle.svg";

export function Menu(props) {
  const menuStyle = ClassHelper(Style, {
    // center: "center",
    // className: props.className,
  });

  return (
    <Animate type='pop'>
      {/* <Header title={"Sistema de cotización"} /> */}
      <p className={Style.title}>Sistema de cotización</p>
      <div className={Style.background}>
        <div className={menuStyle}>
          <Grid spaceItems={"center"} cols='3' className={Style.threeItems}>
            <MenuItem padding title='Configuración de perfiles' img={Logo} />
            <MenuItem padding title='Seguro agrícola' img={Logo} />
            <MenuItem padding title='Seguro vida con ahorro' img={Logo} />
          </Grid>
        </div>
        <div className={menuStyle}>
          <Grid spaceItems={"center"} cols='2' className={Style.twoItems}>
            <MenuItem
              padding
              title='Configuración de producto'
              goto='/producto'
              img={Logo}
            />
            {/* <MenuItem
              padding
              title='Dashboard vida y agro'
              goto='/dashboard'
              img={Logo}
            /> */}
          </Grid>
        </div>
      </div>
      {/* <Feedback /> */}
    </Animate>
  );
}
