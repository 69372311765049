/***
 *
 *
 **********/

import React from "react";
import { Animate, Button, Content, Table, useNavigate } from "components/lib";
import { useState } from "react";
import { useEffect } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { getFilterDataPricesLife } from "components/bridges/BridgeServices";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import Style from "./price.module.scss";
import Input from "../../../css/globalStyle.module.scss";
import "../../../css/DateRangePicker.css";
import GlobalStyle from "../../../css/globalStyle.module.scss";
import { BackButton } from "components/backButton/button";
import useViewPort from "components/hooks/useViewPort";
import { Scrollbars } from "react-custom-scrollbars-2";

export function PriceLife(props) {
  const navigate = useNavigate();
  const [screenSize] = useViewPort();
  const [data, setData] = useState({});
  const [dataSeach, setDataSearch] = useState({
    id: "",
    dateBegin: "",
    dateEnd: "",
    date: "",
  });
  const [allCandidates, setAllCandidates] = useState([]);

  const handleSearchData = async () => {
    let obj = {
      nro_documento: dataSeach.id,
      fecha_desde: dataSeach.dateBegin,
      fecha_hasta: dataSeach.dateEnd,
      product_type: "life",
    };

    await getFilterDataPricesLife(obj).then((res) => {
      if (res) {
        let tempAllCandidates = [];
        tempAllCandidates = res.data.filter(
          (item) =>
            item.product_type !== "agro" &&
            item.product !== null &&
            item.product !== undefined &&
            Object.entries(item.product).length > 0
        );
        setAllCandidates(tempAllCandidates);
      }
    });
  };

  const parseDate = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleDate = (date) => {

    if (date === null) {
      setDataSearch({
        ...dataSeach,
        date: null,
      });
    } else {
      setDataSearch({
        ...dataSeach,
        dateBegin: parseDate(date[0]),
        dateEnd: parseDate(date[1]),
        date,
      });
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const alphanumericExtendedRegex = /^[0-9 ]+$/;
    if (value === '' || alphanumericExtendedRegex.test(value)) {
      setDataSearch({ ...dataSeach, [name]: value });
    }
  };

  const viewProduct = (data, callback) => {
    navigate(`/vendedor/vida/cotizaciones/${data.detail.nro_documento}`, {
      state: data.detail,
    });
  };

  const makeDataTable = () => {
    let tempDataTable = {
      header: [
        { name: "name", title: "Nombre", sort: false },
        { name: "ci", title: "Cédula", sort: false },
        { name: "date", title: "Fecha", sort: false },
        { name: "product", title: "Producto", sort: false },
        { name: "plan", title: "Plan", sort: false },
        { name: "status", title: "Estado", sort: false },
        { name: "observations", title: "Observaciones", sort: false },
        { name: "detail", title: "Detalles", sort: false },
      ],
      body: [],
    };
    let tempObjectCultivation = {};
    allCandidates.map((item, idx) => {
      tempObjectCultivation.detail = item;
      tempObjectCultivation.name = (
        <div>{`${item.detail.primer_nombre} ${item.detail.apellido_paterno}`}</div>
      );
      tempObjectCultivation.ci = <div>{`${item.nro_documento}`}</div>;
      tempObjectCultivation.date = (
        <div>{`${item.updated_at.split("T")[0]}`}</div>
      );
      tempObjectCultivation.product = (
        <div>
          {`${item?.product?.product1?.plan?.cod_producto?.astr_descripcion}`}
        </div>
      );
      tempObjectCultivation.plan = (
        <div>{`${item?.product?.product1?.plan?.textPlan}`}</div>
      );
      tempObjectCultivation.status = <div>{`${item.estado}`}</div>;
      tempObjectCultivation.observations = <div></div>;
      tempDataTable.body.push(tempObjectCultivation);
      tempObjectCultivation = {};
    });
    setData(tempDataTable);
  };

  useEffect(() => {
    makeDataTable();
  }, [allCandidates]);

  useEffect(() => {
    localStorage.removeItem("oldPageData");
  }, []);

  return (
    <Animate type='pop'>
      <div className={GlobalStyle.buttonPosition}>
        <BackButton go='/vendedor/vida' />
      </div>
      <section className={GlobalStyle.paddingLeft}>
        <Content>
          <div style={{ padding: "15px 0px" }}>
            <p className={GlobalStyle.textTitle}>Vida</p>
          </div>
          <div style={{ paddingBottom: 20 }}>
            <div className={GlobalStyle.priceInputSection}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Número de documento:</label>
                <input
                  className={Input["input_number"]}
                  value={dataSeach.id}
                  name='id'
                  type='text'
                  onChange={handleInputChange}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: 10,
                }}
              >
                <label>Filtrar por fecha:</label>
                <DateRangePicker
                  onChange={handleDate}
                  value={dataSeach.date}
                  required
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Button color='blue' text='Buscar' action={handleSearchData} />
              </div>
            </div>
          </div>
          <div>
            {screenSize.dynamicWidth > 1024 ? (
              <Table
                id='table-Cotizaciones'
                search
                filterColumns={['name', 'ci', 'product']}
                show={[
                  "name",
                  "ci",
                  "date",
                  "product",
                  "plan",
                  "status",
                  "observations",
                ]}
                data={data}
                loading={Object.entries(data).length === 0 ? true : false}
                actions={{ edit: viewProduct }}
                hideActionsHeaders={true}
              />
            ) : (
              <Scrollbars
                style={{
                  width: "100%",
                  height: "600px",
                }}
              >
                <Table
                  id='table-Cotizaciones'
                  search
                  show={[
                    "name",
                    "ci",
                    "date",
                    "product",
                    "plan",
                    "status",
                    "observations",
                  ]}
                  data={data}
                  loading={Object.entries(data).length === 0 ? true : false}
                  actions={{ edit: viewProduct }}
                  hideActionsHeaders={true}
                />
              </Scrollbars>
            )}
          </div>
          <div style={{ padding: "20px 0px", textAlign: "right" }}>
            <ReactHtmlTableToExcel
              id='test-table-xls-button'
              className={Style.download}
              table='table-Cotizaciones'
              filename='Cotizaciones-vida'
              sheet='cotizaciones'
              buttonText='Descargar tabla'
            />
          </div>
        </Content>
      </section>
    </Animate>
  );
}
