import { Animate, Grid } from "components/lib";
import { colourStyles } from "css/globalStyles";
import DatePicker from "react-date-picker";
import { useForm } from "react-hook-form";
import Select from "react-select";
import "../../../../css/datePicker.css";
import Style from "../../../../css/globalStyle.module.scss";
import Spin from "../../agriculture/agricultureSeller.module.scss";

export function StepOne(props) {
  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const {
    client,
    handleInputChange,
    handleSelectChange,
    handleDate,
    isLoading,
    saveCandidate
  } = props;

  const onSubmit = (data) => {
    saveCandidate();
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const handleEmailValidation = (email) => {
    const isValid = isValidEmail(email);
    const validityChanged =
      (errors.email && isValid) || (!errors.email && !isValid);
    if (validityChanged) {
    }
    return isValid;
  };

  function disabledButton() {
    if (
      !client.nombre ||
      !client.apellido ||
      !client.fechaNacimiento ||
      !client.telefono ||
      !client.correo ||
      !client.frecuenciaPago
    ) {
      return true;
    } else {
      return false;
    }
  }

  return (
    <Animate type='pop'>
      <div style={{ textAlign: 'center', marginBottom: '40px' }}>
        <h2><b>Agregar información del candidato</b></h2>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <Grid cols='3'> */}
        <div className={Style.containerWithDateInput}>
          <div className={Style.containerInput}>
            <label>Nombre</label>
            <input
              onKeyDown={(event) => {
                if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              {...register("nombre", { required: true })}
              value={
                client.nombre.charAt(0).toUpperCase() +
                client.nombre.slice(1).toLowerCase()
              }
              name='nombre'
              type='text'
              onChange={handleInputChange}
              className={Style["input"]}
            />
            {errors.nombre?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
          <div className={Style.containerInput}>
            <label>Apellido</label>
            <input
              onKeyDown={(event) => {
                if (!/[ñáéíóúÑÁÉÍÓÚa-zA-Z\s]+$/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              {...register("apellido", { required: true })}
              value={
                client.apellido.charAt(0).toUpperCase() +
                client.apellido.slice(1).toLowerCase()
              }
              name='apellido'
              type='text'
              onChange={handleInputChange}
              className={Style["input"]}
            />
            {errors.apellido?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
          <div className={Style.containerInput}>
            <label style={{ paddingRight: 15 }}>
              Fecha de nacimiento
            </label>
            <DatePicker
              {...register("fechaNacimiento", {
                required: true,
                value: client.fechaNacimientoSinFormato,
              })}
              name='fechaNacimiento'
              locale='es'
              format='MM/dd/yyyy'
              onChange={(e) => {
                handleDate(e);
                setValue("fechaNacimiento", e);
              }}
              value={client.fechaNacimientoSinFormato}
            />
            {errors.fechaNacimiento?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </div>
        {/* </Grid> */}
        <Grid cols='3'>
          <div className={Style.containerInput}>
            <label>Teléfono/Celular</label>
            <input
              {...register("telefono", { required: true })}
              onKeyDown={(event) => {
                if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'ArrowLeft' || event.key === 'ArrowRight' || event.key === 'Tab') {
                  return;
                }
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={client.telefono}
              name='telefono'
              type='text'
              onChange={handleInputChange}
              maxLength={"10"}
              className={Style["input_number"]}
            />
            {errors.telefono?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
          <div className={Style.containerInput}>
            <label>Correo</label>
            <input
              {...register("correo", {
                required: true,
                validate: handleEmailValidation,
              })}
              value={client.correo}
              name='correo'
              type='text'
              onChange={handleInputChange}
              className={Style["input"]}
            // pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
            />
            {errors.correo?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
            {errors.correo?.type === "validate" && (
              <p style={{ color: "red" }}>Correo invalido</p>
            )}
          </div>
          <div className={Style.containerInput}>
            <label>Frecuencia de pago</label>
            <Select
              className='basic-single'
              classNamePrefix='select'
              //inputRef={ref}
              onChange={(e) => {
                handleSelectChange(e, "frecuenciaPago");
                //onChange(e.value);
              }}
              styles={colourStyles}
              options={[
                { label: "Anual", value: "A" },
                { label: "Mensual", value: "M" },
              ]}
              value={client.frecuenciaPago === "A" ? { label: "Anual", value: "A" }
                : client.frecuenciaPago === "M"
                  ? { label: "Mensual", value: "M" }
                  : null}
              isSearchable
              isLoading={isLoading}
              menuPosition='fixed'
              menuPlacement={"auto"}
              placeholder='Seleccionar'
            />
            {errors.frecuenciaPago?.type === "required" && (
              <p style={{ color: "red" }}>Este campo es requerido</p>
            )}
          </div>
        </Grid>
        <Grid cols='3'>
          <div>
            {!isLoading ? (
              <input
                disabled={disabledButton()}
                style={{
                  cursor: "pointer",
                  padding: "0.725em 3.7em",
                  backgroundColor:
                    disabledButton() ? "#455680" : "#0F265C",
                  color: "white",
                  borderRadius: "0.25em",
                  fontSize: "1em",
                  fontWeight: 600,
                  textAlign: "center",
                  textDecoration: "none",
                  marginTop: 24,
                  width: "256px",
                }}
                type='submit'
                value='Cotizar'
              />
            ) : (
              <div className={Spin.ldsDualRing}></div>
            )}
          </div>
        </Grid>
      </form>
    </Animate>
  );
}
