/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   noPadding: remove the padding
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/

import React from "react";
import { FormUp, FormDown } from "grommet-icons";

import {
  Accordion,
  AccordionPanel,
  Box,
  Heading,
  ThemeContext,
} from "grommet";
import { useState } from "react";

const richAccordionTheme = {
  accordion: {
    icons: {
      collapse: FormUp,
      expand: FormDown,
    },
    panel: {
      border: {
        side: "horizontal",
        size: "medium",
        color: "#FFFFFF",
      },
    },
  },
};

const RichPanel = ({ children, label }) => {
  const [hovering, setHovering] = useState(false);

  const renderPanelTitle = () => (
    <Box
      height={"64px"}
      direction='row'
      align='center'
      gap='small'
      pad={{ horizontal: "small" }}
    >
      <Heading level={4} color={hovering ? "dark-3" : "#95D4E9"}>
        {label}
      </Heading>
    </Box>
  );

  return (
    <AccordionPanel
      label={renderPanelTitle()}
      onMouseOver={() => setHovering(true)}
      onMouseOut={() => setHovering(false)}
      onFocus={() => setHovering(true)}
      onBlur={() => setHovering(false)}
    >
      {children}
    </AccordionPanel>
  );
};

export function TitleProduct(props) {

  return (
    <>
      <ThemeContext.Extend value={richAccordionTheme}>
        {props.whiteTitle && (
          <Heading level={3} margin='none' color='#0F265C' weight={700}>
            {props.whiteTitle}
          </Heading>
        )}
        {props.blackTitle && (
          <Accordion background={"#0F265C"}>
            <RichPanel label={props.blackTitle}>
              <Box
                background={"#FFFFFF"}
                pad={{
                  bottom: "medium",
                  horizontal: "small",
                  top: "small",
                }}
                gap='medium'
              >
                <Box gap='xsmall'>
                  {/* {...getCollapseProps()} */}
                  <div>
                    <div className='content'>{props.children}</div>
                  </div>
                </Box>
              </Box>
            </RichPanel>
          </Accordion>
        )}
        {/* <section className={titleStyle}>
          {props.whiteTitle && (
            <h2 className={Style.whiteTitle}>{props.whiteTitle}</h2>
          )}
          {props.blackTitle && (
            <div className='collapsible'>
              <div className='header' {...getToggleProps()}>
                {isExpanded ? (
                  <h2 className={Style.blackTitle}>{props.blackTitle}</h2>
                ) : (
                  <h2 className={Style.blackTitle}>{props.blackTitle}</h2>
                )}
              </div>
              <div {...getCollapseProps()}>
                <div className='content'>{props.children}</div>
              </div>
            </div>
          )}
        </section> */}
      </ThemeContext.Extend>
    </>
  );
}
