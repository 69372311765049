import React, { useState } from "react";
import Style from "./switch.module.scss";

export function SwitchProduct(props) {
  // state

  const id = props.id || 'toggleInput';
  const disabled = props.disabled || false;

  function toggle() {
    props.onChange(props.name, !props.checked);
  }

  return (
    <div className={Style.switch}>
      <span>
        <input
          type='checkbox'
          id={id}
          checked={props.checked}
          onChange={props.onChange}     
          disabled={disabled}     
        />
        <button
          className={Style.slider}
          type='button'
          onClick={toggle}
          disabled={disabled}     
        ></button>
      </span>
      <label htmlFor={id} onClick={toggle}>
        {props.label}
        {/* Change to {this.props.title} and you can set the label text in a higher level component */}
      </label>
    </div>
    // <div className={Style.switch}>
    //   <Label
    //     text={props.label}
    //     required={props.required}
    //     className={Style.label}
    //   />

    //   <div className={trackStyle} onClick={toggle}>
    //     <div className={handleStyle}></div>
    //   </div>
    // </div>
  );
}
