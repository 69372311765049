import React from "react";
import { Title } from "./title";
import Style from "../../../../../css/globalStyle.module.scss";

export function InformacionConyuge(props) {
  const { register, errors } = props;
  return (
    <div>
      <Title whiteTitle='Sección información del cónyuge / Conviviente del propuesto asegurado' />
      <div className={Style.inputContainerConyugue}>
        <div className={Style.inputContainerQuarterConyugue}>
          <label><b style={{ color: 'red' }}>*</b>&nbsp;Tipo de identificación</label>
          <select
            className={Style["select"]}
            {...register("tipoIdentificacionConyuge", { required: true })}
          >
            <option value='Cedula'>Cedula</option>
            <option value='Pasaporte'> Pasaporte</option>
          </select>
          {errors.tipoIdentificacionConyuge?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerQuarterConyugue}>
          <label><b style={{ color: 'red' }}>*</b>&nbsp;Número de identificación</label>
          <input
            tabIndex={-10}
            type='text'
            className={Style["input_number"]}
            {...register("numeroIdentificacionConyuge", { required: true })}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          {errors.numeroIdentificacionConyuge?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
      </div>
      <div className={Style.inputContainerConyugue}>
        <div className={Style.inputContainerQuarterConyugue}>
          <label><b style={{ color: 'red' }}>*</b>&nbsp;Primer apellido</label>
          <input
            tabIndex={-9}
            type='text'
            className={Style["input"]}
            {...register("primerApellidoConyuge", {
              required: true,
              maxLength: 255,
              pattern: `//^[A-Za-z]+$//i`,
            })}
          />
          {errors.primerApellidoConyuge?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerQuarterConyugue}>
          <label><b style={{ color: 'red' }}>*</b>&nbsp;Segundo apellido</label>
          <input
            tabIndex={-8}
            type='text'
            className={Style["input"]}
            {...register("segundoApellidoConyuge", {
              required: true,
              maxLength: 255,
              pattern: `//^[A-Za-z]+$//i`,
            })}
          />
          {errors.segundoApellidoConyuge?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerQuarterConyugue}>
          <label><b style={{ color: 'red' }}>*</b>&nbsp;Primer nombre</label>
          <input
            tabIndex={-7}
            type='text'
            className={Style["input"]}
            {...register("primerNombreConyuge", {
              required: true,
              maxLength: 255,
              pattern: `//^[A-Za-z]+$//i`,
            })}
          />
          {errors.primerNombreConyuge?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
        <div className={Style.inputContainerQuarterConyugue}>
          <label><b style={{ color: 'red' }}>*</b>&nbsp;Segundo nombre</label>
          <input
            tabIndex={-6}
            type='text'
            className={Style["input"]}
            {...register("segundoNombreConyuge", {
              required: true,
              maxLength: 255,
              pattern: `//^[A-Za-z]+$//i`,
            })}
          />
          {errors.segundoNombreConyuge?.type === "required" && (
            <p style={{ color: "red" }}>Este campo es requerido</p>
          )}
        </div>
      </div>
    </div>
  );
}
