import React from "react";
import { Animate, Grid } from "components/lib";
import Select from "react-select";
import Style from "../agricultureSeller.module.scss";
import DatePicker from "react-date-picker";
import { Controller, useForm } from "react-hook-form";
import "./step.module.scss";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useEffect } from "react";
import { colourStyles } from "../../../../css/globalStyles";
import GlobalStyle from "../../../../css/globalStyle.module.scss";

export function StepTwo(props) {
  const { register, formState: { errors }, handleSubmit, control } = useForm();
  const { cultivation, setCultivation, options, generatePrice, handleInputCultivationChange,
    handleCityCultivationChange, handleChangePlanSelected, handleSelectCultivationChange, dataCatalog } = props;
  const [tasaVigencia, setDataVigencia] = useState();
  const [errorMessage, setErrorMessage] = useState({ superficie: '' });
  const [optionsCultivos, setOptionsCultivos] = useState([]);

  const handleDate = (date, name) => {
    let tempDate = parseDate(date);
    setCultivation({
      ...cultivation,
      [name]: tempDate,
      [`${name}SinFormato`]: date,
    });
  };

  const limit = 9999.99
  const handleOnValueChange = (value, _, values, nameState) => {
    if (!value) {
      setCultivation({ ...cultivation, [nameState]: '' });
      return;
    }

    if (Number(value) > limit) {
      setErrorMessage('Valor maximo superado')
      return;
    }

    if (Number(value) < 0) {
      setErrorMessage('No se admiten valores negativos')
      return;
    }

    setCultivation({ ...cultivation, [nameState]: value });
  };

  const handleSelectCultivation = () => {
    for (const [key, value] of Object.entries(options.cultivos)) {
      if (cultivation.cultivoAsegurar === value.value) {
        let vigencia = value.vigencia;
        setDataVigencia(vigencia);
      }
    }
  };

  const parseDate = (str) => {
    let date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const onSubmit = (data) => {
    generatePrice();
  };

  useEffect(() => {
    let temp;
    if (cultivation?.cultivoAsegurar !== undefined && cultivation?.cultivoAsegurar !== null && cultivation?.cultivoAsegurar !== "") {
      let tmpCatalogCultivo = dataCatalog.data.find(ele => ele?.name === "cultivos")
      if (!tmpCatalogCultivo) {
        console.log('No "cultivos" found in the catalog.');
        return;
      }
      if (tmpCatalogCultivo) {
        temp = tmpCatalogCultivo.detail.detail.filter(
          (item) => item?.nombre_del_cultivo === cultivation?.cultivoAsegurar
        );
      }
    }
  }, [cultivation]);

  //Filtering cultivos
  const filterCultivosByRol = async () => {
    setOptionsCultivos(options.cultivos);
  }

  useEffect(() => {
    let tmpVar = cultivation;
    delete tmpVar['plan'];
    setCultivation(tmpVar);
    //filterCultivos
    const fetchData = async () => {
      await filterCultivosByRol(options.cultivos);
    }
    fetchData().catch(console.error);
  }, [cultivation?.cultivoAsegurar]);


  const [filteredCultivationPlans, setFilteredCultivationPlans] = useState([]);

  useEffect(() => {
    if (cultivation?.optionsCultivation?.cultivationPlan) {
      const user = JSON.parse(localStorage.getItem("profile"));
      const isSuperAdmin = user.data.flag_uno === true;
      if (isSuperAdmin) {
        setFilteredCultivationPlans(cultivation?.optionsCultivation?.cultivationPlan);
      } else {

        const astr_nombre_asesor = user.data.intermediary.sponsor.astr_nombre_asesor || user.data.intermediary.broker.astr_nombre_asesor || user.data.intermediary.sdp.astr_nombre_asesor;
        let tmpPlans = cultivation?.optionsCultivation?.cultivationPlan;
        const plansWithFuerzaVenta = tmpPlans.filter(plan =>
          plan.fuerza_venta !== undefined &&
          plan.fuerza_venta !== null && (
            (plan.fuerza_venta.broker?.length > 0 && plan.fuerza_venta.broker.some(broker => broker.astr_nombre_asesor === astr_nombre_asesor)) ||
            (plan.fuerza_venta.sponsor?.length > 0 && plan.fuerza_venta.sponsor.some(sponsor => sponsor.astr_nombre_asesor === astr_nombre_asesor)) ||
            (plan.fuerza_venta.sdp?.length > 0 && plan.fuerza_venta.sdp.some(sdp => sdp.astr_nombre_asesor === astr_nombre_asesor))
          )
        );
        setFilteredCultivationPlans(plansWithFuerzaVenta);
      }

    }
  }, [cultivation.optionsCultivation.cultivationPlan]);

  return (
    <Animate type='pop'>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <section>
            <span className={Style.titleContainer}>
              <h2 className={Style["title"]}>Datos del predio</h2>
              <p>
                <span className={Style.fontRequired}>*</span> Campos requeridos
              </p>
            </span>
          </section>
          <section>
            <Grid cols='3'>
              <div className={Style.containerInput}>
                <label>
                  Provincia <span className={Style.fontRequired}>*</span>
                </label>
                <Controller
                  control={control}
                  name='provincia'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      styles={colourStyles}
                      options={options && options.provincias}
                      isSearchable
                      isLoading={options ? false : true}
                      menuPosition='fixed'
                      menuPlacement={"auto"}
                      onChange={(e) => {
                        onChange(e.value);
                        handleSelectCultivationChange(e);
                        handleCityCultivationChange();
                      }}
                    />
                  )}
                />
                {errors.provincia?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
              <div className={Style.containerInput}>
                <label>
                  Recinto/Comunidad{" "}
                  <span className={Style.fontRequired}>*</span>
                </label>
                <Controller
                  control={control}
                  name='recinto'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <input
                      value={cultivation.recinto}
                      name='recinto'
                      type='text'
                      maxLength={250}
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleInputCultivationChange(e);
                      }}
                      className={Style["input"]}
                    />
                  )}
                />
                {errors.recinto?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
              <div className={Style.containerInput}>
                <label>
                  Coordenadas X <span className={Style.fontRequired}>*</span>
                </label>
                <Controller
                  control={control}
                  name='coordenadasX'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <input
                      value={cultivation.coordenadasX}
                      name='coordenadasX'
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      type='number'
                      maxLength={9}
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleInputCultivationChange(e);
                      }}
                      className={Style["input_number"]}
                    />
                  )}
                />
                {errors.coordenadasX?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
            </Grid>
            <Grid cols='3'>
              {options.ciudadesCultivation.length > 0 ||
                cultivation.provincia === "" ? (
                <div className={Style.containerInput}>
                  <label>
                    Cantón <span className={Style.fontRequired}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name='canton'
                    rules={{ required: true }}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select isDisabled={options && options.ciudadesCultivation.length === 0 ? true : false}
                        className='basic-single'
                        classNamePrefix='select'
                        styles={colourStyles}
                        options={options && options.ciudadesCultivation}
                        isSearchable
                        isLoading={options ? false : true}
                        menuPosition='fixed'
                        menuPlacement={"auto"}
                        onChange={(e) => {
                          onChange(e.value);
                          handleSelectCultivationChange(e);
                          handleCityCultivationChange();
                        }}
                      />
                    )}
                  />
                  {errors.canton?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
                </div>
              ) : (
                <div className={Style.ldsDualRing}></div>
              )}
              <div className={Style.containerInput}>
                <label>
                  Calle/camino/referencia{" "}
                  <span className={Style.fontRequired}>*</span>
                </label>
                <Controller
                  control={control}
                  name='calle'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <input
                      value={cultivation.calle}
                      name='calle'
                      type='text'
                      maxLength={250}
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleInputCultivationChange(e);
                      }}
                      className={Style["input"]}
                    />
                  )}
                />
                {errors.calle?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
              <div className={Style.containerInput}>
                <label>
                  Coordenadas Y <span className={Style.fontRequired}>*</span>
                </label>
                <Controller
                  control={control}
                  name='coordenadasY'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <input
                      value={cultivation.coordenadasY}
                      name='coordenadasY'
                      onInput={(e) => {
                        if (e.target.value.length > e.target.maxLength)
                          e.target.value = e.target.value.slice(
                            0,
                            e.target.maxLength
                          );
                      }}
                      type='number'
                      maxLength={9}
                      onChange={(e) => {
                        onChange(e.target.value);
                        handleInputCultivationChange(e);
                      }}
                      className={Style["input_number"]}
                    />
                  )}
                />
                {errors.coordenadasY?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
            </Grid>
            <Grid cols='3'>
              {options.parroquiasCultivation.length > 0 ||
                cultivation.canton === "" ? (
                <div className={Style.containerInput}>
                  <label>
                    Parroquia <span className={Style.fontRequired}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name='parroquia'
                    rules={{ required: true }}
                    render={({ field: { onChange, value, name, ref } }) => (
                      <Select
                        isDisabled={options && options.parroquiasCultivation.length === 0 ? true : false}
                        className='basic-single'
                        classNamePrefix='select'
                        styles={colourStyles}
                        options={options && options.parroquiasCultivation}
                        isSearchable
                        isLoading={options ? false : true}
                        menuPosition='fixed'
                        menuPlacement={"auto"}
                        onChange={(e) => {
                          onChange(e.value);
                          handleSelectCultivationChange(e);
                        }}
                      />
                    )}
                  />
                  {errors.parroquia?.type === "required" && (
                    <p style={{ color: "red" }}>Este campo es requerido</p>
                  )}
                </div>
              ) : (
                <div className={Style.ldsDualRing}></div>
              )}
            </Grid>
          </section>
          <section>
            <span className={Style.titleContainer}>
              <h2 className={Style["title"]}>Datos del cultivo</h2>
              <p>
                <span className={Style.fontRequired}>*</span> Campos requeridos
              </p>
            </span>
          </section>
          <section>
            <Grid cols='3'>
              <div className={Style.containerInput}>
                <label>
                  Cultivo a asegurar{" "}
                  <span className={Style.fontRequired}>*</span>
                </label>
                <Controller
                  control={control}
                  name='cultivo'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      placeholder={"Seleccionar"}
                      styles={colourStyles}
                      options={optionsCultivos}
                      isSearchable
                      isLoading={options ? false : true}
                      menuPosition='fixed'
                      menuPlacement={"auto"}
                      onChange={(e) => {
                        onChange(e.value);
                        handleSelectCultivationChange(e);
                        handleSelectCultivation(e);
                      }}
                    />
                  )}
                />
                {errors.cultivo?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
              <div className={Style.containerInput}>
                <label>
                  Tipo de semilla <span className={Style.fontRequired}>*</span>
                </label>
                <Controller
                  control={control}
                  name='tipoSemilla'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      styles={colourStyles}
                      options={options && options.tipoSemilla}
                      isSearchable
                      isLoading={options ? false : true}
                      menuPosition='fixed'
                      menuPlacement={"auto"}
                      placeholder={"Seleccionar"}
                      onChange={(e) => {
                        onChange(e.value);
                        handleSelectCultivationChange(e);
                      }}
                    />
                  )}
                />
                {errors.tipoSemilla?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
            </Grid>
            <div className={Style.containerWithDateInput}>
              <div className={Style.containerInput}>
                <label>
                  Superficie a asegurar{" "}
                  <span className={Style.fontRequired}>*</span>
                </label>
                <div className={Style.prefixGroup}>
                  <CurrencyInput
                    {...register("superficie", {
                      required: true,
                      min: 0,
                      value: cultivation.superficie,
                    })}
                    id='superficie'
                    name='superficie'
                    decimalsLimit={2}
                    decimalSeparator="."
                    groupSeparator=" "
                    onValueChange={(value, _, values) => {
                      handleOnValueChange(value, _, values, "superficie");
                    }}
                    className={GlobalStyle["input_numberFinanciero"]}
                    value={cultivation.superficie} />

                  <p style={{ color: "red", marginLeft: 10 }}>
                    {errorMessage.superficie !== "" && errorMessage.superficie}
                  </p>

                  <span className={Style.sufixGroup}>ha</span>
                </div>
              </div>
              <div className={Style.containerInput}>
                <label>
                  Costos directos de producción (ha){" "}
                  <span className={Style.fontRequired}>*</span>
                </label>
                <CurrencyInput
                  {...register("costoProduccionTotal", {
                    required: true,
                    min: 0,
                    value: cultivation.costoProduccionTotal,
                  })}
                  id='costoProduccionTotal'
                  name='costoProduccionTotal'
                  decimalsLimit={2}
                  decimalSeparator="."
                  groupSeparator=" "
                  onValueChange={(value, _, values) => {
                    handleOnValueChange(value, _, values, "costoProduccionTotal");
                  }}
                  className={GlobalStyle["input_numberFinanciero"]}
                  value={cultivation.costoProduccionTotal}
                />

                <p style={{ color: "red", marginLeft: 10 }}>
                  {errorMessage.costoProduccionTotal !== "" && errorMessage.costoProduccionTotal}
                </p>

              </div>
              <div className={Style.containerInput}>
                <label>
                  Fecha de siembra/inicio de vigencia{" "}
                  <span className={Style.fontRequired}>*</span>
                </label>
                <Controller
                  control={control}
                  name='fechaSiembraVigencia'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <div>
                      <DatePicker
                        {...register("fechaSiembraVigencia", {
                          required: true,
                        })}
                        name='fechaSiembraVigencia'
                        onChange={(e) => {
                          handleDate(e, "fechaSiembraVigencia");
                          onChange(e);
                        }}
                        value={cultivation.fechaSiembraVigenciaSinFormato}
                      />
                    </div>
                  )}
                />
                {errors.fechaSiembraVigencia?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
            </div>
            <Grid cols='3'>
              <div className={Style.containerInput}>
                <label>
                  Dispone de riego <span className={Style.fontRequired}>*</span>
                </label>
                <Controller
                  control={control}
                  name='riego'
                  rules={{ required: true }}
                  render={({ field: { onChange, value, name, ref } }) => (
                    <Select
                      className='basic-single'
                      classNamePrefix='select'
                      styles={colourStyles}
                      options={[
                        { value: "Si", label: "Si" },
                        { value: "No", label: "No" },
                      ]}
                      isSearchable
                      isLoading={options ? false : true}
                      menuPosition='fixed'
                      menuPlacement={"auto"}
                      placeholder={"Seleccionar"}
                      onChange={(e) => {
                        e.type = "riego";
                        onChange(e.value);
                        handleSelectCultivationChange(e);
                      }}
                    />
                  )}
                />
                {errors.riego?.type === "required" && (
                  <p style={{ color: "red" }}>Este campo es requerido</p>
                )}
              </div>
              <div className={Style.containerInput}>
                <label>Tipo de riego</label>
                <Select
                  className='basic-single'
                  classNamePrefix='select'
                  styles={colourStyles}
                  options={options && options.tipoRiego}
                  isSearchable
                  isLoading={options ? false : true}
                  menuPosition='fixed'
                  menuPlacement={"auto"}
                  onChange={handleSelectCultivationChange}
                  placeholder={"Seleccionar"}
                />
              </div>
            </Grid>
          </section>
          <section>
            <span style={{ display: "flex", justifyContent: "center" }}>
              <h2 className={Style["title"]}>Planes</h2>
            </span>
          </section>
          <section>
            <div className={Style.containerWithDateInput}>
              <div className={Style.containerInput}>
                <h2
                  style={{
                    fontWeight: "700",
                    paddingBottom: "10px",
                    color: "#0f265c",
                    textAlign: "center",
                  }}
                >
                  Planes <span className={Style.fontRequired}>*</span>
                </h2>
                {
                  filteredCultivationPlans && filteredCultivationPlans.map(
                    (plan, idx) => {
                      return (
                        <div
                          key={`planAgroStepTwo${idx}`}
                          style={{ padding: 10 }}>
                          <input
                            type='radio'
                            value={idx}
                            name='plan'
                            checked={cultivation?.plan?.namePlan === plan.namePlan}
                            onChange={(e) => {
                              handleSelectCultivation();
                              handleChangePlanSelected(e, plan.plan);
                            }}
                          />
                          <label style={{ paddingLeft: 20 }}>
                            {`${plan.plan}`}. {`${plan.namePlan}`}
                          </label>
                        </div>
                      );
                    }
                  )}
              </div>
              <div className={Style.containerInput}>
                <h2
                  style={{
                    fontWeight: "700",
                    paddingBottom: "10px",
                    color: "#0f265c",
                    textAlign: "center",
                  }}
                >
                  Coberturas
                </h2>
                {cultivation?.plan?.coverages?.map((plan, idx) => {
                  return (
                    <div
                      key={`planCoverageStepTwo${idx}`}
                      style={{ padding: 10 }}
                    >
                      <label
                        style={{ paddingLeft: 20 }}
                      >- {`${plan.astr_cob_descrip_print}`}</label>
                    </div>
                  );
                })}
              </div>
              <div className={Style.containerInput}>
                <h2
                  style={{
                    fontWeight: "700",
                    paddingBottom: "10px",
                    color: "#0f265c",
                    textAlign: "center",
                  }}>
                  Tasa %
                </h2>
                <div>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "25px",
                      color: "#0f265c",
                      border: "2px solid #0F265C",
                      borderRadius: "5px",
                      padding: "10px",
                      width: "50%",
                      fontFamily: "'Roboto',sans-serif",
                      margin: "0 auto",
                      textAlign: "center",
                    }}>

                    {`${cultivation?.plan?.tax === undefined
                      ? 0
                      : ((cultivation?.plan?.tax * tasaVigencia) / 365).toFixed(2)
                      } %`}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div
              style={{
                backgroundColor: "#d1d1d1",
                margin: "15px 0px",
                padding: "30px 10px",
              }}>
              <p>
                El presente documento no significa aceptación del riesgo, la
                emisión de la póliza está sujeta a verificación y análisis de la
                información entregada.
              </p>
            </div>
            <div>
              <input
                className={Style["submit"]}
                type='submit'
                value='Cotizar'
                onClick={generatePrice}
              />
            </div>
          </section>
        </div>
      </form>

    </Animate>
  );

}
