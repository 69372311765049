import React, { useContext, useEffect, useState } from "react";
import {
  Animate,
  Content,
  ProgressSteps,
  useAPI,
  useNavigate,
} from "components/lib";
import {
  getBranchXProduct,
  getGender,
  getPerson,
  getTypeId,
  getTypePaid,
  lstOfac,
  Submit,
  postFormSave,
} from "components/bridges/BridgeServices";
import { ViewContext } from "components/lib";
import { StepOne } from "./steps/stepOne";
import { StepTwo } from "./steps/stepTwo";
import { StepThree } from "./steps/stepThree";
import Style from "../../../css/globalStyle.module.scss";
import { BackButton } from "components/backButton/button";
import useViewPort from "components/hooks/useViewPort";
import { useDataContext } from "../../../context/DataContext";

export function CandidateLife(props) {
  const navigate = useNavigate();
  const productsLife = useAPI("/api/product/life");
  const viewContext = useContext(ViewContext);
  const [options, setOptions] = useState({
    personeria: [],
    tipoId: [],
    genero: [],
    tipoPago: [],
    tipoPlan: [],
    planOptions: [],
    branchProduct: {},
    estadoMigratorio: [],
  });
  const [screenSize] = useViewPort();
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState();
  const [uuidPlan, setUuidPlan] = useState([]);
  const [uuidAlternative, setUuidAlternative] = useState([]);
  const [step, setStep] = useState(0);
  const [dataProjection, setDataProjection] = useState();
  const [userId, setUserId] = useState("");
  const [prices, setPrices] = useState({});
  const [idForm, setIdForm] = useState("");
  const [stepTwo, setStepTwo] = useState();
  const [limitAge, setLimitAge] = useState({ isValid: false, min: 0, max: 0 });
  const [client, setClient] = useState({
    asesor: "",
    personeria: "N",
    tipoId: "",
    tipoDocumento: "",
    numeroDocumento: "",
    primerNombre: "",
    segundoNombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    fechaNacimiento: "",
    fechaNacimientoSinFormato: "",
    genero: "",
    telefono: "",
    correo: "",
    estadoMigratorio: "",
    expedicionPasaporte: "",
    expedicionPasaporteSinFormato: "",
    ingresoPais: "",
    ingresoPaisSinFormato: "",
    caducidadPasaporte: "",
    caducidadPasaporteSinFormato: "",
    typePaid: "",
    smoker: "",
    status: "",
    value: 0,
  });
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  ////////////////////////////////////////////////////////////////////////////

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (
      name === "correo" ||
      name === "apellidoPaterno" ||
      name === "segundoNombre" ||
      name === "primerNombre" ||
      name === "apellidoMaterno"
    ) {
      setClient({ ...client, [name]: value.toUpperCase() });
    } else {
      setClient({ ...client, [name]: value });
    }
  };

  const handleDate = (date) => {
    let tempDate = parseDate(date);
    setClient({
      ...client,
      fechaNacimiento: tempDate,
      fechaNacimientoSinFormato: date,
    });
  };

  const handleDatePass = (date) => {
    let tempDate = parseDate(date);
    setClient({
      ...client,
      expedicionPasaporte: tempDate,
      expedicionPasaporteSinFormato: date,
    });
  };

  const handleIncomingDate = (date) => {
    let tempDate = parseDate(date);
    setClient({
      ...client,
      ingresoPais: tempDate,
      ingresoPaisSinFormato: date,
    });
  };

  const handleExpireDate = (date) => {
    let tempDate = parseDate(date);
    setClient({
      ...client,
      caducidadPasaporte: tempDate,
      caducidadPasaporteSinFormato: date,
    });
  };

  const parseDate = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  };

  const handleSelectChange = (event) => {
    //label
    const { value, type } = event;
    setClient({ ...client, [type]: value });
  };

  const handleChangePlanSelected = (e, idx) => {
    let tempPlanUuid = uuidPlan;
    /*let tempCoverages = [];
    options.branchProduct.map((branch) => {
      if (branch.astr_id_producto === plan.cod_producto.astr_id_producto) {
        branch.coberturas.map((cobertura) => {
          plan.planes[0][`plan${idx + 1}`].values.map((itemPlan) => {
            if (cobertura.astr_codigo_cob === itemPlan)
              tempCoverages.push({
                ...cobertura,
                label: cobertura.astr_cob_descrip_print,
                value: cobertura.astr_codigo_cob,
              });
          });
        });
      }
    });*/
    tempPlanUuid.push(plan.planes[0][`plan${idx + 1}`].uuid);
    setPlan({
      ...plan,
      //alternativesSelected: tempCoverages,
      planSelected: plan.planes[0][`plan${idx + 1}`],
      textPlan: `plan${idx + 1}`,
      uuidPlanSelected: tempPlanUuid,
    });
  };

  const handleChangeSelectOptions = (event) => {
    if (event.value.length > 1) {
      setClient({ ...client, typePlan: event.value });
    } else {
      setClient({ ...client, smoker: event.value });
    }
  };

  const handleChangeAlternativeSelected = (e, idx) => {
    let tempValueAlterntive = [];
    let tempUuidAlternativaSelected = "";
    let tempAlternativeUuid = uuidAlternative;
    plan.valores_asegurados[plan.textPlan].map((item) => {
      tempValueAlterntive.push(item.alternativas[idx].valor_alternativa);
      tempUuidAlternativaSelected = item.alternativas[idx].uuidAlternativa;
    });

    tempAlternativeUuid.push(tempUuidAlternativaSelected);
    // Sorting planes
    let tempCoverages = [];
    options.branchProduct.map((branch) => {
      if (branch.astr_id_producto === plan.cod_producto.astr_id_producto) {
        branch.coberturas.map((cobertura) => {
          plan.planes[0][findPlanKeyByUuid(plan)].values.map((itemPlan) => {
            if (cobertura.astr_codigo_cob === itemPlan)
              tempCoverages.push({
                ...cobertura,
                label: cobertura.astr_cob_descrip_print,
                value: cobertura.astr_codigo_cob,
              });
          });
        });
      }
    });
    let tempCoveragesV2 = [];
    for (let row of plan.valores_asegurados[findPlanKeyByUuid(plan)]) {
      let findedCoverage = tempCoverages.find(ele => ele?.astr_codigo_cob === row?.astr_codigo_cob);
      if (findedCoverage) {
        tempCoveragesV2.push(findedCoverage);
      }
    }
    setPlan({
      ...plan,
      alternativesSelected: tempCoveragesV2,
      valuesSelected: tempValueAlterntive,
      alternativeNumber: idx + 1,
      textAlternativa: `alternativa${idx + 1}`,
      uuidAlternativaSelected: tempAlternativeUuid,
    });
  };

  function findPlanKeyByUuid(planObject) {
    // Extract uuidPlanSelected from the object (always an array with one position)
    const uuidPlanSelected = planObject?.uuidPlanSelected?.[0];

    // Check if uuidPlanSelected exists
    if (!uuidPlanSelected) {
      console.error("No uuidPlanSelected found.");
      return null;
    }

    // Iterate over the planes array to find the key with matching uuid
    for (let plan of planObject?.planes || []) {
      // Check each key in the plan object
      for (let key in plan) {
        if (plan[key]?.uuid === uuidPlanSelected) {
          return key;  // Return the matching key
        }
      }
    }

    console.error("No matching plan key found for uuid:", uuidPlanSelected);
    return null;
  }

  const handleSelectPlan = (event) => {
    let tempOptions = { ...options };
    tempOptions.tipoPlan = [];
    if (event.detail.cod_producto.tipo.includes("nivelada")) {
      tempOptions.tipoPlan.push({
        value: "nivelada",
        label: "nivelada",
      });
    }
    if (event.detail.cod_producto.tipo.includes("creciente")) {
      tempOptions.tipoPlan.push({
        value: "creciente",
        label: "creciente",
      });
    }

    getMinMaxAge(event.detail);
    setPlan(event.detail);
    setOptions(tempOptions);
  };

  const getMinMaxAge = (itemPlan) => {
    let tempEdad = calcularEdad(client.fechaNacimientoSinFormato);
    let tempLimitAge = limitAge;
    tempLimitAge.min = itemPlan.cod_producto.aint_edad_min;
    tempLimitAge.max = itemPlan.cod_producto.aint_edad_max;
    if (tempEdad < tempLimitAge.min || tempEdad > tempLimitAge.max) {
      tempLimitAge.isValid = true;
    } else {
      tempLimitAge.isValid = false;
    }
    setLimitAge(tempLimitAge);
  };

  const calcularEdad = (fecha) => {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    return edad;
  };

  const getOptions = async () => {
    try {
      let tempOptions = {
        ...options,
      };

      const respIdV2 = localStorage.getItem('typeid')
      let respIdJson;
      if (respIdV2) {
        respIdJson = JSON.parse(respIdV2)?.data
      }
      const respPersonV2 = localStorage.getItem('person')
      let respPersonJson;
      if (respPersonV2) {
        respPersonJson = JSON.parse(respPersonV2)?.data
      }
      const respGenderV2 = localStorage.getItem('gender')
      let respGenderJson;
      if (respGenderV2) {
        respGenderJson = JSON.parse(respGenderV2)?.data
      }
      const respTypePaidV2 = localStorage.getItem('typePaid')
      let respTypePaidJson;
      if (respTypePaidV2) {
        respTypePaidJson = JSON.parse(respTypePaidV2)?.data
      }

      const respId = state?.typeid?.data ? state.typeid.data : respIdJson ? respIdJson : await getTypeId();
      const respPerson = state?.person?.data ? state.person.data : respPersonJson ? respPersonJson : await getPerson();
      const respGender = state?.gender?.data ? state.gender.data : respGenderJson ? respGenderJson : await getGender();
      const respTypePaid = state?.typePaid?.data ? state.typePaid.data : respTypePaidJson ? respTypePaidJson : await getTypePaid();

      respPerson.map((item) => {
        if (item.astr_descripcion !== "Jurídica") {
          tempOptions.personeria.push({
            value: item.astr_id,
            label: item.astr_descripcion,
            type: "personeria",
          });
        }
      });
      respId.map((item) => {
        tempOptions.tipoId.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "tipoId",
        });
      });
      respGender.map((item) => {
        tempOptions.genero.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          type: "genero",
        });
      });
      respTypePaid.map((item) => {
        tempOptions.tipoPago.push({
          value: item.astr_id,
          label: item.astr_descripcion,
        });
      });

      let obj = {
        ramo: "20",
        ahorro: "S",
      };
      const respBranchXProductV2 = localStorage.getItem('branchXProducts')
      let respBranchXProductJson;
      if (respBranchXProductV2) {
        respBranchXProductJson = JSON.parse(respBranchXProductV2)?.data
      }
      const respBranchXProduct = state?.branchXProducts?.data ? state.branchXProducts.data :
        respBranchXProductJson ? respBranchXProductJson : await getBranchXProduct(obj);
      tempOptions.branchProduct = respBranchXProduct;
      setOptions(tempOptions);
    } catch (error) {
      viewContext.handleError(error);
    }
  };

  const setDataOptions = () => {
    let tempPlanOptions = { ...options };
    productsLife.data.map((item) => {
      tempPlanOptions.planOptions.push({
        value: item.detail.cod_producto.astr_id_producto,
        label: item.detail.cod_producto.astr_descripcion,
        detail: item.detail,
      });
    });
    setOptions(tempPlanOptions);
  };

  const verificarCedula = (ci) => {
    var isNumeric = true;
    var total = 0,
      individual;

    for (var position = 0; position < 10; position++) {
      // Obtiene cada posición del número de cédula
      // Se convierte a string en caso de que 'ci' sea un valor numérico
      individual = ci.toString().substring(position, position + 1)

      if (isNaN(individual)) {
        return { code: false, message: `No puede ingresar caracteres: ${ci}` };
        isNumeric = false;
        break;
      } else {
        // Si la posición es menor a 9
        if (position < 9) {
          // Si la posición es par, osea 0, 2, 4, 6, 8.
          if (position % 2 == 0) {
            // Si el número individual de la cédula es mayor a 5
            if (parseInt(individual) * 2 > 9) {
              // Se duplica el valor, se obtiene la parte decimal y se aumenta uno 
              // y se lo suma al total
              total += 1 + ((parseInt(individual) * 2) % 10);
            } else {
              // Si el número individual de la cédula es menor que 5 solo se lo duplica
              // y se lo suma al total
              total += parseInt(individual) * 2;
            }
            // Si la posición es impar (1, 3, 5, 7)
          } else {
            // Se suma el número individual de la cédula al total
            total += parseInt(individual);
          }
        }
      }
    }

    if ((total % 10) != 0) {
      total = (total - (total % 10) + 10) - total;
    } else {
      total = 0;
    }


    if (isNumeric) {
      // El total debe ser igual al último número de la cédula
      // La cédula debe contener al menos 10 dígitos
      if (ci.toString().length != 10) {
        return { code: false, message: `La c\u00E9dula debe ser de: 10 d\u00EDgitos: ${ci}` };
      }

      // El número de cédula no debe ser cero
      if (parseInt(ci, 10) == 0) {
        alert("La c\u00E9dula ingresada no puede ser cero.");
        return { code: false, message: `La c\u00E9dula ingresada no puede ser cero: ${ci}` };
      }

      // El total debe ser igual al último número de la cédula
      if (total != parseInt(individual)) {
        return { code: false, message: `La c\u00E9dula ingresada no es v\u00E1lida: ${ci}` };
      }

      return { code: true, message: `La c\u00E9dula ingresada es v\u00E1lida: ${ci}` };
    }

    // Si no es un número  
    return { code: true, message: `El dato solo puede contener numeros: ${ci}` };
  }

  const validateRUC = (ci) => {
    let numero = ci;
    let numeroProvincias = 24;

    /* Verifico que el campo no contenga letras */
    let ok = 1;
    for (let i = 0; i < numero.length && ok == 1; i++) {
      let n = parseInt(numero.charAt(i));
      if (isNaN(n)) ok = 0;
    }
    if (ok == 0) {
      return { code: false, message: `No puede ingresar caracteres: ${ci}` };
    }

    if (numero.length < 13) {
      return {
        code: false,
        message: `El número ingresado no es válido (logintud<13): ${ci}`,
      };
    }

    /* Los primeros dos digitos corresponden al codigo de la provincia */
    let provincia = numero.substr(0, 2);
    let provinciaInt = parseInt(provincia, 10);
    if (provinciaInt < 1 || provinciaInt > numeroProvincias) {
      return {
        code: false,
        message: `El código de la provincia (dos primeros dígitos) es inválido: ${ci}`,
      };
    }
    /* El tercer dígito es un número menor a 6 (0,1,2,3,4,5). ó 6(publico) ó 9(juridico o extranjero)*/
    let tercerDigito = parseInt(numero.charAt(2));
    if (tercerDigito === 6 || tercerDigito === 7 || tercerDigito === 8)
      return {
        code: false,
        message: `Tercer dígito inválido: ${ci} (${tercerDigito})`,
      };

    return { code: true, message: `RUC válido:${tercerDigito}` };
  };

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  const validateClient = async () => {
    setIsLoading(true);
    if (client.tipoId === "01") {
      if (!(await evaluateCandidatePass())) {
        viewContext.notification.show("Campos vacíos", "error");
        setIsLoading(false);
        return;
      }
    } else {
      if (!(await evaluateCandidate())) {
        viewContext.notification.show("Campos vacíos", "error");
        setIsLoading(false);
        return;
      }
    }
    //Setting Asesor Name
    const user = JSON.parse(localStorage.getItem("user"));
    client.asesor = user.name;
    //Check ci/ruc validation
    let respCiRucValidation = {};
    if (client.tipoId === "00")
      respCiRucValidation = verificarCedula(client.numeroDocumento);
    if (client.tipoId === "02")
      respCiRucValidation = validateRUC(client.numeroDocumento);
    if (!respCiRucValidation.code && client.tipoId !== "01") {
      viewContext.notification.show(respCiRucValidation.message, "error");
      setIsLoading(false);
      return;
    }
    let flagIsValidEmail = isValidEmail(client.correo);
    if (!flagIsValidEmail) {
      viewContext.notification.show(
        `Correo no válido: ${client.correo}`,
        "error"
      );
      setIsLoading(false);
      return;
    }
    //Check if candidate is adult
    // let curAge = calcularEdad(client.fechaNacimiento);
    // if (curAge < 18 || curAge > 55) {
    //   viewContext.notification.show(
    //     `Edad candidato no permitida: ${curAge}`,
    //     "error"
    //   );
    //   setIsLoading(false);
    //   return;
    // }
    //Send to ListasOfac service
    const dataOfac = JSON.parse(localStorage.getItem("loginListasOfac"));
    let objUserOfac = {
      resource: "/rest/jwt/listasofacext",
      data: {
        tipoCliente: client.personeria,
        tipoId: client.tipoId,
        cedula: client.numeroDocumento,
        primerNombre: client.primerNombre.toUpperCase(),
        segundoNombre: client.segundoNombre.toUpperCase(),
        primerApellido: client.apellidoPaterno.toUpperCase(),
        segundoApellido: client.apellidoMaterno.toUpperCase(),
        idUsuario: dataOfac.idUsuario,
        codigoPlataforma: dataOfac.codigoPlataforma,
        correoUsuario: dataOfac.correoUsuario.toUpperCase(),
      },
      httpVerb: "POST",
    };

    let objSaveCandidate = {
      product_type: "life",
      detail: {
        personeria: client.personeria,
        tipo_documento: client.tipoId,
        primer_nombre: client.primerNombre.toUpperCase(),
        segundo_nombre: client.segundoNombre.toUpperCase(),
        apellido_paterno: client.apellidoPaterno.toUpperCase(),
        apellido_materno: client.apellidoMaterno.toUpperCase(),
        fecha_nacimiento: client.fechaNacimiento,
        genero: client.genero,
        telefono: client.telefono,
        correo: client.correo.toUpperCase(),
        pasaporte: client.tipoId === "01" ? client.numeroDocumento : "",
        estadoMigratorio: client.tipoId === "01" ? client.estadoMigratorio : "",
        fecha_expedicion_pasaporte:
          client.tipoId === "01" ? client.expedicionPasaporte : "",
        fecha_ultimo_ingreso_pais:
          client.tipoId === "01" ? client.ingresoPais : "",
        fecha_caducidad_pasaporte:
          client.tipoId === "01" ? client.caducidadPasaporte : "",
      },
      nro_documento: client.numeroDocumento,
    };

    let objStep = {
      id: userId,
      step: "Paso 1",
      step_one_request: {
        description: "",
      },
      step_one_response: {
        description: "",
      },
    };

    try {
      const resp = await lstOfac(objUserOfac);

      if (resp?.message?.data?.Info === "Cliente en revisión.") {
        viewContext.notification.show("Cliente en revisión", "error");
        setIsLoading(false);
        return;
      }
      if (resp?.message?.data?.Info === "Cliente no aprobado.") {
        viewContext.notification.show("Cliente no aprobado", "error");
        setIsLoading(false);
        return;
      }
      // if (resp?.message?.data?.data?.Info.includes("Cliente en revisión.")) {
      //   viewContext.notification.show("Cliente en revisión", "error");
      //   return;
      // }
      if (resp?.message?.data?.data?.Info === "OK") {
        viewContext.notification.show("Validación exitosa", "success", true);
        setStep(1);
        setIsLoading(false);
      }

      await Submit("candidate", objSaveCandidate, "post")
        .then((value) => {
          objStep.id = value.data.data[0].id;
          setUserId(value.data.data[0].id);
          objStep.step_one_request.description = objUserOfac;
          objStep.step_one_response.description = resp;
        })
        .catch((error) => {
          viewContext.notification.show(error, "error");
        })
        .finally(() => { });

      await Submit("candidate/updateStep", objStep, "patch")
        .then((value) => {
          setIsLoading(false);
        })
        .catch((error) => {
          viewContext.handleError(error);
          setIsLoading(false);
        })
        .finally(() => {
          viewContext.handleError(
            "The Promise is settled, meaning it has been resolved or rejected."
          );
        });
    } catch (error) {
      viewContext.handleError(error);
      setIsLoading(false);
    }
  };

  const evaluateCandidate = async () => {
    let isValidCandidate = true;
    if (
      client.personeria === "" ||
      client.tipoId === "" ||
      client.numeroDocumento === "" ||
      client.primerNombre === "" ||
      client.apellidoPaterno === "" ||
      client.fechaNacimiento === "" ||
      client.genero === "" ||
      client.telefono === "" ||
      client.correo === "" ||
      client.fechaNacimientoSinFormato === null
    ) {
      isValidCandidate = false;
    }
    return isValidCandidate;
  };

  const evaluateCandidatePass = async () => {
    let isValidCandidate = true;
    if (
      client.caducidadPasaporte === "" ||
      client.estadoMigratorio === "" ||
      client.expedicionPasaporte === "" ||
      client.ingresoPais === "" ||
      client.caducidadPasaporteSinFormato === null ||
      client.estadoMigratorioSinFormato === null ||
      client.ingresoPaisSinFormato === null ||
      client.expedicionPasaporteSinFormato === null ||
      client.personeria === "" ||
      client.tipoId === "" ||
      client.numeroDocumento === "" ||
      client.primerNombre === "" ||
      client.apellidoPaterno === "" ||
      client.fechaNacimiento === "" ||
      client.genero === "" ||
      client.telefono === "" ||
      client.correo === "" ||
      client.fechaNacimientoSinFormato === null
    ) {
      isValidCandidate = false;
    }
    return isValidCandidate;
  };

  const generatePrice = async () => {
    try {
      setIsLoading(true);
      //Validations
      if (!plan?.planes[0][plan.textPlan]) {
        viewContext.notification.show("No se ha seleccionado un plan", "error");
        setIsLoading(false);
        return;
      }
      if (!plan?.valuesSelected) {
        setIsLoading(false);
        viewContext.notification.show(
          "No se ha seleccionado una alternativa",
          "error"
        );
        setIsLoading(false);
        return;
      }
      if (client.typePaid === "") {
        setIsLoading(false);
        viewContext.notification.show(
          "No se ha seleccionado una frecuencia de pago",
          "error"
        );
        setIsLoading(false);
        return;
      }
      if (client.smoker === "") {
        setIsLoading(false);
        viewContext.notification.show(
          "No se ha seleccionado la condición de fumador",
          "error"
        );
        return;
      }

      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;

      let tempCoverages = [];
      plan.planes[0][plan.textPlan].values.map((item, indexItem) => {
        plan.cod_producto.coberturas.map((cobertura) => {
          if (cobertura.astr_codigo_cob === item) {
            tempCoverages.push({
              astr_cobertura: cobertura?.astr_codigo_cob,
              adec_valor_aseg: plan?.valuesSelected[indexItem],
              astr_tipo_cobertura: cobertura?.astr_principal === "S" ? 1 : 0,
            });
          }
        });
      });

      let objReq = {
        resource: "/rest/jwt/tarifaproducto",
        data: {
          adt_fecha_nac: client.fechaNacimiento,
          adt_fecha_cotiz: today,
          astr_sexo: client.genero,
          astr_fumador: client.smoker,
          aint_edad: calcularEdad(client.fechaNacimiento),
          astr_producto: plan.cod_producto.astr_descripcion,
          astr_frec_pago: client.typePaid,
          coberturas: tempCoverages,
        },
        httpVerb: "GET",
      };

      let objSaveCandidate = {
        id: userId,
        detail: {
          personeria: client.personeria,
          tipo_documento: client.tipoId,
          primer_nombre: client.primerNombre.toUpperCase(),
          segundo_nombre: client.segundoNombre.toUpperCase(),
          apellido_paterno: client.apellidoPaterno.toUpperCase(),
          apellido_materno: client.apellidoMaterno.toUpperCase(),
          fecha_nacimiento: client.fechaNacimiento,
          genero: client.genero,
          telefono: client.telefono,
          correo: client.correo.toUpperCase(),
          pasaporte: client.tipoId === "01" ? client.numeroDocumento : "",
          estadoMigratorio: client.tipoId === "01" ? client.estadoMigratorio : "",
          fecha_expedicion_pasaporte:
            client.tipoId === "01" ? client.expedicionPasaporte : "",
          fecha_ultimo_ingreso_pais:
            client.tipoId === "01" ? client.ingresoPais : "",
          fecha_caducidad_pasaporte:
            client.tipoId === "01" ? client.caducidadPasaporte : "",
          fumador: client.smoker,
        },
      };

      let objStep = {
        id: userId,
        step: "Paso 2",
        step_two_request: {
          description: "",
        },
        step_two_response: {
          description: "",
        },
      };

      await Submit("bridgesdp", objReq, "post")
        .then(async (value1) => {
          setDataProjection(value1.data);
          setStepTwo(objReq.data);
          objStep.step_two_request.description = objReq.data;
          objStep.step_two_response.description = value1;
          viewContext.notification.show(value1.data.message, "success", true);
          if (value1?.data?.data?.proyeccion) {
            await Submit("candidate", objSaveCandidate, "patch")
              .then(async (value2) => {
                await Submit("candidate/updateStep", objStep, "patch")
                  .then((value3) => {
                    setClient({
                      ...client,
                      status: "CANDIDATO",
                    });
                    setIsLoading(false);
                    setStep(2);
                  })
                  .catch((error) => {
                    viewContext.handleError(error);
                    setIsLoading(false);
                  })
                  .finally(() => {
                    viewContext.handleError(
                      "The Promise is settled, meaning it has been resolved or rejected."
                    );
                  });
              })
              .catch((error) => {
                viewContext.handleError(error);
                setIsLoading(false);
              })
              .finally(() => {
                viewContext.handleError(
                  "The Promise is settled, meaning it has been resolved or rejected."
                );
              });
          } else {
            setIsLoading(false);
            viewContext.notification.show(
              "Problemas al cotizaar",
              "error",
              true
            );
          }
        })
        .catch((error) => {
          viewContext.handleError(error);
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
          viewContext.handleError(
            "The Promise is settled, meaning it has been resolved or rejected."
          );
        });

    } catch (error) {
      viewContext.handleError(error);
    }
  };

  const saveCandidate = async () => {
    try {
      let tempCoverages = [];
      plan.planes[0][plan.textPlan].values.map((item, indexItem) => {
        plan.cod_producto.coberturas.map((cobertura) => {
          if (cobertura.astr_codigo_cob === item) {
            tempCoverages.push({
              astr_cobertura: cobertura.astr_codigo_cob,
              adec_valor_aseg: plan.valuesSelected[indexItem],
              astr_tipo_cobertura: cobertura.astr_principal === "S" ? 1 : 0,
            });
          }
        });
      });
      const objStep = {
        id: userId,
        product: prices,
        estado: "Candidato",
      };

      await Submit("candidate/updateStep", objStep, "patch")
        .then((value) => {
          // navigate("/vendedor/vida");
          viewContext.notification.show("Cotización guardada", "success", true);
        })
        .catch((error) => {
          viewContext.handleError(error);
        })
        .finally(() => {
          viewContext.handleError(
            "The Promise is settled, meaning it has been resolved or rejected."
          );
        });

      let productName = localStorage.getItem("productNamePrice");
      let reqv2 = {
        type: "cotizacion",
        detail: stepTwo,
        candidate_id: userId,
        product_name: productName,
      };
      await postFormSave(reqv2).then((resp) => {
        viewContext.notification.show("Formulario guardado", "success", true);
        setIdForm(resp.data[0].id);
      });
    } catch (error) {
      viewContext.handleError(error);
    }
  };

  useEffect(() => {
    if (dataProjection) {
      let tempClient = { ...client };
      tempClient.status = "CANDIDATO";
      setClient(tempClient);
      let tempCoverages = [];

      plan.planes[0][plan.textPlan].values.map((item, indexItem) => {
        plan.cod_producto.coberturas.map((cobertura) => {
          if (cobertura.astr_codigo_cob === item) {
            tempCoverages.push({
              astr_cobertura: cobertura.astr_codigo_cob,
              adec_valor_aseg: plan.valuesSelected[indexItem],
              astr_tipo_cobertura: cobertura.astr_principal === "S" ? 1 : 0,
            });
          }
        });
      });

      let tempPrices = { ...prices };
      let length = Object.keys(tempPrices).length;
      localStorage.setItem("productNamePrice", `product${length + 1}`);
      tempPrices[`product${length + 1}`] = {
        client,
        plan,
        coberturas: tempCoverages,
        dataProjection: dataProjection,
      };
      // tempPrices.push({
      //   client,
      //   plan,
      //   coberturas: tempCoverages,
      //   dataProjection: dataProjection,
      // });
      setPrices(tempPrices);
    }
  }, [dataProjection]);

  useEffect(() => {
    getOptions();
  }, []);

  useEffect(() => {
    if (productsLife.data) setDataOptions();
  }, [productsLife]);

  const stepsCandidate = [
    {
      name: "Información del candidato",
      completed: true,
    },
    {
      name: "Información de producto",
      completed: step >= 1 ? true : false,
    },
    {
      name: "Cotización",
      completed: step >= 2 ? true : false,
    },
  ];

  return (
    <Animate type='pop'>
      <div className={Style.buttonPosition}>
        <BackButton
          action={() => {
            if (step === 0) {
              navigate("/vendedor/vida");
            } else {
              setStep(step - 1);
            }
          }}
        />
      </div>
      <section className={Style.paddingLeft}>
        <Content>
          <div style={{ padding: "15px 0px" }}>
            <p className={Style.textTitle}>Vida</p>
          </div>
          <section style={{ padding: "20px 0px" }}>
            {screenSize.dynamicWidth > 768 ? (
              <ProgressSteps items={stepsCandidate} />
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div className={Style.circle}>{step + 1}</div>
                <p className={Style.textStep}>{stepsCandidate[step].name}</p>
              </div>
            )}
          </section>
          {step === 0 && (
            <StepOne
              options={options}
              client={client}
              handleSelectChange={handleSelectChange}
              handleInputChange={handleInputChange}
              handleDate={handleDate}
              handleDatePass={handleDatePass}
              handleIncomingDate={handleIncomingDate}
              handleExpireDate={handleExpireDate}
              validateClient={validateClient}
              isLoading={isLoading}
            />
          )}
          {step === 1 && (
            <StepTwo
              options={options}
              plan={plan}
              setPlan={setPlan}
              generatePrice={generatePrice}
              handleInputChange={handleInputChange}
              handleChangeAlternativeSelected={handleChangeAlternativeSelected}
              handleChangePlanSelected={handleChangePlanSelected}
              handleSelectPlan={handleSelectPlan}
              handleChangeSelectOptions={handleChangeSelectOptions}
              isLoading={isLoading}
              limitAge={limitAge}
            />
          )}
          {step === 2 && (
            <StepThree
              client={client}
              plan={plan}
              dataProjection={dataProjection}
              generatePrice={generatePrice}
              saveCandidate={saveCandidate}
              setStep={setStep}
              idForm={idForm}
              setIdForm={setIdForm}
            />
          )}
        </Content>
      </section>
    </Animate>
  );

}
