/***
*
*   SIGN UP STEP 2
*   Signup form for account owners
*   Step 1: create account
*   Step 2: select plan and make payment
*
**********/

import React, { useContext } from 'react';
import { Animate, AuthContext, Row, Card, usePlans, Link, useNavigate, Event } from 'components/lib';

export function SignupPlan(props) {

  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const plans = usePlans();
  const plan = window.location.hash.substring(1);

  if (!plans.data)
    return false;

  return (
    <Animate type='pop'>
      <Row title='Seleccciona un Plan'>
        <Card loading={false} restrictWidth center>
          data={{
            plan: {
              label: 'Plan',
              type: 'select',
              options: plans.data.list,
              default: plan,
              required: true,
            },
            token: {
              label: 'Tarjeta de Crédito',
              type: 'creditcard',
              required: true,
            }
          }}
          url='/api/account/plan'
          method='POST'
          buttonText='Guardar Plan'
          callback={res => {

            // save the plan to context, then redirect
            Event('selected_plan', { plan: res.data.plan });
            context.update({ plan: res.data.plan, subscription: res.data.subscription });
            navigate(res.data.onboarded ? '/dashboard' : '/welcome');

          }}

          <div className='mt-4'>
            <Link url='/account/profile' text='Gestiona Tu Cuenta' />
          </div>

        </Card>
      </Row>
    </Animate>
  );
}
