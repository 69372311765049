import { Button, SalesForce } from "components/lib";
import { useEffect, useState } from "react";
import Style from "../../../../css/globalStyle.module.scss";

export function FuerzaVentasPromptModal({ isOpen, onClose, onSave, salesForce, setSalesForce, optionsByPlan, currentPlan }) {
    const [dimensions, setDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    useEffect(() => {
        const handleResize = () => {
            setDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const extraHeight = () => {
        let totalItems = 0;
        if (salesForce.broker !== null && salesForce.broker !== undefined) {
            totalItems += salesForce.broker.length;
        }
        if (salesForce.sponsor !== null && salesForce.sponsor !== undefined) {
            totalItems += salesForce.sponsor.length;
        }
        if (salesForce.sdp !== null && salesForce.sdp !== undefined) {
            totalItems += salesForce.sdp.length;
        }

        totalItems = totalItems - 4;
        if (totalItems <= 0) {
            return 0;
        }

        return totalItems * 5;

    };


    const [plan, setPlan] = useState({});

    const onSaveHandler = () => {
        //validate salesForce dont have empty arrays
        let channelCount = 0;
        if (salesForce.broker !== null && salesForce.broker !== undefined) {
            channelCount++;
        }
        if (salesForce.sponsor !== null && salesForce.sponsor !== undefined) {
            channelCount++;
        }
        if (salesForce.sdp !== null && salesForce.sdp !== undefined) {
            channelCount++;
        }

        if (channelCount === 0) {
            alert("Debe seleccionar al menos 1 canal");
            return;
        }
        onSave(plan);
        onClose();
    }

    if (!isOpen) return null;

    const handleOnChangePlan = (e) => {
        const plan = optionsByPlan.find((option) => option.value === e.target.value);
        setPlan(plan);
    }

    return (
        <div className={Style['modal']} style={{ width: dimensions.width, height: dimensions.height }}>
            <div className={Style["modal-content"]} style={{ textAlign: 'center', height: '600px' }} >
                <span className={Style["close"]} onClick={() => onClose()}>&times;</span>
                <div style={{ padding: "15px 0px" }}>
                    {
                        currentPlan !== null && currentPlan !== undefined ? (
                            <p className={Style.prioriTextTitle} style={{ textAlign: 'left' }}>
                                Plan Seleccionado
                            </p>
                        ) : (
                            <p className={Style.prioriTextTitle} style={{ textAlign: 'left' }}>
                                Seleccionar Plan
                            </p>
                        )
                    }
                </div>
                {
                    optionsByPlan && (
                        <div className={Style.center} style={{ padding: "15px 0px" }}>
                            <div style={{ display: "flex" }}>
                                <select
                                    style={{ display: "inline-block", maxWidth: "100%", color: currentPlan !== null && currentPlan !== undefined ? "gray" : "black" }}
                                    name='selectBox'
                                    id="cultivationPlan"
                                    value={currentPlan !== null && currentPlan !== undefined ? currentPlan.codePlan : plan.value}
                                    onChange={(e) => handleOnChangePlan(e)}
                                    disabled={currentPlan !== null && currentPlan !== undefined ? true : false}
                                >
                                    <option
                                                key={''}
                                                value={''}
                                            
                                            >
                                                {'Seleccionar Plan'}
                                            </option>
                                    {optionsByPlan.map((option) => {
                                        return (
                                            <option
                                                key={option.value}
                                                value={option.value}
                                            //selected ={currentPlan !== null && currentPlan !== undefined && option.value === currentPlan.codePlan ? true : false}
                                            >
                                                {option.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    )
                }

                <div style={{ padding: "15px 0px" }}>
                    <p className={Style.prioriTextTitle} style={{ textAlign: 'left' }}>
                        Fuerza de Ventas
                    </p>
                </div>
                <div style={{ display: "flex" }}>
                    <SalesForce
                        salesForce={salesForce}
                        setSalesForce={setSalesForce}
                        style={{ width: dimensions.width - dimensions.width / 3, height: dimensions.height / 6 }}
                    />
                </div>
                <br></br>
                <br></br>
                <div className={Style.center} style={{ marginTop: extraHeight() }}>
                    <Button
                        color={"blue"}
                        text={'Guardar'}
                        action={onSaveHandler}
                        className={Style.button}
                    //fullWidth={onSaveHandler}
                    />

                    <Button
                        color={"red"}
                        outline
                        text='Cancelar'
                        className={Style.button}
                        action={onClose}
                    />
                </div>

            </div>
        </div>
    );
}

