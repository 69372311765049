import { getCitiesLocal, lstOfac } from "components/bridges/BridgeServices";
import { ViewContext } from "components/lib";
import { useContext, useEffect, useState } from "react";
import Style from "../../../../../css/globalStyle.module.scss";
import { Title } from "./title";
import { useDataContext } from "../../../../../context/DataContext";

export function DatosPersonales(props) {
  /////////////////////////DataContext implementation/////////////////////////
  const {
    state
  } = useDataContext();
  const {
    options,
    datosPersonales,
    setDatosPersonales,
    setIsLoading,
    savedCedula,
    setSavedCedula,
    setPep,
    setActividadEconomica,
    setAutorizacionDebito
  } = props;

  useEffect(() => {
    if (savedCedula !== datosPersonales.numeroIdentificacion && datosPersonales.numeroIdentificacion.length > 0) {
      setDatosPersonales({ ...datosPersonales, isValidOFAC: null });
    }

  }, [datosPersonales.numeroIdentificacion]);

  const viewContext = useContext(ViewContext);
  const [cantones, setCantones] = useState([]);

  const getCitiesOptions = async () => {
    try {
      const respCiudadesV2 = localStorage.getItem('ciudades')
      let respCiudadesJson;
      if (respCiudadesV2) {
        respCiudadesJson = JSON.parse(respCiudadesV2)?.data
      }
      const respCiudades = state?.ciudades ? state.ciudades : respCiudadesJson ? respCiudadesJson : await getCitiesLocal();
      let tmpResp = getValueByKey(respCiudades?.data ? respCiudades.data : respCiudades, datosPersonales.provincia);
      let tempCities = [];
      tmpResp.map((item) => {
        tempCities.push({
          value: item.astr_id,
          label: item.astr_descripcion,
          astr_id: item.astr_id,
          astr_descripcion: item.astr_descripcion,
          astr_padre: item.astr_padre,
          type: "canton",
        });
      });
      if (datosPersonales?.canton === '') {
        let firstCity;
        if (tempCities.length > 0) {
          firstCity = tempCities[0];
        }
        setDatosPersonales({ ...datosPersonales, canton: firstCity?.value, cantonStr: firstCity?.label });
      }
      setCantones(tempCities);
    } catch (error) { }
    // }
  };

  useEffect(() => {
    if (datosPersonales.provincia !== "" && datosPersonales.provincia !== undefined) {
      getCitiesOptions();
    }
  }, [datosPersonales.provincia]);


  const validateOFAC = async () => {
    if (datosPersonales.primerNombre === '') {
      viewContext.notification.show(
        `Debe ingresar el primer nombre`,
        "error"
      );
      return false;
    }
    if (datosPersonales.primerApellido === '') {
      viewContext.notification.show(
        `Debe ingresar el primer apellido`,
        "error"
      );
      return false;
    }
    if (datosPersonales.segundoApellido === '') {
      viewContext.notification.show(
        `Debe ingresar el segundo apellido`,
        "error"
      );
      return false;
    }

    if (datosPersonales.numeroIdentificacion === '') {
      viewContext.notification.show(
        `Debe ingresar el número de identificación`,
        "error"
      );
      return false;
    }
    const validCi = verificarCedula(datosPersonales.numeroIdentificacion);
    if (!validCi.code) {
      viewContext.notification.show(
        validCi.message,
        "error"
      );
      return false;
    }

    setIsLoading(true);
    const validOFAC = await IsOfacValid();
    setIsLoading(false);
    let tmpValidOfac = false;
    if (validOFAC.code) {
      tmpValidOfac = true;
      viewContext.notification.show(
        validOFAC.message,
        "success"
      );
    } else {
      viewContext.notification.show(
        validOFAC.message,
        "error"
      );
    }
    setSavedCedula(datosPersonales.numeroIdentificacion);
    initializeDatosPer(datosPersonales, tmpValidOfac);
  }

  const IsOfacValid = async () => {

    const dataOfac = JSON.parse(localStorage.getItem("loginListasOfac"));
    let objUserOfac = {
      resource: "/rest/jwt/listasofacext",
      data: {
        tipoCliente: "N",
        tipoId: "00",
        cedula: datosPersonales.numeroIdentificacion,
        primerNombre: datosPersonales.primerNombre,
        segundoNombre: datosPersonales.segundoNombre,
        primerApellido: datosPersonales.primerApellido,
        segundoApellido: datosPersonales.segundoApellido,
        idUsuario: dataOfac.idUsuario,
        codigoPlataforma: dataOfac.codigoPlataforma,
        correoUsuario: dataOfac.correoUsuario,
      },
      httpVerb: "POST",
    };

    const resp = await lstOfac(objUserOfac);
    if (resp?.message?.data?.data?.Info === "OK") {
      return {
        code: true,
        message: `Validación exitosa`,
      };
    } else {
      return {
        code: false,
        message: resp?.message?.data?.data?.Info,
      };
    }

  };


  const handleChangePais = (e) => {
    const selectedPais = e.target.value;
    const paisName = options.nacionalidades.filter((item) => item.value === selectedPais)[0].label;
    setDatosPersonales({ ...datosPersonales, paisStr: paisName, pais: selectedPais, provincia: '', provinciaStr: '', canton: '', cantonStr: '' });
  };

  const handleChangeResidenciaFiscal = (e) => {
    const residenciaFiscal = e.target.value;
    const residenciaFiscalName = options.nacionalidades.filter((item) => item.value === residenciaFiscal)[0].label;
    setDatosPersonales({ ...datosPersonales, residenciaFiscal: residenciaFiscal, residenciaFiscalStr: residenciaFiscalName });
  };

  const verificarCedula = (ci) => {
    let isNumeric = true;
    let total = 0,
      individual;

    for (let position = 0; position < 10; position++) {
      // Obtiene cada posición del número de cédula
      // Se convierte a string en caso de que 'ci' sea un valor numérico
      individual = ci.toString().substring(position, position + 1)

      if (isNaN(individual)) {
        return { code: false, message: `No puede ingresar caracteres: ${ci}` };
        // isNumeric = false;
        // break;
      } else {
        // Si la posición es menor a 9
        if (position < 9) {
          // Si la posición es par, osea 0, 2, 4, 6, 8.
          if (position % 2 == 0) {
            // Si el número individual de la cédula es mayor a 5
            if (parseInt(individual) * 2 > 9) {
              // Se duplica el valor, se obtiene la parte decimal y se aumenta uno 
              // y se lo suma al total
              total += 1 + ((parseInt(individual) * 2) % 10);
            } else {
              // Si el número individual de la cédula es menor que 5 solo se lo duplica
              // y se lo suma al total
              total += parseInt(individual) * 2;
            }
            // Si la posición es impar (1, 3, 5, 7)
          } else {
            // Se suma el número individual de la cédula al total
            total += parseInt(individual);
          }
        }
      }
    }

    if ((total % 10) != 0) {
      total = (total - (total % 10) + 10) - total;
    } else {
      total = 0;
    }


    if (isNumeric) {
      // El total debe ser igual al último número de la cédula
      // La cédula debe contener al menos 10 dígitos
      if (ci.toString().length != 10) {
        return { code: false, message: `La c\u00E9dula debe ser de: 10 d\u00EDgitos: ${ci}` };
      }

      // El número de cédula no debe ser cero
      if (parseInt(ci, 10) == 0) {
        alert("La c\u00E9dula ingresada no puede ser cero.");
        return { code: false, message: `La c\u00E9dula ingresada no puede ser cero: ${ci}` };
      }

      // El total debe ser igual al último número de la cédula
      if (total != parseInt(individual)) {
        return { code: false, message: `La c\u00E9dula ingresada no es v\u00E1lida: ${ci}` };
      }

      return { code: true, message: `La c\u00E9dula ingresada es v\u00E1lida: ${ci}` };
    }

    // Si no es un número  
    return { code: true, message: `El dato solo puede contener numeros: ${ci}` };
  }

  function renderProvinciaEcuador() {
    return (
      <div className={Style.inputContainerQuarter}>
        <label>Provincia</label>
        <select
          className={Style["select"]}
          value={datosPersonales.provincia}
          onChange={(e) => {
            const provinciaName = options.provincias.filter((item) => item.value === e.target.value)[0].label;
            setDatosPersonales({ ...datosPersonales, provincia: e.target.value, provinciaStr: provinciaName, canton: '', cantonStr: '' });
          }}
        >
          {options &&
            options.provincias &&
            options.provincias.map((item) => {
              return (
                <option
                  key={item.value}
                  value={`${item.value}`}
                >
                  {item.label}
                </option>
              );
            })}
        </select>
      </div>
    );
  }

  function renderProvinciaOtroPais() {
    return (
      <div className={Style.inputContainerQuarter}>
        <label>Provincia/Estado</label>
        <input
          value={datosPersonales.provincia}
          type='text'
          className={Style["input"]}
          onChange={(e) => {
            const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
            if (e.target.value === "" || alphanumericExtendedRegex.test(e.target.value)) {
              setDatosPersonales({ ...datosPersonales, provincia: e.target.value.toUpperCase() });
            }
          }}
        />
      </div>
    );
  }

  function renderCantonEcuador() {
    return (
      <div className={Style.inputContainerQuarter}>
        <label>Cantón</label>
        <select
          className={Style["select"]}
          value={datosPersonales.canton}
          onChange={(e) => {
            const cantonName = cantones.filter((item) => item.value === e.target.value)[0].label;
            setDatosPersonales({ ...datosPersonales, canton: e.target.value, cantonStr: cantonName });
          }}
        >
          {cantones.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </div>
    );
  }

  function renderCantonOtroPais() {
    return (
      <div className={Style.inputContainerQuarter}>
        <label>Ciudad</label>
        <input
          value={datosPersonales.canton}
          type='text'
          className={Style["input"]}
          onChange={(e) => {
            const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
            if (e.target.value === "" || alphanumericExtendedRegex.test(e.target.value)) {
              setDatosPersonales({ ...datosPersonales, canton: e.target.value.toUpperCase() });
            }
          }}
        />
      </div>
    );
  }
  //flushing data
  function initializeDatosPer(dp, valid) {

    let tmpDP = {
      canton: "",
      cantonStr: "",
      correoElectronico: dp?.correoElectronico,
      direccionDomicilio: "",
      isValidOFAC: dp?.isValidOFAC,
      numeroIdentificacion: dp?.numeroIdentificacion,
      pais: "",
      paisStr: "",
      pasiStr: "",
      primerApellido: dp?.primerApellido,
      primerNombre: dp?.primerNombre,
      provincia: "",
      provinciaStr: "",
      residenciaFiscal: "",
      residenciaFiscalStr: "",
      segundoApellido: dp?.segundoApellido,
      segundoNombre: dp?.segundoNombre,
      telefono: dp?.telefono,
      tipoIdentificacion: dp?.tipoIdentificacion
    };
    setDatosPersonales({ ...tmpDP, isValidOFAC: valid });

    setPep({
      answer: "No",
      institution: '',
      position: '',
      positionCompletionDate: new Date(),
    })

    setActividadEconomica({
      actividadEconomica: '000000S01',
      actividadEconomicaStr: 'Jubilado',
      ingresos: 1,
      ingresosStr: '0.00 - 500.00',
      patrimonio: 9,
      patrimonioStr: '0.00 - 10,000.00',
    })

    setAutorizacionDebito({
      primerNombre: '',
      segundoNombre: '',
      primerApellido: '',
      segundoApellido: '',
      tipoIdentificacion: '00',
      numeroIdentificacion: '',
      isValidOFAC: false,
      answer: "Other",
      medioCobro: 'CUENTA_CORRIENTE',
      targetaCredito: '',
      targetaCreditoStr: '',
      numeroTarjeta: '',
      bancoTarjetaCredito: '',
      bancoTarjetaCreditoStr: '',
      bancoCuenta: '20106',
      bancoCuentaStr: 'BANCA INTESA SANPAOLO',
      numeroCuenta: '',
      fechaVencimientoTarjeta: '',
      parentesco: '01',
      parentescoStr: 'MADRE'
    })

  }

  function getValueByKey(obj, key) {
    if (obj.hasOwnProperty(key)) {
      return obj[key];
    } else {
      return undefined;
    }
  }

  return (
    <div>
      <Title whiteTitle='1. Sección datos personales' />
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerQuarter}>
          <label>Primer nombre</label>
          <input
            value={datosPersonales.primerNombre}
            type='text'
            className={Style["input"]}
            onChange={(e) => {
              const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
              if (e.target.value === "" || alphanumericExtendedRegex.test(e.target.value)) {
                setDatosPersonales({ ...datosPersonales, primerNombre: e.target.value.toUpperCase() });
              }
            }}
            disabled={datosPersonales.isValidOFAC !== null}
          />
        </div>
        <div className={Style.inputContainerQuarter}>
          <label>Segundo nombre</label>
          <input
            value={datosPersonales.segundoNombre}
            type='text'
            className={Style["input"]}
            onChange={(e) => {
              const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
              if (e.target.value === "" || alphanumericExtendedRegex.test(e.target.value)) {
                setDatosPersonales({ ...datosPersonales, segundoNombre: e.target.value.toUpperCase() });
              }
            }}
            disabled={datosPersonales.isValidOFAC !== null}
          />
        </div>
        <div className={Style.inputContainerQuarter}>
          <label>Primer apellido</label>
          <input
            value={datosPersonales.primerApellido}
            type='text'
            className={Style["input"]}
            onChange={(e) => {
              const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
              if (e.target.value === "" || alphanumericExtendedRegex.test(e.target.value)) {
                setDatosPersonales({ ...datosPersonales, primerApellido: e.target.value.toUpperCase() });
              }
            }}
            disabled={datosPersonales.isValidOFAC !== null}
          />
        </div>
        <div className={Style.inputContainerQuarter}>
          <label>Segundo apellido</label>
          <input
            value={datosPersonales.segundoApellido}
            type='text'
            className={Style["input"]}
            onChange={(e) => {
              const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
              if (e.target.value === "" || alphanumericExtendedRegex.test(e.target.value)) {
                setDatosPersonales({ ...datosPersonales, segundoApellido: e.target.value.toUpperCase() });
              }
            }}
            disabled={datosPersonales.isValidOFAC !== null}
          />
        </div>
      </div>
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerQuarter}>
          <label>Tipo de identificación</label>
          <select
            value={datosPersonales.tipoIdentificacion}
            className={Style["select"]}
            onChange={(e) => setDatosPersonales({ ...datosPersonales, tipoIdentificacion: e.target.value })}
            disabled={datosPersonales.isValidOFAC !== null}
          >
            <option
              value='00'
            >
              CEDULA
            </option>
          </select>
        </div>
        <div className={Style.inputContainerQuarter}>
          <label>Número de identificación</label>
          <input
            className={Style["input_number"]}
            value={datosPersonales.numeroIdentificacion}
            type='text'
            onChange={(e) => {
              const datosPersonalesTmp = datosPersonales;
              // if (datosPersonales.isValidOFAC && savedCedula === datosPersonales.numeroIdentificacion) {
              //   datosPersonalesTmp.primerNombre = '';
              //   datosPersonalesTmp.segundoNombre = '';
              //   datosPersonalesTmp.primerApellido = '';
              //   datosPersonalesTmp.segundoApellido = '';
              // }
              const inputText = e.target.value;
              const numericInput = inputText.replace(/[^0-9]/g, '');
              datosPersonalesTmp.numeroIdentificacion = numericInput;
              setDatosPersonales({ ...datosPersonalesTmp });
            }}
            maxLength={10}
            pattern="[0-9]*" // Optional: HTML pattern attribute for browsers that support it
            disabled={datosPersonales.isValidOFAC === true || datosPersonales.isValidOFAC === false}
          />
        </div>

        <div className={Style.containerButtons}>
          <input
            type='submit'
            value='Validar cédula'
            className={Style.buttonFinalizar}
            onClick={() => validateOFAC()}
            style={{ marginTop: '8px' }}
            disabled={datosPersonales.isValidOFAC === true || datosPersonales.isValidOFAC === false}
          />
        </div>



      </div>
      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainerQuarter}>
          <label>País</label>
          <select
            className={Style["select"]}
            value={datosPersonales.pais}
            onChange={(e) => handleChangePais(e)}
          >
            {options?.nacionalidades?.map((item) => {
              return (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              );
            })}
          </select>
        </div>
        {
          datosPersonales.pais === '327' ? renderProvinciaEcuador() : renderProvinciaOtroPais()
        }
        {
          datosPersonales.pais === '327' ? renderCantonEcuador() : renderCantonOtroPais()
        }
        <div className={Style.inputContainerQuarter}>
          <label>Residencia Fiscal</label>
          <select
            className={Style["select"]}
            value={datosPersonales.residenciaFiscal}
            onChange={(e) => handleChangeResidenciaFiscal(e)}
          >
            {options &&
              options.nacionalidades &&
              options.nacionalidades.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
          </select>
        </div>
      </div>

      <div className={Style.mainContainerForm}>
        <div className={Style.inputContainer35}>
          <label>Dirección de Domicilio</label>
          <input
            className={Style["input"]}
            type='text'
            maxLength={255}
            value={datosPersonales.direccionDomicilio}
            onChange={(e) => {
              const alphanumericExtendedRegex = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ/_.\-@ ]+$/;
              if (e.target.value === "" || alphanumericExtendedRegex.test(e.target.value)) {
                setDatosPersonales({ ...datosPersonales, direccionDomicilio: e.target.value.toUpperCase() });
              }
            }}

          />
        </div>
        <div className={Style.inputContainer35}>
          <label>Correo Electrónico</label>
          <input
            className={Style["input"]}
            type='email'
            maxLength={255}
            value={datosPersonales.correoElectronico}
            onChange={(e) => setDatosPersonales({ ...datosPersonales, correoElectronico: e.target.value })}
          />
        </div>
        <div className={Style.inputContainer35}>
          <label>Teléfono</label>
          <input
            className={Style["input_number"]}
            type='text'

            maxLength={10}
            minLength={10}
            value={datosPersonales.telefono}
            onChange={(e) => {
              const inputText = e.target.value;
              const numericInput = inputText.replace(/[^0-9]/g, '');
              setDatosPersonales({ ...datosPersonales, telefono: numericInput });
            }}
            pattern="[0-9]*" // Optional: HTML pattern attribute for browsers that support it
          />
        </div>
      </div>
      <div style={{ marginBottom: '20px' }}>
      </div>
    </div>
  );
}
