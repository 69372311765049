import React, { Fragment } from "react";
import { Icon, ViewContext, useLocation } from "components/lib";
import Style from "./valuesPlan.module.scss";
import { Scrollbars } from "react-custom-scrollbars-2";
import CurrencyInput from "react-currency-input-field";
import { Submit } from "components/bridges/BridgeServices";
import { useContext } from "react";
import Input from "../../../css/globalStyle.module.scss";

export function ValuesPlan(props) {
  const viewContext = useContext(ViewContext);
  const { planList, setPlanList } = props;
  let url = useLocation();
  let vecUrl = url.pathname.split("/");
  let id = "";
  if (vecUrl.length > 3) {
    id = vecUrl[3];
  }
  //Alternatives Actions
  // handle input change
  const handleInputAlternativeChange = async (
    value,
    name,
    indexPlan,
    indexAlternative,
    indexDetail,
    planAll
  ) => {
    try {
      const regex = /^[0-9\b]+$/;
      const plan = [...planList];
      if (id !== "") {
        let url = `product/getByIdProducto/${planAll[indexPlan].alternative[indexAlternative][0].uuid}/alternativa`;
        await Submit(url, "", "get").then((resp) => {
          if (resp.data?.data?.hasSons) {
            viewContext.notification.show(
              "No se puede editar la alternativa",
              "error",
              true
            );
            return;
          } else {
            let max = parseFloat(
              plan[indexPlan].features[indexDetail].adbl_cob_mon_max
            );
            let min = parseFloat(
              plan[indexPlan].features[indexDetail].adbl_cob_mon_min
            );
            let parseValue = parseFloat(value);

            // if value is not blank, then test the regex
            if (min === max) {
              plan[indexPlan][name][indexAlternative][indexDetail] = {
                value: `${min}`,
                uuid: planAll[indexPlan].alternative[indexAlternative][0].uuid,
              };
              setPlanList(plan);
            } else if (
              value === "" ||
              (regex.test(value) && parseValue > min && parseValue <= max)
            ) {
              plan[indexPlan][name][indexAlternative][indexDetail] = {
                value: value,
                uuid: planAll[indexPlan].alternative[indexAlternative][0].uuid,
              };
              setPlanList(plan);
            }
          }
          // idForm = resp.data[0].id;
        });
      } else {
        let max = parseFloat(
          plan[indexPlan].features[indexDetail].adbl_cob_mon_max
        );
        let min = parseFloat(
          plan[indexPlan].features[indexDetail].adbl_cob_mon_min
        );
        let parseValue = parseFloat(value);

        if (min === max) {
          plan[indexPlan][name][indexAlternative][indexDetail] = `${min}`;
          setPlanList(plan);
        } else if (
          value === "" ||
          (regex.test(value) && parseValue > min && parseValue <= max)
        ) {
          plan[indexPlan][name][indexAlternative][indexDetail] = value;
          setPlanList(plan);
        }
      }
    } catch (error) {
      console.error(error)
      viewContext.notification.show(
        "El valor ingresado no es válido",
        "error",
        true
      );
    }
  };

  // handle add alternative
  const handleAddAlternativeClick = (indexPlan) => {
    const plan = [...planList];
    plan[indexPlan].alternative.push([]);
    const lengthActualAlternative = plan[indexPlan].alternative.length;
    const lengthAlternativeToDuplicate = plan[indexPlan].features.length;
    for (let index = 0; index < lengthAlternativeToDuplicate; index++) {
      plan[indexPlan].alternative[lengthActualAlternative - 1].push(0);
    }
    setPlanList(plan);
  };

  // handle remove alternative
  const handleRemoveAlternativeClick = async (
    indexPlan,
    indexAlternative,
    alternative
  ) => {
    if (id !== "") {
      let uuid = alternative[0].uuid;
      let url = `product/getByIdProducto/${uuid}/alternativa`;
      await Submit(url, "", "get").then((resp) => {
        if (resp?.data?.data?.hasSons) {
          viewContext.notification.show(
            "No se puede eliminar la alternativa",
            "error",
            true
          );
          return;
        } else {
          const plan = [...planList];
          plan[indexPlan].alternative.splice(indexAlternative, 1);
          setPlanList(plan);
        }
        // idForm = resp.data[0].id;
      });
    } else {
      const plan = [...planList];
      plan[indexPlan].alternative.splice(indexAlternative, 1);
      setPlanList(plan);
    }
  };

  // useEffect(() => {
  //   const content = contentRef.current;
  //   setHeight((content && content.clientHeight) || 0);
  // }, [contentRef]);

  return (
    <div className={Style.valuesPlan}>
      <Scrollbars
        style={{
          width: "100%",
          height: "250px",
        }}
      >
        <div>
          {planList.map((plan, indexFatherPlan) => {
            return (
              <div key={`${indexFatherPlan}`} style={{ padding: 15 }}>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <h2
                    style={{
                      background: "#95D4E9 0% 0% no-repeat padding-box",
                      borderRadius: "10px",
                      opacity: 1,
                      padding: "15px",
                      color: "#0F265C",
                      width: "170px",
                      textAlign: "center",
                    }}
                  >
                    {`Plan ${indexFatherPlan + 1}`}
                  </h2>
                  {plan.alternative.length === 0 && (
                    <button
                      style={{
                        background: "#0F265C 0% 0% no-repeat padding-box",
                        opacity: 1,
                        borderRadius: "15px",
                        color: "white",
                        padding: "3px",
                        height: "25px",
                        alignSelf: "center",
                        margin: "0 5px",
                      }}
                      onClick={() => handleAddAlternativeClick(indexFatherPlan)}
                    >
                      <Icon image={"plus"} size={18} />
                    </button>
                  )}
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    {planList[indexFatherPlan].features.map(
                      (feature, indexFeature) => {
                        return (
                          <Fragment key={`${indexFeature}`}>
                            <div
                              style={{
                                display: "flex",
                                padding: "10px 10px 10px 0px",
                                width: "175px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  padding: "10px 10px 10px 0px",
                                  backgroundColor: "#e6e8f4",
                                  height: "125px",
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                <p>{feature.label}</p>
                              </div>
                            </div>
                          </Fragment>
                        );
                      }
                    )}
                  </div>
                  {planList.map((plan, indexPlan) => {
                    return (
                      <div key={`${indexPlan}`}>
                        {indexFatherPlan === indexPlan && (
                          <div
                            style={{
                              padding: 15,
                              display: "flex",
                              marginTop: "-70px",
                            }}
                          >
                            <Fragment>
                              {planList[indexPlan].alternative.map(
                                (alternative, indexAlternative) => {
                                  return (
                                    <div key={`${indexAlternative}`}>
                                      <div
                                        style={{
                                          display: "flex",
                                          position: "relative",
                                          // top: "-33px",
                                        }}
                                      >
                                        <h2
                                          style={{
                                            background:
                                              "#95D4E9 0% 0% no-repeat padding-box",
                                            borderRadius: "10px",
                                            opacity: 1,
                                            padding: "15px",
                                            width: "150px",
                                            color: "#0F265C",
                                          }}
                                        >{`Alternativa ${indexAlternative + 1
                                          }`}</h2>
                                        <button
                                          style={{
                                            background:
                                              "#0F265C 0% 0% no-repeat padding-box",
                                            opacity: 1,
                                            borderRadius: "15px",
                                            color: "white",
                                            padding: "3px",
                                            height: "25px",
                                            alignSelf: "center",
                                            margin: "0 5px",
                                          }}
                                          onClick={() =>
                                            handleAddAlternativeClick(indexPlan)
                                          }
                                        >
                                          <Icon image={"plus"} size={18} />
                                        </button>
                                        {/* {indexAlternative > 0 && ( */}
                                        <button
                                          style={{
                                            background:
                                              "#0F265C 0% 0% no-repeat padding-box",
                                            opacity: 1,
                                            borderRadius: "15px",
                                            color: "white",
                                            padding: "3px",
                                            height: "25px",
                                            alignSelf: "center",
                                            margin: "0 5px",
                                          }}
                                          onClick={() =>
                                            handleRemoveAlternativeClick(
                                              indexPlan,
                                              indexAlternative,
                                              alternative
                                            )
                                          }
                                        >
                                          <Icon image={"minus"} size={18} />
                                        </button>
                                        {/* )} */}
                                      </div>
                                      <div style={{}}>
                                        {alternative.map(
                                          (value, indexDetail) => {
                                            return (
                                              <div
                                                key={`${indexDetail}`}
                                                style={{
                                                  marginTop: "0px",
                                                  display: "flex",
                                                  padding: "10px 10px 10px 0",
                                                  height: "150px",
                                                }}
                                              >
                                                <CurrencyInput
                                                  disabled={false}
                                                  id={`alternative${indexPlan}${indexPlan}`}
                                                  name='alternative'
                                                  value={
                                                    planList[indexPlan]
                                                      .alternative[
                                                      indexAlternative
                                                    ][indexDetail].value
                                                  }
                                                  style={{ width: "150px" }}
                                                  className={Input["input"]}
                                                  decimalsLimit={2}
                                                  // groupSeparator=" "
                                                  onValueChange={(
                                                    value,
                                                    name
                                                  ) =>
                                                    handleInputAlternativeChange(
                                                      value,
                                                      name,
                                                      indexPlan,
                                                      indexAlternative,
                                                      indexDetail,
                                                      planList
                                                    )
                                                  }
                                                  prefix={"$"}
                                                />
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </Fragment>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
}
