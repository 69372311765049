import { BackButton } from "components/backButton/button";
import {
    Submit,
    buscaEmisionSdp,
    getCandidateById,
} from "components/bridges/BridgeServices";
import {
    Animate,
    ViewContext,
    useAPI,
    useLocation
} from "components/lib";
import { Download } from 'grommet-icons';
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import GlobalStyle from "../../../../css/globalStyle.module.scss";

export function FormCargaDocumentos() {
    let url = useLocation();
    const plan = JSON.parse(localStorage.getItem('plan_details'));
    const navigate = useNavigate();
    let vecUrl = url.pathname.split("/");
    let candidateId = vecUrl[vecUrl.length - 1];
    const [isLoading, setIsLoading] = useState(true);
    const [isChecked, setIsChecked] = useState(false);
    //DOCUMENTOS ASEGURADO
    const cedulaFileInputRef = useRef(null);
    const [cedulaFileName, setCedulaFileName] = useState('');
    const [cedulaFileData, setCedulaFileData] = useState('');
    const [cedulaURL, setCedulaURL] = useState(null);
    //DOCUMENTOS TERCERA PERSONA
    const cedulaTerceraPersonaFileInputRef = useRef(null);
    const [cedulaTerceraPersonaFileName, setCedulaTerceraPersonaFileName] = useState('');
    const [cedulaTerceraPersonaFileData, setCedulaTerceraPersonaFileData] = useState('');
    const [cedulaTerceraPersonaURL, setCedulaTerceraPersonaURL] = useState(null);

    const autorizacionDebitoFileInputRef = useRef(null);
    const [autorizacionDebitoFileName, setAutorizacionDebitoFileName] = useState('');
    const [autorizacionDebitoFileData, setAutorizacionDebitoFileData] = useState('');
    const [autorizacionDebitoURL, setAutorizacionDebitoURL] = useState(null);
    const formsFilled = useAPI(`/api/formByFilter?candidate_id=${candidateId}&product_name=priori&type=priori_vinculacion`);
    const viewContext = useContext(ViewContext);
    const [isEmited, setIsEmited] = useState(false);

    const getUploadedDocuments = async () => {
        const formsFilledV2 = await Submit(`formByFilterV2?candidate_id=${candidateId}`, null, "get");
        const documents = formsFilledV2.data;
        if (documents.data) {
            documents.data.map(async (item) => {
                if (documents.data.length > 0) {
                    if (item.url !== null) {
                        switch (item.type) {
                            case 'cedula_asegurado_priori': setCedulaURL(item.url); break;
                            case 'cedula_tercero_priori': setCedulaTerceraPersonaURL(item.url); break;
                            case 'autorizacion_priori': setAutorizacionDebitoURL(item.url); break;
                            default: break;
                        }
                    }
                }
            });
        }
    }

    useEffect(() => {
        async function getEmitStatus() {
            let curCandidate = await getCandidateById(candidateId);
            let idProveedor = curCandidate.data[0]?.emitesdp_request?.astr_id_proveedor;
            if (idProveedor === null || idProveedor === undefined || idProveedor === '') {
                setIsLoading(false);
                return;
            }
            try {
                let responseBusca = await buscaEmisionSdp(idProveedor, candidateId);
                let response = JSON.parse(responseBusca?.data?.message);
                if (response.resultado === 'OK') {
                    setIsEmited(true);
                }
            }
            catch (error) {
                setIsEmited(false);
            }
            setIsLoading(false);
        }
        getEmitStatus();
    }, []);

    useEffect(() => {
        async function tryGetFormData() {
            if (!formsFilled.loading && formsFilled.data) {
                formsFilled.data.map(async (item) => {
                    if (formsFilled.data.length > 0) {
                        const form = formsFilled.data[0].detail;
                        if (payThrirtPerson(form)) {
                            setIsChecked(true)
                        } else {
                            setIsChecked(false)
                        }
                    } else {
                        setIsChecked(false)
                    }
                });
            }
        }
        tryGetFormData();
    }, [formsFilled]);

    useEffect(() => {
        getUploadedDocuments();
    }, []);

    const payThrirtPerson = (form) => {

        if (form.autorizacionDebito.answer === 'Other') {
            return true;
        }

        return false;
    }

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleCedulaButtonClick = () => {
        cedulaFileInputRef.current.click();
    };

    const handleCedulaFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            let displayFileName = file.name;
            if (displayFileName.length > 30) {
                displayFileName = displayFileName.substring(0, 30) + '...';
            }
            setCedulaFileName(displayFileName);
            const reader = new FileReader();
            reader.onloadend = () => {
                setCedulaFileData(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleCedulaTerceraPersonaButtonClick = () => {
        cedulaTerceraPersonaFileInputRef.current.click();
    };

    const handleCedulaTerceraPersonaFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            let displayFileName = file.name;
            if (displayFileName.length > 30) {
                displayFileName = displayFileName.substring(0, 30) + '...';
            }
            setCedulaTerceraPersonaFileName(displayFileName);
            const reader = new FileReader();
            reader.onloadend = () => {
                setCedulaTerceraPersonaFileData(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleAutorizacionDebitoButtonClick = () => {
        autorizacionDebitoFileInputRef.current.click();
    };

    const handleAutorizacionDebitoFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            let displayFileName = file.name;
            if (displayFileName.length > 30) {
                displayFileName = displayFileName.substring(0, 30) + '...';
            }
            setAutorizacionDebitoFileName(displayFileName);
            const reader = new FileReader();
            reader.onloadend = () => {
                setAutorizacionDebitoFileData(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const downloadAuthorization = async () => {
        const url = 'https://segurosdelpichincha.com/docs/priori/AUTORIZACION-DE-DEBITO-TECERA-PERSONA.pdf';
        window.open(url, '_blank');
    };

    const saveOnly = async () => {

        if (cedulaFileData === '' && cedulaTerceraPersonaFileData === '' && autorizacionDebitoFileData === '') {
            viewContext.notification.show("No existen documentos para guardar", "error", true);
            return;
        }

        setIsLoading(true);
        const rawUser = JSON.parse(localStorage.getItem('profile'));
        let obj = {
            candidate_id: candidateId,
            email: rawUser.data.email,
            type: "priori_vinculacion",
        };
        const docsPromise = [];
        if (cedulaFileInputRef.current != null) {
            docsPromise.push(uploadCedula(obj, cedulaFileData));
        }
        if (cedulaTerceraPersonaFileInputRef.current != null) {
            docsPromise.push(uploadOtherCedula(obj, cedulaTerceraPersonaFileData));
        }
        if (autorizacionDebitoFileInputRef.current != null) {
            docsPromise.push(uploadAutorization(obj, autorizacionDebitoFileData));
        }

        await Promise.all(docsPromise)
            .then((values) => {
                getUploadedDocuments();
                viewContext.notification.show("Documentos guardados exitosamente: ", "success", true);
            })
            .catch((error) => {
                viewContext.handleError(error);
            });
        setIsLoading(false);
    };

    const sendToReview = async () => {
        setIsLoading(true);
        let obj = {
            id: candidateId,
            estado: "En suscripción"
        };
        await Submit("candidate/updateStepPriori", obj, "patch")
            .then(async (value) => {
                viewContext.notification.show("Documentos guardados exitosamente: ", "success", true);
            })
            .catch((error) => {
                viewContext.handleError(error);
            });
        setIsLoading(false);
    };

    const otpButton = async () => {

        setIsLoading(true);
        if (canSave()) {
            await saveOnly();
        }
        setIsLoading(false);
        navigate(`/formVerificacionOTP/${candidateId}`, { state: { plan } });
    };

    async function uploadCedula(obj, fileBase64) {
        obj.type = "cedula_asegurado_priori";
        obj.data = fileBase64;
        return new Promise((resolve, reject) => {
            Submit("signed_form/priori", obj, "post")
                .then(async (value) => {
                    resolve(value);
                })
                .catch((error) => {
                    viewContext.handleError(error);
                    reject(error);
                });
        });
    }

    async function uploadOtherCedula(obj, fileBase64) {
        obj.type = "cedula_tercero_priori";
        obj.data = fileBase64;
        return new Promise((resolve, reject) => {
            Submit("signed_form/priori", obj, "post")
                .then(async (value) => {
                    resolve(value);
                })
                .catch((error) => {
                    viewContext.handleError(error);
                    reject(error);
                });
        });
    }

    async function uploadAutorization(obj, fileBase64) {
        obj.type = "autorizacion_priori";
        obj.data = fileBase64;
        return new Promise((resolve, reject) => {
            Submit("signed_form/priori", obj, "post")
                .then(async (value) => {
                    resolve(value);
                })
                .catch((error) => {
                    viewContext.handleError(error);
                    reject(error);
                });
        });
    }


    const canSave = () => {

        if (isEmited) {
            return false;
        }

        if (isChecked) {
            if (cedulaFileData === '' && cedulaTerceraPersonaFileData === '' && autorizacionDebitoFileData === '') {
                return false;
            }
        } else {
            if (cedulaFileData === '') {
                return false;
            }
        }
        return true;
    }

    const canSendToReview = () => {
        if (isEmited) {
            return false;
        }
        if (isChecked) {
            if (cedulaURL === null || cedulaTerceraPersonaURL === null || autorizacionDebitoURL === null) {
                return false;
            }
        } else {
            if (cedulaURL === null) {
                return false;
            }
        }
        return true;
    }


    return (
        <Animate type='pop'>
            <div className={GlobalStyle.buttonPosition}>
                <BackButton go={-1} />
            </div>
            <section className={GlobalStyle.form}>
                <div style={{ width: '90%', justifyContent: 'center', alignItems: 'center', marginLeft: '5%' }}>

                    {!isLoading ? (
                        <>

                            <div className={GlobalStyle.mainContainerForm}>
                                <div className={GlobalStyle.inputContainerHalf}>
                                    <div className={GlobalStyle.containerValuePlanThreeNoColor}>
                                        <div className={GlobalStyle.prioriTextTitle}>
                                            Cotización {plan.secuencial} / ${plan.product.planCode}
                                        </div>
                                    </div>

                                </div>
                                <div className={GlobalStyle.inputContainerHalf}>
                                    <div className={GlobalStyle.containerValuePlanThree}>
                                        <div className={GlobalStyle.prioriTextTitle}>
                                            PRIORI - Ahorro ${plan.product.planCode}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={GlobalStyle.mainContainerFormWithBgLightBlue}>
                                <div className={GlobalStyle.inputContainerQuarter}>
                                    <div className={GlobalStyle.rowTitle} style={{ padding: '15px' }}>
                                        Cédula del asegurado
                                    </div>

                                </div>
                                <div className={GlobalStyle.verticalDivider}></div>
                                <div className={GlobalStyle.inputContainerHalf}>
                                    <div className={GlobalStyle.rowBody} style={{ padding: '15px' }}>
                                        {cedulaFileName}
                                    </div>
                                </div>

                                <div className={GlobalStyle.inputContainerQuarter}>
                                    <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        ref={cedulaFileInputRef}
                                        onChange={handleCedulaFileChange}
                                        accept=".jpg, .jpeg, .png, .pdf"
                                    />
                                    <input
                                        type='button'
                                        value='Cargar'
                                        className={!isEmited ? GlobalStyle.buttonFinalizar : GlobalStyle.buttonFinalizarDisabled}
                                        onClick={handleCedulaButtonClick}
                                        style={{ alignSelf: 'center', }}
                                    />
                                </div>

                                {cedulaURL && (
                                    <div>
                                        <a href={cedulaURL} target="_blank" rel="noopener noreferrer" className={GlobalStyle.downloadButton}>
                                            <Download color="white" size="medium" />
                                        </a>
                                    </div>
                                )}

                            </div>

                            <div>
                                <div style={{ fontSize: '25px', fontWeight: 'bold', marginLeft: '25px', marginTop: '25px' }}>
                                    <input
                                        type="checkbox"
                                        id="toggleInput"
                                        style={{ width: '25px', height: '25px' }}
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        disabled={true}
                                    />
                                    <label htmlFor="toggleInput" style={{ marginLeft: '10px' }}>
                                        Paga una tercera persona?
                                    </label>
                                </div>
                                {isChecked && (
                                    <div>
                                        <div className={GlobalStyle.mainContainerForm} style={{ marginTop: '50px' }}>
                                            <div className={GlobalStyle.mainContainerForm}>
                                                <input
                                                    type='button'
                                                    value='Descargar autorización de débito'
                                                    className={GlobalStyle.buttonFinalizar}
                                                    onClick={downloadAuthorization}
                                                    style={{ alignSelf: 'center', }}
                                                />
                                            </div>
                                        </div>

                                        <div className={GlobalStyle.mainContainerFormWithBgLightBlue}>
                                            <div className={GlobalStyle.inputContainerQuarter}>
                                                <div className={GlobalStyle.rowTitle} style={{ padding: '15px' }}>
                                                    Cédula tercera persona
                                                </div>

                                            </div>
                                            <div className={GlobalStyle.verticalDivider}></div>
                                            <div className={GlobalStyle.inputContainerHalf}>
                                                <div className={GlobalStyle.rowBody} style={{ padding: '15px' }}>
                                                    {cedulaTerceraPersonaFileName}
                                                </div>
                                            </div>

                                            <div className={GlobalStyle.inputContainerQuarter}>
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={cedulaTerceraPersonaFileInputRef}
                                                    onChange={handleCedulaTerceraPersonaFileChange}
                                                    accept=".jpg, .jpeg, .png, .pdf"
                                                />
                                                <input
                                                    type='button'
                                                    value='Cargar'
                                                    className={!isEmited ? GlobalStyle.buttonFinalizar : GlobalStyle.buttonFinalizarDisabled}
                                                    onClick={handleCedulaTerceraPersonaButtonClick}
                                                    style={{ alignSelf: 'center', }}
                                                />

                                            </div>

                                            {cedulaTerceraPersonaURL && (
                                                <div>
                                                    <a href={cedulaTerceraPersonaURL} target="_blank" rel="noopener noreferrer" className={GlobalStyle.downloadButton}>
                                                        <Download color="white" size="medium" />
                                                    </a>
                                                </div>
                                            )}
                                        </div>

                                        <div className={GlobalStyle.mainContainerFormWithBgLightBlue}>
                                            <div className={GlobalStyle.inputContainerQuarter}>
                                                <div className={GlobalStyle.rowTitle} style={{ padding: '15px' }}>
                                                    Autorización de débito
                                                </div>

                                            </div>
                                            <div className={GlobalStyle.verticalDivider}></div>
                                            <div className={GlobalStyle.inputContainerHalf}>
                                                <div className={GlobalStyle.rowBody} style={{ padding: '15px' }}>
                                                    {autorizacionDebitoFileName}
                                                </div>
                                            </div>

                                            <div className={GlobalStyle.inputContainerQuarter}>
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={autorizacionDebitoFileInputRef}
                                                    onChange={handleAutorizacionDebitoFileChange}
                                                    accept=".jpg, .jpeg, .png, .pdf"
                                                />
                                                <input
                                                    type='button'
                                                    value='Cargar'
                                                    className={!isEmited ? GlobalStyle.buttonFinalizar : GlobalStyle.buttonFinalizarDisabled}
                                                    onClick={handleAutorizacionDebitoButtonClick}
                                                    style={{ alignSelf: 'center', }}
                                                />

                                            </div>

                                            {autorizacionDebitoURL && (
                                                <div>
                                                    <a href={autorizacionDebitoURL} target="_blank" rel="noopener noreferrer" className={GlobalStyle.downloadButton}>
                                                        <Download color="white" size="medium" />
                                                    </a>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                )}
                            </div>
                            <div style={{ marginTop: '30px' }}>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div className={GlobalStyle.containerButtons}>
                                    <input
                                        type='submit'
                                        value='Guardar sin Finalizar(1)'
                                        className={canSave() ? GlobalStyle.buttonFinalizar : GlobalStyle.buttonFinalizarDisabled}
                                        onClick={saveOnly}
                                    />
                                </div>
                                <div className={GlobalStyle.containerButtons} style={{ paddingLeft: '10px' }} >
                                    <input
                                        type='submit'
                                        value='Enviar a revisión del cliente(2)'
                                        className={canSendToReview() ? GlobalStyle.buttonFinalizar : GlobalStyle.buttonFinalizarDisabled}
                                        onClick={sendToReview}
                                    />
                                </div>
                                <div className={GlobalStyle.containerButtons} style={{ paddingLeft: '10px' }} >
                                    <input
                                        type='submit'
                                        value='Guardar y pasar a firma OTP(3)'
                                        className={!isEmited && cedulaURL ? GlobalStyle.buttonFinalizar : GlobalStyle.buttonFinalizarDisabled}
                                        onClick={otpButton}
                                    />
                                </div>
                            </div>

                        </>
                    ) : (
                        <div className={GlobalStyle.ldsDualRing}></div>
                    )}
                </div>
            </section>
        </Animate>
    )
}