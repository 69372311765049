/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   noPadding: remove the padding
 *   restrictWidth: restrict the width of the card on large screens
 *
 **********/

import React from "react";
import { Grid, ClassHelper } from "components/lib";
import Style from "./radioButton-module.scss";

export function RadioProduct(props) {
  const inputStyle = ClassHelper(Style, {
    className: props.className,
  });

  return (
    <section className={inputStyle}>
      <label>{props.title}</label>
      <div
        style={{ display: "flex", justifyContent: "space-evenly" }}
        onChange={props.onChange}
      >
        {props.options.map((option, index) => {
          return (
            <>
              <input
                key={`${index}${option.value}radio`}
                type='radio'
                value={option.value}
                name={option.name}
              />{" "}
              {option.label}
            </>
          );
        })}
      </div>
    </section>
  );
}
